import React from 'react';
import PropTypes from 'prop-types'; 
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { default as MuiButton } from '@material-ui/core/Button';
import Button from './button';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const SplitButton = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const showMenu = (e) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const closeMenu = (e) => {
		if (e) e.preventDefault();
		setAnchorEl(null);
	};

	const buttonProps = {...props};
	delete buttonProps.items;

	return (
		<React.Fragment>
			<ButtonGroup>
				<Button
					{...buttonProps}
				>
					{props.children}
				</Button>
				{props.button}
				<MuiButton
					size="small"
					onClick={(e) => showMenu(e)}
					>
					<ArrowDropDownIcon />
				</MuiButton>
			</ButtonGroup>
			<Menu
				open={open}
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
				onClose={closeMenu}
			>
				{props.items(closeMenu)}
			</Menu>
		</React.Fragment>
	);
};
	
SplitButton.propTypes = {
	children: PropTypes.any.isRequired,
	items: PropTypes.func.isRequired
};

SplitButton.defaultProps = {
};

export default SplitButton;
