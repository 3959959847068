import React from 'react';

const DisplayLine = ({ value }) =>
	<div className="display-line">
		<span>{value}</span>
	</div>;

DisplayLine.propsTypes = {
};

DisplayLine.defaultProps = {
};

export default DisplayLine;
