import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverDateField from '../components/handoverDateField';
import HandoverMemoField from '../components/handoverMemoField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';
import map from 'lodash/map';
import Paper from '@material-ui/core/Paper';
import { toCurrency } from '../../../utils/utils';

class SeparablePortionHandoverContent extends React.Component {
	render() {
		const separablePortion = this.props.report;

		const itemColumns = [
			{ name: 'itemNo', label: 'Item No' }
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Name of Separable Portion" value={separablePortion.name} />
				<HandoverDateField label="Date for PC" value={separablePortion.practicalCompletionDate} />
				<HandoverField label="Maintenance Period" value={separablePortion.maintenancePeriod} />
				<HandoverMemoField label="Comments" value={separablePortion.comments} />
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Schedules</HandoverSectionHeader>

				{
					map(separablePortion.schedules, (s) =>
						<Paper 
							elevation={0}
							style={{ 
								padding: '10px', 
								marginBottom: '10px',
								border: '1px solid #777'
							}}
						>
							<h2 style={{ marginTop: '0', marginBottom: '0', fontWeight: 'bold' }}>{s.scheduleName} {s.appliesToEntireSchedule && <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>(Applies To Entire Schedule)</span>}</h2>
							<p style={{ fontStyle: 'italic', marginBottom: '10px' }}>{`${(s.items || []).length} items`}</p>
							{!s.appliesToEntireSchedule &&
								<div>
									<DataTable 
										data={s.items || []} 
										columns={itemColumns} 
										style={{ width: '100%' }}
										hideActions={true}
										hideSearch={true}
										hideFilter={true}
										hidePaging={true}
										defaultRowsPerPage="All"
									/>
								</div>
							}
							<div style={{ 
									textAlign: 'right', 
									fontSize: '16px', 
									fontWeight: 'bold', 
									marginTop: '10px' 
								}}
							>
								<span>Total: </span>
								<span style={{ display: 'inline-block', minWidth: '100px' }}>{toCurrency(s.totalValue)}</span>
							</div>
						</Paper>
					)
				}
			</div>
		</div>
    );
  }
}

SeparablePortionHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default SeparablePortionHandoverContent;
