import React from 'react';
import PropTypes from 'prop-types';
import { 
	BarChart, 
	Bar, 
	XAxis, 
	YAxis, 
	Legend,
	LabelList,
	Tooltip,
	ResponsiveContainer
} from 'recharts';
import find from 'lodash/find';
import { toCurrency } from '../../utils/utils';

const CurrentClaimThisPeriod = ({ certifiedAndClaim }) => {
	const certifiedAndClaimThisPeriod = find(certifiedAndClaim, p => p.status === 'Open');

	return (
		<ResponsiveContainer>
		<BarChart 
			layout="vertical" 
			data={[certifiedAndClaimThisPeriod]}
		>
			<Tooltip 
				formatter={(v) => toCurrency(v)}
			/>
			<Bar isAnimationActive={false} name="Certified Amount" dataKey="certifiedAmount" fill="#0E76BC">
				<LabelList dataKey="certifiedAmount" position="center" formatter={(val) => toCurrency(val)} />
			</Bar>
			<Bar isAnimationActive={false} name="Claim Amount" dataKey="claimAmount" fill="#8DC63F">
				<LabelList dataKey="claimAmount" position="center" formatter={(val) => toCurrency(val)} />
			</Bar>

			<YAxis type="category" dataKey="name" hide />
			<XAxis type="number" hide />
			<Legend />
		</BarChart>
		</ResponsiveContainer>
	);
};

CurrentClaimThisPeriod.propTypes = {
	setLoading: PropTypes.func.isRequired,
	contractId: PropTypes.number
};

CurrentClaimThisPeriod.defaultProps = {
	contractId: null
};

export default CurrentClaimThisPeriod;
