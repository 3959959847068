import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PageLayout from '../pageLayout';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import TenantsGrid from './TenantsGrid';
import { get } from '../../utils/ajax';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import { 
	Grid,
} from '@material-ui/core';
import TenantCard from './TenantCard';
import map from 'lodash/map';

const Tenants = props => {
	const [state, setState] = React.useState({
		tenants: [],
		isLoading: false
	});

	React.useEffect(() => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		get({
			url: `/api/tenants`,
			onSuccess: data => setState({ tenants: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	}, [props.contractId]);

	// const onDeleteTenant = (tenant) => {
	// 	props.confirmDelete(tenant, () => {
	// 		setState(prevState => ({ ...prevState, isLoading: true }));
			
	// 		post({
	// 			url: `/api/tenants/${tenant.tenantId}/delete`,
	// 			onSuccess: data => {
	// 				setState({ isLoading: false, tenants: filter(state.tenants, c => c.tenantId !== tenant.tenantId) });

	// 				if (data.success) {
	// 					props.showSuccessNotification(data.message);
	// 				} else {
	// 					props.showErrorNotification(data.message);
	// 				}
	// 			},
	// 			onError: error => {
	// 				setState(prevState => ({ ...prevState, isLoading: false }));
	// 				props.showErrorNotification(error.message);
	// 			}
	// 		});
	// 	});
	// };
	return (
		<PageLayout
			title="Tenants"
			isLoading={state.isLoading}
			actions={
				[
					{
						label: 'New Tenant',
						onClick: () => { props.history.push('/tenants/new'); }
					}
				]
			}
			content={
				<div>
					{/* <ContentContainer>
						<TenantsGrid
							tenants={state.tenants}
							canView={props.canView}
							canEdit={props.canEdit}
							onEditTenant={tenant => { props.history.push(`/tenant/${tenant.tenantId}`); }}
							onViewTenant={tenant => { props.history.push(`/tenant/${tenant.tenantId}`); }}
						/>

					</ContentContainer> */}

					<Grid container spacing={3} style={{ marginTop: 0 }}>
						{map(state.tenants, (tenant, i) => 
							<TenantCard 
								key={i} 
								tenant={tenant} 
								canEdit={props.canEdit} 
							/>
						)}
					</Grid>
				</div>
			}
		/>
    );
};

Tenants.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

Tenants.defaultProps = {
};

const mapStateToProps = (state) => ({
	canView: canView(state, 'ViewTenants'),
	canEdit: canEdit(state, 'EditTenants'),
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	confirmDelete: (tenant, onOk) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this tenant?',
			onOk: () => {
				if (onOk) onOk(tenant);
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(Tenants);
