
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

const ContentContainer = ({ 
	header, subHeader, style, children 
}) => {
	const paperStyle = {
		padding: '10px',
		margin: '10px 0',
		...style
	};
	return (
		<Paper style={paperStyle}>
			<h3 style={{ 
					display: header ? 'inline-block' : 'none',
					color: '#03a9f4',
					margin: '0 0 10px 0'
				}}
			>
				{header}
			</h3>
			{subHeader &&
				<h4 style={{
						display: 'inline',
						fontSize: '14px',
						fontWeight: 'normal',
						fontStyle: 'italic',
						marginLeft: '10px' 
					}}
					
				>
					{subHeader}
				</h4>
			}
			<div className="row clearfix">
				{children}
			</div>
		</Paper>
	);
};

ContentContainer.propTypes = {
	header: PropTypes.string,
	subHeader: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.any
};

ContentContainer.defaultProps = {
	header: '',
	subHeader: undefined,
	style: undefined,
	children: undefined
};

export default ContentContainer;
