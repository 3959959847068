import { createFetchAction, createPostAction } from '../utils/reducer-utils';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	progressClaim: null
};

const CLEAR_PROGRESS_CLAIM = 'CLEAR_PROGRESS_CLAIM';
const CLEAR_PROGRESS_CLAIM_SAVE_RESULT = 'CLEAR_PROGRESS_CLAIM_SAVE_RESULT';
const REQUEST_PROGRESS_CLAIM = 'REQUEST_PROGRESS_CLAIM';
const RECEIVE_PROGRESS_CLAIM = 'RECEIVE_PROGRESS_CLAIM';
const SAVE_PROGRESS_CLAIM = 'SAVE_PROGRESS_CLAIM';
const RECEIVE_SAVE_PROGRESS_CLAIM_RESPONSE = 'RECEIVE_SAVE_PROGRESS_CLAIM_RESPONSE';
const SHOW_PROGRESS_CLAIM_ERRORS = 'SHOW_PROGRESS_CLAIM_ERRORS';

export const clearProgressClaim = () => ({ type: CLEAR_PROGRESS_CLAIM });
export const clearSaveResult = () => ({ type: CLEAR_PROGRESS_CLAIM_SAVE_RESULT });
export const receiveProgressClaim = (data) => ({ type: RECEIVE_PROGRESS_CLAIM, payload: { data } });
export const receiveSaveProgressClaimResponse = (data) => ({ type: RECEIVE_SAVE_PROGRESS_CLAIM_RESPONSE, data });

export const requestProgressClaim = () => (
	createFetchAction({
		objectName: 'Progress Claim',
		passContext: true,
		url: '/api/progress-claim',
		startAction: REQUEST_PROGRESS_CLAIM,
		success: (data) => receiveProgressClaim(data)
	})
);

const validateProgressClaim = () => {
	const errors = [];
	return errors;
};

export const saveProgressClaim = (progressClaim, onSuccess) => {
	const errors = validateProgressClaim(progressClaim);
	if (errors.length > 0) return { type: SHOW_PROGRESS_CLAIM_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/progress-claim',
		data: progressClaim,
		startAction: SAVE_PROGRESS_CLAIM,
		success: (data, dispatch) => {
			dispatch(receiveSaveProgressClaimResponse(data));
			if (onSuccess) onSuccess.call(this, data);
		}
	});
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_PROGRESS_CLAIM:
			return {
				...state,
				progressClaim: null
			};
		case CLEAR_PROGRESS_CLAIM_SAVE_RESULT:
			return {
				...state,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case REQUEST_PROGRESS_CLAIM:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				progressClaim: null
			};
		case RECEIVE_PROGRESS_CLAIM:
			return {
				...state,
				isLoading: false,
				progressClaim: action.payload.data
			};
		case SAVE_PROGRESS_CLAIM:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_PROGRESS_CLAIM_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				progressClaim: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_PROGRESS_CLAIM_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
