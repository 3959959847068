import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { globals } from '../globals';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	limitOfAccuracy: null, 
	limitsOfAccuracy: null
};

const CLEAR_LIMITS_OF_ACCURACY = 'CLEAR_LIMITS_OF_ACCURACY';
const CLEAR_LIMIT_OF_ACCURACY = 'CLEAR_LIMIT_OF_ACCURACY';
const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT';
const REQUEST_LIMITS_OF_ACCURACY = 'REQUEST_LIMITS_OF_ACCURACY';
const RECEIVE_LIMITS_OF_ACCURACY = 'RECEIVE_LIMITS_OF_ACCURACY';
const REQUEST_LIMIT_OF_ACCURACY = 'REQUEST_LIMIT_OF_ACCURACY';
const RECEIVE_LIMIT_OF_ACCURACY = 'RECEIVE_LIMIT_OF_ACCURACY';
const SAVE_LIMIT_OF_ACCURACY = 'SAVE_LIMIT_OF_ACCURACY';
const RECEIVE_SAVE_LIMIT_OF_ACCURACY_RESPONSE = 'RECEIVE_SAVE_LIMIT_OF_ACCURACY_RESPONSE';
const SHOW_LIMIT_OF_ACCURACY_ERRORS = 'SHOW_LIMIT_OF_ACCURACY_ERRORS';
const EDIT_LIMIT_OF_ACCURACY = 'EDIT_LIMIT_OF_ACCURACY';

export const clearLimitsOfAccuracy = () => ({ type: CLEAR_LIMITS_OF_ACCURACY });
export const clearLimitOfAccuracy = () => ({ type: CLEAR_LIMIT_OF_ACCURACY });
export const clearSaveResult = () => ({ type: CLEAR_SAVE_RESULT });
export const receiveLimitsOfAccuracy = (data) => ({ type: RECEIVE_LIMITS_OF_ACCURACY, payload: { data } });
export const receiveLimitOfAccuracy = (data) => ({ type: RECEIVE_LIMIT_OF_ACCURACY, payload: { data } });
export const receiveSaveLimitOfAccuracyResponse = (data) => ({ type: RECEIVE_SAVE_LIMIT_OF_ACCURACY_RESPONSE, data });
export const editLimitOfAccuracy = (limitOfAccuracy) => ({ type: EDIT_LIMIT_OF_ACCURACY, limitOfAccuracy });

export const requestLimitsOfAccuracy = () => (
	createFetchAction({
		objectName: 'Limits of Accuracy',
		passContext: true,
		url: '/api/limits-of-accuracy',
		startAction: REQUEST_LIMITS_OF_ACCURACY,
		success: (data) => receiveLimitsOfAccuracy(data)
	})
);

export const requestLimitOfAccuracy = (limitOfAccuracyId) => (
	createFetchAction({
		objectName: 'Limit of Accuracy',
		passContext: true,
		url: `/api/limits-of-accuracy/${limitOfAccuracyId}`,
		startAction: REQUEST_LIMIT_OF_ACCURACY,
		success: (data) => receiveLimitOfAccuracy(data)
	})
);

const validateLimitOfAccuracy = () => {
	const errors = [];

	return errors;
};

export const saveLimitOfAccuracy = (limitOfAccuracy, onSuccess) => {
	const errors = validateLimitOfAccuracy(limitOfAccuracy);
	if (errors.length > 0) return { type: SHOW_LIMIT_OF_ACCURACY_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/limits-of-accuracy',
		data: limitOfAccuracy,
		startAction: SAVE_LIMIT_OF_ACCURACY,
		success: (data, dispatch) => {
			dispatch(receiveSaveLimitOfAccuracyResponse(data));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_LIMITS_OF_ACCURACY:
			return {
				...state,
				limitsOfAccuracy: null
			};
		case CLEAR_LIMIT_OF_ACCURACY:
			return {
				...state,
				limitOfAccuracy: null
			};
		case CLEAR_SAVE_RESULT:
			return {
				...state,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case EDIT_LIMIT_OF_ACCURACY:
			return {
				...state,
				limitOfAccuracy: action.limitOfAccuracy,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case REQUEST_LIMITS_OF_ACCURACY:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				limitsOfAccuracy: []
			};
		case RECEIVE_LIMITS_OF_ACCURACY:
			return {
				...state,
				isLoading: false,
				limitsOfAccuracy: action.payload.data
			};
		case REQUEST_LIMIT_OF_ACCURACY:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				limitOfAccuracy: globals.templates.limitOfAccuracy
			};
		case RECEIVE_LIMIT_OF_ACCURACY:
			return {
				...state,
				isLoading: false,
				limitOfAccuracy: action.payload.data
			};
		case SAVE_LIMIT_OF_ACCURACY:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_LIMIT_OF_ACCURACY_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				limitsOfAccuracy: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_LIMIT_OF_ACCURACY_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
