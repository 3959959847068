import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import { toCurrency } from '../../utils/utils';
import round from 'lodash/round';

class ProgressClaimSummaryReportContent extends React.Component {
	render() {
		const report = this.props.report || {};
		const claim = report.claim ? report.claim : { items: [] };
		const claimItems = claim.items;
		const claimItemsBySchedule = groupBy(claimItems, (i) => Number(`${i.scheduleOrder}.${i.scheduleOfRatesId}`));
		const tempClaimItems = filter(claim.items, i => i.claimType === 'Temporary');

		const scheduleTotals = [];
		let totalAmount = 0;
		let totalPrevClaim = 0;
		let totalContractorClaim = 0;
		let totalCertThisPeriod = 0;
		let totalCertToDate = 0;
		let totalDifference = 0;

		map(filter(claimItemsBySchedule, i => i[0].scheduleIsActive), (items) => {
			const scheduleTotal = {
				name: items && items.length > 0 ? items[0].scheduleName : '',
				qty: 0,
				amount: 0,
				prevPeriodClaimQty: 0,
				prevPeriodClaimAmount: 0,
				claimQty: 0,
				claimAmount: 0,
				prevClaimQty: 0,
				prevClaimAmount: 0,
				prevCertifiedQty: 0,
				prevCertifiedAmount: 0,
				certifiedQty: 0,
				certifiedAmount: 0
			};
			forEach(items, (c) => {
				scheduleTotal.qty += c.qty;
				scheduleTotal.amount += round(c.qty * c.rate, 2);
				scheduleTotal.prevPeriodClaimQty += c.prevPeriodClaimQty;
				scheduleTotal.prevPeriodClaimAmount += c.prevPeriodClaimAmount;
				scheduleTotal.claimQty += c.claimQty;
				scheduleTotal.claimAmount += c.claimAmount;
				scheduleTotal.prevClaimQty += c.prevClaimQty;
				scheduleTotal.prevClaimAmount += c.prevClaimAmount;
				scheduleTotal.prevCertifiedQty += c.prevCertifiedQty;
				scheduleTotal.prevCertifiedAmount += c.prevCertifiedAmount;
				scheduleTotal.certifiedQty += c.certifiedQty;
				scheduleTotal.certifiedAmount += c.certifiedAmount || c.calcCertifiedAmount;
			});
			scheduleTotals.push(scheduleTotal);

			totalAmount += scheduleTotal.amount;
			totalPrevClaim += scheduleTotal.prevCertifiedAmount;
			totalContractorClaim += scheduleTotal.claimAmount;
			totalCertThisPeriod += scheduleTotal.certifiedAmount;
			totalCertToDate += (scheduleTotal.prevCertifiedAmount + scheduleTotal.certifiedAmount);
			totalDifference += (scheduleTotal.certifiedAmount - scheduleTotal.claimAmount);
		});
		
		if (tempClaimItems.length > 0) {
			const tempTotal = {
				name: 'Temporary Claim Items',
				qty: 0,
				amount: 0,
				prevPeriodClaimQty: 0,
				prevPeriodClaimAmount: 0,
				claimQty: 0,
				claimAmount: 0,
				prevClaimQty: 0,
				prevClaimAmount: 0,
				prevCertifiedQty: 0,
				prevCertifiedAmount: 0,
				certifiedQty: 0,
				certifiedAmount: 0
			};
			forEach(tempClaimItems, (c) => {
				tempTotal.claimAmount += c.claimAmount;
			});
			scheduleTotals.push(tempTotal);
			totalContractorClaim += tempTotal.claimAmount;
			totalDifference += -tempTotal.claimAmount;
		}

		return (
		<div>
			<div className="progress-claim-summary-data">
				<div className="section-header">
				</div>

				<table className="report-table">
					<thead>	
						<tr>
							<th>Schedule</th>
							<th>Amount</th>
							<th>Previous Claim</th>
							<th>Contractor Claim</th>
							<th>Certified this Period</th>
							<th>Certified to Date</th>
							<th>Difference</th>
						</tr>
					</thead>
					<tbody>
						{
							map(scheduleTotals, (scheduleTotal) =>
								<tr>
									<td>{scheduleTotal.name}</td>
									<td style={{ textAlign: 'right' }}>{toCurrency(scheduleTotal.amount)}</td>
									<td style={{ textAlign: 'right' }}>{toCurrency(scheduleTotal.prevCertifiedAmount)}</td>
									<td style={{ textAlign: 'right' }}>{toCurrency(scheduleTotal.claimAmount)}</td>
									<td style={{ textAlign: 'right' }}>{toCurrency(scheduleTotal.certifiedAmount)}</td>
									<td style={{ textAlign: 'right' }}>{toCurrency(scheduleTotal.prevCertifiedAmount + scheduleTotal.certifiedAmount)}</td>
									<td style={{ textAlign: 'right' }}>{toCurrency(scheduleTotal.certifiedAmount - scheduleTotal.claimAmount)}</td>
								</tr>
							)
						}
						<tr className="report-table-totals">
							<td>Total</td>
							<td style={{ textAlign: 'right' }}>{toCurrency(totalAmount)}</td>
							<td style={{ textAlign: 'right' }}>{toCurrency(totalPrevClaim)}</td>
							<td style={{ textAlign: 'right' }}>{toCurrency(totalContractorClaim)}</td>
							<td style={{ textAlign: 'right' }}>{toCurrency(totalCertThisPeriod)}</td>
							<td style={{ textAlign: 'right' }}>{toCurrency(totalCertToDate)}</td>
							<td style={{ textAlign: 'right' }}>{toCurrency(totalDifference)}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
    );
  }
}

ProgressClaimSummaryReportContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default ProgressClaimSummaryReportContent;
