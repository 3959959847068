import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import addReportWrapper from './reportWrapper';
import LotStatusByLotContent from './lotStatusByLotContent';

class LotStatusByLot extends React.Component {
	render() {
		const report = this.props.report || {};
		
		return (
			<LotStatusByLotContent report={report} />
		);
	}
}

LotStatusByLot.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addReportWrapper(
	LotStatusByLot, {
		reportUrl: '/api/reports/lot-status-report',
		downloadUrl: '/Reports/LotStatus',
		title: 'Lot Status Report',
		className: 'lot-status-report'
	})
);
