import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';

class ReportField extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			value: props.value,
			valid: this.isValid(props.value)
		};

		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value,
			valid: this.isValid(nextProps.value)
		});
	}
	isValid() {
		return true;
	}
	onChange(e, v) {
		if (this.props.editable === false) return;
		this.setState({
			value: v
		});
	}
	onBlur() {
		if (this.props.onChange) this.props.onChange(this.state.value);
	}
	render() {
		const val = this.state.value || '';
		let variant = 'standard';

		let background = '';
		if (!this.state.valid) {
			background = '#F4DCDE';
		}
		
		let classes = 'report-field';
		if (this.props.editable !== false) {
			classes += ' editable';
			variant = 'filled';
		}

		const style = {
			width: '100px', 
			height: '18px',
			background: background,
			...this.props.style
		};
		const inputStyle = {
			fontSize: '12px', 
			verticalAlign: 'top',
			padding: '2px',
			...this.props.inputStyle
		};

		return (
			<TextField 
				style={style} 
				variant={variant}
				inputStyle={inputStyle}
				className={classes}
				onChange={this.onChange} 
				onBlur={this.onBlur} 
				underlineShow={false} 
				value={val} 
			/>
		);
	}
}

ReportField.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	editable: PropTypes.bool,
	style: PropTypes.object.isRequired,
	inputStyle: PropTypes.object
};

ReportField.defaultProps = {
	value: undefined,
	onChange: () => {},
	inputStyle: {},
	editable: false
};

export default ReportField;
