import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { showNotification } from '../reducers/notifications';

class Notifications extends Component {
    constructor(props) {
        super(props);

		this.state = {
			show: false
		};
    }

	handleRequestClose() {
		this.props.hideNotification();
	}

	render() {
		const messageContent = (
			<div>
				<span style={{ marginLeft: '5px' }}>{this.props.message}</span>
			</div>
		);
		return (
      <div>
        <Snackbar
			open={this.props.show}
			message={messageContent}
			autoHideDuration={4000}
			onClose={() => this.handleRequestClose() }
        />
      </div>
		);
	}
}

Notifications.propTypes = {
	show: PropTypes.bool.isRequired,
	message: PropTypes.string,
	hideNotification: PropTypes.func.isRequired
};

Notifications.defaultProps = {
	message: ''
};

const mapStateToProps = (state) => ({
	show: state.notifications.show,
	message: state.notifications.message
});

const mapDispatchToProps = (dispatch) => ({
	hideNotification: () => {
		dispatch(showNotification(false));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Notifications);
