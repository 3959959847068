/* eslint react/jsx-no-duplicate-props: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { errorColour } from '../../variables';
import uniqueId from 'lodash/uniqueId';
import {
	KeyboardDatePicker, KeyboardDateTimePicker
} from '@material-ui/pickers';
import isValidDate from 'date-fns/isValid';
import {
	IconButton
} from '@material-ui/core';
import ActionDelete from '@material-ui/icons/Clear';

const DateField = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId('date-field-'));
	let { value } = props;
	if (!value) {
		value = null; // "" triggers invalid date validation, we just want to leave blank in this case
	}

	const setDate = newDate => {
		if (!isValidDate(newDate)) return;
		props.onChange(newDate);
	};
	
	let Picker =  KeyboardDatePicker;
	let format = "dd/MM/yyyy";
	if (props.showTime) {
		Picker = KeyboardDateTimePicker;
		format = "dd/MM/yyyy hh:mm a";
	}

	let helpText = props.helpText;
	if (props.errorText) {
		helpText = props.errorText;
	}

	return (
		<FormControl 
			fullWidth={props.fullWidth}
			error={props.error || Boolean(props.errorText)} 
			style={{
				borderTopLeftRadius: '4px',
				borderTopRightRadius: '4px',
				background: 'rgba(0, 0, 0, 0.09)',
				...props.style
			}} 
			className={`${props.className} date-field`}
		>
			{props.label &&
				<InputLabel htmlFor={id} shrink {...props.InputLabelProps} style={{ margin: '10px 0 0 10px' }}>
					{props.label}
					{props.required && <span style={{ color: errorColour }}> *</span>}
				</InputLabel>
			}
			<div style={{ marginTop: '0px' }}>
				<Picker
					id={id}
					fullWidth={props.fullWidth}
					placeholder={props.placeholder}
					disableToolbar 
					variant="inline"
					style={{ marginTop: '12px', ...props.datePickerStyle }}
					format={format}
					value={value}
					initialFocusedDate={props.defaultValue}
					onChange={setDate}
					InputProps={{
						startAdornment: (
							<IconButton onClick={() => props.onChange(null)} disabled={!value} style={{ order: 1, ...props.clearButtonStyle }}>
								<ActionDelete color="disabled" fontSize="small" />
							</IconButton>
						),
						disableUnderline: props.disableUnderline
					}}
					inputProps={{
						style: {
							paddingLeft: '8px',
							paddingTop: '10px',
							...props.inputStyle
						}
					}}
					InputAdornmentProps={{
						position: 'end',
						style: { order: 2, marginLeft: 0 }
					}}
				/>
			</div>
			{helpText && <FormHelperText>{helpText}</FormHelperText>}
		</FormControl>
	);
});

DateField.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.string
	]),
	onChange: PropTypes.func,
	required: PropTypes.bool,
	error: PropTypes.bool,
	placeholder: PropTypes.string,
	helpText: PropTypes.node,
	showTime: PropTypes.bool,
	disabled: PropTypes.bool,
	InputLabelProps: PropTypes.object,
	style: PropTypes.object,
	clearButtonStyle: PropTypes.object,
	fullWidth: PropTypes.bool,
	disableUnderline: PropTypes.bool,
	datePickerStyle: PropTypes.object,
	inputStyle: PropTypes.object
};

DateField.defaultProps = {
	id: '',
	name: '',
	type: 'text',
	label: '',
	onChange: () => {},
	required: false,
	error: false,
	placeholder: '',
	helpText: '',
	value: null,
	showTime: false,
	disabled: false,
	InputLabelProps: undefined,
	style: undefined,
	clearButtonStyle: {},
	fullWidth: false,
	disableUnderline: false,
	datePickerStyle: {},
	inputStyle: {}
};

export default DateField;
