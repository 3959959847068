import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';

class PeriodHandoverContent extends React.Component {
	render() {
		const period = this.props.report;

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Name" value={period.name} />
				<HandoverField label="Start Date" value={period.startDate} />
				<HandoverField label="End Date" value={period.endDate} />
			</div>
		</div>
    );
  }
}

PeriodHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default PeriodHandoverContent;
