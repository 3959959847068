import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { injectIntl } from 'react-intl';
import ContentContainer from '../contentContainer';
import Checkbox from '../widgets/Checkbox';
import Paper from '@material-ui/core/Paper';
import { toCurrency } from '../../utils/utils';
import ForecastScheduleCard from './ForecastScheduleCard';

class ForecastForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			scheduleOfRates: null,
			itemErrorText: ''
		};
    
		this.getScheduleItemByForecastItem = this.getScheduleItemByForecastItem.bind(this);
	}

	getScheduleItemByForecastItem(forecastItem) {
		for (let i = 0, ii = this.props.scheduleOfRates.length; i < ii; i++) {
			for (let j = 0, jj = this.props.scheduleOfRates[i].items.length; j < jj; j++) {
				const item = this.props.scheduleOfRates[i].items[j];
				if (item.scheduleOfRatesItemId === forecastItem.scheduleOfRatesItemId) return item;
			}
		}

		return null;
	}

	render() {
		const forecast = this.props.forecast || {};
		const forecastItems = forecast.items || [];

		const isOutOfLimits = (i) => {
			if (i._appliedForecastQty && i.limit) {
				const lowerLimit = (1 - i.limit) * i.qty * i.rate;
				const upperLimit = (1 + i.limit) * i.qty * i.rate;
				const forecastAmount = i._appliedForecastQty * i.rate;
				if (forecastAmount < lowerLimit || forecastAmount > upperLimit) {
					return true;
				}
			}

			return false;
		};
		let total = 0;
		forEach(forecastItems, (i) => {
			if (i.forecastQty !== null) {
				i._appliedForecastQty = i.forecastQty;
				i._difference =  (i.forecastQty * i.rate) - (i.qty * i.rate);
			} else if (i.prevForecastQty !== null) {
				i._appliedForecastQty = i.prevForecastQty;
				i._difference =  (i.prevForecastQty * i.rate) - (i.qty * i.rate);
			} else {
				i._appliedForecastQty = i.qty;
				i._difference =  0;
			}
			total += i._appliedForecastQty * i.rate;

			// Check limits of accuracy
			i._isOutOfLimits = isOutOfLimits(i);
		});

		// forecastItems = filter(forecastItems, i => i._appliedForecastQty !== i.qty);

		const itemsBySchedule = groupBy(forecastItems, (i) => i.scheduleGroup);

		return (
<div>
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={forecast.name}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('name', v); }}
				label="Name."
				errorText={getFieldErrorText(this.props.saveResult, 'Name')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m6: true, hasToggle: true }} infoText="There can be only one active forecast. Setting this forecast to active will make any other forecast inactive.">
			<Checkbox
				label="Is Active"
				labelPosition="right"
				checked={forecast.isActive}
				onCheck={(e, v) => { this.props.updateField('isActive', v); }}
			/>
		</FormFieldContainer>
			
		<FormFieldContainer>
			<TextField
				value={forecast.comments}
				fullWidth={true}
				multiline={true}
				onChange={(e, v) => { this.props.updateField('comments', v); }}
				label="Comments"
				errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
			/>
		</FormFieldContainer>
	</ContentContainer>

	{
		map(itemsBySchedule, (items) => {
			const scheduleName = items[0].scheduleGroup;
			
			return (
				<div>
					<ForecastScheduleCard
						scheduleName={scheduleName}
						items={items}
						updateForecastItem={this.props.updateForecastItem}
					/>
				</div>
			);
		})
	}

	<Paper 
		style={{ 
			float: 'right', 
			padding: '20px', 
			margin: '20px 0', 
			fontSize: '16px', 
			fontWeight: 'bold' 
		}}
	>
		<div>
			<span>Total: </span>
			<span>{toCurrency(total)}</span>
		</div>
	</Paper>
</div>
		);
	}
}

ForecastForm.propTypes = {
	scheduleOfRates: PropTypes.array,
	forecast: PropTypes.object,
	updateField: PropTypes.func.isRequired,
	updateForecastItem: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired
};

ForecastForm.defaultProps = {
	scheduleOfRates: [],
	forecast: undefined
};

export default injectIntl(ForecastForm);
