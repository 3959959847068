import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import PersistedDataTable from '../widgets/persistedDataTable';
import map from 'lodash/map';

class OutgoingCorrespondenceGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.correspondences || this.props.correspondences.length !== nextProps.correspondences.length;
	}

	render() {
		const correspondences = this.props.correspondences || [];

		const columns = [
			{ name: 'rfiNumber', label: 'Outgoing Ref Number' },
			{ 
				name: 'hasAttachment', 
				label: <AttachmentIcon />,
				fullLabel: 'Has Attachments',
				dataType: 'bool',
				value: args => args.data.files && args.data.files.length > 0,
				cellRenderer: args => 
					args.data.files && args.data.files.length > 0 ? <AttachmentIcon /> : <span></span>,
				cellStyle: {
					textAlign: 'center'
				},
				filterOperators: ['='],

			},
			{ name: 'receivedDate', label: 'Date', dataType: 'date' },
			{ name: 'docRefNo', label: 'Doc Ref No' },
			{ name: 'docTitle', label: 'Document Title', headerStyle: { minWidth: '300px' } },
			{ name: 'toName', label: 'To' },
			{ name: 'fromName', label: 'From' },
			{ 
				name: 'deliveryMethod', 
				label: 'Delivery Method',
				filterOperators: ['multi-select'],
				filterOptions: ['Email', 'Mail', 'Verbal']
			},
			{ name: 'sentDate', label: 'Sent Date', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.status !== 'Open') {
						statusBackColour = '#35aa47';
						statusForeColour = '#111';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed']
			},
			{
				name: 'tags',
				label: 'Tags',
				displayValue: (args) => 
					map(args.data.tags, (t) => t.name).join(','),
				filterOperators: ['multi-select'],
				filterOptions: this.props.tags,
				filterOptionsKey: 'tagId',
				filterOptionsText: 'name'
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="outgoingCorrespondence"
				data={correspondences} 
				columns={columns}
			/>
		);
	}
}

OutgoingCorrespondenceGrid.propTypes = {
	correspondences: PropTypes.array.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditCorrespondence: PropTypes.func.isRequired,
	onViewCorrespondence: PropTypes.func.isRequired,
	onDeleteCorrespondence: PropTypes.func.isRequired,
	tags: PropTypes.array
};

OutgoingCorrespondenceGrid.defaultProps = {
	tags: []
};

export default OutgoingCorrespondenceGrid;
