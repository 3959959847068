import React from 'react';
import PropTypes from 'prop-types';
import WizardSteps from './wizardSteps';
import Button from '../widgets/button';
import forEach from 'lodash/forEach';
import isString from 'lodash/isString';
import map from 'lodash/map';
import { 
	List, 
	ListItem, 
	ListItemText, 
	ListItemIcon, 
	Card, 
	CardContent 
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ContentContainer from '../contentContainer';

class Wizard extends React.Component {
	state = {
		currentStep: 1,
		stepsDone: 0,
		completeSteps: new Set(),
		inError: false,
		errorMessage: ""
	}

	onPrev = () => {
		const prevStep = this.state.currentStep > 1 ? this.state.currentStep - 1 : 1;
		this.setState({
			inError: false,
			errorMessage: "",
			currentStep: prevStep
		});
		if (this.props.onPrev) this.props.onPrev(prevStep);
	}

	onNext = () => {
		this.setState({
			inError: false
		}, () => {
			const currentStep = this.props.steps[this.state.currentStep - 1];
			let validateResult = true;
			if (currentStep.validate) validateResult = currentStep.validate();

			if (validateResult !== true) {
				this.setState({
					inError: true,
					errorMessage: isString(validateResult) ? validateResult : ""
				});
			} else {
				if (this.state.currentStep === this.props.steps.length) {
					this.props.onComplete(
						() => { /* onSuccess*/ },
						() => { /* onError */
							this.setState({
								inError: true
							});	
						}
					);
				} else {
					const nextStep = this.state.currentStep === this.props.steps.length ? this.state.currentStep : this.state.currentStep + 1;
					this.setState({
						inError: false,
						currentStep: nextStep,
						completeSteps: this.state.completeSteps.add(this.state.currentStep),
						stepsDone: this.state.currentStep > this.state.stepsDone ? this.state.currentStep : this.state.stepsDone
					});
					if (this.props.onNext) this.props.onNext(nextStep);
				}
			}
		});
	}

	render = () => {
		const steps = this.props.steps;
		let page = null;
		forEach(steps, (step, index) => {
			step.number = index + 1;
			step.current = index + 1 === this.state.currentStep;
			if (step.current) page = step.content;

			step.complete = index <= this.state.stepsDone;
			step.isLastStepDone = index === this.state.stepsDone;
			step.disabled = !step.current && index > this.state.stepsDone;
			step.error = step.current && this.state.inError;
		});

		const errors = this.props.errors || [];

		return (
			<div className="wizard">
				<ContentContainer style={{ overflow: 'auto' }}>
					<WizardSteps 
						steps={this.props.steps} 
						style={this.props.stepsStyle}
					/>
				</ContentContainer>

				<div className="wizard-page-content">
					{page}	
				</div>

				{this.state.inError && (this.state.errorMessage || errors.length > 0) &&
					<Card 	
						style={{
							marginTop: '10px',
							borderLeft: '5px solid #d1332e',
							backgroundColor: '#FFF5F4'
						}}
					>
						<CardContent style={{ padding: 0, color: "#f44336" }}>
							<List dense>
								<span 
									className="h5"
									style={{
										marginLeft: '10px',
										color: '#d1332e',
										fontWeight: 'bold'
									}}
								>
									{this.state.errorMessage || "Errors"}
								</span>

								{map(errors, (error, i) => {
									return (
										<ListItem key={`wiz-err-${i}`} style={{ paddingTop: 0 }}>
											<ListItemIcon>
												<ErrorIcon style={{ color: "#d1332e" }} />
											</ListItemIcon>
											<ListItemText primary={error}></ListItemText>
										</ListItem>
									);
								})}
							</List>
						</CardContent>
					</Card>
				}

				<div className="wizard-buttons">
					<Button onClick={this.props.onCancel} style={{ position: 'absolute', left: '20px' }}>Cancel</Button>
					<Button onClick={this.onPrev} style={{ marginRight: '10px' }}>Previous</Button>
					<Button 
						onClick={
							() => this.props.onNextConfirm ? this.props.onNextConfirm(this.state.currentStep, this.onNext) : this.onNext()
						} 
						success={this.props.success} 
						loading={this.props.loading} 
						variant="contained" 
						color="primary" 
						primary={true} 
					>
						{this.state.currentStep === steps.length ? this.props.submitLabel : 'Next'}
					</Button>
				</div>
			</div>
		);
	}
}

Wizard.propTypes = {
	steps: PropTypes.array.isRequired,
	onCancel: PropTypes.func,
	onComplete: PropTypes.func.isRequired,
	onPrev: PropTypes.func,
	onNext: PropTypes.func,
	submitLabel: PropTypes.string,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	stepsStyle: PropTypes.object
};

Wizard.defaultProps = {
	onCancel: () => {},
	onPrev: () => {},
	onNext: () => {},
	success: false,
	loading: false,
	submitLabel: 'Submit',
	stepsStyle: undefined
};

export default Wizard;
