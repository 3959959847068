import find from 'lodash/find';


export const getFieldErrorText = (validationResult, fieldName, data)  => {
	if (!validationResult) return null;
	if (!validationResult.fields) return null;

	const field = find(validationResult.fields, (f) => f.fieldName === fieldName && (!data || data === f.data));
	if (!field || field.valid) return null;
	return field.message;	
};

export const addFieldError = (validationResult, fieldName, errorMessage) => {
	const newResult = validationResult || {};
	if (!newResult.fields) newResult.fields = [];

	let field = find(newResult.fields, (f) => f.fieldName === fieldName);
	if (!field) {
		field = { fieldName: fieldName };
		newResult.fields.push(field);
	}
	field.valid = false;
	field.message = errorMessage;
	return newResult;
}
