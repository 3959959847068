import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';
import map from 'lodash/map';

class NCRsGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.ncrs || this.props.ncrs.length !== nextProps.ncrs.length;
	}

	render() {
		const ncrs = this.props.ncrs || [];

		const columns = [
			{ name: 'contractorNcrNo', label: 'NCR No' },
			{ name: 'carRefNo', label: 'CAR Ref No' },
			{ name: 'dateIdentified', label: 'Date Identified', dataType: 'date' },
			{ 
				name: 'dateNcrRaised', 
				label: 'Date NCR Raised', 
				dataType: 'date', 
				excludeFromGrid: true 
			},
			{ 
				name: 'lotRef', 
				label: 'Lot Ref',
				value: (args) => {
					if (!args.data.lots || args.data.lots.length === 0) return '';
					return map(args.data.lots, (l) => l.lotNo).join(', ');
				}
			},
			{ name: 'details', label: 'Details of Non-Conformance', minWidth: '300px' },
			{ name: 'correctiveAction', label: 'Corrective Action', minWidth: '300px' },
			{ name: 'preventativeAction', label: 'Preventative Action', minWidth: '300px' },
			{ name: 'comments', label: 'Comments', minWidth: '200px' },
			{ 
				name: 'accepted', 
				label: 'Acceptance/Rejection',
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.accepted) {
						statusBackColour = '#35aa47';
						statusForeColour = '#111';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				value: (args) => args.data.accepted ? 'Accepted' : 'Rejected'
			},
			{ 
				name: 'responseDate', 
				label: 'Response Date', 
				dataType: 'date', 
				hideByDefault: true 
			},
			{ 
				name: 'dateResubmitted', 
				label: 'Date Resubmitted', 
				dataType: 'date', 
				hideByDefault: true 
			},
			{ name: 'dateClosed', label: 'Date Closed', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.status !== 'Open') {
						statusBackColour = '#35aa47';
						statusForeColour = '#111';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed']
			},
			{
				name: 'tags',
				label: 'Tags',
				displayValue: (args) => 
					map(args.data.tags, (t) => t.name).join(','),
				filterOperators: ['multi-select'],
				filterOptions: this.props.tags,
				filterOptionsKey: 'tagId',
				filterOptionsText: 'name'
			},
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) =>
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditNCR(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteNCR(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) =>
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewNCR(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="ncrs"
				data={ncrs} 
				columns={columns}
			/>
		);
	}
}

NCRsGrid.propTypes = {
	ncrs: PropTypes.array,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditNCR: PropTypes.func.isRequired,
	onViewNCR: PropTypes.func.isRequired,
	onDeleteNCR: PropTypes.func.isRequired,
	tags: PropTypes.array
};

NCRsGrid.defaultProps = {
	ncrs: [],
	tags: []
};

export default NCRsGrid;
