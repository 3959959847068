import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import SubContentContainer from '../subContentContainer';
import FloatingActionButton from '@material-ui/core/Fab';
import ContentAdd from '@material-ui/icons/Add';
import ScheduleItemDialog from './scheduleItemDialog';
import ScheduleFormItems from './scheduleFormItems';
import uniqueId from 'lodash/uniqueId';
import forEach from 'lodash/forEach';
import startsWith from 'lodash/startsWith';
import { fetch, addTask } from 'domain-task';
import ContentContainer from '../contentContainer';
import Checkbox from '../widgets/Checkbox';

const newItem = {
	_isNew: true,
	itemNo: '',
	description: '',
	unit: '',
	qty: 0,
	rate: 0,
	forecastQty: null
};

class ScheduleForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			showScheduleItemDialog: false,
			scheduleItem: { ...newItem }
		};
    
		this.addScheduleItem = this.addScheduleItem.bind(this);
		this.editScheduleItem = this.editScheduleItem.bind(this);
		this.viewScheduleItem = this.viewScheduleItem.bind(this);
		this.onAddScheduleItem = this.onAddScheduleItem.bind(this);
		this.onCancelScheduleDialog = this.onCancelScheduleDialog.bind(this);
	}

	addScheduleItem() {
		this.setState({ 
			showScheduleItemDialog: true,
			scheduleItem: { 
				...newItem,
				_clientKey: uniqueId('scheduleOfRatesItemId_')
			}
		});

		const fetchTask =  fetch(`/api/schedule-of-rates/next-variation-item-number?contractId=${this.props.contractId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.text();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				let nextItemNumber = Number(data.substr(this.props.settings.variationItemNumberPrefix.length)) || 1;

				forEach(this.props.schedule.items, (i) => {
					if (startsWith(i.itemNo, this.props.settings.variationItemNumberPrefix)) {
						const itemNumber = Number(i.itemNo.substr(this.props.settings.variationItemNumberPrefix.length)) || 0;
						if (itemNumber >= nextItemNumber) nextItemNumber = itemNumber + 1;
					}
				});

				this.setState({
					...this.state,
					scheduleItem: {
						...this.state.scheduleItem,
						itemNo: `${this.props.settings.variationItemNumberPrefix}${nextItemNumber}`
					}
				});
			}).catch((error) => {
				console.log('request failed', error);
			});
					
		addTask(fetchTask);
	}

	editScheduleItem(item) {
		this.setState({ 
			showScheduleItemDialog: true,
			scheduleItem: { 
				...item
			}
		});
	}
	viewScheduleItem(item) {
		this.setState({ 
			showScheduleItemDialog: true,
			scheduleItem: { 
				...item
			}
		});
	}

	onAddScheduleItem(item) {
		this.props.updateScheduleItem(item);

		this.setState({ 
			showScheduleItemDialog: false 
		});
	}

	onCancelScheduleDialog() {
		this.setState({ showScheduleItemDialog: false });
	}	

	render() {
		const schedule = this.props.schedule;

		return (
<div>

	<ScheduleItemDialog 
		show={this.state.showScheduleItemDialog} 
		canEdit={this.props.canEditAll}
		scheduleItem={this.state.scheduleItem}
		addScheduleItem={this.onAddScheduleItem} 
		cancel={this.onCancelScheduleDialog} 
	/>
	
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={schedule.name}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('name', v); }}
				label="Schedule No."
				errorText={getFieldErrorText(this.props.saveResult, 'Name')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m6: true, hasToggle: true }}>
			<Checkbox
				label="Is Active"
				labelPosition="right"
				checked={schedule.active}
				onCheck={(e, v) => { this.props.updateField('active', v); }}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m6: true, hasToggle: true }}>
			<Checkbox
				label="Can Be Used In Separable Portions"
				labelPosition="right"
				checked={schedule.canBeUsedInSeparablePortions}
				onCheck={(e, v) => { this.props.updateField('canBeUsedInSeparablePortions', v); }}
				disabled={!this.props.canEditAll}
			/>
		</FormFieldContainer>
	</ContentContainer>

	<SubContentContainer header="Items">
		<ScheduleFormItems 
			items={this.props.schedule.items} 
			canEdit={this.props.canEditAll}
			deleteScheduleItem={this.props.deleteScheduleItem} 
			editScheduleItem={this.editScheduleItem} 
			viewScheduleItem={this.viewScheduleItem} 
		/>

		{this.props.canEditAll &&
			<div className="row">
				<FloatingActionButton onClick={this.addScheduleItem} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
					<ContentAdd />
				</FloatingActionButton>
			</div>
		}

	</SubContentContainer>
</div>
		);
	}
}


ScheduleForm.propTypes = {
	settings: PropTypes.object.isRequired,
	schedule: PropTypes.object.isRequired,
	contractId: PropTypes.number.isRequired,
	updateField: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canEditAll: PropTypes.bool.isRequired,
	updateScheduleItem: PropTypes.func.isRequired,
	deleteScheduleItem: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default ScheduleForm;
