import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';

class SeparablePortionsGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.separablePortions || this.props.separablePortions.length !== nextProps.separablePortions.length;
	}

	render() {
		const separablePortions = this.props.separablePortions || [];

		const columns = [
			{ name: 'name', label: 'Name' },
			{ name: 'practicalCompletionDate', label: 'Practical Completion Date', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					switch (args.data.status) {
						case 'Closed':
							statusBackColour = '#C6EFCE';
							statusForeColour = '#2C6153';
							break;
						default:
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed']
			},
			{ 
				name: 'totalValue', 
				label: 'Total Value', 
				dataType: 'number', 
				formatString: 'currency' 
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) =>
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditSeparablePortion(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteSeparablePortion(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) =>
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewSeparablePortion(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="separablePortions"
				data={separablePortions} 
				columns={columns}
			/>
		);
	}
}

SeparablePortionsGrid.propTypes = {
	separablePortions: PropTypes.array.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditSeparablePortion: PropTypes.func.isRequired,
	onViewSeparablePortion: PropTypes.func.isRequired,
	onDeleteSeparablePortion: PropTypes.func.isRequired
};

export default SeparablePortionsGrid;
