import { getPermissions } from './permissions';

export const canEdit = (state, editPermission) => {
	if (state.context.contract && state.context.contract.contractStatus === 'Closed') return false;
	const permissions = getPermissions(state);
	const hasAccess = permissions.hasFullAccess || permissions[editPermission];
	if (!state.context.period) return hasAccess;
	return !!(state.context.period.status === 'Open' && hasAccess);
};

export const canEditAnyPeriod = (state, editPermission) => {
	if (state.context.contract && state.context.contract.contractStatus === 'Closed') return false;
	const permissions = getPermissions(state);
	const hasAccess = permissions.hasFullAccess || permissions[editPermission];
	if (!state.context.period) return hasAccess;
	return !!(hasAccess);
};
