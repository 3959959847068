import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import map from 'lodash/map';

const initialState = {
	isLoading: false,
	handover: null,
	logs: [],
	isLogsLoading: false
};

const CLEAR_HANDOVER = 'CLEAR_HANDOVER';
const REQUEST_HANDOVER = 'REQUEST_HANDOVER';
const RECEIVE_HANDOVER = 'RECEIVE_HANDOVER';
const INITIATE_HANDOVER = 'INITIATE_HANDOVER';
const SHOW_HANDOVER_ERRORS = 'SHOW_HANDOVER_ERRORS';
const REQUEST_HANDOVER_LOGS = 'REQUEST_HANDOVER_LOGS';
const RECEIVE_HANDOVER_LOGS = 'RECEIVE_HANDOVER_LOGS';

export const clearHandover = () => ({ type: CLEAR_HANDOVER });
export const receiveHandover = (data) => ({ type: RECEIVE_HANDOVER, payload: { data } });
export const receiveHandoverLogs = (data) => ({ type: RECEIVE_HANDOVER_LOGS, payload: { data } });

export const requestHandover = (contractId, onSuccess) => (
	createFetchAction({
		objectName: 'Handover',
		passContext: false,
		url: `/api/handover/${contractId}`,
		startAction: REQUEST_HANDOVER,
		success: (data) => {
			const handover = data ? {
				...data,
				dateCommenced: data.dateCommenced ? new Date(data.dateCommenced) : null,
				dateComplete: data.dateComplete ? new Date(data.dateComplete) : null
			} : null;
			if (onSuccess) onSuccess.call(this, handover);
			return receiveHandover(handover);
		}
	})
);

export const requestHandoverLogs = (handoverId) => (
	createFetchAction({
		objectName: 'Handover Logs',
		passContext: false,
		url: `/api/handover/${handoverId}/logs?topCount=50`,
		startAction: REQUEST_HANDOVER_LOGS,
		success: (data) =>
			receiveHandoverLogs(map(data, (l) => ({
				...l,
				logTime: l.logTime ? new Date(l.logTime) : null
			}))),
		onError: (error) => {}
	})
);

export const initiateHandover = (contractId, includeAttachments, cleanUpFirst, onSuccess) =>
	createPostAction({
		url: `/api/handover/${contractId}/initiate`,
		data: {
			includeAttachments,
			cleanUpFirst
		},
		startAction: INITIATE_HANDOVER,
		success: (data, dispatch) => {
			dispatch(receiveHandover({
				...data,
				object: data.object ? {
					...data.object,
					dateCommenced: new Date(data.object.dateCommenced),
					dateComplete: new Date(data.object.dateComplete)
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_HANDOVER:
			return {
				...state,
				handover: null,
				logs: []
			};
		case REQUEST_HANDOVER:
			return {
				...state,
				isLoading: true,
				logs: []
			};
		case RECEIVE_HANDOVER:
			return {
				...state,
				isLoading: false,
				handover: action.payload.data
			};
		case INITIATE_HANDOVER:
			return {
				...state,
				isLoading: true,
				message: null,
				logs: []
			};
		case SHOW_HANDOVER_ERRORS:
			return {
				...state,
				isLoading: false
			};
		case REQUEST_HANDOVER_LOGS:
			return {
				...state,
				isLogsLoading: true
			};
		case RECEIVE_HANDOVER_LOGS:
			return {
				...state,
				isLogsLoading: false,
				logs: action.payload.data
			};
		default:
			return state;
	}
};
