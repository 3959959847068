import find from 'lodash/find';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { globals } from '../globals';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	user: null, 
	userList: null
};

const CLEAR_USERS = 'CLEAR_USERS';
const CLEAR_USER = 'CLEAR_USER';
const FETCH_USER = 'FETCH_USER';
const DELETE_USER = 'DELETE_USER';
const REQUEST_USERS = 'REQUEST_USERS';
const RECEIVE_USERS = 'RECEIVE_USERS';
const REQUEST_USER = 'REQUEST_USER';
const RECEIVE_USER = 'RECEIVE_USER';
const CREATE_NEW_USER = 'CREATE_NEW_USER';
const SAVE_USER = 'SAVE_USER';
const RECEIVE_SAVE_USER_RESPONSE = 'RECEIVE_SAVE_USER_RESPONSE';
const RECEIVE_DELETE_USER_RESPONSE = 'RECEIVE_DELETE_USER_RESPONSE';
const START_USER = 'START_USER';
const RECEIVE_START_USER_RESPONSE = 'RECEIVE_START_USER_RESPONSE';
const SHOW_USER_ERRORS = 'SHOW_USER_ERRORS';

export const clearUsers = () => ({ type: CLEAR_USERS });
export const clearUser = () => ({ type: CLEAR_USER });
export const fetchUser = (userId) => ({ type: FETCH_USER, userId });
export const receiveUsers = (data) => ({ type: RECEIVE_USERS, payload: { data } });
export const receiveUser = (data) => ({ type: RECEIVE_USER, payload: { data } });
export const createNewUser = () => ({ type: CREATE_NEW_USER });
export const receiveSaveUserResponse = (data) => ({ type: RECEIVE_SAVE_USER_RESPONSE, data });
export const receiveDeleteUserResponse = (data) => ({ type: RECEIVE_DELETE_USER_RESPONSE, data });
export const receiveStartUserResponse = (data) => ({ type: RECEIVE_START_USER_RESPONSE, data });

export const requestUsers = () => (
	createFetchAction({
		objectName: 'Users',
		url: '/api/users',
		startAction: REQUEST_USERS,
		success: (data) => receiveUsers(data)
	})
);

export const requestAllUsers = () => (
	createFetchAction({
		objectName: 'Users',
		url: '/api/users/all-users',
		startAction: REQUEST_USERS,
		success: (data) => receiveUsers(data)
	})
);

export const requestUser = (userId) => (
	createFetchAction({
		objectName: 'User',
		url: `/api/users/${userId}`,
		startAction: REQUEST_USER,
		success: (data) => receiveUser(data)
	})
);

const validateUser = (user) => {
	const errors = [];
	if (!user.userName) {
		errors.push({
			fieldName: 'UserName',
			valid: false,
			message: 'User Name is required'
		});
	}
	if (user._isNew) {
		if (!user.password) {
			errors.push({
				fieldName: 'Password',
				valid: false,
				message: 'Password is required'
			});
		} else if (user.password.length < 8) {
			errors.push({
				fieldName: 'Password',
				valid: false,
				message: 'The password must be at least 8 characters long'
			});
		}
	}
	if (!user.email) {
		errors.push({
			fieldName: 'Email',
			valid: false,
			message: 'Email is required'
		});
	}
	if (!user.userRole) {
		errors.push({
			fieldName: 'UserRole',
			valid: false,
			message: 'Role is required'
		});
	}
	return errors;
};

export const saveUser = (user, onSuccess) => {
	const errors = validateUser(user);
	if (errors.length > 0) return { type: SHOW_USER_ERRORS, data: errors };

	return createPostAction({
		url: '/api/users',
		data: user,
		startAction: SAVE_USER,
		success: (data, dispatch) => {
			dispatch(receiveSaveUserResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteUser = (user) => (
	createPostAction({
		url: `/api/users/${user.userId}/delete`,
		startAction: DELETE_USER,
		success: (data) => receiveDeleteUserResponse(data)
	})
);

export default (originalState = initialState, action) => {
	let state = originalState;
	if (!state._hydrated) {
		state = { ...initialState, ...state, _hydrated: true };
	}
	
	switch (action.type) {
		case CLEAR_USERS:
			return {
				...state,
				userList: null
			};
		case CLEAR_USER:
			return {
				...state,
				user: null
			};
		case REQUEST_USERS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				userList: []
			};
		case RECEIVE_USERS:
			return {
				...state,
				isLoading: false,
				userList: action.payload.data
			};
		case REQUEST_USER:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				user: { ...cloneDeep(globals.templates.user) }
			};
		case RECEIVE_USER:
			return {
				...state,
				isLoading: false,
				user: action.payload.data
			};
		case DELETE_USER:
			return { 
				...state
			};
		case RECEIVE_DELETE_USER_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message
					}, 
				};
			}
			return {
				...state,
				isLoading: false,
				userList: state.userList.filter(c => c.userId !== action.data.objectId) 
			};
		case FETCH_USER:
			return { 
				...state,
				user: find(state.userList, (c) => c.userId === action.userId)
			};
		case CREATE_NEW_USER:
			return {
				...state,
				user: { ...cloneDeep(globals.templates.user) }
			};	
		case SAVE_USER:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_USER_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				user: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case START_USER:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case SHOW_USER_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
