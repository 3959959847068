import map from 'lodash/map';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { addOrUpdate } from '../utils/utils';

const emptyPeriod = {
	'periodId': 0,
	'contractId': 0,
	'name': '',
	'status': 'Preliminary',
	'startDate': (new Date()),
	'endDate': null,
	'closedBy': null
};

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	periods: null,
	period: null
};

const CLEAR_PERIODS = 'CLEAR_PERIODS';
const REQUEST_PERIODS = 'REQUEST_PERIODS';
const RECEIVE_PERIODS = 'RECEIVE_PERIODS';
const REQUEST_PERIOD = 'REQUEST_PERIOD';
const RECEIVE_PERIOD = 'RECEIVE_PERIOD';
const SHOW_PERIOD_ERRORS = 'SHOW_PERIOD_ERRORS';
const RECEIVE_PERIOD_ERROR_RESPONSE = 'RECEIVE_PERIOD_ERROR_RESPONSE';

export const clearPeriods = () => ({ type: CLEAR_PERIODS });
export const receivePeriods = (data) => ({ type: RECEIVE_PERIODS, payload: { data } });
export const receivePeriod = (data) => ({ type: RECEIVE_PERIOD, payload: { data } });
export const receiveErrorResponse = (error) => ({ type: RECEIVE_PERIOD_ERROR_RESPONSE, error });

const CLEAR_PERIOD = 'CLEAR_PERIOD';
export const clearPeriod = () => ({ type: CLEAR_PERIOD });

export const requestPeriods = () => (
	createFetchAction({
		objectName: 'Periods',
		url: '/api/periods',
		passContext: true,
		startAction: REQUEST_PERIODS,
		success: (data) => 
			receivePeriods(map(data, (p) => ({
				...p,
				startDate: p.startDate ? new Date(p.startDate) : null,
				endDate: p.endDate ? new Date(p.endDate) : null
			})))
	})
);

export const requestPeriod = (periodId) => (
	createFetchAction({
		objectName: 'Period',
		url: `/api/periods/${periodId}`,
		startAction: REQUEST_PERIOD,
		success: (data) => 
			receivePeriod({
				...data,
				startDate: data.startDate ? new Date(data.startDate) : null,
				endDate: data.endDate ? new Date(data.endDate) : null
			})
	})
);

const validatePeriod = (period) => {
	const errors = [];
	if (!period.name) {
		errors.push({
			fieldName: 'Name',
			valid: false,
			message: 'Name is required'
		});
	}
	return errors;
};

const SAVE_PERIOD = 'SAVE_PERIOD';
const RECEIVE_SAVE_PERIOD_RESPONSE = 'RECEIVE_SAVE_PERIOD_RESPONSE';
export const receiveSavePeriodResponse = (data) => ({ type: RECEIVE_SAVE_PERIOD_RESPONSE, data });
export const savePeriod = (period, onSuccess) => {
	const errors = validatePeriod(period);
	if (errors.length > 0) return { type: SHOW_PERIOD_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: `/api/periods/${period.periodId}`,
		data: period,
		startAction: SAVE_PERIOD,
		onError: (response) => receiveErrorResponse(response),
		success: (data, dispatch) => {
			const result = {
				...data,
				object: data.object ? {
					...data.object,
					startDate: data.object.startDate ? new Date(data.object.startDate) : null,
					endDate: data.object.endDate ? new Date(data.object.endDate) : null
				} : data.object
			};
			dispatch(receiveSavePeriodResponse(result));
			if (result.success && result.success && onSuccess) onSuccess.call(this, result);
		}
	});
};

export default (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_PERIOD_ERROR_RESPONSE:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: action.error.message,
					errors: [] 
				}
			};
		case CLEAR_PERIODS:
			return {
				...state,
				periods: null
			};
		case CLEAR_PERIOD:
			return {
				...state,
				period: null
			};
		case REQUEST_PERIODS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				periods: []
			};
		case RECEIVE_PERIODS:
			return {
				...state,
				isLoading: false,
				periods: action.payload.data
			};
		case REQUEST_PERIOD:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				period: cloneDeep(emptyPeriod)
			};
		case RECEIVE_PERIOD:
			return {
				...state,
				isLoading: false,
				period: action.payload.data
			};
		case SAVE_PERIOD:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_PERIOD_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				periods: addOrUpdate(state.periods, action.data.object, { periodId: action.data.object.periodId }), 
				period: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		default:
			return state;
	}
};
