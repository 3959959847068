import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import MessagePanel from '../messagePanel';
import ContentContainer from '../contentContainer';
import PageLayout from '../pageLayout';
import { 
	Grid,
} from '@material-ui/core';

const form = {
	initValues: props => props.tenant,
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
		},
		{
			name: "isActive",
			label: "Is Active",
			type: 'checkbox'
		},
		{
			name: "comments",
			label: "Comments",
			multiline: true
		},
		{
			name: "maxContracts",
			type: 'numeric',
			label: "Maximum number of Contracts allowed",
			helpText: "Set to 0 for no limit"
		},
		{
			name: "maxUsers",
			type: 'numeric',
			label: "Maximum number of Users allowed",
			helpText: "Set to 0 for no limit"
		},
		{
			name: "maxData",
			type: 'numeric',
			numberType: 'decimal',
			label: "Maximum GB of data the tenant is allowed",
			helpText: "Set to 0 for no limit"
		}
	]
};

const EditTenant = ({ 
	isLoading, 
	canEdit, 
	fields,
	values,
	dirty,
	saveResult,
	onSave,
	onSaveAndContinue,
	onCancel
}) => {
	return (
<PageLayout
	title={values.Name}
	isLoading={isLoading}
	formActions={{
		isLoading: isLoading,
		canEdit: canEdit,
		hasUnsavedChanges: dirty,
		onSave: () => onSave(values),
		onSaveAndContinue: () => onSaveAndContinue(values),
		onCancel: () => onCancel() 
	}}
	content={
		<div className="row">
			<ContentContainer>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.name()}</Grid>
					<Grid item xs={12}>{fields.isActive()}</Grid>
					<Grid item xs={12}>{fields.comments()}</Grid>
					<Grid item xs={12} md={6}>{fields.maxContracts()}</Grid>
					<Grid item xs={12} md={6}>{fields.maxUsers()}</Grid>
					<Grid item xs={12} md={6}>{fields.maxData()}</Grid>
				</Grid>
			</ContentContainer>

			<MessagePanel isSuccess={saveResult.success} message={saveResult.message} />
		</div>
	}
/>
	);
}

EditTenant.propTypes = {
	contract: PropTypes.object.isRequired,
	contractors: PropTypes.array,
	employees: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onSaveAndContinue: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

EditTenant.defaultProps = {
};

export default withForm(form)(EditTenant);
