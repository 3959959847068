import React from 'react';
import PropTypes from 'prop-types';
import ReportField from '../widgets/reportField';
import ReportNumericField from '../widgets/reportNumericField';
import ReportMemoField from '../widgets/reportMemoField';
import ReportCurrencyField from '../widgets/reportCurrencyField';
import ReportDateField from '../widgets/reportDateField';
import map from 'lodash/map';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import { globals } from '../../globals';

const FinalCertificateContent = props => {
	const cert = props.report || {};
	const updateField = props.updateField;

	const currentContractValue = cert.originalTender + cert.variations || 0;
	const valueOfWorkThisPeriod = cert.valueOfCompletedWork - cert.prevValueOfCompletedWork || 0;
	const adjustedValue = valueOfWorkThisPeriod + cert.otherCosts + cert.riseFallAdjustment - cert.cashRetention + cert.retentionRefund || 0;
	const gstAmount = adjustedValue * globals.gstRate || 0;
	const adjustedValuePlusGST = adjustedValue + gstAmount || 0;
	const liquidatedDamages = cert.deductionsThisPay;
	const paymentAmount = adjustedValuePlusGST - liquidatedDamages || 0;
	const claimAmountInclGST = cert.claimAmount; // GST added on server so user can edit field without issues
	const paymentDiff = paymentAmount - claimAmountInclGST || 0;
	const separablePortions = cert.separablePortions || [];
	
	return (
		<React.Fragment>
			<div className='report-box-full'>
				<div className='report-row'>
					<span className='contract-name-label'>Contract No.</span>
					<span className='contract-name'>{cert.contractNumber}</span>
					<span className='status'>{cert.status}</span>
				</div>
				<div className='report-row'>
					<span className='contract-location'>{cert.contractName}</span>
				</div>
			</div>

			<table className='report-box-container'>
				<tbody>
					<tr>
						<td className='report-box-left bordered' style={{ padding: '10px 5px' }}>
							<div className='report-row'>
								<span className='report-box-label' style={{ width: '245px' }}>Date Final Payment Claim Received:</span>
								<span className='report-box-value'><ReportDateField value={cert.dateFinalPaymentClaimReceived} style={{ width: '95px' }} editable={true} onChange={(v) => updateField('dateFinalPaymentClaimReceived', v)} /></span> 
							</div>
							<div className='report-row'>
								<span className='report-box-label' style={{ width: '245px' }}>Payment Claim Number:</span>
								<span className='report-box-value'><ReportField value={cert.paymentClaimNumber} style={{ width: '95px' }} onChange={(v) => updateField('paymentClaimNumber', v)} /></span>
							</div>
						</td>
						<td className='report-box-spacer'></td>
						<td className='report-box-right bordered'>
							<div className='report-row'>
								<span className='report-box-label' style={{ width: '75px' }}>Contractor:</span>
								<span className='report-box-value' style={{ width: '250px', display: 'inline-block' }}>
									<ReportField value={cert.contractor} style={{ width: '255px' }} onChange={(v) => updateField('contractor', v)} />
								</span>
							</div>				
							<div className='report-row'>
								<span className='report-box-label' style={{ width: '75px' }}></span>
								<span className='report-box-value' style={{ width: '250px', display: 'inline-block' }}>
									<ReportMemoField fullWidth value={cert.contractorAddress || ''} onChange={(v) => updateField('contractorAddress', v)} />
								</span>
							</div>				
						</td>
					</tr>
				</tbody>
			</table>

			<table className="report-sections">
				<tbody>
					<tr>
						<td className="left-section">
							<div className="contract-value-details">
								<div className="section-header">
									<span>Contract Value Details</span>
								</div>
								<div className='report-row'>
									<span className='label'>Original Tender</span>
									<span className='value'><ReportCurrencyField value={cert.originalTender} editable={false} onChange={(v) => updateField('originalTender', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Variations (Approved)</span>
									<span className='value'><ReportCurrencyField value={cert.variations} editable={false} onChange={(v) => updateField('variations', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Current Contract Value</span>
									<span className='value'><ReportCurrencyField value={currentContractValue} editable={false} /></span>
								</div>
							</div>
							<div>
								<div className="date-for-practical-completion">
									<div className="section-header">
										<span>Date for Practical Completion</span>
									</div>
									<div className='report-row'>
										<span className='label'>Original Date for Practical Completion</span>
										<span className='value'><ReportDateField value={cert.originalCompletionDate} editable={false} onChange={(v) => updateField('originalCompletionDate', v)} /></span>
									</div>
									<div className='report-row'>
										<span className='label'>Extension of Time (Days)</span>
										<span className='value'><ReportNumericField value={cert.extensionOfTime || 0} editable={false} onChange={(v) => updateField('extensionOfTime', v)} /></span>
									</div>
								</div>
								<div className="date-of-practical-completion">
									<div className='report-row'>
										<span className='label'>Date of Practical Completion</span>
										<span className='value'><ReportDateField value={cert.dateOfPracticalCompletion} editable={false} onChange={(v) => updateField('dateForPracticalCompletion', v)} /></span>
									</div>
									<div className='report-row'>
										<span className='label'>Expiry of Defects Liability Period</span>
										<span className='value'><ReportDateField value={cert.expiryOfDefectsLiability} editable={false} onChange={(v) => updateField('expiryOfDefectsLiability', v)} /></span>
									</div>
								</div>
							</div>
							<div className="liquidated-damages">
								<div className="section-header">
									<span>Liquidated Damages</span>
								</div>
								<div className='report-row'>
									<span className='label'>Applies From</span>
									<span className='value'><ReportDateField value={cert.dateForPracticalCompletion ? addDays(parseISO(cert.dateForPracticalCompletion), 1) : null} editable={false} onChange={(v) => updateField('appliesFrom', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Amount per Day</span>
									<span className='value'><ReportCurrencyField value={cert.amountPerDay} editable={false} onChange={(v) => updateField('amountPerDay', v)} /></span>
								</div>
								<div className='report-row-spacer'>
								</div>
								<div className='report-row'>
									<span className='label'>Previous Deductions</span>
									<span className='value'><ReportCurrencyField value={cert.prevDeductions} editable={false} onChange={(v) => updateField('previousDeductions', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Deductions this Pay</span>
									<span className='value'><ReportCurrencyField value={cert.deductionsThisPay} onChange={(v) => updateField('deductionsThisPay', v)} /></span>
								</div>
							</div>
						</td>
						<td className="right-section">
							<div className="payment-summary">
								<div className="section-header">
									<span>Payment Summary</span>
								</div>
								<div className='report-row'>
									<span className='label'>Value of Completed Work</span>
									<span className='value'><ReportCurrencyField value={cert.valueOfCompletedWork} editable={false} onChange={(v) => updateField('valueOfCompletedWork', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Less value Completed Last Payment</span>
									<span className='value' style={{ borderBottom: '1px solid red' }}><ReportCurrencyField value={cert.prevValueOfCompletedWork} editable={false} onChange={(v) => updateField('prevValueOfCompletedWork', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Value of Work this Period</span>
									<span className='value'><ReportCurrencyField value={valueOfWorkThisPeriod} editable={false} onChange={(v) => updateField('valueOfWorkThisPeriod', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Plus Other Costs/Deductions</span>
									<span className='value'><ReportCurrencyField value={cert.otherCosts} onChange={(v) => updateField('otherCosts', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Plus Rise Fall Cost Adjustment</span>
									<span className='value'><ReportCurrencyField value={cert.riseFallAdjustment} onChange={(v) => updateField('riseFallAdjustment', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Less Cash Retention</span>
									<span className='value'><ReportCurrencyField value={cert.cashRetention} onChange={(v) => updateField('cashRetention', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Plus Retention Refund</span>
									<span className='value' style={{ borderBottom: '1px solid red' }}><ReportCurrencyField value={cert.retentionRefund} onChange={(v) => updateField('retentionRefund', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Adjusted Value</span>
									<span className='value'><ReportCurrencyField value={adjustedValue} editable={false} onChange={(v) => updateField('adjustedValue', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Plus GST</span>
									<span className='value' style={{ borderBottom: '1px solid red' }}><ReportCurrencyField value={gstAmount} editable={false} onChange={(v) => updateField('plusGST', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Adjusted Value Plus GST</span>
									<span className='value'><ReportCurrencyField value={adjustedValuePlusGST} editable={false} onChange={(v) => updateField('adjustedValuePlusGST', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Liquidated Damages</span>
									<span className='value' style={{ borderBottom: '2px solid red' }}><ReportCurrencyField value={liquidatedDamages} editable={false} onChange={(v) => updateField('liquidatedDamages', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Payment Amount to be Made</span>
									<span className='value'><ReportCurrencyField value={paymentAmount} editable={false} onChange={(v) => updateField('paymentAmount', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Claim Amount incl GST</span>
									<span className='value' style={{ borderBottom: '1px solid red' }}><ReportCurrencyField value={claimAmountInclGST} editable={true} onChange={(v) => updateField('claimAmount', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='label'>Difference</span>
									<span className='value'><ReportCurrencyField value={paymentDiff} editable={false} onChange={(v) => updateField('difference', v)} /></span>
								</div>
								<div className='report-row'>
									<span className='payment-remarks-label' style={{ verticalAlign: 'top' }}>Payment Remarks</span>
									<span className='payment-remarks-value'><ReportMemoField value={cert.paymentRemarks || ''} style={{ width: '320px' }} onChange={(v) => updateField('paymentRemarks', v)} /></span>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<table className='signature-table'>
				<tbody>
					<tr className='first-row'>
						<td>I certify the Payment which, in my opinion, is to be made by the {paymentAmount >= 0 ? "Principal to the Contractor" : "Contractor to the Principal"}</td>
					</tr>
					<tr style={{ height: '60px', verticalAlign: 'bottom' }}>
						<td className='super-sig'><span>  /&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/  </span></td>
					</tr>
					<tr style={{ textAlign: 'center' }}>
						<td><span>Administrator / Administrator&#39;s Representative</span></td>
					</tr>
				</tbody>
			</table>

			<div className='report-notes'>
				<ReportMemoField value={cert.notes || ''} style={{ width: '735px' }} onChange={(v) => updateField('notes', v)} />
			</div>
		</React.Fragment>
	);
};

FinalCertificateContent.propTypes = {
	report: PropTypes.object,
	updateField: PropTypes.func
};

FinalCertificateContent.defaultProps = {
	report: undefined,
	updateField: () => {}
};

export default FinalCertificateContent;
