import forEach from 'lodash/forEach';
import axios from 'axios';
import { getCookieValue, getPathFromUrl } from './utils';
import history from '../history';
import * as Sentry from "@sentry/react";

export const get = (args) => {
	const csrfToken = getCookieValue("XSRF-REQUEST-TOKEN");
	const headers = {
		"cache-control": "no-store",
		"pragma": "no-cache",
		"X-XSRF-TOKEN": csrfToken,
		...args.headers || {}
	};
	const transaction = Sentry.startTransaction({ name: getPathFromUrl(args.url) });
	const span = transaction.startChild({
		op: "http",
		description: args.url,
	});

	return axios.get(args.url, { 
			withCredentials: true, 
			headers,
			cancelToken: args.cancelToken
		})
		.then(response => {
			if (response.status >= 200 && response.status < 300) return response.data;

			const error = new Error(response.statusText);
			error.response = response;
			throw error;
		}).then(data => {
			span.finish();
			transaction.finish(); 
			if (args.onSuccess) args.onSuccess.call(this, data);
		}).catch(error => {
			span.setData("http.error", JSON.stringify(error));
			span.finish();
			transaction.finish(); 
			if (error.response && error.response.status === 401) {
				if (error.response.data) {
					error.message = error.response.data;
					if (args.onError) args.onError.call(this, error);
				} else {
					if (args.onSessionTimeout) {
						args.onSessionTimeout(this, error);
					} else if (!args.ignoreSessionTimeout) {
						// Redirect to login page with timeout
						history.push("/login", { timeout: true });
					}
				}
			} else {
				if (args.onError) args.onError.call(this, error);
			}
		});
};

export const post = (args) => {
	const csrfToken = getCookieValue("XSRF-REQUEST-TOKEN");
	const headers = {
		"cache-control": "no-store",
		"pragma": "no-cache",
		"X-XSRF-TOKEN": csrfToken,
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		...args.headers || {}
	};
	const transaction = Sentry.startTransaction({ name: getPathFromUrl(args.url) });
	const span = transaction.startChild({
		op: "http",
		description: args.url,
	});

	return axios.post(args.url, args.data, {
		withCredentials: true,
		headers,
		onUploadProgress: progressEvent => {
			if (args.onProgress && progressEvent.lengthComputable) {
				const progress = progressEvent.loaded / progressEvent.total * 100;
				args.onProgress.call(progress);
			}
		}
	})
		.then(response => {
			if (response.status >= 200 && response.status < 300) return response.data;

			const error = new Error(response.statusText);
			error.response = response;
			throw error;
		}).then(data => {
			span.finish();
			transaction.finish(); 
			if (args.onSuccess) args.onSuccess.call(this, data);
		}).catch(error => {
			span.setData("http.error", JSON.stringify(error));
			span.finish();
			transaction.finish(); 
			if (error.response && error.response.status === 401) {
				if (error.response.data) {
					error.message = error.response.data;
					if (args.onError) args.onError.call(this, error);
				} else {
					if (args.onSessionTimeout) {
						args.onSessionTimeout(this, error);
					} else if (!args.ignoreSessionTimeout) {
						// Redirect to login page with timeout
						history.push("/login", { timeout: true });
					}
				}
			} else {
				if (args.onError) args.onError.call(this, error);
			}
		});

};

export const downloadFile = (url, args) => {
	const exportForm = document.createElement('form');
	exportForm.id = 'exportForm';
	exportForm.action = url;
	exportForm.method = 'POST';

	forEach(args, a => {
		const input = document.createElement('input');
		input.name = a.name;  
		input.value = JSON.stringify(a.value);
		exportForm.appendChild(input);
	});

	document.body.appendChild(exportForm);
	exportForm.submit();
	document.body.removeChild(exportForm);
};
