import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExtensionOfTimeHandoverContent from '../content/eotHandoverContent';
import addHandoverWrapper from '../handoverWrapper';

class ExtensionOfTimeHandover extends React.Component {
	static getTitle(report) {
		return `Extension of Time ${report.extensionOfTimeNumber}`;
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<ExtensionOfTimeHandoverContent report={report} />
		);
	}
}

ExtensionOfTimeHandover.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	ExtensionOfTimeHandover, {
		title: 'Extension of Time',
		className: 'eot-handover'
	})
);
