import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';

const FormRow = ({ style, classes, children }) => {
	let mappedClasses = classes;
	if (isArray(classes)) {
		mappedClasses = zipObject(classes, map(classes, () => true));
	}
	
	const cls = classNames({
		'form-row': true,
		...mappedClasses
	});
	return (
			<div style={style} className={cls}>
				{children}
			</div>

	);
};

FormRow.propTypes = {
	children: PropTypes.any,
	classes: PropTypes.any,
	style: PropTypes.object
};


export default FormRow;
