import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	savePracticalCompletion, 
	clearPracticalCompletion, 
	requestPracticalCompletion, 
	createNewPracticalCompletion 
} from '../../reducers/practicalCompletion';
import { requestSeparablePortions } from '../../reducers/separablePortions';
import PracticalCompletionForm from './practicalCompletionForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class PracticalCompletionMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			practicalCompletion: props.practicalCompletion,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestSeparablePortions());
		if (props.match.params.practicalCompletionId) {
			dispatch(requestPracticalCompletion(props.match.params.practicalCompletionId));
		} else {
			dispatch(createNewPracticalCompletion());
		}
	}
	static clearData(dispatch) {
		dispatch(clearPracticalCompletion());
	}
	componentDidMount() {
		PracticalCompletionMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			practicalCompletion: nextProps.practicalCompletion,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const practicalCompletion = this.state.practicalCompletion;
		practicalCompletion[field] = newValue;
        this.setState({
			practicalCompletion,
			hasUnsavedChanges: true
        });
	}

	render() {
		const practicalCompletion =  this.state.practicalCompletion || {};

		return (
<PageLayout
	title={practicalCompletion.practicalCompletionNumber}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.practicalCompletion),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.practicalCompletion),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<PracticalCompletionForm 
			practicalCompletion={practicalCompletion} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			separablePortions={this.props.separablePortions}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

PracticalCompletionMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	practicalCompletion: PropTypes.object.isRequired,
	separablePortions: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	practicalCompletion: state.practicalCompletion.practicalCompletion,
	separablePortions: state.separablePortions.separablePortions,
	canEdit: canEdit(state, 'EditCertificates'),
	isLoading: state.practicalCompletion.isLoading,
	saveResult: state.practicalCompletion.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (practicalCompletion) => {
		dispatch(savePracticalCompletion(practicalCompletion, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (practicalCompletion) => {
		dispatch(savePracticalCompletion(practicalCompletion, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(PracticalCompletionMaintenance);
