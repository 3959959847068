import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import DateField from '../widgets/DateField';
import ContentContainer from '../contentContainer';
import PersistedDataTable from '../widgets/persistedDataTable';
import Checkbox from '../widgets/Checkbox';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import Paper from '@material-ui/core/Paper';
import SelectField from '../widgets/selectField';
import { toCurrency, toPercent, addOrUpdate } from '../../utils/utils';
import NumericField from '../widgets/numericField';

class SeparablePortionForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			separablePortion: props.separablePortion
		};

		this.selectSchedule = this.selectSchedule.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			separablePortion: nextProps.separablePortion
		});
	}

	selectSchedule(schedule, selection) {
		const separablePortion = this.state.separablePortion;
		let spSchedule = null;
		switch (selection) {
			case 'NotSelected':
				remove(separablePortion.schedules, (s) => s.scheduleOfRatesId === schedule.scheduleOfRatesId);
				break;
			case 'EntireSchedule':
				spSchedule = find(separablePortion.schedules, (s) => s.scheduleOfRatesId === schedule.scheduleOfRatesId);
				if (!spSchedule) {
					spSchedule = {
						scheduleOfRatesId: schedule.scheduleOfRatesId,
						separablePortionId: separablePortion.separablePortionId,
						items: []
					};
					separablePortion.schedules.push(spSchedule);
				}
				spSchedule.appliesToEntireSchedule = true;
				break;
			case 'Items':
				spSchedule = find(separablePortion.schedules, (s) => s.scheduleOfRatesId === schedule.scheduleOfRatesId);
				if (!spSchedule) {
					spSchedule = {
						scheduleOfRatesId: schedule.scheduleOfRatesId,
						separablePortionId: separablePortion.separablePortionId,
						items: []
					};
					separablePortion.schedules.push(spSchedule);
				}
				spSchedule.appliesToEntireSchedule = false;
				break;
			default:
		}
		this.setState({
			separablePortion: separablePortion
		});
	}

	selectScheduleItem(item, selected) {
		const separablePortion = this.state.separablePortion;
		const schedule = find(this.props.schedules, (s) => s.scheduleOfRatesId === item.scheduleOfRatesId);
		const spSchedule = find(separablePortion.schedules, (s) => s.scheduleOfRatesId === schedule.scheduleOfRatesId);
		if (spSchedule) {
			if (selected) {
				if (!some(spSchedule.items, (i) => i.scheduleOfRatesItemId === item.scheduleOfRatesItemId)) {
					spSchedule.items.push({
						separablePortionId: separablePortion.separablePortionId,
						separablePortionScheduleId: spSchedule.separablePortionScheduleId,
						scheduleOfRatesItemId: item.scheduleOfRatesItemId,
						selected: true
					});
				}
			} else {
				remove(spSchedule.items, (i) => i.scheduleOfRatesItemId === item.scheduleOfRatesItemId);
			}
			this.setState({
				separablePortion: separablePortion
			});
		}
	}
	isAllSelected = (schedule) => {
		const separablePortion = this.state.separablePortion;
		const spSchedule = find(separablePortion.schedules, (s) => s.scheduleOfRatesId === schedule.scheduleOfRatesId);
		if (!spSchedule) return false;
		for (let i = 0, ii = schedule.items.length; i < ii; i++) {
			const item = schedule.items[i];
			const selected = some(spSchedule.items, spItem => spItem.scheduleOfRatesItemId === item.scheduleOfRatesItemId);
			if (!selected) return false;
		}
		return true;
	}
	toggleAll = (schedule) => {
		const nextValue = !this.isAllSelected(schedule);
		const separablePortion = this.state.separablePortion;
		const spSchedule = find(separablePortion.schedules, (s) => s.scheduleOfRatesId === schedule.scheduleOfRatesId);
		if (!spSchedule) return;
		forEach(schedule.items, (i) => {
			if (nextValue) {
				spSchedule.items = addOrUpdate(
					spSchedule.items, 
					{
						separablePortionId: separablePortion.separablePortionId,
						separablePortionScheduleId: spSchedule.separablePortionScheduleId,
						scheduleOfRatesItemId: i.scheduleOfRatesItemId,
						selected: true
					}, 
					spItem => spItem.scheduleOfRatesItemId === i.scheduleOfRatesItemId
				);
			} else {
				remove(spSchedule.items, spItem => spItem.scheduleOfRatesItemId === i.scheduleOfRatesItemId);
			}
		});
		this.setState({
			separablePortion: separablePortion
		});
	}

	render() {
		const separablePortion = this.state.separablePortion;
		const schedules = filter(this.props.schedules ? this.props.schedules : [], (s) => s.canBeUsedInSeparablePortions);
		let total = 0;
		forEach(schedules, (s) => {
			const spSchedule = find(separablePortion.schedules, (s2) => s2.scheduleOfRatesId === s.scheduleOfRatesId);
			let scheduleTotal = 0;
			let selectedTotal = 0;
			s._isSelectable = true;
			s._isEntireScheduleSelectable = true;
			if (spSchedule && spSchedule.appliesToEntireSchedule) {
				s._selection = 'EntireSchedule';
				forEach(s.items, (i) => {
					scheduleTotal += i.rate * i.qty;
				});
				selectedTotal = scheduleTotal;
			} else if (spSchedule) {
				s._selection = 'Items';
				forEach(s.items, (i) => {
					scheduleTotal += i.rate * i.qty;
					i._selected = some(spSchedule.items, (i2) => i2.scheduleOfRatesItemId === i.scheduleOfRatesItemId);
					if (i._selected) {
						selectedTotal += i.rate * i.qty;
						i._isSelectable = true;
					} else {
						i._isSelectable = !i.includedInSeparablePortion;
					}
				});
			} else {
				s._selection = 'NotSelected';
				if (s.includedInSeparablePortion) s._isSelectable = false;
				if (some(s.items, (i) => i.includedInSeparablePortion)) s._isEntireScheduleSelectable = false;
				forEach(s.items, (i) => {
					i._isSelectable = !i.includedInSeparablePortion;
				});
			}

			s._total = scheduleTotal;
			s._selectedTotal = selectedTotal;
			total += selectedTotal;
		});
		const itemColumns = (schedule) => [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				value: (args) => args.data._selected || false,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected(schedule)}
						onCheck={() => { this.toggleAll(schedule); } }
					/>,
				cellRenderer: (args) => 
					args.data._isSelectable ?
						<Checkbox
							checked={args.data._selected}
							onCheck={(e, v) => { this.selectScheduleItem(args.data, v); } }
						/> 
						: ''
			},
			{ name: 'itemNo', label: 'Item No' },
			{ name: 'description', label: 'Description' }
		];
		return (
<div>
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={separablePortion.name}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('name', v); }}
				label="Name of Separable Portion"
				errorText={getFieldErrorText(this.props.saveResult, 'Name')}
			/>
		</FormFieldContainer>

		<FormRow>
			<FormFieldContainer classes={['s12', 'm6']}>
				<DateField
					value={separablePortion.practicalCompletionDate} 
					label="Date for PC" 
					onChange={(d) => { this.props.updateField('practicalCompletionDate', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={['s12', 'm6']}>
				<NumericField 
					container="inline" 
					value={separablePortion.maintenancePeriod} 
					label="Maintenance Period" 
					onChange={(v) => { this.props.updateField('maintenancePeriod', v); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormFieldContainer>
			<TextField
				value={separablePortion.comments}
				fullWidth={true}
				multiline={true}
				onChange={(e, v) => { this.props.updateField('comments', v); }}
				label="Comments"
				errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
			/>
		</FormFieldContainer>
	</ContentContainer>


	{
		map(schedules, (s) =>
			<Paper style={{ 
					padding: '10px', 
					marginBottom: '10px', 
					color: s._selection === 'NotSelected' ? '#aaa' : undefined, 
					backgroundColor: s._isSelectable ? '#fff' : '#eee' 
				}}
			>
				{s._isSelectable &&
					<SelectField 
						value={s._selection} 
						style={{ width: 'auto', float: 'right' }}
						onChange={(v) => { this.selectSchedule(s, v); } } 
						fullWidth={true}
						items={[
							{ value: 'NotSelected', label: 'Not Selected' },
							...(s._isEntireScheduleSelectable ? [{ value: 'EntireSchedule', label: 'Entire Schedule' }] : []),
							{ value: 'Items', label: 'Selected Items' }
						]}
					>
					</SelectField>
				}

				<h3 style={{ marginTop: '0', marginBottom: '0' }}>{s.name}</h3>
				<p style={{ fontStyle: 'italic', marginBottom: '10px' }}>{`${(s.items || []).length} items`}</p>
				{s._selection === 'Items' && 
					<div>
						<PersistedDataTable 
							id="separablePortionScheduleItems"
							data={s.items || []} 
							columns={itemColumns(s)} 
							rowStyle={(data) => 
								data._isSelectable ? {} : { color: '#aaa' }
							}
						/>
					</div>
				}
				{s._selection === 'EntireSchedule' && 
					<div style={{ 
							textAlign: 'right', 
							fontSize: '16px', 
							fontWeight: 'bold', 
							marginTop: '10px' 
						}}
					>
						<span>Selected Total: </span>
						<span style={{ display: 'inline-block', minWidth: '100px' }}>{toCurrency(s._total)}</span>
					</div>
				}
				{s._selection === 'Items' && 
					<div style={{ 
							textAlign: 'right', 
							fontSize: '16px', 
							fontWeight: 'bold', 
							marginTop: '10px' 
						}}
					>
						<div>
							<span>Selected Total: </span>
							<span style={{ display: 'inline-block', minWidth: '100px' }}>{toCurrency(s._selectedTotal)}</span>
						</div>
						<div>
							<span>% of Schedule Total: </span>
							<span style={{ display: 'inline-block', minWidth: '100px' }}>{toPercent(s._selectedTotal / s._total)}</span>
						</div>
					</div>
				}
			</Paper>
		)
	}

	<Paper style={{ 
			float: 'right', 
			padding: '20px', 
			fontSize: '16px', 
			fontWeight: 'bold' 
		}}
	>
		<div>
			<span>Separable Portion Total: </span>
			<span>{toCurrency(total)}</span>
		</div>
	</Paper>
</div>
		);
	}
}

SeparablePortionForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	separablePortion: PropTypes.object.isRequired,
	schedules: PropTypes.array.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default SeparablePortionForm;
