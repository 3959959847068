import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ExtensionsOfTimeGrid from './extensionsOfTimeGrid';
import { deleteExtensionOfTime, requestExtensionsOfTime, clearExtensionsOfTime } from '../../reducers/extensionsOfTime';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class ExtensionsOfTime extends Component {
    constructor(props) {
        super(props);

        this.state = {
			extensionsOfTime: {}
        };

		this.onEditExtensionOfTime = this.onEditExtensionOfTime.bind(this);
		this.onViewExtensionOfTime = this.onViewExtensionOfTime.bind(this);
		this.onDeleteExtensionOfTime = this.onDeleteExtensionOfTime.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestExtensionsOfTime());
	}
	static clearData(dispatch) {
		dispatch(clearExtensionsOfTime());
	}
	componentDidMount() {
		ExtensionsOfTime.fetchData(this.props.dispatch);
	}

	onEditExtensionOfTime = (extensionOfTime) => {
		this.props.history.push(`/extension-of-time/${extensionOfTime.extensionOfTimeId}`);
	}

	onViewExtensionOfTime = (extensionOfTime) => {
		this.props.history.push(`/extension-of-time/${extensionOfTime.extensionOfTimeId}`);
	}
	onDeleteExtensionOfTime(extensionOfTime) {
		this.props.deleteExtensionOfTime(extensionOfTime);
	}

	newExtensionOfTime = () => {
		this.props.history.push('/extension-of-time');
	}

	render() {
		return (
<PageLayout
	title="Extensions of Time"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Extension Of Time',
				onClick: this.newExtensionOfTime
			}
		] : []
	}
	content={
		<ContentContainer>
			<ExtensionsOfTimeGrid 
				extensionsOfTime={this.props.extensionsOfTime} 
				onEditExtensionOfTime={this.onEditExtensionOfTime} 
				onDeleteExtensionOfTime={this.onDeleteExtensionOfTime} 
				onViewExtensionOfTime={this.onViewExtensionOfTime} 
				canView={this.props.canView}
				canEdit={this.props.canEdit}
			/>
		</ContentContainer>
	}
/>
		);
	}
}

ExtensionsOfTime.propTypes = {
	dispatch: PropTypes.func.isRequired,
	extensionsOfTime: PropTypes.array,
	deleteExtensionOfTime: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

ExtensionsOfTime.defaultProps = {
	extensionsOfTime: []
};

const mapStateToProps = (state) => ({
	extensionsOfTime: state.extensionsOfTime.extensionsOfTime,
	canView: canView(state, 'ViewExtensionOfTime'),
	canEdit: canEdit(state, 'EditExtensionsOfTime'),
	isLoading: state.extensionsOfTime.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteExtensionOfTime: (extensionOfTime) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this EOT?',
			onOk: () => {
				dispatch(deleteExtensionOfTime(extensionOfTime));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ExtensionsOfTime);
