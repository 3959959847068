import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import forEach from 'lodash/forEach';
import PersistedDataTable from '../widgets/persistedDataTable';
import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import some from 'lodash/some';
import filter from 'lodash/filter';
import round from 'lodash/round';
import map from 'lodash/map';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import { units } from '../../variables';
import { produce } from 'immer';
import { toCurrency } from '../../utils/utils';
import cloneDeep from 'lodash/cloneDeep';

class VariationItemDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			scheduleOfRates: this.setupItems(props.scheduleOfRates)
		};

		this.setQty = this.setQty.bind(this);
		this.addVariationItems = this.addVariationItems.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(produce(draft => {
			draft.scheduleOfRates = this.setupItems(nextProps.scheduleOfRates);
		}));
	}
	setupItems(scheduleOfRates) {
		const schedules = scheduleOfRates ? cloneDeep(scheduleOfRates) : scheduleOfRates;
		forEach(schedules, schedule => {
			forEach(schedule.items, i => {
				i._qty = 0;
				i._rate = i.rate;
			});
		});
		return schedules;
	}

	addVariationItems() {
		const selectedItems = [];
		forEach(this.state.scheduleOfRates, (s) => {
			forEach(s.items, (i) => {
				if (i._isSelected) {
					selectedItems.push({
						scheduleOfRatesId: i.scheduleOfRatesId,
						scheduleOfRatesItemId: i.scheduleOfRatesItemId,
						itemNo: i.itemNo,
						itemDescription: i.description,
						unit: i.unit,
						rate: i._rate,
						qty: i._qty
					});
				}
			});
		});
		this.props.addVariationItems(selectedItems);
	}

	setQty(item, qty) {
		this.setState(produce(draft => {
			forEach(draft.scheduleOfRates, (s) => {
				forEach(s.items, (i) => {
					if (i.scheduleOfRatesItemId === item.scheduleOfRatesItemId) {
						i._isSelected = true;
						i._qty = qty;
					}
				});
			});
		}));
	}
	setRate = (item, rate)  => {
		this.setState(produce(draft => {
			forEach(draft.scheduleOfRates, (s) => {
				forEach(s.items, (i) => {
					if (i.scheduleOfRatesItemId === item.scheduleOfRatesItemId) {
						i._isSelected = true;
						i._rate = rate;
					}
				});
			});
		}));
	}

	isRowSelectable = (row) => !row.isTextOnlyLine;
	isRowSelected = (row) => !row.isTextOnlyLine && row._isSelected;
	onSelectionChanged = (rows, selected)  => {
		this.setState(produce(draft => {
			const items = flatMap(draft.scheduleOfRates, (s) => s.items);
			forEach(rows, (r) => {
				const item = find(items, i => i.scheduleOfRatesItemId === r.scheduleOfRatesItemId);
				if (item) {
					if (!item.isTextOnlyLine) item._isSelected = selected;
				}
			});
		}));
	}

	render() {
		const scheduleOfRates = filter(this.state.scheduleOfRates || [], (s) => !s.tendered);

		const columns = [
			{ name: 'itemNo', label: 'Item' },
			{ 
				name: 'description', 
				label: 'Description',
				colspan: (args) => args.data.isTextOnlyLine ? 4 : 1
			},
			{ 
				name: 'unit', 
				label: 'Unit',
				filterOperators: ['=', 'Contains', 'multi-select'],
				filterOptions: units 
			},
			{ 
				name: 'qty', 
				label: 'Qty',
				dataType: 'number',
				cellRenderer: (args) =>
					!this.props.variation.isLotBased ?
						<DataTableNumericEditor
							type="decimal"
							value={args.data._qty} 
							onChange={(v) => { this.setQty(args.data, v); }}
							hintText={`${round(args.data.remainingQty, 2)} remaining`}
						/>
					:
					round(args.data.remainingQty, 2)
			},
			{ 
				name: 'rate', 
				label: 'Rate', 
				dataType: 'number', 
				formatString: 'currency',
				cellRenderer: (args) =>
					!this.props.variation.isLotBased ?
						<DataTableNumericEditor
							type="currency"
							nullable={true}
							value={args.data._rate} 
							onChange={(v) => { this.setRate(args.data, v); }}
						/>
					:
					toCurrency(args.data.rate)
			}
		];

		let scheduleItems = [];
		forEach(scheduleOfRates, s => {
			const items = filter(s.items, i => !i.existsInVariation);
			if (some(items, i => !i.isTextOnlyLine)) scheduleItems = scheduleItems.concat(map(items, i => ({ ...i })));
		});

		return (
	<Dialog
		title="Items"
		actions={
			<React.Fragment>
				<Button
					label="Add"
					primary={true}
					onClick={() => this.addVariationItems()}
				/>,
				<Button
					label="Cancel"
					primary={false}
					onClick={this.props.cancel}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.cancel}
		scroll='paper'
		fullWidth={true}
		maxWidth='lg'
	>
		<p>Select the items to add to the Variation</p>
		
		<PersistedDataTable 
			id="variationScheduleItems"
			data={scheduleItems || []} 
			columns={columns}
			enableSelection={true}
			onSelectionChanged={this.onSelectionChanged}
			isRowSelected={this.isRowSelected}
			isRowSelectable={this.isRowSelectable}
			groupBy="scheduleOfRatesId"
			groupByFormatter={(args) => find(scheduleOfRates, (s) => s.scheduleOfRatesId === args.value).name}
		/>
	</Dialog>
		);
	}
}

VariationItemDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	addVariationItems: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	variation: PropTypes.object.isRequired,
	scheduleOfRates: PropTypes.array.isRequired
};

export default VariationItemDialog;
