import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import SelectField from '../widgets/selectField';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import DateField from '../widgets/DateField';
import ContentContainer from '../contentContainer';
import SubContentContainer from '../subContentContainer';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import FileUpload from '@material-ui/icons/Publish';
import FileDownload from '@material-ui/icons/GetApp';
import map from 'lodash/map';
import Button from '../widgets/button';
import PersistedDataTable from '../widgets/persistedDataTable';
import VisibilityIcon from '@material-ui/icons/Visibility';
import filter from 'lodash/filter';
import CorrespondencePicker from '../widgets/correspondencePicker';
import Tabs from '../widgets/Tabs';
import Tab from '../widgets/Tab';
import PersonSelect from '../widgets/PersonSelect';
import TagField from '../widgets/TagField';

class PrincipalCorrespondenceForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			correspondence: props.correspondence,
			showCorrespondencePicker: false
		};

		this.selectFiles = this.selectFiles.bind(this);
		this.selectResponseFiles = this.selectResponseFiles.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			correspondence: nextProps.correspondence
		});
	}
	selectFiles(event) {
		this.props.addFiles('PrincipalCorrespondence', event.target.files);
	}
	selectResponseFiles(event) {
		this.props.addFiles('PrincipalCorrespondence.Response', event.target.files);
	}

	addLinkedCorrespondence = () => {
		this.setState({ showCorrespondencePicker: true });
	}

	onSelectCorrespondence = (selectedCorrespondence) => {
		this.props.onSelectCorrespondence(selectedCorrespondence);
		this.setState({
			showCorrespondencePicker: false
		});
	}
	onCancelCorrespondencePicker = () => {
		this.setState({ showCorrespondencePicker: false });
	}


	render() {
		const correspondence = this.state.correspondence;
		const linkedCorrespondence = correspondence.linkedCorrespondence || [];
		const files = filter(correspondence.files, (f) => f.referenceType === 'PrincipalCorrespondence');
		const responseFiles = filter(correspondence.files, (f) => f.referenceType === 'PrincipalCorrespondence.Response');

		const styles = {
			button: {
				marginTop: 12
			},
			fileInput: {
				cursor: 'pointer',
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				width: '100%',
				opacity: 0
			}
		};

		const linkedCorrespondenceColumns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.viewLinkedCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<VisibilityIcon />
						</IconButton>
						{this.props.canEdit && 
							<IconButton onClick={ () => { this.props.deleteLinkedCorrespondence(args.data); } }>
								<ActionDelete />
							</IconButton>
						}
					</div>				
			},
			{ 
				name: 'correspondenceType', 
				label: 'Type'
			},
			{ name: 'correspondenceNumber', label: 'Correspondence Number' },
			{ name: 'correspondenceDate', label: 'Date', dataType: 'date' }
		];

		return (
<div>
	<CorrespondencePicker 
		show={this.state.showCorrespondencePicker} 
		types={['Outgoing', 'Incoming']}
		selectedCorrespondence={{
			outgoingCorrespondence: map(filter(correspondence.linkedCorrespondence, (c) => !c.isParent && c.correspondenceType === 'Outgoing'), (c) => ({ outgoingCorrespondenceId: c.correspondenceId })),
			incomingCorrespondence: map(filter(correspondence.linkedCorrespondence, (c) => !c.isParent && c.correspondenceType === 'Incoming'), (c) => ({ incomingCorrespondenceId: c.correspondenceId }))
		}}
		onOk={this.onSelectCorrespondence} 
		cancel={this.onCancelCorrespondencePicker} 
	/>

	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={correspondence.rfiNumber}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('rfiNumber', v); }}
				label="Principal Correspondence Number"
				errorText={getFieldErrorText(this.props.saveResult, 'RfiNumber')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm4']}>
			<SelectField 
				value={correspondence.inOrOut} 
				fullWidth={true}
				label="In/Out" 
				onChange={(v) => { this.props.updateField('inOrOut', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'InOrOut')}
				items={[
					{ value: 'In', label: 'In' },
					{ value: 'Out', label: 'Out' }
				]}
			>
			</SelectField>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm8']}>
			<DateField
				value={correspondence.responseRequiredBy} 
				label="Response Required By" 
				onChange={(d) => { this.props.updateField('responseRequiredBy', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={correspondence.description}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('description', v); }}
				label="Description"
				errorText={getFieldErrorText(this.props.saveResult, 'Description')}
			/>
		</FormFieldContainer>

		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<SelectField 
					value={correspondence.status} 
					fullWidth={true}
					label="Status" 
					onChange={(v) => { this.props.updateField('status', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'Status')}
					items={[
						{ value: 'Open', label: 'Open' },
						{ value: 'Closed', label: 'Closed' },
						{ value: 'Awaiting Response', label: 'Awaiting Response' }
					]}
				>
				</SelectField>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<DateField 
					value={correspondence.dateAdded} 
					label="Date Added" 
					onChange={(d) => { this.props.updateField('dateAdded', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={['s12', 'm6']}>
				<PersonSelect
					personType={correspondence.fromType}
					personId={correspondence.fromId}
					fullWidth={true}
					onChange={(v) => { this.props.updateField('from', { fromId: v ? v.personId : 0, fromType: v ? v.personType : null }); }}
					label="From"
					errorText={getFieldErrorText(this.props.saveResult, 'From')}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={['s12', 'm6']}>
				<PersonSelect
					personType={correspondence.toType}
					personId={correspondence.toId}
					fullWidth={true}
					onChange={(v) => { this.props.updateField('to', { toId: v ? v.personId : 0, toType: v ? v.personType: null }); }}
					label="To"
					errorText={getFieldErrorText(this.props.saveResult, 'To')}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField 
				value={correspondence.dateSent} 
				label="Date Sent" 
				onChange={(d) => { this.props.updateField('dateSent', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField
				value={correspondence.dateClosed} 
				label="Date Closed" 
				onChange={(d) => { this.props.updateField('dateClosed', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TagField
				label="Tags" 
				fullWidth={true}
				value={correspondence.tags}
				onChange={tags => this.props.updateField('tags', tags)}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={correspondence.comments}
				fullWidth={true}
				multiline={true}
				onChange={(e, v) => { this.props.updateField('comments', v); }}
				label="Comments"
				errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
			/>
		</FormFieldContainer>
	</ContentContainer>

	<SubContentContainer header="Linked Correspondence" style={{ marginTop: '10px' }}>
		<Tabs renderOnLoad>
			<Tab label="Linked To" value="LinkedTo">
				<PersistedDataTable 
					id="linkedCorrespondence"
					data={filter(linkedCorrespondence || [], c => !c.isParent)} 
					columns={linkedCorrespondenceColumns}
				/>
			</Tab>
			<Tab label="Linked By" value="LinkedBy">
				<PersistedDataTable 
					id="linkedByCorrespondence"
					data={filter(linkedCorrespondence || [], c => c.isParent)} 
					columns={[
						{
							name: 'commands',
							excludeFromSearch: true,
							excludeFromFilter: true,
							cellRenderer: (args) => 
								<div style={{ whiteSpace: 'nowrap' }}>
									<IconButton onClick={ () => { this.props.viewLinkedCorrespondenceBy(args.data); } } style={{ padding: '2px' }}>
										<VisibilityIcon />
									</IconButton>
								</div>				
						},
						{ 
							name: 'targetType', 
							label: 'Type'
						},
						{ name: 'targetNumber', label: 'Number' }
					]}
				/>
			</Tab>
		</Tabs>

		<div className="row">
			<Button
				label="Add"
				style={styles.button}
				onClick={() => this.addLinkedCorrespondence()}
			>
			</Button>
			<Button
				style={{
					...styles.button,
					marginLeft: '10px'
				}}
				label="Explore"
				onClick={() => this.props.exploreLinkedCorrespondence()}
			>
			</Button>
		</div>
	</SubContentContainer>

	<SubContentContainer 
		header="Files"
		onDragOver={(e) => {
			e.dataTransfer.dropEffect = 'copy';
			e.stopPropagation();
			e.preventDefault();
		}}
		onDrop={(e) => {
			e.stopPropagation();
			e.preventDefault();
			// Allow files to be added by dropping anywhere in container
			this.props.addFiles('PrincipalCorrespondence', e.dataTransfer.files);
		}}
		style={{ marginTop: '10px' }}	
	>
		<div className="table-container">
			<table className="full-bordered" style={{ width: '100%' }}>
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
					</tr>
				</thead>
				<tbody>
					{
						map(files, (f) =>
							<tr key={f.userFileId}>
								<td style={{ whiteSpace: 'nowrap' }}>
									{!f._isNew && 
										<IconButton onClick={ () => { this.props.downloadFile(f); } } style={{ padding: '2px' }}>
											<FileDownload />
										</IconButton>
									}
									{this.props.canEdit &&
										<IconButton onClick={ () => { this.props.deleteFile(f); } } style={{ padding: '2px' }}>
											<ActionDelete />
										</IconButton>
									}
								</td>	
								<td>{f.name}</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</div>

		{this.props.canEdit &&
			<div className="row">
				<Button
					label="Select a file to upload"
					style={styles.button}
					component="label"
					startIcon={<FileUpload />}
				>
					<input type="file" style={styles.fileInput} multiple="multiple" onChange={this.selectFiles} />
				</Button>
			</div>
		}

	</SubContentContainer>

	<SubContentContainer 
		header="Response Files"
		style={{ marginTop: '10px' }}
		onDragOver={(e) => {
			e.dataTransfer.dropEffect = 'copy';
			e.stopPropagation();
			e.preventDefault();
		}}
		onDrop={(e) => {
			e.stopPropagation();
			e.preventDefault();
			// Allow files to be added by dropping anywhere in container
			this.props.addFiles('PrincipalCorrespondence.Response', e.dataTransfer.files);
		}}
	>
		<div className="table-container">
			<table className="full-bordered" style={{ width: '100%' }}>
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
					</tr>
				</thead>
				<tbody>
					{
						map(responseFiles, (f) =>
							<tr key={f.userFileId}>
								<td style={{ whiteSpace: 'nowrap' }}>
									{!f._isNew && 
										<IconButton onClick={ () => { this.props.downloadFile(f); } } style={{ padding: '2px' }}>
											<FileDownload />
										</IconButton>
									}
									{this.props.canEdit &&
										<IconButton onClick={ () => { this.props.deleteFile(f); } } style={{ padding: '2px' }}>
											<ActionDelete />
										</IconButton>
									}
								</td>	
								<td>{f.name}</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</div>

		{this.props.canEdit &&
			<div className="row">
				<Button
					label="Select a file to upload"
					style={styles.button}
					component="label"
					startIcon={<FileUpload />}
				>
					<input type="file" style={styles.fileInput} multiple="multiple" onChange={this.selectResponseFiles} />
				</Button>
			</div>
		}

	</SubContentContainer>

</div>
		);
	}
}

PrincipalCorrespondenceForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	downloadFile: PropTypes.func.isRequired,
	deleteFile: PropTypes.func.isRequired,
	addFiles: PropTypes.func.isRequired,
	correspondence: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	onSelectCorrespondence: PropTypes.func.isRequired,
	deleteLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondenceBy: PropTypes.func.isRequired,
	exploreLinkedCorrespondence: PropTypes.func.isRequired
};

export default PrincipalCorrespondenceForm;
