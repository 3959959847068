import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import PersistedDataTable from '../widgets/persistedDataTable';
import map from 'lodash/map';

class IncomingCorrespondenceGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.correspondences || this.props.correspondences.length !== nextProps.correspondences.length;
	}

	render() {
		const correspondences = this.props.correspondences || [];

		const columns = [
			{ name: 'rfiNumber', label: 'Incoming Number' },
			{ 
				name: 'hasAttachment', 
				label: <AttachmentIcon />,
				fullLabel: 'Has Attachments',
				dataType: 'bool',
				value: args => args.data.files && args.data.files.length > 0,
				cellRenderer: args => 
					args.data.files && args.data.files.length > 0 ? <AttachmentIcon /> : <span></span>,
				cellStyle: {
					textAlign: 'center'
				},
				filterOperators: ['=']
			},
			{ name: 'receivedDate', label: 'Date Received', dataType: 'date' },
			{ name: 'docRefNo', label: 'Doc Ref No' },
			{ name: 'docTitle', label: 'Document Title', headerStyle: { minWidth: '300px' } },
			{ name: 'toName', label: 'To' },
			{ 
				name: 'fromName', 
				label: 'From'
			},
			{ name: 'dated', label: 'Dated', dataType: 'date' },
			{ name: 'actionDueDate', label: 'Action Due Date', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					switch (args.data.status) {
						case 'Closed':
							statusBackColour = '#35aa47';
							statusForeColour = '#111';
							break;
						case 'DRR':
						case 'PRFI':
							statusBackColour = '#f0ad4e';
							statusForeColour = '#222';
							break;
						default:
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed']
			},
			{
				name: 'tags',
				label: 'Tags',
				displayValue: (args) => 
					map(args.data.tags, (t) => t.name).join(','),
				filterOperators: ['multi-select'],
				filterOptions: this.props.tags,
				filterOptionsKey: 'tagId',
				filterOptionsText: 'name'
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="incomingCorrespondence"
				data={correspondences} 
				columns={columns}
			/>
		);
	}
}

IncomingCorrespondenceGrid.propTypes = {
	correspondences: PropTypes.array.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditCorrespondence: PropTypes.func.isRequired,
	onViewCorrespondence: PropTypes.func.isRequired,
	onDeleteCorrespondence: PropTypes.func.isRequired,
	tags: PropTypes.array
};

IncomingCorrespondenceGrid.defaultProps = {
	tags: []
};

export default IncomingCorrespondenceGrid;
