import React from 'react';
import PropTypes from 'prop-types';
import { 
	Table, 
	TableBody, 
	TableRow, 
	TableCell 
} from '@material-ui/core';
import { toCurrency, formatDate } from '../../utils/utils';

const ContractInfo = ({ contract }) => {
	if (!contract) return <div></div>;

	return (
		<div style={{ overflow: 'auto', height: '100%' }}>
		<Table>
			<TableBody>
				<TableRow>
					<TableCell>Tender Value</TableCell>
					<TableCell>{toCurrency(contract.originalTender)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Revised Tender Value</TableCell>
					<TableCell>{toCurrency(contract.revisedTenderValue)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Letter of Acceptance</TableCell>
					<TableCell>{contract.dateCommenced ? formatDate(contract.dateCommenced, 'dd/MM/yyyy') : ''}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Variations (approved)</TableCell>
					<TableCell>{toCurrency(contract.variationsApproved)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Extras/Deductions</TableCell>
					<TableCell>{toCurrency(contract.extras)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Current Contract Value</TableCell>
					<TableCell>{toCurrency(contract.revisedTenderValue + contract.variationsApproved)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Date for PC</TableCell>
					<TableCell>{contract.practicalCompletionDate ? formatDate(contract.practicalCompletionDate, 'dd/MM/yyyy') : ''}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Approved EOT&#39;s</TableCell>
					<TableCell>{contract.daysApproved}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Revised Date for PC</TableCell>
					<TableCell>{contract.revisedPCDate ? formatDate(contract.revisedPCDate, 'dd/MM/yyyy') : ''}</TableCell>
				</TableRow>
			</TableBody>
		</Table>
		</div>
	);
};

ContractInfo.propTypes = {
	contract: PropTypes.object
};

ContractInfo.defaultProps = {
	contract: {}
};

export default ContractInfo;
