import React from 'react';
import PropTypes from 'prop-types';
import PersistedDataTable from '../widgets/persistedDataTable';
import DateField from '../widgets/DateField';
import ContentContainer from '../contentContainer';
import { 
	Grid
} from '@material-ui/core';

const AuditLogGrid = ({ 
	auditLog, 
	fromDate,
	setFromDate,
	fromDateError,
	toDate,
	setToDate
}) => {
	const columns = [
		{ name: 'logDate', label: 'Date', dataType: 'datetime', headerStyle: { minWidth: '180px' } },
		{ name: 'userName', label: 'User' },
		{ 
			name: 'category', 
			label: 'Category',
			filterOperators: ['multi-select'],
			filterOptions: ['Login', 'Contracts', 'ProgressClaims', 'Lots']
		},
		{ name: 'action', label: 'Action' },
		{ name: 'message', label: 'Message' },
		{ name: 'contractNumber', label: 'Contract' },
		{ name: 'periodName', label: 'Period' },
		{ name: 'targetName', label: 'Target' },
		{ name: 'ipAddress', label: 'IP Address' },
	];

	return (
		<div>
			<ContentContainer>
				<Grid container spacing={1} style={{ marginBottom: '10px' }}>
					<Grid item xs={6}>

					<DateField 
						value={fromDate} 
						label="From" 
						onChange={(d) => { setFromDate(d); }}
						errorText={fromDateError}
						fullWidth={true}
					/>
				</Grid>

				<Grid item xs={6}>
					<DateField 
						value={toDate} 
						label="To" 
						onChange={(d) => { setToDate(d); }}
						fullWidth={true}
						/>
				</Grid>
			</Grid>

			<PersistedDataTable 
				id="auditLog"
				data={auditLog || []} 
				columns={columns}
			/>
			</ContentContainer>
		</div>
	);
};

AuditLogGrid.propTypes = {
	auditLog: PropTypes.array.isRequired
};

export default AuditLogGrid;
