import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveRegisterDefinition, 
	clearRegisterDefinition, 
	requestRegisterDefinition, 
	createNewRegisterDefinition 
} from '../../reducers/registerDefinitions';
import RegisterDefinitionForm from './registerDefinitionForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { globals } from '../../globals';
import { canEdit } from '../../selectors/canEdit';
import cloneDeep from 'lodash/cloneDeep';
import uniqueId from 'lodash/uniqueId';
import forEach from 'lodash/forEach';
import { produce } from 'immer';
import PageLayout from '../pageLayout';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';

class RegisterDefinitionMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			registerDefinition: {
				...props.registerDefinition,
				fields: props.registerDefinition ? sortBy(props.registerDefinition.fields, ['order']) : []
			},
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.addDefinitionField = this.addDefinitionField.bind(this);
		this.deleteDefinitionField = this.deleteDefinitionField.bind(this);
		this.updateDefinitionField = this.updateDefinitionField.bind(this);
	}
	static fetchData(dispatch, props) {
		if (props.match.params.registerDefinitionId) {
			dispatch(requestRegisterDefinition(props.match.params.registerDefinitionId));
		} else {
			dispatch(createNewRegisterDefinition());
		}
	}
	static clearData(dispatch) {
		dispatch(clearRegisterDefinition());
	}
	componentDidMount() {
		RegisterDefinitionMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(produce(draft => {
			draft.registerDefinition = {
				...nextProps.registerDefinition,
				fields: nextProps.registerDefinition ? sortBy(nextProps.registerDefinition.fields, ['order']) : []
			};
		}));
	}

	setClientKeyOnFields(registerDefinition) {
		forEach(registerDefinition.fields, (f) => { f._clientKey = uniqueId('registerField_'); });
	}
	updateField(field, newValue) {
		this.setState(produce(draft => {
			draft.registerDefinition[field] = newValue;
			draft.hasUnsavedChanges = false;
		}));
	}
	addDefinitionField() {
		this.setState(produce(draft => {
			const lastField = maxBy(draft.registerDefinition.fields, 'order');
			draft.registerDefinition.fields.push({
				...cloneDeep(globals.templates.registerField),
				_clientKey: uniqueId('registerField_'),
				order: lastField ? lastField.order + 1 : 1,
				_isExpanded: true
			});
			draft.hasUnsavedChanges = true;
		}));
	}
	deleteDefinitionField(index) {
		this.setState(produce(draft => {
			draft.registerDefinition.fields.splice(index, 1);
			draft.hasUnsavedChanges = true;
		}));
	}
	updateDefinitionField(index, fieldName, value) {
		this.setState(produce(draft => {
			draft.registerDefinition.fields[index][fieldName] = value;
			draft.hasUnsavedChanges = true;
		}));
	}
	updateFieldOrder = (sourceIndex, destinationIndex) => {
		this.setState(produce(draft => {
			const [removed] = draft.registerDefinition.fields.splice(sourceIndex, 1);
			draft.registerDefinition.fields.splice(destinationIndex, 0, removed);
			forEach(draft.registerDefinition.fields, (f, index) => {
				f.order = index;
			});
		}));
	}
	expandField = (fieldIndex) => {
		this.setState(produce(draft => {
			draft.registerDefinition.fields[fieldIndex]._isExpanded = true;
		}));
	}
	collapseField = (fieldIndex) => {
		this.setState(produce(draft => {
			draft.registerDefinition.fields[fieldIndex]._isExpanded = false;
		}));
	}
	collapseAllFields = () => {
		this.setState(produce(draft => {
			forEach(draft.registerDefinition.fields, (f, index) => {
				draft.registerDefinition.fields[index]._isExpanded = false;
			});
		}));
	}

	render() {
		const registerDefinition =  this.state.registerDefinition || {};

	return (
<PageLayout
	title={registerDefinition.name}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	actions={this.props.canEdit ? [
		{
			label: 'Add Field',
			onClick: this.addDefinitionField
		}
	] : []}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.registerDefinition),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.registerDefinition),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<RegisterDefinitionForm 
			registerDefinition={registerDefinition} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			addDefinitionField={this.addDefinitionField}
			deleteDefinitionField={this.deleteDefinitionField}
			updateDefinitionField={this.updateDefinitionField}
			updateFieldOrder={this.updateFieldOrder}
			expandField={this.expandField}
			collapseField={this.collapseField}
			collapseAllFields={this.collapseAllFields}
			fields={this.props.fields}
			canEdit={this.props.canEdit}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

RegisterDefinitionMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	registerDefinition: PropTypes.object.isRequired,
	fields: PropTypes.array,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

RegisterDefinitionMaintenance.defaultProps = {
	fields: []
};

const mapStateToProps = (state) => ({
	canEdit: canEdit(state, 'EditRegisterDefinitions'),
	registerDefinition: state.registerDefinitions.registerDefinition,
	fields: globals.fields,
	isLoading: state.registerDefinitions.isLoading,
	saveResult: state.registerDefinitions.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (registerDefinition) => {
		dispatch(saveRegisterDefinition(registerDefinition, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (registerDefinition) => {
		dispatch(saveRegisterDefinition(registerDefinition, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(RegisterDefinitionMaintenance);
