import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	requestForecastPeriods, 
	clearForecastPeriods,
	newForecastPeriod,
	deleteForecastPeriod,
	mergeForecastPeriod
} from '../../reducers/forecasts';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import map from 'lodash/map';
import find from 'lodash/find';
import ForecastPeriodDialog from '../forecasts/forecastPeriodDialog';
import { showModal } from '../../reducers/modal';
import MergeIcon from '@material-ui/icons/MergeType';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import { formatDate } from '../../utils/utils';
import Chip from '@material-ui/core/Chip';
import orderBy from 'lodash/orderBy';
import { 
	Card, 
	CardActions, 
	CardHeader
} from '@material-ui/core';
import Button from '../widgets/button';
import PeriodPicker from './periodPicker';
import { showSuccessNotification, showWarningNotification } from '../../reducers/notifications';
import PageLayout from '../pageLayout';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
	chipLabelClosed: {
		color: '#fff'
	},
	chipLabelOpen: {
		color: '#fff'
	},
	chipLabelForecast: {
		color: '#fff'
	},
	mergedPeriodTitle: {
		fontSize: '1rem'
	}
});

class Periods extends Component {
	state = {
		showForecastPeriodDialog: false,
		showPeriodPicker: false,
		targetForecastPeriod: null
	};
	
	static fetchData(dispatch, props) {
        if (props.canViewForecastPeriods) {
            dispatch(requestForecastPeriods());
        }
	}
	static clearData(dispatch) {
		dispatch(clearForecastPeriods());
	}

	componentDidMount() {
		if (this.props.canViewForecastPeriods) {
			Periods.fetchData(this.props.dispatch, this.props);
		}

		if (!this.props.canViewForecastPeriods) {
            this.props.showWarningNotification('You do not have access to forecast periods');
        }
    }

	closeCurrentPeriod = () => {
		const currentPeriodId = this.props.period.periodId;
		this.props.history.push(`/period/${currentPeriodId}/close`);
	}
	onEditPeriod(period) {
		this.props.history.push(`/period/${period.periodId}`);
	}
	newForecastPeriod = () => {
		this.props.history.push('/forecast-period');
	}
	onForecastPeriodDialogOk = (periodName) => {
		this.props.newForecastPeriod(periodName);
		this.setState({ 
			showForecastPeriodDialog: false 
		});
	}
	onForecastPeriodDialogClose = () => {
		this.setState({ 
			showForecastPeriodDialog: false 
		});
	}
	showForecastPeriodDialog = () => {
		this.setState({ 
			showForecastPeriodDialog: false 
		});
	}
	onEditForecastPeriod = (forecastPeriod) => {
		this.props.history.push(`/forecast-period/${forecastPeriod.forecastPeriodId}`);
	}

	onDeleteForecastPeriod(forecastPeriod) {
		this.props.deleteForecastPeriod(forecastPeriod);
	}
	onMergeForecastPeriod = (forecastPeriod) => {
		this.setState({ 
			showPeriodPicker: true,
			targetForecastPeriod: forecastPeriod
		});
	}
	onPeriodPickerOk = (period) => {
		this.props.mergeForecastPeriod(this.state.targetForecastPeriod, period);
		this.setState({ 
			showPeriodPicker: false 
		});
	}

	onCancelPeriodPicker = () => {
		this.setState({ showPeriodPicker: false });
	}

	render() {
		let periods = map(this.props.periods || [], p => ({ ...p, type: 'Actual', forecastPeriods: [] }));
		const forecastPeriods = this.props.forecastPeriods || [];

		for (let i = 0, ii = forecastPeriods.length; i < ii; i++) {
			const forecastPeriod = forecastPeriods[i];
			if (forecastPeriod.periodId) {
				const period = find(periods, p => p.periodId === forecastPeriod.periodId);
				if (period) {
					period.forecastPeriods.push(forecastPeriod);
				}
			} else {
				periods.push({
					...forecastPeriod, 
					name: forecastPeriod.periodName,
					status: 'Forecast' 
				});
			}
		}

		periods = orderBy(periods, 'startDate');

		const pageActions = [];
        if (this.props.canEditPeriods) {
            pageActions.push({
                label: 'Close Current Period',
                onClick: this.closeCurrentPeriod
            });
            if (this.props.canEditForecastPeriods) {
                pageActions.push({
                    label: 'Add Forecast Period',
                    onClick: this.newForecastPeriod
                });
            }
        }

		return (
<PageLayout
	title="Periods"
	isLoading={this.props.isForecastPeriodsLoading}
	actions={pageActions}
	content={
	<div>
		<PeriodPicker 
			show={this.state.showPeriodPicker} 
			onOk={this.onPeriodPickerOk} 
			cancel={this.onCancelPeriodPicker} 
		/>

		<ForecastPeriodDialog show={this.state.showForecastPeriodDialog} onClose={this.onForecastPeriodDialogClose} onOk={this.onForecastPeriodDialogOk} />
		
		<div style={{ marginTop: '10px' }}>
			{
				map(periods, p =>  {
					let statusBackgroundColour;
					let labelClass;
					switch (p.status) {
						case 'Closed':
							statusBackgroundColour = '#f0ad4e';
							labelClass = this.props.classes.chipLabelClosed;
							break;
						case 'Open':
							statusBackgroundColour = '#35aa47';
							labelClass = this.props.classes.chipLabelOpen;
							break;
						case 'Forecast':
							statusBackgroundColour = '#5bc0de';
							labelClass = this.props.classes.chipLabelForecast;
							break;
						default:
					}
					return (
						<Paper style={{ 
								padding: '10px', 
								marginBottom: '10px'
							}}
						>
							<div>
								<h3 
									style={{ 
										marginTop: '0', 
										marginBottom: '0', 
										marginRight: '10px', 
										display: 'inline-block' 
									}}
								>{p.name}</h3>
								<Chip
									size="small"
									style={{ background: statusBackgroundColour }}
									classes={{
										label: labelClass,
									}}
									label={p.status}
								/>
							</div>
							<p style={{ fontStyle: 'italic', marginBottom: '10px' }}>{`${p.startDate ? formatDate(p.startDate, 'do MMMM yyyy') : ''} - ${p.endDate ? formatDate(p.endDate, 'do MMMM yyyy') : ''}`}</p>

							{p.status !== 'Forecast' &&
								<div>
                                    {this.props.canEditPeriods && <Button label="Edit Period" onClick={() => this.onEditPeriod(p) } icon={<EditIcon />} />}

									{p.forecastPeriods && p.forecastPeriods.length > 0 && 
										<div>
											<div className="form-section-header">
												Merged Forecast Periods
											</div>
											{
												map(p.forecastPeriods, fp => 
													<Card style={{ width: '300px', display: 'inline-block', marginRight: '10px' }}>
														<CardHeader
															title={fp.periodName}
															classes={{ title: this.props.classes.mergedPeriodTitle }}
														/>
                                                        {this.props.canEditPeriods &&
                                                            <CardActions>
                                                                <Button label="Edit" onClick={() => this.onEditForecastPeriod(fp) } icon={<EditIcon />} />
                                                                <Button label="Delete" onClick={() => this.onDeleteForecastPeriod(fp) } icon={<ActionDelete />} />
                                                            </CardActions>
                                                        }
													</Card>
												)
											}
										</div>
									}
								</div>
							}
							{p.status === 'Forecast' && this.props.canEditPeriods &&
								<div>
									<Button label="Edit" onClick={() => this.onEditForecastPeriod(p) } icon={<EditIcon />} />
									<Button label="Delete" onClick={() => this.onDeleteForecastPeriod(p) } icon={<ActionDelete />} />
									<Button label="Merge" onClick={() => this.onMergeForecastPeriod(p) } icon={<MergeIcon />} />
								</div>
							}
						</Paper>
					);
				})
			}
		</div>
	</div>
	}
/>
		);
	}
}

Periods.propTypes = {
	dispatch: PropTypes.func.isRequired,
	period: PropTypes.object.isRequired,
	periods: PropTypes.array.isRequired,
    canViewForecastPeriods: PropTypes.bool,
	canEditForecastPeriods: PropTypes.bool.isRequired,
	forecastPeriods: PropTypes.array.isRequired,
	newForecastPeriod: PropTypes.func.isRequired,
	isForecastPeriodsLoading: PropTypes.bool.isRequired,
	deleteForecastPeriod: PropTypes.func.isRequired,
	mergeForecastPeriod: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
    canEditPeriods: PropTypes.bool.isRequired,
    showWarningNotification: PropTypes.func.isRequired
};

Periods.defaultProps = {
    canViewForecastPeriods: false
};

const mapStateToProps = (state) => ({
	period: state.context.period,
	periods: state.context.contract.periods,
	canViewForecastPeriods: canView(state, 'ViewForecasts'),
	canEditForecastPeriods: canEdit(state, 'EditForecasts'),
	forecastPeriods: state.forecasts.forecastPeriods,
	isForecastPeriodsLoading: state.forecasts.isLoading,
	canEditPeriods: canEdit(state, 'EditPeriods')
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	newForecastPeriod: (periodName) => {
		dispatch(newForecastPeriod(periodName));
	},
	deleteForecastPeriod: (forecastPeriod) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Forecast Period?',
			onOk: () => {
				dispatch(deleteForecastPeriod(forecastPeriod));
			}
		}));
	},
	mergeForecastPeriod: (forecastPeriod, period) => {
		dispatch(mergeForecastPeriod(forecastPeriod, period, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	},
    showWarningNotification: message => dispatch(showWarningNotification(message))
});

export default withStyles(styles)(connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Periods));
