import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';
import map from 'lodash/map';

class NCRsHandoverContent extends React.Component {
	render() {
		const data = this.props.report;

		const columns = [
			{ name: 'contractorNcrNo', label: 'Contractor NCR No' },
			{ name: 'dateIdentified', label: 'Date Identified', dataType: 'date' },
			{ name: 'carRefNo', label: 'CAR Ref No' },
			{ name: 'dateIdentified', label: 'Date Identified', dataType: 'date' },
			{ name: 'dateNcrRaised', label: 'Date NCR Raised', dataType: 'date' },
			{ 
				name: 'lotRef', 
				label: 'Lot Ref',
				headerStyle: { minWidth: '200px' },
				value: (args) => {
					if (!args.data.lots || args.data.lots.length === 0) return '';
					return map(args.data.lots, (l) => l.lotNo).join(', ');
				}
			},
			{ name: 'details', label: 'Details of Non-Conformance' },
			{ name: 'correctiveAction', label: 'Corrective Action' },
			{ name: 'preventativeAction', label: 'Preventative Action' },
			{ name: 'comments', label: 'Comments' },
			{ 
				name: 'accepted', 
				label: 'Acceptance/Rejection',
				value: (args) => args.data.accepted ? 'Accepted' : 'Rejected'
			},
			{ name: 'responseDate', label: 'Response Date', dataType: 'date' },
			{ name: 'dateResubmitted', label: 'Date Resubmitted', dataType: 'date' },
			{ name: 'dateClosed', label: 'Date Closed', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.status !== 'Open') {
						statusBackColour = '#35aa47';
						statusForeColour = '#111';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				} 
			}
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

NCRsHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default NCRsHandoverContent;
