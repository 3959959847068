import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ReportContainer from '../reportContainer';
import { fetch, addTask } from 'domain-task';
import CircularProgress from '@material-ui/core/CircularProgress';
import FloatingActionButton from '@material-ui/core/Fab';
import FileDownload from '@material-ui/icons/GetApp';
import MessagePanel from '../messagePanel';
import classNames from 'classnames';
import reject from 'lodash/reject';

class FinalCertificateTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
			isLoading: true,
			data: null,
			isValid: true,
			invalidFields: [],
			success: false,
			message: ''
        };

		this.onSave = this.onSave.bind(this);
		this.updateField = this.updateField.bind(this);
    }

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			isLoading: true,
			isValid: true,
			invalidFields: [],
			data: null
		});

		this.runReport(nextProps);
	}

	componentDidMount() {
		this.runReport(this.props);
	}
	updateField(fieldName, value, isValid) {
		if (!this.state.data) return; 
		const data = this.state.data;

		data[fieldName] = value;
		const invalidFields = reject(this.state.invalidFields, (f) => f.name === fieldName);
		if (isValid === false) {
			invalidFields.push({ name: fieldName });
		}
		this.setState({
			data: data,
			isValid: invalidFields.length === 0,
			invalidFields: invalidFields
		});
	}

	runReport(props) {
		const contractId = props.context.contract.contractId;

		const fetchTask =  fetch(`/api/reports/final-certificate?contractId=${contractId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				this.setState({
					data: data,
					isLoading: false
				}, () => {
					window.status = 'ready_to_print'; // For wkhtmltopdf to know report is ready
				});
			}).catch((error) => {
				window.status = 'ready_to_print'; // For wkhtmltopdf to know report is ready
				console.log('request failed', error);
			});
					
		addTask(fetchTask);
	}
	downloadReport() {
		// browserHistory.push('/Reports/FinalCertificate');
		window.open('/Reports/FinalCertificate');
	}
	onSave() {
		const contractId = this.props.context.contract.contractId;
		const periodId = this.props.context.period.periodId;

		this.setState({
			isLoading: false
		});
		const fetchTask =  fetch(`/api/reports/final-certificate?contractId=${contractId}&periodId=${periodId}`, { 
				method: 'post', 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache',
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: encodeURIComponent(JSON.stringify(this.state.data))
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				this.setState({
					data: data.object,
					success: data.success,
					message: data.message,
					isLoading: false
				});
			}).catch((error) => {
				this.setState({
					success: false,
					message: error,
					isLoading: false
				});
			});
		addTask(fetchTask);	
	}

	render() {
		const cert = this.state.data || {};

        const reportClass = classNames({
			'editable-report': !this.props.isReportView,
			'final-certificate': true
		});
		
		return (
<div className={reportClass}>
    <section className="content">
		<ReportContainer isReportView={this.props.isReportView}>
			<div className='header'>
				<h1>Final Certificate</h1>
			</div>

			<div className="logo">
				<img src="/images/contrack_logo_200x58.jpg" alt="logo" />
			</div>

			{!this.props.isReportView && 
				<FloatingActionButton 
					onClick={this.downloadReport} 
					size="small"
					style={{ 
						position: 'fixed', 
						zIindex: '999', 
						top: '80px', 
						right: '10px' 
					}}
				>
					<FileDownload />
				</FloatingActionButton>
			}
			<CircularProgress style={{ display: this.state.isLoading ? undefined : 'none' }} />

			<div className='report-box-full'>
				<div className='report-row'>
					<span className='contract-name-label'>Contract No.</span>
					<span className='contract-name'>{cert.contractNumber}</span>
					<span className='status'>{cert.status}</span>
				</div>
				<div className='report-row'>
					<span className='contract-location'>{cert.contractName}</span>
				</div>
			</div>

			<table className='layout-table'>
				<tbody>
					<tr>
						<td className='layout-cell-left'>
							<div>
								<span className='report-box-label'>To The Principal</span>
								<div className='box-field' style={{ height: '100px' }}>
									<span className='inside-field-label'>Name and Address of Principal</span>
								</div>

								<span className='report-box-label'>and the Contractor</span>
								<div className='box-field' style={{ height: '100px' }}>
									<span className='inside-field-label'>Name and Address of Contractor</span>
								</div>
							</div>
						</td>
						<td className='layout-cell-right'>
							<div>
								<div>
									<div className='float-right-field'>
									</div>
									<span className='float-right-field-label'>Claim Amount</span>
								</div>
								<div style={{ clear: 'both', paddingTop: '10px' }}>
									<div className='float-right-field'>
									</div>
									<span className='float-right-field-label' style={{ marginTop: '1px' }}>Date Final Payment Claim Received</span>
								</div>
							</div>
						</td>
					</tr>	
					<tr>
						<td className='layout-cell-full' colSpan={2}>
							<span className='report-box-label'>Description of Contract</span>
							<div className='box-field' style={{ height: '200px' }}>
							</div>
						</td>
					</tr>
					<tr>
						<td className='layout-cell-full' colSpan={2}>
							<p>	I certify that the payment which, in my opinion, is to be made by the —	</p>
							<div style={{ clear: 'both', marginTop: '10px', float: 'right' }}>
								<span className='inline-field-label' style={{ marginLeft: '20px' }}>Principal to the Contractor</span>
								<div className='inline-field' style={{ width: '50px' }}>
								</div>
								<span className='inline-field-label' style={{ marginLeft: '20px' }}>OR Contractor to the Principal</span>
								<div className='inline-field' style={{ width: '50px' }}>
								</div>
								<span className='inline-field-label' style={{ marginLeft: '5px' }}>is</span>
								<div className='inline-field' style={{ width: '100px' }}>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td className='layout-cell-full' colSpan={2}>
							<div>
								<span className='inline-field-label' style={{ marginTop: '5px' }}>of which an amount as shown at right is the amount of GST</span>
								<div className='float-right-field'>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td className='layout-cell-full' colSpan={2}>
							<p>	The calculations employed to arrive at this amount are set out in the schedules attached to the Payment Schedule Summary (Form C6883) and in the following schedules —	</p>
							<div style={{ clear: 'both', marginTop: '10px' }}>
								<div className='float-right-field'>
								</div>
								<span className='float-right-field-label' style={{ width: '400px' }}>	Rise and Fall Summary (Form _____)</span>
							</div>
							<div style={{ clear: 'both', marginTop: '10px', paddingTop: '10px' }}>
								<div className='float-right-field'>
								</div>
								<span className='float-right-field-label' style={{ width: '400px' }}>Security and Retention Summary (Form _____)</span>
							</div>
							<div style={{ clear: 'both', marginTop: '10px', paddingTop: '10px' }}>
								<div className='float-right-field'>
								</div>
								<span className='float-right-field-label' style={{ width: '400px' }}>Liquidated Damages Summary (Form _____)</span>
							</div>		
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p>
								Where the amount is more than or less than the amount claimed by the Contractor, the reasons for the difference are set out on the attached Schedule of Reasons
							</p>
						</td>
					</tr>
					<tr>
						<td className='layout-cell-full' colSpan={2} style={{ padding: '0' }}>
							<div style={{ borderTop: '2px solid #ccc' }}>
							</div>
						</td>
					</tr>
					<tr>
						<td className='layout-cell-full' colSpan={2}>
							<span style={{ display: 'block', fontSize: '14px' }}>Certified by</span>
							<span className='report-box-label'>Administrator for the Contract</span>
							<div>
								<div className='inline-field' style={{ height: '50px', width: '59%', marginLeft: '0' }}>
									<span className='inside-field-label'>Name or Company Name and Name of Nominee</span>
								</div>
								<div className='inline-field' style={{ height: '50px', width: '19%' }}>
									<span className='inside-field-label'>Signature</span>
								</div>
								<div className='inline-field' style={{ height: '50px', width: '19%' }}>
									<span className='inside-field-label'>Date</span>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</ReportContainer>

		{!this.state.isValid && 
			<MessagePanel isSuccess={false} message="One of more fields are invalid" />
		}
		<MessagePanel isSuccess={this.state.success} message={this.state.message} />
		
		{/* {!this.props.isReportView && 
			<Button label="Save" enabled={this.state.isValid} primary={true} icon={<SaveIcon />} onClick={this.onSave} />
		} */}
    </section>
</div>
    );
  }
}

FinalCertificateTemplate.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connectedRouterHOC(
	mapStateToProps
)(FinalCertificateTemplate);
