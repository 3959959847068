import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveContractor, 
	clearContractor, 
	requestContractor, 
	createNewContractor 
} from '../../reducers/contractors';
import ContractorForm from './contractorForm';
import ContentContainer from '../contentContainer';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class ContractorMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			contractor: props.contractor,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
	}
	static fetchData(dispatch, props) {
		if (props.match.params.contractorId) {
			dispatch(requestContractor(props.match.params.contractorId));
		} else {
			dispatch(createNewContractor());
		}
	}
	static clearData(dispatch) {
		dispatch(clearContractor());
	}
	componentDidMount() {
		ContractorMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.contractor !== this.props.contractor) {
			this.setState({
				contractor: nextProps.contractor
			});
		}
	}

	updateField(field, newValue) {
		const contractor = this.state.contractor;
		contractor[field] = newValue;
        this.setState({
			contractor,
			hasUnsavedChanges: true
        });
	}

	render() {
		const contractor = this.state.contractor || {};

		return (
<PageLayout
	title={contractor.name}
	isLoading={this.props.isLoading}
	formActions={{
		isLoading: this.props.isLoading,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.contractor),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.contractor),
		onCancel: () => { this.props.history.goBack(); }
	}}
	content={
		<div>
		<ContentContainer>
			<ContractorForm contractor={contractor} updateField={this.updateField} saveResult={this.props.saveResult} />
		</ContentContainer>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

ContractorMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	contractor: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	canEdit: canEditAnyPeriod(state, 'EditContractors'),
	contractor: state.contractors.contractor,
	isLoading: state.contractors.isLoading,
	saveResult: state.contractors.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (contractor) => {
		dispatch(saveContractor(contractor, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (contractor) => {
		dispatch(saveContractor(contractor, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.replace(`/contractor/${data.object.contractorId}`, { fromNew: true });
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ContractorMaintenance);
