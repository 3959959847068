/* eslint react/no-multi-comp: off */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearTempState } from '../reducers/tempState';
import IconButton from './widgets/IconButton';
import HideIcon from '@material-ui/icons/Clear';
import { toggleFullScreenSidePanel, closeFullScreenSidePanel } from '../reducers/layout';
import SidePanelMenu from './sidePanelMenu';
import SidePanelLogo from './sidePanelLogo';
import Avatar from '@material-ui/core/Avatar';
import { getInitials } from '../utils/utils';
import { withStyles } from '@material-ui/core/styles';
import { secondaryColour, secondaryTextColour } from '../variables';

const styles = (theme) => ({
	avatar: {
		color: secondaryTextColour,
		backgroundColor: secondaryColour
	}
});

class SidePanel extends Component {
	render() {
		const classes = this.props.classes;

		const avatarSrc = this.props.user && this.props.user.avatar ? `/images/avatars/${this.props.user.avatar}.png` : '';
		const initials = this.props.user ? getInitials(this.props.user.fullName) : '';

		return (			
			<aside className="main-side-panel">
				<div className="hide-side-panel-button">
					<IconButton 
						onClick={ () => { this.props.toggleFullScreenSidePanel(); } }
					>
						<HideIcon />
					</IconButton>
				</div>

				<SidePanelLogo 
					closeFullScreenSidePanel={this.props.closeFullScreenSidePanel}
					clearTempState={this.props.clearTempState}
				/>
				<section className="side-panel">
				
					<div className="user-panel">
							<div className="pull-left image">
								{avatarSrc ? 
									<img src={avatarSrc} className="img-circle" alt="User Avatar" />
									:
									<Avatar className={classes.avatar}>{initials}</Avatar>
								}
							</div>
						<div className="pull-left info">
							<p>{this.props.user ? this.props.user.fullName : ''}</p>
							<span>{this.props.user ? this.props.user.position : ''}</span>
						</div>
					</div>

					<SidePanelMenu />
				</section>
			</aside>
		);
	}
}

SidePanel.propTypes = {
	user: PropTypes.object,
	toggleFullScreenSidePanel: PropTypes.func.isRequired,
	closeFullScreenSidePanel: PropTypes.func.isRequired,
	clearTempState: PropTypes.func.isRequired
};

SidePanel.defaultProps = {
	user: null
};

const mapStateToProps = (state) => ({
	user: state.context.user
});

const mapDispatchToProps = (dispatch) => ({
	toggleFullScreenSidePanel: () => {
		dispatch(toggleFullScreenSidePanel());
	},
	closeFullScreenSidePanel: () => {
		dispatch(closeFullScreenSidePanel());
	},
	clearTempState: () => {
		dispatch(clearTempState());
	}
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SidePanel));
