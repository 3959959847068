import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveSchedule, 
	requestSchedule, 
	clearSchedule, 
	createNewSchedule 
} from '../../reducers/scheduleOfRates';
import ScheduleForm from './scheduleForm';
import MessagePanel from '../messagePanel';
import filter from 'lodash/filter';
import map from 'lodash/map';
// import forEach from 'lodash/forEach';
// import uniqueId from 'lodash/uniqueId';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class ScheduleMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			schedule: props.schedule,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.updateScheduleItem = this.updateScheduleItem.bind(this);
		this.deleteScheduleItem = this.deleteScheduleItem.bind(this);
	}
	static fetchData(dispatch, props) {
		if (props.match.params.scheduleId) {
			dispatch(requestSchedule(props.match.params.scheduleId));
		} else {
			dispatch(createNewSchedule());
		}
	}
	static clearData(dispatch) {
		dispatch(clearSchedule());
	}
	componentDidMount() {
		ScheduleMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			schedule: nextProps.schedule,
			hasUnsavedChanges: false
		});
	}

	addClientKeysToScheduleItems(schedule) {
		return schedule;
		// if (!schedule) return schedule;

		// forEach(schedule.items, (i) => {
		// 	i._clientKey = uniqueId('scheduleOfRatesItemId_');
		// });
		// return schedule;
	}

	updateField(field, newValue) {
		const schedule = this.state.schedule;
		schedule[field] = newValue;
        this.setState({
			schedule,
			hasUnsavedChanges: true
        });
	}
	updateScheduleItem(item) {
		const schedule = this.state.schedule;

		if (item._isNew) {
			schedule.items = [
				...this.state.schedule.items,
				{
					...item,
					_isNew: false
				}
			];
		} else {
			schedule.items = map(schedule.items, (i) => {
				let isMatch = false;
				if (item._clientKey) {
					isMatch = item._clientKey === i._clientKey;
				} else {
					isMatch = item.scheduleOfRatesItemId === i.scheduleOfRatesItemId;
				}
				if (isMatch) {
					return {
						...i,
						itemNo: item.itemNo,
						description: item.description,
						qty: item.qty,
						rate: item.rate,
						unit: item.unit,
						forecastQty: item.forecastQty
					};
				}
				return i;
			});
		}

		// Need to call setState to update the schedule
        this.setState({
			schedule: schedule,
			hasUnsavedChanges: true
        });
	}
	deleteScheduleItem(scheduleItem) {
		// Need to call setState to update the schedule
        this.setState({
			schedule: {
				...this.state.schedule,
				items: filter(this.state.schedule.items, (i) => i !== scheduleItem && (i.scheduleOfRatesItemId === 0 || i.scheduleOfRatesItemId !== scheduleItem.scheduleOfRatesItemId))
			},
			hasUnsavedChanges: true
        });
	}
	render() {
		const schedule = this.state.schedule || {};

		return (
<PageLayout
	title={schedule.name}
	isLoading={this.props.isLoading}
	formActions={{
		isLoading: this.props.isLoading,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.schedule),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.schedule),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<ScheduleForm 
			schedule={schedule} 
			updateField={this.updateField} 
			deleteScheduleItem={this.deleteScheduleItem} 
			updateScheduleItem={this.updateScheduleItem} 
			settings={this.props.settings} 
			contractId={this.props.contractId} 
			periods={this.props.periods} 
			onSubmit={this.handleSubmit} 
			canEdit={this.props.canEdit} 
			canEditAll={this.props.canEditAll} 
			onCancel={this.onCancel} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult} 
		/>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

ScheduleMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	schedule: PropTypes.object,
	contractId: PropTypes.number.isRequired,
	periods: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canEditAll: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

ScheduleMaintenance.defaultProps = {
	schedule: null
};

const mapStateToProps = (state) => ({
	settings: state.settings,
	schedule: state.scheduleOfRates.schedule,
	contractId: state.context.contract.contractId,
	periods: state.context.contract.periods,
	canEdit: canEditAnyPeriod(state, 'EditSchedules'),
	canEditAll: canEditAnyPeriod(state, 'EditSchedules') && (state.context.contract.contractStatus === 'Preliminary' || !state.scheduleOfRates.schedule || !state.scheduleOfRates.schedule.tendered),
	isLoading: state.scheduleOfRates.isLoading,
	saveResult: state.scheduleOfRates.result
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (schedule) => {
		dispatch(saveSchedule(schedule, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (schedule) => {
		dispatch(saveSchedule(schedule, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ScheduleMaintenance);
