import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { toCurrency } from '../../utils/utils';
import { 
	ResponsiveContainer,
	LineChart, 
	Line, 
	XAxis, 
	YAxis, 
	Tooltip, 
	Legend  
} from 'recharts';

class ContractForecastChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
			contractForecast: props.contractForecast
        };

		this.saveChanges = this.saveChanges.bind(this);
    }

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			contractForecast: nextProps.contractForecast
		});
	}
	
	getSubForecastPeriod = (f, sf, period) => {
		let amount = null;
		let fp;
		let fpIndex;

		if (period.forecastPeriodId) {
			fpIndex = findIndex(sf.periodForecasts, pf => pf.forecastPeriodId === period.forecastPeriodId);
			if (fpIndex > -1) {
				fp = sf.periodForecasts[fpIndex];
				amount = fp.amount;
			}
		} else {
			fpIndex = findIndex(sf.periodForecasts, pf => pf.forecastPeriodId === period.periodId);
			if (fpIndex > -1) {
				fp = sf.periodForecasts[fpIndex];
				amount = fp.amount;
			}
		}

		return {
			forecastPeriod: fp,
			fpIndex: fpIndex,
			amount: amount
		};
	}

	saveChanges() {

	}
	render() {
		const contractForecast = this.props.contractForecast || {};
		const periods = this.props.periods;

		const chartData = map(periods, (p) => {
			const period = {
				order: p.order,
				name: p.name
			};
			
			// Add actuals
			let actualPeriod = null;
			if (p.type === 'Actual') {
				actualPeriod = find(contractForecast.periods, p2 => p2.periodId === p.periodId);
				if (actualPeriod) period.forecast = actualPeriod.certifiedToDate;
			}

			// Add forecasts
			forEach(contractForecast.forecasts, (f, i) => {
				let forecastPeriod = find(f.periodForecasts, fp => p.periodId ? (fp.periodId === p.periodId || fp.forecastPeriodId === p.forecastPeriodId) : fp.forecastPeriodId === p.forecastPeriodId);
				if (!forecastPeriod) {
					// May be in a forecast period that was merged into the actual period
					for (let j = 0, jj = p.forecastPeriods.length; j < jj; j++) {
						forecastPeriod = find(f.periodForecasts, pf => pf.forecastPeriodId === p.forecastPeriods[j].forecastPeriodId);
						if (forecastPeriod) break;
					}
				}
				if (forecastPeriod) {
					let amount = forecastPeriod.amount;
					if (f.subForecasts) {
						for (let x = 0, xx = f.subForecasts.length; x < xx; x++) {
							const subForecast = f.subForecasts[x];
							if (subForecast.include) {
								const {
									amount: subAmount
								} = this.getSubForecastPeriod(f, subForecast, p);
								amount += subAmount;
							}
						}
					}
					period[`forecast-${i}`] = amount;
				}
			});

			return period;
		});

		const COLORS = ['#AA4643', '#4572A7', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92'];

		const chartSeries = [];
		forEach(contractForecast.forecasts, (f, i) => {
			if (f.includeInChart) {
				chartSeries.push(
					<Line name={f.name} dataKey={`forecast-${i}`} connectNulls={true} stroke={COLORS[i % COLORS.length]} strokeWidth={3} />
				);
			}
		});
		
		return (
<div>
	<ResponsiveContainer aspect={3}>
		<LineChart data={chartData} width={1000} height={600}>
			<Tooltip label="Baseline" formatter={(val) => toCurrency(val) } isAnimationActive={false} />
			<Line name='Certfied To Date' dataKey="forecast" connectNulls={true} stroke="#89A54E" strokeWidth={3} />
			{chartSeries}
			<XAxis dataKey="name" />
			<YAxis width={120} tickFormatter={(v) => toCurrency(v)} />
			<Legend />
		</LineChart>
	</ResponsiveContainer>
</div>
		);
	}
}

ContractForecastChart.propTypes = {
	periods: PropTypes.array.isRequired,
	contractForecast: PropTypes.object.isRequired
};

export default injectIntl(ContractForecastChart);
