import { createSelector } from 'reselect';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import { globals } from '../globals';

const getContract = (state) => state.context.contract;
const getUser = (state) => state.context.user;

export const getPermissions = createSelector(
	[getContract, getUser],
	(contract, user) => {
		const permissions = {
			isGlobalAdmin: false,
			hasFullAccess: false
		};

		if (!user) return {};
		if (user.userRole === "Global Administrator") {
			permissions.isGlobalAdmin = true;
			permissions.hasFullAccess = true;
			forEach(globals.permissions, p => { permissions[p.key] = true; });
		} else if (user.userRole === "Administrator") {
			// Return all permissions with access
			permissions.hasFullAccess = true;
			forEach(globals.permissions, p => { permissions[p.key] = true; });
		} else {
			// Flatten our permissions for each role for the current
			// contract into a flat object of permissions.
			const contractRoles = contract ?  user.contractRoles[contract.contractId] : null;
			const rolePermissions = contractRoles ? uniq(map(flatMap(contractRoles.roles, (r) => r.permissions), (p) => p.permission)) : [];
	
			if (user.hasFullAccess) permissions.users = true;
			forEach(rolePermissions, (rp) => { permissions[rp] = true; });
		}

		return permissions;
	}
);
