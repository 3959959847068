import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { fetch, addTask } from 'domain-task';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { addOrUpdate } from '../utils/utils';

const initialState = {
	isLoading: false,
	saveResult: {
		progress: null,
		success: null,
		message: null,
		fields: [] 
	},
	incomingCorrespondence: null, 
	incomingCorrespondences: null
};

const emptyIncomingCorrespondence = {
	_isNew: true,
	'incomingCorrespondenceId': 0,
	'contractId': 0,
	'rfiNumber': '',
	'receivedDate': null,
	'docRefNo': '',
	'docTitle': '',
	'to': null,
	'from': '',
	'deliveryMethod': '',
	'dated': null,
	'timeBarred': false,
	'notificationUser': null,
	'actionRequired': false,
	'actionDueDate': null,
	'status': 'Open',
	'actionedBy': null,
	'actionDate': null,
	'comments': '',
	'dateAdded': new Date(),
	'files': [],
	tags: []
};

const CLEAR_INCOMING_CORRESPONDENCES = 'CLEAR_INCOMING_CORRESPONDENCES';
const CLEAR_INCOMING_CORRESPONDENCE = 'CLEAR_INCOMING_CORRESPONDENCE';
const REQUEST_INCOMING_CORRESPONDENCE = 'REQUEST_INCOMING_CORRESPONDENCE';
const RECEIVE_INCOMING_CORRESPONDENCE = 'RECEIVE_INCOMING_CORRESPONDENCE';
const REQUEST_INCOMING_CORRESPONDENCES = 'REQUEST_INCOMING_CORRESPONDENCES';
const RECEIVE_INCOMING_CORRESPONDENCES = 'RECEIVE_INCOMING_CORRESPONDENCES';
const CREATE_NEW_INCOMING_CORRESPONDENCE = 'CREATE_NEW_INCOMING_CORRESPONDENCE';
const SAVE_INCOMING_CORRESPONDENCE = 'SAVE_INCOMING_CORRESPONDENCE';
const RECEIVE_SAVE_INCOMING_CORRESPONDENCE_RESPONSE = 'RECEIVE_SAVE_INCOMING_CORRESPONDENCE_RESPONSE';
const DELETE_INCOMING_CORRESPONDENCE = 'DELETE_INCOMING_CORRESPONDENCE';
const RECEIVE_DELETE_INCOMING_CORRESPONDENCE_RESPONSE = 'RECEIVE_DELETE_INCOMING_CORRESPONDENCE_RESPONSE';
const SHOW_INCOMING_CORRESPONDENCE_ERRORS = 'SHOW_INCOMING_CORRESPONDENCE_ERRORS';
const UPDATE_INCOMING_CORRESPONDENCE_NUMBER = 'UPDATE_INCOMING_CORRESPONDENCE_NUMBER';
const UPDATE_INCOMING_CORRESPONDENCE_SAVE_PROGRESS = 'UPDATE_INCOMING_CORRESPONDENCE_SAVE_PROGRESS';

export const clearIncomingCorrespondences = () => ({ type: CLEAR_INCOMING_CORRESPONDENCES });
export const clearIncomingCorrespondence = () => ({ type: CLEAR_INCOMING_CORRESPONDENCE });
export const receiveIncomingCorrespondences = (data) => ({ type: RECEIVE_INCOMING_CORRESPONDENCES, payload: { data } });
export const receiveIncomingCorrespondence = (data) => ({ type: RECEIVE_INCOMING_CORRESPONDENCE, payload: { data } });
export const receiveSaveIncomingCorrespondenceResponse = (data) => ({ type: RECEIVE_SAVE_INCOMING_CORRESPONDENCE_RESPONSE, data });
export const receiveDeleteIncomingCorrespondenceResponse = (data) => ({ type: RECEIVE_DELETE_INCOMING_CORRESPONDENCE_RESPONSE, data });
export const updateCorrespondenceNumber = (number) => ({ type: updateCorrespondenceNumber, number });
export const updateSaveProgress = (progress) => ({ type: UPDATE_INCOMING_CORRESPONDENCE_SAVE_PROGRESS, progress });

export const createNewIncomingCorrespondence = (contractId) =>
	(dispatch) => {	
		const newCorrespondence = {
			...cloneDeep(emptyIncomingCorrespondence)
		};
		dispatch({ type: CREATE_NEW_INCOMING_CORRESPONDENCE, newCorrespondence: newCorrespondence });

		const fetchTask =  fetch(`/api/correspondence/get-incoming-correspondence-number?contractId=${contractId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.text();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				dispatch({ type: UPDATE_INCOMING_CORRESPONDENCE_NUMBER, number: data });
			}).catch((error) => {
				console.log('request failed', error);
			});
					
		addTask(fetchTask);
	};

export const requestIncomingCorrespondences = () => (
	createFetchAction({
		objectName: 'Incoming Correspondence',
		passContext: true,
		url: '/api/incoming-correspondence',
		startAction: REQUEST_INCOMING_CORRESPONDENCES,
		success: (data) =>
			receiveIncomingCorrespondences(map(data, (c) => ({
				...c,
				receivedDate: c.receivedDate ? new Date(c.receivedDate) : null,
				dated: c.dated ? new Date(c.dated) : null,
				actionDueDate: c.actionDueDate ? new Date(c.actionDueDate) : null,
				actionDate: c.actionDate ? new Date(c.actionDate) : null
			})))
	})
);

export const requestIncomingCorrespondence = (correspondenceId) => (
	createFetchAction({
		objectName: 'Incoming Correspondence',
		passContext: true,
		url: `/api/incoming-correspondence/${correspondenceId}`,
		startAction: REQUEST_INCOMING_CORRESPONDENCE,
		success: (data) => 
			receiveIncomingCorrespondence({
				...data,
				receivedDate: data.receivedDate ? new Date(data.receivedDate) : null,
				dated: data.dated ? new Date(data.dated) : null,
				actionDueDate: data.actionDueDate ? new Date(data.actionDueDate) : null,
				actionDate: data.actionDate ? new Date(data.actionDate) : null,
				linkedCorrespondence: map(data.linkedCorrespondence, (lc) => ({
					...lc,
					correspondenceDate: lc.correspondenceDate ? new Date(lc.correspondenceDate) : null
				}))
			})
	})
);

export const saveIncomingCorrespondence = (correspondence, newFiles, onSuccess) => {
	const errors = [];
	if (!correspondence.rfiNumber) {
		errors.push({
			fieldName: 'RfiNumber',
			valid: false,
			message: 'Incoming Number is required'
		});
	}
	if (!correspondence.docTitle) {
		errors.push({
			fieldName: 'DocTitle',
			valid: false,
			message: 'Document Title is required'
		});
	}
	if (correspondence.timeBarred && !correspondence.notificationUser) {
		errors.push({
			fieldName: 'NotificationUser',
			valid: false,
			message: 'You must set a user to notify when the correspondence is Time Barred'
		});
	}
	if (errors.length > 0) return { type: SHOW_INCOMING_CORRESPONDENCE_ERRORS, data: errors };

	const formData = new FormData();
	formData.append('Correspondence', encodeURIComponent(JSON.stringify(correspondence)));
	forEach(newFiles, (f) => formData.append('NewFiles', f.file));

	return createFormPostAction({
		passContext: true,
		url: '/api/incoming-correspondence',
		formData,
		startAction: SAVE_INCOMING_CORRESPONDENCE,
		onProgress: (progress) => updateSaveProgress(progress),
		success: (data, dispatch) => {
			dispatch(receiveSaveIncomingCorrespondenceResponse({
				...data,
				object: data.object ? {
					...data.object,
					receivedDate: data.object.receivedDate ? new Date(data.object.receivedDate) : null,
					dated: data.object.dated ? new Date(data.object.dated) : null,
					actionDueDate: data.object.actionDueDate ? new Date(data.object.actionDueDate) : null,
					actionDate: data.object.actionDate ? new Date(data.object.actionDate) : null
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteIncomingCorrespondence = (correspondence, onSuccess) => (
	createPostAction({
		passContext: true,
		url: `/api/incoming-correspondence/${correspondence.incomingCorrespondenceId}/delete`,
		data: correspondence,
		startAction: DELETE_INCOMING_CORRESPONDENCE,
		success: (data, dispatch) => {
			dispatch(receiveDeleteIncomingCorrespondenceResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_INCOMING_CORRESPONDENCES:
			return {
				...state,
				incomingCorrespondences: null
			};
		case CLEAR_INCOMING_CORRESPONDENCE:
			return {
				...state,
				incomingCorrespondence: null
			};
		case REQUEST_INCOMING_CORRESPONDENCES:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				incomingCorrespondences: []
			};
		case RECEIVE_INCOMING_CORRESPONDENCES:
			return {
				...state,
				isLoading: false,
				incomingCorrespondences: action.payload.data
			};
		case REQUEST_INCOMING_CORRESPONDENCE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				incomingCorrespondence: cloneDeep(emptyIncomingCorrespondence)
			};
		case RECEIVE_INCOMING_CORRESPONDENCE:
			return {
				...state,
				isLoading: false,
				incomingCorrespondence: action.payload.data
			};
		case DELETE_INCOMING_CORRESPONDENCE:
			return { 
				...state
			};
		case RECEIVE_DELETE_INCOMING_CORRESPONDENCE_RESPONSE:
			return {
				...state,
				incomingCorrespondences: state.incomingCorrespondences ? state.incomingCorrespondences.filter(c => c.incomingCorrespondenceId !== action.data.objectId) : state.incomingCorrespondences
			};
		case CREATE_NEW_INCOMING_CORRESPONDENCE:
			return {
				...state,
				incomingCorrespondence: action.newCorrespondence
			};	
		case SAVE_INCOMING_CORRESPONDENCE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_INCOMING_CORRESPONDENCE_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				incomingCorrespondences: addOrUpdate(state.incomingCorrespondences, action.data.object, { incomingCorrespondenceId: action.data.object.incomingCorrespondenceId }), 
				incomingCorrespondence: action.data.object,				
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_INCOMING_CORRESPONDENCE_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		case UPDATE_INCOMING_CORRESPONDENCE_NUMBER:
			return {
				...state,
				incomingCorrespondence: {
					...state.incomingCorrespondence,
					rfiNumber: action.number
				}
			};
		case UPDATE_INCOMING_CORRESPONDENCE_SAVE_PROGRESS:
			return {
				...state,
				saveResult: {
					...state.saveResult,
					progress: action.progress
				}
			};
		default:
			return state;
	}
};
