import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgressClaimSummaryReportContent from '../reports/progressClaimSummaryReportContent';
import addHandoverWrapper from './handoverWrapper';

class ProgressClaimSummaryReport extends React.Component {
	static getTitle(report) {
		return `Progress Claim Summary`;
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<ProgressClaimSummaryReportContent report={report} />
		);
	}
}

ProgressClaimSummaryReport.propTypes = {
	context: PropTypes.object.isRequired,
	report: PropTypes.object
};

ProgressClaimSummaryReport.defaultProps = {
	report: undefined
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	ProgressClaimSummaryReport, {
		title: 'Progress Claim Summary',
		className: 'progress-claim-summary-report'
	})
);
