import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { addOrUpdate } from '../utils/utils';
import { globals } from '../globals';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	forecast: null, 
	forecasts: null
};

const CLEAR_SCHEDULE_FORECASTS = 'CLEAR_SCHEDULE_FORECASTS';
const CLEAR_SCHEDULE_FORECAST = 'CLEAR_SCHEDULE_FORECAST';
const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT';
const DELETE_SCHEDULE_FORECAST = 'DELETE_SCHEDULE_FORECAST';
const REQUEST_SCHEDULE_FORECASTS = 'REQUEST_SCHEDULE_FORECASTS';
const RECEIVE_SCHEDULE_FORECASTS = 'RECEIVE_SCHEDULE_FORECASTS';
const REQUEST_SCHEDULE_FORECAST = 'REQUEST_SCHEDULE_FORECAST';
const RECEIVE_SCHEDULE_FORECAST = 'RECEIVE_SCHEDULE_FORECAST';
const CREATE_NEW_SCHEDULE_FORECAST = 'CREATE_NEW_SCHEDULE_FORECAST';
const RECEIVE_NEW_SCHEDULE_FORECAST = 'RECEIVE_NEW_SCHEDULE_FORECAST';
const SAVE_SCHEDULE_FORECAST = 'SAVE_SCHEDULE_FORECAST';
const RECEIVE_SAVE_SCHEDULE_FORECAST_RESPONSE = 'RECEIVE_SAVE_SCHEDULE_FORECAST_RESPONSE';
const RECEIVE_DELETE_SCHEDULE_FORECAST_RESPONSE = 'RECEIVE_DELETE_SCHEDULE_FORECAST_RESPONSE';
const SHOW_SCHEDULE_FORECAST_ERRORS = 'SHOW_SCHEDULE_FORECAST_ERRORS';
const EDIT_SCHEDULE_FORECAST = 'EDIT_SCHEDULE_FORECAST';

export const clearScheduleForecasts = () => ({ type: CLEAR_SCHEDULE_FORECASTS });
export const clearScheduleForecast = () => ({ type: CLEAR_SCHEDULE_FORECAST });
export const clearSaveResult = () => ({ type: CLEAR_SAVE_RESULT });
export const receiveScheduleForecasts = (data) => ({ type: RECEIVE_SCHEDULE_FORECASTS, payload: { data } });
export const receiveScheduleForecast = (data) => ({ type: RECEIVE_SCHEDULE_FORECAST, payload: { data } });
export const receiveNewScheduleForecast = (data) => ({ type: RECEIVE_NEW_SCHEDULE_FORECAST, payload: { data } });
export const receiveSaveScheduleForecastResponse = (data) => ({ type: RECEIVE_SAVE_SCHEDULE_FORECAST_RESPONSE, data });
export const receiveDeleteScheduleForecastResponse = (data) => ({ type: RECEIVE_DELETE_SCHEDULE_FORECAST_RESPONSE, data });
export const editScheduleForecast = (forecast) => ({ type: EDIT_SCHEDULE_FORECAST, forecast });

export const createNewScheduleForecast = () =>
	(dispatch, getState) => {	
		const newForecast = {
			...cloneDeep(globals.templates.scheduleForecast)
		};
		dispatch({ type: CREATE_NEW_SCHEDULE_FORECAST, newForecast: newForecast });

		const fetch = createFetchAction({
			objectName: 'New Schedule Forecast',
			passContext: true,
			url: '/api/schedule-forecasts/new',
			startAction: REQUEST_SCHEDULE_FORECASTS,
			success: (data) => receiveScheduleForecast(data)
		});
		fetch(dispatch, getState);
	};

export const requestScheduleForecasts = () => (
	createFetchAction({
		objectName: 'Schedule Forecasts',
		passContext: true,
		url: '/api/schedule-forecasts',
		startAction: REQUEST_SCHEDULE_FORECASTS,
		success: (data) => receiveScheduleForecasts(data)
	})
);

export const requestScheduleForecast = (forecastId) => (
	createFetchAction({
		objectName: 'Schedule Forecast',
		passContext: true,
		url: `/api/schedule-forecasts/${forecastId}`,
		startAction: REQUEST_SCHEDULE_FORECAST,
		success: (data) => receiveScheduleForecast(data)
	})
);

const validateScheduleForecast = (forecast) => {
	const errors = [];
	if (!forecast.name) {
		errors.push({
			fieldName: 'Name',
			valid: false,
			message: 'Name is required'
		});
	}

	return errors;
};

export const saveScheduleForecast = (forecast, onSuccess) => {
	const errors = validateScheduleForecast(forecast);
	if (errors.length > 0) return { type: SHOW_SCHEDULE_FORECAST_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/schedule-forecasts',
		data: forecast,
		startAction: SAVE_SCHEDULE_FORECAST,
		success: (data, dispatch) => {
			dispatch(receiveSaveScheduleForecastResponse(data));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const saveScheduleForecastAndNew = (forecast) => {
	const errors = validateScheduleForecast(forecast);
	if (errors.length > 0) return { type: SHOW_SCHEDULE_FORECAST_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/forecasts',
		data: forecast,
		startAction: SAVE_SCHEDULE_FORECAST,
		success: (data, dispatch) => {
			dispatch(receiveSaveScheduleForecastResponse(data));
			if (data.success) dispatch(createNewScheduleForecast());
		}
	});
};

export const deleteScheduleForecast = (forecast) => (
	createPostAction({
		passContext: true,
		url: `/api/schedule-forecasts/${forecast.scheduleForecastId}/delete`,
		data: forecast,
		startAction: DELETE_SCHEDULE_FORECAST,
		success: (data, dispatch) => {
			dispatch(receiveDeleteScheduleForecastResponse(data));
			// if (data.success) dispatch(createNewScheduleForecast());
		}
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_SCHEDULE_FORECASTS:
			return {
				...state,
				forecasts: null
			};
		case CLEAR_SCHEDULE_FORECAST:
			return {
				...state,
				forecast: null
			};
		case CLEAR_SAVE_RESULT:
			return {
				...state,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case EDIT_SCHEDULE_FORECAST:
			return {
				...state,
				forecast: action.forecast,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case REQUEST_SCHEDULE_FORECASTS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				forecasts: []
			};
		case RECEIVE_SCHEDULE_FORECASTS:
			return {
				...state,
				isLoading: false,
				forecasts: action.payload.data
			};
		case REQUEST_SCHEDULE_FORECAST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				forecast: globals.templates.forecast
			};
		case RECEIVE_SCHEDULE_FORECAST:
			return {
				...state,
				isLoading: false,
				forecast: action.payload.data
			};
		case DELETE_SCHEDULE_FORECAST:
			return { 
				...state,
				isLoading: true
			};
		case RECEIVE_DELETE_SCHEDULE_FORECAST_RESPONSE:
			return {
				...state,
				isLoading: false,
				forecasts: state.forecasts ? state.forecasts.filter(c => c.scheduleForecastId !== action.data.objectId) : []
			};
		case CREATE_NEW_SCHEDULE_FORECAST:
			return {
				...state,
				forecast: action.newForecast,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};	
		case SAVE_SCHEDULE_FORECAST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_SCHEDULE_FORECAST_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				forecasts: addOrUpdate(state.forecasts, action.data.object, { forecastId: action.data.object.forecastId }), 
				forecast: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_SCHEDULE_FORECAST_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
