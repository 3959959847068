import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { requestContract, clearContract } from '../../reducers/contracts';
import ContentContainer from '../contentContainer';
import MessagePanel from '../messagePanel';
import CloseContractWizardStartPage from './closeContractWizardStartPage';
import CloseContractWizardLotsPage from './closeContractWizardLotsPage';
import CloseContractWizardCertPage from './closeContractWizardCertPage';
import CloseContractWizardCorrespondencePage from './closeContractWizardCorrespondencePage';
import map from 'lodash/map';
import { fetch, addTask } from 'domain-task';
import { changeContractContext } from '../../reducers/context';
import PageLayout from '../pageLayout';
import Wizard from '../wizard/wizard';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { showModal } from '../../reducers/modal';
import { post } from '../../utils/ajax';

class CloseContractWizard extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			currentStep: 1,
			steps: [
				{ 
					number: 1, title: 'Start', active: true, completed: false 
				},
				{ 
					number: 2, title: 'Lots', active: false, completed: false 
				},
				{ 
					number: 3, title: 'Correspondence', active: false, completed: false 
				},
				{ 
					number: 4, title: 'Certificate', active: false, completed: false 
				},
				{ 
					number: 5, title: 'Finish', active: false, completed: false 
				}
			],
			openLots: null,
			openCorrespondence: null,
			canPrev: false,
			canNext: true,
			hasOpenLots: false,
			ignoredOpenLots: null,
			hasOpenCorrespondence: false,
			ignoredOpenCorrespondence: null,
			hasFinalCert: false,
			ignoredFinalCert: null
		};

		this.updateField = this.updateField.bind(this);
		this.onPrev = this.onPrev.bind(this);
		this.onNext = this.onNext.bind(this);
		this.changePage = this.changePage.bind(this);
		this.checkLots = this.checkLots.bind(this);
		this.onSelectLot = this.onSelectLot.bind(this);
		this.onSelectCorrespondence = this.onSelectCorrespondence.bind(this);
		this.checkCorrespondence = this.checkCorrespondence.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestContract(props.match.params.contractId));
	}
	static clearData(dispatch) {
		dispatch(clearContract());
	}
	componentDidMount() {
		CloseContractWizard.fetchData(this.props.dispatch, this.props);
	}

	onCancel = () => {
		this.props.history.push('/contracts');
	}

	changePage(pageNum) {
		if (pageNum < 1 || pageNum > this.state.steps.length) return;

		const steps = map(this.state.steps, (s) => (
			{
				...s,
				active: (s.number === pageNum)
			}
		));
		this.setState({ 
			...this.state,
			currentStep: pageNum,
			steps
		});	
	}
	onPrev() {
		const pageNum = this.state.currentStep - 1; 
		this.changePage(pageNum);

		switch (pageNum) {
			case 1:
				this.setState({
					...this.state,
					canPrev: false,
					canNext: true
				});
				break;
			default:
				this.setState({
					...this.state,
					canPrev: true,
					canNext: true
				});
		}
	}
	onNext() {
		const nextPageNum = this.state.currentStep + 1; 
		this.changePage(nextPageNum);

		this.setState({
			...this.state,
			canPrev: true
		});
		switch (nextPageNum) {
			case 2:
				this.checkLots();
				break;
			case 3:
				this.checkCorrespondence();
				break;
			default:
		}
	}

	updateField(field, newValue) {
		const closeArgs = this.state.closeArgs;
		closeArgs[field] = newValue;
        this.setState({
			...this.state,
			closeArgs
        });
	}
	checkLots() {
		this.setState({
			...this.state,
			openLots: null,
			hasOpenLots: false,
			isLoading: true
		});
		const fetchOpenLots = fetch(`/api/lots/open-lots?contractId=${this.props.contract.contractId}`, { 
			credentials: 'same-origin',
			headers: {
				'cache-control': 'no-store',
				'pragma': 'no-cache'
			}
		})
		.then(response => {
			if (response.status >= 200 && response.status < 300) return response.json();

			const error = new Error(response.statusText);
			error.response = response;
			throw error;
		});

		const fetchAll = Promise.all([
			fetchOpenLots
		])
		.then((data) => {
			this.setState({
				...this.state,
				openLots: data[0],
				hasOpenLots: data[0] && data[0].length > 0,
				isLoading: false
			});
		}).catch((error) => {
			console.log('request failed', error);
			this.props.showErrorNotification(error.message);
		});

		addTask(fetchAll);
	}
	checkCorrespondence() {
		this.setState({
			...this.state,
			hasOpenCorrespondence:  null,
			openCorrespondence: null,
			isLoading: true
		});
		const fetchOpenCorrespondence = fetch(`/api/correspondence/open-correspondence?contractId=${this.props.contract.contractId}`, { 
			credentials: 'same-origin',
			headers: {
				'cache-control': 'no-store',
				'pragma': 'no-cache'
			}
		})
		.then(response => {
			if (response.status >= 200 && response.status < 300) return response.json();

			const error = new Error(response.statusText);
			error.response = response;
			throw error;
		});

		const fetchAll = Promise.all([
			fetchOpenCorrespondence
		])
		.then((data) => {
			this.setState({
				...this.state,
				openCorrespondence: data[0],
				hasOpenCorrespondence: data[0] && data[0].length > 0,
				isLoading: false,
				canNext: data[0].length === 0
			});
		}).catch((error) => {
			console.log('request failed', error);
			this.props.showErrorNotification(error.message);
		});

		addTask(fetchAll);
	}
	checkFinalCert = () => {
		this.setState({
			...this.state,
			finalCert: null,
			hasFinalCert: false,
			isLoading: true
		});
		const fetFinalCert = fetch(`/api/final-certificate?contractId=${this.props.contract.contractId}`, { 
			credentials: 'same-origin',
			headers: {
				'cache-control': 'no-store',
				'pragma': 'no-cache'
			}
		})
		.then(response => {
			if (response.status === 204) return null;
			if (response.status >= 200 && response.status < 300) return response.json();

			const error = new Error(response.statusText);
			error.response = response;
			throw error;
		});

		const fetchAll = Promise.all([
			fetFinalCert
		])
		.then((data) => {
			this.setState({
				...this.state,
				finalCert: data[0],
				hasFinalCert: !!data[0],
				isLoading: false,
				canNext: true
			});
		}).catch((error) => {
			this.setState({
				...this.state,
				finalCert: null,
				hasFinalCert: false,
				isLoading: false
			});
			console.log('request failed', error);
			this.props.showErrorNotification(error.message);
		});

		addTask(fetchAll);
	}

	onSelectCorrespondence(correspondence) {
		if (this.props.currentContract.contractId !== correspondence.contractId) {
			this.props.changeContractContext(correspondence.contractId);
		}
		if (correspondence.incomingCorrespondenceId) {
			this.props.history.push(`/incoming-correspondence/${correspondence.incomingCorrespondenceId}`);
		} else {
			this.props.history.push(`/outgoing-correspondence/${correspondence.outgoingCorrespondenceId}`);
		}
	}
	onSelectLot(lot) {
		if (this.props.currentContract.contractId !== lot.contractId) {
			this.props.changeContractContext(lot.contractId);
		}
		this.props.history.push(`/lot/${lot.lotId}`);
	}

	closeContract =  () => {
		post({
			url: `/api/contracts/close`,
			data: {
				contractId: this.props.contract.contractId,
                hasOpenLots: this.state.hasOpenLots,
                ignoredOpenLots: this.state.ignoredOpenLots,
                hasOpenCorrespondence: this.state.hasOpenCorrespondence,
                ignoredOpenCorrespondence: this.state.ignoredOpenCorrespondence,
                hasFinalCertificate: this.state.hasFinalCert,
                ignoredFinalCertificate: this.state.ignoredFinalCert
			},
			onSuccess: data => {
				if (data.success) {
					this.props.history.push(`/contracts`);
					this.props.showSuccessNotification(data.message);
				} else {
					this.props.showErrorNotification(data.message);
				}
			},
			onError: (error) => {
				this.props.showErrorNotification(error.message);
			}
		});
	}

	render() {
		return (
<PageLayout
	className='close-contract'
	title={`Closing ${this.props.contract ? this.props.contract.contractName : ''}`}
	isLoading={this.props.isLoading}
	content={
	<div>
		<Wizard
			loading={this.props.isLoading || this.state.isLoading}
			success={this.props.closeResult.success}
			submitLabel="Close"
			onNext={(step) => {
				switch (step) {
					case 2:
						this.checkLots();
						break;
					case 3:
						this.checkCorrespondence();
						break;
					case 4:
						this.checkFinalCert();
						break;
					default:
				}
			}}
			onNextConfirm={(currentStep, next) => {
				switch (currentStep) {
					case 2:
						if (this.state.openLots != null && this.state.openLots.length > 0) {
							this.props.confirmNext(
								'Warning',
								'Do you wish to proceed with closing the Contract wihout closing all open Lots?',
								() => {
									this.setState({
										...this.state,
										ignoredOpenLots: new Date()
									});
									next();
								}
							);
							return;
						}
						break;
					case 3:
						if (this.state.openCorrespondence != null && this.state.openCorrespondence.length > 0) {
							this.props.confirmNext(
								'Warning',
								'Do you wish to proceed with closing the Contract without closing all open correspondence?',
								() => {
									this.setState({
										...this.state,
										ignoredOpenCorrespondence: new Date()
									});
									next();
								}
							);
							return;
						}
						break;
					case 4:
						if (this.state.finalCert == null) {
							this.props.confirmNext(
								'Warning',
								'Do you wish to proceed with closing the Contract without generating a Final Certificate?',
								() => {
									this.setState({
										...this.state,
										ignoredFinalCert: new Date()
									});
									next();
								}
							);
							return;
						}
						break;
					default:
				}
				next();
			}}
			onComplete={() => {
				this.closeContract();
			}}
			steps={[
				{ 
					title: 'Start', 
					content: 
						<ContentContainer>
							<CloseContractWizardStartPage />
						</ContentContainer>
				},
				{ 
					title: 'Lots', 
					content:
						<ContentContainer>
						<CloseContractWizardLotsPage 
							openLots={this.state.openLots}
							onSelectLot={this.onSelectLot}
							isLoading={this.state.isLoading}
						/>
						</ContentContainer>
				},
				{ 
					title: 'Correspondence', 
					content: 
						<ContentContainer>
						<CloseContractWizardCorrespondencePage 
							openCorrespondence={this.state.openCorrespondence}
							onSelectCorrespondence={this.onSelectCorrespondence}
							isLoading={this.state.isLoading}
						/>
						</ContentContainer>
				},
				{ 
					title: 'Certificate', 
					content: 
						<ContentContainer>
						<CloseContractWizardCertPage 
							finalCert={this.state.finalCert}
							isLoading={this.state.isLoading}
						/>
						</ContentContainer>
				},
				{ 
					title: 'Finish', 
					content: 
						<ContentContainer>
						<div>
							<p>
								You are ready to close the contract. Click Close to continue.
							</p>
						</div>
						</ContentContainer>
				}
			]}
		/>

		<MessagePanel isSuccess={this.props.closeResult.success} message={this.props.closeResult.message} />
	</div>
	}
/>
		);
	}
}

CloseContractWizard.propTypes = {
	dispatch: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	closeResult: PropTypes.object.isRequired,
	contract: PropTypes.object.isRequired,
	currentContract: PropTypes.object.isRequired,
	changeContractContext: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	isLoading: state.periods.isLoading,
	closeResult: state.closePeriod.closeResult,
	contract: state.contracts.contract,
	currentContract: state.context.contract
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	closeContract: (closeContract) => {
		dispatch(closeContract(closeContract));
	},
	changeContractContext: (contractId) => {
		dispatch(changeContractContext(contractId));
	},
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	confirmNext: (title, message, onYes) => {
		dispatch(showModal('YES_NO', {
			title: title,
			message: message,
			onYes: () => {
				if (onYes) onYes();
			}
		}));
	}

});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(CloseContractWizard);
