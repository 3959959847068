import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';

class DesignCorrespondencesHandoverContent extends React.Component {
	render() {
		const data = this.props.report;
		
		const columns = [
			{ name: 'drrNumber', label: 'DRR Number' },
			{ name: 'dateSent', label: 'Sent Date', dataType: 'date' },
			{ name: 'description', label: 'Description', headerStyle: { minWidth: '300px' } },
			{ name: 'raisedByName', label: 'Issued By' },
			{ name: 'addressTo', label: 'Issued To' },
			{ name: 'finalResponseDate', label: 'Response Date', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.status !== 'Open') {
						statusBackColour = '#35aa47';
						statusForeColour = '#111';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				} 
			},
			{ name: 'comments', label: 'Comments' }
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

DesignCorrespondencesHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default DesignCorrespondencesHandoverContent;
