import React from 'react';
import PropTypes from 'prop-types';

const HandoverSectionHeader = ({ children }) => 
	<div className="form-section-header">{children}</div>
;

HandoverSectionHeader.propTypes = {
	children: PropTypes.any
};

export default HandoverSectionHeader;
