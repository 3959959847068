import isDate from 'lodash/isDate';
import format from 'date-fns/format';
import isValidDate from 'date-fns/isValid';

export const parseDate = (dateString) => {
	if (!dateString) return null;
	if (isDate(dateString)) return dateString;

	if ((/^[0-9]{1,2}\/[0-9]\/{1,2}[0-9]{2,4}$/i).test(dateString)) {
		const parts = dateString.split('/');
		if (parts.length === 3) {
			return new Date(parts[2], Number(parts[1]) - 1, parts[0]);
		} else {
			return null;
		}	
	} else if ((/^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{2,4}$/i).test(dateString)) {
		const parts = dateString.split('.');
		if (parts.length === 3) {
			return new Date(parts[2], Number(parts[1]) - 1, parts[0]);
		} else {
			return null;
		}
	} else if ((/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{2,4}$/i).test(dateString)) {
		const parts = dateString.split('-');
		if (parts.length === 3) {
			return new Date(parts[2], Number(parts[1]) - 1, parts[0]);
		} else {
			return null;
		}	
	} else {
		return new Date(dateString);
	}
};

export const formatDate = (dateValue, formatString) => {
	if (!dateValue) return '';
	return isValidDate(dateValue) ? format(dateValue, formatString) : '';
};
