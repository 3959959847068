import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import SelectField from '../widgets/selectField';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import DateField from '../widgets/DateField';
import ContentContainer from '../contentContainer';
import SubContentContainer from '../subContentContainer';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Checkbox from '../widgets/Checkbox';
import FormSectionHeader from '../widgets/formSectionHeader';
import FloatingActionButton from '@material-ui/core/Fab';
import ContentAdd from '@material-ui/icons/Add';
import PersistedDataTable from '../widgets/persistedDataTable';
import LotDialog from './lotDialog';
import map from 'lodash/map';
import filter from 'lodash/filter';
import CorrespondencePicker from '../widgets/correspondencePicker';
import TagField from '../widgets/TagField';

class NCRForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			showLotDialog: false,
			lots: null,
			ncr: props.ncr,
			showCorrespondencePicker: false
		};

		this.showAddLotDialog = this.showAddLotDialog.bind(this);
		this.setSelectedLots = this.setSelectedLots.bind(this);
		this.onCancelLotDialog = this.onCancelLotDialog.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			ncr: nextProps.ncr
		});
	}

	showAddLotDialog() {
		this.setState({ showLotDialog: true });
	}

	setSelectedLots(lots) {
		this.props.setSelectedLots(lots);
		this.setState({ 
			showLotDialog: false 
		});
	}

	onCancelLotDialog() {
		this.setState({ showLotDialog: false });
	}

	addLinkedCorrespondence = () => {
		this.setState({ showCorrespondencePicker: true });
	}

	onSelectCorrespondence = (selectedCorrespondence) => {
		this.props.onSelectCorrespondence(selectedCorrespondence);
		this.setState({
			showCorrespondencePicker: false
		});
	}
	onCancelCorrespondencePicker = () => {
		this.setState({ showCorrespondencePicker: false });
	}

	render() {
		const ncr = this.state.ncr || {};
		const lots = ncr.lots || [];
		const linkedCorrespondence = ncr.linkedCorrespondence || [];

		const columns = [
			{ name: 'lotNo', label: 'Lot' },
			{ name: 'lotDescription', label: 'Description' }
		];

		if (this.props.canEdit) {
			columns.unshift({
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.deleteLot(args.data); } }>
							<ActionDelete />
						</IconButton>
					</div>
			});
		}

		const linkedCorrespondenceColumns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.viewLinkedCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<VisibilityIcon />
						</IconButton>
						{this.props.canEdit && 
							<IconButton onClick={ () => { this.props.deleteLinkedCorrespondence(args.data); } }>
								<ActionDelete />
							</IconButton>
						}
					</div>				
			},
			{ 
				name: 'correspondenceType', 
				label: 'Type'
			},
			{ name: 'correspondenceNumber', label: 'Correspondence Number' },
			{ name: 'correspondenceDate', label: 'Date', dataType: 'date' }
		];

		return (
<div>
	<CorrespondencePicker 
		show={this.state.showCorrespondencePicker} 
		types={['Incoming', 'Outgoing']}
		selectedCorrespondence={{
			incomingCorrespondence: map(filter(ncr.linkedCorrespondence, (c) => c.correspondenceType === 'Incoming'), (c) => ({ incomingCorrespondenceId: c.correspondenceId })),
			outgoingCorrespondence: map(filter(ncr.linkedCorrespondence, (c) => c.correspondenceType === 'Outgoing'), (c) => ({ outgoingCorrespondenceId: c.correspondenceId }))
		}}
		onOk={this.onSelectCorrespondence} 
		cancel={this.onCancelCorrespondencePicker} 
	/>

	<LotDialog 
		show={this.state.showLotDialog} 
		setSelectedLots={this.setSelectedLots} 
		cancel={this.onCancelLotDialog} 
		lots={this.props.lots}
	/>

	<ContentContainer>
		<FormFieldContainer classes={['s12', 'm4']}>
			<TextField
				value={ncr.contractorNcrNo}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('contractorNcrNo', v); }}
				label="Contractor NCR Number"
				errorText={getFieldErrorText(this.props.saveResult, 'ContractorNcrNo')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm8']}>
			<TextField
				value={ncr.carRefNo}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('carRefNo', v); }}
				label="CAR Ref No"
				errorText={getFieldErrorText(this.props.saveResult, 'CarRefNo')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm4']}>
			<DateField
				value={ncr.dateIdentified} 
				label="Date Identified" 
				onChange={(d) => { this.props.updateField('dateIdentified', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm4']}>
			<DateField
				value={ncr.dateCarRaised} 
				label="Date CAR Raised" 
				onChange={(d) => { this.props.updateField('dateCarRaised', d); }}
				error={getFieldErrorText(this.props.saveResult, 'DateCarRaised')}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm4']}>
			<DateField
				value={ncr.dateNcrRaised} 
				label="Date NCR Raised" 
				onChange={(d) => { this.props.updateField('dateNcrRaised', d); }}
				error={getFieldErrorText(this.props.saveResult, 'DateNcrRaised')}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={ncr.details}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('details', v); }}
				label="Details"
				errorText={getFieldErrorText(this.props.saveResult, 'Details')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={ncr.correctiveAction}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('correctiveAction', v); }}
				label="Corrective Action"
				errorText={getFieldErrorText(this.props.saveResult, 'CorrectiveAction')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={ncr.preventativeAction}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('preventativeAction', v); }}
				label="Preventative Action"
				errorText={getFieldErrorText(this.props.saveResult, 'PreventativeAction')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TagField
				label="Tags" 
				fullWidth={true}
				value={ncr.tags}
				onChange={tags => this.props.updateField('tags', tags)}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={ncr.comments}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('comments', v); }}
				label="Comments"
				errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
			/>
		</FormFieldContainer>

		<FormRow>
			<FormFieldContainer classes={['s12', 'm6', 'hasToggle']}>
				<Checkbox
					label="Accepted"
					labelPosition="right"
					checked={ncr.accepted}
					onCheck={(e, v) => { this.props.updateField('accepted', v); }}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={['s12', 'm6']}>
				<DateField
					value={ncr.responseDate} 
					label="Response Date" 
					onChange={(d) => { this.props.updateField('responseDate', d); }}
					error={getFieldErrorText(this.props.saveResult, 'ResponseDate')}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField
				value={ncr.dateResubmitted} 
				label="Date Resubmitted" 
				onChange={(d) => { this.props.updateField('dateResubmitted', d); }}
				error={getFieldErrorText(this.props.saveResult, 'DateResubmitted')}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField 
				value={ncr.dateClosed} 
				label="Date Closed" 
				onChange={(d) => { this.props.updateField('dateClosed', d); }}
				error={getFieldErrorText(this.props.saveResult, 'DateClosed')}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormSectionHeader text="Status" />

		<FormFieldContainer>
			<SelectField 
				value={ncr.status} 
				fullWidth={true}
				label="Status" 
				onChange={(v) => { this.props.updateField('status', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'Status')}
				items={[
					{ value: 'Open', label: 'Open' },
					{ value: 'Closed', label: 'Closed' }
				]}
			>
			</SelectField>
		</FormFieldContainer>

	</ContentContainer>

	<SubContentContainer header="Lots">
		<PersistedDataTable 
			id="lots"
			data={lots} 
			columns={columns}
		/>

		{this.props.canEdit &&
			<div className="row">
				<FloatingActionButton onClick={this.showAddLotDialog} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
					<ContentAdd />
				</FloatingActionButton>
			</div>
		}
	</SubContentContainer>

	<SubContentContainer header="Correspondence" style={{ marginTop: '10px' }}>
		<PersistedDataTable 
			id="linkedCorrespondence"
			data={linkedCorrespondence} 
			columns={linkedCorrespondenceColumns}
		/>

		{this.props.canEdit &&
			<div className="row">
				<FloatingActionButton onClick={this.addLinkedCorrespondence} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
					<ContentAdd />
				</FloatingActionButton>
			</div>
		}
	</SubContentContainer>
</div>
		);
	}
}

NCRForm.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	updateField: PropTypes.func.isRequired,
	deleteLot: PropTypes.func.isRequired,
	setSelectedLots: PropTypes.func.isRequired,
	ncr: PropTypes.object.isRequired,
	lots: PropTypes.array.isRequired,
	saveResult: PropTypes.object.isRequired,
	onSelectCorrespondence: PropTypes.func.isRequired,
	deleteLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondence: PropTypes.func.isRequired
};

export default NCRForm;
