import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import ContentContainer from '../contentContainer';
import SubContentContainer from '../subContentContainer';
import PersistedDataTable from '../widgets/persistedDataTable';
import map from 'lodash/map';
import some from 'lodash/some';
import find from 'lodash/find';

class RoleForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			role: props.role
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			role: nextProps.role
		});
	}

	render() {
		const role = this.state.role;
		const rolePermissions = map(this.props.permissions, p => ({
			_selected: some(role.permissions, rp => rp.permission === p.key),
			...p
		}));

		const columns = [
			{ name: 'name', label: 'Permission' },
            { 
                label: 'Needs',
                cellRenderer: args => map(args.data.dependencies, d => {
                    const p = find(rolePermissions, rp => rp.key === d);
                    return p ? p.name : d;
                }).join(', ')
            }
		];
		return (
<div>
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={role.name}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('name', v); }}
				label="Role Name"
				errorText={getFieldErrorText(this.props.saveResult, 'Name')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={role.description}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('description', v); }}
				label="Description"
				errorText={getFieldErrorText(this.props.saveResult, 'Description')}
			/>
		</FormFieldContainer>
	</ContentContainer>

	<SubContentContainer header="Role Permissions">
		<PersistedDataTable 
			id="rolePermissions"
			data={rolePermissions} 
			columns={columns}
			enableSelection={true}
			onSelectionChanged={this.props.onSelectionChanged}
			isRowSelected={this.props.isRowSelected}
			groupBy="group"
			rowsPerPage='All'
		/>
	</SubContentContainer>
</div>
		);
	}
}

RoleForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	role: PropTypes.object.isRequired,
	permissions: PropTypes.array.isRequired,
	saveResult: PropTypes.object.isRequired,
	onSelectionChanged: PropTypes.func.isRequired,
	isRowSelected: PropTypes.func.isRequired
};

export default RoleForm;
