import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';

const IconButton = props =>
	<MuiIconButton {...props} />;
	

IconButton.propTypes = {
};

IconButton.defaultProps = {
};

export default IconButton;
