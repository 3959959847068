import React from 'react';
import connectedRouterHOC from '../../../utils/connectedRouterHOC';
import PropTypes from 'prop-types';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions,
	Grid
} from '@material-ui/core';
import Button from '../../widgets/button';
import { withForm } from '../../../utils/forms';
import Form from '../../widgets/Form';

const form = {
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "name",
			label: "Name",
			required: true
		},
		{
			name: "tradingName",
			label: "Trading Name"
		},
		{
			name: "abn",
			label: "ABN"
		},
		{
			name: "address",
			label: "Address"
		}
	]
};

const ContractorDialog = props => {
	const classes = props.classes;
	const { fields, values, isNew, contractor, updateValues } = props;

	React.useEffect(() => {
		updateValues(contractor);
	}, contractor);

	const onClose = () => {
		props.onCancel();
	};
	const onSubmit = () => {
		if (props.validateFields()) {
			props.onOk(values);
		} 
	}
	return (
		<Dialog fullWidth={true} maxWidth="lg" open={props.show} onClose={onClose} style={{ zIndex: 9990 }} classes={classes}>
			<DialogTitle>{props.isNew ? "Add Contractor" : "Edit Contractor"}</DialogTitle>
			<DialogContent style={{ }}>
				<Form 
					onSubmit={onSubmit}
					style={{ }}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>{fields.name()}</Grid>
						<Grid item xs={12} md={6}>{fields.tradingName()}</Grid>
						<Grid item xs={12} md={6}>{fields.abn()}</Grid>
						<Grid item xs={12} md={6}>{fields.address()}</Grid>
					</Grid>
				</Form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
	 			<Button onClick={onSubmit}>{isNew ? "Add" : "Update"}</Button>
			</DialogActions>
		</Dialog>
	);
};

ContractorDialog.propTypes = {
	show: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired
};

ContractorDialog.defaultProps = {
	show: false
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(withForm(form)(ContractorDialog));
