import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';
import addDays from 'date-fns/addDays';

class PracticalCompletionsGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.practicalCompletions || this.props.practicalCompletions.length !== nextProps.practicalCompletions.length;
	}

	render() {
		const practicalCompletions = this.props.practicalCompletions || [];

		const columns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => {
					if (this.props.canEdit) {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.onEditPracticalCompletion(args.data); } } style={{ padding: '2px' }}>
									<EditIcon />
								</IconButton>
								<IconButton onClick={ () => { this.props.onDeletePracticalCompletion(args.data); } } style={{ padding: '2px' }}>
									<ActionDelete />
								</IconButton>
							</div>
						);
					} else {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.onViewPracticalCompletion(args.data); } } style={{ padding: '2px' }}>
									<ViewIcon />
								</IconButton>
							</div>
						);
					}
				}	
			},
			{ name: 'completedByName', label: 'Completed By' },
			{ name: 'separablePortionList', label: 'Separable Portions' },
			{ name: 'completionDate', label: 'Date of PC', dataType: 'date' },
			{ name: 'maintenancePeriod', label: 'Maintenance Period' },
			{ 
				name: 'maintenanceDate', 
				label: 'Off Maintenance Date', 
				dataType: 'date',
				value: (args) => args.data.maintenancePeriod > 0 ? addDays(args.data.completionDate, args.data.maintenancePeriod) : args.data.completionDate
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		return (
			<PersistedDataTable 
				id="practicalCompletions"
				data={practicalCompletions} 
				columns={columns}
			/>
		);
	}
}

PracticalCompletionsGrid.propTypes = {
	practicalCompletions: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditPracticalCompletion: PropTypes.func.isRequired,
	onViewPracticalCompletion: PropTypes.func.isRequired,
	onDeletePracticalCompletion: PropTypes.func.isRequired
};

export default PracticalCompletionsGrid;
