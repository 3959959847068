import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OutgoingCorrespondenceHandoverContent from '../content/outgoingCorrespondenceHandoverContent';
import addHandoverWrapper from '../handoverWrapper';

class OutgoingCorrespondenceHandover extends React.Component {
	static getTitle(report) {
		return `Outgoing Correspondence ${report.rfiNumber}`;
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<OutgoingCorrespondenceHandoverContent report={report} />
		);
	}
}

OutgoingCorrespondenceHandover.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	OutgoingCorrespondenceHandover, {
		title: 'Outgoing Correspondence',
		className: 'outgoing-correspondence-handover'
	})
);
