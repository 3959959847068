import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import { injectIntl, intlShape } from 'react-intl';
import isNumber from 'lodash/isNumber';
import { toNumber } from '../../utils/utils';

class NumericField extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			value: props.value,
			displayValue: this.getDisplayValue(props.value)
		};

		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value,
			displayValue: this.getDisplayValue(nextProps.value)
		});
	}

	getDisplayValue(value) {
		if (value === '' && this.props.nullable) {
			return '';
		}

		switch (this.props.type) {
			case 'currency':
				return isNumber(value) ? this.props.intl.formatNumber(value, { style: 'currency', currency: 'AUD' }) : value;
			case 'percent':
				return isNumber(value) ? this.props.intl.formatNumber(value, { style: 'percent', maximumFractionDigits: 2 }) : value;
			default:
				return (value === undefined || value === null) ? '' : value;
		}
	}
	onChange(e, v) {
		if (this.props.editable === false) return;

		this.setState({
			value: v,
			displayValue: v
		});
		if (this.props.onInput) this.props.onInput(v, v);
	}
	onFocus() {
	}

	onBlur(e) {
		const inputValue = e.target.value;
		let value;
		let displayValue;
		if (inputValue === '') {
			value = '';
			displayValue = '';
		} else {
			switch (this.props.type) {
				case 'int':
					value = Math.round(toNumber(inputValue));
					break;
				case 'percent':
					value = toNumber(inputValue) / 100.00;
					break;
				default:
					value = toNumber(inputValue);
					break;
			}
			displayValue = this.getDisplayValue(value);
		}
		if (value === '') {
			if (this.props.nullable) {
				value = null;
				displayValue = '';
			} else {
				value = 0;
				displayValue = '0';
			}
		}

		this.setState({
			value: value,
			displayValue: displayValue
		});
		if (this.props.onChange) this.props.onChange(value, displayValue);
	}
	render() {
		const displayValue = this.state.displayValue;
		const fieldStyles = {
			// floatingLabelStyle: { top: '24px' },
			// style: { height: '58px' },
			// inputStyle: { marginTop: '6px' }
		};
		const mergedProps = { ...fieldStyles, ...this.props };
		delete mergedProps.nullable;
		delete mergedProps.disableUnderline;

		return (
			<TextField 
				{...mergedProps}
				variant={this.props.variant}
				onInput={null}
				onChange={this.onChange} 
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				value={displayValue} 
				underlineShow={this.props.disableUnderline !== true}
			/>
		);
	}
}

NumericField.propTypes = {
	type: PropTypes.oneOf(['int', 'decimal', 'currency', 'percent']),
	value: PropTypes.any,
	nullable: PropTypes.bool,
	editable: PropTypes.bool,
	onChange: PropTypes.func,
	onInput: PropTypes.func,
	intl: intlShape.isRequired,
	disableUnderline: PropTypes.bool,
	variant: PropTypes.string
};

NumericField.defaultProps = {
	type: 'int',
	editable: true,
	nullable: false,
	value: null,
	onInput: () => {},
	onChange: () => {},
	disableUnderline: false,
	variant: 'filled'
};

export default injectIntl(NumericField);
