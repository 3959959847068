import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import ContentContainer from '../contentContainer';
import DateField from '../widgets/DateField';
import Checkbox from '../widgets/Checkbox';
import SelectField from '../widgets/selectField';
import NumericField from '../widgets/numericField';
import FormSectionHeader from '../widgets/formSectionHeader';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import map from 'lodash/map';

class EventForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			event: props.event
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			event: nextProps.event
		});
	}


	render() {
		const event = this.state.event;

		return (
<div>
	<ContentContainer>
	<FormFieldContainer>
			<TextField
				value={event.name}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('name', v); }}
				label="Name"
				errorText={getFieldErrorText(this.props.saveResult, 'Name')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={event.description}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('description', v); }}
				label="Description"
				errorText={getFieldErrorText(this.props.saveResult, 'Description')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<DateField 
				value={event.eventDate} 
				label="Event Date" 
				onChange={(d) => { this.props.updateField('eventDate', d); } }
				fullWidth={true}
				required={true}
				showTime={true}
				errorText={getFieldErrorText(this.props.saveResult, 'EventDate')}
			/>
		</FormFieldContainer>
		
		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true, hasToggle: true }} infoText="Set to true to send notifications based on Action Due Date">
				<Checkbox
					label="Notification"
					labelPosition="right"
					checked={event.notification}
					onCheck={(e, v) => { this.props.updateField('notification', v); }}
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<TextField
					value={event.notificationEmail}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('notificationEmail', v); }}
					label="Notification Email"
					errorText={getFieldErrorText(this.props.saveResult, 'NotificationEmail')}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormSectionHeader text={
			<React.Fragment>
				<span>Reminders</span>
				<IconButton 
					onClick={() => {
						const reminders = event.reminders || [];
						reminders.push({
							timeUnit: 'Days',
							timeLength: 3
						});
						this.props.updateField('reminders', reminders);
					}} 
					size="small" 
					color="secondary" 
					style={{ marginLeft: '5px', marginTop: '-3px' }}
				>
					<AddIcon  />
				</IconButton>
			</React.Fragment>
		} />

		{
			map(event.reminders, (r, i) => 
				<FormRow key={`event-reminder-${i}`}>
					<IconButton 
						onClick={() => {
							const reminders = event.reminders || [];
							reminders.splice(i, 1);
							this.props.updateField('reminders', reminders);
						}} 
						size="small" 
					>
						<DeleteIcon  />
					</IconButton>
					<NumericField 
						container="inline" 
						value={r.timeLength} 
						label="" 
						variant="standard"
						disableUnderline={true}
						style={{ width: '40px', marginLeft: '10px' }}
						onChange={(v) => { 
							const reminders = event.reminders || [];
							reminders[i].timeLength = v;
							this.props.updateField('reminders', reminders);
						}}
						fullWidth={false}
					/>
					<SelectField 
						value={r.timeUnit} 
						label="" 
						onChange={(v) => { 
							const reminders = event.reminders || [];
							reminders[i].timeUnit = v;
							this.props.updateField('reminders', reminders);
						}}
						variant="standard"
						disableUnderline={true}
						fullWidth={false}
						items={[
							{ value: 'Minutes', label: 'Minutes' },
							{ value: 'Hours', label: 'Hours' },
							{ value: 'Days', label: 'Days' },
							{ value: 'Weeks', label: 'Weeks' }
						]}
					>
					</SelectField>
				</FormRow>
			)
		}

		<FormFieldContainer>
			<TextField
				value={event.comments}
				fullWidth={true}
				multiline={true}
				onChange={(e, v) => { this.props.updateField('comments', v); }}
				label="Comments"
				errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
			/>
		</FormFieldContainer>
	</ContentContainer>
</div>
		);
	}
}

EventForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	event: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default EventForm;
