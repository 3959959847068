import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProgressIndicator = () => (
	<CircularProgress style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '1em', marginBottom: '1em' }} />
);

ProgressIndicator.propTypes = {
};


export default ProgressIndicator;
