import React from 'react';
import {
    useHistory, useLocation, useParams
} from 'react-router-dom';  

export const routerHOC = Component => {
    return props => {
        const history = useHistory();
        const location = useLocation();
        const params = useParams();

        return (
            <Component 
                history={history} 
                location={location} 
                params={params} 
                {...props} 
            />
        );
    };
};
