import React from 'react';
import PropTypes from 'prop-types';
import MessagePanel from '../messagePanel';

function addHandoverWrapper(WrappedComponent, args) {
	const wrappedClass = class extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				report: null,
				title: args.title,
				success: false,
				message: ''
			};
		}

		componentDidMount() {
			// Add method to allow handover to be rendered externally
			window.renderHandover = (type, d) => {
				const d2 = JSON.parse(d);
				const data = d2.data.data;
				this.setState({
					title: WrappedComponent.getTitle ? WrappedComponent.getTitle(data) : type,
					report: data
				}, () => {
					window.status = 'ready_to_print';
				});
			};
			window.status = 'ready_for_data';
		}

		render() {
			return (
<div className="handover">
    <section className={`content ${args.className}`}>
		<div className="report-container">
			<div className='header'>
				<h1>{this.state.title}</h1>
			</div>

			<div className="logo">
				<img src="/images/contrack_logo_200x58.jpg" alt="" />
			</div>

			<WrappedComponent 
				{...this.props} 
				report={this.state.report} 
				isLoading={this.state.isLoading} 
				success={this.state.success} 
				message={this.state.success} 
			/>
		</div>

		<MessagePanel isSuccess={this.state.success} message={this.state.message} />
    </section>

	{/* Add div to indicate rendering has completed for PDF generation */}
	{!this.state.isLoading &&
		<div id="report-rendering-complete"></div>
	}
</div>
			);
		}
	};

	wrappedClass.propTypes = {
		report: PropTypes.object
	};

	wrappedClass.defaultProps = {
		report: undefined
	};

	return wrappedClass;
}

export default addHandoverWrapper;
