import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';
import AttachmentIcon from '@material-ui/icons/AttachFile';

class ExtensionsOfTimeGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.extensionsOfTime || this.props.extensionsOfTime.length !== nextProps.extensionsOfTime.length;
	}

	render() {
		const extensionsOfTime = this.props.extensionsOfTime || [];

		const columns = [
			{ name: 'extensionOfTimeNumber', label: 'EOT Number' },
			{ name: 'date', label: 'Date', dataType: 'date' },
			{ name: 'description', label: 'EOT Description' },
			{ 
				name: 'status', 
				label: 'EOT Status',
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Approved', 'Rejected'] 
			},
			{ 
				name: 'daysApproved', 
				label: 'Number of Days Approved',
				showTotal: true,
				headerStyle: {
					whiteSpace: 'normal'
				},
			},
			{ 
				name: 'daysClaimedByContractor', 
				label: 'Days Claimed by Contractor',
				showTotal: true,
				headerStyle: {
					whiteSpace: 'normal'
				}
			},
			{ name: 'approvedByName', label: 'Approved By' },
			{ name: 'previousPracticalCompletionDate', label: 'Previous Date for PC', dataType: 'date' },
			{ name: 'revisedPracticalCompletionDate', label: 'Revised Date for PC', dataType: 'date' },
			{ 
				name: 'hasAttachment', 
				label: <AttachmentIcon />,
				fullLabel: 'Has Attachments',
				dataType: 'bool',
				value: args => args.data.files && args.data.files.length > 0,
				cellRenderer: args => 
					args.data.files && args.data.files.length > 0 ? <AttachmentIcon /> : <span></span>,
				cellStyle: {
					textAlign: 'center'
				},
				filterOperators: ['=']
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditExtensionOfTime(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteExtensionOfTime(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewExtensionOfTime(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="extensionsOfTime"
				data={extensionsOfTime} 
				columns={columns}
			/>
		);
	}
}

ExtensionsOfTimeGrid.propTypes = {
	extensionsOfTime: PropTypes.array,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditExtensionOfTime: PropTypes.func.isRequired,
	onViewExtensionOfTime: PropTypes.func.isRequired,
	onDeleteExtensionOfTime: PropTypes.func.isRequired
};

ExtensionsOfTimeGrid.defaultProps = {
	extensionsOfTime: []
};

export default ExtensionsOfTimeGrid;
