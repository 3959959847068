
const getElementInAnotherTableCell = (direction, startingElement, targetCellElement) => {
	// First move up parent nodes until we have the TD element
	let parentElement = startingElement;
	while (parentElement.tagName !== 'TD') {
		parentElement = parentElement.parentNode;
	}
	const td = parentElement;

	// Next move up until we have the TR element
	while (parentElement.tagName !== 'TR') {
		parentElement = parentElement.parentNode;
	}
	let tr = parentElement;

	// Get the index of the TD in the TR
	let cell = tr.children[0];
	let colNum = 1;
	while (cell !== td) {
		colNum += 1;
		cell = cell.nextSibling;
	}

	const sibling = direction === 'up' ? 'previousSibling' : 'nextSibling';
	tr = tr[sibling];
	while (tr) {
		cell = tr.children.length > 0 ? tr.children[0] : null;

		let i = 0;
		if (cell) i += cell.colSpan ? cell.colSpan : 1;
		while (cell && i < colNum) {
			cell = cell.nextSibling;
			if (cell) i += cell.colSpan ? cell.colSpan : 1;
		}
		if (cell && cell.children.length > 0) {
			// Get the target element in the cell
			let childElement = cell.children[0];
			while (childElement && childElement.tagName !== targetCellElement && childElement.children) {
				childElement = childElement.children.length > 0 ? childElement.children[0] : null;
			}
			if (childElement) return childElement;
		}

		tr = tr[sibling];
	}

	return null;
};

export const getElementInTableCellAbove = (startingElement, targetCellElement) =>
	getElementInAnotherTableCell('up', startingElement, targetCellElement);

export const getElementInTableCellBelow = (startingElement, targetCellElement) =>
	getElementInAnotherTableCell('down', startingElement, targetCellElement);
