import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import { globals } from '../globals';
import cloneDeep from 'lodash/cloneDeep';
import { showErrorNotification, showSuccessNotification } from './notifications';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	tag: null, 
	tags: null
};

const CLEAR_TAGS = 'CLEAR_TAGS';
const CLEAR_TAG = 'CLEAR_TAG';
const REQUEST_TAG = 'REQUEST_TAG';
const RECEIVE_TAG = 'RECEIVE_TAG';
const REQUEST_TAGS = 'REQUEST_TAGS';
const RECEIVE_TAGS = 'RECEIVE_TAGS';
const CREATE_NEW_TAG = 'CREATE_NEW_TAG';
const SAVE_TAG = 'SAVE_TAG';
const RECEIVE_SAVE_TAG_RESPONSE = 'RECEIVE_SAVE_TAG_RESPONSE';
const DELETE_TAG = 'DELETE_TAG';
const RECEIVE_DELETE_TAG_RESPONSE = 'RECEIVE_DELETE_TAG_RESPONSE';
const SHOW_TAG_ERRORS = 'SHOW_TAG_ERRORS';

export const clearTags = () => ({ type: CLEAR_TAGS });
export const clearTag = () => ({ type: CLEAR_TAG });
export const receiveTags = (data) => ({ type: RECEIVE_TAGS, payload: { data } });
export const receiveTag = (data) => ({ type: RECEIVE_TAG, payload: { data } });
export const createNewTag = () => ({ type: CREATE_NEW_TAG });
export const receiveSaveTagResponse = (data) => ({ type: RECEIVE_SAVE_TAG_RESPONSE, data });
export const receiveDeleteTagResponse = (data) => ({ type: RECEIVE_DELETE_TAG_RESPONSE, data });

export const requestTags = () => (
	createFetchAction({
		objectName: 'Tags',
		passContext: true,
		url: '/api/tags',
		startAction: REQUEST_TAGS,
		success: (data) => 
			receiveTags(data)
	})
);

export const requestTag = (tagId) => (
	createFetchAction({
		objectName: 'Tag',
		passContext: true,
		url: `/api/tags/${tagId}`,
		startAction: REQUEST_TAG,
		success: (data) => 
			receiveTag(data)
	})
);

export const saveTag = (tag, onSuccess) => {
	const errors = [];
	if (!tag.name) {
		errors.push({
			fieldName: 'Name',
			valid: false,
			message: 'Name is required'
		});
	}
	if (errors.length > 0) return { type: SHOW_TAG_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/tags',
		data: tag,
		startAction: SAVE_TAG,
		success: (data, dispatch) => {
			dispatch(receiveSaveTagResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteTag = (tag) => (
	createPostAction({
		passContext: true,
		url: `/api/tags/${tag.tagId}/delete`,
		data: tag,
		startAction: DELETE_TAG,
		success: (data, dispatch) => {
			dispatch(receiveDeleteTagResponse(data));
			if (data) {
				if (data.success) dispatch(showSuccessNotification(data.message));
				if (!data.success) dispatch(showErrorNotification(data.message));
			}
		}
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_TAGS:
			return {
				...state,
				tags: null
			};
		case CLEAR_TAG:
			return {
				...state,
				tag: null
			};
		case REQUEST_TAGS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				tags: []
			};
		case RECEIVE_TAGS:
			return {
				...state,
				isLoading: false,
				tags: action.payload.data
			};
		case REQUEST_TAG:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				tag: { ...cloneDeep(globals.templates.tag) }
			};
		case RECEIVE_TAG:
			return {
				...state,
				isLoading: false,
				tag: action.payload.data
			};
		case DELETE_TAG:
			return { 
				...state
			};
		case RECEIVE_DELETE_TAG_RESPONSE:
			return {
				...state,
				tags: state.tags.filter(e => e.tagId !== action.data.objectId) 
			};
		case CREATE_NEW_TAG:
			return {
				...state,
				tag: { ...cloneDeep(globals.templates.tag) }
			};	
		case SAVE_TAG:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_TAG_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				tags: addOrUpdate(state.tags, action.data.object, { tagId: action.data.object.tagId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_TAG_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
