import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import Checkbox from '../widgets/Checkbox';

const ContactForm = (props) => {
	const contact = props.contact;

	return (
		<div>
			<FormFieldContainer>
				<TextField
					value={contact.name}
					fullWidth={true}
					onChange={(e, v) => { props.updateField('name', v); }}
					label="Name"
					errorText={getFieldErrorText(props.saveResult, 'Name')}
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<TextField
					value={contact.email}
					fullWidth={true}
					onChange={(e, v) => { props.updateField('email', v); }}
					label="Email"
					errorText={getFieldErrorText(props.saveResult, 'Email')}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true, hasToggle: true }}>
				<Checkbox
					label="Is Active"
					labelPosition="right"
					checked={contact.isActive}
					onCheck={(e, v) => { props.updateField('isActive', v); }}
				/>
			</FormFieldContainer>
		</div>
	);
};

ContactForm.propTypes = {
	contact: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default ContactForm;
