import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

const SubContentContainer = ({ 
	header, subHeader, children, style, onDragOver, onDrop, onDragStart
}) => {
	const paperStyle = {
		padding: '10px',
		...style
	};
	return (
		<Paper style={paperStyle} className='clearfix' onDrag={onDragStart} onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onDrop}>
			<div style={{ 
				borderBottom: '1px solid #eee',
				paddingBottom: '2px',
				fontWeight: 'normal',
				backgroundColor: '#0E76BC',
				color: 'white',
				margin: '-10px -10px 10px -10px',
				padding: '10px' 
			}}
			>
				<h3 style={{
					display: 'inline' 
				}}
				>
					{header}
				</h3>
				{subHeader &&
					<h4 style={{
							display: 'inline',
							fontSize: '14px',
							fontWeight: 'normal',
							fontStyle: 'italic',
							marginLeft: '10px' 
						}}
						
					>
						{subHeader}
					</h4>
				}
			</div>

			{children}
		</Paper>
	);
};

SubContentContainer.propTypes = {
	children: PropTypes.any,
	header: PropTypes.string.isRequired,
	subHeader: PropTypes.string,
	style: PropTypes.object,
	onDragOver: PropTypes.func,
	onDrop: PropTypes.func,
	onDragStart: PropTypes.func
};

SubContentContainer.defaultProps = {
	children: undefined,
	subHeader: undefined,
	style: undefined,
	onDragOver: undefined,
	onDrop: undefined,
	onDragStart: undefined
};

export default SubContentContainer;
