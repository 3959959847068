import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { addOrUpdate } from '../utils/utils';
import { globals } from '../globals';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	lotAdjustment: null, 
	lotAdjustments: null
};

const CLEAR_LOT_ADJUSTMENTS = 'CLEAR_LOT_ADJUSTMENTS';
const CLEAR_LOT_ADJUSTMENT = 'CLEAR_LOT_ADJUSTMENT';
const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT';
const DELETE_LOT_ADJUSTMENT = 'DELETE_LOT_ADJUSTMENT';
const REQUEST_LOT_ADJUSTMENTS = 'REQUEST_LOT_ADJUSTMENTS';
const RECEIVE_LOT_ADJUSTMENTS = 'RECEIVE_LOT_ADJUSTMENTS';
const REQUEST_LOT_ADJUSTMENT = 'REQUEST_LOT_ADJUSTMENT';
const RECEIVE_LOT_ADJUSTMENT = 'RECEIVE_LOT_ADJUSTMENT';
const CREATE_NEW_LOT_ADJUSTMENT = 'CREATE_NEW_LOT_ADJUSTMENT';
const SAVE_LOT_ADJUSTMENT = 'SAVE_LOT_ADJUSTMENT';
const RECEIVE_SAVE_LOT_ADJUSTMENT_RESPONSE = 'RECEIVE_SAVE_LOT_ADJUSTMENT_RESPONSE';
const RECEIVE_DELETE_LOT_ADJUSTMENT_RESPONSE = 'RECEIVE_DELETE_LOT_ADJUSTMENT_RESPONSE';
const SHOW_LOT_ADJUSTMENT_ERRORS = 'SHOW_LOT_ADJUSTMENT_ERRORS';
const EDIT_LOT_ADJUSTMENT = 'EDIT_LOT_ADJUSTMENT';
const RECEIVE_LOT_ADJUSTMENTS_ERROR_RESPONSE = 'RECEIVE_LOT_ADJUSTMENTS_ERROR_RESPONSE';

export const clearLotAdjustments = () => ({ type: CLEAR_LOT_ADJUSTMENTS });
export const clearLotAdjustment = () => ({ type: CLEAR_LOT_ADJUSTMENT });
export const clearSaveResult = () => ({ type: CLEAR_SAVE_RESULT });
export const receiveLotAdjustments = (data) => ({ type: RECEIVE_LOT_ADJUSTMENTS, payload: { data } });
export const receiveLotAdjustment = (data) => ({ type: RECEIVE_LOT_ADJUSTMENT, payload: { data } });
export const receiveSaveLotAdjustmentResponse = (data) => ({ type: RECEIVE_SAVE_LOT_ADJUSTMENT_RESPONSE, data });
export const receiveDeleteLotAdjustmentResponse = (data) => ({ type: RECEIVE_DELETE_LOT_ADJUSTMENT_RESPONSE, data });
export const editLotAdjustment = (lotAdjustment) => ({ type: EDIT_LOT_ADJUSTMENT, lotAdjustment });
export const receiveErrorResponse = (error) => ({ type: RECEIVE_LOT_ADJUSTMENTS_ERROR_RESPONSE, error });

export const createNewLotAdjustment = () =>
	(dispatch, getState) => {	
		const state = getState();
		const newLotAdjustment = {
			...cloneDeep(globals.templates.lotAdjustment),
			periodClaimed: state.context.period ? state.context.period.periodId : null
		};
		dispatch({ type: CREATE_NEW_LOT_ADJUSTMENT, newLotAdjustment: newLotAdjustment });
	};

export const requestLotAdjustments = () => (
	createFetchAction({
		objectName: 'Lot Adjustments',
		passContext: true,
		url: '/api/lot-adjustments',
		startAction: REQUEST_LOT_ADJUSTMENTS,
		success: (data) => receiveLotAdjustments(data)
	})
);

export const requestLotAdjustment = (lotAdjustmentId) => (
	createFetchAction({
		objectName: 'Lot Adjustment',
		passContext: true,
		url: `/api/lot-adjustments/${lotAdjustmentId}`,
		startAction: REQUEST_LOT_ADJUSTMENT,
		success: (data) => receiveLotAdjustment(data)
	})
);

const validateLotAdjustment = (lotAdjustment) => {
	const errors = [];
	if (lotAdjustment.lotId <= 0) {
		errors.push({
			fieldName: 'LotId',
			valid: false,
			message: 'Lot is required'
		});
	}
	if (lotAdjustment.lotItemId <= 0) {
		errors.push({
			fieldName: 'LotItemId',
			valid: false,
			message: 'Lot Item is required'
		});
	}
	return errors;
};


export const saveLotAdjustment = (lotAdjustment, onSuccess) => {
	const errors = validateLotAdjustment(lotAdjustment);
	if (errors.length > 0) return { type: SHOW_LOT_ADJUSTMENT_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/lot-adjustments',
		data: lotAdjustment,
		startAction: SAVE_LOT_ADJUSTMENT,
		success: (data, dispatch) => {
			dispatch(receiveSaveLotAdjustmentResponse(data));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const saveLotAdjustmentAndNew = (lotAdjustment) => {
	const errors = validateLotAdjustment(lotAdjustment);
	if (errors.length > 0) return { type: SHOW_LOT_ADJUSTMENT_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/lot-adjustments',
		data: lotAdjustment,
		startAction: SAVE_LOT_ADJUSTMENT,
		success: (data, dispatch) => {
			dispatch(receiveSaveLotAdjustmentResponse(data));
			if (data.success) dispatch(createNewLotAdjustment());
		}
	});
};

export const deleteLotAdjustment = (lotAdjustment) => (
	createPostAction({
		passContext: true,
		url: `/api/lot-adjustments/${lotAdjustment.lotAdjustmentId}/delete`,
		data: lotAdjustment,
		startAction: DELETE_LOT_ADJUSTMENT,
		success: (data) => receiveDeleteLotAdjustmentResponse(data)
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_LOT_ADJUSTMENTS:
			return {
				...state,
				lotAdjustments: null
			};
		case CLEAR_LOT_ADJUSTMENT:
			return {
				...state,
				lotAdjustment: null
			};
		case CLEAR_SAVE_RESULT:
			return {
				...state,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case EDIT_LOT_ADJUSTMENT:
			return {
				...state,
				lotAdjustment: action.lotAdjustment,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case REQUEST_LOT_ADJUSTMENTS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				lotAdjustments: []
			};
		case RECEIVE_LOT_ADJUSTMENTS:
			return {
				...state,
				isLoading: false,
				lotAdjustments: action.payload.data
			};
		case REQUEST_LOT_ADJUSTMENT:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				lotAdjustment: globals.templates.lotAdjustment
			};
		case RECEIVE_LOT_ADJUSTMENT:
			return {
				...state,
				isLoading: false,
				lotAdjustment: action.payload.data
			};
		case DELETE_LOT_ADJUSTMENT:
			return { 
				...state
			};
		case RECEIVE_DELETE_LOT_ADJUSTMENT_RESPONSE:
			return {
				...state,
				lotAdjustments: state.lotAdjustments.filter(c => c.lotAdjustmentId !== action.data.objectId) 
			};
		case CREATE_NEW_LOT_ADJUSTMENT:
			return {
				...state,
				lotAdjustment: action.newLotAdjustment,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};	
		case SAVE_LOT_ADJUSTMENT:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_LOT_ADJUSTMENT_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				lotAdjustments: addOrUpdate(state.lotAdjustments, action.data.object, { lotAdjustmentId: action.data.object.lotAdjustmentId }), 
				lotAdjustment: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_LOT_ADJUSTMENT_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		case RECEIVE_LOT_ADJUSTMENTS_ERROR_RESPONSE:
			return {
				...state,
				isLoading: false,
				result: {
					success: false,
					message: action.error.message,
					errors: [] 
				}
			};
		default:
			return state;
	}
};
