import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../../utils/field-validation';
import TextField from '../../widgets/TextField';
import FormFieldContainer from '../../formFieldContainer';
import ContentContainer from '../../contentContainer';
import DateField from '../../widgets/DateField';

class ForecastPeriodForm extends React.Component {
	state = {
		forecastPeriod: this.props.forecastPeriod
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			forecastPeriod: nextProps.forecastPeriod
		});
	}

	render() {
		const forecastPeriod = this.state.forecastPeriod;

return (
<div>
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={forecastPeriod.periodName}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('periodName', v); }}
				label="Period Name"
				errorText={getFieldErrorText(this.props.saveResult, 'PeriodName')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField
				value={forecastPeriod.startDate} 
				label="Start Date" 
				onChange={(d) => { this.props.updateField('startDate', d); }}
				error={getFieldErrorText(this.props.saveResult, 'StartDate')}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField 
				value={forecastPeriod.endDate} 
				label="End Date" 
				onChange={(d) => { this.props.updateField('endDate', d); }}
				error={getFieldErrorText(this.props.saveResult, 'EndDate')}
				fullWidth={true}
			/>
		</FormFieldContainer>
	</ContentContainer>
</div>
		);
	}
}

ForecastPeriodForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	forecastPeriod: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default ForecastPeriodForm;
