import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverDateField from '../components/handoverDateField';
import HandoverMemoField from '../components/handoverMemoField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';

class ExtensionOfTimeHandoverContent extends React.Component {
	render() {
		const items = this.props.report;

		const linkedCorrespondence = items.linkedCorrespondence;
		
		const linkedCorrespondenceColumns = [
			{ name: 'correspondenceType', label: 'Type' },
			{ name: 'correspondenceNumber', label: 'Number' },
			{ name: 'correspondenceTitle', label: 'Title' }
		];

		const filesColumns = [
			{ name: 'name', label: 'Name' }
		];
		
		return (
		<div>
			<div className="handover-data">
				<HandoverField label="EOT Number" value={items.extensionOfTimeNumber} />
				<HandoverField label="EOT Description" value={items.description} />
				<HandoverDateField label="Date" value={items.date} />
				<HandoverField label="EOT Status" value={items.status} />
				<HandoverField label="Number of Days Approved" value={items.daysApproved} />
				<HandoverField label="Approved By" value={items.approvedByName} />
				<HandoverDateField label="Previous Date for PC" value={items.previousPracticalCompletionDate} />
				<HandoverDateField label="Revised PC Date" value={items.revisedPracticalCompletionDate} />
				<HandoverMemoField label="Comments" value={items.comments} />
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Linked Correspondence</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={linkedCorrespondence || []}
					columns={linkedCorrespondenceColumns}
				/>
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Attachments</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={items.files || []}
					columns={filesColumns}
				/>
			</div>
		</div>
    );
  }
}

ExtensionOfTimeHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default ExtensionOfTimeHandoverContent;
