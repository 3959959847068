/* eslint global-require: off */
/* eslint dot-notation: off */
/* eslint key-spacing: off */

import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import localforage from 'localforage';
import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import { loadPersistedState } from './persistedState';
import {
	default as bootstrap,
	BOOTSTRAP_SUCCESS,
	BOOTSTRAP_FAILURE
} from './bootstrap';
import * as Sentry from "@sentry/react";
import LogRocket from 'logrocket';
import { combineReducers } from 'redux';
import modal from './modal';
import { reducer as toasts } from 'react-notification-system-redux';
import system from './system';
import settings from './settings';
import persistedState from './persistedState';
import tempState from './tempState';
import context from './context';
import user from './user';
import users from './users';
import roles from './roles';
import tags from './tags';
import layout from './layout';
import sidePanel from './sidePanel';
import notifications from './notifications';
import contracts from './contracts';
import contractors from './contractors';
import employees from './employees';
import scheduleOfRates from './scheduleOfRates';
import periods from './periods';
import closePeriodReducer from './closePeriod';
import lots from './lots';
import lotAdjustments from './lotAdjustments';
import variations from './variations';
import incomingCorrespondence from './incomingCorrespondence';
import outgoingCorrespondence from './outgoingCorrespondence';
import progressClaim from './progressClaims';
import extensionsOfTime from './extensionsOfTime';
import separablePortions from './separablePortions';
import practicalCompletion from './practicalCompletion';
import principalCorrespondence from './principalCorrespondence';
import designCorrespondence from './designCorrespondence';
import nonConformanceRegister from './nonConformanceRegister';
import forecasts from './forecasts';
import scheduleForecasts from './scheduleForecasts';
import limitsOfAccuracy from './limitsOfAccuracy';
import handover from './handover';
import registerDefinitions from './registerDefinitions';
import registers from './registers';
import events from './events';
import helpPages from './helpPages';
import loading from './loading';
import { setGlobals } from '../globals';

export default function configureStore(initialState) {
	const reducers = {
		bootstrap: bootstrap,
		closePeriod: closePeriodReducer,
		context,
		contractors,
		contracts,
		employees,
		extensionsOfTime,
		designCorrespondence,
		events,
		forecasts,
		handover,
		helpPages,
		incomingCorrespondence,
		layout,
		limitsOfAccuracy,
		loading,
		lotAdjustments,
		lots,
		modal,
		nonConformanceRegister,
		notifications,
		outgoingCorrespondence,
		periods,
		persistedState,
		practicalCompletion,
		principalCorrespondence,
		progressClaim,
		registerDefinitions,
		registers,
		roles,
		scheduleForecasts,
		scheduleOfRates,
		separablePortions,
		settings,
		sidePanel,
		system,
		tags,
		tempState,
		toasts,
		user,
		users,
		variations
	};

	const enhancers = [];

	if (window.Contrack && window.Contrack.SentryDSN) {
		const sentryReduxEnhancer = Sentry.createReduxEnhancer({
			// Optionally pass options
		});
		enhancers.push(sentryReduxEnhancer);
	}

	// Use the browser's Redux dev tools extension if installed
	if (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
	}

	const appReducer = combineReducers({ ...reducers });

	const rootReducer = (state, action) => {
		switch (action.type) {
			case BOOTSTRAP_SUCCESS:
				const data = action.data

				if (data.user) {
					LogRocket.identify(data.user.userId, {
						name: data.user.userName,
						email: data.user.email,
						userName: data.user.userName,
						position: data.user.position,
						fullName: data.user.fullName,
						role: data.user.userRole
					});
					
					Sentry.setUser({ 
						userId: data.user.userId,
						email: data.user.email,
						username: data.user.userName,
						fullName: data.user.fullName,
						position: data.user.position,
						role: data.user.userRole
					});
				}

				const templates = data.templates;
				forEach(templates, (t) => { t._isNew = true; });
				setGlobals({
					permissions: data.permissions,
					templates: templates,
					tenantName: data.tenantName,
					gstRate: data.gstRate
				});

				return {
					...state,
					bootstrap: {
						loading: false,
						error: null
					},
					context: {
						...state.context,
						isLoggedIn: data.isLoggedIn,
						user: data.user,
						userRole: data.user ? data.user.userRole : "",
						contracts: data.contracts,
						contract: data.contract,
						period: data.period,
						registers: data.registers,
						isTenanted: data.isTenanted,
						maxContracts: data.maxContracts,
						maxUsers: data.maxUsers,
						maxData: data.maxData,
						currentData: data.currentData
					},
					users: {
						...state.users,
						userList: data.users
					},
					settings: {
						...data.settings
					},
					system: {
						baseUrl: data.baseUrl,
						userAgent: data.userAgent,
						...state.system
					},
					helpPages: {
						helpPages: data.helpPages
					}
				};
			case BOOTSTRAP_FAILURE:
				return { 
					...state, 
					bootstrap: {
						data: null,
						error: action.error,
						loading: false
					}
				};
			default:
				return appReducer(state, action);
		}
	}

	const store = createStore(
		rootReducer, 
		initialState, 
		compose(applyMiddleware(thunkMiddleware, LogRocket.reduxMiddleware()), ...enhancers)
	);

	localforage.config({
		driver      : localforage.IndexedDB, // Force WebSQL; same as using setDriver()
		name        : 'CAS',
		version     : 1.0,
		storeName   : 'CAS_DB', // Should be alphanumeric, with underscores.
		description : 'CAS Local Data Storage'
	});

	localforage.getItem('persistedState')
		.then((value) => {
			store.dispatch(loadPersistedState(value));

			// Don't subscribe until after we load the state otherwise
			// we may overwrite the state before we load it
			store.subscribe(debounce(() => {
				localforage.setItem('persistedState', store.getState().persistedState);
			}, 20));
		});

	return store;
};
