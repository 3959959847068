import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../reducers/modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../widgets/button';
import FormContainer from '../formContainer';
import FormFieldContainer from '../formFieldContainer';
import TextField from '../widgets/TextField';
import { logIn } from '../../reducers/context';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { retryFailedAjaxCall, clearFailedAjaxCalls } from '../../reducers/system';
import { getFieldErrorText } from '../../utils/field-validation';

const LoginDialog = props => {
	const [loading, setLoading] = React.useState(false);
	const [loginResult, setLoginResult] = React.useState({
        success: false,
        fields: []
    });
	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');

	const login = () => {
        setLoading(true);
        props.logIn(username, password, false, result => {
            setLoading(false);

            if (result.success) {
                props.onSuccess();
                props.onOk();

                for (let i = 0, ii = props.failedAjaxCalls.length; i < ii; i++) {
                    const ajaxCall = props.failedAjaxCalls[i];
                    props.retryFailedAjaxCall(ajaxCall);
                }

                props.clearFailedAjaxCalls();
            } else {
                setLoginResult(result);
                props.onError(result.message);
            }
        }, errorResult => {
            setLoginResult(errorResult);
        });		
	};

	return (
		<Dialog 
			open 
			onClose={props.onCancel} 
			aria-labelledby="simple-dialog-title" 
			className="login-modal"
			disableBackdropClick
			disableEscapeKeyDown
		>
			<DialogTitle id="simple-dialog-title">Login</DialogTitle>
			<DialogContent>
                <FormContainer>
                    Your session timed-out. Please enter your credentials to continue.

                    <FormFieldContainer>
                        <TextField	
                            name="username"
                            value={username}
                            onChange={(e, v) => { setUsername(v); }}
                            fullWidth={true}
                            label="Username"
                            errorText={getFieldErrorText(loginResult, 'UserName')}
                    />
                    </FormFieldContainer>	

                    <FormFieldContainer>
                        <TextField	
                            name="password"
                            value={password}
                            onChange={(e, v) => { setPassword(v); }}
                            fullWidth={true}
                            label="Password"
                            type="password"
                            errorText={getFieldErrorText(loginResult, 'Password')}
                        />
                    </FormFieldContainer>	
	            </FormContainer>
			</DialogContent>
			<DialogActions>
				<Button 
                    primary={true} 
                    onClick={login} 
                    loading={loading} 
                    className="ok-button"
                >Login
                </Button>
			</DialogActions>
		</Dialog>
	);
}

LoginDialog.propTypes = {
	onOk: PropTypes.func,
	onCancel: PropTypes.func,
	logIn: PropTypes.func.isRequired,
	failedAjaxCalls: PropTypes.array.isRequired
};

LoginDialog.defaultProps = {
	onOk: () => null,
	onCancel: () => null
};

const mapStateToProps = state => ({
	failedAjaxCalls: state.system.failedAjaxCalls
});

const mapDispatchToProps = dispatch => ({
	dispatch,
	logIn: (userName, password, keepLoggedIn, onSuccess) => dispatch(logIn(userName, password, keepLoggedIn, onSuccess)),
	onSuccess: () => [
		dispatch(showSuccessNotification("You have successfully logged back in.")),
		dispatch(hideModal())
	],
	onError: message => dispatch(showErrorNotification(message)),
    retryFailedAjaxCall: ajaxCall => dispatch(retryFailedAjaxCall(ajaxCall)),
	clearFailedAjaxCalls: () => dispatch(clearFailedAjaxCalls())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
