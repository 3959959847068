import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import { toCurrency } from '../../utils/utils';
import filter from 'lodash/filter';
import round from 'lodash/round';

class TableOfDifferencesContent extends React.Component {
	render() {
		const report = this.props.report || {};
		const claim = report.claim ? report.claim : { items: [] };
		const claimItems = filter(claim.items, (i) => (i.certifiedQty - i.claimQty) !== 0);
		const claimItemsBySchedule = groupBy(claimItems, (i) => Number(`${i.scheduleOrder}.${i.scheduleOfRatesId}`));
		const tempClaimItems = filter(claim.items, i => i.claimType === 'Temporary');

		return (
		<div>
			<div className="lots-data">
				<div className="section-header">
				</div>

				{
					map(claimItemsBySchedule, (items) => 
						!items[0].scheduleIsActive ? undefined : (
						<table className="report-table">
							<thead>	
								<tr className="schedule-header">
									<td colSpan={15}>{items[0].scheduleName}</td>
								</tr>
								<tr>
									<th>Item No</th>
									<th style={{ minWidth: '200px' }}>Description</th>
									<th>Unit</th>
									<th>Tendered Quantity</th>
									<th>Unit Rate($)</th>
									<th>Contractor Claim</th>
									<th>Certified Amount</th>
									<th>Difference Quantity</th>
									<th>Difference</th>
									<th style={{ minWidth: '300px' }}>Comments</th>
								</tr>
							</thead>
							<tbody>
								{
									(() => {
										let scheduleName = null;
										let differenceTotal = 0;

										const rows = [];

										scheduleName = items[0].scheduleName;
										
										forEach(items, (i) => {
											differenceTotal += round((i.certifiedQty - i.claimQty) * i.rate, 2);

											rows.push(
												<tr>
													<td>{i.itemNo}</td>
													<td>{i.description}</td>
													<td style={{ textAlign: 'right' }}>{i.unit}</td>
													<td style={{ textAlign: 'right' }}>{i.qty}</td>
													<td style={{ textAlign: 'right' }}>{toCurrency(i.rate)}</td>
													<td style={{ textAlign: 'right' }}>{toCurrency(i.claimQty * i.rate, 3)}</td>
													<td style={{ textAlign: 'right' }}>{toCurrency(i.certifiedAmount || i.calcCertifiedAmount)}</td>
													<td>{round((i.certifiedQty - i.claimQty), 2)}</td>
													<td>{toCurrency((i.certifiedQty - i.claimQty) * i.rate)}</td>
													<td style={{ textAlign: 'right' }}>{i.comments}</td>
												</tr>
											);
										});

										rows.push(
											<tr className="schedule-total">
												<td colSpan={8}>{scheduleName} Total</td>
												<td style={{ textAlign: 'right' }}>{toCurrency(differenceTotal)}</td>
												<td></td>
											</tr>
										);
											
										return rows;
									})()
								}
							</tbody>
						</table>
					))
				}

				{tempClaimItems.length > 0 && 
					<table className="report-table">
						<thead>	
							<tr className="schedule-header">
								<td colSpan={5}>Temporary Claim Items</td>
							</tr>
							<tr>
								<th>Item No</th>
								<th style={{ minWidth: '200px' }}>Description</th>
								<th>Contractor Claim</th>
								<th>Difference</th>
								<th style={{ minWidth: '300px' }}>Comments</th>
							</tr>
						</thead>
						<tbody>
							{
								(() => {
									let differenceTotal = 0;
									const rows = [];
									forEach(tempClaimItems, (i) => {
										differenceTotal += -i.claimAmount;

										rows.push(
											<tr>
												<td>{i.itemNo}</td>
												<td>{i.description}</td>
												<td style={{ textAlign: 'right' }}>{toCurrency(i.claimAmount, 3)}</td>
												<td>{toCurrency(-i.claimAmount)}</td>
												<td style={{ textAlign: 'right' }}>{i.comments}</td>
											</tr>
										);
									});

									rows.push(
										<tr className="schedule-total">
											<td colSpan={3}>Temporary Claim Items Total</td>
											<td style={{ textAlign: 'right' }}>{toCurrency(differenceTotal)}</td>
											<td></td>
										</tr>
									);
										
									return rows;
								})()
							}
						</tbody>
					</table>
				}
			</div>
		</div>
    );
  }
}

TableOfDifferencesContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default TableOfDifferencesContent;
