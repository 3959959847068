import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from '../contentContainer';
import FormSectionHeader from '../widgets/formSectionHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import classNames from 'classnames';
import ProgressIndicator from '../widgets/progressIndicator';
import { formatBytes } from '../../utils/utils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	colorPrimary: {
		backgroundColor: '#BDBDBD',
	},
	barColorPrimary: {
		backgroundColor: '#0E76BC',
	}
});

const HandoverForm= (props) => {
		const handover = props.handover;
		const fileSizeString = handover && handover.fileSize ? ` (${formatBytes(handover.fileSize)})` : '';
		const classes = useStyles();

		return (
<ContentContainer>
	{handover ? (
		<div>
			<FormSectionHeader text="Status" />
			<div>{handover.status}</div>
			<div>{handover.message}</div>

			<FormSectionHeader text="Progress" />

			<LinearProgress
				variant="determinate"
				value={handover.progress}
				classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} 
				style={{ height: '20px' }} 
			/>
			<span className="handover-progress-label">{handover.progress}%</span>

			{handover.status === 'Complete' && 
				<div>
					<FormSectionHeader text="Handover File" />
					<a href={`/api/handover/${handover.contractId}/download-file`}><span>{handover.contractName} Handover.zip {fileSizeString}</span></a>
				</div>
			}

			<FormSectionHeader text="Logs" />	
			{props.isLogsLoading && <ProgressIndicator />}

			<ul className="handover-logs">
				{map(props.logs || [], (l) => {
					const logClasses = classNames({
						'log-message': true,
						'info-message': l.logType === 'info',
						'warning-message': l.logType === 'warning',
						'error-message': l.logType === 'error',
						'success-message': l.logType === 'success',
						'debug-message': l.logType === 'debug'
					});
					return (
						<li className={logClasses}>
							<span>{`${l.logTime.toLocaleTimeString()}    ${l.message}`}</span>
						</li>
					);
				})}
			</ul>
		</div>
	) : (
		<p>No handover has been generated. Click the &quot;Initiate&quot; button to begin building the handover.</p>
	)}
</ContentContainer>

	);
};

HandoverForm.propTypes = {
	handover: PropTypes.object.isRequired,
	logs: PropTypes.array,
	isLogsLoading: PropTypes.bool.isRequired
};

HandoverForm.defaultProps = {
	logs: []
};

export default HandoverForm;
