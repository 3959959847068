import forEach from 'lodash/forEach';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { periodClosed } from './context';

const initialState = {
	isLoading: false,
	closeResult: {
		success: null,
		message: null,
		fields: []
	},
	closePeriodData: null
};

const CLEAR_CLOSE_PERIOD_DATA = 'CLEAR_CLOSE_PERIOD_DATA';
const REQUEST_CLOSE_PERIOD_DATA = 'REQUEST_CLOSE_PERIOD_DATA';
const RECEIVE_CLOSE_PERIOD_DATA = 'RECEIVE_CLOSE_PERIOD_DATA';
const CLOSE_PERIOD = 'CLOSE_PERIOD';
const RECEIVE_CLOSE_PERIOD_RESPONSE = 'RECEIVE_CLOSE_PERIOD_RESPONSE';
const SHOW_CLOSE_PERIOD_ERRORS = 'SHOW_CLOSE_PERIOD_ERRORS';

export const clearClosePeriodData = () => ({ type: CLEAR_CLOSE_PERIOD_DATA });
export const receiveClosePeriodResponse = (data) => ({ type: RECEIVE_CLOSE_PERIOD_RESPONSE, data });
export const receiveClosePeriodData = (data) => ({ type: RECEIVE_CLOSE_PERIOD_DATA, payload: { data } });

export const requestClosePeriodData = (periodId) => (
	createFetchAction({
		objectName: 'Closed Period Data',
		url: `/api/periods/${periodId}/closeData`,
		passContext: true,
		startAction: REQUEST_CLOSE_PERIOD_DATA,
		success: (data) => 
			receiveClosePeriodData({
				...data,
				startDate: data.startDate ? new Date(data.startDate) : null,
				endDate: data.endDate ? new Date(data.endDate) : null
			})
	})
);

export const closePeriod = (args) => {
	const errors = [];
	if (!args.nextPeriodName) {
		errors.push({
			fieldName: 'NextPeriodName',
			valid: false,
			message: 'You must enter a name for the next period'
		});
	}
	if (errors.length > 0) return { type: SHOW_CLOSE_PERIOD_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: `/api/periods/${args.periodId}/close`,
		data: args,
		startAction: CLOSE_PERIOD,
		success: (data, dispatch) => {
			if (data.updatedPeriods) {
				forEach(data.updatedPeriods, (p) => {
					p.startDate = p.startDate ? new Date(p.startDate) : null;
					p.endDate = p.endDate ? new Date(p.endDate) : null;
				});
			}
			dispatch(receiveClosePeriodResponse(data));
			if (data.success) {
				dispatch(periodClosed(data));
			}
		}
	});
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CLOSE_PERIOD_DATA:
			return {
				...state,
				closePeriodData: null
			};
		case REQUEST_CLOSE_PERIOD_DATA:
			return {
				...state,
				isLoading: true,
				closeResult: {
					success: null,
					message: null,
					fields: [] 
				},
				closePeriodData: null
			};
		case RECEIVE_CLOSE_PERIOD_DATA:
			return {
				...state,
				isLoading: false,
				closePeriodData: action.payload.data
			};
		case SHOW_CLOSE_PERIOD_ERRORS:
			return {
				...state,
				isLoading: false,
				closeResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		case CLOSE_PERIOD:
			return {
				...state,
				isLoading: true,
				closeResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case RECEIVE_CLOSE_PERIOD_RESPONSE:
			return {
				...state,
				isLoading: false,
				closeResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		default:
			return state;
	}
};
