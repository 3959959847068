import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';
import { successColour, errorColour } from '../../variables';

class ForecastsGrid extends React.Component {
	// shouldComponentUpdate(nextProps) {
	// 	return this.props.forecasts.length !== nextProps.forecasts.length;
	// }

	render() {
		const columns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => {
					if (this.props.canEdit) {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.handleEditForecast(args.data); } } style={{ padding: '2px' }}>
									<EditIcon />
								</IconButton>
								<IconButton onClick={ () => { this.props.handleDeleteForecast(args.data); } } style={{ padding: '2px' }}>
									<ActionDelete />
								</IconButton>
							</div>
						);
					} else {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.handleViewForecast(args.data); } } style={{ padding: '2px' }}>
									<ViewIcon />
								</IconButton>
							</div>
						);
					}
				}	
			},
			{ name: 'name', label: 'Name' },
			{ name: 'isActive', label: 'Is Active', dataType: 'bool' },
			{ name: 'forecastAmount', label: 'Forecast Amount', formatString: 'currency' },
			{ 
				name: 'forecastDifference', 
				label: 'Difference', 
				formatString: 'currency',
				value: args => args.data.forecastAmount - args.data.tenderAmount,
				cellStyle: (args) => {
					const style = {};
					style.color = args.value < 0 ? errorColour : successColour;
					return style;
				}
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		return (
			<PersistedDataTable 
				id="forecasts"
				data={this.props.forecasts || []} 
				columns={columns}
			/>
		);
	}
}

ForecastsGrid.propTypes = {
	forecasts: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	handleViewForecast: PropTypes.func.isRequired,
	handleEditForecast: PropTypes.func.isRequired,
	handleDeleteForecast: PropTypes.func.isRequired
};

export default ForecastsGrid;
