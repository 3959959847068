import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

class CloseContractWizardCertPage extends Component {
	render() {
	console.log(this.props.finalCert);
	return (
<div> 
	{this.props.isLoading &&
		<div>
			<p>
				Checking for Final Certificate.
			</p>
			<CircularProgress style={{ display: this.props.isLoading ? undefined : 'none' }} />
		</div>
	}
	{!this.props.isLoading && !this.props.finalCert &&
		<div>
			<p>Final Certificate has not been generated. Pressing Next will close the Contract, and you will not be able to generate a Final Certificate.</p>
		</div>
	}
	{!this.props.isLoading && !this.props.finalCert &&
		<p> Final Certificate has been generated. Prior to closing the Contract, ensure the Final Certificate has been downloaded and the values are correct. Then proceed to Finish.</p>
	}
</div>
		);
	}
}

CloseContractWizardCertPage.propTypes = {
	finalCert: PropTypes.object,
	isLoading: PropTypes.bool.isRequired
};

CloseContractWizardCertPage.defaultProps = {
	finalCert: null
}

export default CloseContractWizardCertPage;
