import React from 'react';
import PropTypes from 'prop-types';
import Button from '../widgets/button';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { 
	Card,
	CardHeader,
	CardActions, 
	CardContent 
} from '@material-ui/core';
import DataTable from '../widgets/dataTable';
import Chip from '@material-ui/core/Chip';
import { toCurrency } from '../../utils/utils';
import filter from 'lodash/filter';
import { units } from '../../variables';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import IconButton from '@material-ui/core/IconButton';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
}));

const ScheduleOfRatesCard = React.memo(({ 
	schedule, 
	canEdit, 
	dragHandleProps,
	onEditSchedule,
	onDeleteSchedule
}) => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const onExpandClick = () => {
		setExpanded(!expanded);
	};

	const columns = [
		{
			name: 'itemNo',
			label: 'Item No',
			cellStyle: (args) => args.data.isTextOnlyLine ? { fontStyle: 'italic', backgroundColor: '#FAFAFA' } : {}
		},
		{
			name: 'description',
			label: 'Description',
			cellStyle: (args) => args.data.isTextOnlyLine ? { fontStyle: 'italic', backgroundColor: '#FAFAFA' } : {}
		},
		{
			name: 'additionalInfo',
			label: 'Additional Info',
			colspan: (args) => args.data.isTextOnlyLine ? 5 : 1,
			cellStyle: (args) => args.data.isTextOnlyLine ? { fontStyle: 'italic', backgroundColor: '#FAFAFA' } : {}
		},
		{ 
			name: 'unit', 
			label: 'Unit',
			filterOperators: ['=', 'Contains', 'multi-select'],
			filterOptions: units 
		},
		{
			name: 'qty',
			label: 'Qty',
			dataType: 'number'
		},
		{
			name: 'rate',
			label: 'Rate',
			dataType: 'number',
			formatString: 'currency'
		},
		{
			name: 'amount',
			label: 'Amount',
			dataType: 'number',
			value: (args) => args.data.qty * args.data.rate,
			formatString: 'currency'
		}
	];

	return (
		<Card style={{ marginTop: '20px' }}>
			<CardHeader
				title={
					<div>
						<span {...dragHandleProps}>
							<DragHandleIcon />
						</span>
						<span>{schedule.name}</span>
					</div>
				}
				titleTypographyProps={{
					variant: 'h6'
				}}
				subheader={`${(filter(schedule.items, (i) => !i.isTextOnlyLine) || []).length} items, ${toCurrency(schedule._total)} total`}
				subheaderTypographyProps={{
					variant: 'body2'
				}}
				action={
					<React.Fragment>
						<Chip
							label={schedule.tendered ? 'Tendered' : 'Variation'}
						/> 
						<IconButton
							className={classnames(classes.expand, {
								[classes.expandOpen]: expanded,
							})}
							onClick={onExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<ExpandIcon />
						</IconButton>
					</React.Fragment>
				}
			>
			</CardHeader>
			{canEdit && schedule._editable &&
				<CardActions>
					{schedule._deletable &&
						<Button
							label="Delete"
							onClick={ () => { onDeleteSchedule(schedule); } }
							icon={<ActionDelete />}
						/>
					}
					<Button
						label="Edit"
						onClick={ () => { onEditSchedule(schedule); } }
						icon={<EditIcon />}
					/>
				</CardActions>
			}
			{expanded &&
				<CardContent>
					<DataTable data={schedule.items || []} columns={columns} />
				</CardContent>
			}
		</Card>
	);
});

ScheduleOfRatesCard.propTypes = {
	schedule: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	dragHandleProps: PropTypes.object.isRequired,
	onEditSchedule: PropTypes.func.isRequired,
	onDeleteSchedule: PropTypes.func.isRequired,
};

export default ScheduleOfRatesCard;
