import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiTextField } from '@material-ui/core/TextField';

const TextField = (props) => {
	let fieldStyles;
	if (!props.multiline) {
		// fieldStyles = {
		// 	floatingLabelStyle: { top: '24px' },
		// 	style: { height: '58px' },
		// 	inputStyle: { marginTop: '6px' }
		// };
	}

	const mergedProps = { ...fieldStyles, ...props };
	
	mergedProps.InputProps = mergedProps.InputProps || {};

	if (props.inputStyle) {
		mergedProps.InputProps.inputProps = {
			style: props.inputStyle
		};
	}
	delete mergedProps.inputStyle;

	if (mergedProps.underlineShow === false) {
		mergedProps.InputProps.disableUnderline = true;
	}
	delete mergedProps.underlineShow;

	if (mergedProps.errorText) {
		mergedProps.error = true;
		mergedProps.helperText = mergedProps.errorText;
	}
	delete mergedProps.errorText;

	if (mergedProps.hintText) {
		mergedProps.placeholder = mergedProps.hintText;
	}
	delete mergedProps.hintText;

	if (mergedProps.onChange) {
		const onChange = mergedProps.onChange;
		mergedProps.onChange = (e) => onChange(e, e.target.value);
	}

	if (mergedProps.value === null || mergedProps.value === undefined) mergedProps.value = '';

	delete mergedProps.editable;
	delete mergedProps.labelStyle;
	delete mergedProps.startAdornment;
	delete mergedProps.endAdornment;
	delete mergedProps.helpText;
	
	return (
		<MuiTextField {...mergedProps} />
	);
};

TextField.propTypes = {
	multiline: PropTypes.bool,
	variant: PropTypes.string,
	inputStyle: PropTypes.object,
	underlineShow: PropTypes.bool
};

TextField.defaultProps = {
	multiline: false,
	variant: 'filled',
	inputStyle: undefined,
	underlineShow: undefined
};

export default TextField;
