import React from 'react';
import PropTypes from 'prop-types';

const Form = props => 
	<form style={props.style} onSubmit={(e) => {
		e.preventDefault();
		return props.onSubmit ? props.onSubmit() : false;
	}}>
		{props.children}
	</form>
;

Form.propTypes = {
	children: PropTypes.node.isRequired,
	onSubmit: PropTypes.func,
	style: PropTypes.object
};

Form.defaultProps = {
	style: null,
	onSubmit: null
};

export default Form;
