import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import forEach from 'lodash/forEach';
import PersistedDataTable from '../widgets/persistedDataTable';
import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import { units, errorColour } from '../../variables';
import { produce } from 'immer';

class LotItemDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			scheduleOfRates: this.setupItems(props.scheduleOfRates)
		};

		this.setQty = this.setQty.bind(this);
		this.addLotItems = this.addLotItems.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			scheduleOfRates: this.setupItems(nextProps.scheduleOfRates)
		});
	}
	setupItems(scheduleOfRates) {
		return scheduleOfRates;
	}

	addLotItems() {
		const selectedItems = [];
		forEach(this.state.scheduleOfRates, (s) => {
			forEach(s.items, (i) => {
				if (i._isSelected) {
					selectedItems.push({
						scheduleOfRatesId: i.scheduleOfRatesId,
						scheduleOfRatesItemId: i.scheduleOfRatesItemId,
						scheduleName: s.name,
						itemNo: i.itemNo,
						itemDescription: i.description,
						unit: i.unit,
						rate: i.rate,
						qty: i._qty,
						remainingQty: i.remainingQty
					});
				}
			});
		});
		this.props.addLotItems(selectedItems);
	}

	setQty(item, qty) {
		this.setState(produce(draft => {
			forEach(draft.scheduleOfRates, (s) => {
				forEach(s.items, (i) => {
					if (i.scheduleOfRatesItemId === item.scheduleOfRatesItemId) {
						if (qty !== '' && qty !== null) {
							i._isSelected = true;
						}
						const diff = (i._qty === undefined ? 0 : i._qty) - qty;
						i._qty = qty;
						i.remainingQty += diff;
					}
				});
			});
		}));
	}

	isRowSelectable = (row) => !row.isTextOnlyLine;
	isRowSelected = (row) => !row.isTextOnlyLine && row._isSelected;
	onSelectionChanged = (rows, selected)  => {
		this.setState(produce(draft => {
			const items = flatMap(draft.scheduleOfRates, (s) => s.items);
			forEach(rows, (r) => {
				const item = find(items, i => i.scheduleOfRatesItemId === r.scheduleOfRatesItemId);
				if (item) {
					if (!item.isTextOnlyLine) item._isSelected = selected;
				}
			});
		}));
	}

	render() {
		const scheduleOfRates = this.state.scheduleOfRates || [];

		const columns = [
			{ name: 'itemNo', label: 'Item' },
			{ 
				name: 'description', 
				label: 'Description',
				colspan: (args) => args.data.isTextOnlyLine ? 4 : 1
			},
			{ 
				name: 'unit', 
				label: 'Unit',
				filterOperators: ['=', 'Contains', 'multi-select'],
				filterOptions: units 
			},
			{ 
				name: 'qty', 
				label: 'Qty',
				dataType: 'number',
				cellRenderer: (args) =>
					<div>
						<DataTableNumericEditor
							type="decimal"
							value={args.data._qty} 
							onChange={(v) => { this.setQty(args.data, v); }}
						/>
						<div style={{ whiteSpace: 'nowrap', fontSize: 'small', color: args.data.remainingQty < 0 ? errorColour : '#bbb' }}> ({args.data.remainingQty} remaining)</div>
					</div>
			},
			{ 
				name: 'remainingQty', 
				label: 'Remaining Qty', 
				dataType: 'number'
			},
			{ 
				name: 'rate', 
				label: 'Rate', 
				dataType: 'number', 
				formatString: 'currency'
			}
		];

		const scheduleItems = flatMap(scheduleOfRates, (s) => s.items);
		return (
	<Dialog
		title="Items"
		actions={
			<React.Fragment>
				<Button
					label="Add"
					primary={true}
					onClick={() => this.addLotItems()}
				/>
				<Button
					label="Cancel"
					primary={false}
					onClick={this.props.cancel}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.cancel}
		scroll='paper'
		fullWidth={true}
		maxWidth='lg'
	>
		<p>Select the items to add to the Lot</p>

		<PersistedDataTable 
			id="lotScheduleItems"
			data={scheduleItems || []} 
			columns={columns}
			enableSelection={true}
			onSelectionChanged={this.onSelectionChanged}
			isRowSelected={this.isRowSelected}
			isRowSelectable={this.isRowSelectable}
			groupBy="scheduleOfRatesId"
			groupByFormatter={(args) => {
				const sched = find(scheduleOfRates, (s) => s.scheduleOfRatesId === args.value);
				return sched ? sched.name : null;
			}}
		/>
	</Dialog>
		);
	}
}

LotItemDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	addLotItems: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	scheduleOfRates: PropTypes.array
};

LotItemDialog.defaultProps = {
	scheduleOfRates: []
};

export default LotItemDialog;
