import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';

class PrincipalCorrespondencesHandoverContent extends React.Component {
	render() {
		const data = this.props.report;

		const columns = [
			{ name: 'rfiNumber', label: 'Principal Correspondence Number' },
			{ 
				name: 'inOrOut', 
				label: 'In/Out',
				filterOperators: ['multi-select'],
				filterOptions: ['In', 'Out'] 
			},
			{ name: 'responseRequiredBy', label: 'Response Required By', dataType: 'date' },
			{ name: 'description', label: 'Description', headerStyle: { minWidth: '300px' } },
			{ name: 'fromName', label: 'From' },
			{ name: 'toName', label: 'To' },
			{ name: 'dateSent', label: 'Date Sent', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.status !== 'Open') {
						statusBackColour = '#35aa47';
						statusForeColour = '#111';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed']
			},
			{ name: 'dateClosed', label: 'Date Closed', dataType: 'date' }
		];
		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

PrincipalCorrespondencesHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default PrincipalCorrespondencesHandoverContent;
