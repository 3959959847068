import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../widgets/button';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { 
	Card,
	CardHeader,
	CardActions, 
	CardContent 
} from '@material-ui/core';
import DataTable from '../../widgets/dataTable';
import Chip from '@material-ui/core/Chip';
import { toCurrency } from '../../../utils/utils';
import filter from 'lodash/filter';
import { units } from '../../../variables';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import IconButton from '@material-ui/core/IconButton';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const ScheduleOfRatesCard = React.memo(({ 
	schedule, 
	dragHandleProps,
	onEditSchedule
}) => {
	return (
		<Card style={{ marginTop: '20px' }}>
			<CardHeader
				title={
					<div>
						<span {...dragHandleProps}>
							<DragHandleIcon />
						</span>
						<span>{schedule.name}</span>
					</div>
				}
				titleTypographyProps={{
					variant: 'h6'
				}}
				subheader={`${(filter(schedule.items, (i) => !i.isTextOnlyLine) || []).length} items, ${toCurrency(schedule._total)} total`}
				subheaderTypographyProps={{
					variant: 'body2'
				}}
				action={
					<React.Fragment>
						<IconButton
							onClick={onEditSchedule}
						>
							<EditIcon />
						</IconButton>
					</React.Fragment>
				}
			>
			</CardHeader>
		</Card>
	);
});

ScheduleOfRatesCard.propTypes = {
	schedule: PropTypes.object.isRequired,
	dragHandleProps: PropTypes.object.isRequired,
	onEditSchedule: PropTypes.func.isRequired
};

export default ScheduleOfRatesCard;
