import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveLot, 
	requestLot, 
	clearLot, 
	createNewLot,
	requestTags
} from '../../reducers/lots';
import { requestScheduleOfRates } from '../../reducers/scheduleOfRates';
import LotForm from './lotForm';
import MessagePanel from '../messagePanel';
import find from 'lodash/find';
import filter from 'lodash/filter';
import last from 'lodash/last';
import { fetch, addTask } from 'domain-task';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { globals } from '../../globals';
import cloneDeep from 'lodash/cloneDeep';

class LotMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			lot: props.lot ?? cloneDeep(globals.templates.lot),
			lotHistory: [],
			lotHistoryLoading: false,
			lotAdjustments: [],
			lotAdjustmentsLoading: false,
			scheduleOfRates: props.scheduleOfRates,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.updateLotItem = this.updateLotItem.bind(this);
		this.deleteLotItem = this.deleteLotItem.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestScheduleOfRates());
		dispatch(requestTags());
		if (props.match.params.lotId) {
			dispatch(requestLot(props.match.params.lotId));
		} else {
			dispatch(createNewLot());
		}
	}
	static clearData(dispatch) {
		dispatch(clearLot());
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => ({
			lot: !prevState.lot || !nextProps.lot || prevState.lot.lotId !== nextProps.lot.lotId ? { ...nextProps.lot } : prevState.lot,
			scheduleOfRates: nextProps.scheduleOfRates,
			hasUnsavedChanges: false
		}));

		this.loadHistory(nextProps);
		this.loadAdjustments(nextProps);
	}

	componentDidMount() {
		this.loadHistory(this.props);
		this.loadAdjustments(this.props);
		LotMaintenance.fetchData(this.props.dispatch, this.props);
	}

	loadAdjustments(props) {
		const contractId = props.context.contract.contractId;
		const periodId = props.context.period.periodId;
		
		if (!props.match.params.lotId) return;
		this.setState({
			lotAdjustmentsLoading: true
		});
		const fetchTask =  fetch(`/api/lot-adjustments/by-lot/${props.match.params.lotId}?contractId=${contractId}&periodId=${periodId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				this.setState({
					lotAdjustments: data,
					lotAdjustmentsLoading: false
				});
			}).catch((error) => {
				this.setState({
					lotAdjustmentsLoading: false
				});
			});
					
		addTask(fetchTask);
	}

	loadHistory(props) {
		const contractId = props.context.contract.contractId;
		const periodId = props.context.period.periodId;
		
		if (!props.match.params.lotId) return;
		this.setState({
			lotHistoryLoading: true
		});
		const fetchTask =  fetch(`/api/lots/${props.match.params.lotId}/history?contractId=${contractId}&periodId=${periodId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				this.setState({
					lotHistory: data,
					lotHistoryLoading: false
				});
			}).catch((error) => {
				this.setState({
					lotHistoryLoading: false
				});
			});
					
		addTask(fetchTask);
	}

	updateField(field, newValue) {
		const lot = this.state.lot;
		if (field === 'status') {
			const currentPeriod = last(this.props.periods);
			switch (lot.prevState) {
				case 'Open':
					switch (newValue) {
						case 'Open':
							lot.periodClaimed = null;
							break;
						case 'Closed':
							lot.periodClaimed = currentPeriod.periodId;
							break;
						case 'Guaranteed':
							lot.periodClaimed = currentPeriod.periodId;
							break;
						default:
					}
					break;
				case 'Guaranteed':
					// Leave claimed as period when Guaranteed
					break;
				case 'Unguaranteed':
				case 'Reopened':
					switch (newValue) {
						case 'Guaranteed>1Month':
							lot.periodClaimed = null;
							break;
						case 'Closed':
							lot.periodClaimed = currentPeriod.periodId;
							break;
						default:
					}
					break;
				default:
			}
		}
		switch (field) {
			default:
				lot[field] = newValue;
				break;
		}

        this.setState({
			lot,
			hasUnsavedChanges: true
        });
	}
	updateLotItem(newItem) {
		const lotItem = find(this.state.lot.items, (i) => i.scheduleOfRatesItemId === newItem.scheduleOfRatesItemId);
		if (lotItem) {
			const diff = (lotItem.qty === undefined ? 0 : lotItem.qty) - newItem.qty;
			lotItem.qty = newItem.qty;
			lotItem.remainingQty += diff;
		} else {
			this.state.lot.items.push(newItem);
		}

		// Need to call setState to update the lot
        this.setState({
			lot: this.state.lot,
			hasUnsavedChanges: true
        });
	}
	deleteLotItem(lotItem) {
		// Need to call setState to update the lot
        this.setState({
			lot: {
				...this.state.lot,
				items: filter(this.state.lot.items, (i) => i.scheduleOfRatesItemId !== lotItem.scheduleOfRatesItemId)
			},
			hasUnsavedChanges: true
        });
	}
	viewNCR = (ncr) => {
		this.props.history.push(`/ncr/${ncr.ncrId}`);
	}
	viewLotAdjustment = (lotAdjustmentItem) => {
		this.props.history.push(`/lot-adjustment/${lotAdjustmentItem.lotAdjustmentId}`);
	}

	render() {
		const lot = this.state.lot || { tags: []};
		const scheduleOfRates = this.state.scheduleOfRates;

		return (
<PageLayout
	title={
		<span>
			{lot.lotNo}
			<small> {lot.lotDescription}</small>
		</span>
	}
	isLoading={this.props.isLoading}
	formActions={{
		isLoading: this.props.isLoading,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.lot, this.props.lot),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.lot, this.props.lot),
		onCancel: () => this.props.history.goBack() 
	}}
	content={
<div>
		<LotForm 
			lot={lot} 
			lotHistory={this.state.lotHistory}
			lotAdjustments={this.state.lotAdjustments}
			canEdit={this.props.canEdit}
			updateField={this.updateField} 
			deleteLotItem={this.deleteLotItem} 
			updateLotItem={this.updateLotItem} 
			scheduleOfRates={scheduleOfRates} 
			tags={this.props.tags} 
			periods={this.props.periods} 
			onSubmit={this.handleSubmit} 
			onCancel={this.onCancel} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult} 
			viewNCR={this.viewNCR}
			viewLotAdjustment={this.viewLotAdjustment}
		/>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
</div>
	}
/>
		);
	}
}

LotMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	lot: PropTypes.object,
	scheduleOfRates: PropTypes.array,
	tags: PropTypes.array,
	periods: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

LotMaintenance.defaultProps = {
	lot: { tags: [] },
	scheduleOfRates: [],
	tags: []
};

const mapStateToProps = (state) => ({
	context: state.context,
	lot: state.lots.lot,
	canEdit: canEdit(state, 'EditLots'),
	scheduleOfRates: state.scheduleOfRates.scheduleOfRates,
	tags: state.lots.tags,
	periods: state.context.contract.periods,
	isLoading: state.lots.isLoading,
	saveResult: state.lots.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch: dispatch,
	saveChanges: (lot, prevLot) => {
		dispatch(saveLot(lot, prevLot, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (lot, prevLot) => {
		dispatch(saveLot(lot, prevLot, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(LotMaintenance);
