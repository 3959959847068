import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveScheduleForecast as saveForecast, 
	requestScheduleForecast as requestForecast, 
	clearScheduleForecast as clearForecast, 
	createNewScheduleForecast as createNewForecast
} from '../../reducers/scheduleForecasts';
import { requestForecastPeriods } from '../../reducers/forecasts';
import { requestScheduleOfRates } from '../../reducers/scheduleOfRates';
import ForecastForm from './forecastForm';
import MessagePanel from '../messagePanel';
import find from 'lodash/find';
import filter from 'lodash/filter';
import last from 'lodash/last';
import { fetch, addTask } from 'domain-task';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class ForecastMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			forecast: props.forecast,
			forecastHistory: [],
			scheduleOfRates: props.scheduleOfRates,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.updateForecastItem = this.updateForecastItem.bind(this);
		this.deleteForecastItem = this.deleteForecastItem.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestForecastPeriods());
		dispatch(requestScheduleOfRates());
		if (props.match.params.forecastId) {
			dispatch(requestForecast(props.match.params.forecastId));
		} else {
			dispatch(createNewForecast());
		}
	}
	static clearData(dispatch) {
		dispatch(clearForecast());
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			forecast: nextProps.forecast,
			scheduleOfRates: nextProps.scheduleOfRates,
			hasUnsavedChanges: false
		});

		this.loadHistory(
			nextProps.context.contract.contractId, 
			nextProps.context.period.periodId, 
			nextProps.match.params.forecastId
		);
	}

	componentDidMount() {
		this.loadHistory(
			this.props.context.contract.contractId, 
			this.props.context.period.periodId, 
			this.props.match.params.forecastId
		);

		ForecastMaintenance.fetchData(this.props.dispatch, this.props);
	}

	loadHistory(contractId, periodId, forecastId) {
		if (forecastId) return;
		const fetchTask =  fetch(`/api/forecasts/${forecastId}/history?contractId=${contractId}&periodId=${periodId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				this.setState({
					forecastHistory: data
				});
			}).catch(() => {
			});
					
		addTask(fetchTask);
	}

	updateField(field, newValue) {
		const forecast = this.state.forecast;
		if (field === 'status') {
			const currentPeriod = last(this.props.periods);
			switch (forecast.prevState) {
				case 'Open':
					switch (newValue) {
						case 'Open':
							forecast.periodClaimed = null;
							break;
						case 'Closed':
							forecast.periodClaimed = currentPeriod.periodId;
							break;
						case 'Guaranteed':
							forecast.periodClaimed = currentPeriod.periodId;
							break;
						default:
					}
					break;
				case 'Guaranteed':
					// Leave claimed as period when Guaranteed
					break;
				case 'Unguaranteed':
				case 'Reopened':
					switch (newValue) {
						case 'Guaranteed>1Month':
							forecast.periodClaimed = null;
							break;
						case 'Closed':
							forecast.periodClaimed = currentPeriod.periodId;
							break;
						default:
					}
					break;
				default:
			}
		}
		forecast[field] = newValue;
        this.setState({
			forecast,
			hasUnsavedChanges: true
        });
	}
	updateForecastItem(newItem) {
		const forecastItem = find(this.state.forecast.items, (i) => i.scheduleOfRatesItemId === newItem.scheduleOfRatesItemId);
		if (forecastItem) {
			forecastItem.forecastQty = newItem.forecastQty;
			forecastItem.comments = newItem.comments;
		} else {
			this.state.forecast.items.push(newItem);
		}

		// Need to call setState to update the forecast
        this.setState({
			forecast: this.state.forecast,
			hasUnsavedChanges: true
        });
	}
	deleteForecastItem(forecastItem) {
		// Need to call setState to update the forecast
        this.setState({
			forecast: {
				...this.state.forecast,
				items: filter(this.state.forecast.items, (i) => i.scheduleOfRatesItemId !== forecastItem.scheduleOfRatesItemId)
			},
			hasUnsavedChanges: true
        });
	}
	render() {
		const forecast = this.state.forecast || {};
		const scheduleOfRates = this.state.scheduleOfRates;

		return (
<PageLayout
	title={
		<span>
			{forecast.name}
		</span>
	}
	isLoading={this.props.isLoading}
	formActions={{
		isLoading: this.props.isLoading,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.forecast),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.forecast),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<ForecastForm 
			forecast={forecast} 
			forecastPeriods={this.props.forecastPeriods}
			forecastHistory={this.state.forecastHistory}
			canEdit={this.props.canEdit}
			updateField={this.updateField} 
			deleteForecastItem={this.deleteForecastItem} 
			updateForecastItem={this.updateForecastItem} 
			scheduleOfRates={scheduleOfRates} 
			periods={this.props.periods} 
			onSubmit={this.handleSubmit} 
			onCancel={this.onCancel} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult} 
		/>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

ForecastMaintenance.propTypes = {
	params: PropTypes.shape({
		forecastId: PropTypes.node
	}).isRequired,
	context: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	forecast: PropTypes.object,
	forecastPeriods: PropTypes.array,
	scheduleOfRates: PropTypes.array,
	periods: PropTypes.array,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

ForecastMaintenance.defaultProps = {
	forecast: undefined,
	scheduleOfRates: [],
	forecastPeriods: [],
	periods: []
};

const mapStateToProps = (state) => ({
	context: state.context,
	forecast: state.scheduleForecasts.forecast,
	forecastPeriods: state.forecasts.forecastPeriods,
	canEdit: canEdit(state, 'EditForecasts'),
	scheduleOfRates: state.scheduleOfRates.scheduleOfRates,
	periods: state.context.contract.periods,
	isLoading: state.scheduleForecasts.isLoading,
	saveResult: state.scheduleForecasts.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch: dispatch,
	saveChanges: (forecast) => {
		dispatch(saveForecast(forecast, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (forecast) => {
		dispatch(saveForecast(forecast, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ForecastMaintenance);
