import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import MessagePanel from '../messagePanel';
import ContentContainer from '../contentContainer';
import PageLayout from '../pageLayout';
import { 
	Grid,
} from '@material-ui/core';

const form = {
	initValues: props => props.settings,
	fields: [
		{
			name: "logo",
			label: "Logo",
			type: 'imageSelector',
			accept: 'image/png, image/jpeg'
		}
	]
};

const Settings = ({ 
	isLoading, 
	canEdit, 
	fields,
	values,
	formFiles,
	dirty,
	saveResult,
	onSave,
	onSaveAndContinue,
	onCancel
}) => {
	return (
<PageLayout
	title={values.Name}
	isLoading={isLoading}
	formActions={{
		isLoading: isLoading,
		canEdit: canEdit,
		hasUnsavedChanges: dirty,
		onSave: () => onSave(values, formFiles.logo),
		onSaveAndContinue: () => onSaveAndContinue(values, formFiles.logo),
		onCancel: () => onCancel() 
	}}
	content={
		<div className="row">
			<ContentContainer>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.logo()}</Grid>
				</Grid>
			</ContentContainer>

			<MessagePanel isSuccess={saveResult.success} message={saveResult.message} />
		</div>
	}
/>
	);
}

Settings.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onSaveAndContinue: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

Settings.defaultProps = {
};

export default withForm(form)(Settings);
