/* eslint prefer-template: "off" */
/* eslint no-return-assign: "off" */
/* eslint no-param-reassign: "off" */
/* eslint no-plusplus: "off" */
/* eslint no-useless-concat: "off" */

import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
// import TreeView from '@material-ui/lab/TreeView';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
// import TreeItem from '@material-ui/lab/TreeItem';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
// import {
// 	Chip,
// 	Grid,
// 	Tooltip
// } from '@material-ui/core';
import { palette } from '../../variables';
import * as d3 from 'd3';
import tippy from 'tippy.js';

// const useStyles = makeStyles({
//   root: {
//     height: 240,
//     flexGrow: 1
//   },
//   content: {
// 	border: `1px solid ${infoColor}`
//   }
// });

const LinkedCorrespondence = (props) => {
	// const classes = useStyles();
	// const [expanded, setExpanded] = React.useState([]);
	let _rootNode = null;

	const removePreviousChart = () => {
		const chart = _rootNode;
		if (!chart) return;
		while (chart.hasChildNodes()) {
			chart.removeChild(chart.lastChild);
		}
	};

	const createChart = (data) => {
		removePreviousChart();
		if (!data || data.length < 1) return;
		data = map(data, d => {
			const d2 = { ...d, _dataId: d.id };

			let href = '';
			let colour = '';
			switch (d.type) {
				case 'IncomingCorrespondence':
					href = `/incoming-correspondence/${d.id}`;
					colour = palette[0];
					break;
				case 'OutgoingCorrespondence':
					href = `/outgoing-correspondence/${d.id}`;
					colour = palette[1];
					break;
				case 'PrincipalCorrespondence':
					href = `/principal-correspondence/${d.id}`;
					colour = palette[2];
					break;
				case 'DesignCorrespondence':
					href = `/design-correspondence/${d.id}`;
					colour = palette[3];
					break;
				case 'ExtensionOfTime':
					href = `/extension-of-time/${d.id}`;
					colour = palette[4];
					break;
				case 'NonConformanceRegister':
					href = `/ncr/${d.id}`;
					colour = palette[5];
					break;
				case 'Variation':
					href = `/variation/${d.id}`;
					colour = palette[6];
					break;
				default:
			}
			d2.href = href;
			d2.colour = colour;
			
			delete d2.id;
			return d2;
		});

		// Need to increase height to allow space for nodes when there's a lot of 
		// nodes in a single level
		const dataByLevel = groupBy(data, d => d.level);
		let maxNodesInALevel = 0;
		forEach(dataByLevel, d => {
			if (d.length > maxNodesInALevel) maxNodesInALevel = d.length;
		});

		const margin = { 
			top: 30, right: 10, bottom: 10, left: 10 
		};
		const width = 960 - margin.left - margin.right;
		const halfWidth = width / 2;
		const height = Math.max(500, maxNodesInALevel * 60) - margin.top - margin.bottom;
		let i = 0;
		const duration = 500;

		const getChildren = (d) => {
			const a = [];
			const parents = filter(data, d2 => d2.childPath === d.path);
			for (let ii = 0; ii < parents.length; ii++) {
				parents[ii].isRight = false;
				parents[ii].parent = d;
				a.push(parents[ii]);
			}
			const children = filter(data, d2 => d2.parentPath === d.path);
			for (let ii = 0; ii < children.length; ii++) {
				children[ii].isRight = true;
				children[ii].parent = d;
				a.push(children[ii]);
			}
			return a.length ? a : null;
		};

		const tree = d3.layout.tree()
			.size([height, width]);


		const calcLeft = (d) => {
			let l = d.y;
			if (!d.isRight) {
				l = d.y - halfWidth;
				l = halfWidth - l;
			}
			return { x: d.x, y: l };
		};

		// let diagonal = d3.svg.diagonal()
		// 	.projection(function (d) { return [d.y, d.x]; });
		const elbow = (d) => {
			const source = calcLeft(d.source);
			const target = calcLeft(d.target);
			let hy = (target.y - source.y) / 2;
			if (d.isRight) hy = -hy;
			return 'M' + source.y + ',' + source.x
				+ 'H' + (source.y + hy)
				+ 'V' + target.x + 'H' + target.y;
		};
		const connector = elbow;

		const vis = d3.select(_rootNode).append('svg')

			.attr('width', '100%')
			.attr('height', height + margin.top + margin.bottom)
			.call(d3.behavior.zoom().on('zoom', () => {
				vis.attr('transform', 'translate(' + d3.event.translate + ')' + ' scale(' + d3.event.scale + ')');
			}))
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		const toArray = (item, arr) => {
			arr = arr || [];
			let ii = 0;
			const l = item.children ? item.children.length : 0;
			arr.push(item);
			for (; ii < l; ii++) {
				toArray(item.children[ii], arr);
			}
			return arr;
		};

		function update(source) {
			// Toggle children on click.
			function click(d) {
				if (d.children) {
					d._children = d.children;
					d.children = null;
				} else {
					d.children = d._children;
					d._children = null;
				}
				update(source);
			}

			// Compute the new tree layout.
			const nodes = toArray(source);

			// Normalize for fixed-depth.
			nodes.forEach((d) => { d.y = d.depth * 180 + halfWidth; });

			// Update the nodes…
			const node = vis.selectAll('g.node')
				.data(nodes, (d) => d.id || (d.id = ++i));

			// Enter any new nodes at the parent's previous position.
			const nodeEnter = node.enter().append('g')
				.attr('class', 'node')
				.attr('transform', () => 'translate(' + source.y0 + ',' + source.x0 + ')')
				.on('click', click);

			const circles = nodeEnter.append('circle')
				.attr('r', 1e-6)
				.style('fill', (d) => d.colour)
				.attr('data-tippy-content', d => d.description);
			tippy(circles[0]);
				
			nodeEnter
				.append('a')
				.attr('xlink:href', d => d.href)
				.append('text')
				.attr('dy', () => 25)
				.attr('text-anchor', 'middle')
				.text((d) => d.number)
				.style('fill-opacity', 1e-6);

			// Transition nodes to their new position.
			const nodeUpdate = node.transition()
				.duration(duration)
				.attr('transform', (d) => { const p = calcLeft(d); return 'translate(' + p.y + ',' + p.x + ')'; });

			nodeUpdate.select('circle')
				.attr('r', 8)
				.style('fill', (d) => d.colour);

			nodeUpdate.select('text')
				.style('fill-opacity', 1);

			// Transition exiting nodes to the parent's new position.
			const nodeExit = node.exit().transition()
				.duration(duration)
				.attr('transform', (d) => { const p = calcLeft(d.parent || source); return 'translate(' + p.y + ',' + p.x + ')'; })
				.remove();

			nodeExit.select('circle')
				.attr('r', 1e-6);

			nodeExit.select('text')
				.style('fill-opacity', 1e-6);

			// Update the links...
			const link = vis.selectAll('path.link')
				.data(tree.links(nodes), (d) => d.target.id);

			// Enter any new links at the parent's previous position.
			link.enter().insert('path', 'g')
				.attr('class', 'link')
				.attr('d', () => {
					const o = { x: source.x0, y: source.y0 };
					return connector({ source: o, target: o });
				});

			// Transition links to their new position.
			link.transition()
				.duration(duration)
				.attr('d', connector);

			// Transition exiting nodes to the parent's new position.
			link.exit().transition()
				.duration(duration)
				.attr('d', (d) => {
					const o = calcLeft(d.source || source);
					if (d.source.isRight) o.y -= halfWidth - (d.target.y - d.source.y);
					else o.y += halfWidth - (d.target.y - d.source.y);
					return connector({ source: o, target: o });
				})
				.remove();

			// Stash the old positions for transition.
			nodes.forEach((d) => {
				const p = calcLeft(d);
				d.x0 = p.x;
				d.y0 = p.y;
			});
		}

		const root = find(data || [], d => d.isStart);
		root.x0 = height / 2;
		root.y0 = width / 2;

		const t1 = d3.layout.tree().size([height, halfWidth]).children((d) => filter(data, d2 => d2.childPath === d.path));
		const t2 = d3.layout.tree().size([height, halfWidth]).children((d) => filter(data, d2 => d2.parentPath === d.path));
		t1.nodes(root);
		t2.nodes(root);

		const rebuildChildren = (node) => {
			node.children = getChildren(node);
			if (node.children) node.children.forEach(rebuildChildren);
		};
		rebuildChildren(root);
		root.isRight = false;
		update(root);
    };

	React.useEffect(() => {
		// const initialExpanded = map(props.data, d => d.path);
		// setExpanded(initialExpanded.concat(map(filter(props.data, d => d.isRepeating), d => `${d.path}.isRepeating`)));
		createChart(props.data);
	}, [props.data]);

	// const rootData = filter(props.data, d => d.isRoot === 1);

	// const buildTree = (d) => {
	// 	const children = filter(props.data, d2 => d2.path === d.childPath || d2.parentPath === d.path);
	// 	let href = '';
	// 	let type = '';
	// 	switch (d.type) {
	// 		case 'IncomingCorrespondence':
	// 			href = `/incoming-correspondence/${d.id}`;
	// 			type = 'Incoming Correspondence';
	// 			break;
	// 		case 'OutgoingCorrespondence':
	// 			href = `/outgoing-correspondence/${d.id}`;
	// 			type = 'Outgoing Correspondence';
	// 			break;
	// 		case 'PrincipalCorrespondence':
	// 			href = `/principal-correspondence/${d.id}`;
	// 			type = 'Principal Correspondence';
	// 			break;
	// 		case 'DesignCorrespondence':
	// 			href = `/design-correspondence/${d.id}`;
	// 			type = 'Design Correspondence';
	// 			break;
	// 		case 'ExtensionOfTime':
	// 			href = `/extension-of-time/${d.id}`;
	// 			type = 'Extension Of Time';
	// 			break;
	// 		case 'NonConformanceRegister':
	// 			href = `/ncr/${d.id}`;
	// 			type = 'Non Conformance Register';
	// 			break;
	// 		case 'Variation':
	// 			href = `/variation/${d.id}`;
	// 			type = 'Variation';
	// 			break;
	// 		default:
	// 	}

	// 	return (
	// 		<TreeItem 
	// 			nodeId={d.path} 
	// 			classes={{
	// 				content: d.isStart ? classes.content : undefined
	// 			}}
	// 			label={
	// 				<div style={{ position: 'relative' }}>
	// 					<Grid container>
	// 						<Grid item xs={12}>
	// 							<a href={href}>{d.number}</a>
	// 						</Grid>
	// 						<Grid item xs={12} style={{ fontSize: '0.8em' }}>{d.description}</Grid>
	// 					</Grid>
	// 					<div
	// 						style={{ 
	// 							position: 'absolute',
	// 							right: 5,
	// 							top: 0,
	// 							height: '100%',
	// 							display: 'flex',
	// 							flexDirection: 'column',
	// 							justifyContent: 'center'
	// 						}}
	// 					>
	// 						<Chip
	// 							size="small"
	// 							label={type}
	// 						/>
	// 					</div>
	// 				</div>
	// 			}
	// 		>
	// 			{map(children, buildTree)}
	// 			{d.isRepeating && d.level > 0 ?
	// 				<TreeItem
	// 					nodeId={`${d.path}.isRepeating`}
	// 					label={
	// 						<Tooltip title="Infinite Loop Detected">
	// 							<AllInclusiveIcon />
	// 						</Tooltip>
	// 					}
	// 				/> : undefined
	// 			}
	// 		</TreeItem>
	// 	);
	// };
	// const treeItems = map(rootData, (d) => {
	// 	const item  = buildTree(d);
	// 	if (d.isRepeating) {
	// 		return (
	// 			<TreeItem
	// 				nodeId={`${d.path}.isRepeating`}
	// 				label={
	// 					<Tooltip title="Infinite Loop Detected">
	// 						<AllInclusiveIcon />
	// 					</Tooltip>
	// 				}
	// 			>
	// 				{item}
	// 			</TreeItem>
	// 		);
	// 	} else {
	// 		return item;
	// 	}
	// });

	const _setRef = (componentNode) => {
        _rootNode = componentNode;
	};
	

	return (
		<div>
			<div className="line-container" ref={_setRef.bind(this)} />
			{/* <TreeView
				className={classes.root}
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
				disableSelection={true}
				onNodeToggle={(e, nodeIds) => {
					setExpanded(nodeIds);
				}}
				expanded={expanded}
			>
				{treeItems}
			</TreeView> */}
		</div>
	);
};

LinkedCorrespondence.propTypes = {
	data: PropTypes.array
};

LinkedCorrespondence.defaultProps = {
	data: []
};

export default LinkedCorrespondence;
