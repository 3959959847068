import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LotAdjustmentsHandoverContent from '../content/lotAdjustmentsHandoverContent';
import addHandoverWrapper from '../handoverWrapper';

class LotAdjustmentsHandover extends React.Component {
	static getTitle() {
		return 'Lot Adjustments';
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<LotAdjustmentsHandoverContent report={report} />
		);
	}
}

LotAdjustmentsHandover.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	LotAdjustmentsHandover, {
		title: 'Lot Adjustments',
		className: 'lot-adjustments-handover'
	})
);
