import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import TagsGrid from './tagsGrid';
import { deleteTag, requestTags, clearTags } from '../../reducers/tags';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class Tags extends Component {
    constructor(props) {
        super(props);

        this.state = {
			tags: {}
        };

		this.onEditTag = this.onEditTag.bind(this);
		this.onViewTag = this.onViewTag.bind(this);
		this.onDeleteTag = this.onDeleteTag.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestTags());
	}
	static clearData(dispatch) {
		dispatch(clearTags());
	}
	componentDidMount() {
		Tags.fetchData(this.props.dispatch);
	}

	onEditTag = (tag) => {
		this.props.history.push(`/tag/${tag.tagId}`);
	}

	onViewTag = (tag) => {
		this.props.history.push(`/tag/${tag.tagId}`);
	}
	onDeleteTag(tag) {
		this.props.deleteTag(tag);
	}

	newTag = () => {
		this.props.history.push('/tag');
	}

	render() {
		return (
<PageLayout
	title="Tags"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Tag',
				onClick: this.newTag
			}
		] : []
	}
	content={
		<ContentContainer>
			<TagsGrid 
				tags={this.props.tags} 
				onEditTag={this.onEditTag} 
				onDeleteTag={this.onDeleteTag} 
				onViewTag={this.onViewTag} 
				canEdit={this.props.canEdit}
			/>
		</ContentContainer>
	}
/>
		);
	}
}

Tags.propTypes = {
	dispatch: PropTypes.func.isRequired,
	tags: PropTypes.array,
	deleteTag: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

Tags.defaultProps = {
	tags: []
};

const mapStateToProps = (state) => ({
	tags: state.tags.tags,
	canEdit: canEditAnyPeriod(state, 'EditTags'),
	isLoading: state.tags.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteTag: (tag) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Tag?',
			onOk: () => {
				dispatch(deleteTag(tag));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Tags);
