import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';

class LotHandoverContent extends React.Component {
	render() {
		const lot = this.props.report;
		
		const columns = [
			{ name: 'itemNo', label: 'Item' },
			{ name: 'itemDescription', label: 'Description', headerStyle: { minWidth: '200px' } },
			{ name: 'unit', label: 'Unit' },
			{ 
				name: 'qty', 
				label: 'Qty'
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '200px' } }
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Lot No" value={lot.lotNo} />
				<HandoverField label="Description" value={lot.lotDescription} />
				<HandoverField label="Status" value={lot.status} />
				<HandoverField label="Period Claimed" value={lot.periodClaimedName} />
				<HandoverField label="Comments" value={lot.comments} />

			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Items</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={lot.items || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

LotHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default LotHandoverContent;
