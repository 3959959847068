import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ContentContainer from '../contentContainer';
import MessagePanel from '../messagePanel';
import PageLayout from '../pageLayout';
import { emptySaveResult } from '../../variables';
import { get, post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import { withForm } from '../../utils/forms';
import { 
	Grid
} from '@material-ui/core';

const helpPageForm = {
	initValues: ({
	}) => ({ 
		name: '',
		description: '',
		pageUrl: '/',
		helpUrl: 'https://scribehow.com/shared/',
		isActive: true,
	}),
	fields: [
		{
			name: "name",
			label: "Name",
			required: false
		},
		{
			name: "description",
			label: "Description",
			required: false
		},
		{
			name: "pageUrl",
			label: "Page Url",
			required: true
		},
		{
			name: "helpUrl",
			label: "Help Page Url"
		},
		{
			name: "isActive",
			label: "Is Active",
			type: 'checkbox'
		}
	]
};

const HelpPageMaintenance = ({ 
	match,
	showSuccessNotification,
	showErrorNotification,
	history,
	canEdit,
	fields,
	values,
	updateValues,
	dirty,
	validateFields
}) => {
	const [state, setState] = React.useState({
		isLoading: false,
		saveResult: emptySaveResult,
	});

	React.useEffect(() => {
		if (match.params.helpPageId) {
			setState(prevState => ({ ...prevState, isLoading: true }));
			get({
				url: `/api/help-pages/${match.params.helpPageId}`,
				onSuccess: data => {
					updateValues(data);
					setState(prevState => ({ ...prevState, isLoading: false }))
				},
				onError: (error) => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					showErrorNotification(error.message);
				}
			});
		}
	}, [match.params.helpPageId]);

	const save = (onSuccess) => {
		if (!validateFields()) return;

		setState(prevState => ({ ...prevState, isLoading: true, saveResult: emptySaveResult }));
			
		post({
			url: `/api/help-pages`,
			data: values,
			onSuccess: data => {
				setState({ 
					isLoading: false, 
					helpPage: data.success ? data.object : values,
					saveResult: {
						success: data.success,
						message: data.message,
						fields: data.fields
					} 
				});

				if (data.success) {
					showSuccessNotification(data.message);
					if (onSuccess) onSuccess();
				} else {
					showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				showErrorNotification(error.message);
			}
		});
	};
	const saveChanges = () => save(() => { history.goBack(); });
	const saveChangesAndContinue = () => save();

	return (
		<PageLayout
			title={values ? values.name : ''}
			isLoading={state.isLoading}
			formActions={{
				isLoading: state.isLoading,
				canEdit: canEdit,
				hasUnsavedChanges: dirty,
				onSave: () => saveChanges(values),
				onSaveAndContinue: () => saveChangesAndContinue(values),
				onCancel: () => history.goBack()
			}}
			content={
				<div>
					<ContentContainer>
						<Grid container spacing={1}>
							<Grid item xs={12}>{fields.name()}</Grid>
							<Grid item xs={12}>{fields.description()}</Grid>
							<Grid item xs={12}>{fields.pageUrl()}</Grid>
							<Grid item xs={12}>{fields.helpUrl()}</Grid>
							<Grid item xs={12}>{fields.isActive()}</Grid>
						</Grid>
					</ContentContainer>

					<MessagePanel 
						isSuccess={state.saveResult.success} 
						message={state.saveResult.message} 
					/>
				</div>
			}
		/>
	);
};

HelpPageMaintenance.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	canEdit: canEditAnyPeriod(state, 'EditHelpPages')
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(withForm(helpPageForm)(HelpPageMaintenance));
