import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import forEach from 'lodash/forEach';
import PersistedDataTable from '../widgets/persistedDataTable';
import Checkbox from '../widgets/Checkbox';
import { produce } from 'immer';
import filter from 'lodash/filter';
import find from 'lodash/find';

class PeriodPicker extends React.Component {
	state = {
		periods: this.props.periods
	};

	selectPeriod(period, selected) {
		this.setState(produce(draft => {
			forEach(draft.periods, (p) => {
				if (p.periodId === period.periodId) {
					p._isSelected = selected;
				} else if (!this.props.multiple) {
					p._isSelected = false;
				}
			});
		}));
	}
	isAllSelected = () => {
		for (let i = 0, ii = this.state.periods.length; i < ii; i++) {
			const period = this.state.periods[i];
			if (period._isSelected !== true) return false;
		}
		return true;
	}
	toggleAll = () => {
		const nextValue = !this.isAllSelected();
		this.setState(produce(draft => {
			forEach(draft.periods, (p) => {
				p._isSelected = nextValue;
			});
		}));
	}
	onOk = () => {
		if (this.props.multiple) {
			this.props.onOk(filter(this.state.periods, p => p._isSelected));
		} else {
			this.props.onOk(find(this.state.periods, p => p._isSelected));
		}
	}

	render() {
		const periods = this.state.periods || [];

		const columns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				value: (args) => args.data._isSelected || false,
				dataType: 'bool',
				sortable: false,
				headerRenderer: this.props.multiple ? () =>
					<Checkbox
						checked={this.isAllSelected()}
						onCheck={() => { this.toggleAll(); } }
					/> : undefined,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectPeriod(args.data, v); } }
					/> 
			},
			{ name: 'name', label: 'Name' },
			{ name: 'startDate', label: 'Start Date', dataType: 'date' },
			{ name: 'endDate', label: 'End Date', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status',
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed'] 
			}
		];

		return (
	<Dialog
		title="Periods"
		actions={
			<React.Fragment>
				<Button
					label="Ok"
					primary={true}
					onClick={() => this.onOk()}
				/>,
				<Button
					label="Cancel"
					primary={false}
					onClick={this.props.cancel}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.cancel}
		scroll='paper'
		fullWidth={true}
		maxWidth='lg'
	>
		<PersistedDataTable 
			id="periodPicker"
			data={periods || []} 
			columns={columns}
		/>
	</Dialog>
		);
	}
}

PeriodPicker.propTypes = {
	show: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	periods: PropTypes.array.isRequired,
	multiple: PropTypes.bool
};

PeriodPicker.defaultProps = {
	multiple: false
};

const mapStateToProps = (state) => ({
	periods: state.context.contract.periods
});

const mapDispatchToProps = (dispatch) => ({
	dispatch
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PeriodPicker);
