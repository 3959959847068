import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { deleteEmployee, requestEmployees, clearEmployees } from '../../reducers/employees';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '../widgets/button';
import Dialog from '../widgets/Dialog';
import MessagePanel from '../messagePanel';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PersistedDataTable from '../widgets/persistedDataTable';
import PageLayout from '../pageLayout';
import { canEdit } from '../../selectors/canEdit';
import { canView } from '../../selectors/canView';
import ViewIcon from '@material-ui/icons/Pageview';

class Employees extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			showDeleteConfirmation: false,
			employee: null
		};

        this.handleEditEmployee = this.handleEditEmployee.bind(this);
        this.handleDeleteEmployee = this.handleDeleteEmployee.bind(this);
        this.handleConfirmDeleteEmployee = this.handleConfirmDeleteEmployee.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
		this.onViewEmploye = this.onViewEmployee.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestEmployees(null));
	}
	static clearData(dispatch) {
		dispatch(clearEmployees());
	}

	componentDidMount() {
		Employees.fetchData(this.props.dispatch);
	}

	handleEditEmployee = (employeeId) => {
		this.props.history.push(`/employee/${employeeId}`);
	}
	onViewEmployee = (employeeId) => {
		this.props.history.push(`/employee/${employeeId}`);
	}

	handleDeleteEmployee(employee) {
		this.setState({
			showDeleteConfirmation: true,
			employee: employee
		});
	}
	handleConfirmDeleteEmployee() {
        this.setState({ 
			showDeleteConfirmation: false 
		});
		this.props.deleteEmployee(this.state.employee);
	}
    handleCancelDelete() {
        this.setState({ 
			showDeleteConfirmation: false 
		});
    }

	newEmployee = () => {
		this.props.history.push('/employee');
	}

	render() {
		const employees = this.props.employees || [];

		const actions = [
			<Button
				key="yes-button"
				label="Yes"
				primary={true}
				onClick={this.handleConfirmDeleteEmployee}
			/>,
			<Button
				key="no-button"
				label="No"
				primary={false}
				onClick={this.handleCancelDelete}
			/>
		];
		const deleteDialog = (
			<Dialog
				title="Delete Employee?"
				actions={actions}
				open={this.state.showDeleteConfirmation}
				onClose={this.handleCancelDelete}
			>
				Are you sure you want to delete this employee?
			</Dialog>
		);

		const columns = [
			{ name: 'name', label: 'Name' },
			{ name: 'email', label: 'Email' },
			{ name: 'isActive', label: 'Is Active', dataType: 'bool' }
		];


		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.handleEditEmployee(args.data.employeeId); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.deleteEmployee(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {	
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.onViewEmployee(args.data.employeeId); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
<PageLayout
	title="Employees"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Employee',
				onClick: this.newEmployee
			}
		] : []
	}
	content={
		<div>
		{deleteDialog}

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />

		<ContentContainer>
			<PersistedDataTable 
				id="employees"
				data={employees || []} 
				columns={columns}
			/>
		</ContentContainer>
		</div>
	}
/>
    );
  }
}

Employees.propTypes = {
	dispatch: PropTypes.func.isRequired,
	employees: PropTypes.array,
	deleteEmployee: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired
};

Employees.defaultProps = {
	employees: []
};

const mapStateToProps = (state) => ({
	employees: state.employees.employees,
	isLoading: state.employees.isLoading,
	saveResult: state.employees.saveResult,
	canView: canView(state, 'ViewEmployees'),
	canEdit: canEdit(state, 'EditEmployees')
});
const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteEmployee: (employee) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this employee?',
			onOk: () => {
				dispatch(deleteEmployee(employee));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(Employees);
