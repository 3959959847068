import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgressClaimByLotReportContent from '../reports/progressClaimByLotReportContent';
import addHandoverWrapper from './handoverWrapper';

class ProgressClaimByLotReport extends React.Component {
	static getTitle(report) {
		return `Progress Claim by Lot`;
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<ProgressClaimByLotReportContent report={report} />
		);
	}
}

ProgressClaimByLotReport.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	ProgressClaimByLotReport, {
		title: 'Progress Claim by Lot',
		className: 'progress-claim-report'
	})
);
