import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import ContentContainer from '../contentContainer';
import MessagePanel from '../messagePanel';
import Wizard from '../wizard/wizard';
import { 
	Grid
} from '@material-ui/core';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import map from 'lodash/map';
import ContractorField from './NewContract/ContractorField';
import SchedulesField from './NewContract/SchedulesField';
import EmployeeField from './NewContract/EmployeeField';
import ContactField from './NewContract/ContactField';
import UserAccessField from './NewContract/UserAccessField';
import PeriodField from './NewContract/PeriodField';
import isObject from 'lodash/isObject';
import forOwn from 'lodash/forOwn';
import filter from 'lodash/filter';
import DisplayField from '../widgets/DisplayField';
import DisplayLine from '../widgets/DisplayLine';
import { toCurrency } from '../../utils/utils';

const contractFields = [
	{
		name: "contractNumber",
		label: "Contract Number",
		required: true
	},
	{
		name: "contractName",
		label: "Contract Name",
		required: true
	},
	{
		name: "dateCommenced",
		label: "Date Commenced",
		type: 'date'
	},
	{
		name: "clientName",
		label: "Client's Name"
	},
	{
		name: "practicalCompletionDate",
		label: "Practical Completion",
		type: 'date'
	},
	{
		name: "defectsLiabilityPeriod",
		label: "Defects Liability Period",
		type: 'numeric',
		numberType: 'int'
	},
	{
		name: "liquidatedDamagesAppliesFrom",
		label: "Applies From",
		type: 'date'
	},
	{
		name: "liquidatedDamagesAmountPerDay",
		label: "Amount Per Day",
		type: 'numeric',
		numberType: 'currency'
	},
	{
		name: "orderNumber",
		label: "Order Number"
	},
	{
		name: "variationPrefix",
		label: "Variation Prefix"
	},
	{
		name: "variationNumberSignificantDigits",
		label: "Variation Number Significant Digits",
		type: 'numeric'
	},
	{
		name: "incomingCorrespondencePrefix",
		label: "Incoming Correspondence Prefix"
	},
	{
		name: "incomingCorrespondenceNumberSignificantDigits",
		label: "Incoming Correspondence Significant Digits",
		type: 'numeric'
	},
	{
		name: "outgoingCorrespondencePrefix",
		label: "Outgoing Correspondence Prefix"
	},
	{
		name: "outgoingCorrespondenceNumberSignificantDigits",
		label: "Outgoing Correspondence Significant Digits",
		type: 'numeric'
	},
	{
		name: "principalCorrespondencePrefix",
		label: "Principal Correspondence Prefix"
	},
	{
		name: "principalCorrespondenceNumberSignificantDigits",
		label: "Principal Correspondence Significant Digits",
		type: 'numeric'
	},
	{
		name: "designCorrespondencePrefix",
		label: "Design Correspondence Prefix"
	},
	{
		name: "designCorrespondenceNumberSignificantDigits",
		label: "Design Correspondence Significant Digits",
		type: 'numeric'
	}
];
const contractorFields = [
	{
		name: "contractor",
		label: "Contractor",
		type: "custom",
		validate: (value, values) => {
			if (value.contractorId === -1) {
				if (!value.name) {
					return {
						name: "Name is required"
					};
				}
			}
		},
		widget: ContractorField
	},
];
const scheduleFields = [
	{
		name: "schedules",
		label: "Schedules of Rates",
		type: "custom",
		validate: (value, values) => {
			const schedules = value || [];
			if (schedules.length <= 0) {
				return {
					schedules: "At least one schedule is required"
				};
			}
		},
		widget: SchedulesField
	},
];

const employeeFields = [
	{
			name: "employees",
			label: "Employees",
			type: "custom",
			defaultValue: [],
			validate: (value, values) => {
			},
			widget: EmployeeField
	}
];
const contactFields = [
	{
			name: "contacts",
			label: "Contacts",
			type: "custom",
			defaultValue: [],
			validate: (value, values) => {
			},
			widget: ContactField
		}
];
const userAccessFields = [
	{
			name: "userAccess",
			label: "User Access",
			type: "custom",
			defaultValue: [],
			validate: (value, values) => {
			},
			widget: UserAccessField
		}
];
const periodFields = [
	{
			name: "period",
			label: "Period",
			type: "custom",
			defaultValue: [],
			validate: (value, values) => {
				if (value.startImmediately && !value.periodName) return 'Period Name is required';
			},
			widget: PeriodField
		}
];

const form = {
	initValues: props => props.contract,
	fields: [
		...contractFields,
		...contractorFields,
		...scheduleFields,
		...employeeFields,
		...contactFields,
		...userAccessFields,
		...periodFields
	]
};

const NoDataText = ({ text }) => 
	<p style={{
		fontStyle: 'italic',
		marginLeft: '5px'
	}}>{text}</p>;

const NewContract = ({ 
	fields,
	values,
	validateFields,
	loading, 
	success,
	saveResult,
	error,
	onComplete,
	clearSaveResult
}) => {
	const errors = [];
	forEach(Object.keys(error), (fieldName, i) => {
		if (error[fieldName]) {
			if (isObject(error[fieldName])) {
				forOwn(error[fieldName], (v, k) => {
					errors.push(`${find(form.fields, f => f.name === fieldName).label}: ${v}`);
				});
			} else {
				errors.push(`${find(form.fields, f => f.name === fieldName).label}: ${error[fieldName]}`);
			}
		}
	});

	return (
	<div>
		<Wizard 
			loading={loading}
			success={success}
			errors={errors}
			submitLabel="Create"
			onPrev={() => { clearSaveResult(); }}
			onComplete={(onSuccess, onError) => {
				onComplete(
					values, 
					() => {},
					(error) => { onError(); }
				);
			}}
			stepsStyle={{ minWidth: '715px' }}
			steps={[
				{ 
					title: 'Contract', 
					validate: () => {
						const fields = map(contractFields, f => f.name);
						return validateFields(fields);
					},
					content:
						<ContentContainer>
						<Grid container spacing={1}>
							<Grid item xs={12}><div className="form-section-header">Contract Details</div></Grid>

							<Grid item xs={12}>{fields.contractNumber()}</Grid>
							<Grid item xs={12}>{fields.contractName()}</Grid>
							<Grid item xs={12} sm={6}>{fields.dateCommenced()}</Grid>
							<Grid item xs={12} sm={6}>{fields.practicalCompletionDate()}</Grid>
							<Grid item xs={12}>{fields.clientName()}</Grid>
							<Grid item xs={12} sm={6}>{fields.defectsLiabilityPeriod()}</Grid>

							<Grid item xs={12}><div className="form-section-header">Liquidated Damages</div></Grid>

							<Grid item xs={12} sm={6}>{fields.liquidatedDamagesAppliesFrom()}</Grid>
							<Grid item xs={12} sm={6}>{fields.liquidatedDamagesAmountPerDay()}</Grid>
							<Grid item xs={12}>{fields.orderNumber()}</Grid>

							<Grid item xs={12}><div className="form-section-header">Prefixes</div></Grid>

							<Grid item xs={12} sm={6}>{fields.variationPrefix()}</Grid>
							<Grid item xs={12} sm={6}>{fields.variationNumberSignificantDigits()}</Grid>

							<Grid item xs={12} sm={6}>{fields.incomingCorrespondencePrefix()}</Grid>
							<Grid item xs={12} sm={6}>{fields.incomingCorrespondenceNumberSignificantDigits()}</Grid>

							<Grid item xs={12} sm={6}>{fields.outgoingCorrespondencePrefix()}</Grid>
							<Grid item xs={12} sm={6}>{fields.outgoingCorrespondenceNumberSignificantDigits()}</Grid>

							<Grid item xs={12} sm={6}>{fields.principalCorrespondencePrefix()}</Grid>
							<Grid item xs={12} sm={6}>{fields.principalCorrespondenceNumberSignificantDigits()}</Grid>

							<Grid item xs={12} sm={6}>{fields.designCorrespondencePrefix()}</Grid>
							<Grid item xs={12} sm={6}>{fields.designCorrespondenceNumberSignificantDigits()}</Grid>
						</Grid>
						</ContentContainer>
				},
				{ 
					title: 'Contractor', 
					validate: () => {
						const fields = map(contractorFields, f => f.name);
						return validateFields(fields);
					},						
					content: 
						<ContentContainer>
							<p>
								A Contractor must be set for the contract. You can either use an existing Contractor or add a new Contractor.
							</p>
							<Grid container spacing={3}>
								<Grid item xs={12}>{fields.contractor()}</Grid>
							</Grid>
						</ContentContainer>
				},
				{ 
					title: 'Employees', 
					validate: () => validateFields(["employees"]),
					content: 
						<React.Fragment>
							<p style={{ fontStyle: 'italic', textAlign: 'center' }}>Employees are generally used in correspondence. The list has been defaulted from the list of current active users but you can modify this as needed.</p>
							<ContentContainer>
								<Grid container spacing={3}>
									<Grid item xs={12}>{fields.employees()}</Grid>
								</Grid>
							</ContentContainer>
						</React.Fragment>
				},
				{ 
					title: 'Contacts', 
					validate: () => validateFields(["contacts"]),
					content: 
					<React.Fragment>
						<p style={{ fontStyle: 'italic', textAlign: 'center' }}>Contacts are generally used in correspondence. You can add these later as needed.</p>
						<ContentContainer>
							<Grid container spacing={3}>
								<Grid item xs={12}>{fields.contacts()}</Grid>
							</Grid>
						</ContentContainer>
					</React.Fragment>
				},
				{ 
					title: 'Schedules', 
					validate: () => {
						const fields = map(scheduleFields, f => f.name);
						return validateFields(fields);
					},						
					content: 
						<React.Fragment>
							<Grid container spacing={3}>
								<Grid item xs={12}>{fields.schedules()}</Grid>
							</Grid>
						</React.Fragment>
				},
				{ 
					title: 'User Access', 
					validate: () => validateFields(["users"]),
					content: 
						<React.Fragment>
							<p style={{ fontStyle: 'italic', textAlign: 'center' }}>Configure who has access to this contract.</p>
							<ContentContainer>
								<Grid container spacing={3}>
									<Grid item xs={12}>{fields.userAccess()}</Grid>
								</Grid>
							</ContentContainer>
						</React.Fragment>
				},
				{ 
					title: 'Period', 
					validate: () => validateFields(["period"]),
					content: 
						<React.Fragment>
							<p style={{ fontStyle: 'italic', textAlign: 'center' }}>You can start the contract now or at any later time.</p>
							<p style={{ fontStyle: 'italic', textAlign: 'center' }}>Note that you will not be able to edit Schedules of Rates after the contract has started. Variations will be required for any changes.</p>
							<ContentContainer>
								{fields.period()}
							</ContentContainer>
						</React.Fragment>
				},
				{ 
					title: 'Review', 
					content: ((newContract) => {
						let scheduleCount = 0;
						let contractTotal = 0;
						for (let i = 0, ii = newContract.schedules.length; i < ii; i++) {
							scheduleCount += 1;
							const schedule = newContract.schedules[i];
							for (let j = 0, jj = schedule.items.length; j < jj; j++) {
								const item = schedule.items[j];
								if (!item.isTextOnlyLine) {
									contractTotal += item.rate * item.qty;
								}
							}
						}

						let usersWithAccess = map(filter(newContract.userAccess, u => u.hasFullAccess || u.roles.length > 0), u => u.name);

						return (
							<div>
								<p>
									Review the new contract below then click Create.
								</p>
								
								<ContentContainer>
									<DisplayField label="Contract Name" value={newContract.contractNumber} />
									<DisplayField label="Contract Number" value={newContract.contractName} />
							</ContentContainer>
								<ContentContainer>
									<DisplayField label="Contractor" value={newContract.contractor.name} />
								</ContentContainer>
								<ContentContainer>
									<DisplayField label="Contacts" value={newContract.contacts.length > 0 ? `${newContract.contacts.length} Contacts` : 'No Contacts'} />
								</ContentContainer>
								<ContentContainer>
									<DisplayField label="Employees" value={newContract.employees.length > 0 ? `${newContract.employees.length} Employees` : 'No Employees'} />
								</ContentContainer>
								<ContentContainer>
									<DisplayLine value={`${scheduleCount} schedule${scheduleCount > 1 ? 's' : ''} with a total value of ${toCurrency(contractTotal)}`} />
								</ContentContainer>
								<ContentContainer>
									{usersWithAccess.length === 0 ? 
										<DisplayLine value= "No user have access to this new contract" />
										: 
										<DisplayField label="Users with some level of access" value={usersWithAccess.join(',')} />
									}
								</ContentContainer>
								<ContentContainer>
									<DisplayLine value={newContract.period.startImmediately ? `The contract will be started immediately and the first period is '${newContract.period.periodName}'` : 'The contract will not be started immediately'} />
								</ContentContainer>
							</div>
						);
					})(values)
				}
			]} 
		/>

		<MessagePanel isSuccess={saveResult.success} message={saveResult.message} />
	</div>
	);
}

NewContract.propTypes = {
};

export default withForm(form)(NewContract);
