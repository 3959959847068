import { createFetchAction, createPostAction,createFormPostAction } from '../utils/reducer-utils';

const initialState = {
	isRehydrating: false,
	failedAjaxCalls: []
};

const UPDATE_IS_REHYDRATING = 'UPDATE_IS_REHYDRATING';
export const updateIsRehydrating = (isRehydrating) => ({ type: UPDATE_IS_REHYDRATING, data: isRehydrating });

const ADD_FAILED_AJAX_CALL = "ADD_FAILED_AJAX_CALL";
export const addFailedAjaxCall = ajaxCall => ({ type: ADD_FAILED_AJAX_CALL, ajaxCall });
const CLEAR_FAILED_AJAX_CALLS = "CLEAR_FAILED_AJAX_CALLS";
export const clearFailedAjaxCalls = () => ({ type: CLEAR_FAILED_AJAX_CALLS });

export const retryFailedAjaxCall = ajaxCall => {
	switch (ajaxCall.type) {
		case "get":
			return createFetchAction(ajaxCall.args);
		case "post":
			return createPostAction(ajaxCall.args);
		case "formPost":
			return createFormPostAction(ajaxCall.args);
		default:
			return undefined;
	}
};


export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_IS_REHYDRATING: {
			return {
				...state,
				isRehydrating: action.data
			};
		}
		case ADD_FAILED_AJAX_CALL:
			return { ...state, failedAjaxCalls: state.failedAjaxCalls.concat([action.ajaxCall]) };
		case CLEAR_FAILED_AJAX_CALLS:
			return { ...state, failedAjaxCalls: [] };
		default:
			return state;
	}
};
