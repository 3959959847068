import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveNCR, 
	clearNCR, 
	requestNCR, 
	createNewNCR 
} from '../../reducers/nonConformanceRegister';
import { requestLots } from '../../reducers/lots';
import NCRForm from './ncrForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class NCRMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			ncr: props.ncr,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.setSelectedLots = this.setSelectedLots.bind(this);
		this.deleteLot = this.deleteLot.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestLots());
		if (props.match.params.ncrId) {
			dispatch(requestNCR(props.match.params.ncrId));
		} else {
			dispatch(createNewNCR());
		}
	}
	static clearData(dispatch) {
		dispatch(clearNCR());
	}
	componentDidMount() {
		NCRMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			ncr: nextProps.ncr,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const ncr = this.state.ncr;
		ncr[field] = newValue;
        this.setState({
			ncr,
			hasUnsavedChanges: true
        });
	}

	setSelectedLots(lots) {
		forEach(lots, (newLot) => {
			const lot = find(this.state.ncr.lots, (l) => l.lotId === newLot.lotId);
			if (!lot) {
				this.state.ncr.lots.push(newLot);
			}
		});

		// Need to call setState to update the NCR
        this.setState({
			ncr: this.state.ncr,
			hasUnsavedChanges: true
        });
	}
	deleteLot(lot) {
		// Need to call setState to update the NCR
        this.setState({
			ncr: {
				...this.state.ncr,
				lots: filter(this.state.ncr.lots, (l) => l.lotId !== lot.lotId)
			},
			hasUnsavedChanges: true
        });
	}

	onSelectCorrespondence = (selectedCorrespondence) => {
		const ncr = this.state.ncr;
		const linkedCorrespondence = [];

		forEach(selectedCorrespondence.incomingCorrespondence, (c) => {
			const lc = {
				targetType: 'NonConformanceRegister',
				targetId: ncr.ncrId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Incoming',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle,
				correspondenceDate: c.correspondenceDate
			};

			// Keep the current id if it already existed
			const existingLC = find(ncr.linkedCorrespondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Incoming');
			if (existingLC) lc.linkedCorrespondenceId = existingLC.linkedCorrespondenceId;

			linkedCorrespondence.push(lc);
		});

		forEach(selectedCorrespondence.outgoingCorrespondence, (c) => {
			const lc = {
				targetType: 'NonConformanceRegister',
				targetId: ncr.ncrId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Outgoing',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle,
				correspondenceDate: c.correspondenceDate
			};

			// Keep the current id if it already existed
			const existingLC = find(ncr.linkedCorrespondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Outgoing');
			if (existingLC) lc.linkedCorrespondenceId = existingLC.linkedCorrespondenceId;

			linkedCorrespondence.push(lc);
		});

		ncr.linkedCorrespondence = linkedCorrespondence;
		this.setState({ 
			ncr,
			hasUnsavedChanges: true
		});
	}
	deleteLinkedCorrespondence = (correspondence) => {
        this.setState({
			ncr: {
				...this.state.ncr,
				linkedCorrespondence: filter(this.state.ncr.linkedCorrespondence, (c) => !(c.correspondenceType === correspondence.correspondenceType && c.correspondenceId === correspondence.correspondenceId))
			},
			hasUnsavedChanges: true
        });
	}
	viewLinkedCorrespondence =(correspondence) => {
		switch (correspondence.correspondenceType) { 
			case 'Incoming':
				this.props.history.push(`/incoming-correspondence/${correspondence.correspondenceId}`);
				break;
			case 'Outgoing':
				this.props.history.push(`/outgoing-correspondence/${correspondence.correspondenceId}`);
				break;
			default:
		}
	}

	render() {
		const ncr =  this.state.ncr || { tags: [] };

		return (
<PageLayout
	title={ncr._isNew ? 'New Non-Conformance Register' : ncr.contractorNcrNo}
	isLoading={this.props.isLoading}
	formActions={{
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.ncr),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.ncr),
		onCancel: () => this.props.history.goBack()
	}}
	content={
	<div>
		<NCRForm 
			canEdit={this.props.canEdit}
			ncr={ncr} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			setSelectedLots={this.setSelectedLots}
			deleteLot={this.deleteLot}
			ncrs={this.props.ncrs}
			lots={this.props.lots}
			onSelectCorrespondence={this.onSelectCorrespondence}
			deleteLinkedCorrespondence={this.deleteLinkedCorrespondence}
			viewLinkedCorrespondence={this.viewLinkedCorrespondence}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
	</div>
	}
/>
		);
	}
}

NCRMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	ncr: PropTypes.object.isRequired,
	ncrs: PropTypes.array.isRequired,
	lots: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	ncr: state.nonConformanceRegister.ncr,
	ncrs: state.nonConformanceRegister.ncrs,
	lots: state.lots.lots,
	canEdit: canEdit(state, 'EditNonConformanceRegister'),
	isLoading: state.nonConformanceRegister.isLoading,
	saveResult: state.nonConformanceRegister.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (ncr) => {
		dispatch(saveNCR(ncr, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (ncr) => {
		dispatch(saveNCR(ncr, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(NCRMaintenance);
