import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveVariation, 
	requestVariation, 
	clearVariation, 
	createNewVariation 
} from '../../reducers/variations';
import { requestEmployees } from '../../reducers/employees';
import { requestScheduleOfRates } from '../../reducers/scheduleOfRates';
import VariationForm from './variationForm';
import MessagePanel from '../messagePanel';
import find from 'lodash/find';
import filter from 'lodash/filter';
import last from 'lodash/last';
import forEach from 'lodash/forEach';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class VariationMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			variation: props.variation,
			newFiles: [],
			scheduleOfRates: props.scheduleOfRates,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.updateVariationItem = this.updateVariationItem.bind(this);
		this.deleteVariationItem = this.deleteVariationItem.bind(this);
		this.addFiles = this.addFiles.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.onSelectCorrespondence = this.onSelectCorrespondence.bind(this);
		this.deleteLinkedCorrespondence = this.deleteLinkedCorrespondence.bind(this);
		this.viewLinkedCorrespondence = this.viewLinkedCorrespondence.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestScheduleOfRates());
		dispatch(requestEmployees());
		if (props.match.params.variationId) {
			dispatch(requestVariation(props.match.params.variationId));
		} else {
			dispatch(createNewVariation(props.contract.contractId));
		}
	}
	static clearData(dispatch) {
		dispatch(clearVariation());
	}
	componentDidMount() {
		VariationMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			variation: nextProps.variation,
			scheduleOfRates: nextProps.scheduleOfRates,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const variation = this.state.variation;
		if (field === 'status' && variation[field] === 'Open' && newValue !== 'Open') {
			variation.periodClaimed = last(this.props.periods).periodId;
		}
		variation[field] = newValue;
        this.setState({
			variation,
			hasUnsavedChanges: true
        });
	}
	updateVariationItem(newItem) {
		const variationItem = find(this.state.variation.items, (i) => i.scheduleOfRatesItemId === newItem.scheduleOfRatesItemId);
		if (variationItem) {
			variationItem.qty = newItem.qty;
			variationItem.rate = newItem.rate;
		} else {
			this.state.variation.items.push(newItem);
		}

		// Need to call setState to update the variation
        this.setState({
			variation: this.state.variation,
			hasUnsavedChanges: true
        });
	}
	deleteVariationItem(variationItem) {
		// Need to call setState to update the variation
        this.setState({
			variation: {
				...this.state.variation,
				items: filter(this.state.variation.items, (i) => i.scheduleOfRatesItemId !== variationItem.scheduleOfRatesItemId)
			},
			hasUnsavedChanges: true
        });
	}

	downloadFile(file) {
		window.open(`/api/variations/${this.state.variation.variationId}/download-file/${file.userFileId}`, '_self');
	}
	deleteFile(file) {
		const variation = this.state.variation;
		variation.files = filter(variation.files, (f) => f !== file);
        this.setState({
			variation,
			hasUnsavedChanges: true
        });
	}
	addFiles(type, files) {
		const variation = this.state.variation;
		const newFiles = this.state.newFiles;
		if (!variation.files) variation.files = [];
		forEach(files, (f) => {
			variation.files.push({
				referenceType: type,
				name: f.name,
				sizeBytes: f.size,
				_isNew: true
			});
			newFiles.push({
				data: type,
				file: f
			});
		});
		this.setState({
			variation,
			newFiles,
			hasUnsavedChanges: true
		});
	}

	onSelectCorrespondence(selectedCorrespondence) {
		const variation = this.state.variation;
		const correspondence = [];

		forEach(selectedCorrespondence.incomingCorrespondence, (c) => {
			const vc = {
				variationId: variation.variationId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Incoming',
				correspondenceNumber: c.correspondenceNumber
			};

			// Keep the current id if it already existed
			const existingVC = find(variation.correspondence, (c2) => c2.correspondenceId === vc.correspondenceId && c2.correspondenceType === 'Incoming');
			if (existingVC) vc.variationCorrespondenceId = existingVC.variationCorrespondenceId;

			correspondence.push(vc);
		});

		forEach(selectedCorrespondence.outgoingCorrespondence, (c) => {
			const vc = {
				variationId: variation.variationId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Outgoing',
				correspondenceNumber: c.correspondenceNumber
			};

			// Keep the current id if it already existed
			const existingVC = find(variation.correspondence, (c2) => c2.correspondenceId === vc.correspondenceId && c2.correspondenceType === 'Outgoing');
			if (existingVC) vc.variationCorrespondenceId = existingVC.variationCorrespondenceId;

			correspondence.push(vc);
		});

		variation.correspondence = correspondence;
		this.setState({ 
			variation,
			hasUnsavedChanges: true
		});
	}
	deleteLinkedCorrespondence(correspondence) {
        this.setState({
			variation: {
				...this.state.variation,
				correspondence: filter(this.state.variation.correspondence, (c) => !(c.correspondenceType === correspondence.correspondenceType && c.correspondenceId === correspondence.correspondenceId))
			},
			hasUnsavedChanges: true
        });
	}
	viewLinkedCorrespondence = (correspondence) => {
		switch (correspondence.correspondenceType) { 
			case 'Incoming':
				this.props.history.push(`/incoming-correspondence/${correspondence.correspondenceId}`);
				break;
			case 'Outgoing':
				this.props.history.push(`/outgoing-correspondence/${correspondence.correspondenceId}`);
				break;
			default:
		}
	}

	render() {
		const variation = this.state.variation || {};
		const scheduleOfRates = this.state.scheduleOfRates;

		return (
<PageLayout
	title={
		<span>
			{variation.variationNo}
		</span>
	}
	isLoading={this.props.isLoading}
	formActions={{
		isLoading: this.props.isLoading,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.variation, this.state.newFiles),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.variation, this.state.newFiles),
		onCancel: () => this.props.history.goBack()
	}}
	content={
	<div>
		<VariationForm 
			variation={variation} 
			canEdit={this.props.canEdit}
			updateField={this.updateField} 
			deleteVariationItem={this.deleteVariationItem} 
			updateVariationItem={this.updateVariationItem} 
			onSelectCorrespondence={this.onSelectCorrespondence}
			deleteLinkedCorrespondence={this.deleteLinkedCorrespondence}
			viewLinkedCorrespondence={this.viewLinkedCorrespondence}
			downloadFile={this.downloadFile}
			deleteFile={this.deleteFile}
			addFiles={this.addFiles}
			scheduleOfRates={scheduleOfRates} 
			userList={this.props.userList}
			employees={this.props.employees}
			periods={this.props.periods} 
			onSubmit={this.handleSubmit} 
			onCancel={this.onCancel} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult} 
		/>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
	</div>
	}
/>
		);
	}
}

VariationMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	variation: PropTypes.object.isRequired,
	scheduleOfRates: PropTypes.array.isRequired,
	userList: PropTypes.array.isRequired,
	employees: PropTypes.array.isRequired,
	periods: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	contract: state.context.contract,
	variation: state.variations.variation,
	canEdit: canEdit(state, 'EditVariations'),
	scheduleOfRates: state.scheduleOfRates.scheduleOfRates,
	userList: state.users.userList,
	employees: state.employees.employees,
	periods: state.context.contract.periods,
	isLoading: state.variations.isLoading,
	saveResult: state.variations.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (variation, newFiles) => {
		dispatch(saveVariation(variation, newFiles, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (variation, newFiles) => {
		dispatch(saveVariation(variation, newFiles, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(VariationMaintenance);
