import React from 'react';
import { connect } from 'react-redux';
import FormContainer from '../formContainer';
import FormFieldContainer from '../formFieldContainer';
import Button from '../widgets/button';
import LoginIcon from '@material-ui/icons/ExitToApp';
import axios from 'axios';
import { getQueryVariable } from '../../utils/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import MessagePanel from '../messagePanel';
import TextField from '../widgets/TextField';
import { bootstrapSuccess } from '../../reducers/bootstrap';
import { routerHOC } from '../../utils/routerHOC';
import { getHelpPage, showHelpPage } from '../../reducers/helpPages';

class Login extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			username: '',
			password: '',
			isLoading: false,
			loginResult: {}
		};

		this.onLogin = this.onLogin.bind(this);
	}

	onLogin() {
		const loginArgs = {
			UserName: this.state.username,
			Password: this.state.password,
			ReturnUrl: getQueryVariable('ReturnUrl')
		};

		this.setState({
			isLoading: true,
			loginResult: {}
		});

		axios.post('/api/users/login', loginArgs)
			.then(response => {
				if (response.status === 204) return null;
				if (response.status >= 200 && response.status < 300) return response.data;

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				this.setState({
					isLoading: false,
					loginResult: data
				});
				if (data.success) {
					this.props.onLogin(data);
					this.props.history.push("/");
				}
			}).catch((error) => {
				this.setState({
					isLoading: false,
					loginResult: {
						success: false,
						message: error
					}
				});
			});
	}

	render() {
		const helpPage = getHelpPage(this.props.helpPages);

	return (
<div>
	<section className="content">
		<FormContainer 
			onSubmit={(e) => {
				this.onLogin();
				e.preventDefault();
			}}
		>
			<FormFieldContainer>
				<TextField	
					name="username"
					value={this.state.username}
					onChange={(e, v) => { this.setState({ username: v }); }}
					fullWidth={true}
					label="Username"
				/>
			</FormFieldContainer>		

			<FormFieldContainer>
				<TextField	
					name="password"
					value={this.state.password}
					onChange={(e, v) => { this.setState({ password: v }); }}
					fullWidth={true}
					label="Password"
					type="password"
				/>
			</FormFieldContainer>	

			<div style={{ marginLeft: '20px', marginRight: '20px', clear: 'both', paddingTop: '10px' }}>
				<Button label="Login" type="submit" primary={true} disabled={this.state.isLoading} icon={<LoginIcon />} onClick={this.onLogin} />

				<CircularProgress style={{ display: this.state.isLoading ? undefined : 'none' }} />
				<MessagePanel isSuccess={this.state.loginResult.success} message={this.state.loginResult.message} />

				{helpPage && 
					<div style={{ textAlign: 'center', marginTop: '10px', cursor: 'pointer' }}>
						<a onClick={() => this.props.showHelpPage(helpPage)}>Need help?</a>
					</div>
				}
			</div>
		</FormContainer>
	</section>
</div>
		);
	}
}

Login.propTypes = {

};

const mapStateToProps = (state) => ({
	helpPages: state.helpPages.helpPages,
});

const mapDispatchToProps = dispatch => ({
	onLogin: (loginData) => dispatch(bootstrapSuccess(loginData.bootstrapData)),
	showHelpPage: (helpPage) => dispatch(showHelpPage(helpPage))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(routerHOC(Login));
