import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import TextField from '../widgets/TextField';
import EmployeeSelect from '../widgets/employeeSelect';

class GenerateOutgoingCorrespondenceDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			show: false,
			docTitle: '',
			from: null
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.show !== this.state.show) {
			this.setState({
				show: nextProps.show,
				docTitle: nextProps.show ? '' : undefined
			});
		}
	}

	render() {
		return (
	<Dialog
		title="Generated Correspondence Number"
		actions={
			<React.Fragment>
				<Button
					label="Hold"
					primary={true}
					disabled={!(this.state.docTitle && this.state.from)}
					onClick={() => this.props.onHold(this.props.correspondenceNumber, this.state.docTitle, this.state.from)}
				/>,
				<Button
					label="Close"
					primary={false}
					onClick={this.props.onClose}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.onClose}
	>
		<p>The generated correspondence number is: {this.props.correspondenceNumber}</p>
		<p>To hold the correspondence enter a Document Title and From Person below</p>
		<TextField
			value={this.state.docTitle}
			fullWidth={true}
			onChange={(e, v) => { this.setState({ docTitle: v }); }}
			label="Document Title"
		/>

		<EmployeeSelect 
			employees={this.props.employees}
			value={this.state.from} 
			label="From" 
			onChange={(v) => { this.setState({ from: v }); }}
		/>
	</Dialog>
		);
	}
}

GenerateOutgoingCorrespondenceDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	onHold: PropTypes.func.isRequired,
	correspondenceNumber: PropTypes.string,
	employees: PropTypes.array,
	onClose: PropTypes.func.isRequired
};

GenerateOutgoingCorrespondenceDialog.defaultProps = {
	correspondenceNumber: '',
	employees: []
};

export default GenerateOutgoingCorrespondenceDialog;
