import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from './Dialog';
import Button from './button';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import PersistedDataTable from './persistedDataTable';
import Checkbox from './Checkbox';
import Tabs from './Tabs';
import Tab from './Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { get } from '../../utils/utils';
import { showErrorNotification } from '../../reducers/notifications';
import { getContractId } from '../../selectors/contract';
import { getPeriodId } from '../../selectors/period';

class CorrespondencePicker extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			incomingCorrespondence: [],
			outgoingCorrespondence: [],
			principalCorrespondence: [],
			designCorrespondence: [],
			isIncomingLoading: false,
			isOutgoingLoading: false,
			isPrincipalLoading: false,
			isDesignLoading: false
		};
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!this.props.show && nextProps.show) {
			this.loadCorrespondence();
		}
	}
	setSelectedStateOnCorrespondence(selectedCorrespondence, correspondence, key) {
		if (!selectedCorrespondence) return correspondence;

		forEach(correspondence, (c) => {
			c._isSelected = some(selectedCorrespondence, (c2) => c2[key] === c.correspondenceId);
		});

		return correspondence;
	}

	loadCorrespondence = () => {
		const contractId = this.props.contractId;
		const periodId = this.props.periodId;

		if (this.props.types.indexOf('Incoming') > -1) {
			this.setState({
				isIncomingLoading: true
			});
			get(`/api/correspondence?contractId=${contractId}&periodId=${periodId}&type=Incoming`)
				.then((data) => {
					const incomingCorrespondence = this.setSelectedStateOnCorrespondence(this.props.selectedCorrespondence.incomingCorrespondence, data, 'incomingCorrespondenceId');
					this.setState({
						incomingCorrespondence: incomingCorrespondence,
						isIncomingLoading: false
					});
				}).catch((error) => {
					this.setState({
						incomingCorrespondence: [],
						isIncomingLoading: false
					}, () => {
						this.props.showError(error);
					});
				});
		}
		if (this.props.types.indexOf('Outgoing') > -1) {
			this.setState({
				isOutgoingLoading: true
			});
			get(`/api/correspondence?contractId=${contractId}&periodId=${periodId}&type=Outgoing`)
				.then((data) => {
					const outgoingCorrespondence = this.setSelectedStateOnCorrespondence(this.props.selectedCorrespondence.outgoingCorrespondence, data, 'outgoingCorrespondenceId');
					this.setState({
						outgoingCorrespondence: outgoingCorrespondence,
						isOutgoingLoading: false
					});
				}).catch((error) => {
					this.setState({
						outgoingCorrespondence: [],
						isOutgoingLoading: false
					}, () => {
						this.props.showError(error);
					});
				});
		}
		if (this.props.types.indexOf('Principal') > -1) {
			this.setState({
				isPrincipalLoading: true
			});
			get(`/api/correspondence?contractId=${contractId}&periodId=${periodId}&type=Principal`)
				.then((data) => {
					const principalCorrespondence = this.setSelectedStateOnCorrespondence(this.props.selectedCorrespondence.principalCorrespondence, data, 'principalCorrespondenceId');
					this.setState({
						principalCorrespondence: principalCorrespondence,
						isPrincipalLoading: false
					});
				}).catch((error) => {
					this.setState({
						principalCorrespondence: [],
						isPrincipalLoading: false
					}, () => {
						this.props.showError(error);
					});
				});
		}
		if (this.props.types.indexOf('Design') > -1) {
			this.setState({
				isDesignLoading: true
			});
			get(`/api/correspondence?contractId=${contractId}&periodId=${periodId}&type=Design`)
				.then((data) => {
					const designCorrespondence = this.setSelectedStateOnCorrespondence(this.props.selectedCorrespondence.designCorrespondence, data, 'designCorrespondenceId');
					this.setState({
						designCorrespondence: designCorrespondence,
						isDesignLoading: false
					});
				}).catch((error) => {
					this.setState({
						designCorrespondence: [],
						isDesignLoading: false
					}, () => {
						this.props.showError(error);
					});
				});
		}
	}

	pickCorrespondence = () => {
		const selectedCorrespondence = {
			incomingCorrespondence: [],
			outgoingCorrespondence: [],
			principalCorrespondence: [],
			designCorrespondence: []
		};

		forEach(this.state.incomingCorrespondence, (c) => {
			if (c._isSelected) {
				selectedCorrespondence.incomingCorrespondence.push(c);
			}
		});
		forEach(this.state.outgoingCorrespondence, (c) => {
			if (c._isSelected) {
				selectedCorrespondence.outgoingCorrespondence.push(c);
			}
		});
		forEach(this.state.principalCorrespondence, (c) => {
			if (c._isSelected) {
				selectedCorrespondence.principalCorrespondence.push(c);
			}
		});
		forEach(this.state.designCorrespondence, (c) => {
			if (c._isSelected) {
				selectedCorrespondence.designCorrespondence.push(c);
			}
		});

		this.props.onOk(selectedCorrespondence);
	}
	
	getCollectionFromType(type) {
		let correspondences;
		let key;
		switch (type) {
			case 'Incoming':
				correspondences = this.state.incomingCorrespondence;
				key = 'incomingCorrespondenceId';
				break;
			case 'Outgoing':
				correspondences = this.state.outgoingCorrespondence;
				key = 'outgoingCorrespondenceId';
				break;
			case 'Principal':
				correspondences = this.state.principalCorrespondence;
				key = 'principalCorrespondenceId';
				break;
			case 'Design':
				correspondences = this.state.designCorrespondence;
				key = 'designCorrespondenceId';
				break;
			default:
		}
		return { correspondences: correspondences, key: key };
	}
	selectCorrespondence = (type, correspondence, selected) => {
		const { correspondences } = this.getCollectionFromType(type);
		forEach(correspondences, (c) => {
			if (c.correspondenceId === correspondence.correspondenceId) {
				c._isSelected = selected;
			}
		});
		this.setState({
			incomingCorrespondence: this.state.incomingCorrespondence,
			outgoingCorrespondence: this.state.outgoingCorrespondence,
			principalCorrespondence: this.state.principalCorrespondence,
			designCorrespondence: this.state.designCorrespondence
		});
	}
	isAllSelected = (type) => {
		const { correspondences } = this.getCollectionFromType(type);

		for (let i = 0, ii = correspondences.length; i < ii; i++) {
			const c = correspondences[i];
			if (c._isSelected !== true) return false;
		}
		return true;
	}
	toggleAll = (type) => {
		const { correspondences } = this.getCollectionFromType(type);
		const nextValue = !this.isAllSelected(type);
		forEach(correspondences, c => {
			c._isSelected = nextValue;
		});
		this.setState({
			incomingCorrespondence: this.state.incomingCorrespondence,
			outgoingCorrespondence: this.state.outgoingCorrespondence,
			principalCorrespondence: this.state.principalCorrespondence,
			designCorrespondence: this.state.designCorrespondence
		});
	}

	render() {
		const incomingCorrespondence = this.state.incomingCorrespondence;
		const outgoingCorrespondence = this.state.outgoingCorrespondence;
		const principalCorrespondence = this.state.principalCorrespondence;
		const designCorrespondence = this.state.designCorrespondence;
		const isLoading = this.state.isIncomingLoading || this.state.isOutgoingLoading || this.state.isPrincipalLoading || this.state.isDesignLoading;
		
		const incomingColumns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected('Incoming')}
						onCheck={() => { this.toggleAll('Incoming'); } }
					/>,
				value: (args) => args.data._isSelected || false,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectCorrespondence('Incoming', args.data, v); } }
					/> 
			},
			{ name: 'correspondenceNumber', label: 'Incoming Number' },
			{ name: 'correspondenceTitle', label: 'Document Title' }
		];
		const outgoingColumns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected('Outgoing')}
						onCheck={() => { this.toggleAll('Outgoing'); } }
					/>,
				value: (args) => args.data._isSelected || false,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectCorrespondence('Outgoing', args.data, v); } }
					/> 
			},
			{ name: 'correspondenceNumber', label: 'Outgoing Number' },
			{ name: 'correspondenceTitle', label: 'Document Title' }
		];
		const principalColumns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected('Principal')}
						onCheck={() => { this.toggleAll('Principal'); } }
					/>,
				value: (args) => args.data._isSelected || false,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectCorrespondence('Principal', args.data, v); } }
					/> 
			},
			{ name: 'correspondenceNumber', label: 'Principal Number' },
			{ name: 'correspondenceDescription', label: 'Description' }
		];
		const designColumns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected('Design')}
						onCheck={() => { this.toggleAll('Design'); } }
					/>,
				value: (args) => args.data._isSelected || false,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectCorrespondence('Design', args.data, v); } }
					/> 
			},
			{ name: 'correspondenceNumber', label: 'Design Number' },
			{ name: 'correspondenceDescription', label: 'Description' }
		];

		return (
	<Dialog
		title={
			<h3>Correspondence
				{isLoading &&
					<CircularProgress size={30} style={{ marginLeft: '1em' }} />
				}
			</h3>
		}
		actions={
			<React.Fragment>
				<Button
					label="Ok"
					primary={true}
					onClick={() => this.pickCorrespondence()}
				/>,
				<Button
					label="Cancel"
					primary={false}
					onClick={this.props.cancel}
				/>
			</React.Fragment>
		}
		ckdropClick={false}
		open={this.props.show}
		onClose={this.props.cancel}
		scroll='paper'
		fullWidth={true}
		maxWidth='lg'
	>

		{!isLoading && 
			<div>
			<p>Select the Correspodence Below</p>
			
			<Tabs renderOnLoad>
				{this.props.types.indexOf('Incoming') > -1 &&
					<Tab label="Incoming" value="Incoming">
						<PersistedDataTable 
							id="pickerIncoming"
							data={incomingCorrespondence || []} 
							columns={incomingColumns}
						/>
					</Tab>
				}
				{this.props.types.indexOf('Outgoing') > -1 &&
					<Tab label="Outgoing" value="Outgoing">
						<PersistedDataTable 
							id="pickerOutgoing"
							data={outgoingCorrespondence || []} 
							columns={outgoingColumns}
						/>
					</Tab>
				}
				{this.props.types.indexOf('Principal') > -1 &&
					<Tab label="Principal" value="Principal">
						<PersistedDataTable 
							id="pickerPrincipal"
							data={principalCorrespondence || []} 
							columns={principalColumns}
						/>
					</Tab>
				}
				{this.props.types.indexOf('Design') > -1 &&
					<Tab label="Design" value="Design">
						<PersistedDataTable 
							id="pickerIncoming"
							data={designCorrespondence || []} 
							columns={designColumns}
						/>
					</Tab>
				}
				</Tabs>
			</div>
		}
	</Dialog>
		);
	}
}

CorrespondencePicker.propTypes = {
	contractId: PropTypes.number.isRequired,
	periodId: PropTypes.number.isRequired,
	show: PropTypes.bool,
	onOk: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	types: PropTypes.array.isRequired,
	selectedCorrespondence: PropTypes.object.isRequired,
	showError: PropTypes.func.isRequired
};

CorrespondencePicker.defaultProps = {
	show: false
};

const mapStateToProps = (state) => ({
	contractId: getContractId(state),
	periodId: getPeriodId(state)
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	showError: (error) => {
		dispatch(showErrorNotification(error));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CorrespondencePicker);
