import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';
import map from 'lodash/map';
import filter from 'lodash/filter';
import some from 'lodash/some';

class LotsGrid extends React.Component {
	// shouldComponentUpdate(nextProps) {
	// 	return this.props.lots.length !== nextProps.lots.length;
	// }

	render() {
		const columns = [
			{ name: 'lotNo', label: 'Lot No' },
			{ name: 'lotDescription', label: 'Lot Description', headerStyle: { minWidth: '300px' } },
			{ 
				name: 'status', 
				label: 'Lot Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					switch (args.data.status) {
						case 'Guaranteed':
							statusBackColour = '#f0ad4e';
							statusForeColour = '#222';
							break;
						case 'Guaranteed>1Month':
							statusBackColour = '#FFC7CE';
							statusForeColour = '#9C0006';
							break;
						case 'Closed':
							statusBackColour = '#C6EFCE';
							statusForeColour = '#2C6153';
							break;
						default:
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Guaranteed', 'Guaranteed>1Month', 'Closed']
			},
			{ name: 'numItems', label: 'Items', dataType: 'number' },
			{ 
				name: 'totalAmount', 
				label: 'Lot Amount', 
				dataType: 'number', 
				formatString: 'currency' 
			},
			{ name: 'periodClaimedName', label: 'Period Claimed' },
			{
				name: 'tags',
				label: 'Tags',
				displayValue: (args) => 
					map(filter(this.props.tags, (t) => some(args.data.tags, (t2) => t2.tagId === t.tagId)), (t) => t.name).join(','),
				filterOperators: ['multi-select'],
				filterOptions: this.props.tags,
				filterOptionsKey: 'tagId',
				filterOptionsText: 'name'
			},
			{ 
				name: 'ncrs', 
				label: 'NCRs',
				value: (args) => {
					if (!args.data.ncRs || args.data.ncRs.length === 0) return '';
					return map(args.data.ncRs, (ncr) => ncr.contractorNcrNo).join(', ');
				}
			},
			{
				name: 'electronicLotData',
				label: 'Electronic Lot Data',
				value: args => args.data.electronicLotData === 'NoRecords' ? 'No Records' : args.data.electronicLotData
			},
			{
				name: 'dateLotCreated',
				label: 'Date notified by Contractor',
				dataType: 'date',
				hideByDefault: true
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } },
			{
				name: 'scheduleOfRatesItem',
				label: 'Lot Items',
				excludeFromGrid: true,
				excludeFromSearch: true,
				filterOperators: ['includes'],
				filterData: this.props.scheduleItems,
				filterKey: 'scheduleOfRatesItemId',
				filterValue: 'label',
				filterFunction: (lot, filterItem) => some(lot.items, i => i.scheduleOfRatesItemId === filterItem.scheduleOfRatesItemId)
			}
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.handleEditLot(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.handleDeleteLot(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {	
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.handleViewLot(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="lots"
				name="Lots"
				data={this.props.lots || []} 
				columns={columns}
			/>
		);
	}
}

LotsGrid.propTypes = {
	lots: PropTypes.array,
	scheduleItems: PropTypes.array,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	tags: PropTypes.array,
	handleViewLot: PropTypes.func.isRequired,
	handleEditLot: PropTypes.func.isRequired,
	handleDeleteLot: PropTypes.func.isRequired
};

LotsGrid.defaultProps = {
	lots: [],
	scheduleItems: [],
	tags: []
};

export default LotsGrid;
