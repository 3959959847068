import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverDateField from '../components/handoverDateField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';
import filter from 'lodash/filter';

class PrincipalCorrespondenceHandoverContent extends React.Component {
	render() {
		const correspondence = this.props.report;
		
		const filesColumns = [
			{ name: 'name', label: 'Name' }
		];

		const linkedCorrespondence = filter(correspondence.linkedCorrespondence || [], c => !c.isParent);
		
		const linkedCorrespondenceColumns = [
			{ name: 'correspondenceType', label: 'Type' },
			{ name: 'correspondenceNumber', label: 'Number' },
			{ name: 'correspondenceTitle', label: 'Title' }
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Principal Number" value={correspondence.rfiNumber} />
				<HandoverField label="In/Out" value={correspondence.inOrOut} />
				<HandoverDateField label="Response Required By" value={correspondence.responseRequiredBy} />
				<HandoverField label="Description" value={correspondence.description} />
				<HandoverField label="Status" value={correspondence.status} />
				<HandoverField label="From" value={correspondence.fromName} />
				<HandoverField label="To" value={correspondence.toName} />
				<HandoverDateField label="Date Sent" value={correspondence.dateSent} />
				<HandoverDateField label="Date Closed" value={correspondence.dateClosed} />
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Linked Correspondence</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={linkedCorrespondence || []}
					columns={linkedCorrespondenceColumns}
				/>
			</div>
			
			<div className="handover-items">
				<HandoverSectionHeader>Attachments</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={correspondence.files || []}
					columns={filesColumns}
				/>
			</div>
		</div>
    );
  }
}

PrincipalCorrespondenceHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default PrincipalCorrespondenceHandoverContent;
