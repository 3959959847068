import React from 'react';
import UserDialog from './UserDialog';
import { 
	IconButton,
	Table, 
	TableHead,
	TableBody, 
	TableRow, 
	TableCell 
} from '@material-ui/core';
import map from 'lodash/map';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import cloneDeep from 'lodash/cloneDeep';

const UserField = props => {
	const [user, setUser] = React.useState(null);
	const [isNewUser, setIsNewUser] = React.useState(true);
	const [showUserDialog, setShowUserDialog] = React.useState(false);
	const value = cloneDeep(props.value);

	return (
	<React.Fragment>
		<UserDialog
			show={showUserDialog} 
			onCancel={() => {
				setShowUserDialog(false);
			}}
			user={user}
			isNew={isNewUser}
			onOk={(newUser) => {
				const users = value || [];
				users.push(
					newUser
				);
				props.onChange(users);
				setShowUserDialog(false);
			}}
		/>
		
		<Table>
			<TableHead>
				<TableRow>
					<TableCell style={{ width: '110px', padding: 0 }}>
						<IconButton 
							onClick={() => { 
								setUser({
								});
								setIsNewUser(true);
								setShowUserDialog(true); 
							}}
							style={{ marginLeft: 5 }}
						>
							<AddIcon />
						</IconButton>
					</TableCell>
					<TableCell>Username</TableCell>
					<TableCell>Email</TableCell>
					<TableCell>Position</TableCell>
					<TableCell>Role</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{map(value, (u, i) =>
					<TableRow key={`user-${i}`}>
						<TableCell style={{ padding: '0px' }}>
							<IconButton 
								onClick={() => { 
									setIsNewUser(false);
									setUser(u); 
									setShowUserDialog(true);
								}} 
								style={{ marginLeft: 5 }}
							>
								<EditIcon />
							</IconButton>
							<IconButton 
								onClick={() => { 
									const users = value;
									users.splice(i, 1);
									props.onChange(users);
								}} 
								style={{ marginLeft: 5 }}
							>
								<DeleteIcon />
							</IconButton>
						</TableCell>
						<TableCell>{u.userName}</TableCell>
						<TableCell>{u.email}</TableCell>
						<TableCell>{u.position}</TableCell>
						<TableCell>{u.userRole}</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	</React.Fragment>
	);
};

export default UserField;
