import React from 'react';
import PropTypes from 'prop-types';
import { 
	CircularProgress,
    IconButton
} from '@material-ui/core';
import {
    Delete as DeleteIcon
} from '@material-ui/icons';

const titleHeaderStyles = {
    color: '#03a9f4',
    position: 'absolute',
    top: '10px',
    'paddingLeft': '10px',
    'width': '100%',
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'paddingRight': '10px',
    'fontSize': '16px',
    'fontWeight': '300'
};

const WidgetLoading = () =>
	<div style={{
		height: "100%",
		marginTop: "-10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	}}>
		<CircularProgress />
	</div>;

const Dashboardwidget = props => {
	const [loading, setLoading] = React.useState(false);
	const [title, setTitle] = React.useState(props.title);
	const [subHeader, setSubHeader] = React.useState("");
	const WrappedWidget = props.widget;

	const headerStyles = {
		...titleHeaderStyles,
		...props.headerStyles
	};

    const isLoading = props.loading || loading;

    return (
        <React.Fragment>
            <div style={headerStyles}>
                {title}
                {subHeader &&
                    <h4 style={{
                            color: '#333',
                            display: 'inline',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            fontStyle: 'italic',
                            marginLeft: '10px' 
                        }}
                        
                    >
                        {subHeader}
                    </h4>
                }
            </div>
            {isLoading && <WidgetLoading />}
            <div style={{ display: isLoading ? 'none' : 'block', height: '100%' }}>
                <WrappedWidget 
                    loading={isLoading} 
                    setLoading={setLoading} 
                    setTitle={setTitle}
                    setSubHeader={setSubHeader}
                    {...props} 
                />
            </div>
            {props.onDelete && 
                <IconButton 
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px'
                    }}
                    onClick={props.onDelete}
                >
                    <DeleteIcon />
                </IconButton>
            }
        </React.Fragment>
    );
};

Dashboardwidget.propTypes = {
	onDelete: PropTypes.func,
    changeContractContext: PropTypes.func,
    loading: PropTypes.bool
};

Dashboardwidget.defaultProps = {
	onDelete: null,
    loading: false
};

export default Dashboardwidget;
