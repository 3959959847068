import React from 'react';
import PropTypes from 'prop-types';
import ActionSearch from '@material-ui/icons/Search';
import TextField from './TextField';
import Paper from '@material-ui/core/Paper';

class SearchField extends React.Component {
	state = {
		search: ''
	}

	render = () => {
		const paperStyle = {
			...this.props.style
		};
		return (
			<Paper style={paperStyle}>
			<div className="search-field-container" style={{ padding: '0 10px' }}>
				<ActionSearch style={{ marginTop: '10px', marginRight: '10px', color: this.state.search ? '#5bc0de' : 'inherit' }} />
				<TextField
					autoComplete="off"
					variant="standard"
					hintText="Search"
					InputProps={{ disableUnderline: true }}
					value={this.state.search}
					style={{ width: 'calc(100% - 68px)', padding: '7px 0' }}
					inputStyle={{ marginTop: 0 }}
					onChange={(e, v) => {
						this.setState({ search: v });
						if (this.props.onSearch) this.props.onSearch(v);
					}}
				/>
			</div>
			</Paper>
		);
	}
}

SearchField.propTypes = {
	style: PropTypes.object,
	onSearch: PropTypes.func
};

SearchField.defaultProps = {
	style: undefined,
	onSearch: () => {}
};

export default SearchField;
