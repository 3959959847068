import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import ContentContainer from '../contentContainer';
import map from 'lodash/map';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {
	Fab as FloatingActionButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper
 } from '@material-ui/core';
import SelectField from '../widgets/selectField';
import IconButton from '../widgets/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningBanner from '../widgets/warningBanner';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';
import { errorColour } from '../../variables';
import Button from '../widgets/button';
import PromptModal from '../modals/promptModal';
import SubContentContainer from '../subContentContainer';

class RegisterDefinitionForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			registerDefinition: props.registerDefinition,
			promptForAllowedValue: false,
			allowedValuesIndex: null
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			registerDefinition: nextProps.registerDefinition
		});
	}
	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}
	
		this.props.updateFieldOrder(result.source.index, result.destination.index);
	}

	render() {
		const registerDefinition = this.state.registerDefinition;

return (
<div>
	<PromptModal 
		show={this.state.promptForAllowedValue}
		onCancel={() => this.setState({ ...this.state, promptForAllowedValue: false })}
		onOk={v => {
			this.setState({ ...this.state, promptForAllowedValue: false });
			const field = registerDefinition.fields[this.state.allowedValuesIndex];
			if (field) {
				let allowedValues = [];
				if (field.allowedValues) allowedValues = JSON.parse(field.allowedValues) || [];
				allowedValues.push(v);
				this.props.updateDefinitionField(this.state.allowedValuesIndex, 'allowedValues', JSON.stringify(allowedValues));
			}
		}}
		title="Add Allowed Value"
		message="Enter a value to add to the list of allowed Values"
	/>
	{registerDefinition.recordCount > 0 &&
		<WarningBanner text="Records exist for this register so changes to the definition may be result in data loss." />
	}
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={registerDefinition.name}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('name', v); }}
				label="Register Name"
				errorText={getFieldErrorText(this.props.saveResult, 'Name')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={registerDefinition.description}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('description', v); }}
				label="Description"
				errorText={getFieldErrorText(this.props.saveResult, 'Description')}
			/>
		</FormFieldContainer>

		
		<FormFieldContainer>
			<SelectField 
				value={registerDefinition.menuLocation} 
				fullWidth={true}
				label="Menu Location Type" 
				onChange={(v) => { this.props.updateField('menuLocation', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'MenuLocation')}
				items={[
					{ value: 'Contractual', label: 'Contractual' },
					{ value: 'Quality Registers', label: 'Quality Registers' },
					{ value: 'Correspondence', label: 'Correspondence' }
				]}
			>
			</SelectField>
		</FormFieldContainer>
	</ContentContainer>

{/* 
	{this.props.canEdit &&
		<FloatingActionButton onClick={this.props.addDefinitionField} size="small" style={{ float: 'right' }}  className="clearfix">
			<ContentAdd />
		</FloatingActionButton>
	} */}
	<h2>Fields</h2>
	<p>Collapsing fields before dragging to reorder is recommended.</p>
	
	<DragDropContext onDragEnd={this.onDragEnd} onBeforeDragStart={this.props.collapseAllFields}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
				ref={provided.innerRef}
				{...provided.droppableProps}
            >
				{map(registerDefinition.fields, (f, index) => {
					let allowedValues = [];
					if (f.allowedValues) allowedValues = JSON.parse(f.allowedValues) || [];

					const fieldContent = ( 
						<div className="row" style={{ display: 'flex', flexDirection: 'column' }}>
							<FormFieldContainer>
								<TextField 
									value={f.name} 
									fullWidth={true}
									label="Field Name" 
									onChange={(e, v) => { this.props.updateDefinitionField(index, 'name', v); }}
									errorText={getFieldErrorText(this.props.saveResult, 'Field.Name', f)}
								>
								</TextField>
							</FormFieldContainer>
		
							<FormFieldContainer>
								<TextField 
									value={f.label} 
									fullWidth={true}
									label="Field Label" 
									onChange={(e, v) => { this.props.updateDefinitionField(index, 'label', v); }}
									errorText={getFieldErrorText(this.props.saveResult, 'Field.Label', f)}
								>
								</TextField>
							</FormFieldContainer>

							<FormFieldContainer>
								<SelectField 
									value={f.fieldType} 
									fullWidth={true}
									label="Field Type" 
									onChange={(v) => { this.props.updateDefinitionField(index, 'fieldType', v); }}
									errorText={getFieldErrorText(this.props.saveResult, 'Field.FieldType', f)}
									items={[
										{ value: 'Text', label: 'Text' },
										{ value: 'Date', label: 'Date' },
										{ value: 'File', label: 'File' },
										{ value: 'Select', label: 'Select' },
										{ value: 'Correspondence', label: 'Correspondence' },
										{ value: 'Tags', label: 'Tags' }
									]}
								>
								</SelectField>
							</FormFieldContainer>

							{f.fieldType === 'Select' && 
								<SubContentContainer
									header="Allowed Values"
									style={{ margin: '5px' }}
								>
									<List dense={true}>
										{map(allowedValues, (v, vi) => 
											<ListItem key={`allowed-value-${vi}`}>
												<ListItemText
													primary={v}
												/>
												<ListItemSecondaryAction>
													<IconButton 
														onClick={() => {
															allowedValues.splice(vi, 1);
															this.props.updateDefinitionField(index, 'allowedValues', JSON.stringify(allowedValues));
														}}
														edge="end" 
														aria-label="delete"
													>
														<DeleteIcon />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										)}
									</List>
									<FloatingActionButton 
										onClick={() => this.setState({ ...this.state, promptForAllowedValue: true, allowedValuesIndex: index })} 
										size="small" 
										style={{ float: 'right', marginTop: '20px' }}
										className="clearfix"
									>
										<ExpandIcon />
									</FloatingActionButton>
								</SubContentContainer>
							}


							<Button 
								label="Remove Field"
								icon={<DeleteIcon />}
								style={{ color: errorColour, alignSelf: 'flex-end' }}
								onClick={() => this.props.deleteDefinitionField(index)} 
							/>
						</div>
					);
		
					const fieldId = `${f.registerFieldId || f._clientKey}`;
					return (
					<Draggable key={fieldId} draggableId={fieldId} index={index}>
					{(dragProvided) => (
						<div>
							<div
								ref={dragProvided.innerRef}
								{...dragProvided.draggableProps}
							>
								<Paper style={{ padding: '10px', marginBottom: '10px' }}>
									<div style={{ position: 'relative' }}>
										<span {...dragProvided.dragHandleProps}>
											<DragHandleIcon  />
										</span>
										<span style={{ fontWeight: '400', fontSize: '14px' }}>{f.name}</span>

										{!f._isExpanded &&
											<IconButton 
												onClick={ () => { this.props.expandField(index); } } 
												style={{ 
													height: '30px',
													width: '30px',
													padding: 0,
													position: 'absolute',
													top: 0,
													right: 0
												}}
											>
												<ExpandIcon />
											</IconButton>
										}
										{f._isExpanded &&
											<IconButton 
												onClick={ () => { this.props.collapseField(index); } } 
												style={{ 
													height: '30px',
													width: '30px',
													padding: 0,
													position: 'absolute',
													top: 0,
													right: 0
												}}
											>
												<CollapseIcon />
											</IconButton>
										}
									</div>
									{f._isExpanded && 
										<div>
											{fieldContent}
										</div>
									}
								</Paper>
							</div>
						</div>
					)}
					</Draggable>
					);
				})}
				{provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
</div>
		);
	}
}

RegisterDefinitionForm.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	updateField: PropTypes.func.isRequired,
	addDefinitionField: PropTypes.func.isRequired,
	deleteDefinitionField: PropTypes.func.isRequired,
	updateDefinitionField: PropTypes.func.isRequired,
	updateFieldOrder: PropTypes.func.isRequired,
	expandField: PropTypes.func.isRequired,
	collapseField: PropTypes.func.isRequired,
	collapseAllFields: PropTypes.func.isRequired,
	registerDefinition: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default RegisterDefinitionForm;
