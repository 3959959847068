import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import {  withStyles } from '@material-ui/core/styles';
import ContentContainer from '../contentContainer';
import { 
	Avatar,
	CircularProgress,
	Grid
} from '@material-ui/core';
import { secondaryColour, secondaryTextColour } from '../../variables';
import { getInitials } from '../../utils/utils';
import round from 'lodash/round';

const styles = (theme) => ({
	avatar: {
		color: secondaryTextColour,
		backgroundColor: secondaryColour,
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
	imageAvatar: {
		width: theme.spacing(10),
		height: theme.spacing(10),
	}
});


const InfiniteProgress = withStyles((theme) => ({
	root: {
	  height: 20,
	  borderRadius: 15,
	},
	colorPrimary: {
	  backgroundColor: theme.palette.grey[400],
	},
	bar: {
	  borderRadius: 5,
	  backgroundColor: '#1a90ff',
	},
  }))(LinearProgress);

const BorderLinearProgress = withStyles((theme) => ({
	root: {
	  height: 20,
	  borderRadius: 15,
	},
	colorPrimary: {
	  backgroundColor: theme.palette.grey[700],
	},
	bar: {
	  borderRadius: 5,
	  backgroundColor: '#1a90ff',
	},
  }))(LinearProgress);

  const ExceeededProgress = withStyles((theme) => ({
	root: {
	  height: 20,
	  borderRadius: 15,
	},
	colorPrimary: {
	  backgroundColor: theme.palette.grey[700],
	},
	bar: {
	  borderRadius: 5,
	  backgroundColor: '#d9534f',
	},
  }))(LinearProgress);

const Account = ({ classes, loading, account, user }) => {
	const avatarSrc = user && user.avatar ? `/images/avatars/${user.avatar}.png` : '';
	const initials = user ? getInitials(user.fullName) : '';

	let maxContractsText = '';
	let maxUsersText = '';
	let maxDataText = '';
	let maxContractsPercent = 0;
	let maxUsersPercent = 0;
	let maxDataPercent = 0;

	if (account) {
		if (account.maxContracts <= 0) {
			maxContractsText = 'Unlimited Contracts';
		} else {
			maxContractsText = `${account.numContracts} / ${account.maxContracts}`;
		}
		maxContractsPercent = account.maxContracts <= 0 || account.numContracts <= 0 ? 0 : account.numContracts / account.maxContracts * 100;

		if (account.maxUsers <= 0) {
			maxUsersText = 'Unlimited Users';
		} else {
			maxUsersText = `${account.numUsers} / ${account.maxUsers}`;
		}
		maxUsersPercent = account.maxUsers <= 0 || account.numUsers <= 0 ? 0 : account.numUsers / account.maxUsers * 100;

		const currenData = account.currentData > 0 ? round(account.currentData * 10**-9, 2) : 0;
		if (account.maxData <= 0) {
			maxDataText = 'Unlimited Data';
		} else {
			maxDataText = `${currenData} GB / ${account.maxData} GB`;
		}
		maxDataPercent = account.maxData <= 0 || currenData <= 0 ? 0 : currenData / account.maxData * 100;
		if (maxDataPercent > 100) maxDataPercent = 100;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={4}>
				<ContentContainer>
					<div className="user-panel" style={{ textAlign: 'center' }}>
						<div className="image" style={{ display: 'inline-block', margin: '20px 0' }}>
							{avatarSrc ? 
								<img src={avatarSrc} className={`${classes.imageAvatar} img-circle`} alt="User Avatar" />
								:
								<Avatar className={classes.avatar}>{initials}</Avatar>
							}
						</div>
						<div className="info">
							<h2>{user ? user.fullName : ''}</h2>
							<h3>{user ? user.position : ''}</h3>
						</div>
					</div>
				</ContentContainer>
			</Grid>

			<Grid item xs={12} md={8}>
				<ContentContainer>
					{loading && 
						<>
							<CircularProgress />
						</>
					}
					{!loading && 
						<>
							<h2 style={{ marginBottom: 0 }}>Contracts</h2>
							<i>{maxContractsText}</i>
							{(() => {
								if (!account || account.maxContracts <= 0) {
									return <InfiniteProgress variant="determinate" value={0} />;
								} else {
									if (maxContractsPercent >= 100) {
										return <ExceeededProgress variant="determinate" value={100} />;
									} else {
										return <BorderLinearProgress variant="determinate" value={maxContractsPercent} />;
									}
								}
							})()}
							<h2 style={{ marginBottom: 0 }}>Users</h2>
							<i>{maxUsersText}</i>
							{(() => {
								if (!account || account.maxUsers <= 0) {
									return <InfiniteProgress variant="determinate" value={0} />;
								} else {
									if (maxUsersPercent >= 100) {
										return <ExceeededProgress variant="determinate" value={100} />;
									} else {
										return <BorderLinearProgress variant="determinate" value={maxUsersPercent} />;
									}
								}
							})()}
							<h2 style={{ marginBottom: 0 }}>Data</h2>
							<i>{maxDataText}</i>
							{(() => {
								if (!account || account.maxData <= 0) {
									return <InfiniteProgress variant="determinate" value={0} />;
								} else {
									if (maxDataPercent >= 100) {
										return <ExceeededProgress variant="determinate" value={100} />;
									} else {
										return <BorderLinearProgress variant="determinate" value={maxDataPercent} />;
									}
								}
							})()}
						</>
					}
				</ContentContainer>
			</Grid>
		</Grid>

	);
};


Account.propTypes = {
	loading: PropTypes.bool.isRequired
};

export default withStyles(styles)(Account);
