import find from 'lodash/find';
import { fetch, addTask } from 'domain-task';
import { addOrUpdate } from '../utils/utils';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import map from 'lodash/map';
import last from 'lodash/last';
import { bootstrapSuccess } from './bootstrap';
import { get } from '../utils/ajax';
import history from '../history';
import { showErrorNotification, showWarningNotification } from './notifications';

const initialState = {
	isLoggedIn: false,
	user: null,
	contracts: [],
	contract: null,
	period: null,
	permissions: []
};

const CHANGE_CONTRACT_CONTEXT = 'CHANGE_CONTRACT_CONTEXT';
const CHANGE_PERIOD_CONTEXT = 'CHANGE_PERIOD_CONTEXT';
const PERIOD_CLOSED = 'PERIOD_CLOSED';
const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
const UPDATE_CONTEXT_CONTRACTS = 'UPDATE_CONTEXT_CONTRACTS';
const RECEIVE_CONTEXT_CONTRACTS = 'RECEIVE_CONTEXT_CONTRACTS';
const REMOVE_CONTRACT_FROM_CONTEXT = 'REMOVE_CONTRACT_FROM_CONTEXT';
const CHANGE_CONTRACT_CONTEXT_RESPONSE = 'CHANGE_CONTRACT_CONTEXT_RESPONSE';
const RECEIVE_SAVE_PERIOD_RESPONSE = 'RECEIVE_SAVE_PERIOD_RESPONSE';

export const periodClosed = (data) => ({ type: PERIOD_CLOSED, data });
export const removeContractFromContext = (data) => ({ type: REMOVE_CONTRACT_FROM_CONTEXT, data });
export const receiveContextContracts = (data) => ({ type: RECEIVE_CONTEXT_CONTRACTS, payload: { data } });

export const updateContract = (data, updateContext) => 
	(dispatch, getState) => {
		dispatch({ type: UPDATE_CONTRACT, data });

		if (updateContext) {
			const contract = data;
			const periodId = (contract.periods.length > 0) ? contract.periods[contract.periods.length - 1].periodId : 0;
			const fetchTask =  fetch('/api/users/set-context', {
					method: 'post', 
					credentials: 'same-origin',
					headers: {
						'cache-control': 'no-store',
						'pragma': 'no-cache',
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						contractId: data.contractId,
						periodId: periodId
					})
				})
				.then(response => {
					if (response.status >= 200 && response.status < 300) return response.json();

					// Client already assumed request worked. TODO: handle failure

					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				}).then((data) => {
					dispatch({ type: CHANGE_CONTRACT_CONTEXT_RESPONSE, data });
				}).catch((error) => {
					console.log('request failed', error);
				});
			addTask(fetchTask);
		}
	};

export const logIn = (userName, password, keepLoggedIn, onSuccess, onError) =>
	createPostAction({
		url: "/api/users/login",
		data: { userName, password, returnUrl: '', rememberMe: keepLoggedIn },
		success: (result, dispatch) => {
			if (result.success) {
				const bootstrapData = result.bootstrapData;
				dispatch(bootstrapSuccess(bootstrapData));

				get({
					url: "/api/antiforgery",
					onSuccess: () => {
						if (onSuccess) onSuccess(result);
					}
				});
			} else {
				if (result.resetPassword) {
					history.push(result.redirectUrl);
					dispatch(showWarningNotification(result.message));
				} else if (onSuccess) {
					// Should onSuccess be called despite failure?
					onSuccess(result);
				} else {
					if (onError) onError(result);
					dispatch(showErrorNotification(result.message));
				}
			}
		},
		onError: error => {
			console.error(error.message)
		}
	});

export const updateContext = () => (
	createFetchAction({
		objectName: 'Contracts',
		url: '/api/contracts',
		startAction: UPDATE_CONTEXT_CONTRACTS,
		success: (data) => 
			receiveContextContracts(map(data, (c) => ({
				...c,
				dateCommenced: new Date(c.dateCommenced),
				practicalCompletionDate: c.practicalCompletionDate ? new Date(c.practicalCompletionDate) : null,
				liquidatedDamagesAppliesFrom: c.liquidatedDamagesAppliesFrom ? new Date(c.liquidatedDamagesAppliesFrom) : null,
				periods: map(c.periods, (p) => ({
					...p,
					startDate: p.startDate ? new Date(p.startDate) : null,
					endDate: p.endDate ? new Date(p.endDate) : null
				}))
			})))
	})
);

export const changeContractContext = (contractId) => 
	(dispatch, getState) => {
		dispatch({ type: CHANGE_CONTRACT_CONTEXT, contractId });

		// Persist to DB
		const state = getState();
		const contract = find(state.context.contracts, (c) => c.contractId === contractId);
		const periodId = (contract.periods.length > 0) ? contract.periods[contract.periods.length - 1].periodId : 0;
		const fetchTask =  fetch('/api/users/set-context', {
				method: 'post', 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache',
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					contractId: contractId,
					periodId: periodId
				})
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				// Client already assumed request worked. TODO: handle failure

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			}).then((data) => {
				dispatch({ type: CHANGE_CONTRACT_CONTEXT_RESPONSE, data });
			}).catch((error) => {
				console.log('request failed', error);
			});
		addTask(fetchTask);
	};

export const changePeriodContext = (periodId) => 
	(dispatch, getState) => {
		dispatch({ type: CHANGE_PERIOD_CONTEXT, periodId });

		// Persist to DB
		const state = getState();
		const contractId = state.context.contract.contractId;
		const fetchTask =  fetch('/api/users/set-context', {
				method: 'post', 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache',
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					contractId: contractId,
					periodId: periodId
				})
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response;

				// Client already assumed requert worked. TODO: handle failure

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			}).catch((error) => {
				console.log('request failed', error);
			});
		addTask(fetchTask);
	};

export default (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_CONTRACT_CONTEXT: {
			const contract = find(state.contracts, (c) => c.contractId === action.contractId);
			return {
				...state,
				contract: contract,
				periods: contract.periods,
				period: contract.periods[contract.periods.length - 1]
			};
		}
		case CHANGE_PERIOD_CONTEXT: {
			const period = find(state.contract.periods, (p) => p.periodId === action.periodId);
			return {
				...state,
				period: period
			};
		}
		case CHANGE_CONTRACT_CONTEXT_RESPONSE: {
			return {
				...state,
				registers: action.data.registers
			};
		}
		case PERIOD_CLOSED:
			return {
				...state,
				period: find(action.data.updatedPeriods, (p) => p.status === 'Open'),
				contract: { 
					...state.contract,
					periods: action.data.updatedPeriods
				}
			};
		case UPDATE_CONTRACT: {
			let contracts = state.contracts;
			contracts = addOrUpdate(contracts, action.data, (c) => c.contractId === action.data.contractId);
			let contract = action.data;
			return {
				...state,
				contract: contract,
				contracts: contracts,
				periods: contract.periods,
				period: contract.periods[contract.periods.length - 1]
			};
		}
		case REMOVE_CONTRACT_FROM_CONTEXT: {
			const contract = state.contract;
			return {
				...state,
				contract: contract && contract.contractId === action.data ? null : contract,
				contracts: state.contracts.filter(c => c.contractId !== action.data) 
			};
		}
		case RECEIVE_SAVE_PERIOD_RESPONSE:
			if (action.data.success) {
				const period = action.data.object;
				return {
					...state,
					contract: state.contract.contractId === period.contractId ? 
						{
							...state.contract,
							periods: addOrUpdate(state.contract.periods, period, { periodId: period.periodId }), 
						} : state.contract,
					period: state.period && state.period.periodId === period.periodId ? period : state.period,
					periods: addOrUpdate(state.periods, period, { periodId: period.periodId })
				};
			} else {
				return state;
			}
		default:
			return state;
	}
};
