import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveRole,
	clearRole, 
	requestRole, 
	createNewRole 
} from '../../reducers/roles';
import RoleForm from './roleForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { globals } from '../../globals';
import remove from 'lodash/remove';
import some from 'lodash/some';
import { canEdit } from '../../selectors/canEdit';
import forEach from 'lodash/forEach';
import  { addOrUpdate } from '../../utils/utils';
import PageLayout from '../pageLayout';
import { produce } from 'immer';

class RoleMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			role: props.role,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.selectPermission = this.selectPermission.bind(this);
	}
	static fetchData(dispatch, props) {
		if (props.match.params.roleId) {
			dispatch(requestRole(props.match.params.roleId));
		} else {
			dispatch(createNewRole());
		}
	}
	static clearData(dispatch) {
		dispatch(clearRole());
	}
	componentDidMount() {
		RoleMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			role: nextProps.role,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const role = this.state.role;
		role[field] = newValue;
        this.setState({
			role,
			hasUnsavedChanges: true
        });
	}
	selectPermission(permission, selected) {
		const role = this.state.role;
		if (selected) {
			if (!some(role.permissions, (rp) => rp.permission === permission.key)) {
				role.permissions.push({
					roleId: role.roleId,
					permission: permission.key
				});
			}
		} else {
			remove(role.permissions, (rp) => rp.permission === permission.key);
		}
        this.setState({
			role,
			hasUnsavedChanges: true
        });
	}
	isAllSelected = () => {
		if (!this.state.role) return false;
		for (let i = 0, ii = this.props.permissions.length; i < ii; i++) {
			const permission = this.props.permissions[i];
			const selected = some(this.state.role.permissions, rp => rp.permission === permission.key);
			if (!selected) return false;
		}
		return true;
	}
	toggleAll = () => {
		const nextValue = !this.isAllSelected();
		const role = this.state.role;
		forEach(this.props.permissions, (p) => {
			if (nextValue) {
				role.permissions = addOrUpdate(
					role.permissions, 
					{
						roleId: role.roleId,
						permission: p.key
					}, 
					rp => rp.permission === p.key
				);
			} else {
				remove(role.permissions, rp => rp.permission === p.key);
			}
		});
		this.setState({
			role,
			hasUnsavedChanges: true
		});
	}

	isRowSelected = (row) => this.state.role ? some(this.state.role.permissions, rp => rp.permission === row.key) : false;
	onSelectionChanged = (rows, selected)  => {
		this.setState(produce(draft => {
			const role = draft.role;
			forEach(rows, (r) => {
				if (selected) {
					if (!some(role.permissions, (rp) => rp.permission === r.key)) {
						role.permissions.push({
							roleId: role.roleId,
							permission: r.key
						});
					}
				} else {
					remove(role.permissions, (rp) => rp.permission === r.key);
				}
			});
		}));
	}

	render() {
		const role =  this.state.role || {};

		return (
<PageLayout
	title={role.name}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.role),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.role),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<RoleForm 
			role={role} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			isRowSelected={this.isRowSelected}
			onSelectionChanged={this.onSelectionChanged}
			permissions={this.props.permissions}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

RoleMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	role: PropTypes.object,
	permissions: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

RoleMaintenance.defaultProps = {
	role: undefined
};

const mapStateToProps = (state) => ({
	canEdit: canEdit(state, 'EditRoles'),
	role: state.roles.role,
	permissions: globals.permissions,
	isLoading: state.roles.isLoading,
	saveResult: state.roles.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (role) => {
		dispatch(saveRole(role, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (role) => {
		dispatch(saveRole(role, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(RoleMaintenance);
