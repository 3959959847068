import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import LimitsOfAccuracyGrid from './limitsOfAccuracyGrid';
import { 
	requestLimitsOfAccuracy, 
	clearLimitsOfAccuracy, 
	saveLimitOfAccuracy 
} from '../../reducers/limitsOfAccuracy';
import ContentContainer from '../contentContainer';
import { requestScheduleOfRates } from '../../reducers/scheduleOfRates';
import SaveIcon from '@material-ui/icons/Save';
import find from 'lodash/find';
import MessagePanel from '../messagePanel';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { showSuccessNotification } from '../../reducers/notifications';

class LimitsOfAccuracy extends Component {
    constructor(props) {
        super(props);

        this.state = {
			limitsOfAccuracy: props.limitsOfAccuracy || []
        };

		this.saveLimitOfAccuracy = this.saveLimitOfAccuracy.bind(this);
		this.setLimit = this.setLimit.bind(this);
    }

	static fetchData(dispatch) {
		dispatch(requestScheduleOfRates());
		dispatch(requestLimitsOfAccuracy());
	}
	static clearData(dispatch) {
		dispatch(clearLimitsOfAccuracy());
	}

	componentDidMount() {
		LimitsOfAccuracy.fetchData(this.props.dispatch);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			limitsOfAccuracy: nextProps.limitsOfAccuracy || []
		});
	}

	setLimit(limitOfAccuracy, value) {
		const loa = find(this.state.limitsOfAccuracy, (l) => l.scheduleOfRatesItemId === limitOfAccuracy.scheduleOfRatesItemId);
		if (loa) {
			loa.limit = value;
		}

        this.setState({
			limitsOfAccuracy: this.state.limitsOfAccuracy
		});
	}

	saveLimitOfAccuracy() {
		this.props.saveChanges(this.state.limitsOfAccuracy);
	}

	render() {
		const limitsOfAccuracy = this.state.limitsOfAccuracy;

		return (
<PageLayout
	title="Limits Of Accuracy"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'Save',
				icon: <SaveIcon />,
				primary: true,
				onClick: this.saveLimitOfAccuracy,
				isLoading: this.props.isLoading
			}
		] : []
	}
	content={
		<div>
		<ContentContainer>
			<LimitsOfAccuracyGrid 
				scheduleOfRates={this.props.scheduleOfRates}
				limitsOfAccuracy={limitsOfAccuracy} 
				setLimit={this.setLimit} 
			/>

			<CircularProgress style={{ display: this.props.isLoading ? undefined : 'none' }} />
		</ContentContainer>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

LimitsOfAccuracy.propTypes = {
	dispatch: PropTypes.func.isRequired,
	limitsOfAccuracy: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	scheduleOfRates: PropTypes.array.isRequired,
	saveChanges: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	limitsOfAccuracy: state.limitsOfAccuracy.limitsOfAccuracy,
	canEdit: canEdit(state, 'EditForecasts'),
	scheduleOfRates: state.scheduleOfRates.scheduleOfRates,
	isLoading: state.limitsOfAccuracy.isLoading,
	saveResult: state.limitsOfAccuracy.saveResult
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	saveChanges: (limitOfAccuracy) => {
		dispatch(saveLimitOfAccuracy(limitOfAccuracy, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LimitsOfAccuracy);
