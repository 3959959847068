import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';

class LotAdjustmentsHandoverContent extends React.Component {
	render() {
		const data = this.props.report;

		const columns = [
			{ name: 'lotNo', label: 'Lot' },
			{ 
				name: 'adjustmentType', 
				label: 'Adjustment Type',
				value: (args) => {
					switch (args.data.adjustmentType) {
						case 'QuantityError':
							return 'Quantity Error';
						default:
							return 'Unknown';
					}
				}
			},
			{ name: 'totalAmount', label: 'Adjusted Amount', formatString: 'currency' }
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

LotAdjustmentsHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default LotAdjustmentsHandoverContent;
