import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { fetch, addTask } from 'domain-task';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { addOrUpdate } from '../utils/utils';
import { globals } from '../globals';

const initialState = {
	isLoading: false,
	saveResult: {
		progress: null,
		success: null,
		message: null,
		fields: [] 
	},
	principalCorrespondence: null, 
	principalCorrespondences: null
};

const CLEAR_PRINCIPAL_CORRESPONDENCES = 'CLEAR_PRINCIPAL_CORRESPONDENCES';
const CLEAR_PRINCIPAL_CORRESPONDENCE = 'CLEAR_PRINCIPAL_CORRESPONDENCE';
const REQUEST_PRINCIPAL_CORRESPONDENCE = 'REQUEST_PRINCIPAL_CORRESPONDENCE';
const RECEIVE_PRINCIPAL_CORRESPONDENCE = 'RECEIVE_PRINCIPAL_CORRESPONDENCE';
const REQUEST_PRINCIPAL_CORRESPONDENCES = 'REQUEST_PRINCIPAL_CORRESPONDENCES';
const RECEIVE_PRINCIPAL_CORRESPONDENCES = 'RECEIVE_PRINCIPAL_CORRESPONDENCES';
const CREATE_NEW_PRINCIPAL_CORRESPONDENCE = 'CREATE_NEW_PRINCIPAL_CORRESPONDENCE';
const SAVE_PRINCIPAL_CORRESPONDENCE = 'SAVE_PRINCIPAL_CORRESPONDENCE';
const RECEIVE_SAVE_PRINCIPAL_CORRESPONDENCE_RESPONSE = 'RECEIVE_SAVE_PRINCIPAL_CORRESPONDENCE_RESPONSE';
const DELETE_PRINCIPAL_CORRESPONDENCE = 'DELETE_PRINCIPAL_CORRESPONDENCE';
const RECEIVE_DELETE_PRINCIPAL_CORRESPONDENCE_RESPONSE = 'RECEIVE_DELETE_PRINCIPAL_CORRESPONDENCE_RESPONSE';
const SHOW_PRINCIPAL_CORRESPONDENCE_ERRORS = 'SHOW_PRINCIPAL_CORRESPONDENCE_ERRORS';
const UPDATE_PRINCIPAL_CORRESPONDENCE_NUMBER = 'UPDATE_PRINCIPAL_CORRESPONDENCE_NUMBER';
const UPDATE_PRINCIPAL_CORRESPONDENCE_SAVE_PROGRESS = 'UPDATE_PRINCIPAL_CORRESPONDENCE_SAVE_PROGRESS';

export const clearPrincipalCorrespondences = () => ({ type: CLEAR_PRINCIPAL_CORRESPONDENCES });
export const clearPrincipalCorrespondence = () => ({ type: CLEAR_PRINCIPAL_CORRESPONDENCE });
export const receivePrincipalCorrespondences = (data) => ({ type: RECEIVE_PRINCIPAL_CORRESPONDENCES, payload: { data } });
export const receivePrincipalCorrespondence = (data) => ({ type: RECEIVE_PRINCIPAL_CORRESPONDENCE, payload: { data } });
export const receiveSavePrincipalCorrespondenceResponse = (data) => ({ type: RECEIVE_SAVE_PRINCIPAL_CORRESPONDENCE_RESPONSE, data });
export const receiveDeletePrincipalCorrespondenceResponse = (data) => ({ type: RECEIVE_DELETE_PRINCIPAL_CORRESPONDENCE_RESPONSE, data });
export const updateCorrespondenceNumber = (number) => ({ type: updateCorrespondenceNumber, number });
export const updateSaveProgress = (progress) => ({ type: UPDATE_PRINCIPAL_CORRESPONDENCE_SAVE_PROGRESS, progress });

export const createNewPrincipalCorrespondence = (contractId) =>
	(dispatch) => {	
		const newCorrespondence = {
			...cloneDeep(globals.templates.principalCorrespondence)
		};
		dispatch({ type: CREATE_NEW_PRINCIPAL_CORRESPONDENCE, newCorrespondence: newCorrespondence });

		const fetchTask =  fetch(`/api/correspondence/get-principal-correspondence-number?contractId=${contractId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.text();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				dispatch({ type: UPDATE_PRINCIPAL_CORRESPONDENCE_NUMBER, number: data });
			}).catch((error) => {
				console.log('request failed', error);
			});
					
		addTask(fetchTask);
	};

export const requestPrincipalCorrespondences = () => (
	createFetchAction({
		objectName: 'Principal Correspondence',
		passContext: true,
		url: '/api/principal-correspondence',
		startAction: REQUEST_PRINCIPAL_CORRESPONDENCES,
		success: (data) =>
			receivePrincipalCorrespondences(map(data, (c) => ({
				...c,
				dateSent: c.dateSent ? new Date(c.dateSent) : null,
				dateClosed: c.dateClosed ? new Date(c.dateClosed) : null,
				responseRequiredBy: c.responseRequiredBy ? new Date(c.responseRequiredBy) : null,
				initialResponseDate: c.initialResponseDate ? new Date(c.initialResponseDate) : null,
				finalResponseDate: c.finalResponseDate ? new Date(c.finalResponseDate) : null
			})))
	})
);

export const requestPrincipalCorrespondence = (correspondenceId) => (
	createFetchAction({
		objectName: 'Principal Correspondence',
		passContext: true,
		url: `/api/principal-correspondence/${correspondenceId}`,
		startAction: REQUEST_PRINCIPAL_CORRESPONDENCE,
		success: (data) =>
			receivePrincipalCorrespondence({
				...data,
				dateSent: data.dateSent ? new Date(data.dateSent) : null,
				dateClosed: data.dateClosed ? new Date(data.dateClosed) : null,
				responseRequiredBy: data.responseRequiredBy ? new Date(data.responseRequiredBy) : null,
				initialResponseDate: data.initialResponseDate ? new Date(data.initialResponseDate) : null,
				finalResponseDate: data.finalResponseDate ? new Date(data.finalResponseDate) : null
			})
	})
);

export const savePrincipalCorrespondence = (correspondence, newFiles, onSuccess) => {
	const errors = [];
	if (!correspondence.rfiNumber) {
		errors.push({
			fieldName: 'RfiNumber',
			valid: false,
			message: 'Principal Correspondence Number is required'
		});
	}
	if (!correspondence.inOrOut) {
		errors.push({
			fieldName: 'InOrOut',
			valid: false,
			message: 'In/Out is required'
		});
	}
	if (errors.length > 0) return { type: SHOW_PRINCIPAL_CORRESPONDENCE_ERRORS, data: errors };

	const formData = new FormData();
	formData.append('Correspondence', encodeURIComponent(JSON.stringify(correspondence)));
	forEach(newFiles, (f) => {
		if (f.data === 'PrincipalCorrespondence') {
			formData.append('NewFiles', f.file);
		} else {
			formData.append('ResponseFiles', f.file);
		}
	});

	return createFormPostAction({
		passContext: true,
		url: '/api/principal-correspondence',
		formData,
		startAction: SAVE_PRINCIPAL_CORRESPONDENCE,
		onProgress: (progress) => updateSaveProgress(progress),
		success: (data, dispatch) => {
			dispatch(receiveSavePrincipalCorrespondenceResponse({
				...data,
				object: data.object ? {
					...data.object,
					dateSent: data.object.dateSent ? new Date(data.object.dateSent) : null,
					dateClosed: data.object.dateClosed ? new Date(data.object.dateClosed) : null,
					responseRequiredBy: data.object.responseRequiredBy ? new Date(data.object.responseRequiredBy) : null,
					initialResponseDate: data.object.initialResponseDate ? new Date(data.object.initialResponseDate) : null,
					finalResponseDate: data.object.finalResponseDate ? new Date(data.object.finalResponseDate) : null
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deletePrincipalCorrespondence = (correspondence, onSuccess) => (
	createPostAction({
		passContext: true,
		url: `/api/principal-correspondence/${correspondence.principalCorrespondenceId}/delete`,
		data: correspondence,
		startAction: DELETE_PRINCIPAL_CORRESPONDENCE,
		success: (data, dispatch) => {
			dispatch(receiveDeletePrincipalCorrespondenceResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_PRINCIPAL_CORRESPONDENCES:
			return {
				...state,
				principalCorrespondences: null
			};
		case CLEAR_PRINCIPAL_CORRESPONDENCE:
			return {
				...state,
				principalCorrespondence: null
			};
		case REQUEST_PRINCIPAL_CORRESPONDENCES:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				principalCorrespondences: []
			};
		case RECEIVE_PRINCIPAL_CORRESPONDENCES:
			return {
				...state,
				isLoading: false,
				principalCorrespondences: action.payload.data
			};
		case REQUEST_PRINCIPAL_CORRESPONDENCE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				principalCorrespondence: globals.templates.principalCorrespondence
			};
		case RECEIVE_PRINCIPAL_CORRESPONDENCE:
			return {
				...state,
				isLoading: false,
				principalCorrespondence: action.payload.data
			};
		case DELETE_PRINCIPAL_CORRESPONDENCE:
			return { 
				...state
			};
		case RECEIVE_DELETE_PRINCIPAL_CORRESPONDENCE_RESPONSE:
			return {
				...state,
				principalCorrespondences: state.principalCorrespondences.filter(c => c.principalCorrespondenceId !== action.data.objectId) 
			};
		case CREATE_NEW_PRINCIPAL_CORRESPONDENCE:
			return {
				...state,
				principalCorrespondence: action.newCorrespondence
			};	
		case SAVE_PRINCIPAL_CORRESPONDENCE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_PRINCIPAL_CORRESPONDENCE_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				principalCorrespondences: addOrUpdate(state.principalCorrespondences, action.data.object, { principalCorrespondenceId: action.data.object.principalCorrespondenceId }), 
				principalCorrespondence: action.data.object,				
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_PRINCIPAL_CORRESPONDENCE_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		case UPDATE_PRINCIPAL_CORRESPONDENCE_NUMBER:
			return {
				...state,
				principalCorrespondence: {
					...state.principalCorrespondence,
					rfiNumber: action.number
				}
			};
		case UPDATE_PRINCIPAL_CORRESPONDENCE_SAVE_PROGRESS:
			return {
				...state,
				saveResult: {
					...state.saveResult,
					progress: action.progress
				}
			};
		default:
			return state;
	}
};
