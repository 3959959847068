import React from 'react';
import PropTypes from 'prop-types';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions
} from '@material-ui/core';
import Button from './button';
import Form from './Form';
import { default as MuiTextField } from '@material-ui/core/TextField';

const TextEditorDialog = props => {
	const classes = props.classes;
	const [value, setValue] = React.useState('');

	React.useEffect(() => {
		if (props.show) {
			setValue(props.value);
		}
	}, [props.show]);

	const onClose = () => {
		props.onCancel();
	};
	const onSubmit = () => {
		props.onOk(value);
	}
	return (
		<Dialog 
			fullWidth={true} 
			maxWidth="lg" 
			open={props.show} 
			onClose={onClose} 
			style={{ zIndex: 9990 }} 
			classes={classes}
			scroll='paper'
		>
			{props.title && <DialogTitle>{props.title}</DialogTitle>}
			<DialogContent style={{ }}>
				<Form 
					onSubmit={onSubmit}
					style={{ }}
				>
					<MuiTextField
						multiline
						variant="outlined"
						minRows={10}
						style={{
							width: '100%',
						}}
						value={value}
						onChange={e => setValue(e.target.value)}
					/>
				</Form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
	 			<Button onClick={onSubmit}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

TextEditorDialog.propTypes = {
	show: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	title: PropTypes.string
};

TextEditorDialog.defaultProps = {
	show: false,
	title: null
};

export default TextEditorDialog;
