import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { tryParseJson } from '../../../utils/utils';

class RegistersHandoverContent extends React.Component {
	render() {
		const data = this.props.report;
		const definition = this.props.registerDefinition;

		const gridFields = filter(definition.fields, f => f.fieldType !== 'File' && f.fieldType !== 'Correspondence');

		const columns = [
			{ name: 'registerId', label: 'Id' },
			...map(gridFields, f => ({
				name: f.name,
				label: f.name, 
				value: (args) => {
					const values = tryParseJson(args.data.values) || {};
					return values[f.name];
				}
			}))
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

RegistersHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default RegistersHandoverContent;
