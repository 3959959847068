import { getPath } from '../utils/utils';

const initialState = {
	helpPages: [],
	showHelpPage: false,
	helpPage: null
};

const SHOW_HELP_PAGE = 'SHOW_HELP_PAGE';
const HIDE_HELP_PAGE = 'HIDE_HELP_PAGE';

export const showHelpPage = (helpPageUrl) => ({ type: SHOW_HELP_PAGE, helpPageUrl: helpPageUrl });
export const hideHelpPage = () => ({ type: HIDE_HELP_PAGE });

export const getHelpPage = (helpPages) => {
	if (!helpPages) return;
	const path = getPath();
	for (let i = 0, ii = helpPages.length; i < ii; i++) {
		const helpPage = helpPages[i];
		if (helpPage.isActive) {
			try {
				let pathRegex = new RegExp(helpPage.pageUrl, "i");
				if (pathRegex.test(path)) return helpPage;
			} catch (ex) {
				console.log('error checking help page path: ' + ex);
				return null;
			}
		}
	}

	return null;
};

export default (originalState = initialState, action) => {
	let state = originalState;
	if (!state._hydrated) {
		state = { ...initialState, ...state, _hydrated: true };
	}
	
	switch (action.type) {
		case SHOW_HELP_PAGE:
			return {
				...state,
				showHelpPage: true,
				helpPage: action.helpPageUrl
			};
		case HIDE_HELP_PAGE:
			return {
				...state,
				showHelpPage: false,
				helpPage: null
			}
		default:
			return state;
	}
};
