import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import SelectField from '../widgets/selectField';
import FormFieldContainer from '../formFieldContainer';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import SubContentContainer from '../subContentContainer';
import FloatingActionButton from '@material-ui/core/Fab';
import ContentAdd from '@material-ui/icons/Add';
import LotItemDialog from './lotItemDialog';
import ContentContainer from '../contentContainer';
import PersistedDataTable from '../widgets/persistedDataTable';
import { toCurrency } from '../../utils/utils';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import { units, errorColour } from '../../variables';
import DateField from '../widgets/DateField';
import ViewIcon from '@material-ui/icons/Pageview';
import CircularProgress from '@material-ui/core/CircularProgress';
import TagField from '../widgets/TagField';

class LotForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			showLotItemDialog: false,
			scheduleOfRates: null,
			itemErrorText: ''
		};
    
		this.onItemChange = this.onItemChange.bind(this);
		this.addLotItem = this.addLotItem.bind(this);
		this.onUpdateItem = this.onUpdateItem.bind(this);
		this.onAddLotItems = this.onAddLotItems.bind(this);
		this.onCancelLotDialog = this.onCancelLotDialog.bind(this);
		this.getScheduleItemByLotItem = this.getScheduleItemByLotItem.bind(this);
	}

	addLotItem() {
		this.setState({ showLotItemDialog: true });
	}

	onItemChange(scheduleOfRates) {
		this.setState(
			{
				scheduleOfRates: scheduleOfRates,
				itemErrorText: ''
			}
		);
	}

	onUpdateItem() {
		this.setState({
			scheduleOfRates: null,
			itemErrorText: ''
		});
	}

	onAddLotItems(items) {
		for (let i = 0, ii = items.length; i < ii; i++) {
			this.props.updateLotItem(items[i]);
		}
		this.setState({ 
			showLotItemDialog: false 
		});
	}

	onCancelLotDialog() {
		this.setState({ showLotItemDialog: false });
	}
	getScheduleItemByLotItem(lotItem) {
		for (let i = 0, ii = this.props.scheduleOfRates.length; i < ii; i++) {
			for (let j = 0, jj = this.props.scheduleOfRates[i].items.length; j < jj; j++) {
				const item = this.props.scheduleOfRates[i].items[j];
				if (item.scheduleOfRatesItemId === lotItem.scheduleOfRatesItemId) return item;
			}
		}

		return null;
	}

	render() {
		const lot = this.props.lot || { tags: []};
		const items = lot.items || [];
		const ncrs  = lot.ncRs || [];
		const isClosedFinal = lot.state === 'ClosedFinal';
		const isClosed = lot.state === 'ClosedFinal' || lot.state === 'Closed' || lot.state === 'ClosedGuaranteed' || lot.state === 'Unguaranteed' || lot.state === 'reopened';
		
		const columns = [
			{ name: 'scheduleName', label: 'Schedule' },
			{ name: 'itemNo', label: 'Item' },
			{ name: 'itemDescription', label: 'Description', headerStyle: { minWidth: '200px' } },
			{ 
				name: 'unit', 
				label: 'Unit',
				filterOperators: ['=', 'Contains', 'multi-select'],
				filterOptions: units 
			},
			{ 
				name: 'remainingQty', 
				label: 'Remaining Qty',
				dataType: 'number',
				hideByDefault: true
			},
			{ 
				name: 'qty', 
				label: 'Qty',
				dataType: 'number',
				cellRenderer: this.props.canEdit && !isClosed ? (args) =>
					<div>
						<DataTableNumericEditor
							type="decimal"
							value={args.data.qty} 
							onChange={(v) => { this.props.updateLotItem({ ...args.data, qty: v }); }}
							style={{ width: 'auto' }}
						/>
						<div style={{ whiteSpace: 'nowrap', fontSize: 'small', color: args.data.remainingQty < 0 ? errorColour : '#bbb' }}> ({args.data.remainingQty} remaining)</div>
					</div>
				: undefined
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '200px' } }
		];

		if (this.props.canEdit && !isClosed) {
			columns.unshift({
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.deleteLotItem(args.data); } }>
							<ActionDelete />
						</IconButton>
					</div>
			});
		}

		let allowedStatues = [];
		switch (lot.prevState) {
			case 'Open':
				allowedStatues = [
					{ value: 'Open', label: 'Open' },
					{ value: 'Guaranteed', label: 'Guaranteed' },
					{ value: 'Closed', label: 'Closed' }
				];
				break;
			case 'Closed':
				allowedStatues = [
					{ value: 'Closed', label: 'Closed' }
				];
				break;
			case 'ClosedGuaranteed':
				allowedStatues = [
					{ value: 'Closed', label: 'Closed' }
				];
				break;
			case 'Guaranteed':
				allowedStatues = [
					{ value: 'Guaranteed>1Month', label: 'Guaranteed > 1 Month' },
					{ value: 'Closed', label: 'Closed' }
				];
				break;
			case 'Unguaranteed':
				allowedStatues = [
					{ value: 'Guaranteed>1Month', label: 'Guaranteed > 1 Month' },
					{ value: 'Closed', label: 'Closed' }
				];
				break;
			case 'Reopened':
				allowedStatues = [
					{ value: 'Guaranteed>1Month', label: 'Guaranteed > 1 Month' },
					{ value: 'Closed', label: 'Closed' }
				];
				break;
			default:
				allowedStatues = [
					{ value: 'Open', label: 'Open' },
					{ value: 'Guaranteed', label: 'Guaranteed' },
					{ value: 'Closed', label: 'Closed' }
				];
		}

		const ncrColumns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.viewNCR(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			},
			{ name: 'contractorNcrNo', label: 'Contractor NCR Number' },
			{ 
				name: 'status', 
				label: 'Status',
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					switch (args.data.status) {
						case 'Open':
							statusBackColour = '#FFC7CE';
							statusForeColour = '#9C0006';
							break;
						case 'Closed':
							statusBackColour = '#C6EFCE';
							statusForeColour = '#2C6153';
							break;
						default:
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed']
			}
		];

		const lotAdjustmentItems  = [];
		forEach(this.props.lotAdjustments, la => {
			forEach(la.items, lai => {
				lotAdjustmentItems.push({
					lotAdjustmentId: la.lotAdjustmentId,
					lotAdjustmentItemId: lai.lotAdjustmentItemId,
					scheduleName: lai.scheduleName,
					itemNo: lai.itemNo,
					itemDescription: lai.itemDescription,
					unit: lai.unit,
					originalQty: lai.originalQty,
					qty: lai.qty,
					totalAdjustments: lai.totalAdjustments,
					periodClaimedName: la.periodClaimedName,
					comments: la.comments
				});
			});
		});
		const lotAdjustmentsColumns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.viewLotAdjustment(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			},
			{ name: 'scheduleName', label: 'Schedule' },
			{ name: 'itemNo', label: 'Item' },
			{ name: 'itemDescription', label: 'Description' },
			{ name: 'unit', label: 'Unit' },
			{ name: 'originalQty', label: 'Original Qty' },
			{ name: 'qty', label: 'Adjusted Qty' },
			{ name: 'periodClaimedName', label: 'Adjustment Period' },
			{ name: 'comments', label: 'Comments' }
		];

		return (
<div>

	<LotItemDialog 
		show={this.state.showLotItemDialog} 
		addLotItems={this.onAddLotItems} 
		cancel={this.onCancelLotDialog} 
		scheduleOfRates={this.props.scheduleOfRates}
	/>
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={lot.lotNo || ''}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('lotNo', v); }}
				label="Lot No."
				errorText={getFieldErrorText(this.props.saveResult, 'LotNo')}
			/>
		</FormFieldContainer>
		<FormFieldContainer>
			<TextField
				value={lot.lotDescription || ''}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('lotDescription', v); }}
				label="Lot Description"
				errorText={getFieldErrorText(this.props.saveResult, 'LotDescription')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<SelectField 
				value={lot.status} 
				fullWidth={true}
				disabled={isClosedFinal}
				label="Status" 
				onChange={(v) => { this.props.updateField('status', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'Status')}
				items={allowedStatues}
			>
			</SelectField>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<SelectField 
				value={lot.periodClaimed} 
				disabled={true}
				fullWidth={true}
				label="Period Claimed" 
				onChange={(v) => { this.props.updateField('periodClaimed', v); }}
				items={
					map(this.props.periods, (p) => 
						({ value: p.periodId, label: p.name })
					)
				}
			>
			</SelectField>
		</FormFieldContainer>
			
		<FormFieldContainer classes={['s12', 'm6']}>

			<TagField
				label="Tags" 
				fullWidth={true}
				value={lot.tags}
				onChange={tags => this.props.updateField('tags', tags)}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<SelectField 
				value={lot.electronicLotData} 
				fullWidth={true}
				label="Electronic Lot Data" 
				onChange={(v) => { this.props.updateField('electronicLotData', v); }}
				items={[
					{ value: 'Complete', label: 'Complete' },
					{ value: 'Incomplete', label: 'Incomplete' },
					{ value: 'NoRecords', label: 'No Records' }
				]}				
			>
			</SelectField>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m6: true }}>
			<DateField
				value={lot.dateLotCreated} 
				label="Date notified by Contractor" 
				onChange={(d) => { this.props.updateField('dateLotCreated', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={lot.comments}
				fullWidth={true}
				multiline={true}
				onChange={(e, v) => { this.props.updateField('comments', v); }}
				label="Comments"
				errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
			/>
		</FormFieldContainer>
	</ContentContainer>

	<SubContentContainer header="Items">
		<PersistedDataTable 
			id="lotItems"
			data={items} 
			columns={columns}
		/>

		{this.props.canEdit && !isClosed &&
			<div className="row">
				<FloatingActionButton onClick={this.addLotItem} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
					<ContentAdd />
				</FloatingActionButton>
			</div>
		}
	</SubContentContainer>

	<SubContentContainer header="NCRs" style={{ marginTop: '10px' }}>
		{ncrs.length === 0 ?
			this.props.isLoading ? <p></p> : <p>This lot has no NCRs</p>
			:
			<PersistedDataTable 
				id="lotNCRs"
				data={ncrs} 
				columns={ncrColumns}
			/>
		}
	</SubContentContainer>

	<SubContentContainer header="Adjustments" style={{ marginTop: '10px' }}>
		{lotAdjustmentItems.length === 0 ?
			this.props.lotAdjustmentsLoading ? <p></p> : <p>This lot has no Lot Adjustments</p>
			:
			<PersistedDataTable 
				id="lotFormlotAdjustments"
				data={lotAdjustmentItems} 
				columns={lotAdjustmentsColumns}
			/>
		}
	</SubContentContainer>

	<SubContentContainer header="Timeline" style={{ marginTop: '10px' }}>
		{this.props.lotHistoryLoading ?
			<CircularProgress size={30} style={{ marginLeft: '1em', marginTop: '7px' }} />
			:
			<ul className="timeline">
				{
					map(this.props.lotHistory, (l, i) => {
						let title;
						let statusColour;
						switch (l.eventType) {
							case 'PeriodStatus':
								title = l.status;
								switch (l.status) {
									case 'Guaranteed':
										statusColour = '#f0ad4e';
										break;
									case 'Guaranteed>1Month':
										statusColour = '#d9534f';
										break;
									case 'Closed':
										statusColour = '#35aa47';
										break;
									default:
										statusColour = '#5bc0de';
										break;
								}
								break;
							case 'Adjustment':
								title = 'Quantity Adjustment';
								statusColour = '#5bc0de';
								break;
							default:
						}

						return (
							<li key={`lot-hist-${i}`} className="event" data-date={l.periodName}>
								<h3 style={{ color: statusColour }}>{title}</h3>
								{l.eventType === 'PeriodStatus' &&
									<p>
										Certified: {toCurrency(l.certifiedAmount)}
									</p>
								}
								{l.eventType === 'Adjustment' &&
									<div>
										<p>
											Adjusted: {toCurrency(l.adjustedAmount)}
										</p>
										<p>
											New Certified: {toCurrency(l.certifiedAmount)}
										</p>
									</div>
								}
								{l.comments && <p>Comments: {l.comments}</p>}
                                {l.adjustments && l.adjustments.length > 0 &&
                                    <React.Fragment>
                                        <h2>Adjustments</h2>
                                        <table className="full-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Original Qty</th>
                                                    <th>Adjusted Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    map(l.adjustments, adj => 
                                                        <tr>
                                                            <td>{adj.itemNo}</td>
                                                            <td style={{ textAlign: 'right' }}>{adj.originalQty}</td>
                                                            <td style={{ textAlign: 'right' }}>{adj.qty}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                }
							</li>
						);
					})
				}
			</ul>
		}
	</SubContentContainer>
</div>
		);
	}
}


LotForm.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	scheduleOfRates: PropTypes.array,
	periods: PropTypes.array.isRequired,
	tags: PropTypes.array.isRequired,
	lot: PropTypes.object.isRequired,
	lotHistory: PropTypes.array.isRequired,
	lotHistoryLoading: PropTypes.bool	,
	lotAdjustments: PropTypes.array.isRequired,
	lotAdjustmentsLoading: PropTypes.bool,
	updateField: PropTypes.func.isRequired,
	updateLotItem: PropTypes.func.isRequired,
	deleteLotItem: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	viewNCR: PropTypes.func.isRequired,
	viewLotAdjustment: PropTypes.func.isRequired
};

LotForm.defaultProps = {
	scheduleOfRates: []
};

export default LotForm;
