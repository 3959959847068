import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import Checkbox from '../widgets/Checkbox';
import MessagePanel from '../messagePanel';
import ContentContainer from '../contentContainer';
import FormFieldContainer from '../formFieldContainer';
import SelectField from '../widgets/selectField';
import AvatarSelect from '../widgets/avatarSelect';
import SubContentContainer from '../subContentContainer';
import PersistedDataTable from '../widgets/persistedDataTable';
import map from 'lodash/map';
import find from 'lodash/find';
import IconButton from '../widgets/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import UserRoleDialog from './userRoleDialog';
import PageLayout from '../pageLayout';

const UserForm = (props) => {
	
	const user = props.user || { contractRoles: [] };
	const contractRoles = map(props.contracts, c => {
		const userContract = find(user.contractRoles, cr => cr.contractId === c.contractId);
		return {
			contractId: c.contractId,
			contractName: c.contractName,
			roleIds: userContract ? map(userContract.roles, r => r.roleId) : [],
			roles: userContract ? map(userContract.roles, r => r.name).join(',') : null
		};
	});

	const columns = [
		{ name: 'contractName', label: 'Contract' },
		{ 
			name: 'roles', 
			label: 'Roles',
			cellRenderer: (args) => 
				<div>
					<span style={{ marginTop: '14px', display: 'inline-block' }}>{args.data.roles}</span>
					<IconButton onClick={ () => { props.showRolesDialog(args.data); }} style={{ float: 'right' }}>
						<EditIcon />
					</IconButton>
				</div>
		}
	];

	const roleItems = [
		{ value: 'Administrator', label: 'Administrator', disabled: props.userRole !== 'Global Administrator' && props.userRole !== 'Administrator' },
		{ value: 'User', label: 'User' }
	];
	if (props.userRole === 'Global Administrator') {
		roleItems.splice(0, 0, { value: 'Global Administrator', label: 'Global Administrator', disabled: props.userRole !== 'Global Administrator' });
	}

	return (
<PageLayout
	title={user.userName}
	isLoading={props.isLoading}
	formActions={{
		isLoading: props.isLoading,
		canEdit: props.canEdit,
		hasUnsavedChanges: props.hasUnsavedChanges,
		onSave: () => props.onSave(),
		onSaveAndContinue: () => props.onSaveAndContinue(),
		onCancel: () => props.onCancel()
	}}
	content={
		<div>
		<UserRoleDialog 
			show={props.showUserRoleDialog} 
			updateRoles={props.onUpdateRoles} 
			cancel={props.onCancelUserRoleDialog} 
			roles={props.roles}
			userContractRoles={props.userContractRoles}
		/>
		
		<ContentContainer>
			{props.isGlobalAdmin && 
			<FormFieldContainer>
				<SelectField 
					value={user.tenantId} 
					fullWidth={true}
					disabled={!user._isNew}
					label="Tenant" 
					onChange={(v) => { props.onTenantIdChanged(v); }}
					errorText={getFieldErrorText(props.saveResult, 'TenantId')}
					items={
						map(props.tenants, (t) => ({ value: t.tenantId, label: t.name }))
					}
				>
				</SelectField>
			</FormFieldContainer>
			}

			<FormFieldContainer>
				<TextField
					value={user.userName}
					autoComplete="off"
					onChange={(e) => { props.updateFieldValue('userName', e.target.value); } }
					label="User Name"
					errorText={getFieldErrorText(props.saveResult, 'UserName')}
					fullWidth={true}
				/>
			</FormFieldContainer>

			{user._isNew ? 
			<FormFieldContainer>
				<TextField
					type="password"
					autoComplete="off"
					value={user.password}
					onChange={(e) => { props.updateFieldValue('password', e.target.value); } }
					label="Password"
					errorText={getFieldErrorText(props.saveResult, 'Password')}
					fullWidth={true}
				/>
			</FormFieldContainer>
			: null }

			<FormFieldContainer 
				classes={{ hasToggle: true }}
				infoText="Inactive users cannot access Contrack"
			>
				<Checkbox
					label="Is Active"
					labelPosition="right"
					checked={user.active}
					onCheck={(e, v) => { props.updateFieldValue('active', v); }}
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<TextField
					value={user.fullName}
					onChange={(e) => { props.updateFieldValue('fullName', e.target.value); } }
					label="Full Name"
					errorText={getFieldErrorText(props.saveResult, 'FullName')}
					fullWidth={true}
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<TextField
					value={user.email}
					onChange={(e) => { props.updateFieldValue('email', e.target.value); } }
					label="Email"
					errorText={getFieldErrorText(props.saveResult, 'Email')}
					fullWidth={true}
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<TextField
					value={user.position}
					onChange={(e) => { props.updateFieldValue('position', e.target.value); } }
					label="Position"
					errorText={getFieldErrorText(props.saveResult, 'Position')}
					fullWidth={true}
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<SelectField 
					value={user.userRole} 
					fullWidth={true}
					label="Role" 
					onChange={(v) => { props.updateFieldValue('userRole', v); }}
					errorText={getFieldErrorText(props.saveResult, 'UserRole')}
					items={roleItems}
					required={true}
				>
				</SelectField>
			</FormFieldContainer>

			<FormFieldContainer 
				classes={{ hasToggle: true }}
				infoText="Provides full administration rights and access to all projects on ConTrack"
			>
				<Checkbox
					label="Full Access"
					labelPosition="right"
					checked={user.hasFullAccess}
					onCheck={(e, v) => { props.updateFieldValue('hasFullAccess', v); }}
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<AvatarSelect value={user.avatar} labelText="Avatar" onChange={(v) => { props.updateFieldValue('avatar', v); } } />
			</FormFieldContainer>

		</ContentContainer>

		<SubContentContainer header="Contract Roles">
			<PersistedDataTable 
				id="userContractRoles"
				data={contractRoles} 
				columns={columns}
			/>
		</SubContentContainer>

		<MessagePanel isSuccess={props.saveResult.success} message={props.saveResult.message} />
		</div>
	}
/>
	);
}

UserForm.propTypes = {
	contracts: PropTypes.array,
	roles: PropTypes.array,
	tenants: PropTypes.array,
	user: PropTypes.object.isRequired,
	userRole: PropTypes.string.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onSaveAndContinue: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

UserForm.defaultProps = {
	contracts: [],
	roles: [],
	tenants: []
}

export default UserForm;
