import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import slice from 'lodash/slice';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import filter from 'lodash/filter';
import some from 'lodash/some';
import every from 'lodash/every';
import toLower from 'lodash/toLower';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import reverse from 'lodash/reverse';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import round from 'lodash/round';
import IconButton from './IconButton';
import LeftArrow from '@material-ui/icons/ChevronLeft';
import RightArrow from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import ActionSearch from '@material-ui/icons/Search';
import FilterIcon from '@material-ui/icons/FilterList';
import TextField from './TextField';
import SelectField from './selectField';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from './Divider';
import Checkbox from './Checkbox';
import { 
	toCurrency, 
	toPercent, 
	groupByArray, 
	formatDate 
} from '../../utils/utils';
import isDate from 'lodash/isDate';
import DataTableRow from './dataTableRow';
import DataTableFilterEditor from './dataTableFilterEditor';
import DataTableColumnSelector from './dataTableColumnSelector';
import { infoColor, warningColour } from '../../variables';
import IconMenu from './IconMenu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { downloadFile } from '../../utils/ajax';
import classNames from 'classnames';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';
import WarningIcon from '@material-ui/icons/Warning';

const defaultHeaderStyle = {
	height: '36px', 
	whiteSpace: 'nowrap',
	fontWeight: 'bold',
	backgroundColor: '#eee',
	WebkitPrintColorAdjust: 'exact'
};

class DataTable extends React.Component {
	_rows = null;
	_rowsKey = null;

	constructor(props, context) {
		super(props, context);

		const rowsPerPage = props.rowsPerPage || props.defaultRowsPerPage || 10;
		const sortBy = props.sortBy || props.defaultSortBy || null;
		const sortDir = props.sortDir || props.defaultSortDir || 'asc';
		const pageNum = props.pageNum || 1;
		const search = props.search || '';
		const filters = props.filters || props.defaultFilters || [];
		const selectedColumns = props.selectedColumns || null;
		
		this.state = {
			pageNum: pageNum,
			rowsPerPage: rowsPerPage,
			sortBy: sortBy,
			sortDir: sortDir,
			searchText: search,
			showFilterDropdown: false,
			filters: filters,
			fullScreen: false,
			collapsedGroups: [],
			expandedGroups: [],
			showColumnSelector: false,
			selectedColumns: selectedColumns
		};

		const totalRows = this.getTotalRows(props.data);
		this.state.pageCount = rowsPerPage === 'All' ? 1 : Math.ceil(totalRows / rowsPerPage);

		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.firstPage = this.firstPage.bind(this);
		this.lastPage = this.lastPage.bind(this);
		this.sortColumn = this.sortColumn.bind(this);
		this.getSortingFunc = this.getSortingFunc.bind(this);
		this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
		this.getSearchFunc = this.getSearchFunc.bind(this);
		this.getFilterFunc = this.getFilterFunc.bind(this);
		this.showFilterDropdown = this.showFilterDropdown.bind(this);
		this.cancelFilter = this.cancelFilter.bind(this);
		this.applyFilter = this.applyFilter.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		let pageCount;
		let rowsPerPage = nextProps.rowsPerPage || this.state.rowsPerPage;
		const sortBy = nextProps.sortBy || this.state.sortBy;
		const sortDir = nextProps.sortDir || this.state.sortDir;
		let pageNum = nextProps.pageNum || this.state.pageNum;
		const search = nextProps.search || this.state.searchText;
		const filters = nextProps.filters || this.state.filters;
		const selectedColumns = nextProps.selectedColumns || this.state.selectedColumns;

		/* When the parent changes the state directly without making a copy this is wrong. Making true for now */
		// const totalRows = this.getTotalRows(nextProps.data, nextProps.data !== this.props.data);
		const totalRows = this.getTotalRows(nextProps.data, true);

		if (rowsPerPage === 'All') {
			pageNum = 1;
			pageCount = 1;
			rowsPerPage = 'All';
		} else {
			const startIndex = this.state.rowsPerPage === 'All' ? 0 : (pageNum - 1) * this.state.rowsPerPage;
			pageNum = Math.ceil((startIndex + 1) / rowsPerPage);
			pageCount = Math.max(1, Math.ceil(totalRows / rowsPerPage));
		}
		
		this.setState({
			pageNum: pageNum,
			pageCount: pageCount,
			rowsPerPage: rowsPerPage,
			sortBy: sortBy,
			sortDir: sortDir,
			searchText: search,
			filters: filters,
			selectedColumns: selectedColumns
		});
	}

	onKeyDown = (e) => {
		if (e.keyCode === 27) {
			this.setState({ fullScreen: false });
			document.body.classList.remove('data-table--full-screen__document-body');
		}
	}

	componentDidMount() {
		document.addEventListener('keydown', this.onKeyDown, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeyDown, false);
	}

	prevPage() {
		const pageNum = Math.max(1, this.state.pageNum <= 1 ? 1 : this.state.pageNum - 1);
		this.setState({
			pageNum: pageNum
		}, () => {
			if (this.props.onChangePageNumber) {
				this.props.onChangePageNumber({
					pageNum: pageNum
				});
			}
		});
	}
	nextPage() {
		const totalRows = this.getTotalRows(this.props.data);
		const pageCount = this.state.rowsPerPage === 'All' ? 1 : Math.ceil(totalRows / this.state.rowsPerPage);
		const pageNum = this.state.pageNum >= pageCount ? pageCount : this.state.pageNum + 1;
		this.setState({
			pageNum: pageNum
		}, () => {
			if (this.props.onChangePageNumber) {
				this.props.onChangePageNumber({
					pageNum: pageNum
				});
			}
		});
	}
	firstPage() {
		this.setState({
			pageNum: 1
		}, () => {
			if (this.props.onChangePageNumber) {
				this.props.onChangePageNumber({
					pageNum: 1
				});
			}
		});
	}
	lastPage() {
		const totalRows = this.getTotalRows(this.props.data);
		const pageCount = this.state.rowsPerPage === 'All' ? 1 : Math.ceil(totalRows / this.state.rowsPerPage);
		const pageNum = pageCount;
		this.setState({
			pageNum: pageNum
		}, () => {
			if (this.props.onChangePageNumber) {
				this.props.onChangePageNumber({
					pageNum: pageNum
				});
			}
		});	
	}
	getTotalRows = (data, clearCache) => {
		const rows = this.getDatasetRows({ data: data }, clearCache);
		return rows.length;
	}

	changeRowsPerPage(value) {
		let pageNum;
		let pageCount;
		let rowsPerPage;
		const totalRows = this.getTotalRows(this.props.data);

		if (value === 'All') {
			pageNum = 1;
			pageCount = 1;
			rowsPerPage = 'All';
		} else {
			const startIndex = this.state.rowsPerPage === 'All' ? 0 : (this.state.pageNum - 1) * this.state.rowsPerPage;
			pageNum = Math.ceil((startIndex + 1) / value);
			pageCount = Math.max(1, Math.ceil(totalRows / value));
			rowsPerPage = value;
		}

		this.setState({
			pageNum: pageNum,
			pageCount: pageCount,
			rowsPerPage: rowsPerPage
		}, () => {
			if (this.props.onChangeRowsPerPage) {
				this.props.onChangeRowsPerPage({
					rowsPerPage: rowsPerPage
				});
			}
		});
	}

	getSortingFunc() {
		if (!this.state.sortBy) return null;
		const col = find(this.props.columns, (c) => c.name === this.state.sortBy);
		if (col && col.value) return (v) => col.value({ data: v });
		if (col && col.formatString) {
			switch (col.formatString) {
				case 'currency':
					return (v) => v[this.state.sortBy];
				case 'percent':
					return (v) => v[this.state.sortBy];
				default:
					return (v) => toLower(v[this.state.sortBy]);
			}
		}
		if (col && col.dataType) {
			switch (col.dataType) {
				case 'date':
				case 'datetime':
					return (v) => v[this.state.sortBy];
				default:
					return (v) => v[this.state.sortBy];
			}
		}
		return (v) => toLower(v[this.state.sortBy]);
	}
	sortColumn(c) {
		if (!this.props.enableSorting || c.sortable === false) return;

		const onChangeSorting = () => {
			if (this.props.onChangeSorting) {
				this.props.onChangeSorting({
					sortBy: this.state.sortBy,
					sortDir: this.state.sortDir
				});
			}
		};

		if (this.state.sortBy === c.name) {
			if (this.state.sortDir === 'asc') {
				this.setState({
					sortDir: this.state.sortDir === 'asc' ? 'desc' : 'asc'
				}, onChangeSorting);
			} else {
				// Reset if column toggled a third time
				this.setState({
					sortBy: null,
					sortDir: 'asc'
				}, onChangeSorting);
			}
		} else {
			this.setState({
				sortBy: c.name,
				sortDir: 'asc'
			}, onChangeSorting);
		}
	}
	getSearchFunc() {
		if (!this.state.searchText) return null;
		const includeColumns = filter(this.props.columns, (c) => c.excludeFromSearch !== true);
		return (v) =>
			some(includeColumns, (c) => {
				const value = c.value ? c.value({ data: v }) : v[c.name];
				const formattedValue = this.getFormattedValue(c, value);
				return toLower(formattedValue) && toLower(formattedValue).includes(toLower(this.state.searchText));
			});
	}
	onSearch(searchText) {
		this.setState({
			pageNum: 1,
			searchText: searchText
		}, () => {
			if (this.props.onChangeSearch) {
				this.props.onChangeSearch({
					search: searchText,
					pageNum: 1
				});
			}
		});
	}
	getFormattedValue(c, v) {
		const value = v;
		let formattedValue;
		if (c.formatter) {
			formattedValue = c.formatter({ value: value });
		} else if (c.formatString) {
			switch (c.formatString) {
				case 'currency':
					formattedValue = toCurrency(value);
					break;
				case 'percent':
					formattedValue = toPercent(value);
					break;
				default: 
					formattedValue = value;
			}
		} else if (c.dataType) {
			switch (c.dataType) {
				case 'date':
					formattedValue = formatDate(value, 'dd/MM/yyyy');
					break;
				case 'datetime':
					formattedValue = formatDate(value, 'dd/MM/yyyy h:mm:ss a');
					break;
				case 'bool':
					if (value === true) {
						formattedValue = 'Yes';
					} else if (value === false) {
						formattedValue = 'No';
					} else { 
						formattedValue = '';
					}
					break;
				default: 
					formattedValue = value;
			}
		} else {
			formattedValue = value;
		}
		return formattedValue;
	}

	onDataTableClick() {
		this.setState({
			showFilterDropdown: false
		});
	}

	onFilterDropdownClick(e) {
		e.stopPropagation();
	}

	showFilterDropdown(e, show) {
		e.stopPropagation();
		this.setState({
			showFilterDropdown: show
		});
	}

	getFilterOptionsKey = (value, column) => {
		if (isObject(value)) {
			return value[column.filterOptionsKey || 'key'];
		} else {
			return value;
		}
	}
	getFilterOptionsText = (value, column) => {
		if (isObject(value)) {
			return value[column.filterOptionsText || 'text'];
		} else {
			return value;
		}
	}

	getFilterFunc() {
		if (!this.state.filters) return null;
		if (this.state.filters.length < 1) return null;
		return (v) => 
			every(this.state.filters, (f) => {
				const column = find(this.props.columns, (c) => c.name === f.column);
				let value = column.value ? column.value({ data: v }) : v[column.name];
				const formattedValue = this.getFormattedValue(column, value);
				if (column.filterFunction) {
					return column.filterFunction.call(this, v, f.value);
				}
				if ((column.dataType === 'date' || column.dataType === 'datetime') && !isDate(value)) value = new Date(value);
				switch (f.operator) {
					case 'multi-select': {
						if (isArray(value) && value.length > 0) {
							// For each selected filter item and each data value use the supplied filterComparison func
							return some(f.value, (fv) => some(value, (v2) => fv === this.getFilterOptionsKey(v2, column)));
						} else if (value) {
							return some(f.value, (fv) => fv === value);
						} else {
							return false;
						}
					}
					case '>': {
						switch (column.dataType) {
							case 'date': 
							case 'datetime':
								return value && f.value ? value.getTime() > f.value.getTime() : false;
							default: return value > f.value;
						}
					}
					case '>=': {
						switch (column.dataType) {
							case 'date': 
							case 'datetime':
								return value && f.value ? value.getTime() >= f.value.getTime() : false;
							default: return value >= f.value;
						}
					}
					case '<': {
						switch (column.dataType) {
							case 'date': 
							case 'datetime':
								return value && f.value ? value.getTime() < f.value.getTime() : false;
							default: return value < f.value;
						}
					}
					case '<=': {
						switch (column.dataType) {
							case 'date': 
							case 'datetime':
								return value && f.value ? value.getTime() <= f.value.getTime() : false;
							default: return value <= f.value;
						}
					}
					case 'Contains': {
						return toLower(formattedValue).indexOf(toLower(f.value)) > -1;
					}
					default: 
						switch (column.dataType) {
							case 'currency':
							case 'percent':
							case 'number':
							case 'bool':
								return value === f.value;
							case 'date':
							case 'datetime':
								return value && f.value ? value.getTime() === f.value.getTime() : false;
							default:
								return toLower(formattedValue) === toLower(f.value);
						}
				}
			});
	}
	cancelFilter() {
		this.setState({
			showFilterDropdown: false
		});
	}
	applyFilter(filters) {
		this.setState({
			showFilterDropdown: false,
			filters: filters,
			pageNum: 1
		}, () => {
			if (this.props.onChangeFilters) {
				this.props.onChangeFilters({
					filters: filters,
					pageNum: 1
				});
			}
		});
	}
	isNumericColumn = (column) =>
		column.dataType === 'number' || column.formatString === 'currency' || column.formatString === 'percent';
	getDisplayedRows = (data) => 
		orderBy(filter(filter(data, this.getFilterFunc()), this.getSearchFunc()), this.getSortingFunc(), this.state.sortDir);

	exportToExcel = () => {
		const headerRows = [];
		const rows = [];
		const footerRows = [];
		const dataset = this.getDataset();
		
		const toExportRow = r => ({
			style: r.style,
			cells: 
				map(r.cells, c => ({
					formattedValue: c.fullLabel || c.formattedValue || c.value,
					value: isObject(c.value) ? (c.fullLabel || c.formattedValue) : c.value,
					formatString: c.formatString,
					style: c.style,
					colSpan: c.colspan,
					rowSpan: c.rowspan
				}))
		});

		forEach(dataset.header, r => headerRows.push(toExportRow(r)));
		forEach(dataset.rows, r => rows.push(toExportRow(r)));
		forEach(dataset.footer, r => footerRows.push(toExportRow(r)));

		const args = [
			{ 
				name: 'export',
				value: {
					name: this.props.name || this.props.id || 'export',
					columns: [],
					headerRows: headerRows,
					rows: rows,
					footerRows: footerRows
				}
			}
		];

		downloadFile('/api/export/excel', args);
	}

	onSaveSettings = () => {
		if (this.props.onSaveSettings) {
			this.props.onSaveSettings({
				filters: this.state.filters,
				search: this.state.searchText,
				rowsPerPage: this.state.rowsPerPage,
				sortBy: this.state.sortBy,
				sortDir: this.state.sortDir,
				pageNum: this.state.pageNum,
				selectedColumns: this.state.selectedColumns
			});
		}
	}
	onRestoreDefaultSettings = () => {
		const rowsPerPage = this.props.defaultRowsPerPage || 10;
		const totalRows = this.getTotalRows(this.props.data);
		const pageCount = rowsPerPage === 'All' ? 1 : Math.ceil(totalRows / rowsPerPage);

		this.setState({
			pageNum: 1,
			rowsPerPage: rowsPerPage,
			sortBy: this.props.defaultSortBy || null,
			sortDir: this.props.defaultSortDir || 'asc',
			searchText: '',
			filters: this.props.defaultFilters || [],
			pageCount: pageCount,
			selectedColumns: null 
		}, () => {
			if (this.props.onRestoreDefaultSettings) {
				this.props.onRestoreDefaultSettings();
			}
		});
	}

	getDataset = () => {
		const dataset = {};

		dataset.header = this.getDatasetHeader({ data: this.props.data });
		dataset.rows = this.getDatasetRows({ data: this.props.data });
		dataset.footer = this.getDatasetFooter({ data: this.props.data });

		return dataset;
	}
	getDatasetColumns = () => {
		const columns = [];
		if (!this.state.selectedColumns || this.state.selectedColumns.length === 0) {
			return filter(this.props.columns, c => !c.excludeFromGrid && !c.hideByDefault);
		}
		forEach(this.state.selectedColumns, c => {
			const col = find(this.props.columns, c2 => c2.name === c);
			if (col) {
				columns.push(col);
			}
		});
		return columns;
	}
	getDatasetHeader = (args) => {
		const columns = this.getDatasetColumns(args);
		const header = [];
		forEach(this.props.headerRows || [], r => {
			header.push(r);
		});
		const headerRow = { cells: [], className: 'sticky-header' };
		forEach(columns, (c) => {
			const headerStyle = {
				...defaultHeaderStyle,
				minWidth: c.minWidth ? c.minWidth : undefined,
				...c.headerStyle
			};
			if (this.state.sortBy === c.name) {
				headerStyle.color = infoColor;
			}

			const headerCellRenderer = c.headerRenderer ? c.headerRenderer :
				(rendererArgs) => 
					<div>
						<span>{rendererArgs.column.label}</span>
						{this.state.sortBy === rendererArgs.column.name && this.state.sortDir === 'asc'
							&& <ArrowUp />
						}
						{this.state.sortBy === rendererArgs.column.name && this.state.sortDir === 'desc'
							&& <ArrowDown />
						}
					</div>;

			headerRow.cells.push({
				onClick: () => this.sortColumn(c),
				style: headerStyle,
				className: c.headerClassName,
				value: c.label,
				formattedValue: c.label,
				fullLabel: c.fullLabel,
				column: c,
				cellRenderer: headerCellRenderer
			});
		});
		header.push(headerRow);

		return header;
	}
	getDatasetRow = (data, args) => {
		const columns = this.getDatasetColumns(args);
		const row = { className: 'data-table-row', data: data, cells: [] };

		let style = {
			height: '50px'
		};
		if (this.props.rowStyle) {
			const customRowStyle = this.props.rowStyle.call(this, data);
			style = { ...style, ...customRowStyle };
		}
		row.style = style;

		if (this.props.enableSelection) {
			if (!this.props.isRowSelectable || this.props.isRowSelectable(data)) {
				row.cells.push({
					style: {
						textAlign: 'center'
					},
					cellRenderer: (rendererArgs) => 
						<Checkbox
							checked={this.props.isRowSelected(rendererArgs.data)}
							onCheck={(e, v) => { 
								this.props.onSelectionChanged([rendererArgs.data], v); 
							} }
						/> 
				});
			} else {
				row.cells.push({
					cellRenderer: () => null
				});
			}
		}

		let skipCols = 0;
		for (let i = 0, ii = columns.length; i < ii; i++) {
			if (skipCols > 0) {
				skipCols -= 1;
			} else {
				const c = columns[i];
				let value;
				if (c.displayValue) {
					value = c.displayValue({ data });
				} else if (c.value) {
					value = c.value({ data });
				} else {
					value = data[c.name];
				}
				const formattedValue = this.getFormattedValue(c, value);
				const cellStyle = {
					paddingTop: '0px', 
					paddingBottom: '0px',
					textAlign: this.isNumericColumn(c) ? 'right' : 'left', 
					...isFunction(c.cellStyle) ? c.cellStyle({ value: value, formattedValue, data: data }) : c.cellStyle
				};
				let colspan;
				if (c.colspan) {
					colspan = c.colspan({ value: value, formattedValue, data: data });
					skipCols = colspan - 1;
				}
				
				row.cells.push({
					className: isFunction(c.cellClassName) ? c.cellClassName({ value: value, formattedValue, data: data }) : c.cellClassName,
					style: cellStyle, 
					formatString: c.formatString,
					colspan: colspan,
					value: value,
					formattedValue: formattedValue,
					cellRenderer: c.cellRenderer,
					tooltip: c.tooltip
				});
			}
		}
		return row;
	}
	getDatasetRows = (args, clearCache) => {
		// const rowsKey = {
		// 	filters: this.state.filters,
		// 	search: this.state.searchText,
		// 	sortBy: this.state.sortBy
		// };
		// if (!clearCache && isEqual(rowsKey, this._rowsKey)) {
		// 	return this._rows;
		// }

		const columns = this.getDatasetColumns(args);
		const rows = [];

		if (this.props.groupBy) {
			const groupedData = groupByArray(args.data, (d) => d[this.props.groupBy]);
			let nullGroupRows = [];

			forEach(groupedData, (g) => {
				if (g.key === null) {
					nullGroupRows = g.values;
				} else {
					const dataRows = this.getDisplayedRows(g.values);

					if (dataRows.length > 0) {
						const isCollapsed = this.isGroupCollapsed(g.key);
	
						let formattedGroup;
						if (this.props.groupByFormatter) {
							formattedGroup = this.props.groupByFormatter({ value: g.key });
						} else {
							formattedGroup = g.key;
						}
	
						const groupByStyle = {
							paddingLeft: '10px', 
							backgroundColor: '#F0F0F0',
							fontStyle: 'italic',
							fontWeight: 'bold',
							cursor: 'pointer'
						};
						
						const cells = [];
						if (this.props.enableSelection) {
							cells.push({
								style: {
									...groupByStyle,
									paddingLeft: undefined,
									textAlign: 'center'
								},
								cellRenderer: () => 
									<Checkbox
										checked={this.isAllSelected(dataRows)}
										onCheck={(e, v) => { this.props.onSelectionChanged(dataRows, v); } }
									/> 
							});
						}
	
	
						cells.push({
							colspan: columns.length,
							style: groupByStyle,
							value: formattedGroup,
							formattedValue: formattedGroup,
							onClick: () => {
								if (isCollapsed) {
									this.expandGroup(g.key);
								} else {
									this.collapseGroup(g.key);
								} 
							},
							cellRenderer: () =>
								<span>
									{isCollapsed ? 
										<IconButton 
											style={{ 
												padding: '2px', 
												height: '25px', 
												width: '25px',
												verticalAlign: 'top',
												margin: '-3px 5px 0px 0px'
											}}
										>
											<ExpandIcon />
										</IconButton>
										:
										<IconButton 
											style={{ 
												padding: '2px', 
												height: '25px', 
												width: '25px',
												verticalAlign: 'top',
												margin: '-3px 5px 0px 0px'
											}}
										>
											<CollapseIcon />
										</IconButton>
									}
									{formattedGroup}
								</span>
						});
						rows.push({
							cells: cells
						});
						
						if (!isCollapsed) {
							forEach(dataRows, data => {
								rows.push(this.getDatasetRow(data, args));
							});
						}
	
						const showGroupTotals = some(columns, (c) => c.includeInGroupTotals);
						if (showGroupTotals) {
							const cols = [];
							let colspan = 0;
	
							const groupTotalStyle = {
								fontWeight: 'bold'
							};
	
							forEach(reverse(columns.slice()), (c, index) => {	
								colspan += 1;
								if (index < columns.length - 1) {
									if (c.includeInGroupTotals) {
										let groupColTotal = 0;
										forEach(g.values, (r) => {
											groupColTotal += (c.value ? c.value({ data: r }) : r[c.name]) || 0;
										});
										const groupColTotalFormatted = this.getFormattedValue(c, groupColTotal);
										cols.push({
											colspan: colspan,
											style: isFunction(c.groupTotalStyle) ? {
													...groupTotalStyle,
													...c.groupTotalStyle({ value: groupColTotal, groupColTotalFormatted })
												} : groupTotalStyle,
											value: groupColTotalFormatted
										});
										colspan = 0;
									}
								} else {
									cols.push({
										colspan: colspan,
										style: groupTotalStyle,
										value: 'Total'
									});
								}
							});
							rows.push({
								cells: reverse(cols.slice())
							});
						}
					}
				}
			});

			// Add rows with a null group at the bottom outside of a group
			forEach(this.getDisplayedRows(nullGroupRows), data => {
				rows.push(this.getDatasetRow(data, args));
			});
		} else {
			forEach(this.getDisplayedRows(args.data), r => {
				rows.push(this.getDatasetRow(r, args));
			});
		}

		// this._rowsKey = rowsKey;
		// this._rows = rows;
		return rows;
	}
	getDatasetFooter = (args) => {
		const columns = this.getDatasetColumns(args);
		const footer = [];
		const hasTotals = some(columns, (c) => c.showTotal);
		if (hasTotals) {
			const footerRow = { className: 'table-totals-row', cells: [] };
			forEach(columns, (c) => {
				let total = '';
				let formattedTotal = '';
				if (c.showTotal) {
					total = 0;
					const displayedRows = this.getDisplayedRows(args.data);
					forEach(displayedRows, (d) => {
						total += round((c.value ? c.value({ data: d }) : d[c.name]), 2) || 0;
					});

					formattedTotal = this.getFormattedValue(c, total);
					if (displayedRows.length < args.data.length) {
						formattedTotal = 
							<span style={{ whiteSpace: 'nowrap' }}>
								<WarningIcon 
									style={{ 
										color: warningColour,
										marginLeft: '10px',
										marginTop: '-3px',
										height: '20px',
										width: '20px'
									}} 
								/>
								{formattedTotal}
							</span>;
					}
				}

				const totalStyle = {
					borderTop: '3px double #777',
					fontWeight: 'bold'
				};

				footerRow.cells.push({
					style: isFunction(c.totalStyle) ? {
							...totalStyle,
							...c.totalStyle({ value: total, formattedTotal })
						} : totalStyle,
					value: total,
					formattedValue: formattedTotal
				});
			});
			footer.push(footerRow);
		}
		forEach(this.props.footerRows || [], r => {
			footer.push(r);
		});
		return footer;
	}

	toggleFullscreen = () => {
		if (this.state.fullScreen) {
			this.setState({ fullScreen: false });
			document.body.classList.remove('data-table--full-screen__document-body');
		} else {
			this.setState({ fullScreen: true });
			document.body.classList.add('data-table--full-screen__document-body');
		}
	}

	isAllSelected = (rows) => {
		for (let i = 0, ii = rows.length; i < ii; i++) {
			const row = rows[i];
			if (!this.props.isRowSelectable || this.props.isRowSelectable(row)) {
				if (!this.props.isRowSelected(row)) return false;
			}
		}
		return true;
	}
	isGroupCollapsed = groupKey => {
		if (this.props.collapseGroupsByDefault) {
			return this.state.expandedGroups.indexOf(groupKey) === -1;
		} else {
			return this.state.collapsedGroups.indexOf(groupKey) > -1;
		}
	}
	collapseGroup = groupKey => {
		if (this.props.collapseGroupsByDefault) {
			const expandedGroups = this.state.expandedGroups;
			const index = expandedGroups.indexOf(groupKey);
			if (index > -1) {
				expandedGroups.splice(index, 1);
			}
			this.setState({ expandedGroups: expandedGroups });
		} else {
			const collapsedGroups = this.state.collapsedGroups;
			if (collapsedGroups.indexOf(groupKey) === -1) collapsedGroups.push(groupKey);
			this.setState({ collapsedGroups: collapsedGroups });
		}
	}
	expandGroup = groupKey => {
		if (this.props.collapseGroupsByDefault) {
			const expandedGroups = this.state.expandedGroups;
			if (expandedGroups.indexOf(groupKey) === -1) expandedGroups.push(groupKey);
			this.setState({ expandedGroups: expandedGroups });
		} else {
			const collapsedGroups = this.state.collapsedGroups;
			const index = collapsedGroups.indexOf(groupKey);
			if (index > -1) {
				collapsedGroups.splice(index, 1);
			}
			this.setState({ collapsedGroups: collapsedGroups });
		}
	} 

	onSelectColumns = () => {
		this.setState({ 
			showColumnSelector: true 
		});
	}

	render() {
		const startIndex = this.state.rowsPerPage === 'All' ? 0 : (this.state.pageNum - 1) * this.state.rowsPerPage;
		const endIndex = this.state.rowsPerPage === 'All' ? undefined : startIndex + this.state.rowsPerPage;
		const filterColumns = filter(this.props.columns, (c) => !c.excludeFromFilter);
		const showFilter = !this.props.hideFilter && filterColumns.length > 0;
		const dataset = this.getDataset();
        const dataTableClasses = classNames({
			'data-table': true,
			'data-table--full-screen': this.state.fullScreen
		});
		const pageCount = Math.max(1, this.state.rowsPerPage === 'All' ? 1 : Math.ceil(dataset.rows.length / this.state.rowsPerPage));
		const selectedColumns = map(this.getDatasetColumns(), c => c.name);

		const buildMenu = (closeMenu) => {
			const menuItems = [];
			menuItems.push(
				<MenuItem 
					key="select-columns"
					style={{ 
						color: this.state.selectedColumns && this.state.selectedColumns.length > 0 ? '#5bc0de' : 'inherit'
					}}
					onClick={this.onSelectColumns} 
				>Select Columns</MenuItem>
			);
			if (this.props.canExportToExcel) {
				menuItems.push(
					<MenuItem key="export-to-excel" onClick={this.exportToExcel}>Export to Excel</MenuItem>
				);
				menuItems.push(<Divider key="table-divider1" />);
			}
			menuItems.push(<MenuItem key="toggle-full-screen" onClick={this.toggleFullscreen}>{this.state.fullScreen ? 'Exit Full Screen' : 'Full Screen'}</MenuItem>);
			menuItems.push(<Divider key="table-divider2" />);
			menuItems.push(<MenuItem key="save-settings" onClick={this.onSaveSettings}>Save Current Settings</MenuItem>);
			menuItems.push(<MenuItem key="restore-default-settings" onClick={this.onRestoreDefaultSettings}>Restore Default Settings</MenuItem>);

			return menuItems;
		};

		return (
<div id={this.props.id || undefined} className={dataTableClasses} onClick={(e) => { this.onDataTableClick(e); }} style={this.props.style} role="navigation">
	<DataTableColumnSelector
		show={this.state.showColumnSelector}
		columns={this.props.columns}
		selectedColumns={selectedColumns}
		onOk={(newSelection) => 
			this.setState({ 
				selectedColumns: newSelection, 
				showColumnSelector: false 
			}, () => {
				if (this.props.onSelectColumns) {
					this.props.onSelectColumns({
						selectedColumns: newSelection
					});
				}
			})
		}
		onCancel={() => this.setState({ showColumnSelector: false })}
	/>

	{!this.props.hideActions && 
		<IconMenu
			style={{ 
				position: 'absolute', 
				right: '0', 
				top: '0'
			}}
			iconStyle={{ 
				color: this.state.selectedColumns && this.state.selectedColumns.length > 0 ? '#5bc0de' : 'inherit'
			}}
			icon={<MoreVertIcon />}
			items={buildMenu}
		/>
	}
	<div>
		{/* row containing the filter and search options */}
		{!this.props.hideSearch &&
			<div className="table-search-container">
				<FilterIcon 
					onClick={(e) => { this.showFilterDropdown(e, true); }} 
					style={{ 
						cursor: 'pointer', 
						marginTop: '10px', 
						marginRight: '10px', 
						color: this.state.filters && this.state.filters.length > 0 ? '#5bc0de' : 'inherit' 
					}} 
				/>
				<ActionSearch style={{ marginTop: '10px', marginRight: '10px', color: this.state.searchText ? '#5bc0de' : 'inherit' }} />
				<TextField
					autoComplete="off"
					hintText="Search"
					variant="standard"
					InputProps={{ disableUnderline: true }}
					value={this.state.searchText}
					style={{ width: 'calc(100% - 108px)', padding: '7px 0' }}
					onChange={(e, v) => { this.onSearch(v); }}
				/>
			</div>
		}
		{/* row displaying all of the filters applied */}
		{this.state.filters && this.state.filters.length > 0 &&  
			<div className="data-table-filters">
				<ul className="filters-list">
					{
						map(this.state.filters, (f, fi) => {
							const column = find(this.props.columns, (c) => c.name === f.column);

							return (
								<li className="filter" key={`filter-${fi}`}>
									<span className="filter-column">{column.fullLabel || column.label || column.name}</span>
									<span className="filter-operator">
										{(() => {
											switch (f.operator) {
												case 'multi-select':
													return 'Any Of';
												case 'includes':
													return 'Includes';
												default:
													return f.operator;
											}
										})()}
									</span>
									<span className="filter-value">
										{(() => {
											switch (f.operator) {
												case 'multi-select': {
													if (f.label) {
														return f.label;
													} else {
														return map(filter(column.filterOptions, (o) => f.value.indexOf(this.getFilterOptionsKey(o, column)) > -1), (o) => this.getFilterOptionsText(o, column)).join(',');
													}
												}
												case 'includes':
													return f.value[column.filterValue];
												default:
													return this.getFormattedValue(column, f.value);
											}
										})()}
									</span>
								</li>
							);
						})
					}
				</ul>
			</div>
		}
	</div>
	<div className="table-container" role="navigation" style={this.props.tableContainerStyles}>
		{showFilter && this.state.showFilterDropdown &&
			<DataTableFilterEditor columns={filterColumns} filters={this.state.filters} onApplyFilter={this.applyFilter} onCancelFilter={this.cancelFilter} />
		}
		<table className="full-bordered" style={{ width: '100%' }} ref={this.props.tableRef}>
			<thead>
				{
					map(dataset.header, (r, rowIndex) => 
						<tr key={`header-row-${rowIndex}`} className={r.className}>
							{this.props.enableSelection &&
								<th style={defaultHeaderStyle}>
									{this.props.canSelectAll && 
									<Checkbox
										checked={this.isAllSelected(this.getDisplayedRows(this.props.data))}
										onCheck={(e, v) => { this.props.onSelectionChanged(this.getDisplayedRows(this.props.data), v); } }
									/>
									}
								</th>
							}
							{
								map(r.cells, (c, cellIndex) => {
									let value;
									if (c.cellRenderer) {
										value = c.cellRenderer({ column: c.column, value: c.value });
									} else {
										value = c.formattedValue || c.value;
									}
									return (
										<th
											key={`header-${cellIndex}`}
											colSpan={c.colspan}
											rowSpan={c.rowspan}
											onClick={c.onClick}
											style={c.style}
											className={c.className}
										>
											{value}
										</th>
									);
								})
							}
						</tr>	
					)
				}
			</thead>
			<tbody style={this.props.tableBodyStyle}>
				{
					map(slice(dataset.rows, startIndex, endIndex), (r, i) =>
						<DataTableRow 
							key={`tr-${i}`}
							row={r}
						/>
					)
				}
			</tbody>
			<tfoot>
				{
					map(dataset.footer, (r, rowIndex) => 
						<tr key={`footer-row-${rowIndex}`} className={r.className}>
							{
								map(r.cells, (c, cellIndex) => {
									let value;
									if (c.cellRenderer) {
										value = c.cellRenderer({ value: c.value, formattedValue: c.formattedValue });
									} else {
										value = c.formattedValue || c.value;
									}

									return (
										<td
											key={`footer-${cellIndex}`}
											colSpan={c.colspan}
											onClick={c.onClick}
											style={c.style}
											className={c.className}
										>
											{value}
										</td>	
									);
								})
							}
						</tr>	
					)
				}
			</tfoot>
		</table>
	</div>
	{/* paging */}
	{!this.props.hidePaging &&
		<div className="data-table-paging">
			<div style={{ float: 'right' }}>
				<span style={{ verticalAlign: 'middle', display: 'inline-block' }}>Rows per page:</span>
				<SelectField
					value={this.state.rowsPerPage}
					onChange={this.changeRowsPerPage}
					disableUnderline={true}
					variant="standard"
					style={{ 
						width: '60px', 
						verticalAlign: 'middle', 
						marginLeft: '5px' 
					}}
					items={[
						{ value: 10, label: '10' },
						{ value: 50, label: '50' },
						{ value: 100, label: '100' },
						{ value: 200, label: '200' },
						{ value: 'All', label: 'All' }
					]}
				>
				</SelectField>
				<span style={{ verticalAlign: 'middle', display: 'inline-block' }}>{`Page ${this.state.pageNum} of ${pageCount}`}</span>
				<IconButton onClick={ () => { this.firstPage(); } }>
					<FirstPage />
				</IconButton>
				<IconButton onClick={ () => { this.prevPage(); } }>
					<LeftArrow />
				</IconButton>
				<IconButton onClick={ () => { this.nextPage(); } }>
					<RightArrow />
				</IconButton>
				<IconButton onClick={ () => { this.lastPage(); } }>
					<LastPage />
				</IconButton>
			</div>
		</div>
	}
</div>
		);
	}
}

DataTable.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	emptyText: PropTypes.string,
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	
	enableSorting: PropTypes.bool,
	hideActions: PropTypes.bool,
	hideSearch: PropTypes.bool,
	hideFilter: PropTypes.bool,
	hidePaging: PropTypes.bool,
	enableSelection: PropTypes.bool,
	canSelectAll: PropTypes.bool,
	defaultSortBy: PropTypes.string,
	defaultSortDir: PropTypes.string,
	defaultFilters: PropTypes.array,
	defaultRowsPerPage: PropTypes.any,
	sortBy: PropTypes.string,
	sortDir: PropTypes.string,
	rowsPerPage: PropTypes.any,
	pageNum: PropTypes.number,
	search: PropTypes.string,
	filters: PropTypes.array,
	canExportToExcel: PropTypes.bool,
	selectedColumns: PropTypes.array,

	groupBy: PropTypes.string,
	groupByFormatter: PropTypes.func,
	collapseGroupsByDefault: PropTypes.bool,

	headerRows: PropTypes.array,
	footerRows: PropTypes.array,

	// Styles
	style: PropTypes.object,
	rowStyle: PropTypes.func,
	tableContainerStyles: PropTypes.object,
	tableBodyStyle: PropTypes.object,

	// Events
	onChangeRowsPerPage: PropTypes.func,
	onChangeSorting: PropTypes.func,
	onChangePageNumber: PropTypes.func,
	onChangeSearch: PropTypes.func,
	onChangeFilters: PropTypes.func,
	onSaveSettings: PropTypes.func,
	onRestoreDefaultSettings: PropTypes.func,
	onSelectionChanged: PropTypes.func,
	isRowSelected: PropTypes.func,
	isRowSelectable: PropTypes.func,
	onSelectColumns: PropTypes.func
};

DataTable.defaultProps = {
	id: '',
	name: '',
	emptyText: '',

	enableSorting: true,
	hideActions: false,
	hideSearch: false,
	hideFilter: false,
	hidePaging: false,
	enableSelection: false,
	canSelectAll: true,

	defaultSortBy: null,
	defaultSortDir: 'asc',
	defaultFilters: null,
	defaultRowsPerPage: 10,

	sortBy: undefined,
	sortDir: undefined,
	rowsPerPage: undefined,
	pageNum: undefined,
	search: undefined,
	filters: undefined,
	selectedColumns: undefined,

	canExportToExcel: true,

	groupBy: undefined,
	groupByFormatter: undefined,
	collapseGroupsByDefault: false,

	headerRows: undefined,
	footerRows: undefined,
	style: undefined,
	rowStyle: undefined,
	tableContainerStyles: undefined,
	tableBodyStyle: undefined,

	onChangeRowsPerPage: undefined,
	onChangeSorting: undefined,
	onChangePageNumber: undefined,
	onChangeSearch: undefined,
	onChangeFilters: undefined,
	onSaveSettings: undefined,
	onRestoreDefaultSettings: undefined,
	onSelectionChanged: undefined,
	isRowSelected: undefined,
	isRowSelectable: undefined,
	onSelectColumns: undefined
};


export default DataTable;
