import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import RegistersGrid from './registersGrid';
import { requestRegisterDefinition } from '../../reducers/registerDefinitions';
import { deleteRegister, requestRegisters, clearRegisters } from '../../reducers/registers';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PageLayout from '../pageLayout';
import { canEdit } from '../../selectors/canEdit';
import { canView } from '../../selectors/canView';
import filter from 'lodash/filter';
import { get } from '../../utils/ajax';
import { getContractId } from '../../selectors/contract';

class Registers extends Component {
    constructor(props) {
        super(props);

        this.state = {
			registers: {},
			tags: []
        };

		this.onEditRegister = this.onEditRegister.bind(this);
		this.onDeleteRegister = this.onDeleteRegister.bind(this);
		this.onViewRegister = this.onViewRegister.bind(this);
		this.newRegister = this.newRegister.bind(this);
    }
	static fetchData(dispatch, props) {
		dispatch(requestRegisterDefinition(props.match.params.registerDefinitionId));
		dispatch(requestRegisters(props.match.params.registerDefinitionId));
	}
	static clearData(dispatch) {
		dispatch(clearRegisters());
	}
	componentDidMount() {
		Registers.fetchData(this.props.dispatch, this.props);

		get({
			url:`/api/registers/tags?contractId=${this.props.contractId}&registerDefinitionId=${this.props.match.params.registerDefinitionId}`,
			onSuccess: data => {
				this.setState(prevState => ({
					...prevState,
					tags: data
				}));
			},
			onError: error => {
			}
		});
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.match.params.registerDefinitionId !== nextProps.match.params.registerDefinitionId) {
			Registers.fetchData(nextProps.dispatch, nextProps);
		}
		if (nextProps.registers && (nextProps.registerDefinition !== this.props.registerDefinition || nextProps.registers !== this.props.registers)) {
			this.setState(() => {
				const registers = nextProps.registers;
				for (let i = 0; i < nextProps.registerDefinition.fields.length; i++) {
					const field = nextProps.registerDefinition.fields[i];
					if (field.fieldType === "Tags") {

						for (let j = 0; j < registers.length; j++) {
							const register = registers[j];
							let tags = filter(
								register.tags, t => t.registerFieldId === field.registerFieldId
							);
							register.values[field.name] = tags;
						}
					}
				}
				return registers;
			});
		}
	}

	onEditRegister = (register) => {
		this.props.history.push(`/register/${this.props.match.params.registerDefinitionId}/${register.registerId}`);
	}

	onViewRegister = (register) => {
		this.props.history.push(`/register/${this.props.match.params.registerDefinitionId}/${register.registerId}`);
	}

	onDeleteRegister(register) {
		this.props.deleteRegister(register);
	}

	newRegister = () => {
		this.props.history.push(`/register/${this.props.match.params.registerDefinitionId}`);
	}

	render() {
		const definition = this.props.registerDefinition || { fields: [] };

		return (
<PageLayout
	title={definition.name}
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: definition && definition.name ? `New ${definition.name}` : 'New',
				onClick: this.newRegister
			}
		] : []
	}
	content={
		<ContentContainer>
			<RegistersGrid 
				registers={this.props.registers} 
				registerDefinition={definition} 
				onEditRegister={this.onEditRegister} 
				onDeleteRegister={this.onDeleteRegister} 
				onViewRegister={this.onViewRegister} 
				canView={this.props.canView}
				canEdit={this.props.canEdit}
				tags={this.state.tags}
			/>
		</ContentContainer>
	} 
/>
		);
	}
}

Registers.propTypes = {
	params: PropTypes.shape({
		registerDefinitionId: PropTypes.node
	}),
	dispatch: PropTypes.func.isRequired,
	registerDefinition: PropTypes.object,
	registers: PropTypes.arrayOf(PropTypes.object),
	deleteRegister: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	contractId: PropTypes.number.isRequired
};

Registers.defaultProps = {
	registerDefinition: null,
	registers: [],
	params: {
		
	}
};

const mapStateToProps = (state) => ({
	registers: state.registers.registers,
	registerDefinition: state.registerDefinitions.registerDefinition,
	isLoading: state.registers.isLoading,
	canView: canView(state, 'ViewRegisters'),
	canEdit: canEdit(state, 'EditRegisters'),
	contractId: getContractId(state)
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteRegister: (register) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Register?',
			onOk: () => {
				dispatch(deleteRegister(register));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Registers);
