import React from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import map from 'lodash/map';

class LotStatusByLotReportContent extends React.Component {
	render() {
		const report = this.props.report || { lots: [] };
		const openLots = filter(report.lots, (l) => l.status === 'Open');
		const guaranteedLots = filter(report.lots, (l) => l.status === 'Guaranteed');
		const guaranteedGreater1MonthLots = filter(report.lots, (l) => l.status === 'Guaranteed>1Month');
		const closedLots = filter(report.lots, (l) => l.status === 'Closed');

		return (
		<div>
			<div>
				<div className="kpi-numeric">
					<span className="kpi-label">Open</span>
					<span className="kpi-value">{openLots.length}</span>
				</div>

				<div className="kpi-numeric" style={{ color: '#35aa47' }}>
					<span className="kpi-label">Guaranteed</span>
					<span className="kpi-value">{guaranteedLots.length}</span>
				</div>

				<div className="kpi-numeric" style={{ color: '#d84a38', width: '170px' }}>
					<span className="kpi-label">Guaranteed &gt; 1 Month</span>
					<span className="kpi-value">{guaranteedGreater1MonthLots.length}</span>
				</div>

				<div className="kpi-numeric" style={{ color: '#35aa47' }}>
					<span className="kpi-label">Closed</span>
					<span className="kpi-value">{closedLots.length}</span>
				</div>

				<div className="kpi-numeric">
					<span className="kpi-label">Total</span>
					<span className="kpi-value">{report.lots ? report.lots.length : 0}</span>
				</div>
			</div>

			<div className="lots-data">
				{
					map([openLots, guaranteedLots, guaranteedGreater1MonthLots, closedLots], (lots, index) => {
						let header = '';
						switch (index) {
							case 0: header = 'Open Lots'; break;
							case 1: header = 'Guaranteed Lots'; break;
							case 2: header = 'Guaranteed > 1 Month Lots'; break;
							case 3: header = 'Closed Lots'; break;
							default:
						}
						return (
							<div>
								<div className="form-section-header">{header}</div>
								<table className="report-table">
									<thead>	
										<tr>
											<th>Lot No</th>
											<th style={{ minWidth: '200px' }}>Description</th>
											<th>Status</th>
											<th>Period Opened</th>
											<th>Period Guaranteed</th>
											<th>Period Closed</th>
											<th>Comments</th>
										</tr>
									</thead>
									<tbody>
										{
											(() => {
												const rows = [];

												forEach(lots, (l) => {
													let statusBackColour = null;
													let statusForeColour = null;
													switch (l.status) {
														case 'Guaranteed':
															statusBackColour = '#f0ad4e';
															statusForeColour = '#222';
															break;
														case 'Guaranteed>1Month':
															statusBackColour = '#FFC7CE';
															statusForeColour = '#9C0006';
															break;
														case 'Closed':
															statusBackColour = '#C6EFCE';
															statusForeColour = '#2C6153';
															break;
														default:
													}
													rows.push(
														<tr>
															<td>{l.lotNo}</td>
															<td>{l.lotDescription}</td>
															<td style={{ backgroundColor: statusBackColour, color: statusForeColour }}>{l.status}</td>
															<td>{l.periodOpened}</td>
															<td>{l.periodGuaranteed}</td>
															<td>{l.periodClosed}</td>
															<td>{l.comments}</td>
														</tr>
													);
												});
													
												return rows;
											})()
										}
									</tbody>
								</table>
							</div>
						);
					})
				}
			</div>
		</div>
    );
  }
}

LotStatusByLotReportContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default LotStatusByLotReportContent;
