import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import SelectField from '../widgets/selectField';
import DateField from '../widgets/DateField';
import find from 'lodash/find';
import map from 'lodash/map';
import AutoComplete from '../widgets/AutoComplete';
import MessagePanel from '../messagePanel';
import NumericField from '../widgets/numericField';
import FormFieldContainer from '../formFieldContainer';
import ContentContainer from '../contentContainer';
import PageLayout from '../pageLayout';
import EmployeeSelect from '../widgets/employeeSelect';

class ContractForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			contract: props.contract,
			hasUnsavedChanges: false
		};
	}
	
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			contract: nextProps.contract,
			hasUnsavedChanges: false
		});
	}

	updateFieldValue(field, newValue) {
		const contract = this.state.contract;
		contract[field] = newValue;
        this.setState({
			contract,
			hasUnsavedChanges: true
        });
	}

	render() {
		const contract = this.state.contract || {};

		return (
<PageLayout
	title={
		<span>
			{contract.contractNumber}
			<small> {contract.contractName}</small>
		</span>
	}
	isLoading={this.props.isLoading}
	formActions={{
		isLoading: this.props.isLoading,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.onSave(this.state.contract),
		onSaveAndContinue: () => this.props.onSaveAndContinue(this.state.contract),
		onCancel: () => this.props.onCancel() 
	}}
	content={
		<div className="row">
			<ContentContainer>
				<div className="form-section-header">Contract Details</div>

				<FormFieldContainer classes={['s12']}>
					<TextField
						value={contract.contractNumber}
						onChange={(e) => { this.updateFieldValue('contractNumber', e.target.value); } }
						label="Contract Number"
						errorText={getFieldErrorText(this.props.saveResult, 'ContractNumber')}
						fullWidth={true}
						autoFocus={true}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12']}>
					<TextField
						value={contract.contractName}
						onChange={(e) => { this.updateFieldValue('contractName', e.target.value); } }
						label="Contract Name"
						errorText={getFieldErrorText(this.props.saveResult, 'ContractName')}
						fullWidth={true}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
					<SelectField 
						disabled={true}
						value={contract.contractStatus} 
						label="Contract Status" 
						onChange={(v) => { this.updateFieldValue('contractStatus', v); } } 
						fullWidth={true}
						items={[
							{ value: 'Preliminary', label: 'Preliminary' },
							{ value: 'In Construction', label: 'In Construction' },
							{ value: 'Pending Defects', label: 'Pending Defects' },
							{ value: 'On Maintenance', label: 'On Maintenance' },
							{ value: 'Complete', label: 'Complete' }
						]}
					>
					</SelectField>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
						<DateField 
							value={contract.dateCommenced} 
							label="Date Commenced" 
							onChange={(d) => { this.updateFieldValue('dateCommenced', d); } }
							fullWidth={true}
						/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12']}>
					<AutoComplete 
						label="Contractor"
						fullWidth={true}
						onChange={(event, newValue) => {
							this.updateFieldValue('contractorId', newValue.value); 
						}}
						value={contract.contractorId}
						options={map(this.props.contractors, c => ({ label: c.name, value: c.contractorId }))}
						errorText={getFieldErrorText(this.props.saveResult, 'ContractorId')}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12']}>
					<EmployeeSelect 
						employees={this.props.employees}
						value={contract.contractAdministrator} 
						label="Administrator"
						onChange={(v) => { this.updateFieldValue('contractAdministrator', v); } } 
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12']}>
					<TextField
						value={contract.clientName}
						onChange={(e) => { this.updateFieldValue('clientName', e.target.value); } }
						label="Client's Name"
						errorText={getFieldErrorText(this.props.saveResult, 'ClientName')}
						fullWidth={true}
					/>
				</FormFieldContainer>


				<FormFieldContainer classes={['s12', 'm6']}>
					<DateField
						value={contract.practicalCompletionDate} 
						label="Practical Completion" 
						onChange={(d) => { this.updateFieldValue('practicalCompletionDate', d); } }
						fullWidth={true}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
					<NumericField
						type="int" 
						value={contract.defectsLiabilityPeriod} 
						label="Defects Liability Period" 
						onChange={(e, v) => { this.updateFieldValue('defectsLiabilityPeriod', v); }}
						fullWidth={true}
					/>
				</FormFieldContainer>
				
				<FormFieldContainer classes={['s12']}>
					<NumericField
						type="currency"
						fullWidth={true}
						label="Original Tender (Calculated)" 
						value={contract.scheduleTenderAmount} 
						disabled={true}
					/>
				</FormFieldContainer>

				<div className="form-section-header">Liquidated Damages</div>

				<FormFieldContainer classes={['s12', 'm6']}>
					<DateField 
						value={contract.liquidatedDamagesAppliesFrom} 
						label="Applies From" 
						onChange={(d) => { this.updateFieldValue('liquidatedDamagesAppliesFrom', d); } }
						error={getFieldErrorText(this.props.saveResult, 'LiquidatedDamagesAppliesFrom')}
						fullWidth={true}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
					<NumericField
						type="currency"
						fullWidth={true}
						label="Amount Per Day" 
						value={contract.liquidatedDamagesAmountPerDay} 
						errorText={getFieldErrorText(this.props.saveResult, 'LiquidatedDamagesAmountPerDay')}
						onChange={(v) => { this.updateFieldValue('liquidatedDamagesAmountPerDay', v); }}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12']}>
					<TextField
						value={contract.orderNumber}
						onChange={(e) => { this.updateFieldValue('orderNumber', e.target.value); } }
						label="Order Number"
						errorText={getFieldErrorText(this.props.saveResult, 'OrderNumber')}
						fullWidth={true}
					/>
				</FormFieldContainer>

				<div className="form-section-header">Prefixes</div>

				<FormFieldContainer classes={['s12', 'm6']}>
					<TextField
						value={contract.variationPrefix}
						onChange={(e) => { this.updateFieldValue('variationPrefix', e.target.value); } }
						label="Variation Prefix"
						errorText={getFieldErrorText(this.props.saveResult, 'variationPrefix')}
						fullWidth={true}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
					<NumericField
						fullWidth={true}
						label="Variation Number Significant Digits" 
						value={contract.variationNumberSignificantDigits} 
						errorText={getFieldErrorText(this.props.saveResult, 'VariationNumberSignificantDigits')}
						onChange={(v) => { this.updateFieldValue('variationNumberSignificantDigits', v); }}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
					<TextField
						value={contract.incomingCorrespondencePrefix}
						onChange={(e) => { this.updateFieldValue('incomingCorrespondencePrefix', e.target.value); } }
						label="Incoming Correspondence Prefix"
						errorText={getFieldErrorText(this.props.saveResult, 'IncomingCorrespondencePrefix')}
						fullWidth={true}
					/>
				</FormFieldContainer>
				<FormFieldContainer classes={['s12', 'm6']}>
					<NumericField
						fullWidth={true}
						label="Incoming Correspondence Significant Digits" 
						value={contract.incomingCorrespondenceNumberSignificantDigits} 
						errorText={getFieldErrorText(this.props.saveResult, 'IncomingCorrespondenceNumberSignificantDigits')}
						onChange={(v) => { this.updateFieldValue('incomingCorrespondenceNumberSignificantDigits', v); }}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
					<TextField
						value={contract.outgoingCorrespondencePrefix}
						onChange={(e) => { this.updateFieldValue('outgoingCorrespondencePrefix', e.target.value); } }
						label="Outgoing Correspondence Prefix"
						errorText={getFieldErrorText(this.props.saveResult, 'OutgoingCorrespondencePrefix')}
						fullWidth={true}
					/>
				</FormFieldContainer>
				<FormFieldContainer classes={['s12', 'm6']}>
					<NumericField
						fullWidth={true}
						label="Outgoing Correspondence Significant Digits" 
						value={contract.outgoingCorrespondenceNumberSignificantDigits} 
						errorText={getFieldErrorText(this.props.saveResult, 'OutgoingCorrespondenceNumberSignificantDigits')}
						onChange={(v) => { this.updateFieldValue('outgoingCorrespondenceNumberSignificantDigits', v); }}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
					<TextField
						value={contract.principalCorrespondencePrefix}
						onChange={(e) => { this.updateFieldValue('principalCorrespondencePrefix', e.target.value); } }
						label="Principal Correspondence Prefix"
						errorText={getFieldErrorText(this.props.saveResult, 'PrincipalCorrespondencePrefix')}
						fullWidth={true}
					/>
				</FormFieldContainer>
				<FormFieldContainer classes={['s12', 'm6']}>
					<NumericField
						fullWidth={true}
						label="Principal Correspondence Significant Digits" 
						value={contract.principalCorrespondenceNumberSignificantDigits} 
						errorText={getFieldErrorText(this.props.saveResult, 'PrincipalCorrespondenceNumberSignificantDigits')}
						onChange={(v) => { this.updateFieldValue('principalCorrespondenceNumberSignificantDigits', v); }}
					/>
				</FormFieldContainer>

				<FormFieldContainer classes={['s12', 'm6']}>
					<TextField
						value={contract.designCorrespondencePrefix}
						onChange={(e) => { this.updateFieldValue('designCorrespondencePrefix', e.target.value); } }
						label="Design Correspondence Prefix"
						errorText={getFieldErrorText(this.props.saveResult, 'DesignCorrespondencePrefix')}
						fullWidth={true}
					/>
				</FormFieldContainer>
				<FormFieldContainer classes={['s12', 'm6']}>
					<NumericField
						fullWidth={true}
						label="Design Correspondence Significant Digits" 
						value={contract.designCorrespondenceNumberSignificantDigits} 
						errorText={getFieldErrorText(this.props.saveResult, 'DesignCorrespondenceNumberSignificantDigits')}
						onChange={(v) => { this.updateFieldValue('designCorrespondenceNumberSignificantDigits', v); }}
					/>
				</FormFieldContainer>
			</ContentContainer>

			<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}


ContractForm.propTypes = {
	contract: PropTypes.object.isRequired,
	contractors: PropTypes.array,
	employees: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onSaveAndContinue: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

ContractForm.defaultProps = {
	contractors: []
};

export default ContractForm;
