import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveSeparablePortion, 
	clearSeparablePortion, 
	requestSeparablePortion, 
	createNewSeparablePortion 
} from '../../reducers/separablePortions';
import SeparablePortionForm from './separablePortionForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { requestScheduleOfRates } from '../../reducers/scheduleOfRates';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class SeparablePortionMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			separablePortion: props.separablePortion,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestScheduleOfRates());
		if (props.match.params.separablePortionId) {
			dispatch(requestSeparablePortion(props.match.params.separablePortionId));
		} else {
			dispatch(createNewSeparablePortion());
		}
	}
	static clearData(dispatch) {
		dispatch(clearSeparablePortion());
	}
	componentDidMount() {
		SeparablePortionMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			separablePortion: nextProps.separablePortion,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const separablePortion = this.state.separablePortion;
		separablePortion[field] = newValue;
        this.setState({
			separablePortion,
			hasUnsavedChanges: true
        });
	}

	render() {
		const separablePortion =  this.state.separablePortion || {};

		return (
<PageLayout
	title={separablePortion.name}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.separablePortion),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.separablePortion),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<SeparablePortionForm 
			separablePortion={separablePortion} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			schedules={this.props.schedules}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

SeparablePortionMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	separablePortion: PropTypes.object.isRequired,
	schedules: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	separablePortion: state.separablePortions.separablePortion,
	schedules: state.scheduleOfRates.scheduleOfRates,
	canEdit: canEdit(state, 'EditSeparablePortions'),
	isLoading: state.separablePortions.isLoading,
	saveResult: state.separablePortions.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (separablePortion) => {
		dispatch(saveSeparablePortion(separablePortion, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (separablePortion) => {
		dispatch(saveSeparablePortion(separablePortion, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(SeparablePortionMaintenance);
