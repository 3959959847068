import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import forEach from 'lodash/forEach';
import PersistedDataTable from '../widgets/persistedDataTable';
import Checkbox from '../widgets/Checkbox';
import find from 'lodash/find';

class UserRoleDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		const userContractRoles = props.userContractRoles || {};
		const roles = props.roles;
		forEach(roles, (r) => {
			const contractRole = find(userContractRoles.roleIds, (r2) => r2 === r.roleId);
			r._isSelected = !!contractRole;
		});
		this.state = {
			roles: roles
		};

		this.selectRole = this.selectRole.bind(this);
		this.updateRoles = this.updateRoles.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		const userContractRoles = nextProps.userContractRoles || {};
		const roles = nextProps.roles;
		forEach(roles, (r) => {
			const contractRole = find(userContractRoles.roleIds, (r2) => r2	 === r.roleId);
			r._isSelected = !!contractRole;
		});
		this.setState({
			roles: roles
		});
	}
	updateRoles() {
		const selectedRoles = [];
		forEach(this.state.roles, (r) => {
			if (r._isSelected) {
				selectedRoles.push(r);
			}
		});
		this.props.updateRoles(this.props.userContractRoles, selectedRoles);
	}
	selectRole(role, selected) {
		forEach(this.state.roles, (r) => {
			if (r.roleId === role.roleId) {
				r._isSelected = selected;
			}
		});
		this.setState({
			roles: this.state.roles
		});
	}
	isAllSelected = () => {
		for (let i = 0, ii = this.state.roles.length; i < ii; i++) {
			const role = this.state.roles[i];
			if (role._isSelected !== true) return false;
		}
		return true;
	}
	toggleAll = () => {
		const nextValue = !this.isAllSelected();
		forEach(this.state.roles, (r) => {
			r._isSelected = nextValue;
		});
		this.setState({
			roles: this.state.roles
		});
	}

	render() {
		const roles = this.state.roles || [];
		const userContractRoles = this.props.userContractRoles || {};

		const columns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected()}
						onCheck={() => { this.toggleAll(); } }
					/>,
				value: (args) => args.data._isSelected || false,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectRole(args.data, v); } }
					/> 
			},
			{ name: 'name', label: 'Name' }
		];

		return (
	<Dialog
		title="Roles"
		actions={
			<React.Fragment>
				<Button
					label="Cancel"
					primary={false}
					onClick={this.props.cancel}
				/>
				<Button
					label="Ok"
					primary={true}
					onClick={() => this.updateRoles()}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.cancel}
		scroll='paper'
		fullWidth={true}
		maxWidth='lg'
	>
		<p>Select the roles that the user belongs to for {userContractRoles.contractName}</p>

		<PersistedDataTable 
			id="userRoles"
			data={roles || []} 
			columns={columns}
		/>
	</Dialog>
		);
	}
}

UserRoleDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	updateRoles: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	roles: PropTypes.array.isRequired,
	userContractRoles: PropTypes.object
};

UserRoleDialog.defaultProps = {
	userContractRoles: []
};

export default UserRoleDialog;
