import { fetch, addTask } from 'domain-task';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import { addOrUpdate } from '../utils/utils';

const emptySchedule = {
	'scheduleOfRatesId': 0,
	'name': '',
	'order': 0,
	'active': true,
	'tendered': false,
	'canBeUsedInSeparablePortions': false,
	'items': []
};

const initialState = {
	scheduleOfRates: null,
	schedule: null, // The current schedule being edited
	isLoading: false,
	result: {
		success: true,
		message: null,
		errors: []
	}
};

// Constant defining the action type
const CLEAR_SCHEDULES = 'CLEAR_SCHEDULES';
const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE';
const ADD_SOR = 'ADD_SOR';
const REQUEST_SOR = 'REQUEST_SOR';
const RECEIVE_SOR = 'RECEIVE_SOR';
const IMPORT_SOR = 'IMPORT_SOR';
const RECEIVE_IMPORT_SOR_RESPONSE = 'RECEIVE_IMPORT_SOR_RESPONSE';
const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
const RECEIVE_DELETE_SCHEDULE_RESPONSE = 'RECEIVE_DELETE_SCHEDULE_RESPONSE';
const DELETE_SCHEDULE_ITEM = 'DELETE_SCHEDULE_ITEM';
const RECEIVE_DELETE_SCHEDULE_ITEM_RESPONSE = 'RECEIVE_DELETE_SCHEDULE_ITEM_RESPONSE';
const CREATE_NEW_SCHEDULE = 'CREATE_NEW_SCHEDULE';
const SAVE_SCHEDULE = 'SAVE_SCHEDULE';
const RECEIVE_SAVE_SCHEDULE_RESPONSE = 'RECEIVE_SAVE_SCHEDULE_RESPONSE';
const SHOW_SCHEDULE_ERRORS = 'SHOW_SCHEDULE_ERRORS';
const REQUEST_SCHEDULE = 'REQUEST_SCHEDULE';
const RECEIVE_SCHEDULE = 'RECEIVE_SCHEDULE';
const RECEIVE_ERROR_RESPONSE = 'RECEIVE_ERROR_RESPONSE';

export const clearSchedules = () => ({ type: CLEAR_SCHEDULES });
export const clearSchedule = () => ({ type: CLEAR_SCHEDULE });
export const addSoR = () => ({ type: ADD_SOR });
export const receiveScheduleOfRates = (data) => ({ type: RECEIVE_SOR, payload: { data } });
export const receiveImportSORResponse = (data) => ({ type: RECEIVE_IMPORT_SOR_RESPONSE, data });
export const receiveDeleteScheduleResponse = (data) => ({ type: RECEIVE_DELETE_SCHEDULE_RESPONSE, data });
export const receiveDeleteScheduleItemResponse = (data, originalItem) => ({ type: RECEIVE_DELETE_SCHEDULE_ITEM_RESPONSE, data, originalItem });
export const receiveSaveScheduleResponse = (data) => ({ type: RECEIVE_SAVE_SCHEDULE_RESPONSE, data });
export const receiveSchedule = (data) => ({ type: RECEIVE_SCHEDULE, data });
export const receiveErrorResponse = (error) => ({ type: RECEIVE_ERROR_RESPONSE, error });

export const requestSchedule = (scheduleId) => (
	createFetchAction({
		objectName: 'Schedule of Rates',
		url: `/api/schedule-of-rates/${scheduleId}`,
		passContext: true,
		startAction: REQUEST_SCHEDULE,
		success: (data) => 
			receiveSchedule(data)
	})
);

export const requestScheduleOfRates = () => (
	createFetchAction({
		objectName: 'Schedule of Rates',
		url: '/api/schedule-of-rates',
		passContext: true,
		startAction: REQUEST_SOR,
		success: (data) => 
			receiveScheduleOfRates(data)
	})
);

export const importScheduleOfRates = (importFile, scheduleOfRatesName, onSuccess) =>
	(dispatch, getState) => {
		const state = getState();
		const contractId = state.context.contract.contractId;
		const periodId = state.context.period ? state.context.period.periodId : null;
		const formData = new FormData();
		formData.append('File', importFile);
		formData.append('Name', scheduleOfRatesName);
		formData.append('ContractId', contractId);
		formData.append('PeriodId', periodId);

		const fetchTask = fetch('/api/schedule-of-rates/import', 
			{
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				},
				body: formData
			}
		).then(response => {
			if (response.status >= 200 && response.status < 300) return response.json();

			const error = new Error(response.statusText);
			error.response = response;
			throw error;
		})
		.then((data) => {
			dispatch(receiveImportSORResponse(data));
			if (data.success) dispatch(requestScheduleOfRates());
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}).catch((error) => {
			console.log('request failed', error);
		});

		addTask(fetchTask);
		dispatch({ type: IMPORT_SOR, payload: null });
	};

export const deleteSchedule = (schedule) => (
	createPostAction({
		passContext: true,
		url: `/api/schedule-of-rates/${schedule.scheduleOfRatesId}/delete`,
		data: schedule,
		startAction: DELETE_SCHEDULE,
		success: (data) => receiveDeleteScheduleResponse(data)
	})
);

export const deleteScheduleItem = (item) => (
	createPostAction({
		passContext: true,
		url: `/api/schedule-of-rates/${item.scheduleOfRatesItemId}/delete-item`,
		data: item,
		startAction: DELETE_SCHEDULE_ITEM,
		success: (data) => receiveDeleteScheduleItemResponse(data, item)
	})
);

export const createNewSchedule = () =>
	(dispatch, getState) => {
		const newSchedule = {
			...cloneDeep(emptySchedule)
		};
		const state = getState();
		if (state && state.context && state.context.contract && state.context.contract.contractStatus === 'Preliminary') {
			newSchedule.canBeUsedInSeparablePortions = true;
		}
		dispatch({ type: CREATE_NEW_SCHEDULE, newSchedule: newSchedule });
	};

const validateSchedule = (schedule) => {
	const errors = [];
	if (!schedule.name) {
		errors.push({
			fieldName: 'Name',
			valid: false,
			message: 'A name is required for the Schedule of Rates'
		});
	}
	return errors;
};

export const saveSchedule = (schedule, onSuccess) => {
	const errors = validateSchedule(schedule);
	if (errors.length > 0) return { type: SHOW_SCHEDULE_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/schedule-of-rates',
		data: schedule,
		startAction: SAVE_SCHEDULE,
		onError: (response) => receiveErrorResponse(response),
		success: (data, dispatch) => {
			dispatch(receiveSaveScheduleResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

const REORDER_SCHEDULES = 'REORDER_SCHEDULES';
const REORDER_SCHEDULES_RESPONSE = 'REORDER_SCHEDULES_RESPONSE';
export const receiveReorderScheduleResponse = (data) => ({ type: REORDER_SCHEDULES_RESPONSE, data });
export const reorderSchedules = (sourceSchedule, destinationSchedule, onSuccess) =>
	createPostAction({
		passContext: true,
		url: '/api/schedule-of-rates/reorder',
		data: {
			sourceScheduleOfRatesId: sourceSchedule.scheduleOfRatesId,
			sourceOrder: sourceSchedule.order,
			destinationScheduleOfRatesId: destinationSchedule.scheduleOfRatesId,
			destinationOrder: destinationSchedule.order
		},
		startAction: REORDER_SCHEDULES,
		onError: (response) => receiveErrorResponse(response),
		success: (data, dispatch) => {
			dispatch(receiveReorderScheduleResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_SCHEDULES:
			return {
				...state,
				scheduleOfRates: null
			};
		case CLEAR_SCHEDULE:
			return {
				...state,
				schedule: null
			};
		case RECEIVE_ERROR_RESPONSE:
			console.log('RECEIVE_ERROR_RESPONSE - scheduleOfRates');
			return {
				...state,
				isLoading: false,
				result: {
					success: false,
					message: action.error.message,
					errors: [] 
				}
			};
		case ADD_SOR:
			return { 
				...state,
				sor: state.sor.concat(action.sor) 
			};
		case REQUEST_SOR:
			return {
				...state,
				isLoading: true,
				result: {
					success: null,
					message: null,
					errors: [] 
				},
				scheduleOfRates: []
			};
		case RECEIVE_SOR:
			return {
				...state,
				isLoading: false,
				scheduleOfRates: action.payload.data
			};
		case REQUEST_SCHEDULE:
			return {
				...state,
				isLoading: true,
				result: {
					success: null,
					message: null,
					errors: [] 
				},
				schedule: cloneDeep(emptySchedule)
			};
		case RECEIVE_SCHEDULE:
			return {
				...state,
				isLoading: false,
				schedule: action.data
			};
		case IMPORT_SOR:
			return {
				...state,
				isLoading: true,
				result: {
					success: null,
					message: null,
					errors: []
				}
			};
		case RECEIVE_IMPORT_SOR_RESPONSE:
			return {
				...state,
				isLoading: false,
				result: {
					success: action.data.success,
					message: action.data.message,
					errors: action.data.errors
				},
			};
		case DELETE_SCHEDULE:
			return { 
				...state,
				isLoading: true,
				result: {
					success: null,
					message: null,
					errors: []
				}
			};
		case RECEIVE_DELETE_SCHEDULE_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					result: {
						success: action.data.success,
						message: action.data.message
					}
				};
			}
			return {
				...state,
				isLoading: false,
				scheduleOfRates: state.scheduleOfRates.filter(s => s.scheduleOfRatesId !== action.data.objectId) 
			};
		case DELETE_SCHEDULE_ITEM:
			return { 
				...state,
				isLoading: true,
				result: {
					success: null,
					message: null,
					errors: []
				}
			};
		case RECEIVE_DELETE_SCHEDULE_ITEM_RESPONSE: {
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					result: {
						success: action.data.success,
						message: action.data.message
					}
				};
			}
			const scheduleOfRates = map(state.scheduleOfRates, (s) => {
				if (s.scheduleOfRatesId === action.originalItem.scheduleOfRatesId) {
					return {
						...s,
						items: s.items.filter(i => i.scheduleOfRatesItemId !== action.data.objectId)
					};
				} else {
					return {
						...s	
					};
				}
			});
			return {
				...state,
				isLoading: false,
				scheduleOfRates: scheduleOfRates 
			};
		}
		case CREATE_NEW_SCHEDULE:
			return {
				...state,
				schedule: action.newSchedule,
				result: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case SAVE_SCHEDULE:
			return {
				...state,
				isLoading: true,
				result: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_SCHEDULE_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				scheduleOfRates: addOrUpdate(state.scheduleOfRates, action.data.object, { scheduleOfRatesId: action.data.object.scheduleOfRatesId }), 
				schedule: action.data.object,
				isLoading: false,
				result: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_SCHEDULE_ERRORS:
			return {
				...state,
				isLoading: false,
				result: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};

		case REORDER_SCHEDULES: {
			const scheduleOfRates = [...state.scheduleOfRates];
			let sourceIndex;
			let destinationIndex;
			for (let i = 0, ii = scheduleOfRates.length; i < ii; i++) {
				if (scheduleOfRates[i].scheduleOfRatesId === action.data.sourceScheduleOfRatesId) {
					sourceIndex = i;
					scheduleOfRates[i].order = action.data.destinationOrder;
				}
				if (scheduleOfRates[i].scheduleOfRatesId === action.data.destinationScheduleOfRatesId) {
					destinationIndex = i;
					scheduleOfRates[i].order = action.data.sourceOrder;
				}
			}
			const [removed] = scheduleOfRates.splice(sourceIndex, 1);
			scheduleOfRates.splice(destinationIndex, 0, removed);
			
			return {
				...state,
				scheduleOfRates: scheduleOfRates,
				isLoading: true,
				result: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		}
		case REORDER_SCHEDULES_RESPONSE:
			return {
				...state,
				isLoading: false
			};

		default:
			return state;
	}
};
