import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import Checkbox from '../widgets/Checkbox';
import PersistedDataTable from '../widgets/persistedDataTable';
import flatMap from 'lodash/flatMap';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import { produce } from 'immer';

class LotAdjustmentItemDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			scheduleOfRates: this.setupItems(props.scheduleOfRates),
			items: this.props.items,
			showAllScheduleItems: false
		};

		this.addLotItems = this.addLotItems.bind(this);
		this.showAllScheduleItems = this.showAllScheduleItems.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			scheduleOfRates: this.setupItems(nextProps.scheduleOfRates),
			items: this.props.items
		});
	}
	setupItems(scheduleOfRates) {
		return scheduleOfRates;
	}

	showAllScheduleItems(showAll) {
		this.setState({
			showAllScheduleItems: showAll
		});
	}

	addLotItems() {
		const selectedItems = [];
		if (this.state.showAllScheduleItems) {
			forEach(this.state.scheduleOfRates, (s) => {
				forEach(s.items, (i) => {
					if (i._isSelected) {
						const item = find(this.state.items, i2 => i2.scheduleOfRatesItemId === i.scheduleOfRatesItemId);
						selectedItems.push({
							lotId: item ? item.lotId : null,
							lotItemId: item ? item.lotItemId : null,
							scheduleOfRatesId: i.scheduleOfRatesId,
							scheduleOfRatesItemId: i.scheduleOfRatesItemId,
							itemNo: i.itemNo,
							itemDescription: i.description,
							unit: i.unit,
							rate: i.rate,
							qty: item ? item.qty : 0,
							adjustedQty: item ? item.adjustedQty : 0
						});
					}
				});
			});
		} else {
			forEach(this.state.items, (i) => {
				if (i._isSelected) {
					selectedItems.push({ 
						lotId: i.lotId,
						lotItemId: i.lotItemId,
						scheduleOfRatesId: i.scheduleOfRatesId,
						scheduleOfRatesItemId: i.scheduleOfRatesItemId,
						itemNo: i.itemNo,
						itemDescription: i.itemDescription,
						unit: i.unit,
						rate: i.rate,
						qty: i.qty,
						adjustedQty: i.adjustedQty
					});
				}
			});
		}
		this.props.addLotItems(selectedItems);
	}

	isRowSelectable = (row) => !row.isTextOnlyLine;
	isRowSelected = (row) => !row.isTextOnlyLine && row._isSelected;
	onSelectionChanged = (rows, selected)  => {
		this.setState(produce(draft => {
			if (draft.showAllScheduleItems) {
				forEach(rows, (r) => {
					forEach(draft.scheduleOfRates, (s) => {
						forEach(s.items, (i) => {
							if (i.scheduleOfRatesItemId === r.scheduleOfRatesItemId) {
								if (!i.isTextOnlyLine) i._isSelected = selected;
							}
						});
					});
				});
			} else {
				forEach(rows, (r) => {
					forEach(draft.items, (i) => {
						if (i.scheduleOfRatesItemId === r.scheduleOfRatesItemId) {
							if (!i.isTextOnlyLine) i._isSelected = selected;
						}
					});
				});
			}
		}));
	}
	
	render() {
		let items = [];
		if (this.state.showAllScheduleItems) {
			const scheduleOfRates = this.state.scheduleOfRates || [];
			items = flatMap(scheduleOfRates, (s) => s.items);
		} else {
			items = this.state.items || [];
		}

		const columns = [
			{ name: 'itemNo', label: 'Item' },
			{ 
				name: 'itemDescription', 
				label: 'Description',
				value: (args) => args.data.description || args.data.itemDescription
			}
		];

		return (
	<Dialog
		title="Select a Lot Item"
		actions={
			<React.Fragment>
				<Button
					label="Add"
					primary={true}
					onClick={this.addLotItems}
				/>,
				<Button
					label="Cancel"
					primary={false}
					onClick={this.props.cancel}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.cancel}
		scroll='paper'
		fullWidth={true}
		maxWidth='lg'
	>
		<Checkbox
			label="Show All Schedule Items"
			checked={this.state.showAllScheduleItems}
			onCheck={(e, v) => { this.showAllScheduleItems(v); } }
		/> 
		
		{/* <List>
			{map(items, (item) =>
				<ListItem 
					primaryText={item.itemNo} 
					secondaryText={item.itemDescription} 
					onClick={() => this.props.addLotItems(item)}
				/>
			)}
		</List> */}

		<PersistedDataTable 
			id="lotScheduleItems"
			data={items || []} 
			columns={columns}
			enableSelection={true}
			onSelectionChanged={this.onSelectionChanged}
			isRowSelected={this.isRowSelected}
			isRowSelectable={this.isRowSelectable}
			groupBy="scheduleOfRatesId"
			groupByFormatter={(args) => find(this.state.scheduleOfRates, (s) => s.scheduleOfRatesId === args.value).name}
		/>
	</Dialog>
		);
	}
}

LotAdjustmentItemDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	addLotItems: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	items: PropTypes.array,
	scheduleOfRates: PropTypes.array
};

LotAdjustmentItemDialog.defaultProps = {
	scheduleOfRates: [],
	items: []
};

export default LotAdjustmentItemDialog;
