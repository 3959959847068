/* eslint no-bitwise: "off" */
/* eslint camelcase	: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import Button from './button';
import TextField from './TextField';
import { post } from '../../utils/ajax';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import LinearProgress from '@material-ui/core/LinearProgress';

class ChangePasswordDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			isLoading: false,
			errorMessage: '',

			prevPassword: '',
			password: '',
			confirmPassword: '',

			prevPasswordValid: true,
			passwordValid: true,
			passwordsMatch: true
		};

		this.validatePrevPassword = this.validatePrevPassword.bind(this);
		this.validatePassword = this.validatePassword.bind(this);
		this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
		this.onOk = this.onOk.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!this.props.show && nextProps.show) {
			// Clear state each time dialog opened
			this.setState({
				prevPassword: '',
				password: '',
				confirmPassword: '',

				prevPasswordValid: true,
				passwordValid: true,
				passwordsMatch: true
			});
		}
	}

	validatePrevPassword() {
		if (!this.state.prevPassword) {
			this.setState({ prevPasswordValid: false });
			return false;
		}
		return true;
	}
	validatePassword() {
		if (!this.state.password || this.state.password.length < 8) {
			this.setState({ passwordValid: false });
			return false;
		}
		return true;
	}
	validateConfirmPassword() {
		if (this.state.confirmPassword !== this.state.password) {
			this.setState({ passwordsMatch: false });
			return false;
		}
		return true;
	}

	onOk() {
		if (this.validatePrevPassword() & this.validatePassword() & this.validateConfirmPassword()) {
			this.setState({
				isLoading: true,
				errorMessage: ''
			});

			post({
				url: '/api/users/change-password',
				data: {
					currentPassword: this.state.prevPassword,
					newPassword: this.state.password
				},
				onSuccess: (data) => {
					if (data.success) {
						this.setState({
							isLoading: false
						});
						this.props.dispatch(showSuccessNotification('You password has been changed'));
						
						this.props.onOk();
					} else {
						this.setState({
							isLoading: false,
							errorMessage: data.error
						});
					}
				},
				onError: (error) => {
					this.setState({
						isLoading: false,
						errorMessage: error
					});
				}
			});
		}
	}

	render() {
		return (
<Dialog
	title={this.props.title}
	actions={
		<React.Fragment>
			<Button
				label='Ok'
				primary={true}
				onClick={() => this.onOk()}
			/>,
			<Button
				label='Cancel'
				onClick={() => this.props.onCancel()}
			/>
		</React.Fragment>
	}
    onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
			if (!this.state.isLoading) this.props.onCancel();
        }
    }}
	open={this.props.show}
>
	<span style={{ marginLeft: '10px' }}>{this.props.message}</span>

	<TextField
		type="password"
		autoComplete="off"
		value={this.state.prevPassword}
		onChange={(e) => { 
			this.setState({ 
				prevPassword: e.target.value,
				prevPasswordValid: true
			}); 
		}}
		onBlur={() => {
			this.validatePrevPassword();
		}}
		label="Current Password"
		errorText={this.state.prevPasswordValid ? '' : 'Invalid password'}
		fullWidth={true}
	/>

	<TextField
		type="password"
		autoComplete="off"
		value={this.state.password}
		onChange={(e) => { 
			this.setState({ 
				password: e.target.value,
				passwordValid: true
			}); 
		}}
		onBlur={() => {
			this.validatePassword();
		}}
		label="Password"
		errorText={this.state.passwordValid ? '' : 'The password must be at least 8 characters'}
		fullWidth={true}
	/>

	<TextField
		type="password"
		autoComplete="off"
		value={this.state.confirmPassword}
		onChange={(e) => { 
			this.setState({ 
				confirmPassword: e.target.value,
				passwordsMatch: true
			}); 
		}}
		onBlur={() => {
			this.validateConfirmPassword();
		}}
		label="Confirm Password"
		errorText={this.state.passwordsMatch ? '' : 'Passwords do not match'}
		fullWidth={true}
	/>

	{this.state.isLoading &&
		<LinearProgress 
			variant='indeterminate' 
			style={{ marginBottom: '10px' }} 
		/>
	}
			
	<MessagePanel isSuccess={false} message={this.state.errorMessage} />
</Dialog>
		);
	}
}

ChangePasswordDialog.propTypes = {
	dispatch: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

export default ChangePasswordDialog;
