import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

const ReportContainer = ({ isReportView, orientation, children }) =>
	isReportView ? 
		<div className='report-container'>{children}</div>
		:
		<Paper 
			className='report-container' 
			style={{ 
				padding: '10px', 
				margin: '0.5cm', 
				width: orientation === 'landscape' ? '28.7cm' : '20cm',
				minHeight: orientation === 'landscape' ? '20cm' : '28.7cm' 
			}}
		>
			{children}
		</Paper>;

ReportContainer.propTypes = {
	isReportView: PropTypes.bool,
	orientation: PropTypes.string,
	children: PropTypes.any.isRequired
};

ReportContainer.defaultProps = {
	isReportView: false,
	orientation: 'portrait'
};

export default ReportContainer;
