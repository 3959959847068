import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import Tab from './Tab';
import { errorColour } from '../../variables';
import map from 'lodash/map';

const Tabs = React.memo(props => {
	const [tab, setTab] = React.useState(0);
	const tabs = React.Children.toArray(props.children).filter(c => c.type === Tab && c.props.show).map(c => c.props);

	if (tabs.length === 0) return <div />;

	return (
		<React.Fragment>
			<MuiTabs 
				value={tab} 
				indicatorColor="primary" 
				onChange={(e, t) => setTab(t)} 
				variant="fullWidth"
			>
				{map(tabs, (t, index) => <MuiTab key={index} label={t.label} style={{ color: t.error && errorColour }} />)}
			</MuiTabs>
			<div>
				{props.renderOnLoad ? tabs[tab].children : map(tabs, (t, index) => <div key={index} hidden={index !== tab}>{t.children}</div>)}
			</div>
		</React.Fragment>
	);
});

Tabs.propTypes = {
	children: PropTypes.arrayOf(Tab).isRequired,
	renderOnLoad: PropTypes.bool
};

Tabs.defaultProps = {
	renderOnLoad: false
};

export default Tabs;
