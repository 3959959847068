import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import forEach from 'lodash/forEach';
import PersistedDataTable from '../widgets/persistedDataTable';
import Checkbox from '../widgets/Checkbox';

class LotDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			lots: props.lots
		};

		this.selectLot = this.selectLot.bind(this);
		this.setSelectedLots = this.setSelectedLots.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			lots: nextProps.lots
		});
	}

	setSelectedLots() {
		const selectedLots = [];
		forEach(this.state.lots, (l) => {
			if (l._isSelected) {
				selectedLots.push({
					lotId: l.lotId,
					lotNo: l.lotNo,
					lotDescription: l.lotDescription
				});
			}
		});
		this.props.setSelectedLots(selectedLots);
	}
	selectLot(lot, selected) {
		forEach(this.state.lots, (l) => {
			if (l.lotId === lot.lotId) {
				l._isSelected = selected;
			}
		});
		this.setState({
			lots: this.state.lots
		});
	}
	isAllSelected = () => {
		for (let i = 0, ii = this.state.lots.length; i < ii; i++) {
			const lot = this.state.lots[i];
			if (lot._isSelected !== true) return false;
		}
		return true;
	}
	toggleAll = () => {
		const nextValue = !this.isAllSelected();
		forEach(this.state.lots, (l) => {
			l._isSelected = nextValue;
		});
		this.setState({
			scheduleOfRates: this.state.scheduleOfRates
		});
	}

	render() {
		const lots = this.state.lots || [];

		const columns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				value: (args) => args.data._isSelected || false,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected()}
						onCheck={() => { this.toggleAll(); } }
					/>,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectLot(args.data, v); } }
					/> 
			},
			{ name: 'lotNo', label: 'Lot No' },
			{ name: 'lotDescription', label: 'Description' }
		];

		return (
	<Dialog
		title="Lots"
		actions={
			<React.Fragment>
				<Button
					label="Add"
					primary={true}
					onClick={() => this.setSelectedLots()}
				/>,
				<Button
					label="Cancel"
					primary={false}
					onClick={this.props.cancel}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.cancel}
		scroll='paper'
		fullWidth={true}
		maxWidth='lg'
	>
		<p>Select the Lots to add to the NCR</p>

		<PersistedDataTable 
			id="lots"
			data={lots || []} 
			columns={columns}
		/>
	</Dialog>
		);
	}
}

LotDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	setSelectedLots: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	lots: PropTypes.array.isRequired
};

export default LotDialog;
