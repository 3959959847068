import React from 'react';
import PropTypes from 'prop-types';
// import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import isFunction from 'lodash/isFunction';
import {
	Tooltip
} from '@material-ui/core';

class DataTableRow extends React.Component {
	// shouldComponentUpdate(nextProps) {
	// 	return !isEqual(this.props.row.data, nextProps.row.data);
	// }

	render() {
		const row = this.props.row;
		return (
			<tr 
				style={row.style} 
				className={row.className}
			>
				{
					map(row.cells, (c, i) => {
						let value;
						if (c.cellRenderer) {
							value = c.cellRenderer({ value: c.value, formattedValue: c.formattedValue, data: row.data });
						} else {
							value = c.formattedValue || c.value;
						}

						const CellWidget = 
							<td
								key={i}
								colSpan={c.colspan}
								onClick={c.onClick}
								style={c.style}
								className={c.className}
							>
								{value}
							</td>;

						let tooltip = "";
						if (c.tooltip) {
							tooltip = isFunction(c.tooltip) ? c.tooltip({ value: c.value, formattedValue: c.formattedValue, data: row.data }) : c.tooltip;
						}

						if (tooltip) {
							return (
								<Tooltip title={tooltip} key={`tt-${i}`}>
									{CellWidget}
								</Tooltip>
							);		
						} else {
							return CellWidget;
						}
					})
				}
			</tr>
		);
	}
}

DataTableRow.propTypes = {
	row: PropTypes.object.isRequired
};

DataTableRow.defaultProps = {
};

export default DataTableRow;
