import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import addReportWrapper from './reportWrapper';
import LotStatusByScheduleItemContent from './lotStatusByScheduleItemContent';

class LotStatusByScheduleItem extends React.Component {
	render() {
		const report = this.props.report || {};

		return (
			<LotStatusByScheduleItemContent report={report} />
		);
	}
}

LotStatusByScheduleItem.propTypes = {
	report: PropTypes.object
};

LotStatusByScheduleItem.defaultProps = {
	report: undefined
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addReportWrapper(
	LotStatusByScheduleItem, {
		reportUrl: '/api/reports/lot-status-by-schedule-item',
		downloadUrl: '/Reports/LotStatusByScheduleItem',
		title: 'Lot Status by Schedule Item',
		className: 'lot-status-by-schedule-item'
	})
);
