import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { emptySaveResult } from '../../variables';
import EditTenant from './EditTenant';
import { get, post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { canEdit } from '../../selectors/canEdit';

const EditTenantController = ({ 
	canEditTenant, 
	match,
	history,
	showSuccessNotification,
	showErrorNotification 
}) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [saveResult, setSaveResult] = React.useState({ ...emptySaveResult });
	const [tenant, setTenant] = React.useState({});

	const tenantId = match.params.tenantId;
	React.useEffect(() => {
		if (tenantId) {
			setIsLoading(true);
			get({
				url: `/api/tenants/${tenantId}`,
				onSuccess: data => {
					setIsLoading(false);
					setTenant(data);
				},
				onError: (error) => {
					setIsLoading(false);
					showErrorNotification(error.message);
				}
			});
		}
	}, [tenantId]);

	const validate = (t) => {
		const errors = [];
		if (!t.name) {
			errors.push({
				fieldName: 'Name',
				valid: false,
				message: 'Name is required'
			});
		}
		return errors;
	};

	const save = (t, onSuccess) => {
		const errors = validate(t);
		if (errors.length > 0) {
			setSaveResult({
				success: false,
				message: 'Please correct the errors',
				fields: errors
			});
			return;
		}

		setIsLoading(true);
		post({
			url: `/api/tenants`,
			data: t,
			onSuccess: data => {
				setIsLoading(false);
				setTenant(data.success ? data.object : t);
				setSaveResult({
					success: data.success,
					message: data.message,
					fields: data.fields
				});

				if (data.success) {
					showSuccessNotification(data.message);
					if (onSuccess) onSuccess();
				} else {
					showErrorNotification(data.message);
				}
			},
			onError: error => {
				setIsLoading(false);
				showErrorNotification(error.message);
			}
		});
	};

	return (
		<EditTenant
			isLoading={isLoading}
			tenant={tenant}
			canEdit={canEditTenant}
			saveResult={saveResult}
			onSave={(t) => save(t, () => { history.goBack(); })}
			onSaveAndContinue={(t) => save(t)}
			onCancel={() => history.goBack() }
		/>
	);
};


EditTenantController.propTypes = {
	canEditTenant: PropTypes.bool.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	canEditTenant: canEdit(state, 'EditTenants')
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(EditTenantController);
