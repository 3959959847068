import React from 'react';
import PropTypes from 'prop-types';
import { globals } from '../globals';
import HelpDrawer from './HelpDrawer';

const LoginLayout = props => {
	const tenantName = globals.tenantName;

    return (
      <div className="login-body">
			<div id="bg">
				<img src="/images/login-background.jpg" alt="" className="bg" />
				<div className="bg-overlay"></div>
			</div>
			<div className="login-wrapper">
			<div className="login-panel">
				<img className="login-logo" alt="" src="/images/contrack_logo_200x58.jpg" />
				<hr />
				{props.body}
				<span style={{ float: 'right', marginRight: '15px' }}>{tenantName}</span>
			</div>
		</div>

		<HelpDrawer />
      </div>
    );
};

LoginLayout.propTypes = {
	body: PropTypes.element.isRequired
};

export default LoginLayout;
