import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import ContentContainer from '../contentContainer';
import forEach from 'lodash/forEach';
import DateField from '../widgets/DateField';
import SubContentContainer from '../subContentContainer';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import FileUpload from '@material-ui/icons/Publish';
import FileDownload from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ContentAdd from '@material-ui/icons/Add';
import Button from '../widgets/button';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { map } from 'lodash';
import SelectField from '../widgets/selectField';
import CorrespondencePicker from '../widgets/correspondencePicker';
import PersistedDataTable from '../widgets/persistedDataTable';
import FloatingActionButton from '@material-ui/core/Fab';
import TagField from '../widgets/TagField';

class RegisterForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			register: props.register,
			showCorrespondencePicker: false,
			registerFieldId: null
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			register: nextProps.register
		});
	}

	selectFiles = (field, files) => {
		this.props.addFiles(field, files);
	}

	addLinkedCorrespondence = (registerFieldId) => {
		this.setState({ registerFieldId: registerFieldId, showCorrespondencePicker: true });
	}
	onSelectCorrespondence = (selectedCorrespondence) => {
		this.props.onSelectCorrespondence(this.state.registerFieldId, selectedCorrespondence);
		this.setState({
			showCorrespondencePicker: false
		});
	}
	onCancelCorrespondencePicker = () => {
		this.setState({ showCorrespondencePicker: false });
	}

	render() {
		const register = this.state.register;

		const styles = {
			button: {
				marginTop: 12
			},
			fileInput: {
				cursor: 'pointer',
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				width: '100%',
				opacity: 0
			}
		};

		const fields = [];
		const fileFields = [];
		const correspondenceFields = [];

		forEach(sortBy(this.props.registerDefinition.fields, ['order']), (f) => {
			const allowedValues = JSON.parse(f.allowedValues) || [];
			switch (f.fieldType) {
				case 'Select': 
					fields.push(
						<FormFieldContainer key={`field-${f.registerFieldId}`}>
							<SelectField
								value={register.values[f.name]}
								label={f.label} 
								onChange={(v) => { this.props.updateField(f.name, v); }}
								fullWidth={true}
								error={getFieldErrorText(this.props.saveResult, f.name)}
								nullable={true}
								items={
									map(allowedValues, v =>
										({ value: v, label: v })
									)
								}
							/>
						</FormFieldContainer>
					);
					break;
				case 'Date': 
					fields.push(
						<FormFieldContainer key={`field-${f.registerFieldId}`}>
							<DateField
								value={register.values[f.name] ? new Date(register.values[f.name]) : null}
								label={f.label} 
								onChange={(d) => { this.props.updateField(f.name, d); }}
								fullWidth={true}
								error={getFieldErrorText(this.props.saveResult, f.name)}
							/>
						</FormFieldContainer>
					);
					break;
				case 'Tags': 
					if (register.values[f.name] !== undefined) {
						fields.push(
							<FormFieldContainer key={`field-${f.registerFieldId}`}>
								<TagField
									value={register.values[f.name]}
									label={f.label} 
									onChange={(tags) => { this.props.updateField(f.name, tags); }}
									fullWidth={true}
									error={getFieldErrorText(this.props.saveResult, f.name)}
								/>
							</FormFieldContainer>
						);
					}
					break;
				case 'File': {
					const files = [];
					forEach(register.files, (file, fileIndex) => {
						const ids = file.referenceId.split('.');
						if (ids[1] === f.registerFieldId.toString()) {
							files.push(
								<tr key={file.userFileId}>
									<td style={{ whiteSpace: 'nowrap' }}>
										{!file._isNew && 
											<IconButton onClick={ () => { this.props.downloadFile(file); } } style={{ padding: '2px' }}>
												<FileDownload />
											</IconButton>
										}
										<IconButton onClick={ () => { this.props.deleteFile(fileIndex); } } style={{ padding: '2px' }}>
											<ActionDelete />
										</IconButton>
									</td>	
									<td>{file.name}</td>
								</tr>
							);
						}
					});
					
					fileFields.push(
						<SubContentContainer 
							key={`field-${f.registerFieldId}`}
							header={f.label}
							onDragOver={(e) => {
								e.dataTransfer.dropEffect = 'copy';
								e.stopPropagation();
								e.preventDefault();
							}}
							onDrop={(e) => {
								e.stopPropagation();
								e.preventDefault();
								// Allow files to be added by dropping anywhere in container
								this.selectFiles(f, e.dataTransfer.files);
							}}
						>
							<div className="table-container">
								<table className="full-bordered" style={{ width: '100%' }}>
									<thead>
										<tr>
											<th></th>
											<th>Name</th>
										</tr>
									</thead>
									<tbody>
										{files}
									</tbody>
								</table>
							</div>
				
							<div className="row">
								<Button
									label="Select a file to upload"
									style={styles.button}
									component="label"
									startIcon={<FileUpload />}
								>
									<input 
										type="file" 
										style={styles.fileInput} 
										multiple="multiple" 
										onChange={(event) => this.selectFiles(f, event.target.files)} 
									/>
								</Button>
							</div>
						</SubContentContainer>
					);
					break;
				}
				case 'Correspondence': {
					const correspondenceColumns = [
						{
							name: 'commands',
							excludeFromSearch: true,
							excludeFromFilter: true,
							cellRenderer: (args) => 
								<div style={{ whiteSpace: 'nowrap' }}>
									<IconButton onClick={ () => { this.props.viewCorrespondence(args.data); } } style={{ padding: '2px' }}>
										<VisibilityIcon />
									</IconButton>
									<IconButton onClick={ () => { this.props.deleteCorrespondence(f.registerFieldId, args.data); } }>
										<ActionDelete />
									</IconButton>
								</div>				
						},
						{ 
							name: 'correspondenceType', 
							label: 'Type'
						},
						{ name: 'correspondenceNumber', label: 'Correspondence Number' },
						{ name: 'correspondenceDate', label: 'Date', dataType: 'date' }
					];
					const correspondence = register.correspondence || [];
					
					correspondenceFields.push(
						<SubContentContainer 
							key={`field-${f.registerFieldId}`}
							header={f.label}
							style={{ marginTop: '10px' }}
						>
							<PersistedDataTable 
								id={`registerCorrespondence-${f.registerFieldId}`}
								data={correspondence} 
								columns={correspondenceColumns}
							/>

							<div className="row">
								<FloatingActionButton onClick={() => this.addLinkedCorrespondence(f.registerFieldId)} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
									<ContentAdd />
								</FloatingActionButton>
							</div>
						</SubContentContainer>
					);
					break;
				}
				default:
					fields.push(
						<FormFieldContainer key={`field-${f.registerFieldId}`}>
							<TextField
								value={register.values[f.name]}
								fullWidth={true}
								onChange={(e, v) => { this.props.updateField(f.name, v); }}
								label={f.label}
								errorText={getFieldErrorText(this.props.saveResult, f.name)}
							/>
						</FormFieldContainer>
					);
			}
		});

return (
<div>
	<ContentContainer>
		{fields}
	</ContentContainer>

	<CorrespondencePicker 
		show={this.state.showCorrespondencePicker} 
		types={['Outgoing', 'Incoming', 'Principal', 'Design']}
		selectedCorrespondence={{
			outgoingCorrespondence: map(filter(register.correspondence, (c) => c.correspondenceType === 'Outgoing'), (c) => ({ outgoingCorrespondenceId: c.correspondenceId })),
			incomingCorrespondence: map(filter(register.correspondence, (c) => c.correspondenceType === 'Incoming'), (c) => ({ incomingCorrespondenceId: c.correspondenceId })),
			principalCorrespondence: map(filter(register.correspondence, (c) => c.correspondenceType === 'Principal'), (c) => ({ principalCorrespondenceId: c.correspondenceId })),
			designCorrespondence: map(filter(register.correspondence, (c) => c.correspondenceType === 'Design'), (c) => ({ designCorrespondenceId: c.correspondenceId }))
		}}
		onOk={this.onSelectCorrespondence} 
		cancel={this.onCancelCorrespondencePicker} 
	/>

	{fileFields}
	{correspondenceFields}
</div>
		);
	}
}

RegisterForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	registerDefinition: PropTypes.object.isRequired,
	register: PropTypes.object.isRequired,
	downloadFile: PropTypes.func.isRequired,
	deleteFile: PropTypes.func.isRequired,
	addFiles: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	onSelectCorrespondence: PropTypes.func.isRequired,
	deleteCorrespondence: PropTypes.func.isRequired,
	viewCorrespondence: PropTypes.func.isRequired
};

export default RegisterForm;
