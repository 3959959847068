import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PageLayout from '../pageLayout';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import ContactsGrid from './ContactsGrid';
import { get, post } from '../../utils/ajax';
import filter from 'lodash/filter';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';

const Contacts = props => {
	const [state, setState] = React.useState({
		contacts: [],
		isLoading: false
	});

	
	React.useEffect(() => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		get({
			url: `/api/contacts?contractId=${props.contractId}`,
			onSuccess: data => setState({ contacts: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	}, [props.contractId]);

	const onDeleteContact = (contact) => {
		props.confirmDelete(contact, () => {
			setState(prevState => ({ ...prevState, isLoading: true }));
			
			post({
				url: `/api/contacts/${contact.contactId}/delete?contractId=${props.contractId}`,
				onSuccess: data => {
					setState({ isLoading: false, contacts: filter(state.contacts, c => c.contactId !== contact.contactId) });

					if (data.success) {
						props.showSuccessNotification(data.message);
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	};

	return (
		<PageLayout
			title="Contacts"
			isLoading={state.isLoading}
			actions={
				props.canEdit ? 
				[
					{
						label: 'New Contact',
						onClick: () => { props.history.push('/contact'); }
					}
				] : []
			}
			content={
				<div>
					<ContentContainer>
						<ContactsGrid
							contacts={state.contacts}
							canView={props.canView}
							canEdit={props.canEdit}
							onEditContact={contact => { props.history.push(`/contact/${contact.contactId}`); }}
							onViewContact={contact => { props.history.push(`/contact/${contact.contactId}`); }}
							onDeleteContact={onDeleteContact}
						/>
					</ContentContainer>
				</div>
			}
		/>
    );
};

Contacts.propTypes = {
	contractId: PropTypes.number.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	confirmDelete: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

Contacts.defaultProps = {
};

const mapStateToProps = (state) => ({
	contractId: state.context.contract ? state.context.contract.contractId : 0,
	canView: canView(state, 'ViewContacts'),
	canEdit: canEdit(state, 'EditContacts'),
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	confirmDelete: (contact, onOk) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this contact?',
			onOk: () => {
				if (onOk) onOk(contact);
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(Contacts);
