import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { addOrUpdate } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification } from './notifications';
import filter from 'lodash/filter';

export const emptyLot = {
	'lotId': 0,
	'contractId': 0,
	'periodId': 0,
	'lotNo': '',
	'lotDescription': '',
	'status': 'Open',
	'state': 'Open',
	'prevState': null,
	'atcBy': '',
	'periodClaimed': '',
	'comments': '',
	'dateLotCreated': new Date(),
	'items': [],
	tags: []
};

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	lot: null, 
	lots: null, 
	tags: null
};

const CLEAR_LOTS = 'CLEAR_LOTS';
const CLEAR_LOT = 'CLEAR_LOT';
const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT';
const GET_LOTS_REQUEST = 'GET_LOTS_REQUEST';
const GET_LOTS_SUCCESS = 'GET_LOTS_SUCCESS';
const GET_LOTS_FAILURE = 'GET_LOTS_FAILURE';
const GET_LOT_REQUEST = 'GET_LOT_REQUEST';
const GET_LOT_SUCCESS = 'GET_LOT_SUCCESS';
const GET_LOT_FAILURE = 'GET_LOT_FAILURE';
const CREATE_NEW_LOT = 'CREATE_NEW_LOT';
const SAVE_LOT_REQUEST = 'SAVE_LOT_REQUEST';
const SAVE_LOT_FAILURE = 'SAVE_LOT_FAILURE';
const SAVE_LOT_SUCCESS = 'SAVE_LOT_SUCCESS';
const DELETE_LOT_REQUEST = 'DELETE_LOT_REQUEST';
const DELETE_LOT_SUCCESS = 'DELETE_LOT_SUCCESS';
const DELETE_LOT_FAILURE = 'DELETE_LOT_FAILURE';
const SHOW_LOT_ERRORS = 'SHOW_LOT_ERRORS';
const EDIT_LOT = 'EDIT_LOT';

export const isLoading = createLoadingSelector(['GET_LOT', 'GET_LOTS', 'GET_LOT_TAGS', 'SAVE_LOT', 'DELETE_LOT']);

export const clearLots = () => ({ type: CLEAR_LOTS });
export const clearLot = () => ({ type: CLEAR_LOT });
export const clearSaveResult = () => ({ type: CLEAR_SAVE_RESULT });
export const receiveLots = (data) => ({ type: GET_LOTS_SUCCESS, payload: { data } });
export const receiveLot = (data) => ({ type: GET_LOT_SUCCESS, payload: { data } });
export const receiveSaveLotResponse = (data) => ({ type: SAVE_LOT_SUCCESS, data });
export const receiveDeleteLotResponse = (data) => ({ type: DELETE_LOT_SUCCESS, data });
export const editLot = (lot) => ({ type: EDIT_LOT, lot });

export const createNewLot = () =>
	(dispatch) => {	
		const newLot = {
			...cloneDeep(emptyLot)
		};
		dispatch({ type: CREATE_NEW_LOT, newLot: newLot });
	};

export const requestLots = () => (
	createFetchAction({
		objectName: 'Lots',
		passContext: true,
		url: '/api/lots',
		startAction: GET_LOTS_REQUEST,
		success: (data) => receiveLots(data),
		onError: error => [{ type: GET_LOTS_FAILURE, payload: { error } }, showErrorNotification(error.message)],
	})
);

export const requestLot = (lotId) => (
	createFetchAction({
		objectName: 'Lot',
		passContext: true,
		url: `/api/lots/${lotId}`,
		startAction: GET_LOT_REQUEST,
		success: (data) => receiveLot(data),
		onError: error => [{ type: GET_LOT_FAILURE, payload: { error } }, showErrorNotification(error.message)],
	})
);

const validateLot = (lot, prevLot) => {
	const errors = [];
	if (lot.scheduleOfRatesItemId <= 0) {
		errors.push({
			fieldName: 'ItemNo',
			valid: false,
			message: 'Item No is required'
		});
	}
	if (lot.qty <= 0) {
		errors.push({
			fieldName: 'Qty',
			valid: false,
			message: 'Qty must be greater than 0'
		});
	}
	if (!lot.status) {
		errors.push({
			fieldName: 'Status',
			valid: false,
			message: 'Invalid Lot Status'
		});
	}

    if (prevLot) {
        if (lot.status !== prevLot.status && !lot.comments) {
            errors.push({
                fieldName: 'Comments',
                valid: false,
                message: 'Comments are required when changing the Lot Status'
            });
        }
    }
	return errors;
};

export const saveLot = (lot, prevLot, onSuccess) => {
	const errors = validateLot(lot, prevLot);
	if (errors.length > 0) return { type: SHOW_LOT_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/lots',
		data: lot,
		startAction: SAVE_LOT_REQUEST,
		success: (data, dispatch) => {
			dispatch(receiveSaveLotResponse(data));
			if (data) {
				if (data.success && onSuccess) onSuccess.call(this, data);
				if (!data.success) dispatch(showErrorNotification(data.message));
			}
		},
		onError: error => [{ type: SAVE_LOT_FAILURE, payload: { error } }, showErrorNotification(error.message)]
	});
};

export const saveLotAndNew = (lot, prevLot) => {
	const errors = validateLot(lot, prevLot);
	if (errors.length > 0) return { type: SHOW_LOT_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/lots',
		data: lot,
		startAction: SAVE_LOT_REQUEST,
		success: (data, dispatch) => {
			dispatch(receiveSaveLotResponse(data));
			if (data.success) dispatch(createNewLot());
			if (!data.success) dispatch(showErrorNotification(data.message));
		},
		onError: error => [{ type: SAVE_LOT_FAILURE, payload: { error } }, showErrorNotification(error.message)]
	});
};

export const deleteLot = (lot, onSuccess) => (
	createPostAction({
		passContext: true,
		url: `/api/lots/${lot.lotId}/delete`,
		data: lot,
		startAction: DELETE_LOT_REQUEST,
		success: (data, dispatch) => {
			dispatch(receiveDeleteLotResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		},
		onError: error => [{ type: DELETE_LOT_FAILURE, payload: { error } }, showErrorNotification(error.message)],
	})
);

const GET_LOT_TAGS_REQUEST = 'GET_LOT_TAGS_REQUEST';
const GET_LOT_TAGS_SUCCESS = 'GET_LOT_TAGS_SUCCESS';
const GET_LOT_TAGS_FAILURE = 'GET_LOT_TAGS_FAILURE';
export const receiveTags = (data) => ({ type: GET_LOT_TAGS_SUCCESS, payload: { data } });
export const requestTags = () => (
	createFetchAction({
		objectName: 'Tags',
		passContext: true,
		url: '/api/lots/tags',
		startAction: GET_LOT_TAGS_REQUEST,
		success: (data) => receiveTags(data),
		onError: error => [{ type: GET_LOT_TAGS_FAILURE, payload: { error } }, showErrorNotification(error.message)],
	})
);

export default (state = initialState, action) => {
		switch (action.type) {
		case CLEAR_LOTS:
			return {
				...state,
				lots: null
			};
		case CLEAR_LOT:
			return {
				...state,
				lot: null
			};
		case CLEAR_SAVE_RESULT:
			return {
				...state,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case EDIT_LOT:
			return {
				...state,
				lot: action.lot,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case GET_LOTS_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				lots: []
			};
		case GET_LOTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				lots: action.payload.data
			};
		case GET_LOT_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				lot: cloneDeep(emptyLot)
			};
		case GET_LOT_SUCCESS:
			return {
				...state,
				isLoading: false,
				lot: action.payload.data
			};
		case DELETE_LOT_REQUEST:
			return { 
				...state
			};
		case DELETE_LOT_SUCCESS:
			return {
				...state,
				lots: filter(state.lots, c => c.lotId !== action.data.objectId) 
			};
		case CREATE_NEW_LOT:
			return {
				...state,
				lot: action.newLot,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};	
		case SAVE_LOT_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case SAVE_LOT_SUCCESS:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				lots: addOrUpdate(state.lots, action.data.object, { lotId: action.data.object.lotId }), 
				lot: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_LOT_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		case GET_LOT_TAGS_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				tags: []
			};
		case GET_LOT_TAGS_SUCCESS:
			return {
				...state,
				isLoading: false,
				tags: action.payload.data
			};

		default:
			return state;
	}
};
