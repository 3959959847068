import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveExtensionOfTime, 
	clearExtensionOfTime, 
	requestExtensionOfTime, 
	createNewExtensionOfTime 
} from '../../reducers/extensionsOfTime';
import { requestEmployees } from '../../reducers/employees';
import { requestSeparablePortions } from '../../reducers/separablePortions';
import ExtensionOfTimeForm from './extensionOfTimeForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class ExtensionOfTimeMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			extensionOfTime: props.extensionOfTime,
			newFiles: [],
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.updateSeparablePortionField = this.updateSeparablePortionField.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestEmployees());
		dispatch(requestSeparablePortions());
		if (props.match.params.extensionOfTimeId) {
			dispatch(requestExtensionOfTime(props.match.params.extensionOfTimeId));
		} else {
			dispatch(createNewExtensionOfTime());
		}
	}
	static clearData(dispatch) {
		dispatch(clearExtensionOfTime());
	}
	componentDidMount() {
		ExtensionOfTimeMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			extensionOfTime: nextProps.extensionOfTime,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const extensionOfTime = this.state.extensionOfTime;
		if (field === 'status' && newValue === 'Approved') {
			const currentStatus = extensionOfTime[field];
			if (currentStatus !== 'Approved') extensionOfTime.approvedDate = new Date();
		}
		extensionOfTime[field] = newValue;
        this.setState({
			extensionOfTime,
			hasUnsavedChanges: true
        });
	}
	updateSeparablePortionField(sp, field, newValue) {
		const extensionOfTime = this.state.extensionOfTime;
		const separablePortion = find(extensionOfTime.separablePortions, (s) => s.separablePortionId === sp.separablePortionId);
		separablePortion[field] = newValue;
        this.setState({
			extensionOfTime,
			hasUnsavedChanges: true
        });
	}

	onSelectCorrespondence = (selectedCorrespondence) => {
		const extensionOfTime = this.state.extensionOfTime;
		const linkedCorrespondence = [];

		forEach(selectedCorrespondence.incomingCorrespondence, (c) => {
			const lc = {
				targetType: 'ExtensionOfTime',
				targetId: extensionOfTime.extensionOfTimeId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Incoming',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle,
				correspondenceDate: c.correspondenceDate
			};

			// Keep the current id if it already existed
			const existingLC = find(extensionOfTime.linkedCorrespondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Incoming');
			if (existingLC) lc.linkedCorrespondenceId = existingLC.linkedCorrespondenceId;

			linkedCorrespondence.push(lc);
		});

		forEach(selectedCorrespondence.outgoingCorrespondence, (c) => {
			const lc = {
				targetType: 'ExtensionOfTime',
				targetId: extensionOfTime.extensionOfTimeId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Outgoing',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle,
				correspondenceDate: c.correspondenceDate
			};

			// Keep the current id if it already existed
			const existingLC = find(extensionOfTime.linkedCorrespondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Outgoing');
			if (existingLC) lc.linkedCorrespondenceId = existingLC.linkedCorrespondenceId;

			linkedCorrespondence.push(lc);
		});

		extensionOfTime.linkedCorrespondence = linkedCorrespondence;
		this.setState({ 
			extensionOfTime,
			hasUnsavedChanges: true
		});
	}
	deleteLinkedCorrespondence = (correspondence) => {
        this.setState({
			extensionOfTime: {
				...this.state.extensionOfTime,
				linkedCorrespondence: filter(this.state.extensionOfTime.linkedCorrespondence, (c) => !(c.correspondenceType === correspondence.correspondenceType && c.correspondenceId === correspondence.correspondenceId))
			},
			hasUnsavedChanges: true
        });
	}
	viewLinkedCorrespondence =(correspondence) => {
		switch (correspondence.correspondenceType) { 
			case 'Incoming':
				this.props.history.push(`/incoming-correspondence/${correspondence.correspondenceId}`);
				break;
			case 'Outgoing':
				this.props.history.push(`/outgoing-correspondence/${correspondence.correspondenceId}`);
				break;
			default:
		}
	}

	downloadFile = (file) => {
		window.open(`/api/eot/${this.state.extensionOfTime.extensionOfTimeId}/download-file/${file.userFileId}?contractId=${this.props.contract.contractId}`, '_self');
	}
	deleteFile = (file) => {
		const extensionOfTime = this.state.extensionOfTime;
		extensionOfTime.files = filter(extensionOfTime.files, (f) => f !== file);
        this.setState(prevState => ({
			...prevState,
			extensionOfTime,
			hasUnsavedChanges: true
        }));
	}
	addFiles = (files) => {
		const extensionOfTime = this.state.extensionOfTime;
		const newFiles = this.state.newFiles;
		if (!extensionOfTime.files) extensionOfTime.files = [];
		forEach(files, (f) => {
			extensionOfTime.files.push({
				name: f.name,
				sizeBytes: f.size,
				_isNew: true
			});
			newFiles.push({
				file: f
			});
		});
		this.setState({
			extensionOfTime,
			newFiles,
			hasUnsavedChanges: true
		});
	}

	render() {
		const extensionOfTime =  this.state.extensionOfTime || { files: [] };

		return (
<PageLayout
	title={extensionOfTime.extensionOfTimeNumber}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.extensionOfTime, this.state.newFiles),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.extensionOfTime, this.state.newFiles),
		onCancel: () => this.props.history.goBack()
	}}
	content={
	<div>
		<ExtensionOfTimeForm 
			extensionOfTime={extensionOfTime} 
			canEdit={this.props.canEdit}
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult} 
			downloadFile={this.downloadFile}
			deleteFile={this.deleteFile} 
			addFiles={this.addFiles}
			updateField={this.updateField}
			updateSeparablePortionField={this.updateSeparablePortionField}
			employees={this.props.employees}
			separablePortions={this.props.separablePortions}
			onSelectCorrespondence={this.onSelectCorrespondence}
			deleteLinkedCorrespondence={this.deleteLinkedCorrespondence}
			viewLinkedCorrespondence={this.viewLinkedCorrespondence}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
	</div>
	}
/>
		);
	}
}

ExtensionOfTimeMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	contract: PropTypes.object.isRequired,
	extensionOfTime: PropTypes.object.isRequired,
	employees: PropTypes.array.isRequired,
	separablePortions: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	extensionOfTime: state.extensionsOfTime.extensionOfTime,
	contract: state.context.contract,
	employees: state.employees.employees,
	separablePortions: state.separablePortions.separablePortions,
	canEdit: canEdit(state, 'EditSeparablePortions'),
	isLoading: state.extensionsOfTime.isLoading,
	saveResult: state.extensionsOfTime.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (extensionOfTime, newFiles) => {
		dispatch(saveExtensionOfTime(extensionOfTime, newFiles, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (extensionOfTime, newFiles) => {
		dispatch(saveExtensionOfTime(extensionOfTime, newFiles, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ExtensionOfTimeMaintenance);
