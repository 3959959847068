const initialState = {
	incomingCorrespondence: {},
	outgoingCorrespondence: {},
	designCorrespondence: {},
	principalCorrespondence: {},
	extensionsOfTime: {},
	lots: {},
	lotItems: {},
	lotScheduleItems: {},
	progressClaims: {},
	scheduleOfRates: {},
	scheduleItems: {},
	variations: {},
	variationItems: {},
	variationScheduleItems: {}
};

const LOAD_TEMP_STATE = 'LOAD_TEMP_STATE';
const UPDATE_TEMP_STATE = 'UPDATE_TEMP_STATE';
const CLEAR_TEMP_STATE = 'CLEAR_TEMP_STATE';

export const loadTempState = (state) => ({ type: LOAD_TEMP_STATE, state });
export const updateTempState = (key, data) => ({ type: UPDATE_TEMP_STATE, key, data });
export const clearTempState = () => ({ type: CLEAR_TEMP_STATE });

export default (state = initialState, action) => {
	let newState = null;
	switch (action.type) {
		case LOAD_TEMP_STATE:
			return action.state ? {
				...initialState,
				...action.state
			} : state;
		case UPDATE_TEMP_STATE:
			newState = {
				...state
			};
			newState[action.key] = action.data;
			return newState;
		case CLEAR_TEMP_STATE:
			return initialState;
		default:
			return state;
	}
};
