import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import map from 'lodash/map';

class CloseContractWizardLotsPage extends Component {
	render() {
		return (
<div>
	{this.props.isLoading &&
		<div>
			<p>
				Checking for open lots.
			</p>
			<CircularProgress style={{ display: this.props.isLoading ? undefined : 'none' }} />
		</div>
	}
	{!this.props.isLoading && this.props.openLots && this.props.openLots.length > 0 &&
		<div>
		<p>You cannot close the Contract while there are open lots. The currently open lots are:</p>
			<List style={{ overflow: 'auto', maxHeight: '400px' }}>
				{
					map(this.props.openLots || [], (l) => (
						<ListItem
							key={l.lotId}
							onClick={() => this.props.onSelectLot(l)}
						>
							<ListItemText 
								primary={l.lotNo}
								secondary={`${l.lotDescription}`}
							/>
						</ListItem>
					))
				}
			</List>
		</div>
	}
	{!this.props.isLoading && (!this.props.openLots || this.props.openLots.length === 0) &&
		<p>All lots are closed, you may proceed</p>
	}
</div>
		);
	}
}

CloseContractWizardLotsPage.propTypes = {
	openLots: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onSelectLot: PropTypes.func.isRequired
};

export default CloseContractWizardLotsPage;
