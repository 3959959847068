import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import CircularProgress from '@material-ui/core/CircularProgress';
import RolesGrid from './rolesGrid';
import { deleteRole, requestRoles, clearRoles } from '../../reducers/roles';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PageLayout from '../pageLayout';

class Roles extends Component {
    constructor(props) {
        super(props);

        this.state = {
			roles: {}
        };

		this.onEditRole = this.onEditRole.bind(this);
		this.onDeleteRole = this.onDeleteRole.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestRoles());
	}
	static clearData(dispatch) {
		dispatch(clearRoles());
	}
	componentDidMount() {
		Roles.fetchData(this.props.dispatch);
	}

	onEditRole = (role) => {
		this.props.history.push(`/role/${role.roleId}`);
	}

	onDeleteRole(role) {
		this.props.deleteRole(role);
	}

	newRole = () => {
		this.props.history.push('/role');
	}

	render() {
		return (
<PageLayout
	title="Roles"
	isLoading={this.props.isLoading}
	actions={
		[
			{
				label: 'New Role',
				onClick: this.newRole
			}
		]
	}
	content={
		<ContentContainer>
			{this.props.isLoading &&
				<CircularProgress />
			}
			<RolesGrid 
				roles={this.props.roles} 
				onEditRole={this.onEditRole} 
				onDeleteRole={this.onDeleteRole} 
			/>
		</ContentContainer>
	}
/>
		);
	}
}

Roles.propTypes = {
	dispatch: PropTypes.func.isRequired,
	roles: PropTypes.array,
	deleteRole: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

Roles.defaultProps = {
	roles: []
};

const mapStateToProps = (state) => ({
	roles: state.roles.roles,
	isLoading: state.roles.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteRole: (role) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Role?',
			onOk: () => {
				dispatch(deleteRole(role));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Roles);
