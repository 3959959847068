import React from 'react';
import { connect } from 'react-redux';
import { hideHelpPage } from '../reducers/helpPages';
import { 
	Drawer
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { startsWith } from 'lodash';

const HelpDrawer = ({ showHelpPage, helpPage, hideHelpPage }) => {
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
    }, [showHelpPage]);

    let helpUrl = helpPage ? helpPage.helpUrl : '';
    if (helpUrl && !startsWith(helpUrl, 'http')) helpUrl = 'https://' + helpUrl;

    return (
        <Drawer 
            open={showHelpPage} 
            onClose={() => hideHelpPage() } style={{ width: '100%', maxWidth: '800px'}}
            anchor='right'
        >
            {loading && 
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '50%'
                }}>
                    <CircularProgress
                        size={40}
                        left={10}
                        top={50}
                        status="loading"
                    />
                </div>
            }
            {helpUrl && 
					<iframe
                        title="help"
						src={helpUrl}
						style={{ border: "0px solid white" }}
						name="intelligenceWidgetIframe"
						height="100%"
						width="800px%"
                        onLoad={() => setLoading(false)}
					></iframe>
            }
        </Drawer>
    );
};

HelpDrawer.propTypes = {

};

const mapStateToProps = (state) => ({
	showHelpPage: state.helpPages.showHelpPage,
	helpPage: state.helpPages.helpPage
});

const mapDispatchToProps = dispatch => ({
	hideHelpPage: () => dispatch(hideHelpPage())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HelpDrawer);
