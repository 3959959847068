import React from 'react';
import connectedRouterHOC from '../../../utils/connectedRouterHOC';
import PropTypes from 'prop-types';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions,
	Grid,
	MenuItem
} from '@material-ui/core';
import Button from '../../widgets/button';
import Form from '../../widgets/Form';
import DataTable from '../../widgets/dataTable';
import IconButton from '../../widgets/IconButton';
import TextField from '../../widgets/TextField';
import DataTableTextEditor from '../../widgets/dataTableTextEditor';
import DataTableNumericEditor from '../../widgets/dataTableNumericEditor';
import DataTableSelectEditor from '../../widgets/DataTableSelectEditor';
import SplitButton from '../../widgets/SplitButton';
import IconMenu from '../../widgets/IconMenu';
import MessagePanel from '../../messagePanel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import uniqueId from 'lodash/uniqueId';
import remove from 'lodash/remove';
import map from 'lodash/map';
import some from 'lodash/some';
import { globals } from '../../../globals';
import { units, errorColour } from '../../../variables';
import { post } from '../../../utils/ajax';
import { showErrorNotification } from '../../../reducers/notifications';

const ScheduleDialog = props => {
	const classes = props.classes;
	const [schedule, setSchedule] = React.useState({
		name: '',
		items: []
	});
	const [nameError, setNameError] = React.useState('');
	const tableRef = React.useRef(null);
	const prevScheduleRef = React.useRef();

	React.useEffect(() => {
		setSchedule(props.schedule);
	}, [props.schedule])

	React.useEffect(() => {
		const previousSchedule = prevScheduleRef.current;

		// Focus the first field when a new item is added
		if (schedule && previousSchedule && schedule.items.length > previousSchedule.items.length) {
			if (tableRef && tableRef.current) {
				const table = tableRef.current;
				let lastRow = table.rows[table.rows.length - 1];
				if (lastRow && lastRow.cells.length > 1) {
					let inputElement = lastRow.cells[1].querySelector('input');
					if (inputElement) {
						inputElement.focus();
					}
				}
			}
		}

		prevScheduleRef.current = schedule;
	}, [schedule])

	// React.useEffect(() => {
	// 	updateValues(schedule);
	// }, schedule);

	const onClose = () => {
		props.onCancel();
	};
	const onSubmit = () => {
		let hasErrors = false;
		if (!schedule.name) {
			hasErrors = true;
			setNameError("Name is required");
		} else {
			setNameError("");
		}
		for (let i = 0, ii = schedule.items.length; i < ii; i++) {
			const item = schedule.items[i];
			item._rateError = undefined;
			if (item.isTextOnlyLine) {
				if (!item.itemNo && !item.description) {
					hasErrors = true;
					item._itemNoError = 'Item No or Description is required for a Text Only Line';
				}
			} else {
				if (!item.rate || item.rate <= 0) {
					hasErrors = true;
					item._rateError = 'Rate is required';
				}
				if (!item.unit) {
					hasErrors = true;
					item._unitError = 'Unit is required';
				}
			}
		}

		if (hasErrors) {
			setSchedule({ ...schedule });
		} else {
			props.onOk(schedule);
		}
	};
	const addItem = (isTextOnly) => {
		const newItem = cloneDeep(globals.templates.scheduleOfRatesItem);
		newItem._clientId = uniqueId("item-");
		newItem.unit = 'each';
		newItem.rate = 0;
		newItem.qty = 0;
		if (isTextOnly) {
			newItem.isTextOnlyLine = true
		}
		setSchedule({
			...schedule,
			items: [
				...schedule.items,
				newItem
			]
		});
	};

	const columns = [
		{
			name: 'commands',
			excludeFromSearch: true,
			excludeFromFilter: true,
			headerStyle: { width: '10px' },
			cellStyle: (args) => {
				const style = { textAlign: 'center' };
				if (args.data._itemNoError || args.data._unitError || args.data._qtyError || args.data._rateError) {
					style.borderLeft = `5px solid ${errorColour}`;
				}
				return style;
			},
			headerRenderer: (args) => {
				return (
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconMenu
							size="small"
							icon={<AddIcon />}
							items={(closeMenu) => ([
								<MenuItem key="add-item" onClick={() => { addItem(); closeMenu(); }}>Add Item</MenuItem>,
								<MenuItem key="add-text-item" onClick={() => { addItem(true); closeMenu(); }}>Add Text Only Item</MenuItem>
							])}
						/>
					</div>
				);
			},
			cellRenderer: (args) => {
				return (
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton 
							onClick={ () => {
								remove(schedule.items, i => i._clientId === args.data._clientId);
								setSchedule({ ...schedule });
							} } style={{ padding: '2px' }}>
							<DeleteIcon />
						</IconButton>
					</div>
				);
			}	
		},
		{
			name: 'itemNo',
			label: 'Item No',
			headerStyle: { minWidth: '200px' },
			tooltip: (args) => args.data._itemNoError,
			cellStyle: (args) => {
				if (args.data._itemNoError) {
					return {
						backgroundColor: '#FFC7CE'
					};
				}
				return undefined;
			},
			cellRenderer: (args) =>
				<DataTableTextEditor
					key={`${args.data._clientId}-itemNo`}
					value={args.data.itemNo} 
					multiline={false}
					fullWidth={true}
					onChange={(v) => { 
						const item = find(schedule.items, i => i._clientId === args.data._clientId);
						if (item) {
							item.itemNo = v;
							if (item.itemNo) item._itemNoError = undefined;
							setSchedule({ ...schedule });
						}
					}}

				/>
		},
		{
			name: 'description',
			label: 'Description',
			headerStyle: { minWidth: '300px' },
			colspan: ({ data }) => {
				if (data.isTextOnlyLine) return 6;
				return 1;
			},
			cellRenderer: (args) =>
				<DataTableTextEditor
					key={`${args.data._clientId}-description`}
					value={args.value} 
					multiline={false}
					fullWidth={true}
					onChange={(v) => { 
						const item = find(schedule.items, i => i._clientId === args.data._clientId);
						if (item) {
							item.description = v;
							setSchedule({ ...schedule });
						}
					}}

				/>
		},
		{
			name: 'unit',
			label: 'Unit',
			headerStyle: { minWidth: '100px' },
			filterOperators: ['=', 'Contains', 'multi-select'],
			filterOptions: units,
			tooltip: (args) => args.data._unitError,
			cellStyle: (args) => {
				if (args.data._unitError) {
					return {
						backgroundColor: '#FFC7CE'
					};
				}
				return undefined;
			},
			cellRenderer: (args) =>
				<DataTableSelectEditor
					key={`${args.data._clientId}-unit`}
					value={args.value} 
					fullWidth={true}
					items={
						map(units, (u) => 
							({ value: u.key, label: u.text })
						)
					}
					onChange={(v) => { 
						const item = find(schedule.items, i => i._clientId === args.data._clientId);
						if (item) {
							item.unit = v;
							if (item.unit) item._unitError = undefined;
							setSchedule({ ...schedule });
						}
					}}

				/>
		},
		{
			name: 'qty',
			label: 'Qty',
			headerStyle: { minWidth: '100px' },
			dataType: 'number',
			tooltip: (args) => args.data._qtyError,
			cellStyle: (args) => {
				if (args.data._qtyError) {
					return {
						backgroundColor: '#FFC7CE'
					};
				}
				return undefined;
			},
			cellRenderer: (args) =>
				<DataTableNumericEditor
					key={`${args.data._clientId}-qty`}
					value={args.value} 
					label="qty"
					fullWidth={true}
					nullable={true}
					onChange={(v) => { 
						const item = find(schedule.items, i => i._clientId === args.data._clientId);
						if (item) {
							item.qty = v;
							if (item.qty && item.qty > 0) item._qtyError = undefined;
							setSchedule({ ...schedule });
						}
					}}

				/>
		},
		{
			name: 'forecastQty',
			label: 'Forecast Qty',
			headerStyle: { minWidth: '100px' },
			dataType: 'number',
			cellRenderer: (args) =>
				<DataTableNumericEditor
					key={`${args.data._clientId}-forecastQty`}
					label="forecastQty"
					value={args.value} 
					fullWidth={true}
					nullable={true}
					onChange={(v) => { 
						const item = find(schedule.items, i => i._clientId === args.data._clientId);
						if (item) {
							item.forecastQty = v;
							setSchedule({ ...schedule });
						}
					}}

				/>
		},
		{
			name: 'rate',
			label: 'Rate',
			headerStyle: { minWidth: '100px' },
			dataType: 'number',
			tooltip: (args) => args.data._rateError,
			cellStyle: (args) => {
				if (args.data._rateError) {
					return {
						backgroundColor: '#FFC7CE'
					};
				}
				return undefined;
			},
			cellRenderer: (args) =>
				<DataTableNumericEditor
					key={`${args.data._clientId}-rate`}
					label="rate"
					type="currency"
					value={args.value} 
					fullWidth={true}
					nullable={true}
					onChange={(v) => { 
						const item = find(schedule.items, i => i._clientId === args.data._clientId);
						if (item) {
							item.rate = v;
							if (item.rate && item.rate > 0) item._rateError = undefined;
							setSchedule({ ...schedule });
						}
					}}

				/>
		},
		{
			name: 'amount',
			label: 'Amount',
			headerStyle: { minWidth: '100px' },
			dataType: 'number',
			value: (args) => {
				return args.data.qty * args.data.rate;
			},
			cellRenderer: (args) => {
				const amount = (args.data.qty * args.data.rate).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
				return amount;
			}
		},
		{
			name: 'additionalInfo',
			label: 'Additional Info',
			headerStyle: { minWidth: '300px' },
			cellRenderer: (args) =>
				<DataTableTextEditor
					key={`${args.data._clientId}-addInfo`}
					value={args.value} 
					multiline={false}
					fullWidth={true}
					onChange={(v) => { 
						const item = find(schedule.items, i => i._clientId === args.data._clientId);
						if (item) {
							item.additionalInfo = v;
							setSchedule({ ...schedule });
						}
					}}

				/>
		}
	];
	const itemsHaveErrors = some(schedule.items, i => i._itemNoError || i._unitError || i._qtyError || i._rateError);

	return (
		<Dialog  fullWidth={true} maxWidth="lg" open={props.show} onClose={onClose} style={{ zIndex: 1200 }} classes={classes}>
			<DialogTitle>{schedule._clientId ? "Update Schedule" : "Add Schedule"}</DialogTitle>
			<DialogContent style={{ }}>
				<Form 
					onSubmit={onSubmit}
					style={{ }}
				>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								value={schedule.name}
								fullWidth={true}
								onChange={(e, v) => { 
									setSchedule({
										...schedule,
										name: v
									});
									if (!!v) setNameError('');
								}}
								label="Schedule No."
								errorText={nameError}
							/>
						</Grid>

						<Grid item xs={12}>
							<DataTable 
								data={schedule.items} 
								columns={columns}
								rowStyle={(data) => {
									if (data.isTextOnlyLine) {
										return {
											fontWeight: 'bold',
											fontStyle: 'italic'
										}
									} else {
										return null;
									}
								}}
								tableRef={tableRef}
							/>
									
							<div className="buttons" style={{
								display: 'flex',
								gap: '10px',
								marginTop: '10px',
								justifyContent: 'end'
							}}>
								<Button
									onClick={() => addItem()}
								>
									Add Item
								</Button>
								<Button
									onClick={() => addItem(true)}
								>
									Add Header
								</Button>
								<SplitButton
									component="label"
									items={(closeMenu) => [
										<MenuItem 
											key="download-template" 
											onClick={() => { 
												window.open('/api/schedule-of-rates/template', '_self');
												closeMenu(); 
											}}
										>Download Template</MenuItem>
									]}
								>
									Import Items
									<input
										type="file"
										hidden
										onChange={(e) => {
											if (e.target.files.length < 1) return;
											const formData = new FormData();
											formData.append('File', e.target.files[0]);
											
											post({
												url: '/api/schedule-of-rates/read-import-file',
												data: formData,
												onSuccess: (data) => {
													if (data.success) {
														setSchedule({
															...schedule,
															items: [
																...schedule.items,
																...map(data.items, i => ({
																	_clientId: uniqueId("item-"),
																	itemNo: i.itemNo,
																	description: i.description,
																	additionalInfo: i.additionalInfo,
																	unit: i.unit,
																	qty: i.qty,
																	rate: i.rate,
																	order: i.order,
																	isTextOnlyLine: i.isTextOnlyLine,
																	forecastQty: null
																}))
															]
														});
													} else {
														props.showErrorNotification(data.message);
													}
												},
												onError: (error) => {
													props.showErrorNotification(error.message);
												}
											});
										}} 
									/>
								</SplitButton>
							</div>
						</Grid>
					</Grid>
				</Form>
				<MessagePanel 
					isSuccess={!itemsHaveErrors} 
					message={itemsHaveErrors ? 'The schedule is not valid' : ''} 
				/>
			</DialogContent>
			<DialogActions>
				{schedule._clientId && 
					<Button onClick={() => { props.onRemove(schedule); }} error={true}>Remove</Button>
				}
				<Button onClick={onClose}>Cancel</Button>
	 			<Button onClick={onSubmit} type="submit" primary={true}>{schedule._clientId ? "Update" : "Add"}</Button>
			</DialogActions>
		</Dialog>
	);
};

ScheduleDialog.propTypes = {
	show: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};

ScheduleDialog.defaultProps = {
	show: false
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	showErrorNotification: (error) => {
		dispatch(showErrorNotification(error));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ScheduleDialog);
