import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import { toCurrency } from '../../utils/utils';
import round from 'lodash/round';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';

class ProgressClaimByLotReportContent extends React.Component {
	render() {
		const report = this.props.report || {};

		const claim = report.claim ? report.claim : { items: [] };
		const claimItems = claim.items;
		const claimItemsBySchedule = groupBy(claimItems, (i) => Number(`${i.scheduleOrder}.${i.scheduleOfRatesId}`));
		const tempClaimItems = filter(claim.items, i => i.claimType === 'Temporary');

		return (
		<div>
			<div className="lots-data">
				{
					map(claimItemsBySchedule, (items) => 
						!items[0].scheduleIsActive ? undefined : (
						<table className="report-table">
							<thead>	
								<tr className="schedule-header">
									<td colSpan={11}>{items[0].scheduleName}</td>
								</tr>
								<tr>
									<th>Item No</th>
									<th style={{ minWidth: '200px' }}>Description</th>
									<th>Unit</th>
									<th>Tendered Quantity</th>
									<th>Unit Rate($)</th>
									<th>Quantity Certified Previous Claim</th>
									<th>Certified Previous Claim</th>
									<th>Quantity To Date</th>
									<th>Certified to Date</th>
									<th>Certified this Period</th>
									<th style={{ minWidth: '200px' }}>Comments</th>
								</tr>
							</thead>
							<tbody>
								{
									(() => {
										let scheduleName = null;
										let prevCertTotal = 0;
										let certifiedToDateTotal = 0;
										let certifiedThisPeriodTotal = 0;

										const rows = [];

										scheduleName = items[0].scheduleName;
										
										forEach(items, (i) => {
											prevCertTotal += i.prevCertifiedAmount;
																						
											let certifiedToDate = 0;
											let certifiedThisPeriod = 0;
											if (!i.isTextOnlyLine) {
												certifiedToDate = i.prevCertifiedAmount + (i.certifiedAmount || i.calcCertifiedAmount);

												if (i.isNonLotBasedVariation) {
													certifiedThisPeriod = round(i.certifiedQty * i.rate, 2);
												} else if (i.certifiedAmount === null) {
													certifiedThisPeriod = i.calcCertifiedAmount;
												} else {
													certifiedThisPeriod = i.certifiedAmount;
												}
											}
											certifiedToDateTotal += certifiedToDate;
											certifiedThisPeriodTotal += certifiedThisPeriod;


											if (i.isTextOnlyLine) {
												rows.push(
													<tr>
														<td style={{ fontStyle: 'italic', backgroundColor: '#FAFAFA' }}>{i.itemNo}</td>
														<td style={{ fontStyle: 'italic', backgroundColor: '#FAFAFA' }}>{i.description}</td>
														<td colSpan='9' style={{ fontStyle: 'italic', backgroundColor: '#FAFAFA' }}>{i.additionalInfo}</td>
													</tr>
												);
											} else {
												rows.push(
													<tr>
														<td>{i.itemNo}</td>
														<td>{i.description}</td>
														<td style={{ textAlign: 'right' }}>{i.unit}</td>
														<td style={{ textAlign: 'right' }}>{i.qty}</td>
														<td style={{ textAlign: 'right' }}>{toCurrency(i.rate)}</td>
														<td style={{ textAlign: 'right' }}>{round(i.prevCertifiedQty, 3)}</td>
														<td style={{ textAlign: 'right' }}>{toCurrency(i.prevCertifiedAmount)}</td>
														<td style={{ textAlign: 'right' }}>{round(i.prevCertifiedQty + i.certifiedQty, 3)}</td>
														<td style={{ textAlign: 'right' }}>{toCurrency(certifiedToDate)}</td>
														<td style={{ textAlign: 'right' }}>{toCurrency(certifiedThisPeriod)}</td>
														<td style={{ textAlign: 'right' }}>{i.comments}</td>
													</tr>
												);
											}

											forEach(filter(report.lots, (l) => l.scheduleOfRatesItemId === i.scheduleOfRatesItemId), (l) => {
												const lotDesc = l.lotDescription ? `${l.lotNo} (${l.lotDescription})` : l.lotNo;
												rows.push(
													<tr style={{ fontSize: '8px' }}>
														<td></td>
														<td colSpan={4}>{lotDesc}</td>
														<td style={{ textAlign: 'right' }}>{l.periodId === report.periodId ? '' : round(l.certifiedQty, 4) || 0}</td>
														<td></td>
														<td style={{ textAlign: 'right' }}>{l.periodId === report.periodId ? round(l.certifiedQty, 4) || 0 : ''}</td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
												);
											});
										});

										rows.push(
											<tr className="schedule-total">
												<td colSpan={6}>{scheduleName} Total</td>
												<td style={{ textAlign: 'right' }}>{toCurrency(prevCertTotal)}</td>
												<td></td>
												<td style={{ textAlign: 'right' }}>{toCurrency(certifiedToDateTotal)}</td>
												<td style={{ textAlign: 'right' }}>{toCurrency(certifiedThisPeriodTotal)}</td>
												<td></td>
											</tr>
										);
											
										return rows;
									})()
								}
							</tbody>
						</table>
					))
				}

				{tempClaimItems.length > 0 && 
					<table className="report-table">
						<thead>	
							<tr className="schedule-header">
								<td colSpan={5}>Temporary Claim Items</td>
							</tr>
							<tr>
								<th>Item No</th>
								<th style={{ minWidth: '200px' }}>Description</th>
								<th>Contractor Claim</th>
								<th style={{ minWidth: '300px' }}>Comments</th>
							</tr>
						</thead>
						<tbody>
							{
								(() => {
									const rows = [];
									forEach(tempClaimItems, (i) => {
										rows.push(
											<tr>
												<td>{i.itemNo}</td>
												<td>{i.description}</td>
												<td style={{ textAlign: 'right' }}>{toCurrency(i.claimAmount, 3)}</td>
												<td style={{ textAlign: 'right' }}>{i.comments}</td>
											</tr>
										);
									});

									rows.push(
										<tr className="schedule-total">
											<td colSpan={3}>Temporary Claim Items Total</td>
											<td></td>
										</tr>
									);
										
									return rows;
								})()
							}
						</tbody>
					</table>
				}
			</div>
		</div>
    );
  }
}

ProgressClaimByLotReportContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default ProgressClaimByLotReportContent;
