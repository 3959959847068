
import React from 'react';
import PropTypes from 'prop-types';

const HandoverBooleanField = ({ label, value }) => (
	<div className="handover-field">
		<span className="handover-field-label">{label}</span>
		<span className="handover-field-value">{value}</span>
	</div>
);

HandoverBooleanField.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string
};

export default HandoverBooleanField;
