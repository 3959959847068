import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeContractContext, changePeriodContext } from '../reducers/context';
import ModalRoot from './modals/modalRoot';
import Notifications from 'react-notification-system-redux';
import { useHistory } from 'react-router-dom';

const App = props => {
	const history = useHistory();

	const onChangeContractContext = (contractId) => {
		props.dispatch(changeContractContext(contractId));
		history.push('/');
	};
	const onChangePeriodContext = (periodId) => {
		props.dispatch(changePeriodContext(periodId));
		if (props.scope === "period") {
			const component = props.component;
			if (!component) return;
			
			if (component.clearData) {
				component.clearData(props.dispatch);
			}
			if (component.fetchData) {
				// The props we pass don't match here however at this point
				// the components are just using the params which is the same
				// and there is no other easy way to do this at the moment
				component.fetchData(props.dispatch, props);
			}
		}
	};

	const onChangeContractAndPeriodContext = (contractId, periodId) => {
		props.dispatch(changeContractContext(contractId));
		props.dispatch(changePeriodContext(periodId));
		history.push('/');
	};

	const { notifications } = props;

	const notificationStyle = {
		NotificationItem: {
			DefaultStyle: {
				padding: '20px'
			},
			success: {
				borderTop: null,
				backgroundColor: '#35aa47',
				color: '#fff'
			},
			error: {
				borderTop: null,
				backgroundColor: '#d9534f',
				color: '#fff'
			},
			warning: {
				borderTop: null,
				backgroundColor: '#f0ad4e',
				color: '#fff'
			},
			info: {
				borderTop: null,
				backgroundColor: '#5bc0de',
				color: '#fff'
			}
		},
		Title: {
			DefaultStyle: {
				fontSize: '18px'
			},
		
			success: {
				color: '#fff'
			},
			error: {
				color: '#fff'
			},
			warning: {
				color: '#fff'
			},
			info: {
				color: '#fff'
			}
		},
		Dismiss: {
			DefaultStyle: {
				fontSize: '22px'
			},

			success: {
				color: '#fff',
				backgroundColor: null
			},
			error: {
				color: '#fff',
				backgroundColor: null
			},
			warning: {
				color: '#fff',
				backgroundColor: null
			},
			info: {
				color: '#fff',
				backgroundColor: null
			}
		}
	};

	return (
		<div>
			{React.Children.map(props.children, child => 
				React.cloneElement(child, { 
					...props, 
					changeContractContext: onChangeContractContext, 
					changePeriodContext: onChangePeriodContext, 
					changeContractAndPeriodContext: onChangeContractAndPeriodContext 
				})
			)}

			<ModalRoot />

			<Notifications
				notifications={notifications}
				style={notificationStyle}
			/>
		</div>
	);
;}

App.propTypes = {
	children: PropTypes.any.isRequired,
	dispatch: PropTypes.func.isRequired,
	notifications: PropTypes.array.isRequired
};

App.defaultProps = {

};

const mapStateToProps = (state) => ({
	notifications: state.toasts
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App);
