import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import CircularProgress from '@material-ui/core/CircularProgress';
import LotAdjustmentsGrid from './lotAdjustmentsGrid';
import { deleteLotAdjustment, requestLotAdjustments, clearLotAdjustments } from '../../reducers/lotAdjustments';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class LotAdjustments extends Component {
    constructor(props) {
        super(props);

        this.state = {
			lotAdjustments: {}
        };

		this.onEditLotAdjustment = this.onEditLotAdjustment.bind(this);
		this.onViewLotAdjustment = this.onViewLotAdjustment.bind(this);
		this.onDeleteLotAdjustment = this.onDeleteLotAdjustment.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestLotAdjustments());
	}
	static clearData(dispatch) {
		dispatch(clearLotAdjustments());
	}
	componentDidMount() {
		LotAdjustments.fetchData(this.props.dispatch);
	}

	onEditLotAdjustment = (lotAdjustment) => {
		this.props.history.push(`/lot-adjustment/${lotAdjustment.lotAdjustmentId}`);
	}
	onViewLotAdjustment = (lotAdjustment) => {
		this.props.history.push(`/lot-adjustment/${lotAdjustment.lotAdjustmentId}`);
	}
	onDeleteLotAdjustment(lotAdjustment) {
		this.props.deleteLotAdjustment(lotAdjustment);
	}

	newLotAdjustment = () => {
		this.props.history.push('/lot-adjustment');
	}

	render() {
		return (
<PageLayout
	title="Lot Adjustments"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Lot Adjustment',
				onClick: this.newLotAdjustment
			}
		] : []
	}
	content={
		<ContentContainer>
			{this.props.isLoading &&
				<CircularProgress />
			}
			<LotAdjustmentsGrid 
				lotAdjustments={this.props.lotAdjustments} 
				onEditLotAdjustment={this.onEditLotAdjustment} 
				onDeleteLotAdjustment={this.onDeleteLotAdjustment} 
				onViewLotAdjustment={this.onViewLotAdjustment} 
				canView={this.props.canView} 
				canEdit={this.props.canEdit} 
				currentPeriod={this.props.period}
			/>
		</ContentContainer>
	}
/>
		);
	}
}

LotAdjustments.propTypes = {
	dispatch: PropTypes.func.isRequired,
	lotAdjustments: PropTypes.array,
	deleteLotAdjustment: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	period: PropTypes.object.isRequired
};

LotAdjustments.defaultProps = {
	lotAdjustments: []
};

const mapStateToProps = (state) => ({
	lotAdjustments: state.lotAdjustments.lotAdjustments,
	canView: canView(state, 'ViewLotAdjustment'),
	canEdit: canEdit(state, 'EditLotAdjustments'),
	isLoading: state.lotAdjustments.isLoading,
	period: state.context.period
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteLotAdjustment: (lotAdjustment) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Lot Adjustment?',
			onOk: () => {
				dispatch(deleteLotAdjustment(lotAdjustment));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(LotAdjustments);
