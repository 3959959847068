import React from 'react';
import PropTypes from 'prop-types';
import { 
	Dialog as MuiDialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core';

const Dialog = props => {
	const propsToPassOn = { ...props };
	const children = props.children;

	delete propsToPassOn.title;
	delete propsToPassOn.children;
	delete propsToPassOn.actions;

	return (
		<MuiDialog {...propsToPassOn}>
			<DialogTitle style={{ padding: '0 20px' }}>
				{props.title}
			</DialogTitle>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions>
				{props.actions}
			</DialogActions>
		</MuiDialog>
	);
};

Dialog.propTypes = {
	title: PropTypes.any,
	children: PropTypes.any,
	actions: PropTypes.any
};

Dialog.defaultProps = {
	title: null,
	children: null,
	actions: null
};

export default Dialog;
