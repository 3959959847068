import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import ContentContainer from '../contentContainer';
import DateField from '../widgets/DateField';

class PeriodForm extends React.Component {
	state = {
		period: this.props.period
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			period: nextProps.period
		});
	}

	render() {
		const period = this.state.period;

return (
<div>
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={period.name}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('name', v); }}
				label="Period Name"
				errorText={getFieldErrorText(this.props.saveResult, 'name')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField
				value={period.startDate} 
				label="Start Date" 
				onChange={(d) => { this.props.updateField('startDate', d); }}
				error={getFieldErrorText(this.props.saveResult, 'StartDate')}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField 
				value={period.endDate} 
				lable="End Date" 
				onChange={(d) => { this.props.updateField('endDate', d); }}
				error={getFieldErrorText(this.props.saveResult, 'EndDate')}
				fullWidth={true}
			/>
		</FormFieldContainer>
	</ContentContainer>
</div>
		);
	}
}

PeriodForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	period: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default PeriodForm;
