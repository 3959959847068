import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { fetch, addTask } from 'domain-task';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { addOrUpdate } from '../utils/utils';

const initialState = {
	isLoading: false,
	saveResult: {
		progress: null,
		success: null,
		message: null,
		fields: [] 
	},
	designCorrespondence: null, 
	designCorrespondences: null
};

const emptyDesignCorrespondence = {
	_isNew: true,
	'designCorrespondenceId': 0,
	'contractId': 0,
	'inOrOut': 'In',
	'drrNumber': '',
	'projectNumber': '',
	'dateSent': null,
	'responseRequiredBy': null,
	'addressTo': '',
	'description': '',
	'raisedBy': null,
	'raisedByName': '',
	'actionedBy': null,
	'actionedByName': '',
	'response': '',
	'initialResponseDate': null,
	'finalResponseDate': null,
	'timeBarResponse': '',
	'status': 'Open',
	'dateAdded': new Date(),
	'files': [],
	tags: []
};

const CLEAR_DESIGN_CORRESPONDENCES = 'CLEAR_DESIGN_CORRESPONDENCES';
const CLEAR_DESIGN_CORRESPONDENCE = 'CLEAR_DESIGN_CORRESPONDENCE';
const REQUEST_DESIGN_CORRESPONDENCE = 'REQUEST_DESIGN_CORRESPONDENCE';
const RECEIVE_DESIGN_CORRESPONDENCE = 'RECEIVE_DESIGN_CORRESPONDENCE';
const REQUEST_DESIGN_CORRESPONDENCES = 'REQUEST_DESIGN_CORRESPONDENCES';
const RECEIVE_DESIGN_CORRESPONDENCES = 'RECEIVE_DESIGN_CORRESPONDENCES';
const CREATE_NEW_DESIGN_CORRESPONDENCE = 'CREATE_NEW_DESIGN_CORRESPONDENCE';
const SAVE_DESIGN_CORRESPONDENCE = 'SAVE_DESIGN_CORRESPONDENCE';
const RECEIVE_SAVE_DESIGN_CORRESPONDENCE_RESPONSE = 'RECEIVE_SAVE_DESIGN_CORRESPONDENCE_RESPONSE';
const DELETE_DESIGN_CORRESPONDENCE = 'DELETE_DESIGN_CORRESPONDENCE';
const RECEIVE_DELETE_DESIGN_CORRESPONDENCE_RESPONSE = 'RECEIVE_DELETE_DESIGN_CORRESPONDENCE_RESPONSE';
const SHOW_DESIGN_CORRESPONDENCE_ERRORS = 'SHOW_DESIGN_CORRESPONDENCE_ERRORS';
const UPDATE_DESIGN_CORRESPONDENCE_NUMBER = 'UPDATE_DESIGN_CORRESPONDENCE_NUMBER';
const UPDATE_DESIGN_CORRESPONDENCE_SAVE_PROGRESS = 'UPDATE_DESIGN_CORRESPONDENCE_SAVE_PROGRESS';

export const clearDesignCorrespondences = () => ({ type: CLEAR_DESIGN_CORRESPONDENCES });
export const clearDesignCorrespondence = () => ({ type: CLEAR_DESIGN_CORRESPONDENCE });
export const receiveDesignCorrespondences = (data) => ({ type: RECEIVE_DESIGN_CORRESPONDENCES, payload: { data } });
export const receiveDesignCorrespondence = (data) => ({ type: RECEIVE_DESIGN_CORRESPONDENCE, payload: { data } });
export const receiveSaveDesignCorrespondenceResponse = (data) => ({ type: RECEIVE_SAVE_DESIGN_CORRESPONDENCE_RESPONSE, data });
export const receiveDeleteDesignCorrespondenceResponse = (data) => ({ type: RECEIVE_DELETE_DESIGN_CORRESPONDENCE_RESPONSE, data });
export const updateCorrespondenceNumber = (number) => ({ type: updateCorrespondenceNumber, number });
export const updateSaveProgress = (progress) => ({ type: UPDATE_DESIGN_CORRESPONDENCE_SAVE_PROGRESS, progress });

export const createNewDesignCorrespondence = (contractId) =>
	(dispatch) => {	
		const newCorrespondence = {
			...cloneDeep(emptyDesignCorrespondence)
		};
		dispatch({ type: CREATE_NEW_DESIGN_CORRESPONDENCE, newCorrespondence: newCorrespondence });

		const fetchTask =  fetch(`/api/correspondence/get-design-correspondence-number?contractId=${contractId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.text();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				dispatch({ type: UPDATE_DESIGN_CORRESPONDENCE_NUMBER, number: data });
			}).catch((error) => {
				console.log('request failed', error);
			});
					
		addTask(fetchTask);
	};

export const requestDesignCorrespondences = () => (
	createFetchAction({
		objectName: 'Design Correspondence',
		passContext: true,
		url: '/api/design-correspondence',
		startAction: REQUEST_DESIGN_CORRESPONDENCES,
		success: (data) =>
			receiveDesignCorrespondences(map(data, (c) => ({
				...c,
				dateSent: c.dateSent ? new Date(c.dateSent) : null,
				responseRequiredBy: c.responseRequiredBy ? new Date(c.responseRequiredBy) : null,
				initialResponseDate: c.initialResponseDate ? new Date(c.initialResponseDate) : null,
				finalResponseDate: c.finalResponseDate ? new Date(c.finalResponseDate) : null
			})))
	})
);

export const requestDesignCorrespondence = (correspondenceId) => (
	createFetchAction({
		objectName: 'Design Correspondence',
		passContext: true,
		url: `/api/design-correspondence/${correspondenceId}`,
		startAction: REQUEST_DESIGN_CORRESPONDENCE,
		success: (data) =>
			receiveDesignCorrespondence({
				...data,
				dateSent: data.dateSent ? new Date(data.dateSent) : null,
				responseRequiredBy: data.responseRequiredBy ? new Date(data.responseRequiredBy) : null,
				initialResponseDate: data.initialResponseDate ? new Date(data.initialResponseDate) : null,
				finalResponseDate: data.finalResponseDate ? new Date(data.finalResponseDate) : null
			})
	})
);

export const saveDesignCorrespondence = (correspondence, newFiles, onSuccess) => {
	const errors = [];
	if (!correspondence.drrNumber) {
		errors.push({
			fieldName: 'DrrNumber',
			valid: false,
			message: 'DRR Number is required'
		});
	}
	if (errors.length > 0) return { type: SHOW_DESIGN_CORRESPONDENCE_ERRORS, data: errors };

	const formData = new FormData();
	formData.append('Correspondence', encodeURIComponent(JSON.stringify(correspondence)));
	forEach(newFiles, (f) => {
		if (f.data === 'DesignCorrespondence') {
			formData.append('DRRFiles', f.file);
		} else {
			formData.append('ResponseFiles', f.file);
		}
	});

	return createFormPostAction({
		passContext: true,
		url: '/api/design-correspondence',
		formData,
		startAction: SAVE_DESIGN_CORRESPONDENCE,
		onProgress: (progress) => updateSaveProgress(progress),
		success: (data, dispatch) => {
			dispatch(receiveSaveDesignCorrespondenceResponse({
				...data,
				object: data.object ? {
					...data.object,
					dateSent: data.object.dateSent ? new Date(data.object.dateSent) : null,
					responseRequiredBy: data.object.responseRequiredBy ? new Date(data.object.responseRequiredBy) : null,
					initialResponseDate: data.object.initialResponseDate ? new Date(data.object.initialResponseDate) : null,
					finalResponseDate: data.object.finalResponseDate ? new Date(data.object.finalResponseDate) : null
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteDesignCorrespondence = (correspondence, onSuccess) => (
	createPostAction({
		passContext: true,
		url: `/api/design-correspondence/${correspondence.designCorrespondenceId}/delete`,
		data: correspondence,
		startAction: DELETE_DESIGN_CORRESPONDENCE,
		success: (data, dispatch) => {
			dispatch(receiveDeleteDesignCorrespondenceResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_DESIGN_CORRESPONDENCES:
			return {
				...state,
				designCorrespondences: null
			};
		case CLEAR_DESIGN_CORRESPONDENCE:
			return {
				...state,
				designCorrespondence: null
			};
		case REQUEST_DESIGN_CORRESPONDENCES:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				designCorrespondences: []
			};
		case RECEIVE_DESIGN_CORRESPONDENCES:
			return {
				...state,
				isLoading: false,
				designCorrespondences: action.payload.data
			};
		case REQUEST_DESIGN_CORRESPONDENCE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				designCorrespondence: cloneDeep(emptyDesignCorrespondence)
			};
		case RECEIVE_DESIGN_CORRESPONDENCE:
			return {
				...state,
				isLoading: false,
				designCorrespondence: action.payload.data
			};
		case DELETE_DESIGN_CORRESPONDENCE:
			return { 
				...state
			};
		case RECEIVE_DELETE_DESIGN_CORRESPONDENCE_RESPONSE:
			return {
				...state,
				designCorrespondences: state.designCorrespondences.filter(c => c.designCorrespondenceId !== action.data.objectId) 
			};
		case CREATE_NEW_DESIGN_CORRESPONDENCE:
			return {
				...state,
				designCorrespondence: action.newCorrespondence
			};	
		case SAVE_DESIGN_CORRESPONDENCE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_DESIGN_CORRESPONDENCE_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				designCorrespondences: addOrUpdate(state.designCorrespondences, action.data.object, { designCorrespondenceId: action.data.object.designCorrespondenceId }), 
				designCorrespondence: action.data.object,				
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_DESIGN_CORRESPONDENCE_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		case UPDATE_DESIGN_CORRESPONDENCE_NUMBER:
			return {
				...state,
				designCorrespondence: {
					...state.designCorrespondence,
					drrNumber: action.number
				}
			};
		case UPDATE_DESIGN_CORRESPONDENCE_SAVE_PROGRESS:
			return {
				...state,
				saveResult: {
					...state.saveResult,
					progress: action.progress
				}
			};
		default:
			return state;
	}
};
