import React from 'react';
import { 
	Table, 
	TableHead,
	TableBody, 
	TableRow, 
	TableCell 
} from '@material-ui/core';
import map from 'lodash/map';
import IconButton from '../../widgets/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import UserAccessRoleDialog from './UserAccessRoleDialog';

const UserAccessField = props => {
	const [showRoleDialog, setShowRoleDialog] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState([]);
	const value = props.value;

	return (
		<React.Fragment>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>User</TableCell>
						<TableCell>Roles</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{map(value, (u, i) =>
						<TableRow key={`contact-${i}`}>
							<TableCell>
								{u.name}
								{u.hasFullAccess && <p style={{ fontStyle: 'italic', margin: 0 }}>User is an administrator and has access to all contracts</p>}
							</TableCell>
							<TableCell>
								<div style={{ display: 'flex' }}>
									<span style={{ flexGrow: 2 }}>{map(u.roles, (r) => r.name).join(',')}</span>
									<IconButton 
										onClick={ () => { 
											setSelectedUser(u);
											setShowRoleDialog(true); 
										}} 
										size="small"
									>
										<EditIcon />
									</IconButton>
								</div>
								
							</TableCell>
						</TableRow>
					)}
					{value.length === 0 && 
						<TableRow>
							<TableCell colSpan={3}>No users to set access for</TableCell>
						</TableRow>
					}
				</TableBody>
			</Table>

			<UserAccessRoleDialog
				show={showRoleDialog}
				onCancel={() => setShowRoleDialog(false)}
				userRoles={selectedUser ? selectedUser.roles : []}
				onChange={(userRoles) => {
					selectedUser.roles = userRoles;
					props.onChange(value);
					setShowRoleDialog(false);
				}}
			/>
		</React.Fragment>
	);
};

export default UserAccessField;
