import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { Autocomplete as MuiAutoComplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import { 
	TextField
} from '@material-ui/core';
import { get, post } from '../../utils/ajax';
import filter from 'lodash/filter';
import { getContractId } from '../../selectors/contract';
import uniqueId from 'lodash/uniqueId';
import toLower from 'lodash/toLower';
import startsWith from 'lodash/startsWith';
import { chipColour } from '../../variables';
import { makeStyles } from '@material-ui/core/styles';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import find from 'lodash/find';
import { replaceObject } from '../../utils/utils';

const useStyles = makeStyles({
	root: {
		backgroundColor: chipColour,
        color: '#fff',
        border: 'none'
	},
	deleteIcon: {
		color: '#eee',
	}
});

const TagField = props => {
	const [id] = React.useState(props.id || uniqueId('date-field-'));
    const [loading, setLoading] = React.useState(true);
    const [options, setOptions] = React.useState([]);
    const classes = useStyles();

	React.useEffect(() => {
        get({
            url: `/api/tags?contractId=${props.contractId}`,
            onSuccess: data => {
                setLoading(false);
                setOptions(data);
            },
            onError: (error) => {     
                setLoading(false);
            }
        });
	}, []);

    return (
        <MuiAutoComplete
            multiple
            id={id}
            options={options}
            freeSolo
            loading={loading}
            value={props.value}
            getOptionLabel={(option) => option.name}
            onChange={(event, updatedTags) => {
                const newTag = find(updatedTags, t => typeof t === 'string' || (t.inputValue));
                if (newTag) {
                    const tagName = typeof newTag === 'string' ? newTag : newTag.inputValue;
                    post({
                        url: `/api/tags?contractId=${props.contractId}`,
                        data: {
                            name: tagName
                        },
                        onSuccess: data => {
                            props.onChange(replaceObject(updatedTags, data.object, t => typeof t === 'string' || (t.inputValue)));
                        },
                        onError: (error) => {
                            props.showError(error.message)

                        }
                    });
                } else {
                    props.onChange(updatedTags);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, o => startsWith(toLower(o.name), toLower(params.inputValue)));
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip 
                        {...getTagProps({ index })} 
                        size="small"
                        classes={{ 
                            root: classes.root, 
                            deleteIcon: classes.deleteIcon 
                        }} 
                        label={option.name} 
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="filled"
                    label={props.label}
                    placeholder={props.placeholder}
                />
            )}
        />
    );   
}

TagField.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	fullWidth: PropTypes.bool,
	required: PropTypes.bool,
	errorText: PropTypes.string,
	variant: PropTypes.string,
	onChange: PropTypes.func,
};

TagField.defaultProps = {
	id: '',
	label: '',
	placeholder: '',
	required: false,
	errorText: undefined,
	variant: 'filled',
	fullWidth: false,
	onChange: () => {}
};

const mapStateToProps = (state) => ({
	contractId: getContractId(state)
});

const mapDispatchToProps = (dispatch) => ({
    showSuccess: (message) => {
		dispatch(showSuccessNotification(message));
	},
	showError: (error) => {
		dispatch(showErrorNotification(error));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(TagField);
