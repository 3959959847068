/* eslint no-mixed-operators: "off" */
/* eslint prefer-template: "off" */
/* eslint one-var: "off" */
/* eslint no-var: "off" */
/* eslint comma-spacing: "off" */
/* eslint space-infix-ops: "off" */
/* eslint one-var-declaration-per-line: "off" */
/* eslint quotes: "off" */
/* eslint no-bitwise: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

import React from 'react';
import findIndex from 'lodash/findIndex';
import { fetch, addTask } from 'domain-task';
import forEach from 'lodash/forEach';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isDate from 'lodash/isDate';
import isValidDate from 'date-fns/isValid';
import history from '../history';

export const addOrUpdate = (arr, obj, predicate) => {
	if (!arr) return [obj];
	const index = findIndex(arr, predicate);
	if (index === -1) {
		return arr.concat(obj);
	} else {
		return arr.slice(0, index).concat(obj, arr.slice(index + 1));
	}
};

export const replaceObject = (arr, obj, predicate) => {
	if (!arr) return [obj];
	const index = findIndex(arr, predicate);
	if (index === -1) {
		return arr;
	} else {
		return arr.slice(0, index).concat(obj, arr.slice(index + 1));
	}
};

/**
 * wkhtmltopdf (which uses QtWeb) fail silently when the second Intl.NumberFormat is setup.
 * No idea why. As a work around just dynamically call them for wkhtmltopdf
 */
const isQtWeb = () => {
	const windowIfDefined = typeof window === 'undefined' ? null : window;
	if (!windowIfDefined) return false;
	return navigator.appVersion.indexOf('QtWeb') > -1 || navigator.appVersion.indexOf('wkhtmltopdf') > -1;
};

const currencyFormatter = isQtWeb() ? null : new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' });
export const toCurrency = (value) => {
	if (currencyFormatter === null) return (value === null || value === undefined) ? '' : new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value || 0);
	return (value === null || value === undefined) ? '' : currencyFormatter.format(value || 0);
};

const percentFormatter = isQtWeb() ? null : new Intl.NumberFormat('en-AU', { style: 'percent', maximumFractionDigits: 2 });
export const toPercent = (value) => {
	if (percentFormatter == null) return (value === null || value === undefined) ? '' : new Intl.NumberFormat('en-AU', { style: 'percent', maximumFractionDigits: 2 }).format(value || 0);
	return (value === null || value === undefined) ? '' : percentFormatter.format(value || 0);
};

export const toNumber = (value) => (
	value === null || value === undefined ? null : Number(value.replace(/[^-0-9.]+/g, ''))
);

export const runClientSideOnly = (f) => {
	f.call(this);
};

export const formatBytes = (bytes, decimals) => {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals || 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
 };

 export const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
	// console.log('Query variable %s not found', variable);
	return null;
};

// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
export const shadeColour = (color, percent) => {   
    var f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
    return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
};

export const groupByArray = (xs, key) => (
	xs.reduce((rv, x) => { 
		const v = key instanceof Function ? key(x) : x[key]; 
		const el = rv.find((r) => r && r.key === v); 
		if (el) { 
			el.values.push(x); 
		} else { 
			rv.push({ key: v, values: [x] }); 
		} 
		return rv; 
	}, [])
);

/**
 * @param {Object} query
 */
export const addQuery = (query) => {
    const location = Object.assign({}, history.location);
    Object.assign(location.query, query);
    history.push(location);
};

/**
 * @param {...String} queryNames
 */
export const removeQuery = (...queryNames) => {
    const location = Object.assign({}, history.location);
    queryNames.forEach(q => delete location.query[q]);
    history.push(location);
};

export const get = (url) => {
	const headers = {
		'cache-control': 'no-store',
		'pragma': 'no-cache'
	};
	if (global._cookies) {
		let cookieString = '';
		forEach(global._cookies, (c) => {
			cookieString += `${c.key}=${c.value};`;
		});
		headers.Cookie = cookieString;
	}
	const fetchTask = fetch(url, { 
		credentials: 'same-origin',
		headers: headers
	})
	.then(response => {
		if (response.status >= 200 && response.status < 300) return response.json();

		const error = new Error(response.statusText);
		error.response = response;
		throw error;
	});

	addTask(fetchTask);
	return fetchTask;
};

export const formatDate = (dateValue, formatString) => {
	if (!dateValue) return '';
	if (isDate(dateValue)) {
		return format(dateValue, formatString);
	} else {
		const d = parseISO(dateValue);
		return isValidDate(d) ? format(d, formatString) : '';
	}
};

export const getPath = () => {
	const windowIfDefined = typeof window === 'undefined' ? null : window;
	return windowIfDefined ? windowIfDefined.location.pathname : '';
};


// Get value of cookie by name
export const getCookieValue = name => {
	const allCookies = decodeURIComponent(document.cookie).split("; ");
	for (let i = 0; i < allCookies.length; i++) {
		const cookie = allCookies[i];
		if (cookie.startsWith(name + "=")) return cookie.substring(name.length + 1);
	}

	return "";
};

export const useMountEffect = (fun) => React.useEffect(fun, []);

export const tryParseJson = jsonString => {
	try {
		return JSON.parse(jsonString);
	} catch (ex) {
		return null;
	}
}

export const getInitials = text => {
	return text.split(" ").map((n)=>n[0]).join(".");
};

export const getPathFromUrl = (url) => {
	return url.split(/[?#]/)[0];
};
