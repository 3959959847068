import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import TextField from '../widgets/TextField';
import FormContainer from '../formContainer';
import FormFieldContainer from '../formFieldContainer';
import SelectField from '../widgets/selectField';
import NumericField from '../widgets/numericField';
import Checkbox from '../widgets/Checkbox';
import map from 'lodash/map';
import { units } from '../../variables';
import toLower from 'lodash/toLower';
import trim from 'lodash/trim';

class ScheduleItemDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			item: {
				...props.scheduleItem
			}
		};
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			item: {
				...nextProps.scheduleItem
			}
		});
	}

	updateFieldValue(field, newValue) {
		const item = this.state.item;
		item[field] = newValue;
        this.setState({
			item
        });
	}

	onOk() {
		const item = this.state.item;

		const qty = Number(item.qty);
		const rate = Number(item.rate);
		const forecastQty = Number(item.forecastQty);
		if (!rate && !item.isTextOnlyLine) return;
		item.qty = qty;
		item.rate = rate;
		item.forecastQty = forecastQty;

		this.props.addScheduleItem(item);
	}

	render() {
		const item = this.state.item;

		return (
	<Dialog
		title={item.itemNo ? item.itemNo : 'Schedule of Rates Item'}
		actions={
			<React.Fragment>
				{this.props.canEdit &&
					<Button
						label={item._isNew ? 'Add' : 'Update'}
						primary={true}
						onClick={() => this.onOk()}
					/>
				}
				<Button
					label={this.props.canEdit ? "Cancel" : "Close"}
					primary={false}
					onClick={this.props.cancel}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.cancel}
		scroll='paper'
		fullWidth={true}
		maxWidth='lg'
	>
		<FormContainer>
			<FormFieldContainer>
				<Checkbox
					label="Is Text Only Line"
					labelPosition="right"
					checked={item.isTextOnlyLine}
					onCheck={(e, v) => { this.updateFieldValue('isTextOnlyLine', v); }}
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<TextField
					value={item.itemNo}
					fullWidth={true}
					onChange={(e, v) => { this.updateFieldValue('itemNo', v); }}
					label="Item No"
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<TextField
					value={item.description}
					fullWidth={true}
					onChange={(e, v) => { this.updateFieldValue('description', v); }}
					label='Description'
				/>
			</FormFieldContainer>

			<FormFieldContainer>
				<TextField
					value={item.additionalInfo}
					fullWidth={true}
					onChange={(e, v) => { this.updateFieldValue('additionalInfo', v); }}
					label='Additional Info'
				/>
			</FormFieldContainer>

			{!item.isTextOnlyLine && 
			<FormFieldContainer classes={['s12', 'm6']}>
				<NumericField
					type="decimal"
					value={item.qty}
					fullWidth={true}
					onChange={(v) => { this.updateFieldValue('qty', v); }}
					label="Quantity"
				/>
			</FormFieldContainer>
			}

			{!item.isTextOnlyLine && 
			<FormFieldContainer classes={['s12', 'm6']}>
				<SelectField 
					value={trim(toLower(item.unit))} 
					label="Unit" 
					onChange={(v) => { this.updateFieldValue('unit', v); } } 
					fullWidth={true}
					items={
						map(units, (u) => 
							({ value: u.key, label: u.text })
						)
					}
				>
				</SelectField>
			</FormFieldContainer>
			}

			{!item.isTextOnlyLine && 
			<FormFieldContainer classes={['s12', 'm6']}>
				<NumericField
					type="decimal"
					value={item.rate}
					fullWidth={true}
					required={true}
					onChange={(v) => { this.updateFieldValue('rate', v); }}
					label="Rate"
				/>
			</FormFieldContainer>
			}


			{!item.isTextOnlyLine && 
			<FormFieldContainer classes={['s12', 'm6']}>
				<NumericField
					type="decimal"
					value={item.forecastQty}
					fullWidth={true}
					nullable={true}
					onChange={(v) => { this.updateFieldValue('forecastQty', v); }}
					label="Forecast Quantity"
				/>
			</FormFieldContainer>
			}

		</FormContainer>
	</Dialog>
		);
	}
}

ScheduleItemDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	scheduleItem: PropTypes.object.isRequired,
	addScheduleItem: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	cancel: PropTypes.func.isRequired
};

export default ScheduleItemDialog;
