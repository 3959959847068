import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import PersistedDataTable from '../widgets/persistedDataTable';
import map from 'lodash/map';

class DesignCorrespondenceGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.correspondences || this.props.correspondences.length !== nextProps.correspondences.length;
	}

	render() {
		const correspondences = this.props.correspondences || [];

		const columns = [
			{ name: 'drrNumber', label: 'DRR Number' },
			{ 
				name: 'hasAttachment', 
				label: <AttachmentIcon />,
				fullLabel: 'Has Attachments',
				dataType: 'bool',
				value: args => args.data.files && args.data.files.length > 0,
				cellRenderer: args => 
					args.data.files && args.data.files.length > 0 ? <AttachmentIcon /> : <span></span>,
				cellStyle: {
					textAlign: 'center'
				},
				filterOperators: ['='],

			},
			{ name: 'dateSent', label: 'Sent Date', dataType: 'date' },
			{ name: 'description', label: 'Description', headerStyle: { minWidth: '300px' } },
			{ name: 'raisedByName', label: 'Issued By' },
			{ name: 'addressTo', label: 'Issued To' },
			{ name: 'responseRequiredBy', label: 'Response Required', dataType: 'date' },
			{ name: 'finalResponseDate', label: 'Response Date', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellClassName: (args) => { 
					// !important used to ensure color stays when row hover applies
					switch (args.data.status) {
						case 'Closed':
							return 'success-cell';
						case 'Awaiting Response':
							return 'warning-cell';
						default:
							return null;
					}
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed']
			},
			{
				name: 'tags',
				label: 'Tags',
				displayValue: (args) => 
					map(args.data.tags, (t) => t.name).join(','),
				filterOperators: ['multi-select'],
				filterOptions: this.props.tags,
				filterOptionsKey: 'tagId',
				filterOptionsText: 'name'
			},
			{ name: 'comments', label: 'Comments' }
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) =>
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="designCorrespondence"
				data={correspondences} 
				columns={columns}
			/>
		);
	}
}

DesignCorrespondenceGrid.propTypes = {
	correspondences: PropTypes.array,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditCorrespondence: PropTypes.func.isRequired,
	onViewCorrespondence: PropTypes.func.isRequired,
	onDeleteCorrespondence: PropTypes.func.isRequired,
	tags: PropTypes.array
};

DesignCorrespondenceGrid.defaultProps = {
	correspondences: [],
	tags: []
};

export default DesignCorrespondenceGrid;
