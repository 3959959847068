import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PageLayout from '../pageLayout';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import HelpPagesGrid from './HelpPagesGrid';
import { get, post } from '../../utils/ajax';
import filter from 'lodash/filter';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';

const HelpPages = props => {
	const [state, setState] = React.useState({
		helpPages: [],
		isLoading: false
	});

	
	React.useEffect(() => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		get({
			url: `/api/help-pages`,
			onSuccess: data => setState({ helpPages: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	}, [props.helpPageId]);

	const onDeleteHelpPage = (helpPage) => {
		props.confirmDelete(helpPage, () => {
			setState(prevState => ({ ...prevState, isLoading: true }));
			
			post({
				url: `/api/help-pages/${helpPage.helpPageId}/delete`,
				onSuccess: data => {
					setState({ isLoading: false, helpPages: filter(state.helpPages, c => c.helpPageId !== helpPage.helpPageId) });

					if (data.success) {
						props.showSuccessNotification(data.message);
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	};

	return (
		<PageLayout
			title="Help Pages"
			isLoading={state.isLoading}
			actions={
				props.canEdit ? 
				[
					{
						label: 'New Help Page',
						onClick: () => { props.history.push('/help-page'); }
					}
				] : []
			}
			content={
				<div>
					<ContentContainer>
						<HelpPagesGrid
							helpPages={state.helpPages}
							canView={props.canView}
							canEdit={props.canEdit}
							onEditHelpPage={helpPage => { props.history.push(`/help-page/${helpPage.helpPageId}`); }}
							onViewHelpPage={helpPage => { props.history.push(`/help-page/${helpPage.helpPageId}`); }}
							onDeleteHelpPage={onDeleteHelpPage}
						/>
					</ContentContainer>
				</div>
			}
		/>
    );
};

HelpPages.propTypes = {
	helpPageId: PropTypes.number.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	confirmDelete: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

HelpPages.defaultProps = {
};

const mapStateToProps = (state) => ({
	helpPageId: state.context.helpPage ? state.context.helpPage.helpPageId : 0,
	canView: canView(state, 'ViewHelpPages'),
	canEdit: canEdit(state, 'EditHelpPages'),
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	confirmDelete: (helpPage, onOk) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Help Page?',
			onOk: () => {
				if (onOk) onOk(helpPage);
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(HelpPages);
