// Constant defining the action type
const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
const TOGGLE_MENU = 'TOGGLE_MENU';
const UPDATE_LOCATION = 'UPDATE_LOCATION';

export const setActiveMenu = (menu) => ({ type: SET_ACTIVE_MENU, menu });
export const toggleMenu = (menu) => ({ type: TOGGLE_MENU, menu });
export const updateLocation = (location) => ({ type: UPDATE_LOCATION, location });

const initialState = {
	collapsedMenus: {
		qse: false,
		contractual: true,
		reports: true,
		registers: true,
		surveillance: true,
		certificates: true,
		forecasting: true,
		admin: false
	}
};

export default (state = initialState, action) => {
	if (action.type === TOGGLE_MENU) {
		const newState = {
			...state,
			collapsedMenus: { ...state.collapsedMenus }
		};
		newState.collapsedMenus[action.menu] = !newState.collapsedMenus[action.menu];
		return newState;
	}

	if (action.type === SET_ACTIVE_MENU) {
		return {
			...state,
			activeMenu: action.menu
		};
	}

	if (action.type === UPDATE_LOCATION) {
		return {
			...state,
		};
	}

	return state;
};
