import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import map from 'lodash/map';
import { globals } from '../globals';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	practicalCompletion: null, 
	practicalCompletions: null
};

const CLEAR_PRACTICAL_COMPLETIONS = 'CLEAR_PRACTICAL_COMPLETIONS';
const CLEAR_PRACTICAL_COMPLETION = 'CLEAR_PRACTICAL_COMPLETION';
const REQUEST_PRACTICAL_COMPLETION = 'REQUEST_PRACTICAL_COMPLETION';
const RECEIVE_PRACTICAL_COMPLETION = 'RECEIVE_PRACTICAL_COMPLETION';
const REQUEST_PRACTICAL_COMPLETIONS = 'REQUEST_PRACTICAL_COMPLETIONS';
const RECEIVE_PRACTICAL_COMPLETIONS = 'RECEIVE_PRACTICAL_COMPLETIONS';
const CREATE_NEW_PRACTICAL_COMPLETION = 'CREATE_NEW_PRACTICAL_COMPLETION';
const SAVE_PRACTICAL_COMPLETION = 'SAVE_PRACTICAL_COMPLETION';
const RECEIVE_SAVE_PRACTICAL_COMPLETION_RESPONSE = 'RECEIVE_SAVE_PRACTICAL_COMPLETION_RESPONSE';
const DELETE_PRACTICAL_COMPLETION = 'DELETE_PRACTICAL_COMPLETION';
const RECEIVE_DELETE_PRACTICAL_COMPLETION_RESPONSE = 'RECEIVE_DELETE_PRACTICAL_COMPLETION_RESPONSE';
const SHOW_PRACTICAL_COMPLETION_ERRORS = 'SHOW_PRACTICAL_COMPLETION_ERRORS';

export const clearPracticalCompletions = () => ({ type: CLEAR_PRACTICAL_COMPLETIONS });
export const clearPracticalCompletion = () => ({ type: CLEAR_PRACTICAL_COMPLETION });
export const receivePracticalCompletions = (data) => ({ type: RECEIVE_PRACTICAL_COMPLETIONS, payload: { data } });
export const receivePracticalCompletion = (data) => ({ type: RECEIVE_PRACTICAL_COMPLETION, payload: { data } });
export const createNewPracticalCompletion = () =>
	(dispatch, getState) => {
		dispatch({ type: CREATE_NEW_PRACTICAL_COMPLETION, data: { maintenancePeriod: getState().context.contract.defectsLiabilityPeriod } });
	};
 
export const receiveSavePracticalCompletionResponse = (data) => ({ type: RECEIVE_SAVE_PRACTICAL_COMPLETION_RESPONSE, data });
export const receiveDeletePracticalCompletionResponse = (data) => ({ type: RECEIVE_DELETE_PRACTICAL_COMPLETION_RESPONSE, data });

export const requestPracticalCompletions = () => (
	createFetchAction({
		objectName: 'Practical Completions',
		passContext: true,
		url: '/api/practical-completion',
		startAction: REQUEST_PRACTICAL_COMPLETIONS,
		success: (data) => 
			receivePracticalCompletions(map(data, (e) => ({
				...e,
				completionDate: e.completionDate ? new Date(e.completionDate) : null
			})))
	})
);

export const requestPracticalCompletion = (practicalCompletionId) => (
	createFetchAction({
		objectName: 'Practical Completion',
		passContext: true,
		url: `/api/practical-completion/${practicalCompletionId}`,
		startAction: REQUEST_PRACTICAL_COMPLETION,
		success: (data) => 
			receivePracticalCompletion({
				...data,
				completionDate: data.completionDate ? new Date(data.completionDate) : null
			})
	})
);

export const savePracticalCompletion = (practicalCompletion, onSuccess) => {
	const errors = [];
	if (errors.length > 0) return { type: SHOW_PRACTICAL_COMPLETION_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/practical-completion',
		data: practicalCompletion,
		startAction: SAVE_PRACTICAL_COMPLETION,
		success: (data, dispatch) => {
			dispatch(receiveSavePracticalCompletionResponse({
				...data,
				object: data.object ? {
					...data.object,
					completionDate: data.object.completionDate ? new Date(data.object.completionDate) : null
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deletePracticalCompletion = (practicalCompletion) => (
	createPostAction({
		passContext: true,
		url: `/api/practical-completion/${practicalCompletion.practicalCompletionId}/delete`,
		data: practicalCompletion,
		startAction: DELETE_PRACTICAL_COMPLETION,
		success: (data) => receiveDeletePracticalCompletionResponse(data)
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_PRACTICAL_COMPLETIONS:
			return {
				...state,
				practicalCompletions: null
			};
		case CLEAR_PRACTICAL_COMPLETION:
			return {
				...state,
				practicalCompletion: null
			};
		case REQUEST_PRACTICAL_COMPLETIONS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				practicalCompletions: []
			};
		case RECEIVE_PRACTICAL_COMPLETIONS:
			return {
				...state,
				isLoading: false,
				practicalCompletions: action.payload.data
			};
		case REQUEST_PRACTICAL_COMPLETION:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				practicalCompletion: { ...cloneDeep(globals.templates.practicalCompletion) }
			};
		case RECEIVE_PRACTICAL_COMPLETION:
			return {
				...state,
				isLoading: false,
				practicalCompletion: action.payload.data
			};
		case DELETE_PRACTICAL_COMPLETION:
			return { 
				...state
			};
		case RECEIVE_DELETE_PRACTICAL_COMPLETION_RESPONSE:
			return {
				...state,
				practicalCompletions: state.practicalCompletions.filter(e => e.practicalCompletionId !== action.data.objectId) 
			};
		case CREATE_NEW_PRACTICAL_COMPLETION:
			return {
				...state,
				practicalCompletion: { ...cloneDeep(globals.templates.practicalCompletion), maintenancePeriod: action.data.maintenancePeriod }
			};	
		case SAVE_PRACTICAL_COMPLETION:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_PRACTICAL_COMPLETION_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				practicalCompletions: addOrUpdate(state.practicalCompletions, action.data.object, { practicalCompletionId: action.data.object.practicalCompletionId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_PRACTICAL_COMPLETION_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
