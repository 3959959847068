import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { emptySaveResult } from '../../variables';
import Settings from './Settings';
import { get, post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { canEdit } from '../../selectors/canEdit';

const SettingsController = ({ 
	canEditSettings, 
	history,
	showSuccessNotification,
	showErrorNotification 
}) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [saveResult, setSaveResult] = React.useState({ ...emptySaveResult });
	const [settings, setSettings] = React.useState({});

	React.useEffect(() => {
		setIsLoading(true);
		get({
			url: `/api/settings`,
			onSuccess: data => {
				setIsLoading(false);
				setSettings(data);
			},
			onError: (error) => {
				setIsLoading(false);
				showErrorNotification(error.message);
			}
		});
	}, []);

	const validate = (settings) => {
		const errors = [];
		return errors;
	};

	const save = (settings, logo, onSuccess) => {
		const errors = validate(settings);
		if (errors.length > 0) {
			setSaveResult({
				success: false,
				message: 'Please correct the errors',
				fields: errors
			});
			return;
		}

		const formData = new FormData();

		formData.append("Settings", encodeURIComponent(JSON.stringify(settings)));
		if (logo) formData.append("Logo", logo);
	
		setIsLoading(true);
		post({
			url: `/api/settings`,
			data: formData,
			onSuccess: data => {
				setIsLoading(false);
				setSettings(data.success ? data.object : settings);
				setSaveResult({
					success: data.success,
					message: data.message,
					fields: data.fields
				});

				if (data.success) {
					showSuccessNotification(data.message);
					if (onSuccess) onSuccess();
				} else {
					showErrorNotification(data.message);
				}
			},
			onError: error => {
				setIsLoading(false);
				showErrorNotification(error.message);
			}
		});
	};

	return (
		<Settings
			isLoading={isLoading}
			settings={settings}
			canEdit={canEditSettings}
			saveResult={saveResult}
			onSave={(t, logo) => save(t, logo, () => { history.push('/'); })}
			onSaveAndContinue={(t, logo) => save(t, logo)}
			onCancel={() => history.goBack() }
		/>
	);
};


SettingsController.propTypes = {
	canEditSettings: PropTypes.bool.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	canEditSettings: canEdit(state, 'EditSettings')
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(SettingsController);
