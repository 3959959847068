/* eslint react/prop-types: "off" */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from './components/app';
import Layout from './components/layout';
import ReportLayout from './components/reportLayout';
import HandoverLayout from './components/handover/handoverLayout';
import Home from './components/home';

import LoginLayout from './components/loginLayout';
import Login from './components/login/login';

import AccountController from './components/account/AccountController';
import Tenants from './components/tenants/Tenants';
import SettingsController from './components/settings/SettingsController';
import NewTenantController from './components/tenants/NewTenantController';
import EditTenantController from './components/tenants/EditTenantController';
import UserList from './components/users/userList';
import UserMaintenance from './components/users/userMaintenance';
import Roles from './components/roles/roles';
import RoleMaintenance from './components/roles/roleMaintenance';
import Tags from './components/tags/tags';
import TagMaintenance from './components/tags/tagMaintenance';
import AuditLogController from './components/auditLog/AuditLogController';

import ContractList from './components/contracts/contractList';
import ContractMaintenance from './components/contracts/contractMaintenance';
import NewContractController from './components/contracts/NewContractController';
import CloseContractWizard from './components/closeContract/closeContractWizard';
import Contractors from './components/contractors/contractors';
import ContractorMaintenance from './components/contractors/contractorMaintenance';
import Employees from './components/employees/employees';
import EmployeeMaintenance from './components/employees/employeeMaintenance';
import Contacts from './components/contacts/Contacts';
import ContactMaintenance from './components/contacts/ContactMaintenance';
import ScheduleOfRates from './components/scheduleOfRates/scheduleOfRates';
import ScheduleMaintenance from './components/scheduleOfRates/scheduleMaintenance';
import Periods from './components/periods/periods';
import PeriodMaintenance from './components/periods/periodMaintenance';
import ClosePeriodWizard from './components/periods/closing/closeWizard';
import Lots from './components/lots/lots';
import LotMaintenance from './components/lots/lotMaintenance';
import LotAdjustments from './components/lotAdjustments/lotAdjustments';
import LotAdjustmentMaintenance from './components/lotAdjustments/lotAdjustmentMaintenance';
import Variations from './components/variations/variations';
import VariationMaintenance from './components/variations/variationMaintenance';
import ExtensionsOfTime from './components/extensionsOfTime/extensionsOfTime';
import ExtensionOfTimeMaintenance from './components/extensionsOfTime/extensionOfTimeMaintenance';
import SeparablePortions from './components/separablePortions/separablePortions';
import SeparablePortionMaintenance from './components/separablePortions/separablePortionMaintenance';
import Adjustments from './components/adjustments';
import ProgressClaims from './components/progressClaims/progressClaims';
import NCRs from './components/ncr/ncrs';
import NCRMaintenance from './components/ncr/ncrMaintenance';
import Forecasts from './components/forecasts/forecasts';
import ForecastMaintenance from './components/forecasts/forecastMaintenance';
import ForecastPeriodMaintenance from './components/periods/forecasts/forecastPeriodMaintenance';
import ContractForecast from './components/forecasts/contractForecast';
import LimitsOfAccuracy from './components/limitsOfAccuracy/limitsOfAccuracy';
import ForecastPeriods from './components/forecasts/forecastPeriods';
import Events from './components/events/events';
import EventMaintenance from './components/events/eventMaintenance';
import HelpPages from './components/helpPages/HelpPages';
import HelpPageMaintenance from './components/helpPages/HelpPageMaintenance';

import IncomingCorrespondence from './components/correspondence/incomingCorrespondence';
import IncomingCorrespondenceMaintenance from './components/correspondence/incomingCorrespondenceMaintenance';
import OutgoingCorrespondence from './components/correspondence/outgoingCorrespondence';
import OutgoingCorrespondenceMaintenance from './components/correspondence/outgoingCorrespondenceMaintenance';
import PrincipalCorrespondence from './components/principalCorrespondence/principalCorrespondence';
import PrincipalCorrespondenceMaintenance from './components/principalCorrespondence/principalCorrespondenceMaintenance';
import DesignCorrespondence from './components/designCorrespondence/designCorrespondence';
import DesignCorrespondenceMaintenance from './components/designCorrespondence/designCorrespondenceMaintenance';
import LinkedCorrespondenceController from './components/linkedCorrespondence/linkedCorrespondenceController';

import RegisterDefinitions from './components/registers/registerDefinitions';
import RegisterDefinitionMaintenance from './components/registers/registerDefinitionMaintenance';
import Registers from './components/registers/registers';
import RegisterMaintenance from './components/registers/registerMaintenance';

import ProgressClaimByLot from './components/reports/progressClaimByLot';
import ProgressClaimReport from './components/reports/progressClaimReport';
import ProgressClaimSummaryReport from './components/reports/progressClaimSummaryReport';
import LotStatusByLot from './components/reports/lotStatusByLot';
import LotStatusByScheduleItem from './components/reports/lotStatusByScheduleItem';
import DeductionsByItems from './components/reports/deductionsByItems';
import DeductionsByLot from './components/reports/deductionsByLot';
import TableOfDifferences from './components/reports/tableOfDifferences';
import WorksComplete from './components/reports/worksComplete';

import PaymentCertificate from './components/certificates/paymentCertificate';
import FinalCertificate from './components/certificates/finalCertificate';
import FinalCertificateTemplate from './components/certificates/finalCertificateTemplate';
import PracticalCompletions from './components/practicalCompletion/practicalCompletions';
import PracticalCompletionMaintenance from './components/practicalCompletion/practicalCompletionMaintenance';

import Handover from './components/handover/handover';
import HandoverRenderer from './components/handover/HandoverRenderer';
import ProgressClaimHandover from './components/handover/progressClaimReport';
import ProgressClaimByLotHandover from './components/handover/progressClaimByLotReport';
import ProgressClaimSummaryHandover from './components/handover/progressClaimSummaryReport';
import LotHandover from './components/handover/lotHandover';
import LotsHandover from './components/handover/handovers/lotsHandover';
import IncomingCorrespondenceHandover from './components/handover/handovers/incomingCorrespondenceHandover';
import IncomingCorrespondencesHandover from './components/handover/handovers/incomingCorrespondencesHandover';
import OutgoingCorrespondenceHandover from './components/handover/handovers/outgoingCorrespondenceHandover';
import OutgoingCorrespondencesHandover from './components/handover/handovers/outgoingCorrespondencesHandover';
import DesignCorrespondenceHandover from './components/handover/handovers/designCorrespondenceHandover';
import DesignCorrespondencesHandover from './components/handover/handovers/designCorrespondencesHandover';
import PrincipalCorrespondenceHandover from './components/handover/handovers/principalCorrespondenceHandover';
import PrincipalCorrespondencesHandover from './components/handover/handovers/principalCorrespondencesHandover';
import ExtensionOfTimeHandover from './components/handover/handovers/eotHandover';
import ExtensionsOfTimeHandover from './components/handover/handovers/eotsHandover';
import LotAdjustmentHandover from './components/handover/handovers/lotAdjustmentHandover';
import LotAdjustmentsHandover from './components/handover/handovers/lotAdjustmentsHandover';
import NCRHandover from './components/handover/handovers/ncrHandover';
import NCRsHandover from './components/handover/handovers/ncrsHandover';
import VariationHandover from './components/handover/handovers/variationHandover';
import VariationsHandover from './components/handover/handovers/variationsHandover';
import GenericHandover from './components/handover/genericHandover';

import NotFound from './components/NotFound';

const SentryRoute = Sentry.withSentryRouting(Route);

const RouteWithLayout = ({ path, component: Component, scope, keyParam }) => (
	<SentryRoute exact path={path} render={props => 
		<App component={Component} scope={scope}>
			<Layout body={<Component key={keyParam ? props.match.params[keyParam] : undefined} {...props} />} >
			</Layout>
		</App>
	} />
);

const RouteWithLoginLayout = ({ path, component: Component }) => (
	<SentryRoute exact path={path} render={props => 
		<App>
			<LoginLayout body={<Component {...props} />}>
			</LoginLayout>
		</App>
	} />
);

const RouteWithReportLayout = ({ path, component: Component }) => (
	<SentryRoute exact path={path} render={props => 
		<ReportLayout body={<Component {...props} isReportView={true} />}>
		</ReportLayout>
	} />
);

const RouteWithHandoverLayout = ({ path, component: Component }) => (
	<SentryRoute exact path={path} render={props => 
		<HandoverLayout body={<Component {...props} />}>
		</HandoverLayout>
	} />
);

export default function (store) {
	return (
		<Switch>
			<RouteWithLoginLayout path="/login" component={Login} />
			
			<RouteWithLayout exact path="/" component={Home} scope="contract" />
			<RouteWithLayout exact path='/account' component={AccountController} />
			<RouteWithLayout exact path='/tenants' component={Tenants} />
			<RouteWithLayout exact path='/tenants/new' component={NewTenantController} />
			<RouteWithLayout exact path='/tenant/:tenantId?' component={EditTenantController} />
			<RouteWithLayout exact path='/settings' component={SettingsController} />
			<RouteWithLayout exact path="/lots" component={Lots} scope="period" />
			<RouteWithLayout exact path='/users' component={UserList} />
			<RouteWithLayout exact path="/user/:userId?" component={UserMaintenance} />
			<RouteWithLayout exact path='/roles' component={Roles} />
			<RouteWithLayout exact path="/role/:roleId?" component={RoleMaintenance} />
			<RouteWithLayout exact path='/tags' component={Tags} />
			<RouteWithLayout exact path="/tag/:tagId?" component={TagMaintenance} />
			<RouteWithLayout exact path='/audit-log' component={AuditLogController} />
			<RouteWithLayout exact path='/contracts' component={ContractList} />
			<RouteWithLayout exact path="/contract/new" component={NewContractController} />
			<RouteWithLayout exact path="/contract/:contractNumber?" component={ContractMaintenance} />
			<RouteWithLayout exact path="/close-contract/:contractId?" component={CloseContractWizard} />
			<RouteWithLayout exact path='/contractors' component={Contractors} />
			<RouteWithLayout exact path="/contractor/:contractorId?" component={ContractorMaintenance} />
			<RouteWithLayout exact path='/employees' component={Employees} />
			<RouteWithLayout exact path="/employee/:employeeId?" component={EmployeeMaintenance} />
			<RouteWithLayout exact path='/contacts' component={Contacts} />
			<RouteWithLayout exact path="/contact/:contactId?" component={ContactMaintenance} />
			<RouteWithLayout exact path='/help-pages' component={HelpPages} />
			<RouteWithLayout exact path="/help-page/:helpPageId?" component={HelpPageMaintenance} />

			<RouteWithLayout exact path='/schedule-of-rates' component={ScheduleOfRates} scope="contract" />
			<RouteWithLayout exact path="/schedule/:scheduleId?" component={ScheduleMaintenance} scope="contract" />
			<RouteWithLayout exact path='/periods' component={Periods} scope="contract" />
			<RouteWithLayout exact path="/period/:periodId/close" component={ClosePeriodWizard} scope="contract" />
			<RouteWithLayout exact path="/period/:periodId" component={PeriodMaintenance} scope="contract" />
			<RouteWithLayout exact path='/ncrs' component={NCRs} scope="contract" />
			<RouteWithLayout exact path="/ncr/:ncrId?" component={NCRMaintenance} keyParam="ncrId" onChangeContract='/ncrs' />
			<RouteWithLayout exact path='/incoming-correspondences' component={IncomingCorrespondence}  scope="contract" />
			<RouteWithLayout exact path="/incoming-correspondence/:correspondenceId?" component={IncomingCorrespondenceMaintenance} keyParam="correspondenceId" scope="contract" />
			<RouteWithLayout exact path='/outgoing-correspondences' component={OutgoingCorrespondence}  scope="contract" />
			<RouteWithLayout exact path="/outgoing-correspondence/:correspondenceId?" component={OutgoingCorrespondenceMaintenance} keyParam="correspondenceId" scope="contract" />
			<RouteWithLayout exact path='/principal-correspondences' component={PrincipalCorrespondence}  scope="contract" />
			<RouteWithLayout exact path="/principal-correspondence/:correspondenceId?" component={PrincipalCorrespondenceMaintenance} keyParam="correspondenceId" scope="contract" />
			<RouteWithLayout exact path='/design-correspondences' component={DesignCorrespondence}  scope="contract" />
			<RouteWithLayout exact path="/design-correspondence/:correspondenceId?" component={DesignCorrespondenceMaintenance} keyParam="correspondenceId" scope="contract" />
			<RouteWithLayout exact path='/linked-correspondence' component={LinkedCorrespondenceController}  scope="contract" />
			<RouteWithLayout exact path='/register-definitions' component={RegisterDefinitions}  scope="contract" />
			<RouteWithLayout exact path="/register-definition/:registerDefinitionId?" component={RegisterDefinitionMaintenance} scope="contract" />
			<RouteWithLayout exact path='/events' component={Events}  scope="contract" />
			<RouteWithLayout exact path="/event/:eventId?" component={EventMaintenance} scope="contract" />
			<RouteWithLayout exact path='/registers/:registerDefinitionId' component={Registers}  scope="contract" />
			<RouteWithLayout exact path="/register/:registerDefinitionId/:registerId?" component={RegisterMaintenance} scope="contract" />

			<RouteWithLayout exact path='/lots' component={Lots} scope="period" />
			<RouteWithLayout exact path="/lot/:lotId?" component={LotMaintenance} scope="period" />
			<RouteWithLayout exact path='/lot-adjustments' component={LotAdjustments} scope="period" />
			<RouteWithLayout exact path="/lot-adjustment/:lotAdjustmentId?" component={LotAdjustmentMaintenance} scope="period" />
			<RouteWithLayout exact path='/variations' component={Variations} scope="period" />
			<RouteWithLayout exact path="/variation/:variationId?" component={VariationMaintenance} keyParam="variationId" onChangeContract='/variations' scope="period" />
			<RouteWithLayout exact path='/extensions-of-time' component={ExtensionsOfTime} scope="period" />
			<RouteWithLayout exact path="/extension-of-time/:extensionOfTimeId?" component={ExtensionOfTimeMaintenance} keyParam="extensionOfTimeId" scope="period" />
			<RouteWithLayout exact path='/separable-portions' component={SeparablePortions} onChangeContract='refresh' />
			<RouteWithLayout exact path="/separable-portion/:separablePortionId?" component={SeparablePortionMaintenance} scope="period" />
			<RouteWithLayout exact path='/adjustments' component={Adjustments} scope="period" />
			<RouteWithLayout exact path='/progress-claims' component={ProgressClaims}  scope="period" />
			<RouteWithLayout exact path='/forecasts' component={Forecasts} scope="period" />
			<RouteWithLayout exact path="/forecast/:forecastId?" component={ForecastMaintenance} scope="period" />
			<RouteWithLayout exact path="/contract-forecast" component={ContractForecast} scope="period" />
			<RouteWithLayout exact path="/limits-of-accuracy" component={LimitsOfAccuracy} scope="period" />
			<RouteWithLayout exact path="/forecast-periods" component={ForecastPeriods} scope="period" />
			<RouteWithLayout exact path="/forecast-period/:forecastPeriodId?" component={ForecastPeriodMaintenance} scope="period" />

			<RouteWithLayout exact path='/reports/progress-claim-by-lot' component={ProgressClaimByLot} scope="period" />
			<RouteWithLayout exact path='/reports/progress-claim' component={ProgressClaimReport} scope="period" />
			<RouteWithLayout exact path='/reports/progress-claim-summary' component={ProgressClaimSummaryReport} scope="period" />
			<RouteWithLayout exact path='/reports/lot-status-report' component={LotStatusByLot} scope="period" />
			<RouteWithLayout exact path='/reports/lot-status-by-schedule-item' component={LotStatusByScheduleItem} scope="period" />
			<RouteWithLayout exact path='/reports/deductions-by-items' component={DeductionsByItems} scope="period" />
			<RouteWithLayout exact path='/reports/deductions-by-lot' component={DeductionsByLot} scope="period" />
			<RouteWithLayout exact path='/reports/table-of-differences' component={TableOfDifferences} scope="period" />
			<RouteWithLayout exact path='/reports/works-complete' component={WorksComplete} scope="period" />
			<RouteWithLayout exact path='/practical-completions' component={PracticalCompletions} scope="period" />
			<RouteWithLayout exact path='/practical-completion/:practicalCompletionId?' component={PracticalCompletionMaintenance} scope="period" />
			<RouteWithLayout exact path='/final-certificate' component={FinalCertificate} scope="period" />
			<RouteWithLayout exact path='/final-certificate/template' component={FinalCertificateTemplate} />
			<RouteWithLayout exact path="/payment-certificate" component={PaymentCertificate} scope="period" />

			<RouteWithReportLayout path="/reports/payment-certificate" component={PaymentCertificate} />
			<RouteWithReportLayout path='/reports/final-certificate' component={FinalCertificate} />
			<RouteWithReportLayout path='/reports/progress-claim-by-lot/pdf' component={ProgressClaimByLot} orientation='landscape' />
			<RouteWithReportLayout path='/reports/progress-claim/pdf' component={ProgressClaimReport} orientation='landscape' />
			<RouteWithReportLayout path='/reports/progress-claim-summary/pdf' component={ProgressClaimSummaryReport} orientation='landscape' />
			<RouteWithReportLayout path='/reports/lot-status-report/pdf' component={LotStatusByLot} orientation='landscape' />
			<RouteWithReportLayout path='/reports/lot-status-by-schedule-item/pdf' component={LotStatusByScheduleItem} orientation='landscape' />
			<RouteWithReportLayout path='/reports/table-of-differences/pdf' component={TableOfDifferences} orientation='landscape' />

			<RouteWithHandoverLayout path='/handover-renderer' component={HandoverRenderer} />
			<RouteWithHandoverLayout path='/handover/progress-claim' component={ProgressClaimHandover} />
			<RouteWithHandoverLayout path='/handover/progress-claim-by-lot' component={ProgressClaimByLotHandover} />
			<RouteWithHandoverLayout path='/handover/progress-claim-summary' component={ProgressClaimSummaryHandover} />
			<RouteWithHandoverLayout path='/handover/lots' component={LotsHandover} />
			<RouteWithHandoverLayout path='/handover/lot' component={LotHandover} />
			<RouteWithHandoverLayout path='/handover/incoming-correspondences' component={IncomingCorrespondencesHandover} />
			<RouteWithHandoverLayout path='/handover/incoming-correspondence' component={IncomingCorrespondenceHandover} />
			<RouteWithHandoverLayout path='/handover/outgoing-correspondences' component={OutgoingCorrespondencesHandover} />
			<RouteWithHandoverLayout path='/handover/outgoing-correspondence' component={OutgoingCorrespondenceHandover} />
			<RouteWithHandoverLayout path='/handover/design-correspondences' component={DesignCorrespondencesHandover} />
			<RouteWithHandoverLayout path='/handover/design-correspondence' component={DesignCorrespondenceHandover} />
			<RouteWithHandoverLayout path='/handover/principal-correspondences' component={PrincipalCorrespondencesHandover} />
			<RouteWithHandoverLayout path='/handover/principal-correspondence' component={PrincipalCorrespondenceHandover} />
			<RouteWithHandoverLayout path='/handover/extensions-of-time' component={ExtensionsOfTimeHandover} />
			<RouteWithHandoverLayout path='/handover/extension-of-time' component={ExtensionOfTimeHandover} />
			<RouteWithHandoverLayout path='/handover/lot-adjustments' component={LotAdjustmentsHandover} />
			<RouteWithHandoverLayout path='/handover/lot-adjustment' component={LotAdjustmentHandover} />
			<RouteWithHandoverLayout path='/handover/ncrs' component={NCRsHandover} />
			<RouteWithHandoverLayout path='/handover/ncr' component={NCRHandover} />
			<RouteWithHandoverLayout path='/handover/variations' component={VariationsHandover} />
			<RouteWithHandoverLayout path='/handover/variation' component={VariationHandover} />
			<RouteWithHandoverLayout path='/handover/generic' component={GenericHandover} />
			
			<RouteWithLayout exact path='/handover/:contractId' component={Handover} />

			<SentryRoute>
				<NotFound />
			</SentryRoute>
		</Switch>
	);
}

// Enable Hot Module Replacement (HMR)
if (module.hot) {
    module.hot.accept();
}
