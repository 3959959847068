import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PersistedDataTable from '../widgets/persistedDataTable';
import ViewIcon from '@material-ui/icons/Pageview';

class LotAdjustmentsGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.lotAdjustments || this.props.lotAdjustments.length !== nextProps.lotAdjustments.length;
	}

	render() {
		const lotAdjustments = this.props.lotAdjustments || [];

		const columns = [
			{ name: 'lotNo', label: 'Lot' },
			{ name: 'lotDescription', label: 'Lot Description' },
			{ 
				name: 'adjustmentType', 
				label: 'Adjustment Description'
			},
			{ 
				name: 'totalAmount', 
				label: 'Adjusted Amount', 
				dataType: 'number', 
				formatString: 'currency' 
			},
			{ name: 'periodClaimedName', label: 'Adjustment Period' },
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => {
					const isClosedClaim = (args.data.periodClaimed !== this.props.currentPeriod.periodId);
					if (isClosedClaim) {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.onViewLotAdjustment(args.data); } } style={{ padding: '2px' }}>
									<ViewIcon />
								</IconButton>
							</div>
						);
					} else {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton 
									onClick={ () => { this.props.onEditLotAdjustment(args.data); } } style={{ padding: '2px' }}
								>
									<EditIcon />
								</IconButton>
								<IconButton 
									onClick={ () => { this.props.onDeleteLotAdjustment(args.data); } } style={{ padding: '2px' }}
								>
									<ActionDelete />
								</IconButton>
							</div>
						);
					}
				}
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewLotAdjustment(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="lotAdjustments"
				data={lotAdjustments} 
				columns={columns}
			/>
		);
	}
}

LotAdjustmentsGrid.propTypes = {
	lotAdjustments: PropTypes.array,
	onEditLotAdjustment: PropTypes.func.isRequired,
	onViewLotAdjustment: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onDeleteLotAdjustment: PropTypes.func.isRequired,
	currentPeriod: PropTypes.object.isRequired
};

LotAdjustmentsGrid.defaultProps = {
	lotAdjustments: []
};

export default LotAdjustmentsGrid;
