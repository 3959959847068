import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';

class ScheduleOfRatesHandoverContent extends React.Component {
	render() {
		const schedule = this.props.report;
		
		const columns = [
			{ name: 'itemNo', label: 'Item No' },
			{ name: 'description', label: 'Description', headerStyle: { minWidth: '200px' } },
			{ name: 'unit', label: 'Unit' },
			{ 
				name: 'qty', 
				label: 'Qty',
				dataType: 'number'
			},
			{ 
				name: 'rate', 
				label: 'Rate',
				dataType: 'number',
				cellRenderer: (args) => (args.value).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
			},
			{
				name: 'amount',
				label: 'Amount',
				dataType: 'number',
				value: (args) => args.data.qty * args.data.rate,
				cellRenderer: (args) => (args.data.qty * args.data.rate).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
			}		
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Schedule No." value={schedule.name} />
				<HandoverField label="Is Active" value={schedule.active} />

			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Items</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={schedule.items || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

ScheduleOfRatesHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default ScheduleOfRatesHandoverContent;
