import React from 'react';
import connectedRouterHOC from '../../../utils/connectedRouterHOC';
import PropTypes from 'prop-types';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions,
	Grid
} from '@material-ui/core';
import Button from '../../widgets/button';
import { withForm } from '../../../utils/forms';
import Form from '../../widgets/Form';

const form = {
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "userName",
			label: "Username",
			validate: value => value.match(/^[a-zA-Z0-9_. ]+$/i) ? "" : "Username must contain no special characters",
			required: true
		},
		{
			name: "password",
			label: "Password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters",
			required: props => props.isNew
		},
		{
			name: "fullName",
			label: "Full Name",
			required: true
		},
		{
			name: "email",
			label: "Email address",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid address",
			required: true
		},
		{
			name: "position",
			label: "Position",
			required: true
		},
		{
			name: "userRole",
			label: "Role",
			type: 'select',
			required: true,
			items: props => [
				{ value: 'Administrator', label: 'Administrator', disabled: props.userRole !== 'Global Administrator' && props.userRole !== 'Administrator' },
				{ value: 'User', label: 'User' }
			]
		}
	]
};

const UserDialog = props => {
	const classes = props.classes;
	const { fields, values, isNew, user, updateValues } = props;

	React.useEffect(() => {
		updateValues(user);
	}, user);

	const onClose = () => {
		props.onCancel();
	};
	const onSubmit = () => {
		if (props.validateFields()) {
			props.onOk(values);
		} 
	}
	return (
		<Dialog fullWidth={true} maxWidth="lg" open={props.show} onClose={onClose} style={{ zIndex: 9990 }} classes={classes}>
			<DialogTitle>{props.isNew ? "Add User" : "Edit User"}</DialogTitle>
			<DialogContent style={{ }}>
				<Form 
					onSubmit={onSubmit}
					style={{ }}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>{fields.fullName()}</Grid>
						<Grid item xs={12} md={6}>{fields.userName()}</Grid>
						<Grid item xs={12} md={6}>{fields.password()}</Grid>
						<Grid item xs={12} md={6}>{fields.email()}</Grid>
						<Grid item xs={12} md={6}>{fields.position()}</Grid>
						<Grid item xs={12} md={6}>{fields.userRole()}</Grid>
					</Grid>
				</Form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
	 			<Button onClick={onSubmit}>{isNew ? "Add" : "Update"}</Button>
			</DialogActions>
		</Dialog>
	);
};

UserDialog.propTypes = {
	show: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	userRole: PropTypes.string.isRequired
};

UserDialog.defaultProps = {
	show: false
};

const mapStateToProps = (state) => ({
	userRole: state.context.userRole
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(withForm(form)(UserDialog));
