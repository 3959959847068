import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import HandoverField from '../components/handoverField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
class GenericHandoverContent extends React.Component {
	render() {
		const item = this.props.report;
		const scalarItems = [];
		const arrayItems = [];

		forEach(keys(item), (key) => {
			const val = item[key];
			if (isArray(val)) {
				arrayItems.push({ key, val });
			} else {
				scalarItems.push({ key, val });
			}
		});

		return (
		<div>
			<div className="handover-data">
				{
					map(scalarItems, (i) => {
						const key = i.key;
						const val = i.val;
						return (
							<HandoverField label={key} value={val} />
						);
					})
				}
			</div>

			<div className="handover-items">
				{ 
					map(arrayItems, (i) => {
						const key = i.key;
						const val = i.val;
						if (val.length === 0) {
							return (
								<HandoverSectionHeader>{key}</HandoverSectionHeader>
							);
						} else {
							const propColumns = [];
							forEach(keys(val[0]), (k) => {
								propColumns.push({
									name: k,
									label: k
								});
							});
							return (
								<div>
									<HandoverSectionHeader>{key}</HandoverSectionHeader>
									<DataTable 
										style={{ marginTop: '20px', width: '100%' }}
										hideActions={true}
										hideSearch={true}
										hideFilter={true}
										hidePaging={true}
										defaultRowsPerPage="All"
										data={val}
										columns={propColumns}
									/>
								</div>
							);
						}
					})
				}
			</div>
		</div>
    );
  }
}

GenericHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default GenericHandoverContent;
