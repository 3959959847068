import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from './dataTable';
import { updatePersistedState } from '../../reducers/persistedState';
import { updateTempState } from '../../reducers/tempState';
import { showSuccessNotification } from '../../reducers/notifications';
import { useHistory, useLocation } from 'react-router-dom';

const PersistedDataTable = props => {
	const location = useLocation();
	const history = useHistory();

	const onChangeRowsPerPage = (args) => {
		props.updatePersistedState(props.id, {
			...props.persistedState[props.id],
			rowsPerPage: args.rowsPerPage
		});
	};
	const onChangeSorting = (args) => {
		props.updatePersistedState(props.id, {
			...props.persistedState[props.id],
			sortBy: args.sortBy,
			sortDir: args.sortDir
		});
	};

	const onChangePageNumber = (args) => {
		props.updateTempState(props.id, {
			...props.tempState[props.id],
			pageNum: args.pageNum
		});
	};

	const onChangeSearch = (args) => {
		if (props.persistSearchToUrl) {
			history.push({
				pathname: location.pathname,
				query: { search: args.search === '' ? undefined : args.search }
			});
			props.updateTempState(props.id, {
				...props.tempState[props.id],
				pageNum: args.pageNum
			});
		} else {
			props.updateTempState(props.id, {
				...props.tempState[props.id],
				search: args.search,
				pageNum: args.pageNum
			});
		}
	};

	const onChangeFilters = (args) => {
		console.log(args);
		props.updateTempState(props.id, {
			...props.tempState[props.id],
			filters: args.filters,
			pageNum: args.pageNum
		});
	};

	const onSelectColumns = (args) => {
		props.updateTempState(props.id, {
			...props.updateTempState[props.id],
			selectedColumns: args.selectedColumns
		});
	};

	const onSaveSettings = (args) => {
		props.updatePersistedState(props.id, {
			...props.persistedState[props.id],
			selectedColumns: args.selectedColumns,
			filters: args.filters,
			search: args.search,
			rowsPerPage: args.rowsPerPage,
			sortBy: args.sortBy,
			sortDir: args.sortDir,
			pageNum: args.pageNum
		});
		props.showSuccessNotification('Current settings saved');
	};
	const onRestoreDefaultSettings = () => {
		props.updatePersistedState(props.id, {});
		props.updateTempState(props.id, {});
		props.showSuccessNotification('Restored default settings');
	};

	const persistedState = props.persistedState[props.id] || {};
	const tempState = props.tempState[props.id] || {};
	const currentState = { ...persistedState, ...tempState }; // temp state overrides persisted state
	const { 
		rowsPerPage, 
		sortBy, 
		sortDir,
		pageNum,
		filters,
		selectedColumns
	} = currentState;
	let search;
	if (props.persistSearchToUrl) {
		search = location ? location.query.search : '';
	} else {
		search = tempState.search || persistedState.search;
	}

	return (
		<DataTable 
			{ ...props }
			rowsPerPage={rowsPerPage}
			onChangeRowsPerPage={onChangeRowsPerPage}
			
			sortBy={sortBy}
			sortDir={sortDir}
			onChangeSorting={onChangeSorting}

			pageNum={pageNum}
			onChangePageNumber={onChangePageNumber}

			search={search}
			onChangeSearch={onChangeSearch}

			filters={filters}
			onChangeFilters={onChangeFilters}

			selectedColumns={selectedColumns}
			onSelectColumns={onSelectColumns}

			onSaveSettings={onSaveSettings}
			onRestoreDefaultSettings={onRestoreDefaultSettings}
		/>
	);
}

PersistedDataTable.propTypes = {
	id: PropTypes.string.isRequired,
	persistedState: PropTypes.object.isRequired,
	tempState: PropTypes.object.isRequired,
	updatePersistedState: PropTypes.func.isRequired,
	updateTempState: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	persistSearchToUrl: PropTypes.bool
};

PersistedDataTable.defaultProps = {
	persistSearchToUrl: false
};

const mapStateToProps = (state) => ({
	persistedState: state.persistedState,
	tempState: state.tempState
});

const mapDispatchToProps = (dispatch) => ({
	updatePersistedState: (key, data) => {
		dispatch(updatePersistedState(key, data));
	},
	updateTempState: (key, data) => {
		dispatch(updateTempState(key, data));
	},
	showSuccessNotification: (message) => {
		dispatch(showSuccessNotification(message));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PersistedDataTable);
