import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';

const ContactsGrid = ({ 
	contacts, canEdit, canView, onEditContact, onDeleteContact, onViewContact
}) => {
	const columns = [
		{ name: 'name', label: 'Name' },
		{ name: 'email', label: 'Email' },
		{ name: 'isActive', label: 'Is Active', dataType: 'bool' }
	];

	if (canEdit) {
		columns.splice(0, 0, {
			name: 'commands',
			excludeFromSearch: true,
			excludeFromFilter: true,
			cellRenderer: (args) => 
				<div style={{ whiteSpace: 'nowrap' }}>
					<IconButton onClick={ () => { onEditContact(args.data); } } style={{ padding: '2px' }}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={ () => { onDeleteContact(args.data); } } style={{ padding: '2px' }}>
						<ActionDelete />
					</IconButton>
				</div>
		});
	} else if (canView) {	
		columns.splice(0, 0, {
			name: 'commands',
			excludeFromSearch: true,
			excludeFromFilter: true,
			cellRenderer: (args) => 
				<div style={{ whiteSpace: 'nowrap' }}>
					<IconButton onClick={ () => { onViewContact(args.data); } } style={{ padding: '2px' }}>
						<ViewIcon />
					</IconButton>
				</div>
		});
	}

	return (
		<PersistedDataTable 
			id="contacts"
			data={contacts || []} 
			columns={columns}
		/>
	);
};

ContactsGrid.propTypes = {
	contacts: PropTypes.array.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditContact: PropTypes.func.isRequired,
	onViewContact: PropTypes.func.isRequired,
	onDeleteContact: PropTypes.func.isRequired
};

export default ContactsGrid;
