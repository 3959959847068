import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { addOrUpdate } from '../utils/utils';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { fetch, addTask } from 'domain-task';

const emptyVariation = {
	_isNew: true,
	'variationId': 0,
	'contractId': 0,
	'periodId': 0,
	'isLotBased': true,
	'approved': false,
	'variationNo': '',
	'contractorVariationNo': '',
	'description': '',
	'status': 'Open',
	'atcBy': '',
	'periodClaimed': 0,
	'comments': '',
	'dateReceived': null,
	'identifiedBy': '',
	'from': '',
	'to': null,
	'actionDueDate': null,
	'actionRequired': false,
	'notificationUser': null,
	'orderDate': null,
	'orderedBy': '',
	'items': [],
	'correspondence': []
};

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	variation: null, 
	variations: null
};

const CLEAR_VARIATIONS = 'CLEAR_VARIATIONS';
const CLEAR_VARIATION = 'CLEAR_VARIATION';
const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT';
const DELETE_VARIATION = 'DELETE_VARIATION';
const REQUEST_VARIATIONS = 'REQUEST_VARIATIONS';
const RECEIVE_VARIATIONS = 'RECEIVE_VARIATIONS';
const REQUEST_VARIATION = 'REQUEST_VARIATION';
const RECEIVE_VARIATION = 'RECEIVE_VARIATION';
const CREATE_NEW_VARIATION = 'CREATE_NEW_VARIATION';
const SAVE_VARIATION = 'SAVE_VARIATION';
const RECEIVE_SAVE_VARIATION_RESPONSE = 'RECEIVE_SAVE_VARIATION_RESPONSE';
const RECEIVE_DELETE_VARIATION_RESPONSE = 'RECEIVE_DELETE_VARIATION_RESPONSE';
const SHOW_VARIATION_ERRORS = 'SHOW_VARIATION_ERRORS';
const EDIT_VARIATION = 'EDIT_VARIATION';
const UPDATE_VARIATION_NUMBER = 'UPDATE_VARIATION_NUMBER';

export const clearVariations = () => ({ type: CLEAR_VARIATIONS });
export const clearVariation = () => ({ type: CLEAR_VARIATION });
export const clearSaveResult = () => ({ type: CLEAR_SAVE_RESULT });
export const receiveVariations = (data) => ({ type: RECEIVE_VARIATIONS, payload: { data } });
export const receiveVariation = (data) => ({ type: RECEIVE_VARIATION, payload: { data } });
export const receiveSaveVariationResponse = (data) => ({ type: RECEIVE_SAVE_VARIATION_RESPONSE, data });
export const receiveDeleteVariationResponse = (data) => ({ type: RECEIVE_DELETE_VARIATION_RESPONSE, data });
export const editVariation = (variation) => ({ type: EDIT_VARIATION, variation });

export const createNewVariation = (contractId) =>
	(dispatch) => {	
		const newVariation = {
			...cloneDeep(emptyVariation)
		};
		dispatch({ type: CREATE_NEW_VARIATION, newVariation: newVariation });


		const fetchTask = fetch(`/api/variations/get-variation-number?contractId=${contractId}`, { 
			credentials: 'same-origin',
			headers: {
				'cache-control': 'no-store',
				'pragma': 'no-cache'
			}
		})
		.then(response => {
			if (response.status >= 200 && response.status < 300) return response.text();

			const error = new Error(response.statusText);
			error.response = response;
			throw error;
		})
		.then((data) => {
			dispatch({ type: UPDATE_VARIATION_NUMBER, number: data });
		}).catch((error) => {
			console.log('request failed', error);
		});
				
	addTask(fetchTask);
	};

export const requestVariations = () => (
	createFetchAction({
		objectName: 'Variations',
		passContext: true,
		url: '/api/variations',
		startAction: REQUEST_VARIATIONS,
		success: (data) =>
			receiveVariations(map(data, (v) => ({
				...v,
				dateReceived: v.dateReceived ? new Date(v.dateReceived) : null,
				actionDueDate: v.actionDueDate ? new Date(v.actionDueDate) : null,
				orderDate: v.orderDate ? new Date(v.orderDate) : null
			})))
	})
);

export const requestVariation = (variationId) => (
	createFetchAction({
		objectName: 'Variation',
		passContext: true,
		url: `/api/variations/${variationId}`,
		startAction: REQUEST_VARIATION,
		success: (data) =>
			receiveVariation({
				...data,
				dateReceived: data.dateReceived ? new Date(data.dateReceived) : null,
				actionDueDate: data.actionDueDate ? new Date(data.actionDueDate) : null,
				orderDate: data.orderDate ? new Date(data.orderDate) : null
			})
	})
);

const validateVariation = (variation) => {
	const errors = [];

	if (!variation.variationNo) {
		errors.push({
			fieldName: 'VariationNo',
			valid: false,
			message: 'Invalid Variation Number'
		});
	}
	if (!variation.status) {
		errors.push({
			fieldName: 'Status',
			valid: false,
			message: 'Invalid Variation Status'
		});
	}
	return errors;
};

export const saveVariation = (variation, newFiles, onSuccess) => {
	const errors = validateVariation(variation);
	if (errors.length > 0) return { type: SHOW_VARIATION_ERRORS, data: errors };

	const formData = new FormData();
	formData.append('Variation', encodeURIComponent(JSON.stringify(variation)));
	forEach(newFiles, (f) => {
		if (f.data === 'Variation.Administrator') {
			formData.append('AdministratorFiles', f.file);
		} else {
			formData.append('ContractorFiles', f.file);
		}
	});

	return createFormPostAction({
		passContext: true,
		url: '/api/variations',
		formData: formData,
		startAction: SAVE_VARIATION,
		success: (data, dispatch) => {
			dispatch(receiveSaveVariationResponse({
				...data,
				object: data.object ? {
					...data.object,
					dateReceived: data.object.dateReceived ? new Date(data.object.dateReceived) : null,
					actionDueDate: data.object.actionDueDate ? new Date(data.object.actionDueDate) : null,
					orderDate: data.object.orderDate ? new Date(data.object.orderDate) : null
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const saveVariationAndNew = (variation) => {
	const errors = validateVariation(variation);
	if (errors.length > 0) return { type: SHOW_VARIATION_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/variations',
		data: variation,
		startAction: SAVE_VARIATION,
		success: (data, dispatch) => {
			const result = {
				...data,
				object: data.object ? {
					...data.object,
					dateReceived: data.object.dateReceived ? new Date(data.object.dateReceived) : null,
					actionDueDate: data.object.actionDueDate ? new Date(data.object.actionDueDate) : null,
					orderDate: data.object.orderDate ? new Date(data.object.orderDate) : null
				} : data.object
			};
			dispatch(receiveSaveVariationResponse(result));
			if (result.success) dispatch(createNewVariation());
		}
	});
};

export const deleteVariation = (variation) => (
	createPostAction({
		passContext: true,
		url: `/api/variations/${variation.variationId}/delete`,
		data: variation,
		startAction: DELETE_VARIATION,
		success: (data) => receiveDeleteVariationResponse(data)
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_VARIATIONS:
			return {
				...state,
				variations: null
			};
		case CLEAR_VARIATION:
			return {
				...state,
				variation: null
			};
		case CLEAR_SAVE_RESULT:
			return {
				...state,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case EDIT_VARIATION:
			return {
				...state,
				variation: action.variation,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case REQUEST_VARIATIONS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				variations: []
			};
		case RECEIVE_VARIATIONS:
			return {
				...state,
				isLoading: false,
				variations: action.payload.data
			};
		case REQUEST_VARIATION:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				variation: cloneDeep(emptyVariation)
			};
		case RECEIVE_VARIATION:
			return {
				...state,
				isLoading: false,
				variation: action.payload.data
			};
		case DELETE_VARIATION:
			return { 
				...state
			};
		case RECEIVE_DELETE_VARIATION_RESPONSE:
			return {
				...state,
				variations: state.variations.filter(c => c.variationId !== action.data.objectId) 
			};
		case CREATE_NEW_VARIATION:
			return {
				...state,
				variation: action.newVariation,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};	
		case SAVE_VARIATION:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_VARIATION_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				variations: addOrUpdate(state.variations, action.data.object, { variationId: action.data.object.variationId }), 
				variation: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_VARIATION_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		case UPDATE_VARIATION_NUMBER:
				return {
					...state,
					variation: {
						...state.variation,
						variationNo: action.number
					}
				};
		default:
			return state;
	}
};
