import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../../utils/connectedRouterHOC';
import { 
	saveForecastPeriod, 
	clearForecastPeriod, 
	requestForecastPeriod, 
	createNewForecastPeriod 
} from '../../../reducers/forecasts';
import ForecastPeriodForm from './forecastPeriodForm';
import MessagePanel from '../../messagePanel';
import { showSuccessNotification } from '../../../reducers/notifications';
import { canEdit } from '../../../selectors/canEdit';
import { produce } from 'immer';
import PageLayout from '../../pageLayout';

class ForecastPeriodMaintenance extends React.Component {
	state = {
		forecastPeriod: this.props.forecastPeriod,
		hasUnsavedChanges: false
	};

	static fetchData(dispatch, props) {
		if (props.match.params.forecastPeriodId) {
			dispatch(requestForecastPeriod(props.match.params.forecastPeriodId));
		} else {
			dispatch(createNewForecastPeriod());
		}
	}
	static clearData(dispatch) {
		dispatch(clearForecastPeriod());
	}
	componentDidMount() {
		ForecastPeriodMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			forecastPeriod: nextProps.forecastPeriod,
			hasUnsavedChanges: false
		});
	}


	updateField = (field, newValue) => {
		this.setState(produce(draft => {
			draft.forecastPeriod[field] = newValue;
			draft.hasUnsavedChanges = true;
		}));
	}

	render() {
		const forecastPeriod =  this.state.forecastPeriod || {};

		return (
<PageLayout
	title={forecastPeriod.periodName}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.forecastPeriod),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.forecastPeriod),
		onCancel: () => this.props.history.goBack()
	}}
	content={
	<div>
		<ForecastPeriodForm 
			forecastPeriod={forecastPeriod} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			canEdit={this.props.canEdit}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
	</div>
	}
/>
		);
	}
}

ForecastPeriodMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	forecastPeriod: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	canEdit: canEdit(state, 'EditForecasts'),
	forecastPeriod: state.forecasts.forecastPeriod,
	isLoading: state.forecasts.isLoading,
	saveResult: state.forecasts.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (forecastPeriod) => {
		dispatch(saveForecastPeriod(forecastPeriod, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (forecastPeriod) => {
		dispatch(saveForecastPeriod(forecastPeriod, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ForecastPeriodMaintenance);
