import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import map from 'lodash/map';
import { globals } from '../globals';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	separablePortion: null, 
	separablePortions: null
};

const CLEAR_SEPARABLE_PORTIONS = 'CLEAR_SEPARABLE_PORTIONS';
const CLEAR_SEPARABLE_PORTION = 'CLEAR_SEPARABLE_PORTION';
const REQUEST_SEPARABLE_PORTION = 'REQUEST_SEPARABLE_PORTION';
const RECEIVE_SEPARABLE_PORTION = 'RECEIVE_SEPARABLE_PORTION';
const REQUEST_SEPARABLE_PORTIONS = 'REQUEST_SEPARABLE_PORTIONS';
const RECEIVE_SEPARABLE_PORTIONS = 'RECEIVE_SEPARABLE_PORTIONS';
const CREATE_NEW_SEPARABLE_PORTION = 'CREATE_NEW_SEPARABLE_PORTION';
const SAVE_SEPARABLE_PORTION = 'SAVE_SEPARABLE_PORTION';
const RECEIVE_SAVE_SEPARABLE_PORTION_RESPONSE = 'RECEIVE_SAVE_SEPARABLE_PORTION_RESPONSE';
const DELETE_SEPARABLE_PORTION = 'DELETE_SEPARABLE_PORTION';
const RECEIVE_DELETE_SEPARABLE_PORTION_RESPONSE = 'RECEIVE_DELETE_SEPARABLE_PORTION_RESPONSE';
const SHOW_SEPARABLE_PORTION_ERRORS = 'SHOW_SEPARABLE_PORTION_ERRORS';

export const clearSeparablePortions = () => ({ type: CLEAR_SEPARABLE_PORTIONS });
export const clearSeparablePortion = () => ({ type: CLEAR_SEPARABLE_PORTION });
export const receiveSeparablePortions = (data) => ({ type: RECEIVE_SEPARABLE_PORTIONS, payload: { data } });
export const receiveSeparablePortion = (data) => ({ type: RECEIVE_SEPARABLE_PORTION, payload: { data } });
export const createNewSeparablePortion = () => ({ type: CREATE_NEW_SEPARABLE_PORTION });
export const receiveSaveSeparablePortionResponse = (data) => ({ type: RECEIVE_SAVE_SEPARABLE_PORTION_RESPONSE, data });
export const receiveDeleteSeparablePortionResponse = (data) => ({ type: RECEIVE_DELETE_SEPARABLE_PORTION_RESPONSE, data });

export const requestSeparablePortions = () => (
	createFetchAction({
		objectName: 'Separable Portions',
		passContext: true,
		url: '/api/separable-portions',
		startAction: REQUEST_SEPARABLE_PORTIONS,
		success: (data) => 
			receiveSeparablePortions(map(data, (e) => ({
				...e,
				date: e.date ? new Date(e.date) : null,
				practicalCompletionDate: e.practicalCompletionDate ? new Date(e.practicalCompletionDate) : null
			})))
	})
);

export const requestSeparablePortion = (separablePortionId) => (
	createFetchAction({
		objectName: 'Separable Portion',
		passContext: true,
		url: `/api/separable-portions/${separablePortionId}`,
		startAction: REQUEST_SEPARABLE_PORTION,
		success: (data) => 
			receiveSeparablePortion({
				...data,
				date: data.date ? new Date(data.date) : null,
				practicalCompletionDate: data.practicalCompletionDate ? new Date(data.practicalCompletionDate) : null
			})
	})
);

export const saveSeparablePortion = (separablePortion, onSuccess) => {
	const errors = [];
	if (!separablePortion.name) {
		errors.push({
			fieldName: 'Name',
			valid: false,
			message: 'Name is required'
		});
	}
	if (errors.length > 0) return { type: SHOW_SEPARABLE_PORTION_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/separable-portions',
		data: separablePortion,
		startAction: SAVE_SEPARABLE_PORTION,
		success: (data, dispatch) => {
			dispatch(receiveSaveSeparablePortionResponse({
				...data,
				object: data.object ? {
					...data.object,
					date: data.object.date ? new Date(data.object.date) : null,
					practicalCompletionDate: data.object.practicalCompletionDate ? new Date(data.object.practicalCompletionDate) : null
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteSeparablePortion = (separablePortion) => (
	createPostAction({
		passContext: true,
		url: `/api/separable-portions/${separablePortion.separablePortionId}/delete`,
		data: separablePortion,
		startAction: DELETE_SEPARABLE_PORTION,
		success: (data) => receiveDeleteSeparablePortionResponse(data)
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_SEPARABLE_PORTIONS:
			return {
				...state,
				separablePortions: null
			};
		case CLEAR_SEPARABLE_PORTION:
			return {
				...state,
				separablePortion: null
			};
		case REQUEST_SEPARABLE_PORTIONS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				separablePortions: []
			};
		case RECEIVE_SEPARABLE_PORTIONS:
			return {
				...state,
				isLoading: false,
				separablePortions: action.payload.data
			};
		case REQUEST_SEPARABLE_PORTION:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				separablePortion: { ...cloneDeep(globals.templates.separablePortion) }
			};
		case RECEIVE_SEPARABLE_PORTION:
			return {
				...state,
				isLoading: false,
				separablePortion: action.payload.data
			};
		case DELETE_SEPARABLE_PORTION:
			return { 
				...state
			};
		case RECEIVE_DELETE_SEPARABLE_PORTION_RESPONSE:
			return {
				...state,
				separablePortions: state.separablePortions.filter(e => e.separablePortionId !== action.data.objectId) 
			};
		case CREATE_NEW_SEPARABLE_PORTION:
			return {
				...state,
				separablePortion: { ...cloneDeep(globals.templates.separablePortion) }
			};	
		case SAVE_SEPARABLE_PORTION:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_SEPARABLE_PORTION_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				separablePortions: addOrUpdate(state.separablePortions, action.data.object, { separablePortionId: action.data.object.separablePortionId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_SEPARABLE_PORTION_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
