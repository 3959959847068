import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';

class IncomingCorrespondencesHandoverContent extends React.Component {
	render() {
		const data = this.props.report;

		const columns = [
			{ name: 'rfiNumber', label: 'Incoming Number' },
			{ name: 'receivedDate', label: 'Date Received', dataType: 'date' },
			{ name: 'docRefNo', label: 'Doc Ref No' },
			{ name: 'docTitle', label: 'Document Title', headerStyle: { minWidth: '300px' } },
			{ name: 'toName', label: 'To' },
			{ name: 'fromName', label: 'From' },
			{ name: 'dated', label: 'Dated', dataType: 'date' },
			{ name: 'actionDueDate', label: 'Action Due Date', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.status !== 'Open') {
						statusBackColour = '#35aa47';
						statusForeColour = '#111';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				} 
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

IncomingCorrespondencesHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default IncomingCorrespondencesHandoverContent;
