import React from 'react';
import Checkbox from '../components/widgets/Checkbox';
import DateField from '../components/widgets/DateField';
import TextField from '../components/widgets/TextField';
import SelectField from '../components/widgets/selectField';
import NumericField from '../components/widgets/numericField';
import ImageSelector from '../components/widgets/ImageSelector';
import EmployeeSelect from '../components/widgets/employeeSelect';
import AutoComplete from '../components/widgets/AutoComplete';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import format from 'date-fns/format';
import isObject from 'lodash/isObject';
import forOwn from 'lodash/forOwn';
import { produce } from 'immer';

const maxValues = {
	textField: 8000,
	number: 100000
};

export const withForm = form => WrappedComponent => {
	const FormComponent = props => {
		const onChange = React.useRef({});

		const [state, setState] = React.useState(() => {
			const values = {}, formFiles = {}, images = {}, error = {};

			forEach(form.fields, f => {
				// Check if an object is supplied
				if (typeof f === "object") {
					// Set default props
					if (!f.type) f.type = "textField";
					if (!f.max && f.max !== false && maxValues[f.type]) f.max = maxValues[f.type];
					if (!f.defaultValue) {
						const value = {
							autoComplete: f.defaultItem ? f.defaultItem.value : f.isMulti ? [] : "",
							checkbox: false,
							dragList: [],
							imageUploader: f.single ? "" : [],
							tagEditor: [],
							number: 0,
						}[f.type];

						f.defaultValue = value || value === false || value === 0 ? value : "";
					}

					if (["imageUploader"].includes(f.type) && !f.single) {
						formFiles[f.name] = [];
						images[f.name] = {};
					}

					error[f.name] = "";
					values[f.name] = f.defaultValue;

					onChange.current[f.name] = (value, file) => {
						setState(produce(draft => {
							const single = !value || f.type !== "autoComplete" || f.isMulti ? value : value.value;
							draft.values = f.setValues ? f.setValues(single, draft.values) : { ...draft.values, [f.name]: single };
							draft.error[f.name] = validateField(f, draft.values, single);
							if (file) {
								draft.formFiles[f.name] = file;
							}
							if (f.onChange) f.onChange(draft.values, value);
							if (form.onChange) form.onChange(props, draft.values, f.name);
							
							if (!draft.dirty) {
								draft.dirty = true;
								if (props.handleDirty) props.handleDirty();
							}
						}, state));
					}
				}
			});

			return { values, error, formFiles, images, brokenAvatar: {}, valid: true, dirty: false };
		});

		React.useEffect(() => {
			if (props.loading) return;

			if (props.saveResult) {
				setState(produce(draft => {
					forEach(props.saveResult.fields, f => {
						if (f.fieldName) draft.error[camelCase(f.fieldName)] = f.message;
					});
				}, state));
			}

			if (form.initValues) {
				const newValues = form.initValues(props);
				if (!newValues) return;

				// Don't do anything if initValues hasn't changed
				if (!isEqual(state.initValues, newValues)) {
					setState(produce(draft => {
						draft.initValues = newValues;

						// Merge previous state values to allow for values to be set during initialisation,
						// use replaceExisting to force state on each update (useful when transitioning 
						// between view and edit)
						const updatedValues = form.replaceExisting ? newValues : { ...state.values, ...newValues };

						if (!isEqual(updatedValues, state.prevValues)) {
							draft.prevValues = cloneDeep(updatedValues);
							draft.values = cloneDeep(updatedValues);
						}
					}, state));
				}
			}
		});

		// Allow values to be passed to avoid scope issues
		const validateField = (f, values, value) => {
			const required = isFunction(f.required) ? f.required(props, values) : f.required,
				trimmed = typeof(value) === "string" ? value.trim() : value || f.defaultValue;

			// If empty, return error if required
			if (!trimmed || (f.type === "textEditor" && !trimmed.replace(/<[^>]+>/gm, ""))) {
				return required ? f.requiredText || "This field is required" : "";
			}

			if (f.isMulti && trimmed.length === 0) {
				return required ? f.requiredText || "A selection is required" : "";
			}

			// Check for max field size
			if (f.max) {
				if (f.type === "number" && trimmed > f.max) {
					return `Max value is ${f.max}`;
				} else if (trimmed.length > f.max) {
					return `Max ${f.max} ${f.isMulti ? "items" : "characters"} only (${trimmed.length})`;
				}
			}

			// Pass new value to validate function including other values in case needed
			return f.validate ? f.validate(value, values) : "";
		}

		const setValue = (name, value) => setState(produce(draft => {
			draft.values[name] = value;
		}, state));

		const { values, error, formFiles, images } = state;
		const formProps = {
			values,
			error,
			formFiles,
			images,
			valid: state.valid,
			dirty: state.dirty,
			onChange: onChange.current,
			resetForm: () => {
				const defaultValues = {}, defaultFiles = {};

				form.fields.forEach(f => {
					defaultValues[f.name] = f.defaultValue;
					if (["imageUploader"].includes(f.type)) defaultFiles[f.name] = [];
				});

				setState({ ...state, error: {}, brokenAvatar: {}, valid: true, dirty: false, values: { ...defaultValues, ...state.initValues }, formFiles: defaultFiles });
			},
			updateValues: newValues => newValues && setState({ ...state, values: { ...values, ...newValues } }),
			fields: mapValues(keyBy(form.fields, "name"), f => () => {
				// Allow custom values using a value func
				const value = f.getValue ? f.getValue(values) : values[f.name],
					placeholder = isFunction(f.placeholder) ? f.placeholder(props, values) : f.placeholder,
					required = isFunction(f.required) ? f.required(props, values) : f.required,
					disabled = isFunction(f.disabled) ? f.disabled(props, values) : f.disabled,
					helpText = error[f.name] || f.helpText,
					style = { ...form.fieldStyle, ...f.style };

				switch (f.type) {
					case "numeric":
						return <NumericField
							name={f.name}
							label={isFunction(f.label) ? f.label(setValue) : f.label}
							required={required}
							error={Boolean(error[f.name])}
							errorText={error[f.name]}
							helperText={helpText}
							value={value}
							onChange={(val, displayValue) => onChange.current[f.name](val)}
							style={style}
							type={f.numberType}
                            fullWidth={true}
						/>;
					case "checkbox":
						return <Checkbox
							name={f.name}
							label={isFunction(f.label) ? f.label(setValue) : f.label}
							required={required}
							error={Boolean(error[f.name])}
							errorText={error[f.name]}
							helpText={helpText}
							checked={Boolean(value)}
							onChange={e => onChange.current[f.name](e.target.checked)}
							style={style}
						/>;
					case "date":
						return <DateField
							value={value}
							label={f.label}
							placeholder={placeholder}
							required={required}
							onChange={date => onChange.current[f.name](date !== null && f.stripTime ? new Date(format(date, "yyyy-MM-dd")) : date)}
							error={Boolean(error[f.name])}
							errorText={error[f.name]}
							helpText={helpText}
							style={style}
							showTime={f.showTime}
							clearable={f.clearable}
                            fullWidth={true}
						/>;
					case "custom":
						const Widget = f.widget;
						return <Widget
							required={required}
							field={f}
							value={value}
							placeholder={placeholder}
							onChange={onChange.current[f.name]}
							error={Boolean(error[f.name])}
							errorText={error[f.name]}
							helpText={helpText}
							style={style}
							values={values}
							ownProps={props}
						/>;
					case "select":
						const items = isFunction(f.items) ? f.items(props) : f.items;

						return <SelectField
							fullWidth={true}
							required={required}
							name={f.name}
							value={value}
							label={f.label}
							onChange={v => onChange.current[f.name](v)}
							error={Boolean(error[f.name])}
							errorText={error[f.name]}
							helpText={helpText}
							items={items}
							style={style}
							disabled={disabled}
							displayEmpty={f.displayEmpty}
						/>;
					case "imageSelector":
						return <ImageSelector 
							label={f.label}
							required={required}
							error={Boolean(error[f.name])}
							imageText={f.init}
							helpText={helpText}
							images={f.images || []}
							style={style}
							value={value}
							onChange={onChange.current[f.name]}
							height={f.height}
							width={f.width}
							background={f.background}
							accept={f.accept}
						/>;
					case "autoComplete":
						let loadItems = {};

						if (isFunction(f.loadItems)) {
							loadItems = f.loadItems(props, values);
						} else {
							loadItems = { ...f.loadItems };
							
							if (isFunction(loadItems.route)) {
								loadItems.route = loadItems.route(props, values);
							}

							if (loadItems.onSuccess) {
								loadItems.onSuccess = mapped => f.loadItems.onSuccess(props, setValue, mapped);
							}

							if (isFunction(loadItems.filter)) {
								loadItems.filter = value => f.loadItems.filter(props, value);
							}

							if (isFunction(loadItems.mapItem)) {
								loadItems.mapItem = (value, i, collection) => f.loadItems.mapItem(value, i, collection, props);
							}
						}
						return <AutoComplete
							id={`field-${f.name}`}
                            fullWidth={true}
							required={required}
							options={isFunction(f.items) ? f.items(props) : f.items}
							getOptionLabel={f.getOptionLabel}
							loadItems={loadItems}
							defaultItem={f.defaultItem}
							value={value}
							label={f.label}
							onChange={(e, v) => onChange.current[f.name](v)}
							error={Boolean(error[f.name])}
							style={style}
							disabled={disabled}
							variant={f.variant}
							filterOptions={f.filterOptions}
						/>;
					case "employeeSelect":
						return <EmployeeSelect 
							label={f.label}
							required={required}
							error={Boolean(error[f.name])}
							style={style}
							value={value}
							onChange={onChange.current[f.name]}
						/>;
					default:
						return <TextField 
                            fullWidth={true}
							required={required}
							value={f.type === "number" ? value || 0 : value || ""}
							label={f.label}
							placeholder={placeholder}
							onChange={e => onChange.current[f.name](e.target.value)}
							error={Boolean(error[f.name])}
							errorText={error[f.name]}
							helperText={helpText}
							type={f.type}
							style={style}
							labelStyle={f.labelStyle}
							startAdornment={f.startAdornment && isFunction(f.startAdornment) ? f.startAdornment(props) : f.startAdornment}
							endAdornment={f.endAdornment && isFunction(f.endAdornment) ? f.endAdornment(props) : f.endAdornment}
							disabled={disabled}
							multiline={f.multiline}
							rowsMax={f.rowsMax}
							onBlur={f.onBlur ? e => f.onBlur(e.target.value, values, setValue) : null}
						/>;
				}
			}),
			validateFields: fields => {
				const newError = { ...error };
				let valid = true;

				// Validate all fields if an array of fields is not given
				forEach(fields ? form.fields.filter(f => fields.includes(f.name)) : form.fields, f => {
					const result = validateField(f, values, values[f.name]);

					newError[f.name] = result;
					valid = valid && !result;
				});

				setState({ ...state, error: newError, valid });

				if (!valid && props.handleError) props.handleError();

				return valid;
			}
		};

		return <WrappedComponent {...formProps} {...props} />;
	}

	return FormComponent;
};

export const normalizeErrors = (error, form) => {
	const errors = [];
	forEach(Object.keys(error), (fieldName, i) => {
		if (error[fieldName]) {
			if (isObject(error[fieldName])) {
				forOwn(error[fieldName], (v, k) => {
					errors.push(`${find(form.fields, f => f.name === fieldName).label}: ${v}`);
				});
			} else {
				errors.push(`${find(form.fields, f => f.name === fieldName).label}: ${error[fieldName]}`);
			}
		}
	});
	return errors;
}
