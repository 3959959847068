import find from 'lodash/find';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { globals } from '../globals';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	registerDefinition: null, 
	registerDefinitions: null
};

const CLEAR_REGISTER_DEFINITIONS = 'CLEAR_REGISTER_DEFINITIONS';
const CLEAR_REGISTER_DEFINITION = 'CLEAR_REGISTER_DEFINITION';
const FETCH_REGISTER_DEFINITION = 'FETCH_REGISTER_DEFINITION';
const DELETE_REGISTER_DEFINITION = 'DELETE_REGISTER_DEFINITION';
const REQUEST_REGISTER_DEFINITIONS = 'REQUEST_REGISTER_DEFINITIONS';
const RECEIVE_REGISTER_DEFINITIONS = 'RECEIVE_REGISTER_DEFINITIONS';
const REQUEST_REGISTER_DEFINITION = 'REQUEST_REGISTER_DEFINITION';
const RECEIVE_REGISTER_DEFINITION = 'RECEIVE_REGISTER_DEFINITION';
const CREATE_NEW_REGISTER_DEFINITION = 'CREATE_NEW_REGISTER_DEFINITION';
const SAVE_REGISTER_DEFINITION = 'SAVE_REGISTER_DEFINITION';
const RECEIVE_SAVE_REGISTER_DEFINITION_RESPONSE = 'RECEIVE_SAVE_REGISTER_DEFINITION_RESPONSE';
const RECEIVE_DELETE_REGISTER_DEFINITION_RESPONSE = 'RECEIVE_DELETE_REGISTER_DEFINITION_RESPONSE';
const SHOW_REGISTER_DEFINITION_ERRORS = 'SHOW_REGISTER_DEFINITION_ERRORS';

export const clearRegisterDefinitions = () => ({ type: CLEAR_REGISTER_DEFINITIONS });
export const clearRegisterDefinition = () => ({ type: CLEAR_REGISTER_DEFINITION });
export const fetchRegisterDefinition = (registerDefinitionId) => ({ type: FETCH_REGISTER_DEFINITION, registerDefinitionId });
export const receiveRegisterDefinitions = (data) => ({ type: RECEIVE_REGISTER_DEFINITIONS, payload: { data } });
export const receiveRegisterDefinition = (data) => ({ type: RECEIVE_REGISTER_DEFINITION, payload: { data } });
export const createNewRegisterDefinition = () => ({ type: CREATE_NEW_REGISTER_DEFINITION });
export const receiveSaveRegisterDefinitionResponse = (data) => ({ type: RECEIVE_SAVE_REGISTER_DEFINITION_RESPONSE, data });
export const receiveDeleteRegisterDefinitionResponse = (data) => ({ type: RECEIVE_DELETE_REGISTER_DEFINITION_RESPONSE, data });

export const requestRegisterDefinitions = () => (
	createFetchAction({
		objectName: 'Register Definitions',
		passContext: true,
		url: '/api/registers/definitions',
		startAction: REQUEST_REGISTER_DEFINITIONS,
		success: (data) =>
		receiveRegisterDefinitions(map(data, (r) => ({
				...r,
				dateCreated: r.dateCreated ? new Date(r.dateCreated) : null
			})))
	})
);

export const requestRegisterDefinition = (registerDefinitionId, onSuccess) => (
	createFetchAction({
		objectName: 'Register Definition',
		passContext: true,
		url: `/api/registers/${registerDefinitionId}/definition`,
		startAction: REQUEST_REGISTER_DEFINITION,
		success: (data, dispatch) => {
			dispatch(receiveRegisterDefinition({
				...data,
				dateCreated: data.dateCreated ? new Date(data.dateCreated) : null
			}));
			if (onSuccess) onSuccess.call(this, data);
		}
	})
);

const validateRegisterDefinition = (def) => {
	const result = {
		success: false,
		message: '',
		fields: []
	};
	if (def.name <= 0) {
		result.fields.push({
			fieldName: 'Name',
			valid: false,
			message: 'Name is required'
		});
	}
	if (def.fields.length === 0) {
		result.message = 'You must add at least one field';
	}
	forEach(def.fields, (f) => {
		if (!f.name) {
			result.fields.push({
				fieldName: 'Field.Name',
				data: f,
				valid: false,
				message: 'Field Name is required'
			});
		}
		if (!f.label) {
			result.fields.push({
				fieldName: 'Field.Label',
				data: f,
				valid: false,
				message: 'Field Label is required'
			});
		}
	});

	result.success = !result.message && result.fields.length === 0;
	if (!result.success && !result.message) result.message = 'Please correct the errors';
	return result;
};

export const saveRegisterDefinition = (registerDefinition, onSuccess) => {
	const result = validateRegisterDefinition(registerDefinition);
	if (!result.success) return { type: SHOW_REGISTER_DEFINITION_ERRORS, data: { errors: result, registerDefinition } };

	return createPostAction({
		passContext: true,
		url: '/api/registers/definition',
		data: registerDefinition,
		startAction: SAVE_REGISTER_DEFINITION,
		success: (data, dispatch) => {
			const d = {
				...data,
				object: data.object ? {
					...data.object,
					endDate: data.object.dateCreated ? new Date(data.object.dateCreated) : null
				} : data.object
			};	
			dispatch(receiveSaveRegisterDefinitionResponse(d));
			if (d.success && onSuccess) onSuccess.call(this, d);
		}
	});
};

export const deleteRegisterDefinition = (registerDefinition) => (
	createPostAction({
		passContext: true,
		url: `/api/registers/${registerDefinition.registerDefinitionId}/delete-definition`,
		startAction: DELETE_REGISTER_DEFINITION,
		success: (data) => receiveDeleteRegisterDefinitionResponse(data)
	})
);

export default (originalState = initialState, action) => {
	let state = originalState;
	if (!state._hydrated) {
		state = { ...initialState, ...state, _hydrated: true };
	}
	
	switch (action.type) {
		case CLEAR_REGISTER_DEFINITIONS:
			return {
				...state,
				registerDefinitions: null
			};
		case CLEAR_REGISTER_DEFINITION:
			return {
				...state,
				registerDefinition: null
			};
		case REQUEST_REGISTER_DEFINITIONS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				registerDefinitions: []
			};
		case RECEIVE_REGISTER_DEFINITIONS:
			return {
				...state,
				isLoading: false,
				registerDefinitions: action.payload.data
			};
		case REQUEST_REGISTER_DEFINITION:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				registerDefinition: { ...cloneDeep(globals.templates.registerDefinition) }
			};
		case RECEIVE_REGISTER_DEFINITION:
			return {
				...state,
				isLoading: false,
				registerDefinition: action.payload.data
			};
		case DELETE_REGISTER_DEFINITION:
			return { 
				...state
			};
		case RECEIVE_DELETE_REGISTER_DEFINITION_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message
					}, 
				};
			}
			return {
				...state,
				isLoading: false,
				registerDefinitions: state.registerDefinitions.filter(c => c.registerDefinitionId !== action.data.objectId) 
			};
		case FETCH_REGISTER_DEFINITION:
			return { 
				...state,
				registerDefinition: find(state.registerDefinitions, (c) => c.registerDefinitionId === action.registerDefinitionId)
			};
		case CREATE_NEW_REGISTER_DEFINITION:
			return {
				...state,
				registerDefinition: { ...cloneDeep(globals.templates.registerDefinition) }
			};	
		case SAVE_REGISTER_DEFINITION:
			return {
				...state,
				isLoading: true,
				registerDefinition: action.data,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_REGISTER_DEFINITION_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				registerDefinition: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_REGISTER_DEFINITION_ERRORS:
			return {
				...state,
				isLoading: false,
				registerDefinition: action.data.registerDefinition,
				saveResult: {
					success: false,
					message: action.data.errors.message,
					fields: action.data.errors.fields
				},
			};
		default:
			return state;
	}
};
