import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ForecastsGrid from './forecastsGrid';
import { 
	requestScheduleForecasts as requestForecasts, 
	clearScheduleForecasts as clearForecasts, 
	clearSaveResult, 
	editScheduleForecast as editForecast, 
	saveScheduleForecast as saveForecast, 
	deleteScheduleForecast as deleteForecast, 
	saveScheduleForecastAndNew as saveForecastAndNew, 
	createNewScheduleForecast as createNewForecast
} from '../../reducers/scheduleForecasts';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { showSuccessNotification } from '../../reducers/notifications';

class Forecasts extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

		this.saveForecast = this.saveForecast.bind(this);
		this.saveForecastAndNew = this.saveForecastAndNew.bind(this);
		this.handleDeleteForecast = this.handleDeleteForecast.bind(this);
		this.handleViewForecast = this.handleViewForecast.bind(this);
		this.handleEditForecast = this.handleEditForecast.bind(this);
    }

	static fetchData(dispatch) {
		dispatch(requestForecasts());
	}
	static clearData(dispatch) {
		dispatch(clearForecasts());
	}

	componentDidMount() {
		Forecasts.fetchData(this.props.dispatch);
	}

	handleViewForecast = (forecast) => {
		this.props.history.push(`/forecast/${forecast.scheduleForecastId}`);
	}
	handleEditForecast = (forecast) => {
		this.props.history.push(`/forecast/${forecast.scheduleForecastId}`);
	}

	handleDeleteForecast(forecast) {
		this.props.deleteForecast(forecast);
	}

	saveForecast() {
		this.props.saveChanges(this.state.forecast);
	}
	saveForecastAndNew() {
		this.props.saveChangesAndNew(this.state.forecast);
	}
	newForecast = () => {
		this.props.history.push('/forecast');
	}

	render() {
		const forecasts = this.props.forecasts;

		return (
<PageLayout
	title="Forecasts"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Forecast',
				onClick: this.newForecast
			}
		] : []
	}
	content={
		<ContentContainer>
			<ForecastsGrid 
				forecasts={forecasts} 
				canEdit={this.props.canEdit} 
				handleViewForecast={this.handleViewForecast} 
				handleEditForecast={this.handleEditForecast} 
				handleDeleteForecast={this.handleDeleteForecast} 
			/>
		</ContentContainer>
	}
/>
		);
	}
}

Forecasts.propTypes = {
	dispatch: PropTypes.func.isRequired,
	forecasts: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndNew: PropTypes.func.isRequired,
	deleteForecast: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
	forecasts: state.scheduleForecasts.forecasts,
	canEdit: canEdit(state, 'EditForecasts'),
	scheduleOfRates: state.scheduleOfRates.scheduleOfRates,
	periods: state.context.contract.periods,
	isLoading: state.scheduleForecasts.isLoading,
	saveResult: state.scheduleForecasts.saveResult
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	editForecast: (forecast) => {
		dispatch(editForecast(forecast));
	},
	clearSaveResult: () => {
		dispatch(clearSaveResult());
	},
	saveChanges: (forecast) => {
		dispatch(saveForecast(forecast, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	},
	saveChangesAndNew: (forecast) => {
		dispatch(saveForecastAndNew(forecast, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	},
	deleteForecast: (forecast) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this forecast?',
			onOk: () => {
				dispatch(deleteForecast(forecast));
			}
		}));
	},
	createNewForecast: () => {
		dispatch(createNewForecast());
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Forecasts);
