import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import map from 'lodash/map';
import { globals } from '../globals';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	extensionOfTime: null, 
	extensionsOfTime: null
};

const CLEAR_EXTENSIONS_OF_TIME = 'CLEAR_EXTENSIONS_OF_TIME';
const CLEAR_EXTENSION_OF_TIME = 'CLEAR_EXTENSION_OF_TIME';
const REQUEST_EXTENSION_OF_TIME = 'REQUEST_EXTENSION_OF_TIME';
const RECEIVE_EXTENSION_OF_TIME = 'RECEIVE_EXTENSION_OF_TIME';
const REQUEST_EXTENSIONS_OF_TIME = 'REQUEST_EXTENSIONS_OF_TIME';
const RECEIVE_EXTENSIONS_OF_TIME = 'RECEIVE_EXTENSIONS_OF_TIME';
const REQUEST_NEW_EXTENSION_OF_TIME = 'REQUEST_NEW_EXTENSION_OF_TIME';
const RECEIVE_NEW_EXTENSION_OF_TIME = 'RECEIVE_NEW_EXTENSION_OF_TIME';
const SAVE_EXTENSION_OF_TIME = 'SAVE_EXTENSION_OF_TIME';
const RECEIVE_SAVE_EXTENSION_OF_TIME_RESPONSE = 'RECEIVE_SAVE_EXTENSION_OF_TIME_RESPONSE';
const DELETE_EXTENSION_OF_TIME = 'DELETE_EXTENSION_OF_TIME';
const RECEIVE_DELETE_EXTENSION_OF_TIME_RESPONSE = 'RECEIVE_DELETE_EXTENSION_OF_TIME_RESPONSE';
const SHOW_EXTENSION_OF_TIME_ERRORS = 'SHOW_EXTENSION_OF_TIME_ERRORS';

export const clearExtensionsOfTime = () => ({ type: CLEAR_EXTENSIONS_OF_TIME });
export const clearExtensionOfTime = () => ({ type: CLEAR_EXTENSION_OF_TIME });
export const receiveExtensionsOfTime = (data) => ({ type: RECEIVE_EXTENSIONS_OF_TIME, payload: { data } });
export const receiveExtensionOfTime = (data) => ({ type: RECEIVE_EXTENSION_OF_TIME, payload: { data } });
export const receiveSaveExtensionOfTimeResponse = (data) => ({ type: RECEIVE_SAVE_EXTENSION_OF_TIME_RESPONSE, data });
export const receiveDeleteExtensionOfTimeResponse = (data) => ({ type: RECEIVE_DELETE_EXTENSION_OF_TIME_RESPONSE, data });
export const receiveNewExtensionOfTime = (data) => ({ type: RECEIVE_NEW_EXTENSION_OF_TIME, payload: { data } });

export const createNewExtensionOfTime = () => (
	createFetchAction({
		objectName: 'New Extension of Time',
		passContext: true,
		url: '/api/eot/new',
		startAction: REQUEST_NEW_EXTENSION_OF_TIME,
		success: (data) => 
			receiveNewExtensionOfTime({
				...data,
				date: data.date ? new Date(data.date) : null,
				previousPracticalCompletionDate: data.previousPracticalCompletionDate ? new Date(data.previousPracticalCompletionDate) : null,
				revisedPracticalCompletionDate: data.revisedPracticalCompletionDate ? new Date(data.revisedPracticalCompletionDate) : null,
				separablePortions: map(data.separablePortions, (sp) => ({
					...sp,
					previousPracticalCompletionDate: sp.previousPracticalCompletionDate ? new Date(sp.previousPracticalCompletionDate) : null,
					revisedPracticalCompletionDate: sp.revisedPracticalCompletionDate ? new Date(sp.revisedPracticalCompletionDate) : null,
					practicalCompletionDate: sp.practicalCompletionDate ? new Date(sp.practicalCompletionDate) : null
				}))
			})
	})
);

export const requestExtensionsOfTime = () => (
	createFetchAction({
		objectName: 'Extensions of Time',
		passContext: true,
		url: '/api/eot',
		startAction: REQUEST_EXTENSIONS_OF_TIME,
		success: (data) => 
			receiveExtensionsOfTime(map(data, (e) => ({
				...e,
				date: e.date ? new Date(e.date) : null,
				previousPracticalCompletionDate: e.previousPracticalCompletionDate ? new Date(e.previousPracticalCompletionDate) : null,
				revisedPracticalCompletionDate: e.revisedPracticalCompletionDate ? new Date(e.revisedPracticalCompletionDate) : null,
				separablePortions: map(e.separablePortions, (sp) => ({
					...sp,
					previousPracticalCompletionDate: sp.previousPracticalCompletionDate ? new Date(sp.previousPracticalCompletionDate) : null,
					revisedPracticalCompletionDate: sp.revisedPracticalCompletionDate ? new Date(sp.revisedPracticalCompletionDate) : null,
					practicalCompletionDate: sp.practicalCompletionDate ? new Date(sp.practicalCompletionDate) : null
				}))
			})))
	})
);

export const requestExtensionOfTime = (extensionOfTimeId) => (
	createFetchAction({
		objectName: 'Extension of Time',
		passContext: true,
		url: `/api/eot/${extensionOfTimeId}`,
		startAction: REQUEST_EXTENSION_OF_TIME,
		success: (data) => 
			receiveExtensionOfTime({
				...data,
				date: data.date ? new Date(data.date) : null,
				previousPracticalCompletionDate: data.previousPracticalCompletionDate ? new Date(data.previousPracticalCompletionDate) : null,
				revisedPracticalCompletionDate: data.revisedPracticalCompletionDate ? new Date(data.revisedPracticalCompletionDate) : null,
				separablePortions: map(data.separablePortions, (sp) => ({
					...sp,
					previousPracticalCompletionDate: sp.previousPracticalCompletionDate ? new Date(sp.previousPracticalCompletionDate) : null,
					revisedPracticalCompletionDate: sp.revisedPracticalCompletionDate ? new Date(sp.revisedPracticalCompletionDate) : null,
					practicalCompletionDate: sp.practicalCompletionDate ? new Date(sp.practicalCompletionDate) : null
				}))
			})
	})
);

export const saveExtensionOfTime = (extensionOfTime, newFiles, onSuccess) => {
	const errors = [];
	if (!extensionOfTime.extensionOfTimeNumber) {
		errors.push({
			fieldName: 'ExtensionOfTimeNumber',
			valid: false,
			message: 'Extension Of Time Number is required'
		});
	}
	if (!extensionOfTime.status) {
		errors.push({
			fieldName: 'Status',
			valid: false,
			message: 'Status is required'
		});
	}
	if (errors.length > 0) return { type: SHOW_EXTENSION_OF_TIME_ERRORS, data: errors };

	const formData = new FormData();
	formData.append('ExtensionOfTime', encodeURIComponent(JSON.stringify(extensionOfTime)));
	forEach(newFiles, (f) => {
		formData.append('NewFiles', f.file);
	});

	return createFormPostAction({
		passContext: true,
		url: '/api/eot',
		formData,
		startAction: SAVE_EXTENSION_OF_TIME,
		success: (data, dispatch) => {
			dispatch(receiveSaveExtensionOfTimeResponse({
				...data,
				object: data.object ? {
					...data.object,
					date: data.object.date ? new Date(data.object.date) : null,
					previousPracticalCompletionDate: data.object.previousPracticalCompletionDate ? new Date(data.object.previousPracticalCompletionDate) : null,
					revisedPracticalCompletionDate: data.object.revisedPracticalCompletionDate ? new Date(data.object.revisedPracticalCompletionDate) : null,
					practicalCompletionDate: data.object.practicalCompletionDate ? new Date(data.object.practicalCompletionDate) : null,
					separablePortions: map(data.object.separablePortions, (sp) => ({
						...sp,
						previousPracticalCompletionDate: sp.previousPracticalCompletionDate ? new Date(sp.previousPracticalCompletionDate) : null,
						revisedPracticalCompletionDate: sp.revisedPracticalCompletionDate ? new Date(sp.revisedPracticalCompletionDate) : null,
						practicalCompletionDate: sp.practicalCompletionDate ? new Date(sp.practicalCompletionDate) : null
					}))
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteExtensionOfTime = (extensionOfTime) => (
	createPostAction({
		passContext: true,
		url: `/api/eot/${extensionOfTime.extensionOfTimeId}/delete`,
		data: extensionOfTime,
		startAction: DELETE_EXTENSION_OF_TIME,
		success: (data) => receiveDeleteExtensionOfTimeResponse(data)
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_EXTENSIONS_OF_TIME:
			return {
				...state,
				extensionsOfTime: null
			};
		case CLEAR_EXTENSION_OF_TIME:
			return {
				...state,
				extensionOfTime: null
			};
		case REQUEST_EXTENSIONS_OF_TIME:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				extensionsOfTime: []
			};
		case RECEIVE_EXTENSIONS_OF_TIME:
			return {
				...state,
				isLoading: false,
				extensionsOfTime: action.payload.data
			};
		case REQUEST_EXTENSION_OF_TIME:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				extensionOfTime: { ...cloneDeep(globals.templates.eot) }
			};
		case RECEIVE_EXTENSION_OF_TIME:
			return {
				...state,
				isLoading: false,
				extensionOfTime: action.payload.data
			};
		case DELETE_EXTENSION_OF_TIME:
			return { 
				...state
			};
		case RECEIVE_DELETE_EXTENSION_OF_TIME_RESPONSE:
			return {
				...state,
				extensionsOfTime: state.extensionsOfTime.filter(e => e.extensionOfTimeId !== action.data.objectId) 
			};
		case REQUEST_NEW_EXTENSION_OF_TIME:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				extensionOfTime: { ...cloneDeep(globals.templates.eot) }
			};
		case RECEIVE_NEW_EXTENSION_OF_TIME:
			return {
				...state,
				isLoading: false,
				extensionOfTime: action.payload.data
			};
		case SAVE_EXTENSION_OF_TIME:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_EXTENSION_OF_TIME_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				extensionsOfTime: addOrUpdate(state.extensionsOfTime, action.data.object, { extensionOfTimeId: action.data.object.extensionOfTimeId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_EXTENSION_OF_TIME_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
