import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverDateField from '../components/handoverDateField';
import HandoverBooleanField from '../components/handoverBooleanField';
import HandoverMemoField from '../components/handoverMemoField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';
import filter from 'lodash/filter';

class DesignCorrespondenceHandoverContent extends React.Component {
	render() {
		const correspondence = this.props.report;
		const files = filter(correspondence.files, (f) => f.referenceType === 'DesignCorrespondence');
		const responseFiles = filter(correspondence.files, (f) => f.referenceType === 'DesignCorrespondence.Response');

		const filesColumns = [
			{ name: 'name', label: 'Name' }
		];

		const linkedCorrespondence = filter(correspondence.linkedCorrespondence || [], c => !c.isParent);
		
		const linkedCorrespondenceColumns = [
			{ name: 'correspondenceType', label: 'Type' },
			{ name: 'correspondenceNumber', label: 'Number' },
			{ name: 'correspondenceTitle', label: 'Title' }
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="DRR Number" value={correspondence.drrNumber} />
				<HandoverField label="Project Number" value={correspondence.projectNumber} />
				<HandoverField label="Description" value={correspondence.description} />
				<HandoverDateField label="Date Sent" value={correspondence.dateSent} />
				<HandoverDateField label="Response Required By" value={correspondence.responseRequiredBy} />
				<HandoverField label="Issued By" value={correspondence.raisedByName} />
				<HandoverField label="Issued To" value={correspondence.addressTo} />
				<HandoverField label="Delivery Method" value={correspondence.deliveryMethod} />
				<HandoverBooleanField label="Designer Response" value={correspondence.designerResponse} />
				<HandoverField label="Response" value={correspondence.response} />
				<HandoverDateField label="Final Response Date" value={correspondence.finalResponseDate} />
				<HandoverField label="Status" value={correspondence.status} />
				<HandoverField label="Actioned By" value={correspondence.actionedByName} />
				<HandoverMemoField label="Comments" value={correspondence.comments} />
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Linked Correspondence</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={linkedCorrespondence || []}
					columns={linkedCorrespondenceColumns}
				/>
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Files</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={files || []}
					columns={filesColumns}
				/>
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Response Files</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={responseFiles || []}
					columns={filesColumns}
				/>
			</div>
		</div>
    );
  }
}

DesignCorrespondenceHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default DesignCorrespondenceHandoverContent;
