import React from 'react';
import EmployeeDialog from './EmployeeDialog';
import { 
	IconButton,
	Table, 
	TableHead,
	TableBody, 
	TableRow, 
	TableCell 
} from '@material-ui/core';
import map from 'lodash/map';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const EmployeeField = props => {
	const [employee, setEmployee] = React.useState(null);
	const [isNewEmployee, setIsNewEmployee] = React.useState(true);
	const [showEmployeeDialog, setShowEmployeeDialog] = React.useState(false);
	const value = props.value;

	return (
	<React.Fragment>
		<EmployeeDialog
			show={showEmployeeDialog} 
			onCancel={() => {
				setShowEmployeeDialog(false);
			}}
			employee={employee}
			isNew={isNewEmployee}
			onOk={(newEmployee) => {
				const employees = value || [];
				employees.push(
					newEmployee
				);
				props.onChange(employees);
				setShowEmployeeDialog(false);
			}}
		/>
		
		<Table>
			<TableHead>
				<TableRow>
					<TableCell style={{ width: '110px', padding: 0 }}>
						<IconButton 
							onClick={() => { 
								setEmployee({
								});
								setIsNewEmployee(true);
								setShowEmployeeDialog(true); 
							}}
							style={{ marginLeft: 5 }}
						>
							<AddIcon />
						</IconButton>
					</TableCell>
					<TableCell>Name</TableCell>
					<TableCell>Email</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{map(value, (u, i) =>
					<TableRow key={`employee-${i}`}>
						<TableCell style={{ padding: '0px' }}>
							<IconButton 
								onClick={() => { 
									setIsNewEmployee(false);
									setEmployee(u); 
									setShowEmployeeDialog(true);
								}} 
								style={{ marginLeft: 5 }}
							>
								<EditIcon />
							</IconButton>
							<IconButton 
								onClick={() => { 
									const employees = value;
									employees.splice(i, 1);
									props.onChange(employees);
								}} 
								style={{ marginLeft: 5 }}
							>
								<DeleteIcon />
							</IconButton>
						</TableCell>
						<TableCell>{u.name}</TableCell>
						<TableCell>{u.email}</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	</React.Fragment>
	);
};

export default EmployeeField;
