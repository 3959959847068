import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import ContentContainer from '../contentContainer';
import Chip from '@material-ui/core/Chip';
import { infoColor, infoForeColor } from '../../variables';

class TagForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			tag: props.tag
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			tag: nextProps.tag
		});
	}

	render() {
		const tag = this.state.tag;

		return (
<div>
	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={tag.name}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('name', v); }}
				label="Name"
				errorText={getFieldErrorText(this.props.saveResult, 'Name')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={tag.description}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('description', v); }}
				label="Description"
				errorText={getFieldErrorText(this.props.saveResult, 'Description')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<Chip
				style={{ background: infoColor, color: infoForeColor, margin: '10px 10px 0 0' }}
				label={`${tag.lotCount} Lots`}
			/>
			<Chip
				style={{ background: infoColor, color: infoForeColor, margin: '10px 10px 0 0' }}
				label={`${tag.incomingCorrespondenceCount} Incoming Correspondence`}
			/>
			<Chip
				style={{ background: infoColor, color: infoForeColor, margin: '10px 10px 0 0' }}
				label={`${tag.outgoingCorrespondenceCount} Outgoing Correspondence`}
			/>
			<Chip
				style={{ background: infoColor, color: infoForeColor, margin: '10px 10px 0 0' }}
				label={`${tag.principalCorrespondenceCount} Principal Correspondence`}
			/>
			<Chip
				style={{ background: infoColor, color: infoForeColor, margin: '10px 10px 0 0' }}
				label={`${tag.designCorrespondenceCount} Design Correspondence`}
			/>
			<Chip
				style={{ background: infoColor, color: infoForeColor, margin: '10px 10px 0 0' }}
				label={`${tag.nonConformanceRegisterCount} Non Conformance Registers`}
			/>
		</FormFieldContainer>
	</ContentContainer>
</div>
		);
	}
}

TagForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	tag: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default TagForm;
