import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';

class TagsGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.tags || this.props.tags.length !== nextProps.tags.length;
	}

	render() {
		const tags = this.props.tags || [];

		const columns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => {
					if (this.props.canEdit) {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.onEditTag(args.data); } } style={{ padding: '2px' }}>
									<EditIcon />
								</IconButton>
								<IconButton onClick={ () => { this.props.onDeleteTag(args.data); } } style={{ padding: '2px' }}>
									<ActionDelete />
								</IconButton>
							</div>
						);
					} else {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.onViewTag(args.data); } } style={{ padding: '2px' }}>
									<ViewIcon />
								</IconButton>
							</div>
						);
					}
				}	
			},
			{ name: 'name', label: 'Name' },
			{ name: 'description', label: 'Description' },
			{ 
				name: 'isActive', 
				label: 'Is Active', 
				dataType: 'bool',
				value: (args) => {
					return args.data.lotCount > 0
						|| args.data.incomingCorrespondenceCount > 0
						|| args.data.outgoingCorrespondenceCount > 0
						|| args.data.principalCorrespondenceCount > 0
						|| args.data.designCorrespondenceCount > 0
						|| args.data.nonConformanceRegisterCount > 0;
				}
			}
		];

		return (
			<PersistedDataTable 
				id="tags"
				data={tags} 
				columns={columns}
			/>
		);
	}
}

TagsGrid.propTypes = {
	tags: PropTypes.array,
	canEdit: PropTypes.bool.isRequired,
	onEditTag: PropTypes.func.isRequired,
	onViewTag: PropTypes.func.isRequired,
	onDeleteTag: PropTypes.func.isRequired
};

TagsGrid.defaultProps = {
	tags: []
};

export default TagsGrid;
