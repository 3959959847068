import React from 'react';
import connectedRouterHOC from '../../../utils/connectedRouterHOC';
import PropTypes from 'prop-types';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions,
	Grid
} from '@material-ui/core';
import Button from '../../widgets/button';
import { withForm } from '../../../utils/forms';
import Form from '../../widgets/Form';

const form = {
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "name",
			label: "Name",
			required: true
		},
		{
			name: "email",
			label: "Email",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid address",
			required: false
		}
	]
};

const EmployeeDialog = props => {
	const classes = props.classes;
	const { fields, values, isNew, employee, updateValues } = props;

	React.useEffect(() => {
		updateValues(employee);
	}, employee);

	const onClose = () => {
		props.onCancel();
	};
	const onSubmit = () => {
		if (props.validateFields()) {
			props.onOk(values);
		} 
	}
	return (
		<Dialog fullWidth={true} maxWidth="lg" open={props.show} onClose={onClose} style={{ zIndex: 9990 }} classes={classes}>
			<DialogTitle>{props.isNew ? "Add Employee" : "Edit Employee"}</DialogTitle>
			<DialogContent style={{ }}>
				<Form 
					onSubmit={onSubmit}
					style={{ }}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>{fields.name()}</Grid>
						<Grid item xs={12} md={6}>{fields.email()}</Grid>
					</Grid>
				</Form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
	 			<Button onClick={onSubmit} primary={true}>{isNew ? "Add" : "Update"}</Button>
			</DialogActions>
		</Dialog>
	);
};

EmployeeDialog.propTypes = {
	show: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired
};

EmployeeDialog.defaultProps = {
	show: false
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(withForm(form)(EmployeeDialog));
