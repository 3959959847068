import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import ContentContainer from '../contentContainer';
import SubContentContainer from '../subContentContainer';
import FloatingActionButton from '@material-ui/core/Fab';
import ContentAdd from '@material-ui/icons/Add';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import map from 'lodash/map';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import some from 'lodash/some';
import includes from 'lodash/includes';
import FormSectionHeader from '../widgets/formSectionHeader';
import PersistedDataTable from '../widgets/persistedDataTable';
import LotAdjustmentItemDialog from './lotAdjustmentItemDialog';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import DataTableTextEditor from '../widgets/dataTableTextEditor';
import { BigNumber } from 'bignumber.js';

class LotAdjustmentForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			showLotAdjustmentItemDialog: false
		};

		this.showAddDialog = this.showAddDialog.bind(this);
		this.onCancelLotAdjustmentDialog = this.onCancelLotAdjustmentDialog.bind(this);
		this.onAddLotAdjustmentItems = this.onAddLotAdjustmentItems.bind(this);
	}

	showAddDialog() {
		this.setState({ showLotAdjustmentItemDialog: true });
	}
	onCancelLotAdjustmentDialog() {
		this.setState({ showLotAdjustmentItemDialog: false });
	}
	onAddLotAdjustmentItems(lotItems) {
		this.setState({
			showLotAdjustmentItemDialog: false
		});
		this.props.onAddLotAdjustmentItems(lotItems);
	}

	isRowSelectable = (row) => !row.isTextOnlyLine;
	isRowSelected = (row) => !row.isTextOnlyLine && this.state.lotAdjustment && row.lotId === this.state.lotAdjustment.lotId;
	onSelectionChanged = (rows)  => {
		this.props.selectLot(rows[0]);
	}

	render() {
		const lotAdjustment = this.props.lotAdjustment || {};
		const items = lotAdjustment.items || [];
		const adjustableLots = orderBy(filter(this.props.lots || [], (l) => includes(['Closed'], l.status)), 'lotNo');
		const lot = lotAdjustment.lotId > 0 ? find(this.props.lots, (l) => l.lotId === lotAdjustment.lotId) : null;
		const lotItems = lot ? lot.items : [];

		const lotColumns = [
			{ name: 'lotNo', label: 'Lot No' },
			{ name: 'lotDescription', label: 'Lot Description', headerStyle: { minWidth: '300px' } },
			{ 
				name: 'status', 
				label: 'Lot Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					switch (args.data.status) {
						case 'Guaranteed':
							statusBackColour = '#f0ad4e';
							statusForeColour = '#222';
							break;
						case 'Guaranteed>1Month':
							statusBackColour = '#FFC7CE';
							statusForeColour = '#9C0006';
							break;
						case 'Closed':
							statusBackColour = '#C6EFCE';
							statusForeColour = '#2C6153';
							break;
						default:
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Guaranteed', 'Guaranteed>1Month', 'Closed']
			},
			{ name: 'numItems', label: 'Items', dataType: 'number' },
			{ 
				name: 'totalAmount', 
				label: 'Lot Amount', 
				dataType: 'number', 
				formatString: 'currency' 
			},
			{ name: 'periodClaimedName', label: 'Period Claimed' },
			{
				name: 'tags',
				label: 'Tags',
				displayValue: (args) => 
					map(filter(this.props.tags, (t) => some(args.data.tags, (t2) => t2.tagId === t.tagId)), (t) => t.name).join(','),
				filterOperators: ['multi-select'],
				filterOptions: this.props.tags,
				filterOptionsKey: 'tagId',
				filterOptionsText: 'name'
			},
			{ 
				name: 'ncrs', 
				label: 'NCRs',
				value: (args) => {
					if (!args.data.ncRs || args.data.ncRs.length === 0) return '';
					return map(args.data.ncRs, (ncr) => ncr.contractorNcrNo).join(', ');
				}
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];


		const itemColumns = [
			{ 
				name: 'isExistingItem', 
				label: 'New or Existing',
				value: args => args.data.lotItemId ? 'Existing' : 'New'
			},
			{ name: 'scheduleName', label: 'Schedule' },
			{ name: 'itemNo', label: 'Item' },
			{ name: 'itemDescription', label: 'Description' },
			{ 
				name: 'totalQty', 
				label: 'Total Qty',
				cellRenderer: (args) =>
					<div>
						<DataTableNumericEditor
							type="decimal"
							value={new BigNumber(args.data.originalQty).plus(args.data.totalAdjustments).toNumber()} 
							onChange={(v) => {
								const change = new BigNumber(v).minus(args.data.originalQty).minus(args.data.totalAdjustments).toNumber();
								const qty = new BigNumber(args.data.qty).plus(change).toNumber();
								const totalAdjustments = new BigNumber(args.data.totalAdjustments).plus(change).toNumber();
								const newAdjustmentItem = {
									...args.data, 
									qty: qty,
									totalAdjustments: totalAdjustments
								};
								this.props.updateLotAdjustmentItem(newAdjustmentItem); 
							}}
							style={{ width: 'auto' }}
						/>
					</div>
			},
			{
				name: 'qty',
				label: 'Adjusted Quantity'
			},
			{ 
				name: 'comments', 
				label: 'Comments', 
				headerStyle: { minWidth: '200px' },
				cellRenderer: (args) =>
					<div>
						<DataTableTextEditor
							name="lotAdjustment.item.comments"
							value={args.data.comments}
							fullWidth={true}
							multiline={true}
							onChange={(v) => { this.props.updateLotAdjustmentItem({ ...args.data, comments: v }); }}
						/>
					</div>
			}
		];

		if (this.props.canEdit) {
			itemColumns.unshift({
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.deleteLotAdjustmentItem(args.data); } }>
							<ActionDelete />
						</IconButton>
					</div>
			});
		}

		let content;
		if (lotAdjustment._isNew && lotAdjustment.lotId <= 0) {
			if (adjustableLots.length > 0) {
				content = (
					<ContentContainer>
						<div>
							<FormSectionHeader 
								text="Select the Lot to Adjust" 
								style={{ 
									paddingTop: 0,
									marginBottom: 0
								}}
							/>
							<PersistedDataTable 
								id="newLotAdjustmentLotds"
								name="Lot Adjustment Lots"
								data={adjustableLots} 
								columns={lotColumns}
								enableSelection={true}
								onSelectionChanged={this.onSelectionChanged}
								isRowSelected={this.isRowSelected}
								isRowSelectable={this.isRowSelectable}
								canSelectAll={false}
							/>
						</div>
					</ContentContainer>
				);
			} else {
				content = (
					<ContentContainer>
						<div>
							{!this.props.isLoading && <p>There are no lots available to be adjusted.</p>}
						</div>
					</ContentContainer>
				);
			}
		} else {
			content = (
				<div>
					<ContentContainer>
						<FormFieldContainer>
							<TextField
								value={lotAdjustment.lotNo}
								fullWidth={true}
								disabled={true}
								label="Lot No"
							/>
						</FormFieldContainer>

						<FormFieldContainer>
							<TextField 
								value={lotAdjustment.adjustmentType} 
								label="Adjustment Description"
								onChange={(e, v) => { this.props.updateLotAdjustment({ adjustmentType: v }); } } 
								fullWidth={true}
							/>
						</FormFieldContainer>

						<FormFieldContainer>
							<TextField
								value={lotAdjustment.comments}
								fullWidth={true}
								multiline={true}
								onChange={(e, v) => { this.props.updateLotAdjustment({ comments: v }); }}
								label="Comments"
								errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
							/>
						</FormFieldContainer>
					</ContentContainer>

					<SubContentContainer header="Items">
						<PersistedDataTable 
							id="lotAdjustmentItems"
							data={items} 
							columns={itemColumns}
						/>

						{this.props.canEdit &&
							<div className="row">
								<FloatingActionButton onClick={this.showAddDialog} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
									<ContentAdd />
								</FloatingActionButton>
							</div>
						}
					</SubContentContainer>
				</div>
			);
		}

		return (
<div>
	<LotAdjustmentItemDialog 
		show={this.state.showLotAdjustmentItemDialog} 
		addLotItems={this.onAddLotAdjustmentItems} 
		cancel={this.onCancelLotAdjustmentDialog} 
		scheduleOfRates={this.props.scheduleOfRates}
		lotId={this.props.lotAdjustment.lotId}
		items={lotItems}
		fullWidth={true}
		maxWidth='lg'
	/>

	{content}
</div>
		);
	}
}

LotAdjustmentForm.propTypes = {
	selectLot: PropTypes.func.isRequired,
	updateLotAdjustment: PropTypes.func.isRequired,
	onAddLotAdjustmentItems: PropTypes.func.isRequired,
	updateLotAdjustmentItem: PropTypes.func.isRequired,
	deleteLotAdjustmentItem: PropTypes.func.isRequired,
	lotAdjustment: PropTypes.object.isRequired,
	scheduleOfRates: PropTypes.array,
	tags: PropTypes.array,
	lots: PropTypes.array,
	canEdit: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

LotAdjustmentForm.defaultProps = {
	scheduleOfRates: [],
	tags: [],
	lots: []
}

export default LotAdjustmentForm;
