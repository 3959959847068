import React from 'react';
import PropTypes from 'prop-types';
import { 
	Tooltip, 
	Legend, 
	PieChart, 
	Pie, 
	Cell,
	ResponsiveContainer
} from 'recharts';

const LotCounts = ({ contract }) => {
	if (!contract) return <div></div>;
	
	const lotStatuses = [];
	lotStatuses.push({ status: 'Open', value: contract.open });
	lotStatuses.push({ status: 'Guaranteed', value: contract.guaranteed });
	lotStatuses.push({ status: 'Guaranteed > 1 Month', value: contract.guaranteed1Mth });
	lotStatuses.push({ status: 'Closed', value: contract.closed });
	return (
		<ResponsiveContainer>
		<PieChart>
			<Pie 
				data={lotStatuses} 
				dataKey="value" 
				nameKey="status" 
				outerRadius={80} 
				fill="#8884d8"
			>
				{
					lotStatuses.map((entry) => {
						let colour;
						switch (entry.status) {
							case 'Open':
								colour = '#eee';
								break;
							case 'Guaranteed':
								colour = '#f0ad4e';
								break;
							case 'Guaranteed > 1 Month':
								colour = '#d84a38';
								break;
							case 'Closed':
								colour = '#35aa47';
								break;
							default:
						}
						return (
							<Cell key={`cell-${entry.status}`} fill={colour} />
						);
					})
				}
			</Pie>
			<Tooltip label="Count" formatter={(val) => val } />

			<Legend 
				layout='vertical' 
				verticalAlign='middle' 
				align='right' 
				formatter={((value, entry) => {
					const status = entry.payload.status;
					const count = entry.payload.value;
					return `${status} - ${count}`;
				})}
			/>
		</PieChart>
		</ResponsiveContainer>
		/* <div className="kpi-numeric">
		<span className="kpi-label">Open</span>
		<span className="kpi-value">{c.open}</span>
	</div>

	<div className="kpi-numeric" style={{ color: '#35aa47' }}>
		<span className="kpi-label">Guaranteed</span>
		<span className="kpi-value">{c.guaranteed}</span>
	</div>

	<div className="kpi-numeric" style={{ color: '#d84a38', width: '170px' }}>
		<span className="kpi-label">Guaranteed &gt; 1 Month</span>
		<span className="kpi-value">{c.guaranteed1Mth}</span>
	</div>

	<div className="kpi-numeric" style={{ color: '#35aa47' }}>
		<span className="kpi-label">Closed</span>
		<span className="kpi-value">{c.closed}</span>
	</div>

	<div className="kpi-numeric">
		<span className="kpi-label">Total</span>
		<span className="kpi-value">{c.open + c.guaranteed + c.guaranteed1Mth + c.closed}</span>
	</div> */
	);
};

LotCounts.propTypes = {
	contract: PropTypes.object
};

LotCounts.defaultProps = {
	contract: {}
};

export default LotCounts;
