import React from 'react';

class DeductionsByItems extends React.Component {

  render() {
    return (
<div>
    <section className="content-header">
        <h1>
            Deductions by Items - TODO
        </h1>
    </section>

    <section className="content">

    </section>
</div>
    );
  }
}

export default DeductionsByItems;
