import React from 'react';
import PropTypes from 'prop-types';
import { 
	MenuItem, 
	ListItemIcon,
	InputLabel, 
	FormHelperText, 
	FormControl, 
	Select as MaterialUISelect,
	IconButton,
	Checkbox
} from '@material-ui/core';
import { errorColour } from '../../variables';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import isString from 'lodash/isString';
import find from 'lodash/find';
import ActionDelete from '@material-ui/icons/Clear';

const SelectField = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId('select-'));

	const selectProps = {};
	if (props.variant !== 'outlined') {
		// When outlined having disableUnderline set to even undefined throws a warning:
		// React does not recognize the `disableUnderline` prop on a DOM element
		selectProps.disableUnderline = props.disableUnderline;
	}

	let value = props.value;
	if (props.value === null) {
		value = props.multiple ? [] : '';
	}

	let error = props.error;
	let helpText = props.helpText;
	if (props.errorText) {
		error = true;
		helpText = props.errorText;
	}

	return (
		<FormControl 
			fullWidth={props.fullWidth} 
			error={error} 
			style={props.style} 
			disabled={props.disabled}
			variant={props.variant}
		>
			{props.label && 
				<InputLabel htmlFor={id} style={{ fontSize: props.fontSize }} shrink={props.displayEmpty || undefined}>
					{props.label}
					{props.required && <span style={{ color: errorColour }}> *</span>}
				</InputLabel>
			}
			<MaterialUISelect
				fullWidth={props.fullWidth} 
				label={props.label}
				required={props.required}
				inputProps={{
					name: props.name,
					id: id,
				}}
				SelectDisplayProps={{
					style: {
						paddingLeft: '8px'
					}
				}}
				style={{ fontSize: props.fontSize }} 
				onChange={(e) => props.onChange(e.target.value)}
				className="select-field__field"
				displayEmpty={props.displayEmpty}
				multiple={props.multiple}
				renderValue={props.multiple ? (selected) =>
					selected.map(selectedValue => {
							const item = find(props.items, i => i.value === selectedValue);
							return item ? item.label : '';
						}).join(', ')
					: undefined }
				value={value}
				MenuProps={{
					style: {
						zIndex: 9990
					},
					getContentAnchorEl: null,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					}
				}}
				endAdornment={props.nullable ? 
					<IconButton 
						onClick={() => props.onChange(null)} 
						disabled={!value} 
						style={{ 
							order: 1,
							position: 'absolute',
							top: '7px',
							right: '40px'
						}}
					>
						<ActionDelete color="disabled" fontSize="small" />
					</IconButton>
					: undefined
				}
				{...selectProps} 
			>
				{map(props.items, (item, index) => 
					<MenuItem 
						key={index} 
						value={isString(item) ? item : item.value}
						disabled={item.disabled}
					>
						{item.leftIcon && <ListItemIcon style={{ verticalAlign: 'middle' }}>{item.leftIcon}</ListItemIcon>}
						{props.multiple && <Checkbox checked={value.indexOf(item.value) > -1} />}
						{isString(item) ? item : item.label}
					</MenuItem>
				)}
			</MaterialUISelect>
			{helpText && <FormHelperText>{helpText}</FormHelperText>}
		</FormControl>
	);
});

SelectField.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
	onChange: PropTypes.func,
	items: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		})
	])).isRequired,
	required: PropTypes.bool,
	error: PropTypes.bool,
	style: PropTypes.object,
	helpText: PropTypes.node,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	displayEmpty: PropTypes.bool,
	variant: PropTypes.string,
	fontSize: PropTypes.string,
	disableUnderline: PropTypes.bool,
	multiple: PropTypes.bool,
	nullable: PropTypes.bool
};

SelectField.defaultProps = {
	id: '',
	name: '',
	label: '',
	value: '',
	onChange: () => null,
	required: false,
	error: false,
	style: {},
	helpText: '',
	disabled: false,
	fullWidth: false,
	displayEmpty: false,
	variant: 'filled',
	fontSize: '1rem',
	disableUnderline: false,
	multiple: false,
	nullable: false
};

export default SelectField;


// import React from 'react';
// import PropTypes from 'prop-types';
// import { Select as MuiSelectField } from '@material-ui/core';

// const SelectField = (props) => {
// 	const fieldStyles = {
// 		// floatingLabelStyle: { top: '24px' },
// 		// style: { height: '58px' },
// 		// inputStyle: { marginTop: '0px' },
// 	};

// 	const mergedProps = { ...fieldStyles, ...props };
// 	if (mergedProps.value === null) mergedProps.value = '';

// 	const className = `select-field ${mergedProps.className} ${props.nullable ? 'nullable' : ''}`;
// 	mergedProps.className = 'select-field__field';

// 	return (
// 		<div className={className} style={{ position: 'relative' }}>
// 			<MuiSelectField {...mergedProps} />
// 			{props.nullable && 
// 				<a 
// 					className="select-field__clear-button" 
// 					role="button" 
// 					onClick={(e) => props.onChange(e, null) } 
// 					style={props.clearButtonStyle}
// 				>
// 					<i className='fa fa-remove' />
// 				</a>
// 			}
// 		</div>
// 	);
// };

// SelectField.propTypes = {
// 	className: PropTypes.string,
// 	value: PropTypes.object,
// 	onChange: PropTypes.func.isRequired,
// 	clearButtonStyle: PropTypes.object,
// 	nullable: PropTypes.bool
// };

// SelectField.defaultProps = {
// 	className: '',
// 	clearButtonStyle: {},
// 	nullable: false,
// 	value: null
// };

// export default SelectField;
