import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';
import map from 'lodash/map';

class LotsHandoverContent extends React.Component {
	render() {
		const data = this.props.report;
	
		const columns = [
			{ name: 'lotNo', label: 'Lot No' },
			{ name: 'lotDescription', label: 'Lot Description', headerStyle: { minWidth: '300px' } },
			{ 
				name: 'status', 
				label: 'Lot Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					switch (args.data.status) {
						case 'Guaranteed':
							statusBackColour = '#f0ad4e';
							statusForeColour = '#222';
							break;
						case 'Guaranteed>1Month':
							statusBackColour = '#FFC7CE';
							statusForeColour = '#9C0006';
							break;
						case 'Closed':
							statusBackColour = '#C6EFCE';
							statusForeColour = '#2C6153';
							break;
						default:
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				} 
			},
			{ name: 'numItems', label: 'Items' },
			{ name: 'totalAmount', label: 'Lot Amount', formatString: 'currency' },
			{ name: 'periodClaimedName', label: 'Period Claimed' },
			{ 
				name: 'ncrs', 
				label: 'NCRs',
				value: (args) => {
					if (!args.data.ncRs || args.data.ncRs.length === 0) return '';
					return map(args.data.ncRs, (ncr) => ncr.contractorNcrNo).join(', ');
				}
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

LotsHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default LotsHandoverContent;
