import React from 'react';
import ContactDialog from './ContactDialog';
import { 
	IconButton,
	Table, 
	TableHead,
	TableBody, 
	TableRow, 
	TableCell 
} from '@material-ui/core';
import map from 'lodash/map';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const ContactField = props => {
	const [contact, setContact] = React.useState(null);
	const [isNewContact, setIsNewContact] = React.useState(true);
	const [showContactDialog, setShowContactDialog] = React.useState(false);
	const value = props.value;

	return (
	<React.Fragment>
		<ContactDialog
			show={showContactDialog} 
			onCancel={() => {
				setShowContactDialog(false);
			}}
			contact={contact}
			isNew={isNewContact}
			onOk={(newContact) => {
				const contacts = value || [];
				contacts.push(
					newContact
				);
				props.onChange(contacts);
				setShowContactDialog(false);
			}}
		/>
		
		<Table>
			<TableHead>
				<TableRow>
					<TableCell style={{ width: '110px', padding: 0 }}>
						<IconButton 
							onClick={() => { 
								setContact({
								});
								setIsNewContact(true);
								setShowContactDialog(true); 
							}}
							style={{ marginLeft: 5 }}
						>
							<AddIcon />
						</IconButton>
					</TableCell>
					<TableCell>Name</TableCell>
					<TableCell>Email</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{map(value, (u, i) =>
					<TableRow key={`contact-${i}`}>
						<TableCell style={{ padding: '0px' }}>
							<IconButton 
								onClick={() => { 
									setIsNewContact(false);
									setContact(u); 
									setShowContactDialog(true);
								}} 
								style={{ marginLeft: 5 }}
							>
								<EditIcon />
							</IconButton>
							<IconButton 
								onClick={() => { 
									const contacts = value;
									contacts.splice(i, 1);
									props.onChange(contacts);
								}} 
								style={{ marginLeft: 5 }}
							>
								<DeleteIcon />
							</IconButton>
						</TableCell>
						<TableCell>{u.name}</TableCell>
						<TableCell>{u.email}</TableCell>
					</TableRow>
				)}
				{value.length === 0 && 
					<TableRow>
						<TableCell colSpan={3}>No contacts</TableCell>
					</TableRow>
				}
			</TableBody>
		</Table>
	</React.Fragment>
	);
};

export default ContactField;
