import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentContainer from '../contentContainer';
import Button from '../widgets/button';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { 
	requestForecastPeriods, 
	clearForecastPeriods,
	newForecastPeriod
} from '../../reducers/forecasts';
import { formatDate } from '../../utils/utils';
import { canEdit } from '../../selectors/canEdit';
import ForecastPeriodDialog from './forecastPeriodDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

class ForecastPeriods extends Component {
	state = {
		showForecastPeriodDialog: false
	};

	static fetchData(dispatch) {
		dispatch(requestForecastPeriods());
	}
	static clearData(dispatch) {
		dispatch(clearForecastPeriods());
	}

	componentDidMount() {
		ForecastPeriods.fetchData(this.props.dispatch);
	}

	newForecastPeriod = () => {
		this.setState({ 
			showForecastPeriodDialog: true 
		});
	}
	onForecastPeriodDialogOk = (periodName) => {
		this.props.newForecastPeriod(periodName);
		this.setState({ 
			showForecastPeriodDialog: false 
		});
	}
	onForecastPeriodDialogClose = () => {
		this.setState({ 
			showForecastPeriodDialog: false 
		});
	}
	showForecastPeriodDialog = () => {
		this.setState({ 
			showForecastPeriodDialog: false 
		});
	}

	render = () => 
	<div>
		<section className="content-header">
			<h1>
				Forecast Periods <CircularProgress style={{ display: this.props.isLoading ? undefined : 'none' }} />
			</h1>
		</section>
		<section className="content">
			<ForecastPeriodDialog show={this.state.showForecastPeriodDialog} onClose={this.onForecastPeriodDialogClose} onOk={this.onForecastPeriodDialogOk} />
			{this.props.canEdit && 
				<Button label="Add Forecast Period" onClick={() => this.newForecastPeriod() } />
			}

			<ContentContainer>
				<ul className="timeline">
					{
						map(orderBy(this.props.forecastPeriods, ['order'], ['desc']), (p) => {
							let status;
							let statusColour;
							if (!p.periodId) {
								status = 'Forecast';
								statusColour = '#5bc0de';
							} else if (!p.endDate) {
								status = 'Current';
								statusColour = '#35aa47';
							} else {
								status = 'Historic';
								statusColour = '#f0ad4e';
							}

							return (
								<li className="event" data-date={p.periodName}>
									<h3 style={{ color: statusColour }}>{status}</h3>
									{status !== 'Forecast' &&
										<p>
											Start Date {p.startDate ? formatDate(p.startDate, 'dd/MM/yyyy') : null}
										</p>
									}
									{status === 'Historic' &&
										<p>
											End Date {p.endDate ? formatDate(p.endDate, 'dd/MM/yyyy') : null}
										</p>
									}
								</li>
							);
						})
					}
				</ul>
			</ContentContainer>
		</section>   
	</div>
}

ForecastPeriods.propTypes = {
	dispatch: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	forecastPeriods: PropTypes.array.isRequired,
	newForecastPeriod: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
	canEdit: canEdit(state, 'EditForecasts'),
	forecastPeriods: state.forecasts.forecastPeriods,
	isLoading: state.forecasts.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	newForecastPeriod: (periodName) => {
		dispatch(newForecastPeriod(periodName));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ForecastPeriods);
