import React from 'react';
import ContractorDialog from './ContractorDialog';
import { 
	IconButton,
	Table, 
	TableHead,
	TableBody, 
	TableRow, 
	TableCell 
} from '@material-ui/core';
import map from 'lodash/map';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const ContractorField = props => {
	const [contractor, setContractor] = React.useState(null);
	const [isNewContractor, setIsNewContractor] = React.useState(true);
	const [showContractorDialog, setShowContractorDialog] = React.useState(false);

	return (
	<React.Fragment>
		<ContractorDialog
			show={showContractorDialog} 
			onCancel={() => {
				setShowContractorDialog(false);
			}}
			contractor={contractor}
			isNew={isNewContractor}
			onOk={(newContractor) => {
				const contractors = props.value || [];
				contractors.push(
					newContractor
				);
				props.onChange(contractors);
				setShowContractorDialog(false);
			}}
		/>
		
		<Table>
			<TableHead>
				<TableRow>
					<TableCell style={{ width: '110px', padding: 0 }}>
						<IconButton 
							onClick={() => { 
								setContractor({
								});
								setIsNewContractor(true);
								setShowContractorDialog(true); 
							}}
							style={{ marginLeft: 5 }}
						>
							<AddIcon />
						</IconButton>
					</TableCell>
					<TableCell>Name</TableCell>
					<TableCell>Trading Name</TableCell>
					<TableCell>ABN</TableCell>
					<TableCell>Address</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{map(props.value, (u, i) =>
					<TableRow key={`contractor-${i}`}>
						<TableCell style={{ padding: '0px' }}>
							<IconButton 
								onClick={() => { 
									setIsNewContractor(false);
									setContractor(u); 
									setShowContractorDialog(true);
								}} 
								style={{ marginLeft: 5 }}
							>
								<EditIcon />
							</IconButton>
							<IconButton 
								onClick={() => { 
									const contractors = props.value;
									contractors.splice(i, 1);
									props.onChange(contractors);
								}} 
								style={{ marginLeft: 5 }}
							>
								<DeleteIcon />
							</IconButton>
						</TableCell>
						<TableCell>{u.name}</TableCell>
						<TableCell>{u.tradingName}</TableCell>
						<TableCell>{u.abn}</TableCell>
						<TableCell>{u.address}</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	</React.Fragment>
	);
};

export default ContractorField;
