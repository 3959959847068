import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../../utils/connectedRouterHOC';
import { 
	closePeriod, 
	requestClosePeriodData, 
	clearClosePeriodData 
} from '../../../reducers/closePeriod';
import ContentContainer from '../../contentContainer';
import MessagePanel from '../../messagePanel';
import Wizard from '../../wizard/wizard';
import { getFieldErrorText } from '../../../utils/field-validation';
import FormContainer from '../../formContainer';
import FormFieldContainer from '../../formFieldContainer';
import TextField from '../../widgets/TextField';
import Checkbox from '../../widgets/Checkbox';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';
import filter from 'lodash/filter';
import { toCurrency, addOrUpdate, formatDate } from '../../../utils/utils';
import PageLayout from '../../pageLayout';
import addDays from 'date-fns/addDays';
import DateField from '../../widgets/DateField';

class CloseWizard extends Component {
	constructor(props, context) {
		super(props, context);

		const todayWithoutTime = new Date();
		todayWithoutTime.setHours(0, 0, 0, 0); // strip time

		this.state = {
			periodEndDate: todayWithoutTime,
			nextPeriodName: formatDate(new Date(), 'MMMM yy'),
			nextPeriodStartDate: addDays(todayWithoutTime, 1),
			forecastPeriodsToMerge: []
		};

		this.onClose = this.onClose.bind(this);
		this.updateField = this.updateField.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestClosePeriodData(props.match.params.periodId));
	}
	static clearData(dispatch) {
		dispatch(clearClosePeriodData());
	}
	componentDidMount() {
		CloseWizard.fetchData(this.props.dispatch, this.props);
	}

	onCancel = () => {
		this.props.history.push('/periods');
	}
	onClose() {
		const closeArgs = {
			periodEndDate: this.state.periodEndDate,
			nextPeriodName: this.state.nextPeriodName,
			nextPeriodStartDate: this.state.nextPeriodStartDate,
			forecastPeriodsToMerge: this.state.forecastPeriodsToMerge,
			contractId: this.props.closeData.contractId,
			periodId: this.props.closeData.periodId	
		};
		this.props.closePeriod(closeArgs);
	}
	updateField(field, newValue) {
        this.setState({
			[field]: newValue
        });
	}

	render() {
		const guaranteedLots = this.props.closeData ? this.props.closeData.guaranteedLots : [];
		const unguaranteedLots = this.props.closeData ? this.props.closeData.unguaranteedLots : [];
		const forecastPeriods = this.props.closeData ? this.props.closeData.forecastPeriods : [];
		let warning = '';
		if (this.state.nextPeriodStartDate < this.state.periodEndDate) {
			warning = 'Next period start date is before the current period end date';
		} else {
			const periodsWithinStart = filter(this.props.periods, p => p.periodId !== this.props.match.params.periodId && p.startDate < this.state.periodEndDate && p.endDate > this.state.periodEndDate);
			if (periodsWithinStart.length === 1) {
				warning = `The current period end date is within Period ${periodsWithinStart[0].name}`;
			} else if (periodsWithinStart.length > 1) {
				warning = `The current period end date is within Periods ${map(periodsWithinStart, p => p.name).join(', ')}`;
			} else {
				const periodsWithinEnd = filter(this.props.periods, p => p.startDate < this.state.nextPeriodStartDate && p.endDate > this.state.nextPeriodStartDate);
				if (periodsWithinEnd.length === 1) {
					warning = `The next period start date is within Period ${periodsWithinEnd[0].name}`;
				} else if (periodsWithinEnd.length > 1) {
					warning = `The next period start date is within Periods ${map(periodsWithinEnd, p => p.name).join(', ')}`;
				}
			}
		}

		return (
<PageLayout
	title={`Close Period ${this.props.closeData ? ` - ${this.props.closeData.name}` : ''}`}
	isLoading={this.props.isLoading}
	content={
	<div>
			<Wizard 
				loading={this.props.isLoading}
				success={this.props.closeResult.success}
				submitLabel="Close"
				onComplete={() => {
					this.onClose();
				}}
				steps={[
					{ 
						title: 'Periods', 
						content: 
							<ContentContainer>
							<FormContainer>
								<FormFieldContainer classes={['s12', 'm6']}>
									<DateField
										value={this.state.periodEndDate} 
										label="End Date of Current Period" 
										onChange={(d) => { this.updateField('periodEndDate', d); }}
										error={getFieldErrorText(this.props.closeResult, 'PeriodEndDate')}
										fullWidth={true}
										required={true}
									/>
								</FormFieldContainer>

								<FormFieldContainer classes={['s12', 'm6']}>
									<DateField
										value={this.state.nextPeriodStartDate} 
										label="Start Date of Next Period" 
										onChange={(d) => { this.updateField('nextPeriodStartDate', d); }}
										error={getFieldErrorText(this.props.closeResult, 'NextPeriodStartDate')}
										fullWidth={true}
										required={true}
									/>
								</FormFieldContainer>

								<FormFieldContainer>
								<span style={{ color: '#f0ad4e' }}>{warning}</span>
								</FormFieldContainer>
								<FormFieldContainer>
									<TextField
										value={this.state.nextPeriodName}
										fullWidth={true}
										onChange={(e, v) => { this.updateField('nextPeriodName', v); }}
										label="Next Period Name"
										errorText={getFieldErrorText(this.props.closeResult, 'NextPeriodName')}
										required={true}
									/>
								</FormFieldContainer>
							</FormContainer>
							</ContentContainer>,
						validate: () => !!this.state.nextPeriodName && !!this.state.periodEndDate && !!this.state.nextPeriodStartDate
					},
					{ 
						title: 'Lots - Guaranteed', 
						content: 
							<ContentContainer>
							<div>
								<p>
									These lots will be set to Guaranteed &gt; 1 Month. They will be deducted from the next Progress Claim unless they are closed.
								</p>
								<div className="table-container">
									<table className="full-bordered" style={{ width: '100%' }}>
										<thead>
											<tr>
												<th>Lot No</th>
												<th style={{ minWidth: '300px' }}>Lot Description</th>
												<th>Items</th>
												<th>Amount</th>
												<th style={{ minWidth: '300px' }}>Comments</th>
											</tr>
										</thead>
										<tbody>
											{
												map(guaranteedLots, (l) =>
													<tr key={l.lotId}>
														<td>{l.lotNo}</td>
														<td>{l.lotDescription}</td>
														<td>{l.numItems}</td>
														<td>{toCurrency(l.totalAmount)}</td>
														<td>{l.comments}</td>
													</tr>
												)
											}
										</tbody>
									</table>
								</div>
							</div>
							</ContentContainer>
					},
					{ 
						title: 'Lots - Guaranteed > 1 Month', 
						content: 
							<ContentContainer>
							<div>
								<p>
									These lots were not closed in this period were deducted from the Progress Claim. The amount will be repaid when they are closed.
								</p>
								<div className="table-container">
									<table className="full-bordered" style={{ width: '100%' }}>
										<thead>
											<tr>
												<th>Lot No</th>
												<th style={{ minWidth: '300px' }}>Lot Description</th>
												<th>Items</th>
												<th>Amount</th>
												<th style={{ minWidth: '300px' }}>Comments</th>
											</tr>
										</thead>
										<tbody>
											{
												map(unguaranteedLots, (l) => 
													<tr key={l.lotId}>
														<td>{l.lotNo}</td>
														<td>{l.lotDescription}</td>
														<td>{l.numItems}</td>
														<td>{toCurrency(l.totalAmount)}</td>
														<td>{l.comments}</td>
													</tr>
												)
											}
										</tbody>
									</table>
								</div>
							</div>
							</ContentContainer>
					},
					{ 
						title: 'Forecast Periods', 
						content: 
							<ContentContainer>
							<div>
								<p>
									Select forecast periods below that match the new period being opened ({this.state.nextPeriodName}).
								</p>
								<p>
									The selected forecast periods are now current and therefore will be merged into the new period.
								</p>
								<div className="table-container">
									<table className="full-bordered" style={{ width: '100%' }}>
										<thead>
											<tr>
												<th>Merge</th>
												<th>Period</th>
											</tr>
										</thead>
										<tbody>
											{
												map(orderBy(forecastPeriods, ['order'], ['desc']), (p) => (
													<tr key={p.forecastPeriodId}>
														<td>
															<Checkbox
																checked={some(this.state.forecastPeriodsToMerge, p2 => p2.forecastPeriodId === p.forecastPeriodId)}
																onCheck={(e, v) => { 
																	let forecastPeriodsToMerge;
																	if (v) {
																		forecastPeriodsToMerge = addOrUpdate(this.state.forecastPeriodsToMerge, p, p2 => p2.forecastPeriodId === p.forecastPeriodId);
																	} else {
																		forecastPeriodsToMerge = filter(this.state.forecastPeriodsToMerge, p2 => p2.forecastPeriodId !== p.forecastPeriodId);
																	}
																	this.setState({
																		forecastPeriodsToMerge: forecastPeriodsToMerge
																	});
																}}
															/> 
														</td>
														<td>{p.periodName}</td>
													</tr>
												))
											}
										</tbody>
									</table>
								</div>
							</div>
							</ContentContainer>
					},
					{ 
						title: 'Close Period', 
						content: 
							<ContentContainer>
							<div>
								<p>
									You are ready to close the period. Click Close to continue.
								</p>
							</div>
							</ContentContainer>
					}
				]} 
			/>

		<MessagePanel isSuccess={this.props.closeResult.success} message={this.props.closeResult.message} />
	</div>
	}
/>
		);
	}
}

CloseWizard.propTypes = {
	params: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	closePeriod: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	closeResult: PropTypes.object.isRequired,
	closeData: PropTypes.object.isRequired,
	periods: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
	isLoading: state.closePeriod.isLoading,
	closeResult: state.closePeriod.closeResult,
	closeData: state.closePeriod.closePeriodData,
	periods: state.context.contract.periods
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	closePeriod: (closeDataArgs) => {
		dispatch(closePeriod(closeDataArgs));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(CloseWizard);
