import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import PageLayout from '../pageLayout';
import Account from './Account';
import { get } from '../../utils/ajax';
import { useMountEffect } from '../../utils/utils';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';

const AccountController = ({ user, showErrorNotification }) => {
	const [loading, setLoading] = React.useState(false);
	const [account, setAccount] = React.useState(null);

	useMountEffect(() => {
		setLoading(true);
		get({
			url: `/api/account`,
			onSuccess: data => {
				setLoading(false);
				setAccount(data);
			},
			onError: error => {
				setLoading(false);
				showErrorNotification(error.message);
			}
		});
	});

	return (
		<PageLayout
			className='account'
			title="Account"
			isLoading={loading}
			content={
				<Account 
					user={user}
					account={account}
					loading={loading}
				/>
			}
		/>
	);
};


AccountController.propTypes = {
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	user: state.context.user
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(AccountController);
