import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import SelectField from '../widgets/selectField';
import FormFieldContainer from '../formFieldContainer';
import DateField from '../widgets/DateField';
import ContentContainer from '../contentContainer';
import SubContentContainer from '../subContentContainer';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import FileUpload from '@material-ui/icons/Publish';
import FileDownload from '@material-ui/icons/GetApp';
import map from 'lodash/map';
import filter from 'lodash/filter';
import Button from '../widgets/button';
import Checkbox from '../widgets/Checkbox';
import FormSectionHeader from '../widgets/formSectionHeader';
import PersistedDataTable from '../widgets/persistedDataTable';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CorrespondencePicker from '../widgets/correspondencePicker';
import EmployeeSelect from '../widgets/employeeSelect';
import Tabs from '../widgets/Tabs';
import Tab from '../widgets/Tab';
import TagField from '../widgets/TagField';

class DesignCorrespondenceForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			correspondence: props.correspondence,
			showCorrespondencePicker: false
		};

		this.selectFiles = this.selectFiles.bind(this);
		this.selectResponseFiles = this.selectResponseFiles.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			correspondence: nextProps.correspondence
		});
	}

	selectFiles(event) {
		this.props.addFiles('DesignCorrespondence', event.target.files);
	}
	selectResponseFiles(event) {
		this.props.addFiles('DesignCorrespondence.Response', event.target.files);
	}

	addLinkedCorrespondence = () => {
		this.setState({ showCorrespondencePicker: true });
	}

	onSelectCorrespondence = (selectedCorrespondence) => {
		this.props.onSelectCorrespondence(selectedCorrespondence);
		this.setState({
			showCorrespondencePicker: false
		});
	}
	onCancelCorrespondencePicker = () => {
		this.setState({ showCorrespondencePicker: false });
	}

	render() {
		const correspondence = this.state.correspondence;
		const linkedCorrespondence = correspondence.linkedCorrespondence || [];
		const files = filter(correspondence.files, (f) => f.referenceType === 'DesignCorrespondence');
		const responseFiles = filter(correspondence.files, (f) => f.referenceType === 'DesignCorrespondence.Response');

		const styles = {
			button: {
				marginTop: 12
			},
			fileInput: {
				cursor: 'pointer',
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				width: '100%',
				opacity: 0
			}
		};

		const linkedCorrespondenceColumns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.viewLinkedCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<VisibilityIcon />
						</IconButton>
						{this.props.canEdit && 
							<IconButton onClick={ () => { this.props.deleteLinkedCorrespondence(args.data); } }>
								<ActionDelete />
							</IconButton>
						}
					</div>				
			},
			{ 
				name: 'correspondenceType', 
				label: 'Type'
			},
			{ name: 'correspondenceNumber', label: 'Correspondence Number' },
			{ name: 'correspondenceDate', label: 'Date', dataType: 'date' }
		];

		return (
<div>
	<CorrespondencePicker 
		show={this.state.showCorrespondencePicker} 
		types={['Outgoing', 'Incoming']}
		selectedCorrespondence={{
			outgoingCorrespondence: map(filter(correspondence.linkedCorrespondence, (c) => !c.isParent && c.correspondenceType === 'Outgoing'), (c) => ({ outgoingCorrespondenceId: c.correspondenceId })),
			incomingCorrespondence: map(filter(correspondence.linkedCorrespondence, (c) => !c.isParent && c.correspondenceType === 'Incoming'), (c) => ({ incomingCorrespondenceId: c.correspondenceId }))
		}}
		onOk={this.onSelectCorrespondence} 
		cancel={this.onCancelCorrespondencePicker} 
	/>

	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={correspondence.drrNumber}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('drrNumber', v); }}
				label="DRR Number"
				errorText={getFieldErrorText(this.props.saveResult, 'DrrNumber')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={correspondence.projectNumber}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('projectNumber', v); }}
				label="Project Number"
				errorText={getFieldErrorText(this.props.saveResult, 'ProjectNumber')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m6: true }}>
			<DateField
				value={correspondence.dateAdded} 
				label="Date Added" 
				onChange={(d) => { this.props.updateField('dateAdded', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>
		
		<FormFieldContainer>
			<TagField
				label="Tags" 
				fullWidth={true}
				value={correspondence.tags}
				onChange={tags => this.props.updateField('tags', tags)}
			/>
		</FormFieldContainer>
		
		<FormSectionHeader text="Question" />

		<FormFieldContainer>
			<TextField
				value={correspondence.description}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('description', v); }}
				label="Description"
				errorText={getFieldErrorText(this.props.saveResult, 'Description')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField
				value={correspondence.dateSent} 
				label="Date Sent" 
				onChange={(d) => { this.props.updateField('dateSent', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField
				value={correspondence.responseRequiredBy} 
				label="Response Required By" 
				onChange={(d) => { this.props.updateField('responseRequiredBy', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<EmployeeSelect 
				employees={this.props.employees}
				value={correspondence.raisedBy} 
				label="Issued By" 
				onChange={(v) => { this.props.updateField('raisedBy', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'RaisedBy')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<TextField
				value={correspondence.addressTo}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('addressTo', v); }}
				label="Issued To"
				errorText={getFieldErrorText(this.props.saveResult, 'Address To')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<SelectField 
				value={correspondence.deliveryMethod} 
				fullWidth={true}
				label="Delivery Method" 
				onChange={(v) => { this.props.updateField('deliveryMethod', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'DeliveryMethod')}
				items={[
					{ value: 'Email', label: 'Email' },
					{ value: 'Mail', label: 'Mail' },
					{ value: 'Verbal', label: 'Verbal' }
				]}
			>
			</SelectField>
		</FormFieldContainer>

		<FormSectionHeader text="Response" />

		<FormFieldContainer>
			<Checkbox
				label="Designer Response"
				labelPosition="right"
				checked={correspondence.designerResponse}
				onCheck={(e, v) => { this.props.updateField('designerResponse', v); }}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<TextField
				value={correspondence.response}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('response', v); }}
				label="Response"
				errorText={getFieldErrorText(this.props.saveResult, 'Response')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={['s12', 'm6']}>
			<DateField
				value={correspondence.finalResponseDate} 
				label="Final Response Date" 
				onChange={(d) => { this.props.updateField('finalResponseDate', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>
		
		<FormSectionHeader text="Status" />

		<FormFieldContainer classes={['s12', 'm6']}>
			<SelectField 
				value={correspondence.status} 
				fullWidth={true}
				label="Status" 
				onChange={(v) => { this.props.updateField('status', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'Status')}
				items={[
					{ value: 'Open', label: 'Open' },
					{ value: 'Closed', label: 'Closed' },
					{ value: 'Awaiting Response', label: 'Awaiting Response' }
				]}
			>
			</SelectField>
		</FormFieldContainer>
		
		<FormFieldContainer classes={['s12', 'm6']}>
			<EmployeeSelect 
				employees={this.props.employees}
				value={correspondence.actionedBy} 
				label="Actioned By" 
				onChange={(v) => { this.props.updateField('actionedBy', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'ActionedBy')}
			/>
		</FormFieldContainer>
	
		<FormFieldContainer>
			<TextField
				value={correspondence.comments}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('comments', v); }}
				label="Comments"
				errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
			/>
		</FormFieldContainer>
	</ContentContainer>

	<SubContentContainer header="Linked Correspondence" style={{ marginTop: '10px' }}>
		<Tabs renderOnLoad>
			<Tab label="Linked To" value="LinkedTo">
				<PersistedDataTable 
					id="linkedCorrespondence"
					data={filter(linkedCorrespondence || [], c => !c.isParent)} 
					columns={linkedCorrespondenceColumns}
				/>
			</Tab>
			<Tab label="Linked By" value="LinkedBy">
				<PersistedDataTable 
					id="linkedByCorrespondence"
					data={filter(linkedCorrespondence || [], c => c.isParent)} 
					columns={[
						{
							name: 'commands',
							excludeFromSearch: true,
							excludeFromFilter: true,
							cellRenderer: (args) => 
								<div style={{ whiteSpace: 'nowrap' }}>
									<IconButton onClick={ () => { this.props.viewLinkedCorrespondenceBy(args.data); } } style={{ padding: '2px' }}>
										<VisibilityIcon />
									</IconButton>
								</div>				
						},
						{ 
							name: 'targetType', 
							label: 'Type'
						},
						{ name: 'targetNumber', label: 'Number' }
					]}
				/>
			</Tab>
		</Tabs>

		<div className="row">
			<Button
				label="Add"
				style={styles.button}
				onClick={() => this.addLinkedCorrespondence()}
			>
			</Button>
			<Button
				style={{
					...styles.button,
					marginLeft: '10px'
				}}
				label="Explore"
				onClick={() => this.props.exploreLinkedCorrespondence()}
			>
			</Button>
		</div>
	</SubContentContainer>

	<SubContentContainer 
		header="Files"
		subHeader="DRR and supporting documentation"
		onDragOver={(e) => {
			e.dataTransfer.dropEffect = 'copy';
			e.stopPropagation();
			e.preventDefault();
		}}
		onDrop={(e) => {
			e.stopPropagation();
			e.preventDefault();
			// Allow files to be added by dropping anywhere in container
			this.props.addFiles('DesignCorrespondence', e.dataTransfer.files);
		}}
		style={{ marginTop: '10px' }}
	>
		<div className="table-container">
			<table className="full-bordered" style={{ width: '100%' }}>
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
					</tr>
				</thead>
				<tbody>
					{
						map(files, (f) =>
							<tr key={f.userFileId}>
								<td style={{ whiteSpace: 'nowrap' }}>
									{!f._isNew && 
										<IconButton onClick={ () => { this.props.downloadFile(f); } } style={{ padding: '2px' }}>
											<FileDownload />
										</IconButton>
									}
									{this.props.canEdit &&
										<IconButton onClick={ () => { this.props.deleteFile(f); } } style={{ padding: '2px' }}>
											<ActionDelete />
										</IconButton>
									}
								</td>	
								<td>{f.name}</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</div>

		{this.props.canEdit &&
			<div className="row">
				<Button
					label="Select a file to upload"
					style={styles.button}
					component="label"
					startIcon={<FileUpload />}
				>
					<input type="file" style={styles.fileInput} multiple="multiple" onChange={this.selectFiles} />
				</Button>
			</div>
		}

	</SubContentContainer>

	<SubContentContainer
		header="Response Files"
		subHeader="DRR response from the designer and supporting documentation"
		style={{ marginTop: '10px' }}
		onDragOver={(e) => {
			e.dataTransfer.dropEffect = 'copy';
			e.stopPropagation();
			e.preventDefault();
		}}
		onDrop={(e) => {
			e.stopPropagation();
			e.preventDefault();
			// Allow files to be added by dropping anywhere in container
			this.props.addFiles('DesignCorrespondence.Response', e.dataTransfer.files);
		}}
	>
		<div className="table-container">
			<table className="full-bordered" style={{ width: '100%' }}>
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
					</tr>
				</thead>
				<tbody>
					{
						map(responseFiles, (f) =>
							<tr key={f.userFileId}>
								<td style={{ whiteSpace: 'nowrap' }}>
									{!f._isNew && 
										<IconButton onClick={ () => { this.props.downloadFile(f); } } style={{ padding: '2px' }}>
											<FileDownload />
										</IconButton>
									}
									{this.props.canEdit &&
										<IconButton onClick={ () => { this.props.deleteFile(f); } } style={{ padding: '2px' }}>
											<ActionDelete />
										</IconButton>
									}
								</td>	
								<td>{f.name}</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</div>

		{this.props.canEdit &&
			<div className="row">
				<Button
					label="Select a file to upload"
					style={styles.button}
					component="label"
					startIcon={<FileUpload />}
				>
					<input type="file" style={styles.fileInput} multiple="multiple" onChange={this.selectResponseFiles} />
				</Button>
			</div>
		}

	</SubContentContainer>
</div>
		);
	}
}

DesignCorrespondenceForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	downloadFile: PropTypes.func.isRequired,
	deleteFile: PropTypes.func.isRequired,
	addFiles: PropTypes.func.isRequired,
	correspondence: PropTypes.object.isRequired,
	employees: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	onSelectCorrespondence: PropTypes.func.isRequired,
	deleteLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondenceBy: PropTypes.func.isRequired,
	exploreLinkedCorrespondence: PropTypes.func.isRequired
};

export default DesignCorrespondenceForm;
