import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';

class ContractorForm extends React.Component {
	render() {
		const contractor = this.props.contractor;

		return (
<div>
	<FormFieldContainer>
		<TextField
			value={contractor.name}
			fullWidth={true}
			onChange={(e, v) => { this.props.updateField('name', v); }}
			label="Name"
			errorText={getFieldErrorText(this.props.saveResult, 'Name')}
		/>
	</FormFieldContainer>

	<FormFieldContainer>
		<TextField
			value={contractor.tradingName}
			fullWidth={true}
			onChange={(e, v) => { this.props.updateField('tradingName', v); }}
			label="Trading Name"
			errorText={getFieldErrorText(this.props.saveResult, 'TradingName')}
		/>
	</FormFieldContainer>

	<FormFieldContainer>
		<TextField
			value={contractor.abn}
			fullWidth={true}
			onChange={(e, v) => { this.props.updateField('abn', v); }}
			label="ABN"
			errorText={getFieldErrorText(this.props.saveResult, 'ABN')}
		/>
	</FormFieldContainer>

	<FormFieldContainer>
		<TextField
			value={contractor.address}
			fullWidth={true}
			rows={2}
			multiline={true}
			onChange={(e, v) => { this.props.updateField('address', v); }}
			label="Address"
			errorText={getFieldErrorText(this.props.saveResult, 'Address')}
		/>
	</FormFieldContainer>
</div>
		);
	}
}

ContractorForm.propTypes = {
	contractor: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default ContractorForm;
