import React from 'react';
import ScheduleDialog from './ScheduleDialog';
import map from 'lodash/map';
import AddIcon from '@material-ui/icons/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ScheduleOfRatesCard from './ScheduleOfRatesCard';
import { toCurrency, shadeColour, replaceObject } from '../../../utils/utils';
import Paper from '@material-ui/core/Paper';
import forEach from 'lodash/forEach';
import round from 'lodash/round';
import cloneDeep from 'lodash/cloneDeep';
import uniqueId from 'lodash/uniqueId';
import remove from 'lodash/remove';
import maxBy from 'lodash/maxBy';
import { globals } from '../../../globals';
import FloatingActionButton from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		background: '#009efb',
		color: '#fff',
		'&:hover': {
			backgroundColor: shadeColour('#009efb', -0.2),
		}
	}
});

const SchedulesField = props => {
	const classes = useStyles();
	const [showScheduleDialog, setShowScheduleDialog] = React.useState(false);
	const [editingSchedule, setEditingSchedule] = React.useState({
		name: '',
		items: []
	});
	const scheduleOfRates = cloneDeep(props.value);


	let total = 0;
	forEach(scheduleOfRates, (s) => {
		let scheduleTotal = 0;
		forEach(s.items, (i) => {
			scheduleTotal += i.rate * i.qty;
		});

		s._total = round(scheduleTotal, 2);
		total += scheduleTotal;
	});
	total = round(total, 2);

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		if (result.source.index === result.destination.index) return;

		let schedules = cloneDeep(props.value || []);
		let movedSchedule = schedules.splice(result.source.index, 1)[0];
		schedules.splice(result.destination.index, 0, movedSchedule);
		for (let i = 0, ii = schedules.length; i < ii; i++) {
			schedules[i].order = i + 1;
		}

		props.onChange(schedules);
	};

	return (
	<React.Fragment>
		<ScheduleDialog
			show={showScheduleDialog} 
			onCancel={() => {
				setShowScheduleDialog(false);
			}}
			schedule={editingSchedule}
			onOk={(updatedSchedule) => {
				setShowScheduleDialog(false);

				let schedules = cloneDeep(props.value);
				if (updatedSchedule._clientId) {
					schedules = replaceObject(schedules, updatedSchedule, s => s._clientId === updatedSchedule._clientId);
				} else {
					updatedSchedule._clientId = uniqueId("schedule-");
					const lastSchedule = maxBy(schedules, 'order');
					updatedSchedule.order = lastSchedule ? lastSchedule.order + 1 : 1;
					schedules.push(
						updatedSchedule
					);
				}

				props.onChange(schedules);
			}}
			onRemove={(removedSchedule) => {
				setShowScheduleDialog(false);

				let schedules = cloneDeep(props.value || []);
				remove(schedules, s => s._clientId === removedSchedule._clientId);
				
				props.onChange(schedules);
			}}
		/>

		<p style={{ fontStyle: 'italic', textAlign: 'center' }}>You must have at least one Schedule of Rates</p>


		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
				>
					{
						map(scheduleOfRates, (s, index) =>
							<Draggable key={s._clientId} draggableId={`${s._clientId}`} index={index}>
							{(dragProvided) => (
								<div
									ref={dragProvided.innerRef}
									{...dragProvided.draggableProps}
								>
									<ScheduleOfRatesCard
										schedule={s} 
										dragHandleProps={{ ...dragProvided.dragHandleProps }}
										onEditSchedule={() => {
											setEditingSchedule(s);
											setShowScheduleDialog(true);
										}}
									/>
								</div>
							)}
							</Draggable>
						)
					}
					{provided.placeholder}
				</div>
				)}
			</Droppable>
		</DragDropContext>

		<div>
			<Paper style={{ 
					float: 'right', 
					padding: '20px', 
					margin: '20px 0', 
					fontSize: '16px', 
					fontWeight: 'bold' 
				}}
			>
				<div>
					<span>Contract Total: </span>
					<span>{toCurrency(total)}</span>
				</div>
			</Paper>

			<div className="buttons" style={{
				float: 'left',
				margin: '20px 0'
			}}>
				<FloatingActionButton 
					className={classes.root}
					onClick={() => {
						const newSchedule = cloneDeep(globals.templates.scheduleOfRates);
						setEditingSchedule(newSchedule);
						setShowScheduleDialog(true);
					}}					
					size="small" 
					style={{ 
						marginTop: '10px',
						marginLeft: '10px'
					}}  
				>
					<AddIcon />
				</FloatingActionButton>
			</div>
		</div>
	</React.Fragment>
	);
};

export default SchedulesField;
