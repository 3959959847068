import find from 'lodash/find';
import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { globals } from '../globals';
import forEach from 'lodash/forEach';
import map from 'lodash/map';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	register: null, 
	registers: null
};

const CLEAR_REGISTERS = 'CLEAR_REGISTERS';
const CLEAR_REGISTER = 'CLEAR_REGISTER';
const FETCH_REGISTER = 'FETCH_REGISTER';
const DELETE_REGISTER = 'DELETE_REGISTER';
const REQUEST_REGISTERS = 'REQUEST_REGISTERS';
const RECEIVE_REGISTERS = 'RECEIVE_REGISTERS';
const REQUEST_REGISTER = 'REQUEST_REGISTER';
const RECEIVE_REGISTER = 'RECEIVE_REGISTER';
const CREATE_NEW_REGISTER = 'CREATE_NEW_REGISTER';
const SAVE_REGISTER = 'SAVE_REGISTER';
const RECEIVE_SAVE_REGISTER_RESPONSE = 'RECEIVE_SAVE_REGISTER_RESPONSE';
const RECEIVE_DELETE_REGISTER_RESPONSE = 'RECEIVE_DELETE_REGISTER_RESPONSE';
const SHOW_REGISTER_ERRORS = 'SHOW_REGISTER_ERRORS';

export const clearRegisters = () => ({ type: CLEAR_REGISTERS });
export const clearRegister = () => ({ type: CLEAR_REGISTER });
export const fetchRegister = (registerId) => ({ type: FETCH_REGISTER, registerId });
export const receiveRegisters = (data) => ({ type: RECEIVE_REGISTERS, payload: { data } });
export const receiveRegister = (data) => ({ type: RECEIVE_REGISTER, payload: { data } });
export const createNewRegister = (definition) => ({ type: CREATE_NEW_REGISTER, definition: definition });
export const receiveSaveRegisterResponse = (data) => ({ type: RECEIVE_SAVE_REGISTER_RESPONSE, data });
export const receiveDeleteRegisterResponse = (data) => ({ type: RECEIVE_DELETE_REGISTER_RESPONSE, data });

export const requestRegisters = (registerDefinitionId) => (
	createFetchAction({
		objectName: 'Registers',
		passContext: true,
		url: `/api/registers/${registerDefinitionId}/registers`,
		startAction: REQUEST_REGISTERS,
		success: (data) => {
			return receiveRegisters(map(data, (r) => ({
				...r,
				dateCreated: r.dateCreated ? new Date(r.dateCreated) : null,
				values: JSON.parse(r.values)
			})));
		}
	})
);

export const requestRegister = (registerId) => (
	createFetchAction({
		objectName: 'Register',
		passContext: true,
		url: `/api/registers/${registerId}/register`,
		startAction: REQUEST_REGISTER,
		success: (data) =>
			receiveRegister({
				...data,
				dateCreated: data.dateCreated ? new Date(data.dateCreated) : null,
				values: JSON.parse(data.values)
			})
	})
);

const validateRegister = () => {
	const result = {
		success: false,
		message: '',
		fields: []
	};

	result.success = !result.message && result.fields.length === 0;
	if (!result.success && !result.message) result.message = 'Please correct the errors';
	return result;
};

export const saveRegister = (register, newFiles, onSuccess) => {
	const result = validateRegister(register);
	if (!result.success) return { type: SHOW_REGISTER_ERRORS, data: result };

	const formData = new FormData();
	formData.append('Register', encodeURIComponent(JSON.stringify({
		...register,
		values: JSON.stringify(register.values)
	})));
	forEach(newFiles, (f) => formData.append('NewFiles', f.file));

	return createFormPostAction({
		passContext: true,
		url: '/api/registers',
		formData,
		startAction: SAVE_REGISTER,
		success: (data, dispatch) => {
			const r = data.object ? {
				...data.object,
				dateCreated: data.object.dateCreated ? new Date(data.object.dateCreated) : null,
				values: JSON.parse(data.object.values)
			} : data.object;
			const d = {
				...data,
				object: r
			};
			dispatch(receiveSaveRegisterResponse(d));
			if (data.success && onSuccess) onSuccess.call(this, d);
		}
	});
};

export const deleteRegister = (register) => (
	createPostAction({
		passContext: true,
		url: `/api/registers/${register.registerId}/delete`,
		startAction: DELETE_REGISTER,
		success: (data) => receiveDeleteRegisterResponse(data)
	})
);

export default (originalState = initialState, action) => {
	let state = originalState;
	if (!state._hydrated) {
		state = { ...initialState, ...state, _hydrated: true };
	}

	switch (action.type) {
		case CLEAR_REGISTERS:
			return {
				...state,
				registers: null
			};
		case CLEAR_REGISTER:
			return {
				...state,
				register: null
			};
		case REQUEST_REGISTERS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				registers: []
			};
		case RECEIVE_REGISTERS:
			return {
				...state,
				isLoading: false,
				registers: action.payload.data
			};
		case REQUEST_REGISTER:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				register: { 
					...cloneDeep(globals.templates.register),
					values: {}
				}
			};
		case RECEIVE_REGISTER:
			return {
				...state,
				isLoading: false,
				register: action.payload.data
			};
		case DELETE_REGISTER:
			return { 
				...state
			};
		case RECEIVE_DELETE_REGISTER_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message
					}, 
				};
			}
			return {
				...state,
				isLoading: false,
				registers: state.registers.filter(c => c.registerId !== action.data.objectId) 
			};
		case FETCH_REGISTER:
			return { 
				...state,
				register: find(state.registers, (c) => c.registerId === action.registerId)
			};
		case CREATE_NEW_REGISTER:
			return {
				...state,
				register: { 
					...cloneDeep(globals.templates.register),
					registerDefinitionId: action.definition.registerDefinitionId,
					values: {}
				}
			};	
		case SAVE_REGISTER:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_REGISTER_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				register: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_REGISTER_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		default:
			return state;
	}
};
