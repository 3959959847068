import React from 'react';
import PropTypes from 'prop-types';
import { getElementInTableCellAbove, getElementInTableCellBelow } from '../../utils/dom';
import debounce from 'lodash/debounce';
import IconButton from './IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TextEditorDialog from './TextEditorDialog';

class DataTableTextEditor extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			value: props.value || '',
			size: 10,
			showDialog: false
		};

		this.input = null;

		this.setInputRef = element => {
			this.input = element;
		};
	}

	onShowDialog = () => {
		this.setState(prevState => ({
			...prevState,
			showDialog: true
		}));
	}


	onHideDialog = () => {
		this.setState(prevState => ({
			...prevState,
			showDialog: false
		}));
	}

	onChangeDebounced = debounce((v) => {
		this.props.onChange(v);
	}, 500)

	onChange = (e) => {
		const value = e.target.value;
		this.setState(prevState => ({
			...prevState,
			value: value
		}), () => {
		});
		if (this.props.onChange) this.onChangeDebounced(value);
	}

	onKeyUp = (e) => {
		switch (e.keyCode) {
			case 40: {
				const belowInput = getElementInTableCellBelow(this.input, 'TEXTAREA');
				if (belowInput) belowInput.focus();
				break;
			}
			case 38: {
				const aboveInput = getElementInTableCellAbove(this.input, 'TEXTAREA');
				if (aboveInput) aboveInput.focus();
				break;
			}
			default: {
				if (this.props.autoWidth) {
					this.setState(prevState => ({
						...prevState,
						size: Math.max(10, e.target.value.length)
					}));
				}
				e.target.style.height = '5px';
				e.target.style.height = `${e.target.scrollHeight}px`;
			}
		}
	};

	render = () => {
		let fieldStyles;
		if (!this.props.multiline) {
			fieldStyles = {
				width: '100%',
				floatingLabelStyle: { top: '24px' },
				inputStyle: { marginTop: '6px' }
			};
		}
		const mergedProps = { ...fieldStyles, ...this.props };
		if (!mergedProps.className) {
			mergedProps.className = '';
		}
		mergedProps.className = `data-table-text-editor ${mergedProps.className}`; 

		mergedProps.onChange = this.onChange;

		let size = this.state.size;
		if (this.state.value && this.props.autoWidth) {
			size = Math.max(10, this.state.value.length);
		}

		if (!mergedProps.style) mergedProps.style = {};
		if (mergedProps.fullWidth) mergedProps.style.width = '100%';

		delete mergedProps.autoWidth;
		delete mergedProps.fullWidth;
		delete mergedProps.multiline;
		delete mergedProps.underlineStyle;
		delete mergedProps.floatingLabelStyle;
		delete mergedProps.inputStyle;

		if (this.props.multiline) {
			const containerStyle = {
				...mergedProps.style,
				position: 'relative'
			};
			delete mergedProps.style;
			return (
				<div 
					className='data-table-text-editor-container' 
					style={containerStyle}
				>
					<textarea 
						ref={this.setInputRef}
						style={{
							marginTop: '4px'
						}}
						{...mergedProps} 
						value={this.state.value}
						onChange={this.onChange}
						onKeyUp={this.onKeyUp}
					/>
					<div
						style={{
							position: 'absolute',
							top: 0,
							bottom: 0,
							right: 0,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<IconButton 
							onClick={ () => { 
								this.onShowDialog();
							} } 
							style={{ padding: '2px' }}
						>
							<EditIcon />
						</IconButton>
					</div>
					<TextEditorDialog 
						show={this.state.showDialog}
						onCancel={this.onHideDialog}
						onOk={(newValue) => {
							this.onChange({
								target: {
									value: newValue
								}
							});
							this.onHideDialog();
						}}
						value={this.state.value}
						onKeyUp={this.onKeyUp}
					/>
				</div>

			);
		} else {	
			return (
				<input
					ref={this.setInputRef}
					size={size}
					{...mergedProps} 
					value={this.state.value}
					onChange={this.onChange}

				/>
			);
		}
	};
}

DataTableTextEditor.propTypes = {
	value: PropTypes.string,
	multiline: PropTypes.bool,
	onChange: PropTypes.func,
	autoWidth: PropTypes.bool
};

DataTableTextEditor.defaultProps = {
	value: undefined,
	multiline: true,
	onChange: true,
	autoWidth: false
};

export default DataTableTextEditor;
