import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import LotsGrid from './lotsGrid';
import { 
	deleteLot, 
	isLoading
} from '../../reducers/lots';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { get } from '../../utils/ajax';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { showErrorNotification, showSuccessNotification } from '../../reducers/notifications';
import axios from 'axios';
import { useMountEffect } from '../../utils/utils';

const Lots = (props) => {
	const [loading, setLoading] = React.useState(false);
	const [state, setState] = React.useState({
		lots: [],
		tags: [],
		scheduleItems: []
	});

	useMountEffect(() => {
		const contractId = props.context.contract.contractId;
		const periodId = props.context.period.periodId;
		const request = axios.CancelToken.source();

		setLoading(true);
		get({
			url: `/api/lots?contractId=${contractId}&periodId=${periodId}`,
			cancelToken: request.token,
			onSuccess: (data) => {
				setLoading(false);
				setState(prevState => ({
					...prevState,
					lots: data
				}));
			},
			onError: error => {
				if (!axios.isCancel(error)) {
					setLoading(false);
					props.showErrorNotification(error.message);
				}
			}
		});

		get({
			url: `/api/tags?contractId=${contractId}&periodId=${periodId}`,
			cancelToken: request.token,
			onSuccess: (data) => {
				setState(prevState => ({
					...prevState,
					tags: data
				}));
			}
		});

		get({
			url: `/api/schedule-of-rates?contractId=${contractId}&periodId=${periodId}`,
			cancelToken: request.token,
			onSuccess: (data) => {
				setState(prevState => ({
					...prevState,
					scheduleItems: flatMap(data, s => map(s.items, i => ({ ...i, label: `${i.itemNo} (${s.name})` })))
				}));
			}
		});

		return () => {
			request.cancel();
		};
	});

	const handleViewLot = (lot) => {
		props.history.push(`/lot/${lot.lotId}`);
	};
	const handleEditLot = (lot) => {
		props.history.push(`/lot/${lot.lotId}`);
	};

	const handleDeleteLot = (lot) => {
		props.deleteLot(lot, (data) => {
		props.showSuccessNotification(data.message);
		setState(prevState => ({
				...prevState,
				lots: filter(prevState.lots, l => l.lotId !== data.objectId)
			}));
		});
	};

	const newLot = () => {
		props.history.push('/lot');
	};

	return (
<PageLayout
	title="Lots"
	isLoading={loading || props.isLoading}
	actions={
		props.canEdit ? 
		[
			{
				label: 'Add Lot',
				onClick: newLot
			}
		] : []
	}
	content={
		<div>
		<ContentContainer>
			<LotsGrid 
				lots={state.lots} 
				scheduleItems={state.scheduleItems}
				canView={props.canView}
				canEdit={props.canEdit} 
				handleViewLot={handleViewLot} 
				handleEditLot={handleEditLot} 
				handleDeleteLot={handleDeleteLot} 
				tags={state.tags}
			/>
		</ContentContainer>
		</div>
	}
/>
		);
};

Lots.propTypes = {
	dispatch: PropTypes.func.isRequired,
	context: PropTypes.object.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	tags: PropTypes.array,
	deleteLot: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

Lots.defaultProps = {
	lots: [],
	lot: null,
	tags: []
};

const mapStateToProps = (state) => ({
	context: state.context,
	tags: state.lots.tags,
	canView: canView(state, 'ViewLot'),
	canEdit: canEdit(state, 'EditLots'),
	periods: state.context.contract.periods,
	isLoading: isLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	deleteLot: (lot, onSuccess) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this lot?',
			onOk: () => {
				dispatch(deleteLot(lot, onSuccess));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Lots);
