import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../utils/ajax';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import map from 'lodash/map';

const OpenEOTs = ({ setLoading, contractId, changeContractContext, setSubHeader }) => {
	const [openEOTs, setOpenEOTs] = React.useState([]);

	const onSelectEOT = (eot) => {
		if (contractId !== eot.contractId) {
			changeContractContext(eot.contractId);
		}
		History.push(`/extension-of-time/${eot.extensionOfTimeId}`);
	}

	React.useEffect(() => {
		const loadData = () => {
			setLoading(true);
			setOpenEOTs([]);
			setSubHeader("");
			get({
				url: `/api/dashboard/open-eots?contractId=${contractId}`,
				onSuccess: data => {
					setSubHeader(data.length === 1 ? '(1 item)' : `(${data.length} items)`);
					setLoading(false);
					setOpenEOTs(data);
				},
				onError: () => {
					setLoading(false);
				}
			});
		};

		if (contractId) {
			loadData();
		}
	}, [contractId]);

	if (openEOTs.length === 0) return <p style={{ marginLeft: '10px', marginTop: '10px' }}>None</p>;
		
	return (
		<div style={{ overflow: 'auto', height: '100%' }}>
		<List>
			{
				map(openEOTs, (eot, i) => (
					<ListItem
						key={`eot-${i}`}
						button
						onClick={() => onSelectEOT(eot)}
					>
						<ListItemIcon>
							<i className='fa fa-code-fork fa-2x'></i>
						</ListItemIcon>
						<ListItemText 
							primary={
								<div>
									<p>{eot.extensionOfTimeNumber}</p>
									<p style={{ fontSize: 'small' }}>{eot.description}</p>
								</div>
							}
							secondary={`Contract: ${eot.contractNumber}`}
						/>
					</ListItem>
				))
			}
		</List>
		</div>
	);
};

OpenEOTs.propTypes = {
	setLoading: PropTypes.func.isRequired,
	changeContractContext: PropTypes.func.isRequired,
	contractId: PropTypes.number
};

OpenEOTs.defaultProps = {
	contractId: null
};

export default OpenEOTs;
