import React from 'react';
import PropTypes from 'prop-types';
import { 
	CardHeader as MuiCardHeader
} from '@material-ui/core';

const CardHeader = props => {
	const propsToPassOn = { ...props };
	delete propsToPassOn.children;
	return (
		<MuiCardHeader {...propsToPassOn}>
			{/* {props.children} */}
			<div className="test"></div>
		</MuiCardHeader>
	);
};

CardHeader.propTypes = {
	children: PropTypes.object.isRequired,
	expanded: PropTypes.bool
};

CardHeader.defaultProps = {
	expanded: undefined
};

export default CardHeader;
