import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import map from 'lodash/map';
import MailIcon from '@material-ui/icons/MailOutline';
import SendIcon from '@material-ui/icons/Send';
import { withRouter } from 'react-router-dom';

class CloseContractWizardCorrespondencePage extends Component {
	selectLot = (lot) => {
		this.props.history.push(`/lot/${lot.lotId}`);
	}

	render() {
		return (
<div>
	{this.props.isLoading &&
		<div>
			<p>
				Checking for open correspondence.
			</p>
			<CircularProgress style={{ display: this.props.isLoading ? undefined : 'none' }} />
		</div>
	}
	{!this.props.isLoading && this.props.openCorrespondence && this.props.openCorrespondence.length > 0 &&
		<div>
		<p>You cannot close the Contract while there is open correspondece. The currently open correspondence are:</p>
			<List style={{ overflow: 'auto', maxHeight: '400px' }}>
				{
					map(this.props.openCorrespondence || [], (c) => (
						<ListItem
							key={c.incomingCorrespondenceId || c.outgoingCorrespondenceId}
							onClick={() => this.props.onSelectCorrespondence(c)}
						>
							<ListItemIcon>
								{c.incomingCorrespondenceId ? <MailIcon /> :  <SendIcon />}
							</ListItemIcon>
							<ListItemText 
								primary={c.rfiNumber}
								secondary={`${c.docTitle}`}
							/>
						</ListItem>
					))
				}
			</List>
		</div>
	}
	{!this.props.isLoading && (!this.props.openCorrespondence || this.props.openCorrespondence.length === 0) &&
		<p>All correspondence is closed, you may proceed</p>
	}
</div>
		);
	}
}

CloseContractWizardCorrespondencePage.propTypes = {
	openCorrespondence: PropTypes.array.isRequired,
	onSelectCorrespondence: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default withRouter(CloseContractWizardCorrespondencePage);
