import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import VariationsGrid from './variationsGrid';
import { 
	clearSaveResult, 
	editVariation, 
	saveVariation, 
	deleteVariation, 
	saveVariationAndNew, 
	createNewVariation, 
	requestVariations, 
	clearVariations 
} from '../../reducers/variations';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class Variations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
			variationDialogTitle: 'Add Variation',
			variation: props.variation
        };

		this.saveVariation = this.saveVariation.bind(this);
		this.saveVariationAndNew = this.saveVariationAndNew.bind(this);
		this.handleViewVariation = this.handleViewVariation.bind(this);
		this.handleDeleteVariation = this.handleDeleteVariation.bind(this);
		this.handleEditVariation = this.handleEditVariation.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestVariations());
	}
	static clearData(dispatch) {
		dispatch(clearVariations());
	}

	componentDidMount() {
		Variations.fetchData(this.props.dispatch);
	}

    handleOpen() {
        this.setState({ open: true });
    }

    handleClose() {
        this.setState({ open: false });
    }

	handleViewVariation = (variation) => {
		this.props.history.push(`/variation/${variation.variationId}`);
	}
	handleEditVariation = (variation) => {
		this.props.history.push(`/variation/${variation.variationId}`);

		// this.props.editVariation(variation);
		// this.setState({ 
		// 	open: true,
		// 	variationDialogTitle: 'Edit Variation'
		// });
	}

	handleDeleteVariation(variation) {
		this.props.deleteVariation(variation);
	}

	saveVariation() {
		this.props.saveChanges(this.state.variation);
	}
	saveVariationAndNew() {
		this.props.saveChangesAndNew(this.state.variation);
	}
	newVariation = () => {
		this.props.history.push('/variation');
	}
	showAddVariationDialog() {
		this.props.createNewVariation(this.props.contract.contractId);

        this.setState({ 
			open: true,
			variationDialogTitle: 'Add Variation'
		});
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			variation: nextProps.variation
		});
	}

	onCancelVariation() {
        this.setState({ open: false });
	}

	render() {
		const variations = this.props.variations;

		return (
<PageLayout
	title="Variations"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'Add Variation',
				onClick: this.newVariation
			}
		] : []
	}
	content={
		<ContentContainer>
			<VariationsGrid 
				variations={variations} 
				canView={this.props.canView} 
				canEdit={this.props.canEdit} 
				handleViewVariation={this.handleViewVariation} 
				handleEditVariation={this.handleEditVariation} 
				handleDeleteVariation={this.handleDeleteVariation} 
			/>
		</ContentContainer>
	}
/>
		);
	}
}

Variations.propTypes = {
	dispatch: PropTypes.func.isRequired,
	contract: PropTypes.object.isRequired,
	variations: PropTypes.array,
	variation: PropTypes.object.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndNew: PropTypes.func.isRequired,
	deleteVariation: PropTypes.func.isRequired,
	createNewVariation: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

Variations.defaultProps = {
	variations: []
};

const mapStateToProps = (state) => ({
	contract: state.context.contract,
	variations: state.variations.variations,
	variation: state.variations.variation,
	canView: canView(state, 'ViewVariation'),
	canEdit: canEdit(state, 'EditVariations'),
	periods: state.context.contract.periods,
	isLoading: state.variations.isLoading,
	saveResult: state.variations.saveResult
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	editVariation: (variation) => {
		dispatch(editVariation(variation));
	},
	clearSaveResult: () => {
		dispatch(clearSaveResult());
	},
	saveChanges: (variation) => {
		dispatch(saveVariation(variation));
	},
	saveChangesAndNew: (variation) => {
		dispatch(saveVariationAndNew(variation));
	},
	deleteVariation: (variation) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this variation?',
			onOk: () => {
				dispatch(deleteVariation(variation));
			}
		}));
	},
	createNewVariation: () => {
		dispatch(createNewVariation());
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Variations);
