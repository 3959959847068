import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ReportContainer from '../reportContainer';
import { fetch, addTask } from 'domain-task';
import FileDownload from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import MessagePanel from '../messagePanel';
import classNames from 'classnames';
import reject from 'lodash/reject';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import ContentAdd from '@material-ui/icons/Add';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import { showModal } from '../../reducers/modal';
import PageLayout from '../pageLayout';
import FinalCertificateContent from './FinalCertificateContent';

const FinalCertificate = props => {
	const [state, setState] = React.useState({
		isLoading: true,
		isCreating: false,
		isDeleting: false,
		isSaving: false,
		data: null,
		isValid: true,
		invalidFields: [],
		success: false,
		message: ''
	});
	const contract = props.context.contract;

	React.useEffect(() => {
		const runReport = (contract) => {
			const contractId = contract.contractId;
			
			setState(prevState => ({
				...prevState,
				data: null,
				isLoading: true
			}));
			const fetchTask =  fetch(`/api/final-certificate?contractId=${contractId}`, { 
					credentials: 'same-origin',
					headers: {
						'cache-control': 'no-store',
						'pragma': 'no-cache'
					}
				})
				.then(response => {
					if (response.status >= 200 && response.status < 300) return response.json();
	
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				})
				.then((data) => {
					setState(prevState => ({
						...prevState,
						data: data,
						isLoading: false
					}));
				}).catch(() => {
					window.status = 'ready_to_print'; // For wkhtmltopdf to know report is ready
					setState(prevState => ({
						...prevState,
						isLoading: false
					}));
				});
						
			addTask(fetchTask);
		}
		if (contract) {
			runReport(contract);
		}
	}, [contract]);

	React.useEffect(() => {
		if (!state.isLoading) {
			window.status = 'ready_to_print'; // For wkhtmltopdf to know report is ready
		}
	}, [state.isLoading]);

	const updateField = (fieldName, value, isValid) => {
		if (!state.data) return; 
		state.data[fieldName] = value;
		const invalidFields = reject(state.invalidFields, (f) => f.name === fieldName);
		if (isValid === false) {
			invalidFields.push({ name: fieldName });
		}
		setState({
			...state,
			data: state.data,
			isValid: invalidFields.length === 0,
			invalidFields: invalidFields
		});
	}

	const createFinalCertificate = () => {
		const contractId = contract.contractId;
		
		setState(prevState => ({
			...prevState,
			isCreating: true
		}));
		const fetchTask =  fetch(`/api/final-certificate/create?contractId=${contractId}`, { 
				method: 'post', 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache',
					'Accept': 'application/json'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				setState(prevState => ({
					...prevState,
					data: data,
					isCreating: false
				}));
			}).catch((error) => {
				setState(prevState => ({
					...prevState,
					success: false,
					message: error,
					isCreating: false
				}));
			});
					
		addTask(fetchTask);
	}
	const onDeleteFinalCertificate = () => {
		props.dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete Final Certificate',
			message: 'Are you sure you wish to delete the Final Certificate?',
			onOk: () => {
				const contractId = props.context.contract.contractId;
		
				setState(prevState => ({
					...prevState,
					isLoading: true,
					isDeleting: true
				}));
				const fetchTask =  fetch(`/api/final-certificate/delete?contractId=${contractId}`, { 
						method: 'post', 
						credentials: 'same-origin',
						headers: {
							'cache-control': 'no-store',
							'pragma': 'no-cache',
							'Accept': 'application/json'
						}
					})
					.then(response => {
						if (response.status >= 200 && response.status < 300) return response.json();
		
						const error = new Error(response.statusText);
						error.response = response;
						throw error;
					})
					.then((data) => {
						props.showSuccess(data.message);
						setState(prevState => ({
							...prevState,
							data: null,
							isLoading: false,
							isDeleting: false
						}));
					}).catch((error) => {
						props.showError(error);
						setState(prevState => ({
							...prevState,
							success: false,
							message: error,
							isLoading: false,
							isDeleting: false
						}));
					});
							
				addTask(fetchTask);
			}
		}));
	}
	const onRegenerateData = () => {
		props.dispatch(showModal('YES_NO', {
			title: 'Refresh Data',
			message: 'Data will be refreshed and generated values will be overwritten. Do you wish to continue?',
			onYes: () => {
				const contractId = props.context.contract.contractId;
		
				setState(prevState => ({
					...prevState,
					isLoading: true
				}));
				const fetchTask =  fetch(`/api/final-certificate/regenerate?contractId=${contractId}`, { 
						method: 'post', 
						credentials: 'same-origin',
						headers: {
							'cache-control': 'no-store',
							'pragma': 'no-cache',
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(state.data)
					})
					.then(response => {
						if (response.status >= 200 && response.status < 300) return response.json();
		
						const error = new Error(response.statusText);
						error.response = response;
						throw error;
					})
					.then((data) => {
						props.showSuccess("The data has been refreshed");
						setState(prevState => ({
							...prevState,
							data: data,
							isLoading: false
						}));
					}).catch((e) => {
						let error = e instanceof Error ? e.toString() : e;
						props.showError(error);
						setState(prevState => ({
							...prevState,
							success: false,
							message: error,
							isLoading: false
						}));
					});
							
				addTask(fetchTask);
			}
		}));
	}

	const downloadReport = () => {
		window.open('/Reports/FinalCertificate');
	}
	const onSave = () => {
		const contractId = contract.contractId;

		setState({
			...state,
			isLoading: false,
			isSaving: true
		});
		const fetchTask =  fetch(`/api/final-certificate?contractId=${contractId}`, { 
				method: 'post', 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache',
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(state.data)
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				props.showSuccess(data.message);
				setState(prevState => ({
					...prevState,
					data: data.object,
					success: data.success,
					message: data.message,
					isLoading: false,
					isSaving: false
				}));
			}).catch((error) => {
				props.showError(error);
				setState(prevState => ({
					...prevState,
					success: false,
					message: error,
					isLoading: false,
					isSaving: false
				}));
			});
		addTask(fetchTask);	
	}

	const reportClass = classNames({
		'editable-report': !props.isReportView,
		'final-certificate': true
	});
		
	if (state.data == null) {
		return (
			<PageLayout
				title="Final Certificate"
				isLoading={state.isLoading || state.isSaving || state.isDeleting || state.isCreating}
				actions={
					(!state.isLoading && !props.isReportView && props.canEdit) ?
						[
							{
								label: 'Create Final Certificate',
								primary: true,
								icon: <ContentAdd />,
								onClick: createFinalCertificate,
								isLoading: state.isCreating
							}
						] : []
				}
				content={
					<div className={reportClass}>
					</div>
				}
			/>
		);
	}
	
	const pageActions = [];
	if (!props.isReportView && props.canEdit) {
		pageActions.push({ 
			label: 'Save', 
			primary: true,
			disabled: !state.isValid,
			onClick: onSave,
			icon: <SaveIcon />,
			isLoading: state.isSaving
		});
	}
	if (!props.isReportView && props.canEdit) {
		pageActions.push({ 
			label: 'Delete',
			icon: <DeleteIcon />,
			onClick: onDeleteFinalCertificate,
			isLoading: state.isDeleting
		});
	}
	if (!props.isReportView) {
		pageActions.push({
			label: 'Download',
			icon: <FileDownload />,
			onClick: downloadReport
		});
	}
	if (!props.isReportView && props.canEdit) {
		pageActions.push({
			label: 'Refresh Data',
			icon: <RefreshIcon />,
			onClick: onRegenerateData,
			isLoading: state.isLoading
		});
	}

	return (
<PageLayout
	title="Final Certificate"
	isLoading={state.isLoading || state.isSaving || state.isDeleting || state.isCreating}
	actions={pageActions}
	showHeader={!props.isReportView}
	content={
		<div className={reportClass}>
			<ReportContainer isReportView={props.isReportView}>
				<div className='header'>
					<h1>Final Certificate</h1>
				</div>

				<div className="logo">
					<img src="/Resources/Logo" alt="" style={{ maxHeight: '100px' }} />
				</div>

				<FinalCertificateContent
					report={state.data}
					updateField={updateField}
				/>
			</ReportContainer>

			{!state.isValid && 
				<MessagePanel isSuccess={false} message="One or more fields are invalid" />
			}
			<MessagePanel isSuccess={state.success} message={state.message} />
		</div>
	}
/>
	);
};

FinalCertificate.propTypes = {
	dispatch: PropTypes.func.isRequired,
	context: PropTypes.object.isRequired,
	period: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	showSuccess: PropTypes.func.isRequired,
	showError: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	period: state.context.period,
	canEdit: canEditAnyPeriod(state, 'EditCertificates'),
	context: state.context
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	showSuccess: (message) => {
		dispatch(showSuccessNotification(message));
	},
	showError: (error) => {
		dispatch(showErrorNotification(error));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(FinalCertificate);
