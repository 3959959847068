import React from 'react';
import PropTypes from 'prop-types';
import { toCurrency } from '../../utils/utils';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	colorPrimary: {
		backgroundColor: '#BDBDBD',
	},
	barColorPrimary: {
		backgroundColor: '#0E76BC',
	}
});

const Certified = ({ contract }) => {
	const classes = useStyles();
	if (!contract) return <div></div>;

	return (
		<div style={{ padding: '10px' }}>
			<LinearProgress 
				variant="determinate" 
				value={contract.certifiedAmount / contract.currentContractValue * 100} 
				classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} 
				style={{ height: '20px' }} 
			/>
			<span className="certified-progress-label">{toCurrency(contract.certifiedAmount)} / {toCurrency(contract.currentContractValue)}</span>
		</div>
	);
};

Certified.propTypes = {
	setLoading: PropTypes.func.isRequired,
	contractId: PropTypes.number
};

Certified.defaultProps = {
	contractId: null
};

export default Certified;
