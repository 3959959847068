import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Header from './header';
import SidePanel from './sidePanel';
import Footer from './footer';
import classNames from 'classnames';
import SupportButton from './supportButton';
import Notifications from './notifications';
import HelpDrawer from './HelpDrawer';

const Layout = props => {
	if (props.pageLoaded && !props.isLoggedIn) {
		return <Redirect to="/login" />;
	}
    const containerClass = classNames({
		'wrapper': true,
		'side-panel-collapse': props.collapseSidePanel,
		'full-sceen-side-panel': props.fullScreenSidePanel
    });

	if (!props.pageLoaded) {
		return (
			<div className="app-loader-overlay">
				<div className="app-loader">
					<img src="/images/ContrackLoaderFade.gif" alt="" />
					<span>Loading</span>
				</div>
			</div>
		);
	}

    return (
<div className={containerClass}>
	<SidePanel 
		changeContractContext={props.changeContractContext} 
		changePeriodContext={props.changePeriodContext} 
	/>

	<Header 
		collapseSidePanel={props.collapseSidePanel}
		changeContractContext={props.changeContractContext} 
		changePeriodContext={props.changePeriodContext} 
	/>

	<div className="content-wrapper" style={{ minHeight: '946px' }}>
		{props.body}
	</div> 

	<SupportButton />
	<HelpDrawer />
	<Notifications />
	<Footer /> 
</div>
    );
};

Layout.propTypes = {
	body: PropTypes.element.isRequired,
	collapseSidePanel: PropTypes.bool.isRequired,
	fullScreenSidePanel: PropTypes.bool.isRequired,
	changeContractContext: PropTypes.func.isRequired,
	changePeriodContext: PropTypes.func.isRequired,
	isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
	pageLoaded: !state.bootstrap.loading,
	collapseSidePanel: state.layout.collapseSidePanel,
	fullScreenSidePanel: state.layout.fullScreenSidePanel,
	isLoggedIn: state.context.isLoggedIn
});

const mapDispatchToProps = () => ({
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Layout);
