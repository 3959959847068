import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import TextField from '../widgets/TextField';
import FormContainer from '../formContainer';
import FormFieldContainer from '../formFieldContainer';
import cloneDeep from 'lodash/cloneDeep';
import { globals } from '../../globals';

const TempClaimItemDialog = (props) => {
	const { show, onOk, onCancel } = props;
	const existingItem = props.item;
	const isNew = !existingItem;

	const [item, setItem] = React.useState({});

	React.useEffect(() => {
		setItem(
			props.item ? { ...props.item } : {
			...cloneDeep(globals.templates.progressClaimItem),
			claimType: 'Temporary',
			scheduleName: '',
			itemNo: '',
			description: ''
		});
	}, [props.show]);

	return (
		<Dialog
			title={isNew ? "New Temporary Claim Item" : "Edit Temporary Claim Item"}
			actions={
				<React.Fragment>
					<Button
						label={isNew ? "Add" : "Update"}
						primary={true}
						onClick={() => {
							onOk(item);
						}}
					/>,
					<Button
						label="Cancel"
						primary={false}
						onClick={onCancel}
					/>
				</React.Fragment>
			}
			open={show}
			onClose={onCancel}
			scroll='paper'
			fullWidth={true}
			maxWidth='lg'
		>
			<FormContainer>
				<FormFieldContainer>
					<TextField
						value={item.scheduleName}
						fullWidth={true}
						onChange={(e, v) => { setItem({ ...item, scheduleName: v }); }}
						label="Schedule/Variation Name"
					/>
				</FormFieldContainer>

				<FormFieldContainer>
					<TextField
						value={item.itemNo}
						fullWidth={true}
						onChange={(e, v) => { setItem({ ...item, itemNo: v }); }}
						label="Item No"
					/>
				</FormFieldContainer>

				<FormFieldContainer>
					<TextField
						value={item.description}
						fullWidth={true}
						onChange={(e, v) => { setItem({ ...item, description: v }); }}
						label="Description"
					/>
				</FormFieldContainer>
			</FormContainer>
		</Dialog>
	);
};

TempClaimItemDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	item: PropTypes.object,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

TempClaimItemDialog.defaultProps = {
	item: null
};

export default TempClaimItemDialog;
