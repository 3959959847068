import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LinkedCorrespondence from './linkedCorrespondence';
import ContentContainer from '../contentContainer';
import PageLayout from '../pageLayout';
import { get } from '../../utils/ajax';
import { showErrorNotification } from '../../reducers/notifications';
import { getQueryVariable } from '../../utils/utils';

const LinkedCorrespondenceController = (props) => {
	const [state, setState] = React.useState({
		tree: null,
		isLoading: false
	});
	const type = getQueryVariable('type');
	const id = getQueryVariable('id');

	React.useEffect(() => {
		if (type && id) {
			setState(prevState => ({ ...prevState, isLoading: true }));
			get({
				url: `/api/linked-correspondence/explore?contractId=${props.contractId}&targetType=${type}&targetId=${id}`,
				onSuccess: data => setState(prevState => ({ ...prevState, tree: data, isLoading: false })),
				onError: (error) => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		} else {
			setState(prevState => ({ ...prevState, tree: [] }));
		}
	}, [props.match.params.contactId, type, id]);

	return (
		<PageLayout
			title='Linked Correspondence Explorer'
			isLoading={state.isLoading}
			content={
				<div>
					<ContentContainer>
						<LinkedCorrespondence 
							data={state.tree}
						/>
					</ContentContainer>
				</div>
			}
		/>
	);
};

LinkedCorrespondenceController.propTypes = {
	params: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	contractId: PropTypes.number.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	contractId: state.context.contract ? state.context.contract.contractId : 0
});

const mapDispatchToProps = (dispatch) => ({
	showErrorNotification: message => dispatch(showErrorNotification(message)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LinkedCorrespondenceController);
