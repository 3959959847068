import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { addOrUpdate, formatDate } from '../utils/utils';
import { globals } from '../globals';
import map from 'lodash/map';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	forecast: null, 
	forecasts: null,
	contractForecast: null,
	forecastPeriods: null,
	forecastPeriod: null
};

const CLEAR_FORECASTS = 'CLEAR_FORECASTS';
const CLEAR_FORECAST = 'CLEAR_FORECAST';
const CLEAR_CONTRACT_FORECAST = 'CLEAR_CONTRACT_FORECAST';
const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT';
const DELETE_FORECAST = 'DELETE_FORECAST';
const REQUEST_FORECASTS = 'REQUEST_FORECASTS';
const RECEIVE_FORECASTS = 'RECEIVE_FORECASTS';
const REQUEST_FORECAST = 'REQUEST_FORECAST';
const RECEIVE_FORECAST = 'RECEIVE_FORECAST';
const REQUEST_CONTRACT_FORECAST = 'REQUEST_CONTRACT_FORECAST';
const RECEIVE_CONTRACT_FORECAST = 'RECEIVE_CONTRACT_FORECAST';
const CREATE_NEW_FORECAST = 'CREATE_NEW_FORECAST';
const RECEIVE_NEW_FORECAST = 'RECEIVE_NEW_FORECAST';
const SAVE_FORECAST = 'SAVE_FORECAST';
const RECEIVE_SAVE_FORECAST_RESPONSE = 'RECEIVE_SAVE_FORECAST_RESPONSE';
const RECEIVE_DELETE_FORECAST_RESPONSE = 'RECEIVE_DELETE_FORECAST_RESPONSE';
const SHOW_FORECAST_ERRORS = 'SHOW_FORECAST_ERRORS';
const EDIT_FORECAST = 'EDIT_FORECAST';
const SAVE_CONTRACT_FORECAST = 'SAVE_CONTRACT_FORECAST';
const RECEIVE_SAVE_CONTRACT_FORECAST_RESPONSE = 'RECEIVE_SAVE_CONTRACT_FORECAST_RESPONSE';

const CLEAR_FORECAST_PERIODS = 'CLEAR_FORECAST_PERIODS';
const REQUEST_FORECAST_PERIODS = 'REQUEST_FORECAST_PERIODS';
const RECEIVE_FORECAST_PERIODS = 'RECEIVE_FORECAST_PERIODS';
const CLEAR_FORECAST_PERIOD = 'CLEAR_FORECAST_PERIOD';
const REQUEST_FORECAST_PERIOD = 'REQUEST_FORECAST_PERIOD';
const RECEIVE_FORECAST_PERIOD = 'RECEIVE_FORECAST_PERIOD';
const CREATE_NEW_FORECAST_PERIOD = 'CREATE_NEW_FORECAST_PERIOD';
const SAVE_FORECAST_PERIOD = 'SAVE_FORECAST_PERIOD';
const NEW_FORECAST_PERIOD = 'NEW_FORECAST_PERIOD';
const DELETE_FORECAST_PERIOD = 'DELETE_FORECAST_PERIOD';
const RECEIVE_SAVE_FORECAST_PERIOD_RESPONSE = 'RECEIVE_SAVE_FORECAST_PERIOD_RESPONSE';
const RECEIVE_NEW_FORECAST_PERIOD_RESPONSE = 'RECEIVE_NEW_FORECAST_PERIOD_RESPONSE';
const RECEIVE_DELETE_FORECAST_PERIOD_RESPONSE = 'RECEIVE_DELETE_FORECAST_PERIOD_RESPONSE';
const RECEIVE_ERROR_RESPONSE = 'RECEIVE_ERROR_RESPONSE';

export const clearForecasts = () => ({ type: CLEAR_FORECASTS });
export const clearForecast = () => ({ type: CLEAR_FORECAST });
export const clearContractForecast = () => ({ type: CLEAR_CONTRACT_FORECAST });
export const clearSaveResult = () => ({ type: CLEAR_SAVE_RESULT });
export const receiveForecasts = (data) => ({ type: RECEIVE_FORECASTS, payload: { data } });
export const receiveForecast = (data) => ({ type: RECEIVE_FORECAST, payload: { data } });
export const receiveContractForecast = (data) => ({ type: RECEIVE_CONTRACT_FORECAST, payload: { data } });
export const receiveNewForecast = (data) => ({ type: RECEIVE_NEW_FORECAST, payload: { data } });
export const receiveSaveForecastResponse = (data) => ({ type: RECEIVE_SAVE_FORECAST_RESPONSE, data });
export const receiveDeleteForecastResponse = (data) => ({ type: RECEIVE_DELETE_FORECAST_RESPONSE, data });
export const editForecast = (forecast) => ({ type: EDIT_FORECAST, forecast });
export const receiveSaveContractForecastResponse = (data) => ({ type: RECEIVE_SAVE_CONTRACT_FORECAST_RESPONSE, data });
export const receiveErrorResponse = (error) => ({ type: RECEIVE_ERROR_RESPONSE, error });

export const createNewForecast = () =>
	(dispatch, getState) => {	
		const newForecast = {
			...cloneDeep(globals.templates.forecast)
		};
		dispatch({ type: CREATE_NEW_FORECAST, newForecast: newForecast });

		const fetch = createFetchAction({
			objectName: 'New Forecast',
			passContext: true,
			url: '/api/forecasts/new',
			startAction: REQUEST_FORECASTS,
			success: (data) => receiveForecast(data)
		});
		fetch(dispatch, getState);
	};

export const requestForecasts = () => (
	createFetchAction({
		objectName: 'Forecasts',
		passContext: true,
		url: '/api/forecasts',
		startAction: REQUEST_FORECASTS,
		success: (data) => receiveForecasts(data)
	})
);

export const requestForecast = (forecastId) => (
	createFetchAction({
		objectName: 'Forecast',
		passContext: true,
		url: `/api/forecasts/${forecastId}`,
		startAction: REQUEST_FORECAST,
		success: (data) => receiveForecast(data)
	})
);

export const requestContractForecast = () => (
	createFetchAction({
		objectName: 'Contract Forecast',
		passContext: true,
		url: '/api/forecasts/contract-forecast',
		startAction: REQUEST_CONTRACT_FORECAST,
		success: (data) => receiveContractForecast(data)
	})
);

const validateForecast = (forecast) => {
	const errors = [];
	if (!forecast.name) {
		errors.push({
			fieldName: 'Name',
			valid: false,
			message: 'Name is required'
		});
	}
	return errors;
};

export const saveForecast = (forecast, onSuccess) => {
	const errors = validateForecast(forecast);
	if (errors.length > 0) return { type: SHOW_FORECAST_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/forecasts',
		data: forecast,
		startAction: SAVE_FORECAST,
		onError: (response) => receiveErrorResponse(response),
		success: (data, dispatch) => {
			dispatch(receiveSaveForecastResponse(data));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const saveForecastAndNew = (forecast) => {
	const errors = validateForecast(forecast);
	if (errors.length > 0) return { type: SHOW_FORECAST_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/forecasts',
		data: forecast,
		startAction: SAVE_FORECAST,
		success: (data, dispatch) => {
			dispatch(receiveSaveForecastResponse(data));
			if (data.success) dispatch(createNewForecast());
		}
	});
};

export const deleteForecast = (forecast) => (
	createPostAction({
		passContext: true,
		url: `/api/forecasts/${forecast.forecastId}/delete`,
		data: forecast,
		startAction: DELETE_FORECAST,
		success: (data, dispatch) => {
			dispatch(receiveDeleteForecastResponse(data));
			if (data.success) dispatch(createNewForecast());
		}
	})
);

export const saveContractForecast = (contractForecast, onSuccess) =>
	createPostAction({
		passContext: true,
		url: '/api/forecasts/save-contract-forecast',
		data: contractForecast,
		startAction: SAVE_CONTRACT_FORECAST,
		onError: (response) => receiveErrorResponse(response),
		success: (data, dispatch) => {
			dispatch(receiveSaveContractForecastResponse(data));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});


export const clearForecastPeriods = () => ({ type: CLEAR_FORECAST_PERIODS });
export const receiveForecastPeriods = (data) => ({ type: RECEIVE_FORECAST_PERIODS, payload: { data } });
export const requestForecastPeriods = () => (
	createFetchAction({
		objectName: 'Forecast Periods',
		passContext: true,
		url: '/api/forecasts/forecast-periods',
		startAction: REQUEST_FORECAST_PERIODS,
		success: (data) =>
			receiveForecastPeriods(map(data, (p) => ({
				...p,
				startDate: p.startDate ? new Date(p.startDate) : null,
				endDate: p.endDate ? new Date(p.endDate) : null
			})))
	})
);

export const clearForecastPeriod = () => ({ type: CLEAR_FORECAST_PERIOD });
export const receiveForecastPeriod = (data) => ({ type: RECEIVE_FORECAST_PERIOD, payload: { data } });
export const requestForecastPeriod = (forecastPeriodId) => (
	createFetchAction({
		objectName: 'Forecast Period',
		passContext: true,
		url: `/api/forecasts/forecast-period/${forecastPeriodId}`,
		startAction: REQUEST_FORECAST_PERIOD,
		success: (data) => receiveForecastPeriod(data)
	})
);

export const receiveSaveForecastPeriodResponse = (data) => ({ type: RECEIVE_SAVE_FORECAST_PERIOD_RESPONSE, data });
export const saveForecastPeriod = (forecastPeriod, onSuccess) =>
	createPostAction({
		passContext: true,
		url: '/api/forecasts/forecast-period',
		data: forecastPeriod,
		startAction: SAVE_FORECAST_PERIOD,
		success: (data, dispatch) => {
			dispatch(receiveSaveForecastPeriodResponse({
				...data,
				object: data.object ? {
					...data.object,
					startDate: data.object.startDate ? new Date(data.object.startDate) : null,
					endDate: data.object.endDate ? new Date(data.object.endDate) : null
				} : data.object
			}));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});

export const createNewForecastPeriod = () => ({ type: CREATE_NEW_FORECAST_PERIOD });

export const receiveNewForecastPeriodResponse = (data) => ({ type: RECEIVE_NEW_FORECAST_PERIOD_RESPONSE, data });
export const newForecastPeriod = (periodName, onSuccess) =>
	createPostAction({
		passContext: true,
		url: '/api/forecasts/new-forecast-period',
		data: { periodName: periodName },
		startAction: NEW_FORECAST_PERIOD,
		success: (data, dispatch) => {
			dispatch(receiveNewForecastPeriodResponse({
				...data,
				object: data.object ? {
					...data.object,
					startDate: data.object.startDate ? new Date(data.object.startDate) : null,
					endDate: data.object.endDate ? new Date(data.object.endDate) : null
				} : data.object
			}));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});

export const receiveDeleteForecastPeriodResponse = (data) => ({ type: RECEIVE_DELETE_FORECAST_PERIOD_RESPONSE, data });
export const deleteForecastPeriod = (forecastPeriod) => (
	createPostAction({
		passContext: true,
		url: `/api/forecasts/delete-forecast-period/${forecastPeriod.forecastPeriodId}`,
		data: forecastPeriod,
		startAction: DELETE_FORECAST_PERIOD,
		success: (data, dispatch) => {
			dispatch(receiveDeleteForecastPeriodResponse(data));
		}
	})
);

const MERGE_FORECAST_PERIOD = 'MERGE_FORECAST_PERIOD';
const RECEIVE_MERGE_FORECAST_PERIOD_RESPONSE = 'RECEIVE_MERGE_FORECAST_PERIOD_RESPONSE';
export const receiveMergeForecastPeriodResponse = (data) => ({ type: RECEIVE_MERGE_FORECAST_PERIOD_RESPONSE, data });
export const mergeForecastPeriod = (forecastPeriod, mergePeriod, onSuccess) => 
	createPostAction({
		passContext: true,
		url: '/api/forecasts/merge-forecast-period',
		data: {
			forecastPeriodId: forecastPeriod.forecastPeriodId,
			mergePeriodId: mergePeriod.periodId
		},
		startAction: MERGE_FORECAST_PERIOD,
		success: (data, dispatch) => {
			dispatch(receiveMergeForecastPeriodResponse({
				...data,
				object: data.object ? {
					...data.object,
					startDate: data.object.startDate ? new Date(data.object.startDate) : null,
					endDate: data.object.endDate ? new Date(data.object.endDate) : null
				} : data.object
			}));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});

export default (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_ERROR_RESPONSE:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: action.error.message,
					errors: [] 
				}
			};
		case CLEAR_FORECASTS:
			return {
				...state,
				forecasts: null
			};
		case CLEAR_FORECAST:
			return {
				...state,
				forecast: null
			};
		case CLEAR_CONTRACT_FORECAST:
			return {
				...state,
				contractForecast: null
			};
		case CLEAR_SAVE_RESULT:
			return {
				...state,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case EDIT_FORECAST:
			return {
				...state,
				forecast: action.forecast,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case REQUEST_FORECASTS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				forecasts: []
			};
		case RECEIVE_FORECASTS:
			return {
				...state,
				isLoading: false,
				forecasts: action.payload.data
			};
		case REQUEST_FORECAST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				forecast: globals.templates.forecast
			};
		case RECEIVE_FORECAST:
			return {
				...state,
				isLoading: false,
				forecast: action.payload.data
			};
		case REQUEST_CONTRACT_FORECAST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				contractForecast: null
			};
		case RECEIVE_CONTRACT_FORECAST:
			return {
				...state,
				isLoading: false,
				contractForecast: action.payload.data
			};
		case DELETE_FORECAST:
			return { 
				...state,
				isLoading: true
			};
		case RECEIVE_DELETE_FORECAST_RESPONSE:
			return {
				...state,
				isLoading: false,
				contractForecast: {
					...state.contractForecast,
					forecasts: state.contractForecast.forecasts.filter(c => c.forecastId !== action.data.objectId) 
				},
				forecasts: state.forecasts ? state.forecasts.filter(c => c.forecastId !== action.data.objectId) : []
			};
		case CREATE_NEW_FORECAST:
			return {
				...state,
				forecast: action.newForecast,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};	
		case SAVE_FORECAST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_FORECAST_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				forecasts: addOrUpdate(state.forecasts, action.data.object, { forecastId: action.data.object.forecastId }), 
				forecast: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SAVE_CONTRACT_FORECAST:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_CONTRACT_FORECAST_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				contractForecast: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case SHOW_FORECAST_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};

		case CLEAR_FORECAST_PERIODS:
			return {
				...state,
				forecastPeriods: null
			};
		case REQUEST_FORECAST_PERIODS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				forecastPeriods: []
			};
		case RECEIVE_FORECAST_PERIODS:
			return {
				...state,
				isLoading: false,
				forecastPeriods: action.payload.data
			};
		case CLEAR_FORECAST_PERIOD:
			return {
				...state,
				forecastPeriod: null
			};
		case REQUEST_FORECAST_PERIOD:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				forecastPeriod: globals.templates.forecastPeriod
			};
		case RECEIVE_FORECAST_PERIOD:
			return {
				...state,
				isLoading: false,
				forecastPeriod: action.payload.data
			};
		case CREATE_NEW_FORECAST_PERIOD:
			return {
				...state,
				forecastPeriod: { 
					...cloneDeep(globals.templates.forecastPeriod),
					periodName: formatDate(new Date(), 'MMMM yy'),
					startDate: new Date()
				
				}
			};	
		case SAVE_FORECAST_PERIOD:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_FORECAST_PERIOD_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				forecastPeriods: addOrUpdate(state.forecastPeriods, action.data.object, { forecastPeriodId: action.data.object.forecastPeriodId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case NEW_FORECAST_PERIOD:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_NEW_FORECAST_PERIOD_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				forecastPeriods: addOrUpdate(state.forecastPeriods, action.data.object, { forecastPeriodId: action.data.object.forecastPeriodId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case DELETE_FORECAST_PERIOD:
			return { 
				...state,
				isLoading: true
			};
		case RECEIVE_DELETE_FORECAST_PERIOD_RESPONSE:
			return {
				...state,
				isLoading: false,
				forecastPeriods: state.forecastPeriods ? state.forecastPeriods.filter(p => p.forecastPeriodId !== action.data.objectId) : []
			};
		case MERGE_FORECAST_PERIOD:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_MERGE_FORECAST_PERIOD_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				forecastPeriods: addOrUpdate(state.forecastPeriods, action.data.object, { forecastPeriodId: action.data.object.forecastPeriodId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
			
		default:
			return state;
	}
};
