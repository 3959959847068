import history from '../history';

export const viewLink = (correspondence) => {
    switch (correspondence.correspondenceType) { 
        case 'Incoming':
            history.push(`/incoming-correspondence/${correspondence.correspondenceId}`);
            break;
        case 'Outgoing':
            history.push(`/outgoing-correspondence/${correspondence.correspondenceId}`);
            break;
        default:
    }
};

export const viewLinkBy = (linkedCorrespondence) => {
    switch (linkedCorrespondence.targetType) { 
        case 'IncomingCorrespondence':
            history.push(`/incoming-correspondence/${linkedCorrespondence.targetId}`);
            break;
        case 'OutgoingCorrespondence':
            history.push(`/outgoing-correspondence/${linkedCorrespondence.targetId}`);
            break;
        case 'DesignCorrespondence':
            history.push(`/design-correspondence/${linkedCorrespondence.targetId}`);
            break;
        case 'PrincipalCorrespondence':
            history.push(`/principal-correspondence/${linkedCorrespondence.targetId}`);
            break;
        case 'NonConformanceRegister':
            history.push(`/ncr/${linkedCorrespondence.targetId}`);
            break;
        case 'ExtensionOfTime':
            history.push(`/extension-of-time/${linkedCorrespondence.targetId}`);
            break;
        case 'Variation':
            history.push(`/variation/${linkedCorrespondence.targetId}`);
            break;
        default:
    }
};
