import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	savePrincipalCorrespondence, 
	clearPrincipalCorrespondence, 
	requestPrincipalCorrespondence, 
	createNewPrincipalCorrespondence 
} from '../../reducers/principalCorrespondence';
import { requestEmployees } from '../../reducers/employees';
import PrincipalCorrespondenceForm from './principalCorrespondenceForm';
import MessagePanel from '../messagePanel';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { viewLink, viewLinkBy } from '../../utils/linkedCorrespondenceUtils';

class PrincipalCorrespondenceMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			correspondence: props.correspondence,
			newFiles: [],
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
		this.addFiles = this.addFiles.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.updateFile = this.updateFile.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestEmployees());
		if (props.match.params.correspondenceId) {
			dispatch(requestPrincipalCorrespondence(props.match.params.correspondenceId));
		} else {
			dispatch(createNewPrincipalCorrespondence(props.contract.contractId));
		}
	}
	static clearData(dispatch) {
		dispatch(clearPrincipalCorrespondence());
	}
	componentDidMount() {
		PrincipalCorrespondenceMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			correspondence: nextProps.correspondence,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const correspondence = this.state.correspondence;

		switch (field) {
			case 'from':
				correspondence.fromType = newValue.fromType;
				correspondence.fromId = newValue.fromId;
				break;
			case 'to':
				correspondence.toType = newValue.toType;
				correspondence.toId = newValue.toId;
				break;
			default:
				correspondence[field] = newValue;
		}

        this.setState({
			correspondence,
			hasUnsavedChanges: true
        });
	}

	downloadFile(file) {
		window.open(`/api/principal-correspondence/${this.state.correspondence.principalCorrespondenceId}/download-file/${file.userFileId}?contractId=${this.props.contract.contractId}`, '_self');
	}
	deleteFile(file) {
		const correspondence = this.state.correspondence;
		correspondence.files = filter(correspondence.files, (f) => f !== file);
        this.setState({
			correspondence,
			hasUnsavedChanges: true
        });
	}
	addFiles(type, files) {
		const correspondence = this.state.correspondence;
		const newFiles = this.state.newFiles;
		if (!correspondence.files) correspondence.files = [];
		forEach(files, (f) => {
			correspondence.files.push({
				referenceType: type,
				name: f.name,
				sizeBytes: f.size,
				_isNew: true
			});
			newFiles.push({
				data: type,
				file: f
			});
		});
		this.setState({
			correspondence,
			newFiles,
			hasUnsavedChanges: true
		});
	}
	updateFile() {

	}

	onSelectCorrespondence = (selectedCorrespondence) => {
		const correspondence = this.state.correspondence;
		const linkedCorrespondence = [];

		forEach(selectedCorrespondence.incomingCorrespondence, (c) => {
			const lc = {
				targetType: 'PrincipalCorrespondence',
				targetId: correspondence.principalCorrespondenceId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Incoming',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle,
				correspondenceDate: c.correspondenceDate
			};

			// Keep the current id if it already existed
			const existingLC = find(correspondence.linkedCorrespondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Incoming');
			if (existingLC) lc.linkedCorrespondenceId = existingLC.linkedCorrespondenceId;

			linkedCorrespondence.push(lc);
		});

		forEach(selectedCorrespondence.outgoingCorrespondence, (c) => {
			const lc = {
				targetType: 'PrincipalCorrespondence',
				targetId: correspondence.principalCorrespondenceId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Outgoing',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle,
				correspondenceDate: c.correspondenceDate
			};

			// Keep the current id if it already existed
			const existingLC = find(correspondence.linkedCorrespondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Outgoing');
			if (existingLC) lc.linkedCorrespondenceId = existingLC.linkedCorrespondenceId;

			linkedCorrespondence.push(lc);
		});

		correspondence.linkedCorrespondence = linkedCorrespondence;
		this.setState({ 
			correspondence,
			hasUnsavedChanges: true
		});
	}
	deleteLinkedCorrespondence = (correspondence) => {
        this.setState({
			correspondence: {
				...this.state.correspondence,
				linkedCorrespondence: filter(this.state.correspondence.linkedCorrespondence, (c) => !(c.correspondenceType === correspondence.correspondenceType && c.correspondenceId === correspondence.correspondenceId))
			},
			hasUnsavedChanges: true
        });
	}
	exploreLinkedCorrespondence = () => {
		const correspondence =  this.state.correspondence || {};
		this.props.history.push(`/linked-correspondence?type=PrincipalCorrespondence&id=${correspondence.principalCorrespondenceId}`);
	}

	render() {
		const correspondence =  this.state.correspondence || { files: [], tags: [] };

		return (
<PageLayout
	title={correspondence.rfiNumber}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.correspondence, this.state.newFiles),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.correspondence, this.state.newFiles, () => this.setState({ newFiles: [] })),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<PrincipalCorrespondenceForm 
			canEdit={this.props.canEdit}
			correspondence={correspondence} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			downloadFile={this.downloadFile}
			deleteFile={this.deleteFile}
			addFiles={this.addFiles}
			updateFile={this.updateFile}
			userList={this.props.userList}
			employees={this.props.employees}
			onSelectCorrespondence={this.onSelectCorrespondence}
			deleteLinkedCorrespondence={this.deleteLinkedCorrespondence}
			viewLinkedCorrespondence={viewLink}
			viewLinkedCorrespondenceBy={viewLinkBy}
			exploreLinkedCorrespondence={this.exploreLinkedCorrespondence}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

PrincipalCorrespondenceMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	contract: PropTypes.object.isRequired,
	correspondence: PropTypes.object,
	userList: PropTypes.array.isRequired,
	employees: PropTypes.array,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

PrincipalCorrespondenceMaintenance.defaultProps = {
	employees: [],
	correspondence: null
};

const mapStateToProps = (state) => ({
	correspondence: state.principalCorrespondence.principalCorrespondence,
	contract: state.context.contract,
	userList: state.users.userList,
	employees: state.employees.employees,
	canEdit: canEdit(state, 'EditPrincipalCorrespondence'),
	isLoading: state.principalCorrespondence.isLoading,
	saveResult: state.principalCorrespondence.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (correspondence, newFiles) => {
		dispatch(savePrincipalCorrespondence(correspondence, newFiles, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (correspondence, newFiles, onSuccess) => {
		dispatch(savePrincipalCorrespondence(correspondence, newFiles, (data) => {
			dispatch(showSuccessNotification(data.message));
			onSuccess();
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(PrincipalCorrespondenceMaintenance);
