import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import RegisterDefinitionsGrid from './registerDefinitionsGrid';
import { deleteRegisterDefinition, requestRegisterDefinitions, clearRegisterDefinitions } from '../../reducers/registerDefinitions';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PageLayout from '../pageLayout';
import { canEdit } from '../../selectors/canEdit';

class RegisterDefinitions extends Component {
    constructor(props) {
        super(props);

        this.state = {
			registerDefinitions: {}
        };

		this.onEditRegisterDefinition = this.onEditRegisterDefinition.bind(this);
		this.onDeleteRegisterDefinition = this.onDeleteRegisterDefinition.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestRegisterDefinitions());
	}
	static clearData(dispatch) {
		dispatch(clearRegisterDefinitions());
	}
	componentDidMount() {
		RegisterDefinitions.fetchData(this.props.dispatch);
	}

	onEditRegisterDefinition = (registerDefinition) => {
		this.props.history.push(`/register-definition/${registerDefinition.registerDefinitionId}`);
	}
	onViewRegisterDefinition = (registerDefinition) => {
		this.props.history.push(`/register-definition/${registerDefinition.registerDefinitionId}`);
	}
	onDeleteRegisterDefinition = (registerDefinition) => {
		this.props.deleteRegisterDefinition(registerDefinition);
	}

	newRegisterDefinition = () => {
		this.props.history.push('/register-definition');
	}

	render() {
		return (
<PageLayout
	title="Setup Registers"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Register Definition',
				onClick: this.newRegisterDefinition
			}
		] : []
	}
	content={
		<ContentContainer>
			<RegisterDefinitionsGrid 
				registerDefinitions={this.props.registerDefinitions} 
				onViewRegisterDefinition={this.onViewRegisterDefinition}
				onEditRegisterDefinition={this.onEditRegisterDefinition} 
				onDeleteRegisterDefinition={this.onDeleteRegisterDefinition} 
				canEdit={this.props.canEdit}
			/>
		</ContentContainer>
	}
/>
		);
	}
}

RegisterDefinitions.propTypes = {
	dispatch: PropTypes.func.isRequired,
	registerDefinitions: PropTypes.arrayOf(PropTypes.object).isRequired,
	deleteRegisterDefinition: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
	registerDefinitions: state.registerDefinitions.registerDefinitions,
	canEdit: canEdit(state, 'EditRegisterDefinitions'),
	isLoading: state.registerDefinitions.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteRegisterDefinition: (registerDefinition) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Register Definition?',
			onOk: () => {
				dispatch(deleteRegisterDefinition(registerDefinition));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(RegisterDefinitions);
