/* eslint object-curly-newline: "off" */
/* eslint new-cap: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentContainer from './contentContainer';
import axios from 'axios';
import { get } from '../utils/ajax';
import groupBy from 'lodash/groupBy';
import { changeContractContext } from '../reducers/context';
import { getPermissions } from '../selectors/permissions';
import PageLayout from './pageLayout';
import ContractInfo from './dashboard/ContractInfo';
import LotCounts from './dashboard/LotCounts';
import CertifiedAndClaimByPeriod from './dashboard/CertifiedAndClaimByPeriod';
import CurrentClaimThisPeriod from './dashboard/CurrentClaimThisPeriod';
import Certified from './dashboard/Certified';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { updatePersistedState } from '../reducers/persistedState';
import DashboardWidget from './dashboard/DashboardWidget';
import UpcomingEvents from './dashboard/UpcomingEvents';
import ProcessingVariations from './dashboard/ProcessingVariations';
import OpenEOTs from './dashboard/OpenEOTs';
import CorrespondenceNeedingAction from './dashboard/CorrespondenceNeedingAction';
import { 
	Dialog, 
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core';
import Button from './widgets/button';
import Checkbox from './widgets/Checkbox';
import FormFieldContainer from './formFieldContainer';

const defaultWidgets = {
	certified: true,
	contractInfo: true,
	lotCounts: true,
	certifiedAndClaimByPeriod: true,
	currentClaimThisPeriod: true,
	correspondenceNeedingAction: true,
	processingVariations: true,
	openEOTs: true,
	upcomingEvents: true
};
const ResponsiveGridLayout = WidthProvider(Responsive);

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
			isLoading: true,
			contract: props.contract,
			isContractSummaryLoading: true,
			contractSummary: null,
			isCertifiedAndClaimSummaryLoading: true,
			certifiedAndClaimSummary: null,
			showWidgetPicker: false,
			widgets: defaultWidgets
        };
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.contract !== nextProps.contract) {
			this.setState(state => ({
				...state,
				isLoading: true,
				contract: nextProps.contract,
				isContractSummaryLoading: true,
				contractSummary: null,
				isCertifiedAndClaimSummaryLoading: true,
				certifiedAndClaimSummary: null,
				widgets: defaultWidgets
			}));

			this.runDashboard(nextProps);
		}
	}
	componentDidMount() {
		this.runDashboard(this.props);
	}

	runDashboard(props) {
		const permissions = props.permissions;
		if (!props.contract || !permissions.ViewDashboard) {
			this.setState(state => ({
				...state,
				isLoading: false,
				isContractSummaryLoading: false,
				isCertifiedAndClaimSummaryLoading: false
			}));
			return;
		}

		const fetchSummary = get({
			url: `/api/dashboard/contract-summary?contractId=${props.contract.contractId}`,
			onSuccess: (data) => { 
				this.setState(state => ({
					...state,
					contractSummary: data,
					isContractSummaryLoading: false
				}));
			}
		});

		const fetchCertifiedAndClaimSummary = get({
			url: `/api/dashboard/certified-and-claim-summary?contractId=${props.contract.contractId}`,
			onSuccess: (data) => { 
				this.setState(state => ({
					...state,
					certifiedAndClaimSummary: data,
					isCertifiedAndClaimSummaryLoading: false
				}));
			}
		});

		axios.all([
			fetchSummary, 
			fetchCertifiedAndClaimSummary
		])
		.then(() => {
			this.setState(
				state => ({
					...state,
					isLoading: false
				})
			);
		})
		.catch((error) => {
			this.setState(
				state => ({
					...state,
					isLoading: false
				})
			);
			console.log('request failed', error);
		});
	}

	onDashboardLayoutChange = (layout, layouts) => {
		this.props.updatePersistedState('HomePage', {
			...this.props.persistedState.HomePage,
			dashboardLayout: layouts
		});
	}
	resetDashboardLayout = () => {
		this.props.updatePersistedState('HomePage', {
			...this.props.persistedState.HomePage,
			dashboardLayout: {},
			dashboardWidgets: defaultWidgets
		});
	}

	render() {
		const persistedState = this.props.persistedState.HomePage || {};
		const widgets = persistedState.dashboardWidgets || defaultWidgets;
		const permissions = this.props.permissions;
		const canViewDashboard = permissions.ViewDashboard;
		const certifiedAndClaimData = this.state.certifiedAndClaimSummary || [];
		const certifiedAndClaimDataByContract = groupBy(certifiedAndClaimData, 'contractId');
		const summaryData = this.state.contractSummary || [];

		const tileStyles = {
			padding: '35px 0px 0px 0px',
			backgroundColor: '#fff',
			// overflow: 'hidden', 
			borderRadius: '4px',
			boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)' 
		};

		const c = summaryData && summaryData.length > 0 ? summaryData[0] : null;
		const certifiedAndClaim = c ? certifiedAndClaimDataByContract[c.contractId] : null;

		const items = [];
		if (widgets.certified) {
			items.push(
				<div key="1" data-grid={{ x: 0, y: 0, w: 11, h: 2 }} style={tileStyles}>
					<DashboardWidget
						title="Certified"
						widget={Certified}
						loading={this.state.isContractSummaryLoading}
						contract={c}
					/>
				</div>
			);
		}
		if (widgets.certifiedAndClaimByPeriod) {
			items.push(
				<div key="2" data-grid={{ x: 0, y: 2, w: 11, h: 4 }} style={tileStyles}>
					<DashboardWidget
						title="Certified $ and Claim $ by Period"
						widget={CertifiedAndClaimByPeriod}
						certifiedAndClaim={certifiedAndClaim}
						loading={this.state.isCertifiedAndClaimSummaryLoading}
					/>
				</div>
			);
		}
		if (widgets.lotCounts) {
			items.push(
				<div key="3" data-grid={{ x: 0, y: 6, w: 5, h: 4 }} style={tileStyles}>
					<DashboardWidget
						title={`Lots ${c ? `(${c.open + c.guaranteed + c.guaranteed1Mth + c.closed} Total)` : ''}`}
						widget={LotCounts}
						loading={this.state.isContractSummaryLoading}
						contract={c}
					/>
				</div>
			);
		}
		if (widgets.currentClaimThisPeriod) {
			items.push(
				<div key="4" data-grid={{ x: 5, y: 6, w: 6, h: 4 }} style={{ ...tileStyles, padding: '35px 10px 5px 0' }}>
					<DashboardWidget
						title="Current Claim Period"
						widget={CurrentClaimThisPeriod}
						certifiedAndClaim={certifiedAndClaim}
						loading={this.state.isCertifiedAndClaimSummaryLoading}
					/>
				</div>
			);
		}
		if (widgets.contractInfo) {
			items.push(
				<div key="5" data-grid={{ x: 11, y: 0, w: 5, h: 10 }} style={tileStyles}>
					<DashboardWidget
						title="Details"
						widget={ContractInfo}
						loading={this.state.isContractSummaryLoading}
						contract={c}
					/>				
				</div>
			);
		}
		if (widgets.correspondenceNeedingAction && permissions.ViewDashboardCorrespondence) {
			items.push(
				<div key="7" data-grid={{ x: 0, y: 11, w: 16, h: 5 }} style={tileStyles}>
					<DashboardWidget
						title="Correspondence Needing Action"
						widget={CorrespondenceNeedingAction}
						contractId={this.props.contract ? this.props.contract.contractId : null}
						changeContractContext={this.props.changeContractContext}
					/>
				</div>
			);
		}
		if (widgets.processingVariations && permissions.ViewVariations && permissions.ViewDashboardVariations) {
			items.push(
				<div key="8" data-grid={{ x: 0, y: 17, w: 9, h: 5 }} style={tileStyles}>
					<DashboardWidget
						title="Processing Variations"
						widget={ProcessingVariations}
						contractId={this.props.contract ? this.props.contract.contractId : null}
						changeContractContext={this.props.changeContractContext}
					/>
				</div>
			);
		}
		if (widgets.openEOTs && permissions.ViewExtensionsOfTime && permissions.ViewDashboardEOTs) {
			items.push(
				<div key="9" data-grid={{ x: 0, y: 23, w: 9, h: 5 }} style={tileStyles}>
					<DashboardWidget
						title="Open EOTs"
						widget={OpenEOTs}
						contractId={this.props.contract ? this.props.contract.contractId : null}
						changeContractContext={this.props.changeContractContext}
					/>
				</div>
			);
		}
		if (widgets.upcomingEvents && permissions.ViewUserEvents) {
			items.push(
				<div key="10" data-grid={{ x: 9, y: 17, w: 7, h: 10 }} style={tileStyles}>
					<DashboardWidget
						title="Upcoming Events"
						widget={UpcomingEvents}
						contractId={this.props.contract ? this.props.contract.contractId : null}
						changeContractContext={this.props.changeContractContext}
					/>

				</div>
			);
		}

		if (!this.props.contractIsSelected) {
			return (
				<PageLayout
					title='Home'
					content={
						<ContentContainer>
							<p>
								Welcome to Contrack. Please select an option in the left menu.
							</p>
						</ContentContainer>
					}
				/>
			);
		}

		return (
<PageLayout
	title={canViewDashboard ? 'Dashboard' : 'Home'}
	isLoading={this.state.isLoading}
	actions={
		[
			{
				label: 'Select Widgets',
				onClick: () => {
					this.setState(state =>({
						...state,
						widgets: widgets,
						showWidgetPicker: true
					}));
				}
			},
			{
				label: 'Reset Layout',
				onClick: this.resetDashboardLayout
			}
		]
	}
	content={
		!canViewDashboard ?
		<ContentContainer>
			<p>
				Welcome to Contrack. Please select an option in the left menu.
			</p>
		</ContentContainer>
		:
		<div>
			<ResponsiveGridLayout 
				className="layout" 
				rowHeight={60} 
				style={{ marginTop: '10px' }}
				containerPadding={[0, 0]}
				breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
				cols={{ lg: 16, md: 16, sm: 16, xs: 16, xxs: 16 }}
				layouts={persistedState.dashboardLayout}
				onLayoutChange={(layout, layouts) =>
					this.onDashboardLayoutChange(layout, layouts)
				}
			>
				{items}
			</ResponsiveGridLayout>
			<Dialog open={this.state.showWidgetPicker} onClose={() => { this.setState(state => ({ ...state, showWidgetPicker: false }))}}>
				<DialogTitle>Select the widgets to display</DialogTitle>
				<DialogContent>
					<FormFieldContainer size={12}>
						<Checkbox 
							label="Certified" 
							checked={this.state.widgets.certified || false} 
							onChange={(e) => 
								this.setState(state => ({ 
									...state,
									widgets: {
										...state.widgets,
										certified: e.target.checked
									}
								}))
							} 
						/>
					</FormFieldContainer>
					<FormFieldContainer size={12}>
						<Checkbox 
							label="Contract Details" 
							checked={this.state.widgets.contractInfo || false} 
							onChange={(e) => 
								this.setState(state => ({ 
									...state,
									widgets: {
										...state.widgets,
										contractInfo: e.target.checked
									}
								}))
							}
						/>
					</FormFieldContainer>
					<FormFieldContainer size={12}>
						<Checkbox	 
							label="Certified $ and Claim $ by Period"
							checked={this.state.widgets.certifiedAndClaimByPeriod || false} 
							onChange={(e) => 
								this.setState(state => ({ 
									...state,
									widgets: {
										...state.widgets,
										certifiedAndClaimByPeriod: e.target.checked
									}
								}))
							}
						/>
					</FormFieldContainer>
					<FormFieldContainer size={12}>
						<Checkbox 
							label="Current Claim Period"
							checked={this.state.widgets.currentClaimThisPeriod || false} 
							onChange={(e) => 
								this.setState(state => ({
									...state,
									widgets: {
										...state.widgets,
										currentClaimThisPeriod: e.target.checked
									}
								}))
							}
						/>
					</FormFieldContainer>
					<FormFieldContainer size={12}>
						<Checkbox 
							label="Lots" 
							checked={this.state.widgets.lotCounts || false} 
							onChange={(e) => 
								this.setState(state =>({ 
									...state,
									widgets: {
										...state.widgets,
										lotCounts: e.target.checked
									}
								}))
							}
						/>
					</FormFieldContainer>
					<FormFieldContainer size={12}>
						<Checkbox 
							label="Correspondence Needing Action" 
							checked={this.state.widgets.correspondenceNeedingAction || false} 
							onChange={(e) => 
								this.setState(state =>({ 
									...state,
									widgets: {
										...state.widgets,
										correspondenceNeedingAction: e.target.checked
									}
								}))
							}
						/>
					</FormFieldContainer>
					<FormFieldContainer size={12}>
						<Checkbox 
							label="Processing Variations" 
							checked={this.state.widgets.processingVariations || false} 
							onChange={(e) => 
								this.setState(state => ({ 
									...state,
									widgets: {
										...state.widgets,
										processingVariations: e.target.checked
									}
								}))
							}
						/>
					</FormFieldContainer>
					<FormFieldContainer size={12}>
						<Checkbox 
							label={`Open EOTs`}
							checked={this.state.widgets.openEOTs || false} 
							onChange={(e) => 
								this.setState(state => ({ 
									...state,
									widgets: {
										...state.widgets,
										openEOTs: e.target.checked
									}
								}))
							}
						/>
					</FormFieldContainer>
					<FormFieldContainer size={12}>
						<Checkbox 
							label={`Upcoming Events`}
							checked={this.state.widgets.upcomingEvents || false} 
							onChange={(e) => 
								this.setState(state => ({ 
									...state,
									widgets: {
										...state.widgets,
										upcomingEvents: e.target.checked
									}
								}))
							}
						/>
					</FormFieldContainer>
				</DialogContent>
				<DialogActions>
					<Button 
						primary={true} 
						onClick={() => {
							this.setState(
								state => ({
									...state, 
									showWidgetPicker: false
								}),
								() => {
									this.props.updatePersistedState('HomePage', {
										...this.props.persistedState.HomePage,
										dashboardWidgets: this.state.widgets
									});
								}
							);
						}}
					>
						Ok
					</Button>
					<Button onClick={() => { this.setState(state =>({ ...state, showWidgetPicker: false }))}}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	}
/>
    );
  }
}

Home.propTypes = {
	contract: PropTypes.object.isRequired,
	contractIsSelected: PropTypes.bool.isRequired,
	changeContractContext: PropTypes.func.isRequired,
	permissions: PropTypes.object.isRequired,
	persistedState: PropTypes.object.isRequired,
	updatePersistedState: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	persistedState: state.persistedState,
	contract: state.context.contract,
	contractIsSelected: !!state.context.contract,
	permissions: getPermissions(state)
});

const mapDispatchToProps = (dispatch) => ({
	changeContractContext: (contractId) => {
		dispatch(changeContractContext(contractId));
	},
	updatePersistedState: (key, data) => {
		dispatch(updatePersistedState(key, data));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Home);
