import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import ContentContainer from '../contentContainer';
import MessagePanel from '../messagePanel';
import Wizard from '../wizard/wizard';
import DisplayField from '../widgets/DisplayField';
import FormSectionHeader from '../widgets/formSectionHeader';
import { 
	Grid,
	Table, 
	TableHead,
	TableBody, 
	TableRow, 
	TableCell 
} from '@material-ui/core';
import UserField from './NewTenant/UserField';
import EmployeeField from './NewTenant/EmployeeField';
import ContactField from './NewTenant/ContactField';
import ContractorField from './NewTenant/ContractorField';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import { map } from 'lodash';

const form = {
	initValues: ({ 
	}) => ({ 
		name: '',
		maxContracts: 0,
		maxUsers: 0,
		maxData: 0
	}),
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
		},
		{
			name: "maxContracts",
			type: 'numeric',
			label: "Maximum number of Contracts allowed",
			helpText: "Set to 0 for no limit"
		},
		{
			name: "maxUsers",
			type: 'numeric',
			label: "Maximum number of Users allowed",
			helpText: "Set to 0 for no limit"
		},
		{
			name: "maxData",
			type: 'numeric',
			numberType: 'decimal',
			label: "Maximum GB of data the tenant is allowed",
			helpText: "Set to 0 for no limit"
		},
		{
			name: "users",
			label: "Users",
			type: "custom",
			defaultValue: [],
			validate: (value, values) => {
				return value.length > 0 ? "" : "At least one user is required";
			},
			widget: UserField
		},
		// {
		// 	name: "employees",
		// 	label: "Employees",
		// 	type: "custom",
		// 	defaultValue: [],
		// 	validate: (value, values) => {
		// 		return true;
		// 	},
		// 	widget: EmployeeField
		// },
		// {
		// 	name: "contacts",
		// 	label: "Contacts",
		// 	type: "custom",
		// 	defaultValue: [],
		// 	validate: (value, values) => {
		// 		return true;
		// 	},
		// 	widget: ContactField
		// },
		{
			name: "contractors",
			label: "Contractors",
			type: "custom",
			defaultValue: [],
			validate: (value, values) => {
				return true;
			},
			widget: ContractorField
		}
	]
};

const NoDataText = ({ text }) => 
	<p style={{
		fontStyle: 'italic',
		marginLeft: '5px'
	}}>{text}</p>;

const NewTenant = ({ 
	fields,
	values,
	validateFields,
	loading, 
	success,
	saveResult,
	error,
	onComplete
}) => {
	const errors = [];
	forEach(Object.keys(error), (fieldName, i) => {
		if (error[fieldName]) {
			errors.push(`${find(form.fields, f => f.name === fieldName).label}: ${error[fieldName]}`);
		}
	});

	return (
	<div>
		<ContentContainer>
			<Wizard 
				loading={loading}
				success={success}
				errors={errors}
				submitLabel="Create"
				onComplete={(onSuccess, onError) => {
					onComplete(
						values, 
						() => {},
						(error) => { onError(); }
					);
				}}
				steps={[
					{ 
						title: 'Tenant', 
						validate: () => {
							const fields = ["name", 'maxContracts', 'maxUsers', 'maxData'];
							return validateFields(fields);
						},
						content:
							<Grid container spacing={3}>
								<Grid item xs={12}>{fields.name()}</Grid>
								<Grid item xs={12} md={6}>{fields.maxContracts()}</Grid>
								<Grid item xs={12} md={6}>{fields.maxUsers()}</Grid>
								<Grid item xs={12} md={6}>{fields.maxData()}</Grid>
							</Grid>
					},
					{ 
						title: 'Users', 
						validate: () => validateFields(["users"]),
						content: 
							<React.Fragment>
								<p>You must have at least one User</p>
								<Grid container spacing={3}>
									<Grid item xs={12}>{fields.users()}</Grid>
								</Grid>
							</React.Fragment>
					},
					// { 
					// 	title: 'Employees', 
					// 	content: 
					// 		<React.Fragment>
					// 			<Grid container spacing={3}>
					// 				<Grid item xs={12}>{fields.employees()}</Grid>
					// 			</Grid>
					// 		</React.Fragment>
					// },
					// { 
					// 	title: 'Contacts', 
					// 	content: 
					// 		<React.Fragment>
					// 			<Grid container spacing={3}>
					// 				<Grid item xs={12}>{fields.contacts()}</Grid>
					// 			</Grid>
					// 		</React.Fragment>
					// },
					{ 
						title: 'Contractors', 
						content: 
							<React.Fragment>
								<p>
									Adding Contractors is optional. They can be created at after tenant creation.
								</p>
								<Grid container spacing={3}>
									<Grid item xs={12}>{fields.contractors()}</Grid>
								</Grid>
							</React.Fragment>
					},
					{ 
						title: 'Review', 
						content: 
							<div>
								<p>
									Review the new tenant below then click Create.
								</p>
								<DisplayField label="Tenant Name" value={values.name} />
								<FormSectionHeader text="Users" />
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Username</TableCell>
											<TableCell>Email</TableCell>
											<TableCell>Position</TableCell>
											<TableCell>Role</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{map(values.users, (u, i) =>
											<TableRow key={`user-${i}`}>
												<TableCell>{u.userName}</TableCell>
												<TableCell>{u.email}</TableCell>
												<TableCell>{u.position}</TableCell>
												<TableCell>{u.userRole}</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
								{/* <FormSectionHeader text="Employees" />
								{values.employees.length === 0 ? <NoDataText text="No Employees" /> : 
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell>Email</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{map(values.employees, (u, i) =>
												<TableRow key={`employee-${i}`}>
													<TableCell>{u.name}</TableCell>
													<TableCell>{u.email}</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								}
								<FormSectionHeader text="Contacts" />
								{values.contacts.length === 0 ? <NoDataText text="No Contacts" /> : 
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell>Email</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{map(values.contacts, (u, i) =>
												<TableRow key={`contact-${i}`}>
													<TableCell>{u.name}</TableCell>
													<TableCell>{u.email}</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								} */}
								<FormSectionHeader text="Contractors" />
								{values.contractors.length === 0 ? <NoDataText text="No Contractors" /> : 
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell>Trading Name</TableCell>
												<TableCell>ABN</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{map(values.contractors, (u, i) =>
												<TableRow key={`contractor-${i}`}>
													<TableCell>{u.name}</TableCell>
													<TableCell>{u.tradingName}</TableCell>
													<TableCell>{u.abn}</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								}
							</div>
					}
				]} 
			/>
		</ContentContainer>

		<MessagePanel isSuccess={saveResult.success} message={saveResult.message} />
	</div>
	);
}

NewTenant.propTypes = {
};

export default withForm(form)(NewTenant);
