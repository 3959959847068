import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import OutgoingCorrespondenceGrid from './outgoingCorrespondenceGrid';
import { showModal } from '../../reducers/modal';
import { 
	saveOutgoingCorrespondence, 
	deleteOutgoingCorrespondence
} from '../../reducers/outgoingCorrespondence';
import ContentContainer from '../contentContainer';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import GenerateOutgoingCorrespondenceDialog from './generateOutgoingCorrespondenceDialog';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import PageLayout from '../pageLayout';
import { getContractId } from '../../selectors/contract';
import { getPeriodId } from '../../selectors/period';
import { globals } from '../../globals';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import axios from 'axios';
import { get } from '../../utils/ajax';
import { useMountEffect } from '../../utils/utils';

const OutgoingCorrespondence = props => {
	const [state, setState] = React.useState({
		correspondences: [],
		employees: [],
		isLoading: false,
		showGenerateDialog: false
	});
	const [tags, setTags] = React.useState([]);
	
	useMountEffect(() => {
		const contractId = props.contractId;
		const periodId = props.periodId;
		const request = axios.CancelToken.source();

		setState(prevState => ({
			...prevState,
			isLoading: true
		}));

		get({
			url:`/api/outgoing-correspondence?contractId=${contractId}&periodId=${periodId}`,
			cancelToken: request.token,
			onSuccess: data => {
				setState(prevState => ({
					...prevState,
					correspondences: data,
					isLoading: false
				}));
			},
			onError: error => {
				if (!axios.isCancel(error)) {
					setState(prevState => ({
						...prevState,
						correspondences: [],
						isLoading: false
					}));
					props.showError(error.message);
				}
			}
		});

		get({
			url: `/api/employees/all?contractId=${contractId}&periodId=${periodId}`,
			cancelToken: request.token,
			onSuccess: (data) => {
				setState(prevState => ({
					...prevState,
					employees: data
				}));
			}
		});

		get({
			url:`/api/outgoing-correspondence/tags?contractId=${contractId}`,
			cancelToken: request.token,
			onSuccess: data => {
				setTags(data);
			},
			onError: error => {
			}
		});

		return () => {
			request.cancel();
		};
	});

	const onEditCorrespondence = (correspondence) => {
		props.history.push(`/outgoing-correspondence/${correspondence.outgoingCorrespondenceId}`);
	};
	const onViewCorrespondence = (correspondence) => {
		props.history.push(`/outgoing-correspondence/${correspondence.outgoingCorrespondenceId}`);
	};

	const onDeleteCorrespondence = (correspondence) => {
		props.deleteOutgoingCorrespondence(correspondence, (data) => {
			if (data.success) {
				setState(prevState => ({
					...prevState,
					correspondences: filter(state.correspondences, c => c.outgoingCorrespondenceId !== data.objectId),
					isLoading: false
				}));
				props.showSuccess(data.message);
			}
		});
	};

	const registerNewCorrespondence = () => {
		props.history.push('/outgoing-correspondence');
	};

	const generateCorrespondenceNumber = () => {
		setState(prevState => ({
			...prevState,
			isLoading: true
		}));

		get({
			url: `/api/correspondence/get-outgoing-correspondence-number?contractId=${props.contract.contractId}`,
			onSuccess: (data) => {
				setState(prevState => ({
					...prevState,
					isLoading: false,
					showGenerateDialog: true,
					correspondenceNumber: data
				}));
			},
			onError: error => {
				setState(prevState => ({
					...prevState,
					isLoading: false,
					showGenerateDialog: false
				}));
				props.showError(error.message);
			}
		});
	};

	const onHoldCorrespondenceNumber = (correspondenceNumber, docTitle, from) => {
		setState(prevState => ({
			...prevState,
			showGenerateDialog: false
		}));
		const correspondence = {
			...cloneDeep(globals.templates.outgoingCorrespondence),
			rfiNumber: correspondenceNumber,
			docTitle: docTitle,
			from: from
		};
		props.saveOutgoingCorrespondence(correspondence, (data) => {
			props.showSuccess(data.message);
		});
	};

	return (
<PageLayout
	title="Outgoing Correspondence"
	isLoading={state.isLoading}
	actions={
		props.canEdit ? 
		[
			{
				label: 'Register Outgoing Correspondence',
				onClick: registerNewCorrespondence
			},
			{
				label: 'Generate Correspondence Number',
				onClick: generateCorrespondenceNumber
			}
		] : []
	}
	content={
    <div>
		<GenerateOutgoingCorrespondenceDialog 
			show={state.showGenerateDialog} 
			correspondenceNumber={state.correspondenceNumber}
			onHold={onHoldCorrespondenceNumber} 
			employees={state.employees}
			onClose={() => setState(prevState => ({ ...prevState, showGenerateDialog: false }))} 
		/>

		<ContentContainer>
			<OutgoingCorrespondenceGrid 
				correspondences={state.correspondences} 
				onEditCorrespondence={onEditCorrespondence} 
				onDeleteCorrespondence={onDeleteCorrespondence} 
				onViewCorrespondence={onViewCorrespondence} 
				canView={props.canView}
				canEdit={props.canEdit}
				tags={tags}
			/>
		</ContentContainer>
	</div>
	}
/>
		);
};

OutgoingCorrespondence.propTypes = {
	dispatch: PropTypes.func.isRequired,
	contractId: PropTypes.number.isRequired,
	periodId: PropTypes.number.isRequired,
	contract: PropTypes.object.isRequired,
	saveOutgoingCorrespondence: PropTypes.func.isRequired,
	deleteOutgoingCorrespondence: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	showSuccess: PropTypes.func.isRequired,
	showError: PropTypes.func.isRequired
};

OutgoingCorrespondence.defaultProps = {
};

const mapStateToProps = (state) => ({
	contractId: getContractId(state),
	periodId: getPeriodId(state),
	contract: state.context.contract,
	username: state.context.user.userName,
	canView: canView(state, 'ViewOutgoingCorrespondenceItem'),
	canEdit: canEdit(state, 'EditOutgoingCorrespondence')
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	saveOutgoingCorrespondence: (correspondence, onSuccess) => {
		dispatch(saveOutgoingCorrespondence(correspondence, null, (data) => {
			if (onSuccess) onSuccess(data);
		}));
	},
	deleteOutgoingCorrespondence: (correspondence, onSuccess) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this correspondence?',
			onOk: () => {
				dispatch(deleteOutgoingCorrespondence(correspondence, onSuccess));
			}
		}));
	},
	showSuccess: (message) => {
		dispatch(showSuccessNotification(message));
	},
	showError: (error) => {
		dispatch(showErrorNotification(error));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(OutgoingCorrespondence);
