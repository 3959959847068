import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import DateField from '../widgets/DateField';
import ContentContainer from '../contentContainer';
import PersistedDataTable from '../widgets/persistedDataTable';
import Checkbox from '../widgets/Checkbox';
import  { addOrUpdate } from '../../utils/utils';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import Paper from '@material-ui/core/Paper';
import SelectField from '../widgets/selectField';
import FormSectionHeader from '../widgets/formSectionHeader';
import NumericField from '../widgets/numericField';

class PracticalCompletionForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			practicalCompletion: props.practicalCompletion,
			appliesTo: !props.practicalCompletion || !props.practicalCompletion.separablePortions || props.practicalCompletion.separablePortions.length === 0 ? 'EntireContract' : 'SeparablePortions'
		};

		this.selectSeparablePortion = this.selectSeparablePortion.bind(this);
		this.selectAppliesTo = this.selectAppliesTo.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			practicalCompletion: nextProps.practicalCompletion,
			appliesTo: !nextProps.practicalCompletion || !nextProps.practicalCompletion.separablePortions || nextProps.practicalCompletion.separablePortions.length === 0 ? 'EntireContract' : 'SeparablePortions'
		});
	}
	selectSeparablePortion(sp, selected) {
		const practicalCompletion = this.state.practicalCompletion;
		if (selected) {
			practicalCompletion.separablePortions = addOrUpdate(practicalCompletion.separablePortions, sp, (s) => sp.separablePortionId === s.separablePortionId);
		} else {
			remove(practicalCompletion.separablePortions, (s) => s.separablePortionId === sp.separablePortionId);
		}
		this.setState({
			practicalCompletion: practicalCompletion
		});
	}
	selectAppliesTo(appliesTo) {
		const practicalCompletion = this.state.practicalCompletion;
		if (appliesTo === 'EntireContract') practicalCompletion.separablePortions = [];
		this.setState({
			appliesTo: appliesTo,
			practicalCompletion: practicalCompletion
		});
	}
	isAllSelected = () => {
		const pc = this.state.practicalCompletion;
		const sps = this.props.separablePortions || [];

		for (let i = 0, ii = sps.length; i < ii; i++) {
			const sp = sps[i];
			const selected = some(pc.separablePortions, sp2 => sp2.separablePortionId === sp.separablePortionId);
			if (!selected) return false;
		}
		return true;
	}
	toggleAll = () => {
		const nextValue = !this.isAllSelected();

		const pc = this.state.practicalCompletion;
		const sps = this.props.separablePortions || [];
		forEach(sps, (sp) => {
			if (nextValue) {
				pc.separablePortions = addOrUpdate(pc.separablePortions, sp, (s) => sp.separablePortionId === s.separablePortionId);
			} else {
				remove(pc.separablePortions, (s) => s.separablePortionId === sp.separablePortionId);
			}
		});
		this.setState({
			practicalCompletion: pc
		});
	}

	render() {
		const practicalCompletion = this.state.practicalCompletion;
		const separablePortions = this.props.separablePortions ? this.props.separablePortions : [];
		forEach(separablePortions, (s) => {
			s._isSelected = some(practicalCompletion.separablePortions, (s2) => s2.separablePortionId === s.separablePortionId);
		});
		const spColumns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				value: (args) => args.data._isSelected || false,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected()}
						onCheck={() => { this.toggleAll(); } }
					/>,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectSeparablePortion(args.data, v); } }
					/> 
			},
			{ name: 'name', label: 'Name' },
			{ name: 'practicalCompletionDate', label: 'Practical Completion', dataType: 'date' }
		];

		return (
<div>
	<ContentContainer>
		<FormRow>
			<FormFieldContainer classes={['s12', 'm6']}>
				<DateField
					value={practicalCompletion.completionDate} 
					label="Completion Date" 
					onChange={(d) => { this.props.updateField('completionDate', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={['s12', 'm6']}>
				<NumericField
					type="int" 
					value={practicalCompletion.maintenancePeriod} 
					label="Maintenance Period" 
					onChange={(e, v) => { this.props.updateField('maintenancePeriod', v); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer>
				<TextField
					value={practicalCompletion.comments}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('comments', v); }}
					label="Comments"
					errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
				/>
			</FormFieldContainer>
		</FormRow>
	</ContentContainer>

	{separablePortions.length > 0 &&
		<Paper style={{ padding: '10px', marginBottom: '10px' }}>
			<FormSectionHeader text="Applies to" />
			<SelectField 
				value={this.state.appliesTo} 
				onChange={(v) => { this.selectAppliesTo(v); } } 
				fullWidth={true}
				items={[
					{ value: 'EntireContract', label: 'Entire Contract' },
					{ value: 'SeparablePortions', label: 'Separable Portios' }
				]}
			>
			</SelectField>

			{this.state.appliesTo === 'SeparablePortions' &&
				<PersistedDataTable 
					id="practicalCompletionSeparablePoritions"
					data={separablePortions || []} 
					columns={spColumns}
				/>
			}
		</Paper>
	}
</div>
		);
	}
}

PracticalCompletionForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	practicalCompletion: PropTypes.object.isRequired,
	separablePortions: PropTypes.array.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default PracticalCompletionForm;
