// Constant defining the action type
const TOGGLE_SIDEPANEL = 'TOGGLE_SIDEPANEL';
const TOGGLE_FULLSCREEN_SIDEPANEL = 'TOGGLE_FULLSCREEN_SIDEPANEL';
const CLOSE_FULLSCREEN_SIDEPANEL = 'CLOSE_FULLSCREEN_SIDEPANEL';
const HIDE_SIDEPANELS = 'HIDE_SIDEPANELS';

// Action creator
export const toggleSidePanel = () => ({ type: TOGGLE_SIDEPANEL });
export const toggleFullScreenSidePanel = () => ({ type: TOGGLE_FULLSCREEN_SIDEPANEL });
export const closeFullScreenSidePanel = () => ({ type: CLOSE_FULLSCREEN_SIDEPANEL });
export const hideSidePanels = () => ({ type: TOGGLE_SIDEPANEL });

const initialState = {
	collapseSidePanel: false,
	fullScreenSidePanel: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_SIDEPANEL:
			return {
				...state,
				collapseSidePanel: !state.collapseSidePanel
			};
		case TOGGLE_FULLSCREEN_SIDEPANEL:
			return {
				...state,
				fullScreenSidePanel: !state.fullScreenSidePanel
			};
		case CLOSE_FULLSCREEN_SIDEPANEL:
			return {
				...state,
				fullScreenSidePanel: false
			};
		case HIDE_SIDEPANELS:
			return {
				...state,
				collapseSidePanel: true,
				fullScreenSidePanel: false
			};			
		default:
			return state;
	}
};
