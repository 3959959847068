
import Notifications from 'react-notification-system-redux';

const initialState = {
	show: false,
	message: ''
};

const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

export const showNotification = (show, message) => ({ type: SHOW_NOTIFICATION, show, message });
export const showSuccessNotification = (title, message) => (
	Notifications.success({
		title,
		message,
		position: 'tc'
	})
);
export const showErrorNotification = (title, message) => (
	Notifications.error({
		title,
		message,
		position: 'tc'
	})
);
export const showWarningNotification = (title, message) => (
	Notifications.warning({
		title,
		message,
		position: 'tc'
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOW_NOTIFICATION:
			return {
				...state,
				show: action.show,
				message: action.message
			};
		default:
			return state;
	}
};
