import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveEvent, 
	clearEvent, 
	requestEvent, 
	createNewEvent 
} from '../../reducers/events';
import EventForm from './eventForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class EventMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			event: props.event,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
	}
	static fetchData(dispatch, props) {
		if (props.match.params.eventId) {
			dispatch(requestEvent(props.match.params.eventId));
		} else {
			dispatch(createNewEvent());
		}
	}
	static clearData(dispatch) {
		dispatch(clearEvent());
	}
	componentDidMount() {
		EventMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			event: nextProps.event,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const event = this.state.event;
		event[field] = newValue;
        this.setState({
			event,
			hasUnsavedChanges: true
        });
	}

	render() {
		const event =  this.state.event || {};

		return (
<PageLayout
	title={event.name}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.event),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.event),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<EventForm 
			event={event} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

EventMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	event: PropTypes.object,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

EventMaintenance.defaultProps = {
	event: null
};

const mapStateToProps = (state) => ({
	event: state.events.event,
	canEdit: canEditAnyPeriod(state, 'EditUserEvents'),
	isLoading: state.events.isLoading,
	saveResult: state.events.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (event) => {
		dispatch(saveEvent(event, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (event) => {
		dispatch(saveEvent(event, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(EventMaintenance);
