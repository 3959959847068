import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import PageLayout from '../pageLayout';
import NewContract from './NewContract';
import { get, post } from '../../utils/ajax';
import { useMountEffect, formatDate } from '../../utils/utils';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import cloneDeep from 'lodash/cloneDeep';
import { globals } from '../../globals';
import { emptySaveResult } from '../../variables';
import { updateContract } from '../../reducers/context';

const NewContractController = ({ history, showSuccessNotification, showErrorNotification, updateContext }) => {
	const [loading, setLoading] = React.useState(false);
	const [contract, setContract] = React.useState({
		...cloneDeep(globals.templates.newContract)
	});
	const [saveResult, setSaveResult] = React.useState(emptySaveResult);

	useMountEffect(() => {
		setLoading(true);
		get({
			url: `/api/contracts/new`,
			onSuccess: data => {
				setLoading(false);
				const newContract = {
					...data,
					contractor: {
						contractorId: -1,
						name: "",
						tradingName: "",
						abn: "",
						address: ""
					}
				};
				newContract.period.periodName = formatDate(new Date(), 'MMMM yy');
				setContract(newContract);
			},
			onError: error => {
				setLoading(false);
				showErrorNotification(error.message);
			}
		});
	});

	return (
		<PageLayout
			className='new-contract'
			title="New Contract Wizard"
			isLoading={loading}
			content={
				<NewContract 
					contract={contract}
					loading={loading}
					saveResult={saveResult}
					clearSaveResult={() => setSaveResult(emptySaveResult)}
					onComplete={(values, onSuccess, onError) => {
						setLoading(true);
						post({
							url: `/api/contracts/new`,
							data: values,
							onSuccess: data => {
								setLoading(false);
								setSaveResult(data);

								if (data.success) {
									showSuccessNotification(data.message);
									if (onSuccess) onSuccess();
									updateContext(data.object);
									history.push("/");
								} else {
									showErrorNotification(data.message);
									if (onError) onError();
								}
							},
							onError: error => {
								setLoading(false);
								showErrorNotification(error.message);
								if (onError) onError();
							}
						});
					}}
				/>
			}
		/>
	);
};


NewContractController.propTypes = {
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	updateContext: contract => dispatch(updateContract(contract, true))
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(NewContractController);
