import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveEmployee, 
	requestEmployee, 
	createNewEmployee, 
	clearEmployee 
} from '../../reducers/employees';
import EmployeeForm from './employeeForm';
import ContentContainer from '../contentContainer';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class EmployeeMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			employee: props.employee,
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
	}
	static fetchData(dispatch, props) {
		if (props.match.params.employeeId) {
			dispatch(requestEmployee(props.match.params.employeeId));
		} else {
			dispatch(createNewEmployee());
		}
	}
	static clearData(dispatch) {
		dispatch(clearEmployee());
	}
	componentDidMount() {
		EmployeeMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			employee: nextProps.employee,
			hasUnsavedChanges: false
		});
	}

	updateField(field, newValue) {
		const employee = this.state.employee;
		employee[field] = newValue;
        this.setState({
			employee,
			hasUnsavedChanges: true
        });
	}

	render() {
		const employee = this.state.employee || {};

		return (
<PageLayout
	title={employee.name}
	isLoading={this.props.isLoading}
	formActions={{
		isLoading: this.props.isLoading,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.employee),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.employee),
		onCancel: () => this.props.history.goBack()
	}}
	content={
		<div>
		<ContentContainer>
			<EmployeeForm employee={employee} updateField={this.updateField} saveResult={this.props.saveResult} />
		</ContentContainer>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
		</div>
	}
/>
		);
	}
}

EmployeeMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	employee: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	canEdit: canEditAnyPeriod(state, 'EditEmployees'),
	employee: state.employees.employee,
	isLoading: state.employees.isLoading,
	saveResult: state.employees.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (employee) => {
		dispatch(saveEmployee(employee, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (employee) => {
		dispatch(saveEmployee(employee, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(EmployeeMaintenance);
