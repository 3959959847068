import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverDateField from '../components/handoverDateField';
import HandoverBooleanField from '../components/handoverBooleanField';
import HandoverMemoField from '../components/handoverMemoField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';

class NCRHandoverContent extends React.Component {
	render() {
		const ncr = this.props.report;
		
		const columns = [
			{ name: 'lotNo', label: 'Lot' },
			{ name: 'lotDescription', label: 'Description' }
		];

		const linkedCorrespondence = ncr.linkedCorrespondence;
		
		const linkedCorrespondenceColumns = [
			{ name: 'correspondenceType', label: 'Type' },
			{ name: 'correspondenceNumber', label: 'Number' },
			{ name: 'correspondenceTitle', label: 'Title' }
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Contractor NCR Number" value={ncr.contractorNcrNo} />
				<HandoverField label="CAR Ref No" value={ncr.carRefNo} />
				<HandoverDateField label="Date Identified" value={ncr.dateIdentified} />
				<HandoverDateField label="Date CAR Raised" value={ncr.dateCarRaised} />
				<HandoverDateField label="Date NCR Raised" value={ncr.dateNcrRaised} />
				<HandoverField label="Details" value={ncr.details} />
				<HandoverField label="Corrective Action" value={ncr.correctiveAction} />
				<HandoverField label="Preventative Action" value={ncr.preventativeAction} />
				<HandoverMemoField label="Comments" value={ncr.comments} />
				<HandoverBooleanField label="Accepted" value={ncr.accepted} />
				<HandoverDateField label="Response Date" value={ncr.responseDate} />
				<HandoverDateField label="Date Resubmitted" value={ncr.dateResubmitted} />
				<HandoverDateField label="Date Closed" value={ncr.dateClosed} />
				<HandoverField label="Status" value={ncr.status} />
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Lots</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={ncr.lots || []}
					columns={columns}
				/>
			</div>


			<div className="handover-items">
				<HandoverSectionHeader>Linked Correspondence</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={linkedCorrespondence || []}
					columns={linkedCorrespondenceColumns}
				/>
			</div>
			
		</div>
    );
  }
}

NCRHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default NCRHandoverContent;
