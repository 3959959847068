import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import { globals } from '../globals';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	employee: null, 
	employees: null
};

const CLEAR_EMPLOYEES = 'CLEAR_EMPLOYEES';
const CLEAR_EMPLOYEE = 'CLEAR_EMPLOYEE';
const REQUEST_EMPLOYEE = 'REQUEST_EMPLOYEE';
const RECEIVE_EMPLOYEE = 'RECEIVE_EMPLOYEE';
const REQUEST_EMPLOYEES = 'REQUEST_EMPLOYEES';
const RECEIVE_EMPLOYEES = 'RECEIVE_EMPLOYEES';
const CREATE_NEW_EMPLOYEE = 'CREATE_NEW_EMPLOYEE';
const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
const RECEIVE_SAVE_EMPLOYEE_RESPONSE = 'RECEIVE_SAVE_EMPLOYEE_RESPONSE';
const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
const RECEIVE_DELETE_EMPLOYEE_RESPONSE = 'RECEIVE_DELETE_EMPLOYEE_RESPONSE';
const SHOW_EMPLOYEE_ERRORS = 'SHOW_EMPLOYEE_ERRORS';
const RECEIVE_EMPLOYEE_ERROR_RESPONSE = 'RECEIVE_EMPLOYEE_ERROR_RESPONSE';

export const clearEmployees = () => ({ type: CLEAR_EMPLOYEES });
export const clearEmployee = () => ({ type: CLEAR_EMPLOYEE });
export const receiveEmployees = (data) => ({ type: RECEIVE_EMPLOYEES, payload: { data } });
export const receiveEmployee = (data) => ({ type: RECEIVE_EMPLOYEE, payload: { data } });
export const createNewEmployee = () => ({ type: CREATE_NEW_EMPLOYEE });
export const receiveSaveEmployeeResponse = (data) => ({ type: RECEIVE_SAVE_EMPLOYEE_RESPONSE, data });
export const receiveDeleteEmployeeResponse = (data) => ({ type: RECEIVE_DELETE_EMPLOYEE_RESPONSE, data });
export const receiveErrorResponse = (error) => ({ type: RECEIVE_EMPLOYEE_ERROR_RESPONSE, error });

export const requestEmployees = (isActive = null) => (
	createFetchAction({
		objectName: 'Employees',
		passContext: true,
		url: isActive === null ? '/api/employees/all' : '/api/employees',
		startAction: REQUEST_EMPLOYEES,
		onError: (response) => receiveErrorResponse(response),
		success: (data) => receiveEmployees(data)
	})
);

export const requestEmployee = (employeeId) => (
	createFetchAction({
		objectName: 'Employee',
		passContext: true,
		url: `/api/employees/${employeeId}`,
		startAction: REQUEST_EMPLOYEE,
		onError: (response) => receiveErrorResponse(response),
		success: (data) => receiveEmployee(data)
	})
);

export const saveEmployee = (employee, onSuccess) => {
	const errors = [];
	if (!employee.name) {
		errors.push({
			fieldName: 'Name',
			valid: false,
			message: 'Name is required'
		});
	}
	if (errors.length > 0) return { type: SHOW_EMPLOYEE_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/employees',
		data: employee,
		startAction: SAVE_EMPLOYEE,
		onError: (response) => receiveErrorResponse(response),
		success: (data, dispatch) => {
			dispatch(receiveSaveEmployeeResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteEmployee = (employee) => (
	createPostAction({
		passContext: true,
		url: `/api/employees/${employee.employeeId}/delete`,
		data: employee,
		startAction: DELETE_EMPLOYEE,
		success: (data) => receiveDeleteEmployeeResponse(data)
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_EMPLOYEES:
			return {
				...state,
				employees: null
			};
		case CLEAR_EMPLOYEE:
			return {
				...state,
				employee: null
			};
		case RECEIVE_EMPLOYEE_ERROR_RESPONSE:
			return {
				...state,
				isLoading: false,
				result: {
					success: false,
					message: action.error.message,
					errors: [] 
				}
			};
		case REQUEST_EMPLOYEES:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				employees: []
			};
		case RECEIVE_EMPLOYEES:
			return {
				...state,
				isLoading: false,
				employees: action.payload.data
			};
		case REQUEST_EMPLOYEE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				employee: { ...cloneDeep(globals.templates.employee) }
			};
		case RECEIVE_EMPLOYEE:
			return {
				...state,
				isLoading: false,
				employee: action.payload.data
			};
		case DELETE_EMPLOYEE:
			return { 
				...state
			};
		case RECEIVE_DELETE_EMPLOYEE_RESPONSE:
			return {
				...state,
				employees: state.employees.filter(c => c.employeeId !== action.data.objectId) 
			};
		case CREATE_NEW_EMPLOYEE:
			return {
				...state,
				employee: { ...cloneDeep(globals.templates.employee) }
			};	
		case SAVE_EMPLOYEE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_EMPLOYEE_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				employees: addOrUpdate(state.employees, action.data.object, { employeeId: action.data.object.employeeId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_EMPLOYEE_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
