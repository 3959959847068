import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import CircularProgress from '@material-ui/core/CircularProgress';
import EventsGrid from './eventsGrid';
import { 
	deleteEvent, 
	requestEvents, 
	clearEvents 
} from '../../reducers/events';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {
			events: {}
        };

		this.onEditEvent = this.onEditEvent.bind(this);
		this.onViewEvent = this.onViewEvent.bind(this);
		this.onDeleteEvent = this.onDeleteEvent.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestEvents());
	}
	static clearData(dispatch) {
		dispatch(clearEvents());
	}
	componentDidMount() {
		Events.fetchData(this.props.dispatch);
	}

	onEditEvent = (event) => {
		this.props.history.push(`/event/${event.userEventId}`);
	}

	onViewEvent = (event) => {
		this.props.history.push(`/event/${event.userEventId}`);
	}
	onDeleteEvent = (event) => {
		this.props.deleteEvent(event);
	}

	newEvent = () => {
		this.props.history.push('/event');
	}

	render() {
		return (
<PageLayout
	title="Events"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Event',
				onClick: this.newEvent
			}
		] : []
	}
	content={
		<ContentContainer>
			{this.props.isLoading &&
				<CircularProgress />
			}
			<EventsGrid 
				events={this.props.events} 
				onEditEvent={this.onEditEvent} 
				onDeleteEvent={this.onDeleteEvent} 
				onViewEvent={this.onViewEvent} 
				canView={this.props.canView}
				canEdit={this.props.canEdit}
			/>
		</ContentContainer>
	}
/>
		);
	}
}

Events.propTypes = {
	dispatch: PropTypes.func.isRequired,
	events: PropTypes.array,
	deleteEvent: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

Events.defaultProps = {
	events: []
};

const mapStateToProps = (state) => ({
	events: state.events.events,
	canView: canView(state, 'ViewUserEvents'),
	canEdit: canEditAnyPeriod(state, 'EditUserEvents'),
	isLoading: state.events.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteEvent: (event) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Event?',
			onOk: () => {
				dispatch(deleteEvent(event));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Events);
