/* eslint jsx-a11y/label-has-for: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import map from 'lodash/map';

const AvatarSelect = ({ labelText, value, onChange }) => {
	const size = 50;
	const avatars = [
		'businessman',
		'man',
		'man-1',
		'man-2',
		'man-3',
		'man-4',
		'man-5',
		'man-6',
		'man-7',
		'man-8',
		'man-9',
		'man-10',
		'man-11',
		'man-12',
		'man-13',
		'man-14',
		'man-15',
		'man-16',
		'man-17',
		'man-18',
		'man-19',
		'man-20',
		'man-21',
		'man-22',
		'man-23',
		'man-24',
		'man-25',
		'man-26',
		'man-27',
		'man-28',
		'man-29',
		'man-30',
		'man-31',
		'man-32',
		'man-33',
		'man-34',
		'businesswoman',
		'woman',
		'woman-1',
		'woman-2',
		'woman-3',
		'woman-4',
		'woman-5',
		'woman-6',
		'woman-7',
		'woman-8',
		'woman-9',
		'woman-10',
		'woman-12'
	];
	const selectAvatar = (e) => {
		const avatar = e.currentTarget.getAttribute('data-avatar');
		onChange.call(this, avatar);
	};
	const getAvatarClass = (avatar) => (
		`avatar ${avatar === value ? ' selected ' : ''}`
	);

	return (
		<div className="avatar-select">
			<label htmlFor="avatar-select">{labelText}</label>

			<div id="avatar-select" className="avatars">
				{
					map(avatars, (a, i) => 
						<div 
							key={`avatar-${i}`} 
							role="presentation" 
							className={getAvatarClass(a)} 
							data-avatar={a} 
							onClick={selectAvatar}
						>
							<Avatar src={`/images/avatars/${a}.png`} size={size} />
						</div>
					)
				}
			</div>
		</div>
	);
};

AvatarSelect.propTypes = {
	labelText: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func
};

AvatarSelect.defaultProps = {
	labelText: '',
	value: null, 
	onChange: () => {}
};

export default AvatarSelect;
