import React from 'react';
import PropTypes from 'prop-types';
import PersistedDataTable from '../widgets/persistedDataTable';
import find from 'lodash/find';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import { units } from '../../variables';

class LimitsOfAccuracyGrid extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.setLimit = this.setLimit.bind(this);
	}
	setLimit(limitOfAccuracy, value) {
		this.props.setLimit(limitOfAccuracy, value);
	}

	render() {
		const scheduleOfRates = this.props.scheduleOfRates;

		const columns = [
			{
				name: 'itemNo',
				label: 'Item No',
				cellStyle: (args) => args.data.isTextOnlyLine ? { fontStyle: 'italic', backgroundColor: '#FAFAFA' } : {}
			},
			{
				name: 'description',
				label: 'Description',
				colspan: (args) => args.data.isTextOnlyLine ? 7 : 1,
				cellStyle: (args) => args.data.isTextOnlyLine ? { fontStyle: 'italic', backgroundColor: '#FAFAFA' } : {}
			},
			{ 
				name: 'unit', 
				label: 'Unit',
				filterOperators: ['=', 'Contains', 'multi-select'],
				filterOptions: units 
			},
			{
				name: 'rate',
				label: 'Rate',
				dataType: 'number',
				cellRenderer: (args) => (args.value).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
			},
			{
				name: 'amount',
				label: 'Amount',
				dataType: 'number',
				value: (args) => args.data.qty * args.data.rate,
				cellRenderer: (args) => (args.data.qty * args.data.rate).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
			},
			{ 
				name: 'limit', 
				label: 'Limit Of Accuracy',
				dataType: 'number',
				cellRenderer: (args) =>
					<DataTableNumericEditor
						type="percent"
						value={args.data.limit} 
						nullable={true} 
						onChange={(v) => { this.setLimit(args.data, v); }}
					/>
			},
		{
				name: 'upper-limit',
				label: 'Upper Limit',
				dataType: 'number',
				cellRenderer: (args) => args.data.limit ? ((1.0 + args.data.limit) * args.data.qty * args.data.rate).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }) : null
			},
			{
				name: 'lower-limit',
				label: 'Lower Limit',
				dataType: 'number',
				cellRenderer: (args) => args.data.limit ? ((1.0 - args.data.limit) * args.data.qty * args.data.rate).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }) : null
			}
		];

		return (
			<PersistedDataTable 
				id="limitsOfAccuracy"
				data={this.props.limitsOfAccuracy || []} 
				columns={columns}
				groupBy="scheduleOfRatesId"
				groupByFormatter={(args) => {
					const schedule = find(scheduleOfRates, (s) => s.scheduleOfRatesId === args.value);
					return schedule ? schedule.name : '';
				}}
			/>
		);
	}
}

LimitsOfAccuracyGrid.propTypes = {
	limitsOfAccuracy: PropTypes.array.isRequired,
	setLimit: PropTypes.func.isRequired,
	scheduleOfRates: PropTypes.array.isRequired

};

export default LimitsOfAccuracyGrid;
