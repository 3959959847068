import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PersistedDataTable from '../widgets/persistedDataTable';

class RolesGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.roles || this.props.roles.length !== nextProps.roles.length;
	}

	render() {
		const roles = this.props.roles || [];

		const columns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditRole(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteRole(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			},
			{ name: 'name', label: 'Name' },
			{ name: 'description', label: 'Description' }
		];

		return (
			<PersistedDataTable 
				id="roles"
				data={roles} 
				columns={columns}
			/>
		);
	}
}

RolesGrid.propTypes = {
	roles: PropTypes.array,
	onEditRole: PropTypes.func.isRequired,
	onDeleteRole: PropTypes.func.isRequired
};

RolesGrid.defaultProps = {
	roles: []
};

export default RolesGrid;
