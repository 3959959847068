/* eslint react/no-multi-comp: off */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as ReactRouterLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { toggleMenu } from '../reducers/sidePanel';
import { getPermissions } from '../selectors/permissions';
import { clearTempState } from '../reducers/tempState';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import { toggleFullScreenSidePanel, closeFullScreenSidePanel } from '../reducers/layout';
import {ReactComponent as DashboardIcon } from '../images/icons/dashboard icon.svg';
import {ReactComponent as LotIcon } from '../images/icons/hammer.svg';
import {ReactComponent as LotAdjustmentIcon } from '../images/icons/hammer-off.svg';
import {ReactComponent as NCRIcon } from '../images/icons/playlist-x.svg';
import {ReactComponent as VariationIcon } from '../images/icons/versions.svg';
import {ReactComponent as EOTIcon } from '../images/icons/clock-plus.svg';
import {ReactComponent as ProgressClaimIcon } from '../images/icons/calendar-dollar.svg';
import {ReactComponent as SeparablePortionIcon } from '../images/icons/chart-pie-3.svg';
import {ReactComponent as ReportIcon } from '../images/icons/report-analytics.svg';
import {ReactComponent as IncomingCorrespondenceIcon } from '../images/icons/mail.svg';
import {ReactComponent as OutgoingCorrespondenceIcon } from '../images/icons/mail-forward.svg';
import {ReactComponent as PrincipalCorrespondenceIcon } from '../images/icons/mail-star.svg';
import {ReactComponent as DesignCorrespondenceIcon } from '../images/icons/mail-code.svg';
import {ReactComponent as CertificateIcon } from '../images/icons/certificate.svg';
import {ReactComponent as LimitsOfAccuracyIcon } from '../images/icons/ruler-measure.svg';
import {ReactComponent as ScheduleForecastIcon } from '../images/icons/chart-sankey.svg';
import {ReactComponent as ContractForecastIcon } from '../images/icons/activity.svg';
import {ReactComponent as ContactIcon } from '../images/icons/address-book.svg';
import {ReactComponent as EmployeeIcon } from '../images/icons/user.svg';
import {ReactComponent as UserIcon } from '../images/icons/users.svg';
import {ReactComponent as EventIcon } from '../images/icons/calendar-clock.svg';
import {ReactComponent as PeriodIcon } from '../images/icons/calendar-cog.svg';
import {ReactComponent as ScheduleIcon } from '../images/icons/checkup-list.svg';
import {ReactComponent as SetupRegisterIcon } from '../images/icons/forms.svg';
import {ReactComponent as TagIcon } from '../images/icons/tag.svg';
import {ReactComponent as ContractIcon } from '../images/icons/license.svg';
import {ReactComponent as ContractorIcon } from '../images/icons/backhoe.svg';
import {ReactComponent as RoleIcon } from '../images/icons/user-check.svg';
import {ReactComponent as TenantIcon } from '../images/icons/shield.svg';
import {ReactComponent as SettingIcon } from '../images/icons/settings.svg';
import {ReactComponent as RegisterIcon } from '../images/icons/file-description.svg';
import {ReactComponent as AuditLogIcon } from '../images/icons/user-search.svg';
import {ReactComponent as HelpIcon } from '../images/icons/help.svg';

const wrapLink = (onClick) => {
	class SidePanelLink extends React.PureComponent {
		render = () =>
			<ReactRouterLink onClick={onClick} {...this.props} />;
	}
	return SidePanelLink;
};

class SidePanelMenu extends Component {
    constructor(props) {
        super(props);

        this.handleMenuToggle = this.handleMenuToggle.bind(this);
    }

    handleMenuToggle(menu) {
		this.props.toggleMenu(menu);
    }

	render() {
		const Link = wrapLink(() => {
			this.props.closeFullScreenSidePanel();
			
			// Clear data table persisted state when navigating from side menu
			// #145
			this.props.clearTempState();
		});

		const path = this.props.location ? this.props.location.pathname : '';
		const permissions = this.props.permissions;
		const isGlobalAdmin = permissions.isGlobalAdmin;
		const hasFullAccess = permissions.hasFullAccess;

		const iconStyle = {
			top: '7px',
			left: '-5px',
			position: 'relative'
		};

		const homeMenuTitle = permissions.ViewDashboard ? 'Dashboard' : 'Home';
        const homePath = '/';
        const usersPath = '/users';
        const settingsPath = '/settings';
		const tenantsPath = '/tenants';
        const rolesPath = '/roles';
        const tagsPath = '/tags';
        const contractsPath = '/contracts';
        const contractorsPath = '/contractors';
        const employeesPath = '/employees';
        const contactsPath = '/contacts';
        const sorPath = '/schedule-of-rates';
        const periodsPath = '/periods';
        const lotsPath = '/lots';
		const lotAdjustmentsPath = '/lot-adjustments';
        const variationsPath = '/variations';
		const eotPath = '/extensions-of-time';
		const separablePortionsPath = '/separable-portions';
        const progressClaimsPath = '/progress-claims';
		const progressClaimByLotPath = '/reports/progress-claim-by-lot';
		const progressClaimReportPath = '/reports/progress-claim';
		const progressClaimSummaryReportPath = '/reports/progress-claim-summary';
        const lotStatusByLotPath = '/reports/lot-status-report';
        const lotStatusByScheduleItemPath = '/reports/lot-status-by-schedule-item';
        const tableOfDifferencesPath = '/reports/table-of-differences';
        const paymentCertificatePath = '/payment-certificate';
        const practicalCompletionPath = '/practical-completions';
        const finalCertificatePath = '/final-certificate';
        const incomingCorrespondencePath = '/incoming-correspondences';
        const outgoingCorrespondencePath = '/outgoing-correspondences';
        const principalCorrespondencePath = '/principal-correspondences';
        const designCorrespondencePath = '/design-correspondences';
		const ncrPath = '/ncrs';
		const contractForecastingPath = '/contract-forecast';
		const scheduleForecastingPath = '/forecasts';
		const limitsOfAccuracyPath = '/limits-of-accuracy';
		const registerDefinitionsPath = '/register-definitions';
		const viewUserEventsPath = '/events';
        const auditLogPath = '/audit-log';
        const helpPagesPath = '/help-pages';
        const homeMenuClass = classNames({
            'active': path === homePath
        });
        const usersMenuClass = classNames({
            'active': path === usersPath
		});
		const settingsMenuClass = classNames({
            'active': path === settingsPath
		});
        const tenantsMenuClass = classNames({
            'active': path === tenantsPath
		});
        const rolesMenuClass = classNames({
            'active': path === rolesPath
		});		
        const tagsMenuClass = classNames({
            'active': path === tagsPath
        });		
        const contractsMenuClass = classNames({
            'active': path === contractsPath
        });
        const contractorsMenuClass = classNames({
            'active': path === contractorsPath
        });
        const employeesMenuClass = classNames({
            'active': path === employeesPath
		});
        const contactsMenuClass = classNames({
            'active': path === contactsPath
		});
        const sorMenuClass = classNames({
            'active': path === sorPath
        });
        const periodsMenuClass = classNames({
            'active': path === periodsPath
        });
        const lotsMenuClass = classNames({
            'active': path === lotsPath
        });
        const lotAdjustmentsMenuClass = classNames({
            'active': path === lotAdjustmentsPath
        });
		const ncrMenuClass = classNames({
            'active': path === ncrPath
        });
        const variationsMenuClass = classNames({
            'active': path === variationsPath
        });
        const eotMenuClass = classNames({
            'active': path === eotPath
		});
        const separablePortionsMenuClass = classNames({
            'active': path === separablePortionsPath
        });
        const progressClaimsMenuClass = classNames({
            'active': path === progressClaimsPath
        });
		const contractForecastingMenuClass = classNames({
            'active': path === contractForecastingPath
		});
		const scheduleForecastingMenuClass = classNames({
            'active': path === scheduleForecastingPath
		});
		const limitsOfAccuracyMenuClass = classNames({
            'active': path === limitsOfAccuracyPath
		});
		const registerDefinitionsMenuClass = classNames({
            'active': path === registerDefinitionsPath
		});
		const viewUserEventsMenuClass = classNames({
            'active': path === viewUserEventsPath
		});
		const auditLogMenuClass = classNames({
            'active': path === auditLogPath
		});
		const helpPagesMenuClass = classNames({
            'active': path === helpPagesPath
		});

		const customQualityRegisters = permissions.ViewRegisters ? filter(this.props.registers, (r) => r.menuLocation === 'Quality Registers') : [];
		const customCorrespondence = permissions.ViewRegisters ? filter(this.props.registers, (r) => r.menuLocation === 'Correspondence') : [];
		const customContractual = permissions.ViewRegisters ? filter(this.props.registers, (r) => r.menuLocation === 'Contractual') : [];

		const contractualMenuItems = [];
		if (this.props.contractHasStarted) {
			if (permissions.ViewVariations) contractualMenuItems.push(<li key="variations" className={variationsMenuClass}><Link to={variationsPath}><VariationIcon style={iconStyle} /> <span>Variations</span></Link></li>);
			if (permissions.ViewExtensionsOfTime) contractualMenuItems.push(<li key="eots" className={eotMenuClass}><Link to={eotPath}><EOTIcon style={iconStyle} /> <span>Extension of Time</span></Link></li>);
			if (permissions.ViewProgressClaims) contractualMenuItems.push(<li key="progress-claims" className={progressClaimsMenuClass}><Link to={progressClaimsPath}><ProgressClaimIcon style={iconStyle} /> <span>Progress Claims</span></Link></li>);
			if (permissions.ViewSeparablePortions) contractualMenuItems.push(<li key="separable-portions" className={separablePortionsMenuClass}><Link to={separablePortionsPath}><SeparablePortionIcon style={iconStyle} /> <span>Separable Portions</span></Link></li>);
		}
		forEach(customContractual, (r) => {
			const itemPath = `/registers/${r.registerDefinitionId}`;
			contractualMenuItems.push(
				<li 
					key={`register-${r.registerDefinitionId}`} 
					className={classNames({
						'active': path === itemPath
					})}
				>
					<Link to={itemPath}><i className='fa fa-file-text'></i> <span>{r.name}</span></Link>
				</li>
			);
		});
		
		const correspondenceMenuItems = [];
		if (this.props.contractIsSelected) {
			if (permissions.ViewIncomingCorrespondence) correspondenceMenuItems.push(<li key="incoming"><Link to={incomingCorrespondencePath}><IncomingCorrespondenceIcon style={iconStyle} /> <span>Incoming Correspondence</span></Link></li>);
			if (permissions.ViewOutgoingCorrespondence) correspondenceMenuItems.push(<li key="outgoing"><Link to={outgoingCorrespondencePath}><OutgoingCorrespondenceIcon style={iconStyle} /> <span>Outgoing Correspondence</span></Link></li>);
			if (permissions.ViewPrincipalCorrespondence) correspondenceMenuItems.push(<li key="principal"><Link to={principalCorrespondencePath}><PrincipalCorrespondenceIcon style={iconStyle} /> <span>Principal Correspondence</span></Link></li>);
			if (permissions.ViewDesignCorrespondence) correspondenceMenuItems.push(<li key="design"><Link to={designCorrespondencePath}><DesignCorrespondenceIcon style={iconStyle} /> <span>Design Correspondence</span></Link></li>);
			forEach(customCorrespondence, (r) => {
				const itemPath = `/registers/${r.registerDefinitionId}`;
				correspondenceMenuItems.push(
					<li 
						key={`register-${r.registerDefinitionId}`}
						className={classNames({
							'active': path === itemPath
						})}
					>
						<Link to={itemPath}><RegisterIcon style={iconStyle} /> <span>{r.name}</span></Link>
					</li>
				);
			});
		}

		const qualityMenuItems = [];
		if (this.props.contractHasStarted) {
			if (permissions.ViewLots) qualityMenuItems.push(<li key="quality" className={lotsMenuClass}><Link to={lotsPath}><LotIcon style={iconStyle} /> <span>Lots</span></Link></li>);
			if (permissions.ViewLotAdjustments) qualityMenuItems.push(<li key="lot-adjustments" className={lotAdjustmentsMenuClass}><Link to={lotAdjustmentsPath}><LotAdjustmentIcon style={iconStyle} /> <span>Lot Adjustments</span></Link></li>);
			if (permissions.ViewNonConformanceRegister) qualityMenuItems.push(<li key="ncrs" className={ncrMenuClass}><Link to={ncrPath}><NCRIcon style={iconStyle} /> <span>Non-Conformance Register</span></Link></li>);
		}
		forEach(customQualityRegisters, (r) => {
			const itemPath = `/registers/${r.registerDefinitionId}`;
			qualityMenuItems.push(
				<li 
					key={`register-${r.registerDefinitionId}`}
					className={classNames({
						'active': path === itemPath
					})}
				>
					<Link to={itemPath}><RegisterIcon style={iconStyle} /> <span>{r.name}</span></Link>
				</li>
			);
		});

		const showContractAdmin = 
			hasFullAccess 
			|| permissions.ViewSchedules
			|| permissions.ViewTags
			|| permissions.ViewUserEvents
			|| permissions.ViewEmployees
			|| permissions.ViewContacts
            || permissions.ViewPeriods
			|| permissions.ViewRegisterDefinitions;
			
		return (			
			<ul className="side-panel-menu">
				<li className={homeMenuClass}><Link to={homePath}><DashboardIcon style={iconStyle} /> <span>{homeMenuTitle}</span></Link></li>
				
				{qualityMenuItems.length > 0 && 
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('qse'); } }>
					<span>Quality Registers</span> <i className={this.props.collapsedMenus.qse ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'}></i>
				</li>
				}
				{qualityMenuItems.length > 0 && 
				<li className={this.props.collapsedMenus.qse ? 'submenu' : 'submenu active'}>
					<ul>
						{qualityMenuItems}
					</ul>
				</li>
				}

				{contractualMenuItems.length > 0 && 
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('contractual'); } }>
					<span>Contractual</span> <i className={this.props.collapsedMenus.contractual ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'}></i>
				</li>
				}
				{contractualMenuItems.length > 0 && 
				<li className={this.props.collapsedMenus.contractual ? 'submenu' : 'submenu active'}>
					<ul>
						{contractualMenuItems}
					</ul>
				</li>
				}

				{permissions.ViewReports && this.props.contractHasStarted && 
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('reports'); } }>
					<span>Reports</span> <i className={this.props.collapsedMenus.reports ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'}></i>
				</li>
				}
				{permissions.ViewReports && this.props.contractHasStarted && 
				<li className={this.props.collapsedMenus.reports ? 'submenu' : 'submenu active'}>
					<ul>
						<li><Link to={progressClaimByLotPath}><ReportIcon style={iconStyle} /> <span>Progress Claim By Lot</span></Link></li>
						<li><Link to={progressClaimReportPath}><ReportIcon style={iconStyle} /> <span>Progress Claim</span></Link></li>
						<li><Link to={progressClaimSummaryReportPath}><ReportIcon style={iconStyle} /> <span>Progress Claim Summary</span></Link></li>
						<li><Link to={lotStatusByLotPath}><ReportIcon style={iconStyle} /> <span>Lot Status</span></Link></li>
						<li><Link to={lotStatusByScheduleItemPath}><ReportIcon style={iconStyle} /> <span>Lot Status by Schedule Item</span></Link></li>
						{/* <li><Link to={deductionsByItemsPath}><ReportIcon style={iconStyle} /> <span>Deductions by Items</span></Link></li>
						<li><Link to={deductionsByLotPath}><ReportIcon style={iconStyle} /> <span>Deductions by Lot</span></Link></li> */}
						<li><Link to={tableOfDifferencesPath}><ReportIcon style={iconStyle} /> <span>Schedule of Differences</span></Link></li>
						{/* <li><Link to={worksCompletePath}><ReportIcon style={iconStyle} /> <span>Works Complete SoR</span></Link></li> */}
					</ul>
				</li>
				}

				{(correspondenceMenuItems.length > 0) &&
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('registers'); } }>
					<span>Correspondence</span> <i className={this.props.collapsedMenus.registers ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'}></i>
				</li>
				}
				{(correspondenceMenuItems.length > 0) &&
				<li className={this.props.collapsedMenus.registers ? 'submenu' : 'submenu active'}>
					<ul>
						{correspondenceMenuItems}
					</ul>
				</li>
				}

				{/* {this.props.contractHasStarted && 
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('surveillance'); } }>
					<span>Surveillance</span> <i className={this.props.collapsedMenus.surveillance ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'}></i>
				</li>
				}
				{this.props.contractHasStarted && 
				<li className={this.props.collapsedMenus.surveillance ? 'submenu' : 'submenu active'}>
					<ul>
						<li className='todo'><Link to={'/'}><i className='fa fa-file'></i> <span>Site Instructions</span></Link></li>
					</ul>
				</li>
				} */}

				{permissions.ViewCertificates && this.props.contractHasStarted && 
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('certificates'); } }>
					<span>Certificates</span> <i className={this.props.collapsedMenus.certificates ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'}></i>
				</li>
				}
				{permissions.ViewCertificates && this.props.contractHasStarted && 
				<li className={this.props.collapsedMenus.certificates ? 'submenu' : 'submenu active'}>
					<ul>
						<li><Link to={paymentCertificatePath}><CertificateIcon style={iconStyle} /> <span>Payment Certificate</span></Link></li>
						<li className='todo'><Link to={practicalCompletionPath}><CertificateIcon style={iconStyle} /> <span>Practical Completion</span></Link></li>
						<li className='todo'><Link to={finalCertificatePath}><CertificateIcon style={iconStyle} /> <span>Final Certificate</span></Link></li>
					</ul>
				</li>
				}

				{permissions.ViewForecasts && this.props.contractHasStarted && 
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('forecasting'); } }>
					<span>Forecasting</span> <i className={this.props.collapsedMenus.forecasting ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'}></i>
				</li>
				}
				{permissions.ViewForecasts && this.props.contractHasStarted && 
				<li className={this.props.collapsedMenus.forecasting ? 'submenu' : 'submenu active'}>
					<ul>
						<li className={limitsOfAccuracyMenuClass}><Link to={limitsOfAccuracyPath}><LimitsOfAccuracyIcon style={iconStyle} /> <span>Limits of Accuracy</span></Link></li>
						<li className={scheduleForecastingMenuClass}><Link to={scheduleForecastingPath}><ScheduleForecastIcon style={iconStyle} /> <span>Schedule Forecasting</span></Link></li>
						<li className={contractForecastingMenuClass}><Link to={contractForecastingPath}><ContractForecastIcon style={iconStyle} /> <span>Contract Forecasting</span></Link></li>
					</ul>
				</li>
				}

				{this.props.contractIsSelected && showContractAdmin &&
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('contractAdmin'); } }>
					<span>Contract Admin</span> <i className={this.props.collapsedMenus.contractAdmin ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'} />
				</li>
				}
				{this.props.contractIsSelected && showContractAdmin &&
					<li className={this.props.collapsedMenus.contractAdmin ? 'submenu' : 'submenu active'}>
					<ul>
						{permissions.ViewContacts && <li className={contactsMenuClass}><Link to={contactsPath}><ContactIcon style={iconStyle} /> <span>Contacts</span></Link></li>}
						{permissions.ViewEmployees && <li className={employeesMenuClass}><Link to={employeesPath}><EmployeeIcon style={iconStyle} /><span>Employees</span></Link></li>}
						{permissions.ViewUserEvents && <li className={viewUserEventsMenuClass}><Link to={viewUserEventsPath}><EventIcon style={iconStyle} /> <span>Events</span></Link></li>}
						{permissions.ViewPeriods && this.props.contractHasStarted && 
							<li className={periodsMenuClass}><Link to={periodsPath}><PeriodIcon style={iconStyle} /> <span>Periods</span></Link></li>
						}
						{permissions.ViewSchedules && <li className={sorMenuClass}><Link to={sorPath}><ScheduleIcon style={iconStyle} /> <span>Schedule of Rates</span></Link></li>}
						{permissions.ViewRegisterDefinitions && <li className={registerDefinitionsMenuClass}><Link to={registerDefinitionsPath}><SetupRegisterIcon style={iconStyle} /><span>Setup Registers</span></Link></li>}
						{permissions.ViewTags && <li className={tagsMenuClass}><Link to={tagsPath}><TagIcon style={iconStyle} /><span>Tags</span></Link></li>}
					</ul>
				</li>
				}

				{(hasFullAccess) &&
				<li className="submenu-header" onClick={ () => { this.handleMenuToggle('systemAdmin'); } }>
					<span>System Admin</span> <i className={this.props.collapsedMenus.systemAdmin ? 'fa fa-angle-up pull-right' : 'fa fa-angle-down pull-right'} />
				</li>
				}
				{(hasFullAccess) &&
					<li className={this.props.collapsedMenus.systemAdmin ? 'submenu' : 'submenu active'}>
					<ul>
						{hasFullAccess && <li className={contractsMenuClass}><Link to={contractsPath}><ContractIcon style={iconStyle} /><span>Contracts</span></Link></li>}
						{hasFullAccess && <li className={contractorsMenuClass}><Link to={contractorsPath}><ContractorIcon style={iconStyle} /><span>Contractors</span></Link></li>}
						{hasFullAccess && <li className={rolesMenuClass}><Link to={rolesPath}><RoleIcon style={iconStyle} /> <span>Roles</span></Link></li>}
						{isGlobalAdmin && <li className={tenantsMenuClass}><Link to={tenantsPath}><TenantIcon style={iconStyle} /> <span>Tenants</span></Link></li>}
						{hasFullAccess && <li className={usersMenuClass}><Link to={usersPath}><UserIcon style={iconStyle} /> <span>Users</span></Link></li>}
						{hasFullAccess && <li className={auditLogMenuClass}><Link to={auditLogPath}><AuditLogIcon style={iconStyle} /> <span>Audit Log</span></Link></li>}
						{hasFullAccess && <li className={settingsMenuClass}><Link to={settingsPath}><SettingIcon style={iconStyle} /> <span>Settings</span></Link></li>}
						{isGlobalAdmin && <li className={helpPagesMenuClass}><Link to={helpPagesPath}><HelpIcon style={iconStyle} /> <span>Help Pages</span></Link></li>}
					</ul>
				</li>
				}
			</ul>
		);
	}
}

SidePanelMenu.propTypes = {
	location: PropTypes.object.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    collapsedMenus: PropTypes.object,
	contractIsSelected: PropTypes.bool.isRequired,
	contractHasStarted: PropTypes.bool.isRequired,
	registers: PropTypes.arrayOf(PropTypes.object),
	permissions: PropTypes.object,
	closeFullScreenSidePanel: PropTypes.func.isRequired,
	clearTempState: PropTypes.func.isRequired
};

SidePanelMenu.defaultProps = {
	registers: [],
	permissions: [],
	collapsedMenus: {}
};

const mapStateToProps = (state) => ({
    collapsedMenus: state.sidePanel.collapsedMenus,
	contractIsSelected: !!state.context.contract,
	contractHasStarted: state.context.contract ? state.context.contract.contractStatus !== 'Preliminary' : false,
	registers: state.context.registers,
	permissions: getPermissions(state)
});

const mapDispatchToProps = (dispatch) => ({
    toggleMenu: (menu) => {
        dispatch(toggleMenu(menu));
    },
	toggleFullScreenSidePanel: () => {
		dispatch(toggleFullScreenSidePanel());
	},
	closeFullScreenSidePanel: () => {
		dispatch(closeFullScreenSidePanel());
	},
	clearTempState: () => {
		dispatch(clearTempState());
	}
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SidePanelMenu));
