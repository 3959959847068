import React from 'react';
import { Divider as MuiDivider } from '@material-ui/core';

const Divider = props =>
	<MuiDivider {...props} />;
	

Divider.propTypes = {
};

Divider.defaultProps = {
};

export default Divider;
