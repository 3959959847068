import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import { toCurrency } from '../../utils/utils';
import round from 'lodash/round';
import filter from 'lodash/filter';

class LotStatusByScheduleItemReportContent extends React.Component {
	render() {
		const report = this.props.report || {};
		
		const claimItems = report.claim ? report.claim.items : [];
		const claimItemsBySchedule = groupBy(claimItems, (i) => Number(`${i.scheduleOrder}.${i.scheduleOfRatesId}`));

		return (
		<div>
			<div className="lots-data">
				<div className="section-header">
				</div>

				{
					map(claimItemsBySchedule, (items, index) => 
						!items[0].scheduleIsActive ? undefined : (
						<table className="report-table" key={index}>
							<thead>	
								<tr className="schedule-header">
									<td colSpan={12}>{items[0].scheduleName}</td>
								</tr>
								<tr>
									<th>Item No</th>
									<th style={{ minWidth: '200px' }}>Description</th>
									<th>Unit</th>
									<th>Tendered Quantity</th>
									<th>Unit Rate($)</th>
									<th>Lot Status</th>
									<th>Quantity Certified Previous Claim</th>
									<th>Certified Previous Claim</th>
									<th>Quantity To Date</th>
									<th>Certified to Date</th>
									<th>Certified this Period</th>
								</tr>
							</thead>
							<tbody>
								{
									(() => {
										let scheduleName = null;
										let prevCertTotal = 0;
										let certifiedToDateTotal = 0;
										let certifiedThisPeriodTotal = 0;

										const rows = [];

										scheduleName = items[0].scheduleName;
										
										forEach(items, (i, itemIndex) => {
											prevCertTotal += i.prevCertifiedAmount;

											let certifiedToDate = 0;
											let certifiedThisPeriod = 0;
											if (!i.isTextOnlyLine) {
												certifiedToDate = i.prevCertifiedAmount + (i.certifiedAmount || i.calcCertifiedAmount);

												if (i.isNonLotBasedVariation) {
													certifiedThisPeriod = round(i.certifiedQty * i.rate, 2);
												} else if (i.certifiedAmount === null) {
													certifiedThisPeriod = i.calcCertifiedAmount;
												} else {
													certifiedThisPeriod = i.certifiedAmount;
												}
											}
											certifiedToDateTotal += certifiedToDate;
											certifiedThisPeriodTotal += certifiedThisPeriod;

											if (i.isTextOnlyLine) {
												rows.push(
													<tr key={`si-${index}-${itemIndex}`}>
														<td style={{ fontStyle: 'italic', backgroundColor: '#FAFAFA' }}>{i.itemNo}</td>
														<td style={{ fontStyle: 'italic', backgroundColor: '#FAFAFA' }}>{i.description}</td>
														<td colSpan='9' style={{ fontStyle: 'italic', backgroundColor: '#FAFAFA' }}>{i.additionalInfo}</td>
													</tr>
												);
											} else {
												rows.push(
													<tr key={`si-${index}-${itemIndex}`}>
														<td>{i.itemNo}</td>
														<td>{i.description}</td>
														<td style={{ textAlign: 'right' }}>{i.unit}</td>
														<td style={{ textAlign: 'right' }}>{i.qty}</td>
														<td style={{ textAlign: 'right' }}>{toCurrency(i.rate)}</td>
														<td></td>
														<td style={{ textAlign: 'right' }}>{round(i.prevCertifiedQty, 3)}</td>
														<td style={{ textAlign: 'right' }}>{toCurrency(i.prevCertifiedAmount)}</td>
														<td style={{ textAlign: 'right' }}>{round(i.prevCertifiedQty + i.certifiedQty, 3)}</td>
														<td style={{ textAlign: 'right' }}>{toCurrency(certifiedToDate)}</td>
														<td style={{ textAlign: 'right' }}>{toCurrency(certifiedThisPeriod)}</td>
													</tr>
												);
											}

											forEach(filter(report.lots, (l) => l.scheduleOfRatesItemId === i.scheduleOfRatesItemId), (l, lotIndex) => {
												const lotDesc = l.lotDescription ? `${l.lotNo} (${l.lotDescription})` : l.lotNo;
												let statusBackColour = null;
												let statusForeColour = null;
												switch (l.status) {
													case 'Guaranteed':
														statusBackColour = '#f0ad4e';
														statusForeColour = '#222';
														break;
													case 'Guaranteed>1Month':
														statusBackColour = '#FFC7CE';
														statusForeColour = '#9C0006';
														break;
													case 'Closed':
														statusBackColour = '#C6EFCE';
														statusForeColour = '#2C6153';
														break;
													default:
												}
												
												rows.push(
													<tr style={{ fontSize: '8px', backgroundColor: '#eee' }} key={`sil-${index}-${itemIndex}-${lotIndex}`}>
														<td></td>
														<td>{lotDesc}</td>
														<td colSpan={3}>{l.periodName}</td>
														<td style={{ backgroundColor: statusBackColour, color: statusForeColour }}>{l.status}</td>
														<td style={{ textAlign: 'right' }}>{l.periodId === report.periodId ? '' : round(l.certifiedQty, 4) || 0}</td>
														<td style={{ textAlign: 'right' }}>{l.periodId === report.periodId ? '' : toCurrency(l.certifiedAmount) || 0}</td>
														<td style={{ textAlign: 'right' }}>{l.periodId === report.periodId ? round(l.certifiedQty, 4) || 0 : ''}</td>
														<td></td>
														<td></td>
													</tr>
												);
											});
										});

										rows.push(
											<tr className="schedule-total" key={`schedule-total-${index}`}>
												<td colSpan={7}>{scheduleName} Total</td>
												<td style={{ textAlign: 'right' }}>{toCurrency(prevCertTotal)}</td>
												<td></td>
												<td style={{ textAlign: 'right' }}>{toCurrency(certifiedToDateTotal)}</td>
												<td style={{ textAlign: 'right' }}>{toCurrency(certifiedThisPeriodTotal)}</td>
											</tr>
										);
											
										return rows;
									})()
								}
							</tbody>
						</table>
					))
				}
			</div>
		</div>
    );
  }
}

LotStatusByScheduleItemReportContent.propTypes = {
	report: PropTypes.object
};

LotStatusByScheduleItemReportContent.defaultProps = {
	report: undefined
};

export default LotStatusByScheduleItemReportContent;
