import React from 'react';
import { 
	Grid
} from '@material-ui/core';
import TextField from '../../widgets/TextField';
import Checkbox from '../../widgets/Checkbox';

const PeriodField = props => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Checkbox
					label="Start Immediately"
					checked={props.value.startImmediately}
					onChange={(e, v) => { 
						props.onChange({
							...props.value,
							startImmediately: v
						});
					}}
				/>
			</Grid>

			{props.value.startImmediately && 
				<Grid item xs={12}>
					<TextField
						label="Period Name"
						value={props.value.periodName}
						fullWidth={true}
						onChange={(e, v) => { 
							props.onChange({
								...props.value,
								periodName: v
							});
						}}
						errorText={props.errorText}
					/>
				</Grid>
			}
		</Grid>
	);
};

export default PeriodField;
