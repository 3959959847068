import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgressClaimSummaryReportContent from './progressClaimSummaryReportContent';
import addReportWrapper from './reportWrapper';

class ProgressClaimSummaryReport extends React.Component {
	render() {
		const report = this.props.report || {};
		
		return (
			<ProgressClaimSummaryReportContent report={report} />
		);
	}
}

ProgressClaimSummaryReport.propTypes = {
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addReportWrapper(
	ProgressClaimSummaryReport, {
		reportUrl: '/api/reports/progress-claim-by-lot',
		downloadUrl: '/Reports/ProgressClaimSummary',
		excelDownloadUrl: '/ExcelReports/ProgressClaimSummary',
		title: 'Progress Claim Summary',
		className: 'progress-claim-summary-report'
	})
);
