import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NCRHandoverContent from '../content/ncrHandoverContent';
import addHandoverWrapper from '../handoverWrapper';

class NCRHandover extends React.Component {
	static getTitle(report) {
		return `NCR ${report.contractorNcrNo}`;
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<NCRHandoverContent report={report} />
		);
	}
}

NCRHandover.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	NCRHandover, {
		title: 'Non Conformance Register',
		className: 'ncr-handover'
	})
);
