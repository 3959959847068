import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../utils/ajax';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import map from 'lodash/map';
import { useHistory } from 'react-router-dom';

const ProcessingVariations = ({ setLoading, contractId, changeContractContext, setSubHeader }) => {
	const [variations, setVariations] = React.useState([]);
	const history = useHistory();

	const onSelectVariation = (variation) => {
		if (contractId !== variation.contractId) {
			changeContractContext(variation.contractId);
		}
		history.push(`/variation/${variation.variationId}`);
	}

	React.useEffect(() => {
		const loadData = () => {
			setLoading(true);
			setVariations([]);
			setSubHeader("");
			get({
				url: `/api/dashboard/processing-variations?contractId=${contractId}`,
				onSuccess: data => {
					setSubHeader(data.length === 1 ? '(1 item)' : `(${data.length} items)`);
					setLoading(false);
					setVariations(data);
				},
				onError: () => {
					setLoading(false);
				}
			});
		};

		if (contractId) {
			loadData();
		}
	}, [contractId]);

	if (variations.length === 0) return <p style={{ marginLeft: '10px', marginTop: '10px' }}>None</p>;
		
	return (
		<div style={{ overflow: 'auto', height: '100%' }}>
		<List>
			{
				map(variations, (v, i) => {
					const title = v.contractorVariationNo ? `${v.variationNo} (${v.contractorVariationNo})` : `${v.variationNo}`;
					return (
						<ListItem
							key={`pv-${i}`}
							button
							onClick={() => onSelectVariation(v)}
						>
							<ListItemIcon>
								<i className='fa fa-code-fork fa-2x'></i>
							</ListItemIcon>
							<ListItemText 
								primary={
									<div>
										<p>{title}</p>
										<p style={{ fontSize: 'small' }}>{v.description}</p>
									</div>
								}
								secondary={`Contract: ${v.contractNumber}`}
							/>
						</ListItem>
					);
				})
			}
		</List>
		</div>
	);
};

ProcessingVariations.propTypes = {
	setLoading: PropTypes.func.isRequired,
	changeContractContext: PropTypes.func.isRequired,
	contractId: PropTypes.number
};

ProcessingVariations.defaultProps = {
	contractId: null
};

export default ProcessingVariations;
