import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverDateField from '../components/handoverDateField';
import HandoverBooleanField from '../components/handoverBooleanField';
import HandoverMemoField from '../components/handoverMemoField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';

class VariationHandoverContent extends React.Component {
	render() {
		const variation = this.props.report;

		const columns = [
			{ name: 'itemNo', label: 'Item' },
			{ name: 'itemDescription', label: 'Description', headerStyle: { minWidth: '200px' } },
			{ name: 'unit', label: 'Unit' },
			{ name: 'qty', label: 'Quantity' },
			{ 
				name: 'rate', 
				label: 'Rate',
				dataType: 'number',
				cellRenderer: (args) => args.value ? (args.value).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }) : null
			},
			{
				name: 'amount',
				label: 'Amount',
				dataType: 'number',
				value: (args) => args.data.qty * args.data.rate,
				cellRenderer: (args) => (args.data.qty * args.data.rate).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
			},
			{ name: 'comments', label: 'Comments' }
		];

		const linkedCorrespondence = variation.correspondence;
		
		const linkedCorrespondenceColumns = [
			{ name: 'correspondenceType', label: 'Type' },
			{ name: 'correspondenceNumber', label: 'Number' },
			{ name: 'correspondenceTitle', label: 'Title' }
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Variation No." value={variation.variationNo} />
				<HandoverField label="Contractor Variation No." value={variation.contractorVariationNo} />
				<HandoverField label="Description" value={variation.description} />
				<HandoverField label="Status" value={variation.status} />
				<HandoverBooleanField label="Is Lot Based" value={variation.isLotBased} />
				<HandoverField label="Identified By" value={variation.identifiedBy} />
				<HandoverField label="To" value={variation.toName} />
				<HandoverField label="From" value={variation.from} />
				<HandoverDateField label="Date Received" value={variation.dateReceived} />
				<HandoverField label="Period Claimed" value={variation.periodClaimedName} />
				<HandoverDateField label="Order Date" value={variation.orderDate} />
				<HandoverField label="Ordered By" value={variation.orderedBy} />
				<HandoverMemoField label="Comments" value={variation.comments} />
				<HandoverBooleanField label="Action Required" value={variation.actionRequired} />
				<HandoverDateField label="Action Due Date" value={variation.actionDueDate} />
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Items</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={variation.items || []}
					columns={columns}
				/>
			</div>

			
			<div className="handover-items">
				<HandoverSectionHeader>Linked Correspondence</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={linkedCorrespondence || []}
					columns={linkedCorrespondenceColumns}
				/>
			</div>
		</div>
    );
  }
}

VariationHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default VariationHandoverContent;
