/* eslint no-restricted-syntax: "off" */
/* eslint no-prototype-builtins: "off" */
/* eslint prefer-template: "off" */
/* eslint operator-assignment: "off" */
/* eslint "react/no-unused-prop-types": "off" */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HandoverLayout extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			vars: {}
		};
	}
  componentDidMount() {
    document.documentElement.className = document.documentElement.className + ' report-view';

	const vars = {};
	const queryStringsFromUrl = document.location.search.substring(1).split('&');
	for (const queryString in queryStringsFromUrl) {
		if (queryStringsFromUrl.hasOwnProperty(queryString)) {
			const tempVar = queryStringsFromUrl[queryString].split('=', 2);
			vars[tempVar[0]] = decodeURI(tempVar[1]);
		}
	}
	this.setState({ vars });
  }
  render() {
    return (
      <div>
{/*
			<img style={{ position: 'fixed', top: 0, left: 0, height: '21cm', width: '21cm' }} src="/images/watermark_landscape.jpg" />
	*/}
{/*
			<img id="report-watermark" src={this.props.orientation === 'landscape' ? "/images/watermark_landscape.jpg" : "/images/watermark.jpeg"} role="presentation" />
	*/}
{/*
			<img id="report-watermark" src="/images/watermark_landscape.jpg" />
	*/}
{/*
		{this.props.orientation === 'landscape' ?
			<img style={{ position: 'fixed', top: 0, left: 0, height: '21cm', width: '29.7cm' }} src="/images/watermark_landscape.jpg" role="presentation" />
			:
			<img style={{ position: 'fixed', top: 0, left: 0, height: '29.7cm', width: '21cm' }} src="/images/watermark.jpeg" role="presentation" />
		}
	*/}
	{/* Was using this last but not quite right. Commenting prob until we use chrome headless 
			<img style={{ position: 'fixed', top: 0, left: 0, height: '29.7cm', width: '21cm' }} src="/images/watermark.jpeg" role="presentation" />
			*/}

            {this.props.body}
      </div>
    );
  }
}

HandoverLayout.propTypes = {
	body: PropTypes.element,
	orientation: PropTypes.string
};

HandoverLayout.defaultProps = {
	body: undefined,
	orientation: 'Portrait'
};

export default HandoverLayout;
