import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';

const FormFieldContainer = ({ size, style, infoText, classes, children }) => {
	let mappedClasses = classes;
	if (isArray(classes)) {
		mappedClasses = zipObject(classes, map(classes, () => true));
	}
	
	const cls = classNames({
		'form-field-container': true,
		'col': true,
		's12': !size || size === 12,
		's11': size === 11,
		's10': size === 10,
		's9': size === 9,
		's8': size === 8,
		's7': size === 7,
		's6': size === 6,
		's5': size === 5,
		's4': size === 4,
		's3': size === 3,
		's2': size === 2,
		's1': size === 1,
		...mappedClasses
	});
	return (
			<div style={style} className={cls}>
				{children}
				{infoText && 
					<span className='info-text'>{infoText}</span>
				}
			</div>

	);
};

FormFieldContainer.propTypes = {
	children: PropTypes.any,
	classes: PropTypes.any,
	infoText: PropTypes.string,
	style: PropTypes.object,
	size: PropTypes.number
};


export default FormFieldContainer;
