import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	savePeriod,
	clearPeriod,
	requestPeriod
} from '../../reducers/periods';
import PeriodForm from './periodForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import { produce } from 'immer';
import PageLayout from '../pageLayout';

class PeriodMaintenance extends React.Component {
	state = {
		period: this.props.period,
		hasUnsavedChanges: false
	};

	static fetchData(dispatch, props) {
		dispatch(requestPeriod(props.match.params.periodId));
	}
	static clearData(dispatch) {
		dispatch(clearPeriod());
	}
	componentDidMount() {
		PeriodMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			period: nextProps.period,
			hasUnsavedChanges: false
		});
	}

	updateField = (field, newValue) => {
		this.setState(produce(draft => {
			draft.period[field] = newValue;
			draft.hasUnsavedChanges = true;
		}));
	}

	render() {
		const period =  this.state.period || {};

		return (
<PageLayout
	title={period.periodName}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.period),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.period),
		onCancel: () => this.props.history.goBack()
	}}
	content={
	<div>
		<PeriodForm 
			period={period} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			canEdit={this.props.canEdit}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
	</div>
	}
/>
		);
	}
}

PeriodMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	period: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	canEdit: canEditAnyPeriod(state, 'EditPeriods'),
	period: state.periods.period,
	isLoading: state.periods.isLoading,
	saveResult: state.periods.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (period) => {
		dispatch(savePeriod(period, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (period) => {
		dispatch(savePeriod(period, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(PeriodMaintenance);
