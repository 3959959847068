import PropTypes from 'prop-types';

const Tab = () => {};

Tab.propTypes = {
	label: PropTypes.string.isRequired,
	error: PropTypes.bool,
	show: PropTypes.bool,
	children: PropTypes.node.isRequired
};

Tab.defaultProps = {
	error: false,
	show: true
};

export default Tab;
