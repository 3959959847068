import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';

class VariationsHandoverContent extends React.Component {
	render() {
		const data = this.props.report;

		const columns = [
			{ name: 'variationNo', label: 'Variation No' },
			{ name: 'contractorVariationNo', label: 'Contractor Variation No' },
			{ name: 'description', label: 'Variation Description', headerStyle: { minWidth: '300px' } },
			{ 
				name: 'status', 
				label: 'Variation Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.status === 'Processing') {
						statusBackColour = '#FFC7CE';
						statusForeColour = '#9C0006';
					}
					if (args.data.status === 'Closed') {
						statusBackColour = '#C6EFCE';
						statusForeColour = '#2C6153';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				} 
			},
			{ name: 'numItems', label: 'Items' },
			{ 
				name: 'totalAmount', 
				label: 'Variation Amount', 
				formatString: 'currency',
				showTotal: true
			},
			{ name: 'periodClaimedName', label: 'Period Claimed' }
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

VariationsHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default VariationsHandoverContent;
