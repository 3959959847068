import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveLotAdjustment, 
	clearLotAdjustment, 
	requestLotAdjustment, 
	createNewLotAdjustment 
} from '../../reducers/lotAdjustments';
import { requestLots, requestTags } from '../../reducers/lots';
import { requestScheduleOfRates } from '../../reducers/scheduleOfRates';
import LotAdjustmentForm from './lotAdjustmentForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { addOrUpdate } from '../../utils/utils';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class LotAdjustmentMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			lotAdjustment: props.lotAdjustment,
			hasUnsavedChanges: false
		};

		this.selectLot = this.selectLot.bind(this);
		this.updateLotAdjustment = this.updateLotAdjustment.bind(this);
		this.onAddLotAdjustmentItems = this.onAddLotAdjustmentItems.bind(this);
		this.updateLotAdjustmentItem = this.updateLotAdjustmentItem.bind(this);
		this.deleteLotAdjustmentItem = this.deleteLotAdjustmentItem.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestScheduleOfRates());
		dispatch(requestTags());
		dispatch(requestLots());
		if (props.match.params.lotAdjustmentId) {
			dispatch(requestLotAdjustment(props.match.params.lotAdjustmentId));
		} else {
			dispatch(createNewLotAdjustment());
		}
	}
	static clearData(dispatch) {
		dispatch(clearLotAdjustment());
	}
	componentDidMount() {
		LotAdjustmentMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			lotAdjustment: nextProps.lotAdjustment,
			hasUnsavedChanges: false
		});
	}

	selectLot(lot) {
		this.setState({
			lotAdjustment: {
				...this.state.lotAdjustment,
				lotId: lot.lotId,
				lotNo: lot.lotNo,
				lotDescription: lot.lotDescription
			}
		});
	}
	updateLotAdjustment(values) {
		this.setState({
			lotAdjustment: {
				...this.state.lotAdjustment,
				...values
			}
		});
	}
	onAddLotAdjustmentItems(lotItems) {
		const lotAdjustment = this.state.lotAdjustment;
		const items = lotAdjustment.items;
		forEach(lotItems, (i) => {
			const item = find(items, (i2) => i2.scheduleOfRatesItemId === i.scheduleOfRatesItemId);
			if (!item) {
				const newAdjItem = { 
					lotAdjustmentId: lotAdjustment.lotAdjustmentId,
					lotItemId: i.lotItemId,
					scheduleOfRatesId: i.scheduleOfRatesId,
					scheduleOfRatesItemId: i.scheduleOfRatesItemId,
					itemNo: i.itemNo,
					itemDescription: i.itemDescription,
					adjustmentType: lotAdjustment.adjustmentType,
					qty: 0,
					unit: i.unit,
					rate: i.rate,
					comments: '',
					originalQty: i.qty,
					totalAdjustments: i.adjustedQty
				};
				items.push(newAdjItem);
			}
		});
		this.setState({
			lotAdjustment: {
				...this.state.lotAdjustment,
				items: items
			},
			hasUnsavedChanges: true
		});
	}
	updateLotAdjustmentItem(newItem) {
		// Need to call setState to update the lot adjustment
        this.setState({
			lotAdjustment: {
				...this.state.lotAdjustment,
				items: addOrUpdate(this.state.lotAdjustment.items, newItem, { scheduleOfRatesItemId: newItem.scheduleOfRatesItemId }), 
			},
			hasUnsavedChanges: true
        });
	}
	deleteLotAdjustmentItem(item) {
		// Need to call setState to update the lot adjustment
        this.setState({
			lotAdjustment: {
				...this.state.lotAdjustment,
				items: filter(this.state.lotAdjustment.items, (i) => i.scheduleOfRatesItemId !== item.scheduleOfRatesItemId)
			},
			hasUnsavedChanges: true
        });
	}

	render() {
		const lotAdjustment =  this.state.lotAdjustment || {};
		const scheduleOfRates = this.props.scheduleOfRates;
		const adjustableLots = filter(this.props.lots, l => l.status !== 'Open');
		const canEdit = this.props.canEdit && lotAdjustment && lotAdjustment.periodClaimed === this.props.period.periodId;

		return (
<PageLayout
	title={lotAdjustment._isNew ? 'New Lot Adjustment' : `Adjustment for ${lotAdjustment.lotNo}`}
	isLoading={this.props.isLoading}
	formActions={{
		isLoading: this.props.isLoading,
		canEdit: canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.props.saveChanges(this.state.lotAdjustment),
		onSaveAndContinue: () => this.props.saveChangesAndContinue(this.state.lotAdjustment),
		onCancel: () => this.props.history.goBack()
	}}
	content={
	<div>
		<LotAdjustmentForm 
			lotAdjustment={lotAdjustment} 
			lots={adjustableLots}
			scheduleOfRates={scheduleOfRates} 
			tags={this.props.tags}
			isLoading={this.props.isLoading} 
			canEdit={canEdit}
			saveResult={this.props.saveResult}  
			selectLot={this.selectLot}
			updateLotAdjustment={this.updateLotAdjustment}
			onAddLotAdjustmentItems={this.onAddLotAdjustmentItems}
			updateLotAdjustmentItem={this.updateLotAdjustmentItem}
			deleteLotAdjustmentItem={this.deleteLotAdjustmentItem}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
	</div>
	}
/>
		);
	}
}

LotAdjustmentMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	lotAdjustment: PropTypes.object,
	lots: PropTypes.array,
	isLoading: PropTypes.bool.isRequired,
	scheduleOfRates: PropTypes.array,
	tags: PropTypes.array,
	saveResult: PropTypes.object.isRequired,
	period: PropTypes.object.isRequired
};

LotAdjustmentMaintenance.defaultProps = {
	lotAdjustment: {},
	lots: [],
	scheduleOfRates: [],
	tags: []
};

const mapStateToProps = (state) => ({
	lotAdjustment: state.lotAdjustments.lotAdjustment,
	lots: state.lots.lots,
	scheduleOfRates: state.scheduleOfRates.scheduleOfRates,
	tags: state.lots.tags,
	canEdit: canEdit(state, 'EditLotAdjustments'),
	isLoading: state.lotAdjustments.isLoading,
	saveResult: state.lotAdjustments.saveResult,
	period: state.context.period
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (lotAdjustment) => {
		dispatch(saveLotAdjustment(lotAdjustment, (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (lotAdjustment) => {
		dispatch(saveLotAdjustment(lotAdjustment, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(LotAdjustmentMaintenance);
