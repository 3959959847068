import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConfirmDeleteModal from './confirmDeleteModal';
import PromptModal from './promptModal';
import YesNoModal from './YesNoModal';
import LoginDialog from './LoginDialog';

const MODAL_COMPONENTS = {
	'CONFIRM_DELETE': ConfirmDeleteModal,
	'PROMPT': PromptModal,
	'YES_NO': YesNoModal,
	'LOGIN': LoginDialog
};

const ModalRoot = ({ modalType, modalProps }) => {
	if (!modalType) {
		return <span />; // after React v15 you can return null here
	}

	const SpecificModal = MODAL_COMPONENTS[modalType];
	return <SpecificModal {...modalProps} />;
};

ModalRoot.propTypes = {
	modalType: PropTypes.string,
	modalProps: PropTypes.object
};

ModalRoot.defaultProps = {
	modalType: '',
	modalProps: {}
};

export default connect(
	state => state.modal
)(ModalRoot);
