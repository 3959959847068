import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import SelectField from '../widgets/selectField';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import DateField from '../widgets/DateField';
import Checkbox from '../widgets/Checkbox';
import ContentContainer from '../contentContainer';
import SubContentContainer from '../subContentContainer';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import FileUpload from '@material-ui/icons/Publish';
import FileDownload from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import map from 'lodash/map';
import Button from '../widgets/button';
import PersistedDataTable from '../widgets/persistedDataTable';
import filter from 'lodash/filter';
import CorrespondencePicker from '../widgets/correspondencePicker';
import EmployeeSelect from '../widgets/employeeSelect';
import PersonSelect from '../widgets/PersonSelect';
import Tabs from '../widgets/Tabs';
import Tab from '../widgets/Tab';
import TagField from '../widgets/TagField';

class OutgoingCorrespondenceForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			correspondence: props.correspondence
		};	

		this.selectFiles = this.selectFiles.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			correspondence: nextProps.correspondence
		});
	}

	selectFiles(event) {
		this.props.addFiles(event.target.files);
	}

	addLinkedCorrespondence = () => {
		this.setState({ showCorrespondencePicker: true });
	}

	onSelectCorrespondence = (selectedCorrespondence) => {
		this.props.onSelectCorrespondence(selectedCorrespondence);
		this.setState({
			showCorrespondencePicker: false
		});
	}
	onCancelCorrespondencePicker = () => {
		this.setState({ showCorrespondencePicker: false });
	}

	render() {
		const correspondence = this.state.correspondence;
		const linkedCorrespondence = correspondence.linkedCorrespondence || [];
		const styles = {
			button: {
				marginTop: 12
			},
			fileInput: {
				cursor: 'pointer',
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				width: '100%',
				opacity: 0
			}
		};

		const linkedCorrespondenceColumns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.viewLinkedCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<VisibilityIcon />
						</IconButton>
						{this.props.canEdit && 
							<IconButton onClick={ () => { this.props.deleteLinkedCorrespondence(args.data); } }>
								<ActionDelete />
							</IconButton>
						}
					</div>				
			},
			{ 
				name: 'correspondenceType', 
				label: 'Type'
			},
			{ name: 'correspondenceNumber', label: 'Correspondence Number' },
			{ name: 'correspondenceDate', label: 'Date', dataType: 'date' }
		];

		return (
<div>
	<CorrespondencePicker 
		show={this.state.showCorrespondencePicker} 
		types={['Incoming', 'Outgoing']}
		selectedCorrespondence={{
			incomingCorrespondence: map(filter(correspondence.linkedCorrespondence, (c) => !c.isParent && c.correspondenceType === 'Incoming'), (c) => ({ incomingCorrespondenceId: c.correspondenceId })),
			outgoingCorrespondence: map(filter(correspondence.linkedCorrespondence, (c) => !c.isParent && c.correspondenceType === 'Outgoing'), (c) => ({ outgoingCorrespondenceId: c.correspondenceId }))
		}}
		onOk={this.onSelectCorrespondence} 
		cancel={this.onCancelCorrespondencePicker} 
	/>

	<ContentContainer>
		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<TextField
					value={correspondence.rfiNumber}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('rfiNumber', v); }}
					label="Outgoing Ref Number"
					errorText={getFieldErrorText(this.props.saveResult, 'RfiNumber')}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<DateField
					value={correspondence.receivedDate} 
					label="Dated" 
					onChange={(d) => { this.props.updateField('receivedDate', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={['s12', 'm4']}>
				<TextField
					value={correspondence.docRefNo}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('docRefNo', v); }}
					label="Doc Ref No"
					errorText={getFieldErrorText(this.props.saveResult, 'DocRefNo')}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={['s12', 'm8']}>
				<TextField
					value={correspondence.docTitle}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('docTitle', v); }}
					label="Document Title"
					errorText={getFieldErrorText(this.props.saveResult, 'DocTitle')}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<PersonSelect
					personType={correspondence.toType}
					personId={correspondence.toId}
					fullWidth={true}
					onChange={(v) => { this.props.updateField('to', { toId: v ? v.personId : 0, toType: v ? v.personType : null }); }}
					label="To"
					errorText={getFieldErrorText(this.props.saveResult, 'To')}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<EmployeeSelect
					employees={this.props.employees} 
					value={correspondence.from} 
					label="From" 
					onChange={(v) => { this.props.updateField('from', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'From')}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<SelectField 
					value={correspondence.deliveryMethod} 
					fullWidth={true}
					label="Delivery Method" 
					onChange={(v) => { this.props.updateField('deliveryMethod', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'DeliveryMethod')}
					items={[
						{ value: 'Email', label: 'Email' },
						{ value: 'Mail', label: 'Mail' },
						{ value: 'Verbal', label: 'Verbal' }
					]}
				>
				</SelectField>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<DateField 
					value={correspondence.sentDate} 
					label="Sent Date" 
					onChange={(d) => { this.props.updateField('sentDate', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>
		
		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true, hasToggle: true }}>
				<Checkbox
					label="Response Required"
					labelPosition="right"
					checked={correspondence.actionRequired}
					onCheck={(e, v) => { this.props.updateField('actionRequired', v); }}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<DateField
					value={correspondence.actionDate} 
					label="Response Received" 
					onChange={(d) => { this.props.updateField('actionDate', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer>
				<SelectField 
					value={correspondence.status} 
					fullWidth={true}
					label="Status" 
					onChange={(v) => { this.props.updateField('status', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'Status')}
					items={[
						{ value: 'Open', label: 'Open' },
						{ value: 'Closed', label: 'Closed' }
					]}
				>
				</SelectField>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<EmployeeSelect
					employees={this.props.employees} 
					value={correspondence.actionedBy} 
					label="Actioned By" 
					onChange={(v) => { this.props.updateField('actionedBy', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'Actioned By')}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<DateField 
					value={correspondence.dateAdded} 
					label="Date Added" 
					onChange={(d) => { this.props.updateField('dateAdded', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>
		
		<FormRow>
			<FormFieldContainer>
				<TagField
					label="Tags" 
					fullWidth={true}
					value={correspondence.tags}
					onChange={tags => this.props.updateField('tags', tags)}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer>
				<TextField
					value={correspondence.comments}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('comments', v); }}
					label="Comments"
					errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
				/>
			</FormFieldContainer>
		</FormRow>
	</ContentContainer>

	<SubContentContainer header="Linked Correspondence" style={{ marginTop: '10px' }}>
		<Tabs renderOnLoad>
			<Tab label="Linked To" value="LinkedTo">
				<PersistedDataTable 
					id="linkedCorrespondence"
					data={filter(linkedCorrespondence || [], c => !c.isParent)} 
					columns={linkedCorrespondenceColumns}
				/>
			</Tab>
			<Tab label="Linked By" value="LinkedBy">
				<PersistedDataTable 
					id="linkedByCorrespondence"
					data={filter(linkedCorrespondence || [], c => c.isParent)} 
					columns={[
						{
							name: 'commands',
							excludeFromSearch: true,
							excludeFromFilter: true,
							cellRenderer: (args) => 
								<div style={{ whiteSpace: 'nowrap' }}>
									<IconButton onClick={ () => { this.props.viewLinkedCorrespondenceBy(args.data); } } style={{ padding: '2px' }}>
										<VisibilityIcon />
									</IconButton>
								</div>				
						},
						{ 
							name: 'targetType', 
							label: 'Type'
						},
						{ name: 'targetNumber', label: 'Number' }
					]}
				/>
			</Tab>
		</Tabs>

		<div className="row">
			<Button
				label="Add"
				style={styles.button}
				onClick={() => this.addLinkedCorrespondence()}
			>
			</Button>
			<Button
				style={{
					...styles.button,
					marginLeft: '10px'
				}}
				label="Explore"
				onClick={() => this.props.exploreLinkedCorrespondence()}
			>
			</Button>
		</div>
	</SubContentContainer>

	<SubContentContainer 
		header="Files"
		onDragOver={(e) => {
			e.dataTransfer.dropEffect = 'copy';
			e.stopPropagation();
			e.preventDefault();
		}}
		onDrop={(e) => {
			e.stopPropagation();
			e.preventDefault();
			// Allow files to be added by dropping anywhere in container
			this.props.addFiles(e.dataTransfer.files);
		}}
	>
		<div className="table-container">
			<table className="full-bordered" style={{ width: '100%' }}>
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
					</tr>
				</thead>
				<tbody>
					{
						map(this.props.correspondence.files, (f) =>
							<tr key={f.userFileId}>
								<td style={{ whiteSpace: 'nowrap' }}>
									{!f._isNew && 
										<IconButton onClick={ () => { this.props.downloadFile(f); } } style={{ padding: '2px' }}>
											<FileDownload />
										</IconButton>
									}
									{this.props.canEdit &&
										<IconButton onClick={ () => { this.props.deleteFile(f); } } style={{ padding: '2px' }}>
											<ActionDelete />
										</IconButton>
									}
								</td>	
								<td>{f.name}</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</div>

		{this.props.canEdit &&
			<div className="row">
				<Button
					label="Select a file to upload"
					style={styles.button}
					component="label"
					startIcon={<FileUpload />}
				>
					<input type="file" style={styles.fileInput} multiple="multiple" onChange={this.selectFiles} />
				</Button>
			</div>
		}

	</SubContentContainer>
</div>
		);
	}
}

OutgoingCorrespondenceForm.propTypes = {
	updateField: PropTypes.func.isRequired,
	downloadFile: PropTypes.func.isRequired,
	deleteFile: PropTypes.func.isRequired,
	addFiles: PropTypes.func.isRequired,
	correspondence: PropTypes.object.isRequired,
	employees: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	onSelectCorrespondence: PropTypes.func.isRequired,
	deleteLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondenceBy: PropTypes.func.isRequired,
	exploreLinkedCorrespondence: PropTypes.func.isRequired
};

export default OutgoingCorrespondenceForm;
