import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import isDate from 'lodash/isDate';
import { parseDate, formatDate } from '../../utils/dateUtils';

class ReportDateField extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			value: props.value,
			valid: this.isValid(props.value),
			displayValue: this.getDisplayValue(props.value)
		};

		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value,
			valid: this.isValid(nextProps.value),
			displayValue: this.getDisplayValue(nextProps.value)
		});
	}
	isValid(value) {
		if (value === null || value === '' || value === undefined) return true;
		const dateValue = formatDate(isDate(value) ? value : parseDate(value), 'dd/MM/yyyy');
		return dateValue !== 'Invalid Date'; 
	}
	getDisplayValue(value) {
		if (value === null || value === '' || value === undefined) return '';
		const dateValue = formatDate(isDate(value) ? value : parseDate(value), 'dd/MM/yyyy');
		const val = dateValue === 'Invalid Date' ? value : dateValue; 
		return val;
	}
	onChange(e, v) {
		if (this.props.editable === false) return;
		this.setState({
			value: v,
			displayValue: v
		});
	}
	onBlur(e) {
		if (this.props.editable === false) return;
		const v = e.target.value;
		this.setState({
			value: v,
			valid: this.isValid(v),
			displayValue: this.getDisplayValue(v)
		});

		if (this.props.onChange) this.props.onChange(this.state.value, this.state.displayValue, this.state.isValid);
	}
	render() {
		const displayValue = this.state.displayValue;
		let background = '';
		if (!this.state.valid) {
			background = '#F4DCDE';
		}
		
		let classes = 'report-field';
		if (this.props.editable !== false) {
			classes += ' editable';
		}

		const style = {
			width: '100px', 
			lineHeight: '14px',
			height: '18px',
			background: background,
			...this.props.style
		};
		const inputStyle = {
			fontSize: '12px', 
			textAlign: 'right',
			padding: '2px',
			...this.props.inputStyle
		};

		return (
			<TextField 
				className={classes}
				onChange={this.onChange} 
				onBlur={this.onBlur} 
				underlineShow={false} 
				style={style} 
				inputStyle={inputStyle} 
				value={displayValue} 
			/>
		);
	}
}

ReportDateField.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	editable: PropTypes.bool,
	style: PropTypes.object,
	inputStyle: PropTypes.object
};

ReportDateField.defaultProps = {
	value: undefined,	
	onChange: () => {},
	editable: false,
	style: {},
	inputStyle: {}
};

export default ReportDateField;
