import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	deleteContractor, 
	requestContractors, 
	clearContractors 
} from '../../reducers/contractors';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MessagePanel from '../messagePanel';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PersistedDataTable from '../widgets/persistedDataTable';
import PageLayout from '../pageLayout';

class Contractors extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			contractor: null
		};

        this.handleEditContractor = this.handleEditContractor.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestContractors());
	}
	static clearData(dispatch) {
		dispatch(clearContractors());
	}

	componentDidMount() {
		Contractors.fetchData(this.props.dispatch);
	}

	handleEditContractor(contractorId) {
		this.props.history.push(`/contractor/${contractorId}`);
	}

	handleDeleteContractor(contractor) {
		this.props.deleteContractor(contractor);
	}

	newContractor = () => {
		this.props.history.push(`/contractor`);
	}

	render() {
		const contractors = this.props.contractors || [];

		const columns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => (
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.handleEditContractor(args.data.contractorId); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.handleDeleteContractor(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
				)
			},
			{ name: 'name', label: 'Name', headerStyle: { minWidth: '300px' } }
		];

		return (
<PageLayout
	title="Contractors"
	isLoading={this.props.isLoading}
	actions={
		[
			{
				label: 'New Contractor',
				onClick: this.newContractor
			}
		]
	}
	content={
		<div>
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />

		<ContentContainer>
			<PersistedDataTable 
				id="contractors"
				name="Contractors"
				data={contractors || []} 
				columns={columns}
			/>
		</ContentContainer>
		</div>
	}
/>
    );
  }
}

Contractors.propTypes = {
	dispatch: PropTypes.func.isRequired,
	contractors: PropTypes.array,
	deleteContractor: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

Contractors.defaultProps = {
	contractors: null
};

const mapStateToProps = (state) => ({
	contractors: state.contractors.contractors,
	isLoading: state.contractors.isLoading,
	saveResult: state.contractors.saveResult
});
const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteContractor: (contractor) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this contractor?',
			onOk: () => {
				dispatch(deleteContractor(contractor));
			}
		}));
	}
});


export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(Contractors);
