import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import DesignCorrespondenceGrid from './designCorrespondenceGrid';
import { 
	saveDesignCorrespondence, 
	deleteDesignCorrespondence, 
	requestDesignCorrespondences, 
	clearDesignCorrespondences 
} from '../../reducers/designCorrespondence';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { getContractId } from '../../selectors/contract';
import { get } from '../../utils/ajax';

class DesignCorrespondence extends Component {
    constructor(props) {
        super(props);

        this.state = {
			correspondence: {},
			tags: []
        };

		this.saveDesignCorrespondence = this.saveDesignCorrespondence.bind(this);
		this.onEditCorrespondence = this.onEditCorrespondence.bind(this);
		this.onViewCorrespondence = this.onViewCorrespondence.bind(this);
		this.onDeleteCorrespondence = this.onDeleteCorrespondence.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestDesignCorrespondences());
	}
	static clearData(dispatch) {
		dispatch(clearDesignCorrespondences());
	}
	componentDidMount() {
		DesignCorrespondence.fetchData(this.props.dispatch);

		get({
			url:`/api/design-correspondence/tags?contractId=${this.props.contractId}`,
			onSuccess: data => {
				this.setState(prevState => ({
					...prevState,
					tags: data
				}));
			},
			onError: error => {
			}
		});
	}
	onEditCorrespondence = (correspondence) => {
		this.props.history.push(`/design-correspondence/${correspondence.designCorrespondenceId}`);
	}
	onViewCorrespondence = (correspondence) => {
		this.props.history.push(`/design-correspondence/${correspondence.designCorrespondenceId}`);
	}
	onDeleteCorrespondence(correspondence) {
		this.props.deleteDesignCorrespondence(correspondence);
	}

	saveDesignCorrespondence() {
		this.props.saveChanges(this.state.correspondence);
	}
	
	onCancelDesignCorrespondence() {
        this.setState({ open: false });
	}

	registerNewCorrespondence = () => {
		this.props.history.push('/design-correspondence');
	}

	render() {
		return (
<PageLayout
	title="Design Correspondence"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'Register Design Correspondence',
				onClick: this.registerNewCorrespondence
			}
		] : []
	}
	content={
		<ContentContainer>
			{ !this.props.isLoading &&
				<DesignCorrespondenceGrid 
					correspondences={this.props.correspondences} 
					onEditCorrespondence={this.onEditCorrespondence} 
					onDeleteCorrespondence={this.onDeleteCorrespondence} 
					onViewCorrespondence={this.onViewCorrespondence} 
					canView={this.props.canView}
					canEdit={this.props.canEdit}
					tags={this.state.tags}
				/>
			}
		</ContentContainer>
	}
/>
		);
	}
}

DesignCorrespondence.propTypes = {
	dispatch: PropTypes.func.isRequired,
	contractId: PropTypes.number.isRequired,
	correspondences: PropTypes.array,
	saveChanges: PropTypes.func.isRequired,
	deleteDesignCorrespondence: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

DesignCorrespondence.defaultProps = {
	correspondences: []
};

const mapStateToProps = (state) => ({
	contractId: getContractId(state),
	correspondences: state.designCorrespondence.designCorrespondences,
	canView: canView(state, 'ViewDesignCorrespondenceItem'),
	canEdit: canEdit(state, 'EditDesignCorrespondence'),
	isLoading: state.designCorrespondence.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	saveChanges: (correspondence) => {
		dispatch(saveDesignCorrespondence(correspondence));
	},
	deleteDesignCorrespondence: (correspondence) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this correspondence?',
			onOk: () => {
				dispatch(deleteDesignCorrespondence(correspondence));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(DesignCorrespondence);
