import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import { hideModal } from '../../reducers/modal';
import { errorColour, errorColourContrast } from '../../variables';

class ConfirmDeleteModal extends React.Component {
	render() {
		return (
<Dialog
	title={this.props.title}
	actions={
		<React.Fragment>
			<Button
				autoFocus
				label='Yes'
				style={{ background: errorColour, color: errorColourContrast }}
				onClick={() => { this.props.hideModal(); this.props.onOk(); }}
			/>,
			<Button
				label='No'
				onClick={() => { this.props.hideModal(); if (this.props.onCancel) this.props.onCancel(); }}
			/>	
		</React.Fragment>		
	}
	open={true}
	onClose={() => { this.props.hideModal(); if (this.props.onCancel) this.props.onCancel(); }}
>
	<span style={{ marginLeft: '10px' }}>{this.props.message}</span>
</Dialog>
		);
	}
}

ConfirmDeleteModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

ConfirmDeleteModal.defaultProps = {
	onCancel: null
};

export default connect(
	() => ({}),
	(dispatch) => ({
		hideModal: () => {
			dispatch(hideModal());
		}
	})
)(ConfirmDeleteModal);
