import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import CircularProgress from '@material-ui/core/CircularProgress';
import NCRsGrid from './ncrsGrid';
import { deleteNCR, requestNCRs, clearNCRs } from '../../reducers/nonConformanceRegister';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { getContractId } from '../../selectors/contract';
import { get } from '../../utils/ajax';

class NCRs extends Component {
    constructor(props) {
        super(props);

        this.state = {
			ncrs: {},
			tags: []
        };

		this.onEditNCR = this.onEditNCR.bind(this);
		this.onViewNCR = this.onViewNCR.bind(this);
		this.onDeleteNCR = this.onDeleteNCR.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestNCRs());
	}
	static clearData(dispatch) {
		dispatch(clearNCRs());
	}
	componentDidMount() {
		NCRs.fetchData(this.props.dispatch);

		get({
			url:`/api/ncrs/tags?contractId=${this.props.contractId}`,
			onSuccess: data => {
				this.setState(prevState => ({
					...prevState,
					tags: data
				}));
			},
			onError: error => {
			}
		});
	}

	onEditNCR = (ncr) => {
		this.props.history.push(`/ncr/${ncr.ncrId}`);
	}

	onViewNCR = (ncr) => {
		this.props.history.push(`/ncr/${ncr.ncrId}`);
	}

	onDeleteNCR = (ncr) => {
		this.props.deleteNCR(ncr);
	}

	newNCR = () => {
		this.props.history.push('/ncr');
	}

	render() {
		return (
<PageLayout
	title="Non-Conformance Register"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Non-Conformance Register',
				onClick: this.newNCR
			}
		] : []
	}
	content={
		<ContentContainer>
			{this.props.isLoading &&
				<CircularProgress />
			}
			<NCRsGrid 
				ncrs={this.props.ncrs} 
				onEditNCR={this.onEditNCR} 
				onDeleteNCR={this.onDeleteNCR} 
				onViewNCR={this.onViewNCR} 
				canView={this.props.canView}
				canEdit={this.props.canEdit}
				tags={this.state.tags}
			/>
		</ContentContainer>
	}   
/>
		);
	}
}

NCRs.propTypes = {
	dispatch: PropTypes.func.isRequired,
	contractId: PropTypes.number.isRequired,
	ncrs: PropTypes.array,
	deleteNCR: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

NCRs.defaultProps = {
	ncrs: []
};

const mapStateToProps = (state) => ({
	contractId: getContractId(state),
	ncrs: state.nonConformanceRegister.ncrs,
	canView: canView(state, 'ViewNonConformanceRegisterItem'),
	canEdit: canEdit(state, 'EditNonConformanceRegister'),
	isLoading: state.nonConformanceRegister.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteNCR: (ncr) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Non-Conformance Register?',
			onOk: () => {
				dispatch(deleteNCR(ncr));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(NCRs);
