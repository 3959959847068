import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { 
	Button as MaterialUIButton,
	CircularProgress
} from '@material-ui/core';
  
class Button extends React.Component {
	render = () => {
		const { 
			disabled, 
			loading, 
			success, 
			error,
			primary
		} = this.props;
		const buttonClasses = classNames({
			'button': true,
			'button-success': success,
			'button-error': error,
		}, this.props.className);

		
		const propsToPassOn = { ...this.props };
		const label = this.props.label;
		propsToPassOn.label = undefined;
		delete propsToPassOn.className;
		delete propsToPassOn.classes;
		delete propsToPassOn.loading;
		delete propsToPassOn.success;
		delete propsToPassOn.error;
		delete propsToPassOn.disabled;
		delete propsToPassOn.primary;

		if (this.props.icon) {
			propsToPassOn.startIcon = this.props.icon;
		}
		delete propsToPassOn.icon;

		return (
			<MaterialUIButton 
				{...propsToPassOn}
				className={buttonClasses}
				disabled={disabled || loading}
				variant={primary ? 'contained' : 'outlined' }
				color={primary ? 'secondary' : undefined}
			>
				{label}
				{this.props.children}
				{loading && <CircularProgress size={24} className="button-progress" />}
			</MaterialUIButton>
		);
	}
}

Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	primary: PropTypes.bool,
	icon: PropTypes.node
};

Button.defaultProps = {
	children: undefined,
	className: '',
	disabled: false,
	loading: false,
	success: false,
	error: false,
	label: undefined,
	primary: false,
	icon: undefined
};

export default Button;
