import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';

class MissingFilesHandoverContent extends React.Component {
	render() {
		const data = this.props.report;

		const columns = [
			{ name: 'name', label: 'Name' },
			{ name: 'referenceType', label: 'Reference Type' },
			{ name: 'referenceName', label: 'Reference Name' }
		];

		return (
		<div>
			<p>The following files are referenced by records but the physical files were unable to be located</p>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

MissingFilesHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default MissingFilesHandoverContent;
