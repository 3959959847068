import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';

const wrapLink = (onClick) => {
	class SidePanelLink extends React.PureComponent {
		render = () =>
			<ReactRouterLink onClick={onClick} {...this.props} />;
	}
	return SidePanelLink;
};

const SidePanelLogo = ({ closeFullScreenSidePanel, clearTempState }) => {
	const Link = wrapLink(() => {
		closeFullScreenSidePanel();
		
		// Clear data table persisted state when navigating from side menu
		// #145
		clearTempState();
	});

	return (			
		<Link to='/' className="logo">
			<span className="logo-lg"><img src="/images/contrack_logo_200x58.jpg" alt="" /></span>
		</Link>
	);
};

SidePanelLogo.propTypes = {
	closeFullScreenSidePanel: PropTypes.func.isRequired,
	clearTempState: PropTypes.func.isRequired
};

SidePanelLogo.defaultProps = {
};

export default SidePanelLogo;
