import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverDateField from '../components/handoverDateField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { tryParseJson } from '../../../utils/utils';

class RegisterHandoverContent extends React.Component {
	render() {
		const data = this.props.report;
		const definition = this.props.registerDefinition;
		const values = tryParseJson(data.values) || {};

		const displayFields = filter(definition.fields, f => f.fieldType !== 'File' && f.fieldType !== 'Correspondence');
		const fileFields = filter(definition.fields, f => f.fieldType === 'File');
		const correspondenceFields = filter(definition.fields, f => f.fieldType === 'Correspondence');

		const filesColumns = [
			{ name: 'name', label: 'Name' }
		];

		const correspondenceColumns = [
			{ name: 'correspondenceType', label: 'Type' },
			{ 
				name: 'correspondence', 
				label: 'Correspondence', 
				value: (args) => {
					return `${args.data.correspondenceNumber}`;
				}
			}
		];

		return (
		<div>
			<div className="handover-data">
				{map(displayFields, f => {
					switch (f.fieldType) {
						case 'Date': 
							return <HandoverDateField label={f.name} value={values[f.name]} />;
						default:
							return <HandoverField label={f.name} value={values[f.name]} />;
						}
				})}
				<HandoverDateField label='Date Created' value={data.dateCreated} />;
				<HandoverDateField label='Date Modified' value={data.dateModified} />;

			</div>

			<div className="handover-items">
				{fileFields.length > 0 &&
					<React.Fragment>
						<HandoverSectionHeader>Files</HandoverSectionHeader>

						<DataTable 
							style={{ marginTop: '20px', width: '100%' }}
							hideActions={true}
							hideSearch={true}
							hideFilter={true}
							hidePaging={true}
							defaultRowsPerPage="All"
							data={data.files || []}
							columns={filesColumns}
						/>
					</React.Fragment>
				}
				{correspondenceFields.length > 0 &&
					<React.Fragment>
						<HandoverSectionHeader>Correspondence</HandoverSectionHeader>

						<DataTable 
							style={{ marginTop: '20px', width: '100%' }}
							hideActions={true}
							hideSearch={true}
							hideFilter={true}
							hidePaging={true}
							defaultRowsPerPage="All"
							data={data.correspondence || []}
							columns={correspondenceColumns}
						/>
					</React.Fragment>
				}
			</div>
		</div>
    );
  }
}

RegisterHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default RegisterHandoverContent;
