import React from 'react';
import PropTypes from 'prop-types';
import SaveIcon from '@material-ui/icons/Save';
import Button from './button';

const FormButtons = ({ 
	isLoading,
	canEdit, 
	hasUnsavedChanges, 
	onSave, 
	onSaveAndContinue, 
	onCancel 
}) => {
	const showSaveButtons = !!canEdit;

	return (
		<div style={{ display: 'inline-block' }}>
			{showSaveButtons &&
				<Button 
					label="Save" 
					primary={true} 
					icon={<SaveIcon />} 
					onClick={onSave} 
					loading={isLoading}
				/>
			}
			{showSaveButtons &&
				<Button 
					label="Save and continue editing" 
					primary={true} 
					onClick={onSaveAndContinue} 
					style={{ marginLeft: '10px' }} 
					loading={isLoading}
				/>
			}
			<Button label={hasUnsavedChanges ? 'Cancel' : 'Back'} onClick={onCancel} style={{ marginLeft: '10px' }} />
		</div>
	);
};

FormButtons.propTypes = {
	isLoading: PropTypes.bool,
	canEdit: PropTypes.bool.isRequired,
	hasUnsavedChanges: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onSaveAndContinue: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

FormButtons.defaultProps = {
	isLoading: false
};

export default FormButtons;
