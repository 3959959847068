import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import PersistedDataTable from '../widgets/persistedDataTable';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import ViewIcon from '@material-ui/icons/Pageview';

class RegistersGrid extends React.Component {
	render() {
		const registers = this.props.registers || [];
		const registerDefinition = this.props.registerDefinition || { fields: [] };

		const columns = [
			{ 
				name: 'hasAttachment', 
				label: <AttachmentIcon />,
				fullLabel: 'Has Attachments',
				dataType: 'bool',
				value: args => args.data.files && args.data.files.length > 0,
				cellRenderer: args => 
					args.data.files && args.data.files.length > 0 ? <AttachmentIcon /> : <span></span>,
				cellStyle: {
					textAlign: 'center'
				},
				filterOperators: ['='],

			}
		];


		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
				<div style={{ whiteSpace: 'nowrap' }}>
					<IconButton onClick={ () => { this.props.onEditRegister(args.data); } } style={{ padding: '2px' }}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={ () => { this.props.onDeleteRegister(args.data); } } style={{ padding: '2px' }}>
						<ActionDelete />
					</IconButton>
				</div>
			});
		} else if (this.props.canView) {	
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewRegister(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}
		
		forEach(sortBy(registerDefinition.fields, ['order']), (f) => {
			let dataType = '';
			let showInGrid = true;
			switch (f.fieldType) {
				case 'Date':
					dataType = 'date';
					break;
				case 'File':
					showInGrid = false;
					break;
				case 'Correspondence':
					showInGrid = false;
					break;
				case 'Tags':
					showInGrid = true;
					break;
				default:
					dataType = undefined;
			}
			if (showInGrid) {
				switch (f.fieldType) {
					case 'Tags':
						columns.push({
							name: f.name,
							label: f.label || f.name,
							displayValue: (args) => map(args.data.values[f.name], (t) => t.name).join(','),
							filterOperators: ['multi-select'],
							filterOptions: this.props.tags,
							filterOptionsKey: 'tagId',
							filterOptionsText: 'name',
							value: (args) => args.data.values[f.name]
						});
						break;
					default:
						columns.push({
							name: f.name,
							label: f.label || f.name,
							dataType: dataType,
							value: (args) => args.data.values[f.name]
						});
				}

			}
		});

		columns.push({
			name: 'dateCreated',
			label: 'Date Created',
			dataType: 'date',
			hideByDefault: true 
		});
		columns.push({
			name: 'dateModified',
			label: 'Date Modified',
			dataType: 'date',
			hideByDefault: true 
		});

		return (
			<div>
			<PersistedDataTable 
				id="registers"
				data={registers} 
				columns={columns}
			/>
			</div>
		);
	}
}

RegistersGrid.propTypes = {
	registers: PropTypes.array,
	registerDefinition: PropTypes.object,
	onEditRegister: PropTypes.func.isRequired,
	onDeleteRegister: PropTypes.func.isRequired,
	onViewRegister: PropTypes.func.isRequired,
	tags: PropTypes.array
};

RegistersGrid.defaultProps = {
	registers: [],
	registerDefinition: null,
	tags: []
};

export default RegistersGrid;
