import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ContactForm from './ContactForm';
import ContentContainer from '../contentContainer';
import MessagePanel from '../messagePanel';
import PageLayout from '../pageLayout';
import { emptySaveResult } from '../../variables';
import { get, post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import { globals } from '../../globals';
import cloneDeep from 'lodash/cloneDeep';

const ContactMaintenance = (props) => {
	const [state, setState] = React.useState({
		contact: null,
		isLoading: false,
		saveResult: emptySaveResult,
		hasUnsavedChanges: false
	});

	const contractId = props.contractId;
	const contactId = props.match.params.contactId;

	React.useEffect(() => {
		if (contactId) {
			setState(prevState => ({ ...prevState, isLoading: true }));
			get({
				url: `/api/contacts/${contactId}?contractId=${contractId}`,
				onSuccess: data => setState(prevState => ({ ...prevState, contact: data, isLoading: false })),
				onError: (error) => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		} else {
			setState(prevState => ({ ...prevState, contact: { ...cloneDeep(globals.templates.contact) } }));
		}
	}, [contactId, contractId]);

	const updateField = (field, newValue) => {
		const contact = state.contact;
		contact[field] = newValue;
        setState(prevState => ({
			...prevState,
			contact,
			hasUnsavedChanges: true
        }));
	};

	const validateContact = (contact) => {
		const errors = [];
		if (!contact.name) {
			errors.push({
				fieldName: 'Name',
				valid: false,
				message: 'Name is required'
			});
		}
		return errors;
	};

	const save = (onSuccess) => {
		const errors = validateContact(state.contact);
		if (errors.length > 0) {
			setState(prevState => ({
				...prevState, 
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: errors
				}
			}));
			return;
		}

		setState(prevState => ({ ...prevState, isLoading: true, saveResult: emptySaveResult }));
			
		post({
			url: `/api/contacts?contractId=${props.contractId}`,
			data: state.contact,
			onSuccess: data => {
				setState({ 
					isLoading: false, 
					contact: data.success ? data.object : state.contact,
					saveResult: {
						success: data.success,
						message: data.message,
						fields: data.fields
					} 
				});

				if (data.success) {
					props.showSuccessNotification(data.message);
					if (onSuccess) onSuccess();
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};
	const saveChanges = () => save(() => { props.history.goBack(); });
	const saveChangesAndContinue = () => save();

	return (
		<PageLayout
			title={state.contact ? state.contact.name : ''}
			isLoading={state.isLoading}
			formActions={{
				isLoading: state.isLoading,
				canEdit: props.canEdit,
				hasUnsavedChanges: state.hasUnsavedChanges,
				onSave: () => saveChanges(state.contact),
				onSaveAndContinue: () => saveChangesAndContinue(state.contact),
				onCancel: () => props.history.goBack()
			}}
			content={
				<div>
					<ContentContainer>
						<ContactForm 
							contact={state.contact || {}}
							updateField={updateField} 
							saveResult={state.saveResult} 
						/>
					</ContentContainer>

					<MessagePanel 
						isSuccess={state.saveResult.success} 
						message={state.saveResult.message} 
					/>
				</div>
			}
		/>
	);
};

ContactMaintenance.propTypes = {
	params: PropTypes.object.isRequired,
	contractId: PropTypes.number.isRequired,
	canEdit: PropTypes.bool.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	contractId: state.context.contract ? state.context.contract.contractId : 0,
	canEdit: canEditAnyPeriod(state, 'EditContacts')
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ContactMaintenance);
