import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import PersistedDataTable from '../widgets/persistedDataTable';

class VariationsGrid extends React.Component {
	// shouldComponentUpdate(nextProps) {
	// 	return this.props.variations.length !== nextProps.variations.length;
	// }

	render() {
		const columns = [
			{ name: 'variationNo', label: 'Variation No' },
			{ 
				name: 'hasAttachment', 
				label: <AttachmentIcon />,
				fullLabel: 'Has Attachments',
				dataType: 'bool',
				value: args => args.data.files && args.data.files.length > 0,
				cellRenderer: args => 
					args.data.files && args.data.files.length > 0 ? <AttachmentIcon /> : <span></span>,
				cellStyle: {
					textAlign: 'center'
				},
				filterOperators: ['='],

			},
			{ name: 'contractorVariationNo', label: 'Contractor Variation No' },
			{ 
				name: 'category', 
				label: 'Category',
				filterOperators: ['multi-select'],
				filterOptions: ['Principal', 'Administrator', 'Design related', 'PUP', 'Site related', 'Other']
			},
			{ name: 'description', label: 'Variation Description', headerStyle: { minWidth: '300px' } },
			{ 
				name: 'status', 
				label: 'Variation Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					if (args.data.status === 'Processing') {
						statusBackColour = '#FFC7CE';
						statusForeColour = '#9C0006';
					}
					if (args.data.status === 'Approved') {
						statusBackColour = '#C6EFCE';
						statusForeColour = '#2C6153';
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Processing', 'Approved', 'Rejected', 'Withdrawn']
			},
			{ name: 'numItems', label: 'Items', dataType: 'number' },
			{ 
				name: 'totalAmount', 
				label: 'Variation Amount', 
				dataType: 'number',
				formatString: 'currency',
				showTotal: true
			},
			{ 
				name: 'certifiedAmount', 
				label: 'Approved/Certified Amount', 
				dataType: 'number',
				formatString: 'currency',
				showTotal: true,
				value: (args) => Math.max(args.data.totalAmount, args.data.certifiedAmount)
			},
			{ 
				name: 'estimatedValue', 
				label: 'Estimated Value', 
				dataType: 'number', 
				formatString: 'currency' 
			},
			{ name: 'reasonForVariation', label: 'Reason For Variation' }
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.handleEditVariation(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.handleDeleteVariation(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.handleViewVariation(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="variations"
				data={this.props.variations || []} 
				columns={columns}
			/>
		);
	}
}

VariationsGrid.propTypes = {
	variations: PropTypes.array.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	handleViewVariation: PropTypes.func.isRequired,
	handleEditVariation: PropTypes.func.isRequired,
	handleDeleteVariation: PropTypes.func.isRequired
};

export default VariationsGrid;
