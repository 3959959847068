import React from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';

const WarningBanner = ({ 
	text 
}) =>
	<div className="warning-banner">
		<WarningIcon /><p>{text}</p>
	</div>;

WarningBanner.propTypes = {
	text: PropTypes.string.isRequired
};

export default WarningBanner;
