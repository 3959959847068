
import { createFetchAction } from '../utils/reducer-utils';

const initialState = {
	loading: true,
	data: null,
	error: null
};

export const BOOTSTRAP_REQUEST = "BOOTSTRAP_REQUEST";
export const BOOTSTRAP_SUCCESS = "BOOTSTRAP_SUCCESS";
export const BOOTSTRAP_FAILURE = "BOOTSTRAP_FAILURE";

export const bootstrap = () => 
	createFetchAction({
		url: "/api/bootstrap",
		startAction: BOOTSTRAP_REQUEST,
		onError: error => bootstrapFailure(error),
		success: data => bootstrapSuccess(data)
	});

export const bootstrapSuccess = data => ({ type: BOOTSTRAP_SUCCESS, data });
export const bootstrapFailure = error => ({ type: BOOTSTRAP_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};