import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderProgressIndicator from './widgets/headerProgressIndicator';
import Button from './widgets/button';
import map from 'lodash/map';
import IconMenu from './widgets/IconMenu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/MenuItem';
import Divider from './widgets/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FormButtons from './widgets/formButtons';
import SaveIcon from '@material-ui/icons/Save';
import { primaryColour } from '../variables';

class PageLayout extends Component {
	componentDidMount = () => {
		window.addEventListener('resize', this.onWindowResize);
		window.addEventListener('scroll', this.onWindowScroll);
		window.addEventListener("keydown", this.onFormKeyPress);
		
		this.fixHeader();
		this.toggleActions();
	}
	
    componentWillUnmount = () => {
		window.removeEventListener('resize', this.onWindowResize);
		window.removeEventListener('scroll', this.onWindowScroll);
		window.removeEventListener("keydown", this.onFormKeyPress);
	}

	onWindowResize = () => {
		this.toggleActions();
	}
	onWindowScroll = () => {
		this.fixHeader();
	}

	onFormKeyPress = (e) => {
		if (e.shiftKey && e.ctrlKey && e.key === 's') {
			e.preventDefault();
			if (this.props.formActions && this.props.formActions.onSaveAndContinue) this.props.formActions.onSaveAndContinue();
		}else if (e.ctrlKey && e.key === 's') {
			e.preventDefault();
			if (this.props.formActions && this.props.formActions.onSave) this.props.formActions.onSave();
		} else if (e.key === 'Escape') {
			e.preventDefault();
			if (this.props.formActions && this.props.formActions.onCancel) this.props.formActions.onCancel();
		}
	}

	componentDidUpdate = () => {
		this.fixHeader();
		this.toggleActions();
	}

	fixHeader = () => {
		if (!this.contentHeader) return;
		const headerTop = this.contentHeader.offsetTop;
		if (window.pageYOffset > headerTop) {
			this.contentHeader.classList.add('sticky-header');
		} else {
			this.contentHeader.classList.remove('sticky-header');
		}
	}

	getElementInternalWidth = (element) => {
		const computedStyle = window.getComputedStyle(element);
		let elementWidth = element.clientWidth;   // width with padding

		elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

		return elementWidth;
	}

	toggleActions = () => {
		if (!this.contentHeader) return;
		const title = this.contentHeader.children[0];
		const actions = this.contentHeader.children[1];

		if (this.getElementInternalWidth(this.contentHeader) < title.offsetWidth + actions.offsetWidth) {
			this.contentHeader.classList.add('collapsed-actions');
		} else {
			this.contentHeader.classList.remove('collapsed-actions');
		}
	}

	buildMenu = (closeMenu) => {
		const headerMenuItems = [];

		if (this.props.formActions && !!this.props.formActions.canEdit) {
			headerMenuItems.push(
				<MenuItem 
					key="save"
					onClick={this.props.formActions.onSave}
					leftIcon={<SaveIcon style={{ color: primaryColour }} color={primaryColour} />}
					style={{ color: primaryColour }}
				>Save</MenuItem>
			);
		}
	
		if (this.props.formActions && !!this.props.formActions.canEdit) {
			headerMenuItems.push(
				<MenuItem 
					key="save-and-continue"
					onClick={this.props.formActions.onSaveAndContinue}
					style={{ color: primaryColour }}
				>Save and continue editing</MenuItem>
			);
		}
		if (this.props.formActions) {
			headerMenuItems.push(
				<MenuItem 
					key="cancel-form"
					onClick={this.props.formActions.onCancel}
				>{this.props.formActions.hasUnsavedChanges ? 'Cancel' : 'Back'}</MenuItem>
			);
		}
		if (this.props.formActions && this.props.actions && this.props.actions.length > 0) {
			headerMenuItems.push(<Divider key="page-divider-1" />);
		}
		headerMenuItems.push(
			...map(this.props.actions, (a, i) => 
				<MenuItem 
					key={`page-action-${i}`}
					onClick={a.onClick}
					leftIcon={a.icon ? 
						React.cloneElement(
							a.icon,
							{ 
								style: { 
									color: a.primary ? primaryColour : undefined 
								}
							}
						) : a.icon
					}
					rightIcon={a.rightIcon ? 
						React.cloneElement(
							a.rightIcon,
							{ 
								style: { 
									color: a.primary ? primaryColour : undefined 
								}
							}
						) : a.rightIcon
					}
					style={{ color: a.primary ? primaryColour : undefined }}
				>{a.label}</MenuItem>
			)
		);
		return headerMenuItems;
	}

	render = () => {
		return (
<div className={this.props.className}>
	{this.props.showHeader &&
	<section className="content-header" ref={(el) => { this.contentHeader = el; }}>
		<div style={{ display: 'inline-block' }}>
			<h1>
				<span>{this.props.title}</span>
			</h1>
			{this.props.isLoading && <HeaderProgressIndicator />}
		</div>
		<div className="header-actions">
			{this.props.formActions && 
				<FormButtons {...this.props.formActions} />
			}
			{
				map(this.props.actions, (a, i) => {
					const ButtonWidget = 
						<Button
							key={`page-action-${i}`}
							label={a.label} 
							onClick={a.onClick}
							startIcon={a.icon}
							endIcon={a.rightIcon}
							primary={a.primary}
							style={{ marginLeft: '10px' }}
							disabled={a.disabled === undefined ? false : a.disabled}
							loading={a.isLoading}
						/>;
					if (a.tooltip) {
						return (
							<Tooltip title={a.tooltip}>
								{ButtonWidget}
							</Tooltip>
						);
					} else {
						return ButtonWidget;
					}
				})
			}
		</div>
		{(this.props.formActions || this.props.actions) && 
		<div className="header-menu">
			<IconMenu
				style={{ position: 'absolute', right: '0', top: '8px' }}
				icon={<MoreVertIcon />}
				items={this.buildMenu}
			/>
		</div>
		}
	</section>
	}
	<section className="content">
		{this.props.formActions &&
			<form 
				onSubmit={(e) => {
					e.preventDefault(); // Stop the page reload
					// if (this.props.formActions.canEdit) {
					// 	this.props.formActions.onSave();
					// }
				}}
			>
				{this.props.content}

				<div className="content__form-actions">
					{/*
						We need a submit button to make enter submit the form. If we make
						the Save button in FormButtons submit however it will trigger both 
						its onClick and the form onSubmit. So instead we add a hidden submit
						button so we get the Enter functionality without the doubling on the
						events
					*/}
					<button type="submit" style={{ display: 'none' }} />

					<FormButtons {...this.props.formActions} isLoading={this.props.isLoading} />
				</div>
			</form>
		}

		{!this.props.formActions && this.props.content}
	</section>
</div>
		);
	}
}

PageLayout.propTypes = {
	title: PropTypes.any,
	className: PropTypes.string,
	content: PropTypes.element.isRequired,
	actions: PropTypes.array,
	formActions: PropTypes.object,
	isLoading: PropTypes.bool,
	showHeader: PropTypes.bool
};

PageLayout.defaultProps = {
	title: '',
	showHeader: true,
	className: '',
	actions: [],
	formActions: null,
	isLoading: false
};

export default PageLayout;
