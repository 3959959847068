import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import { addOrUpdate } from '../utils/utils';
import { globals } from '../globals';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	ncr: null, 
	ncrs: null
};

const CLEAR_NCRS = 'CLEAR_NCRS';
const CLEAR_NCR = 'CLEAR_NCR';
const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT';
const DELETE_NCR = 'DELETE_NCR';
const REQUEST_NCRS = 'REQUEST_NCRS';
const RECEIVE_NCRS = 'RECEIVE_NCRS';
const REQUEST_NCR = 'REQUEST_NCR';
const RECEIVE_NCR = 'RECEIVE_NCR';
const CREATE_NEW_NCR = 'CREATE_NEW_NCR';
const SAVE_NCR = 'SAVE_NCR';
const RECEIVE_SAVE_NCR_RESPONSE = 'RECEIVE_SAVE_NCR_RESPONSE';
const RECEIVE_DELETE_NCR_RESPONSE = 'RECEIVE_DELETE_NCR_RESPONSE';
const SHOW_NCR_ERRORS = 'SHOW_NCR_ERRORS';
const EDIT_NCR = 'EDIT_NCR';

export const clearNCRs = () => ({ type: CLEAR_NCRS });
export const clearNCR = () => ({ type: CLEAR_NCR });
export const clearSaveResult = () => ({ type: CLEAR_SAVE_RESULT });
export const receiveNCRs = (data) => ({ type: RECEIVE_NCRS, payload: { data } });
export const receiveNCR = (data) => ({ type: RECEIVE_NCR, payload: { data } });
export const receiveSaveNCRResponse = (data) => ({ type: RECEIVE_SAVE_NCR_RESPONSE, data });
export const receiveDeleteNCRResponse = (data) => ({ type: RECEIVE_DELETE_NCR_RESPONSE, data });
export const editNCR = (ncr) => ({ type: EDIT_NCR, ncr });

export const createNewNCR = () =>
	(dispatch) => {	
		const newNCR = {
			...cloneDeep(globals.templates.ncr)
		};
		dispatch({ type: CREATE_NEW_NCR, newNCR: newNCR });
	};

export const requestNCRs = () => (
	createFetchAction({
		objectName: 'Non-Conformance Register',
		passContext: true,
		url: '/api/ncrs',
		startAction: REQUEST_NCRS,
		success: (data) =>
			receiveNCRs(map(data, (ncr) => ({
				...ncr,
				dateIdentified: ncr.dateIdentified ? new Date(ncr.dateIdentified) : null,
				dateCarRaised: ncr.dateCarRaised ? new Date(ncr.dateCarRaised) : null,
				dateNcrRaised: ncr.dateNcrRaised ? new Date(ncr.dateNcrRaised) : null,
				responseDate: ncr.responseDate ? new Date(ncr.responseDate) : null,
				dateResubmitted: ncr.dateResubmitted ? new Date(ncr.dateResubmitted) : null,
				dateClosed: ncr.dateClosed ? new Date(ncr.dateClosed) : null
			})))
	})
);

export const requestNCR = (ncrId) => (
	createFetchAction({
		objectName: 'Non-Conformance Register',
		passContext: true,
		url: `/api/ncrs/${ncrId}`,
		startAction: REQUEST_NCR,
		success: (ncr) =>
			receiveNCR({
				...ncr,
				dateIdentified: ncr.dateIdentified ? new Date(ncr.dateIdentified) : null,
				dateCarRaised: ncr.dateCarRaised ? new Date(ncr.dateCarRaised) : null,
				dateNcrRaised: ncr.dateNcrRaised ? new Date(ncr.dateNcrRaised) : null,
				responseDate: ncr.responseDate ? new Date(ncr.responseDate) : null,
				dateResubmitted: ncr.dateResubmitted ? new Date(ncr.dateResubmitted) : null,
				dateClosed: ncr.dateClosed ? new Date(ncr.dateClosed) : null
			})
	})
);

const validateNCR = (ncr) => {
	const errors = [];
	if (!ncr.contractorNcrNo) {
		errors.push({
			fieldName: 'ContractorNcrNo',
			valid: false,
			message: 'Contractor NCR Number is required'
		});
	}
	return errors;
};

export const saveNCR = (ncr, onSuccess) => {
	const errors = validateNCR(ncr);
	if (errors.length > 0) return { type: SHOW_NCR_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/ncrs',
		data: ncr,
		startAction: SAVE_NCR,
		success: (data, dispatch) => {
			dispatch(receiveSaveNCRResponse({
				...data,
				object: data.object ? {
					...data.object,
					dateIdentified: data.object.dateIdentified ? new Date(data.object.dateIdentified) : null,
					dateCarRaised: data.object.dateCarRaised ? new Date(data.object.dateCarRaised) : null,
					dateNcrRaised: data.object.dateNcrRaised ? new Date(data.object.dateNcrRaised) : null,
					responseDate: data.object.responseDate ? new Date(data.object.responseDate) : null,
					dateResubmitted: data.object.dateResubmitted ? new Date(data.object.dateResubmitted) : null,
					dateClosed: data.object.dateClosed ? new Date(data.object.dateClosed) : null
				} : data.object
			}));
			if (data.success && data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const saveNCRAndNew = (ncr) => {
	const errors = validateNCR(ncr);
	if (errors.length > 0) return { type: SHOW_NCR_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/ncrs',
		data: ncr,
		startAction: SAVE_NCR,
		success: (data, dispatch) => {
			dispatch(receiveSaveNCRResponse(data));
			if (data.success) dispatch(createNewNCR());
		}
	});
};

export const deleteNCR = (ncr) => (
	createPostAction({
		passContext: true,
		url: `/api/ncrs/${ncr.ncrId}/delete`,
		data: ncr,
		startAction: DELETE_NCR,
		success: (data) => receiveDeleteNCRResponse(data)
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_NCRS:
			return {
				...state,
				ncrs: null
			};
		case CLEAR_NCR:
			return {
				...state,
				ncr: null
			};
		case CLEAR_SAVE_RESULT:
			return {
				...state,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case EDIT_NCR:
			return {
				...state,
				ncr: action.ncr,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};
		case REQUEST_NCRS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				ncrs: []
			};
		case RECEIVE_NCRS:
			return {
				...state,
				isLoading: false,
				ncrs: action.payload.data
			};
		case REQUEST_NCR:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				ncr: globals.templates.ncr
			};
		case RECEIVE_NCR:
			return {
				...state,
				isLoading: false,
				ncr: action.payload.data
			};
		case DELETE_NCR:
			return { 
				...state
			};
		case RECEIVE_DELETE_NCR_RESPONSE:
			return {
				...state,
				ncrs: state.ncrs.filter(c => c.ncrId !== action.data.objectId) 
			};
		case CREATE_NEW_NCR:
			return {
				...state,
				ncr: action.newNCR,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				}
			};	
		case SAVE_NCR:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_NCR_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				ncrs: addOrUpdate(state.ncrs, action.data.object, { ncrId: action.data.object.ncrId }), 
				ncr: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_NCR_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
