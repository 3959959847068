/* eslint object-curly-newline: "off" */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentContainer from '../contentContainer';
import { 
	requestProgressClaim, 
	clearProgressClaim, 
	clearSaveResult, 
	saveProgressClaim 
} from '../../reducers/progressClaims';
import { requestScheduleOfRates } from '../../reducers/scheduleOfRates';
import map from 'lodash/map';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import remove from 'lodash/remove';
import ProgressIndicator from '../widgets/progressIndicator';
import SaveIcon from '@material-ui/icons/Save';
import MessagePanel from '../messagePanel';
import PersistedDataTable from '../widgets/persistedDataTable';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import DataTableTextEditor from '../widgets/dataTableTextEditor';
import round from 'lodash/round';
import Popover from '../widgets/Popover';
import { toCurrency } from '../../utils/utils';
import { canEdit } from '../../selectors/canEdit';
import { units } from '../../variables';
import PageLayout from '../pageLayout';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import AddIcon from '@material-ui/icons/Add';
import TempClaimItemDialog from './TempClaimItemDialog';
import uniqueId from 'lodash/uniqueId';
import IconButton from '../widgets/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

class ProgressClaims extends Component {
    constructor(props) {
        super(props);

		const progressClaim = props.progressClaim;
		if (progressClaim && progressClaim.items) {
			forEach(progressClaim.items, i => {
				i._clientKey = uniqueId("pci_")
			});
		}

        this.state = {
            progressClaim: progressClaim || { items: [] },
			scheduleColours: ['#FEECD1', '#E8EBFF', '#FFF3BF', '#E0FFE3', '#F2F2F2', '#BFBFBF'],
			showCertifiedAmountPopover: false,
			certifiedAmountPopoverElement: null,
			showTempClaimItemDialog: false,
			tempClaimItem: null
        };

		this.onSave = this.onSave.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
		this.updateClaimQty = this.updateClaimQty.bind(this);
		this.updateCertifiedQty = this.updateCertifiedQty.bind(this);
		this.updateCertifiedAmount = this.updateCertifiedAmount.bind(this);
		this.updateComments = this.updateComments.bind(this);
		this.onMouseOverCertifiedAmount = this.onMouseOverCertifiedAmount.bind(this);
		this.onMouseLeaveCertifiedAmount = this.onMouseLeaveCertifiedAmount.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestProgressClaim());
		dispatch(requestScheduleOfRates());
	}
	static clearData(dispatch) {
		dispatch(clearProgressClaim());
	}

	componentDidMount() {
		ProgressClaims.fetchData(this.props.dispatch);
		window.addEventListener('resize', this.onWindowResize);
		this.setTableHeightToAvailable();
	}
	componentWillUnmount = () => {
		window.removeEventListener('resize', this.onWindowResize);
	}
	onWindowResize = () => {
		this.setTableHeightToAvailable();
	}
	setTableHeightToAvailable = () => {
		const dataTable = document.querySelector('#progressClaims .table-container');
		const dataTableDimensions = dataTable.getBoundingClientRect();
		const footer = document.getElementsByClassName('main-footer')[0];
		const dataTableBodyY = dataTableDimensions.top - window.scrollY;
		const footerY = footer.getBoundingClientRect().top;
		const availableHeight = footerY - dataTableBodyY - 90;	 /* extra for paging, margin, etc */
		this.setState({ tableHeight: availableHeight });
	}
	
	UNSAFE_componentWillReceiveProps(nextProps) {
		const progressClaim = nextProps.progressClaim;
		if (progressClaim && progressClaim.items) {
			forEach(progressClaim.items, i => {
				i._clientKey = uniqueId("pci_")
			});
		}

		this.setState({
			progressClaim: progressClaim || { items: [] }
		});
	}

    handleOpen() {
        this.setState({ open: true });
    }

    handleClose() {
        this.setState({ open: false });
    }
	updateClaimQty(item, qty) {
		const progressClaimItem = find(this.state.progressClaim.items, (i) => i._clientKey === item._clientKey);
		if (progressClaimItem) {
			if (Number(qty).toString() === qty) {
				progressClaimItem.claimQty = Number(qty);
			} else {
				progressClaimItem.claimQty = qty;
			}
			progressClaimItem.claimAmount = progressClaimItem.claimQty * progressClaimItem.rate;
		}

		// Need to call setState to update the item
        this.setState({
			progressClaim: this.state.progressClaim
        });
	}
	updateClaimAmount(item, amount) {
		const progressClaimItem = find(this.state.progressClaim.items, (i) => i._clientKey === item._clientKey);
		if (progressClaimItem) {
			if (Number(amount).toString() === amount) {
				progressClaimItem.claimAmount = Number(amount);
			} else {
				progressClaimItem.claimAmount = amount;
			}
		}

		// Need to call setState to update the item
        this.setState({
			progressClaim: this.state.progressClaim
        });
	}
	updateCertifiedQty(item, qty) {
		const progressClaimItem = find(this.state.progressClaim.items, (i) => i._clientKey === item._clientKey);
		if (progressClaimItem) {
			if (Number(qty).toString() === qty) {
				progressClaimItem.certifiedQty = Number(qty);
			} else {
				progressClaimItem.certifiedQty = qty;
			}
		}

		// Need to call setState to update the item
        this.setState({
			progressClaim: this.state.progressClaim
        });
	}
	updateCertifiedAmount(item, qty) {
		const progressClaimItem = find(this.state.progressClaim.items, (i) => i._clientKey === item._clientKey);
		if (progressClaimItem) {
			if (qty === progressClaimItem.calcCertifiedAmount) {
				progressClaimItem.certifiedAmount = null;
			} else if (qty === null) {
				progressClaimItem.certifiedAmount = null;
			} else if (Number(qty).toString() === qty) {
				progressClaimItem.certifiedAmount = Number(qty);	
			} else {
				progressClaimItem.certifiedAmount = qty;
			}
		}

		// Need to call setState to update the item
        this.setState({
			progressClaim: this.state.progressClaim
        });
	}
	updateComments(item, comments) {
		const progressClaimItem = find(this.state.progressClaim.items, (i) => i._clientKey === item._clientKey);
		if (progressClaimItem) {
			progressClaimItem.comments = comments;
		}

		// Need to call setState to update the item
        this.setState({
			progressClaim: this.state.progressClaim
        });
	}
	onSave() {
		this.props.saveChanges(this.state.progressClaim);
	}

	onMouseOverCertifiedAmount(e, item) {
        this.setState({
			showCertifiedAmountPopover: true,
			certifiedAmountPopoverElement: e.currentTarget,
			calcCertifiedAmount: item.calcCertifiedAmount
        });
	}
	onMouseLeaveCertifiedAmount(e) {
        this.setState({
			showCertifiedAmountPopover: false,
			certifiedAmountPopoverElement: e.currentTarget
        });
	}

	render() {
		const progressClaim = this.state.progressClaim;
		const scheduleOfRates = this.props.scheduleOfRates || [];

		const scheduleTotals = [];
		const total = {
			qty: 0,
			amount: 0,
			prevPeriodClaimQty: 0,
			prevPeriodClaimAmount: 0,
			claimQty: 0,
			claimAmount: 0,
			prevClaimQty: 0,
			prevClaimAmount: 0,
			prevCertifiedQty: 0,
			prevCertifiedAmount: 0,
			certifiedQty: 0,
			certifiedAmount: 0
		};
		let scheduleTotal = null;
		forEach(progressClaim.items, (c) => {
			if (c.scheduleOfRatesId > 0) {
				if (!scheduleTotal || scheduleTotal.scheduleOfRatesId !== c.scheduleOfRatesId) {
					scheduleTotal = find(scheduleTotals, (s) => s.scheduleOfRatesId === c.scheduleOfRatesId);
				}
				if (!scheduleTotal) {
					const schedule = find(scheduleOfRates, (s) => s.scheduleOfRatesId === c.scheduleOfRatesId);
					scheduleTotal = {
						scheduleOfRatesId: c.scheduleOfRatesId,
						name: schedule ? schedule.name : '',
						qty: 0,
						amount: 0,
						prevPeriodClaimQty: 0,
						prevPeriodClaimAmount: 0,
						claimQty: 0,
						claimAmount: 0,
						prevClaimQty: 0,
						prevClaimAmount: 0,
						prevCertifiedQty: 0,
						prevCertifiedAmount: 0,
						certifiedQty: 0,
						certifiedAmount: 0
					};
					scheduleTotals.push(scheduleTotal);
				}
				scheduleTotal.qty += c.qty;
				scheduleTotal.amount += round(c.qty * c.rate, 2);
				scheduleTotal.prevPeriodClaimQty += c.prevPeriodClaimQty;
				scheduleTotal.prevPeriodClaimAmount += c.prevPeriodClaimAmount;
				scheduleTotal.claimQty += c.claimQty;
				scheduleTotal.claimAmount += c.claimAmount;
				scheduleTotal.prevClaimQty += c.prevClaimQty;
				scheduleTotal.prevClaimAmount += c.prevClaimAmount;
				scheduleTotal.prevCertifiedQty += c.prevCertifiedQty;
				scheduleTotal.prevCertifiedAmount += c.prevCertifiedAmount;
				scheduleTotal.certifiedQty += c.certifiedQty;
				scheduleTotal.certifiedAmount += c.certifiedAmount || c.calcCertifiedAmount;
			}

			total.qty += c.qty;
			total.amount += round(c.qty * c.rate, 2);
			total.prevPeriodClaimQty += c.prevPeriodClaimQty;
			total.prevPeriodClaimAmount += c.prevPeriodClaimAmount;
			total.claimQty += c.claimQty;
			total.claimAmount += c.claimAmount;
			total.prevClaimQty += c.prevClaimQty;
			total.prevClaimAmount += c.prevClaimAmount;
			total.prevCertifiedQty += c.prevCertifiedQty;
			total.prevCertifiedAmount += c.prevCertifiedAmount;
			total.certifiedQty += c.certifiedQty;
			total.certifiedAmount += c.certifiedAmount || c.calcCertifiedAmount;
		});

		let colourIndex = 0;
		const scheduleColours = map(scheduleOfRates, (s) => {
			colourIndex += 1;
			const colour = this.state.scheduleColours[colourIndex % this.state.scheduleColours.length];
			return { scheduleOfRatesId: s.scheduleOfRatesId, colour: colour };
		});
								
		const columns = [
			{
				name: 'itemNo',
				label: 'Item No',
				headerClassName: 'schedule-header',
				headerStyle: { backgroundColor: '#d8e4bc', top: '62px' },
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					const style = { 
							backgroundColor: scheduleColour ? scheduleColour.colour : null
					};
					if (args.data.isTextOnlyLine) {
						style.fontStyle = 'italic';
						style.fontWeight = 'bold';
					}
					return style;
				},
				cellRenderer: (args) => {
					if (args.data.claimType === 'Temporary') {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton 
									onClick={ () => { 
										this.setState({
											...this.state,
											showTempClaimItemDialog: true,
											tempClaimItem: args.data
										});
									} } 
									style={{ padding: '2px' }}
								>
									<EditIcon />
								</IconButton>
								<IconButton 
									onClick={ () => { 
										remove(this.state.progressClaim.items, (i) => i._clientKey === args.data._clientKey);
										this.setState({
											progressClaim: this.state.progressClaim
										});
									} } 
									style={{ padding: '2px' }}
								>
									<DeleteIcon />
								</IconButton>
								<span style={{ marginLeft: '5px' }}>{args.data.itemNo}</span>
							</div>
						);
					} else {
						return args.data.itemNo;
					}
				}
			},
			{
				name: 'description',
				label: 'Description',
				headerClassName: 'schedule-header',
				headerStyle: { minWidth: '300px', backgroundColor: '#d8e4bc', top: '62px' },
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					const style = { 
							backgroundColor: scheduleColour ? scheduleColour.colour : null
					};
					if (args.data.isTextOnlyLine) {
						style.fontStyle = 'italic';
						style.fontWeight = 'bold';
					}
					return style;
				}
			},
			{
				name: 'additionalInfo',
				label: 'Additional Info',
				headerClassName: 'schedule-header',
				headerStyle: { minWidth: '300px', backgroundColor: '#d8e4bc', top: '62px' },
				colspan: (args) => args.data.isTextOnlyLine ? 5 : undefined,
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					const style = { 
							backgroundColor: scheduleColour ? scheduleColour.colour : null
					};
					if (args.data.isTextOnlyLine) {
						style.fontStyle = 'italic';
						style.fontWeight = 'bold';
					}
					return style;
				}
			},
			{
				name: 'unit', 
				label: 'Unit',
				filterOperators: ['=', 'Contains', 'multi-select'],
				filterOptions: units,
				excludeFromSearch: true,
				headerClassName: 'schedule-header',
				headerStyle: { backgroundColor: '#d8e4bc', top: '62px' },
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					return (
						{ 
							backgroundColor: scheduleColour ? scheduleColour.colour : null
						}
					);
				}
			},
			{
				name: 'qty',
				label: 'Qty',
				excludeFromSearch: true,
				headerClassName: 'schedule-header',
				headerStyle: { backgroundColor: '#d8e4bc', top: '62px' },
				dataType: 'number',
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					return (
						{ 
							textAlign: 'right',
							backgroundColor: scheduleColour ? scheduleColour.colour : null
						}
					);
				}
			},
			{
				name: 'rate',
				label: 'Rate',
				dataType: 'number',
				excludeFromSearch: true,
				headerClassName: 'schedule-header',
				headerStyle: { backgroundColor: '#d8e4bc', top: '62px' },
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					return (
						{ 
							textAlign: 'right',
							backgroundColor: scheduleColour ? scheduleColour.colour : null
						}
					);
				},
				formatString: 'currency'
			},
			{
				name: 'amount',
				label: 'Amount',
				dataType: 'number',
				excludeFromSearch: true,
				headerClassName: 'schedule-header',
				headerStyle: { backgroundColor: '#d8e4bc', top: '62px' },
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					return (
						{ 
							textAlign: 'right',
							backgroundColor: scheduleColour ? scheduleColour.colour : null
						}
					);
				},
				formatString: 'currency',
				value: (args) => args.data.qty * args.data.rate
			},
			{
				name: 'prevCertifiedQty',
				label: 'Qty',
				fullLabel: 'Previous Claim Qty',
				dataType: 'number',
				displayValue: (args) => args.data.isTextOnlyLine ? '' : args.data.prevCertifiedQty,
				excludeFromSearch: true,
				headerClassName: 'schedule-header',
				headerStyle: { backgroundColor: '#d8e4bc', top: '62px' },
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					return (
						{ 
							textAlign: 'right',
							backgroundColor: scheduleColour ? scheduleColour.colour : null
						}
					);
				}
			},
			{
				name: 'prevCertifiedAmount',
				label: 'Amount',
				fullLabel: 'Previous Claim Amount',
				dataType: 'number',
				displayValue: (args) => args.data.isTextOnlyLine ? '' : args.data.prevCertifiedAmount,
				excludeFromSearch: true,
				formatString: 'currency',
				headerClassName: 'schedule-header',
				headerStyle: { backgroundColor: '#d8e4bc', top: '62px' },
				cellStyle: (args) => {
					const scheduleColour = find(scheduleColours, (s) => s.scheduleOfRatesId === args.data.scheduleOfRatesId);
					return (
						{ 
							textAlign: 'right',
							backgroundColor: scheduleColour ? scheduleColour.colour : null
						}
					);
				}
			},
			{
				name: 'claimQty',
				label: 'Qty',
				fullLabel: 'Contractor Claim Qty',
				dataType: 'number',
				excludeFromSearch: true,
				cellRenderer: (args) => {
					if (args.data.isTextOnlyLine) {
						return '';
					} else if (this.props.canEdit && args.data.claimType !== 'Temporary') {
						return (
							<div className="editable-field">
								<DataTableNumericEditor
									type="decimal"
									value={args.data.claimQty} 
									onChange={(v) => { this.updateClaimQty(args.data, v); }}
									style={{ width: 'auto' }}
									underlineStyle={{ borderBottom: '2px solid #5bc0de' }}
								/>
							</div>
						);
					} else {
						return args.formattedValue;
					}
				},
				headerStyle: { backgroundColor: '#e6b8b7', top: '62px' },
				cellStyle: { textAlign: 'right', backgroundColor: '#e6b8b7' },
				headerClassName: 'contractor-header',
				cellClassName: 'contractor-data'
			},
			{
				name: 'claimAmount',
				label: 'Amount',
				fullLabel: 'Contractor Claim Amount',
				dataType: 'number',
				excludeFromSearch: true,
				formatString: 'currency',
				cellRenderer: (args) => {
					if (args.data.isTextOnlyLine) {
						return '';
					} else if (this.props.canEdit && args.data.claimType === 'Temporary') {
						return (
							<div className="editable-field">	
								<DataTableNumericEditor
									type="currency"
									value={args.data.claimAmount} 
									nullable={true}
									onChange={(v) => { this.updateClaimAmount(args.data, v); }}
									style={{ width: 'auto' }}
									underlineStyle={{ borderBottom: '2px solid #5bc0de' }}
								/>
							</div>
						);
					} else {
						return args.formattedValue;
					}
				},
				headerStyle: { backgroundColor: '#e6b8b7', top: '62px' },
				cellStyle: { textAlign: 'right', backgroundColor: '#e6b8b7' },
				headerClassName: 'contractor-header',
				cellClassName: 'contractor-data'
			},
			{
				name: 'certifiedQty',
				label: 'Qty',
				fullLabel: 'Certified This Period Qty',
				dataType: 'number',
				excludeFromSearch: true,
				headerStyle: { backgroundColor: '#c5d9f1', top: '62px' },
				cellStyle: { textAlign: 'right', backgroundColor: '#c5d9f1' },
				headerClassName: 'administrator-header',
				cellClassName: 'administrator-data',
				cellRenderer: (args) => {
					if (args.data.isTextOnlyLine) {
						return '';
					} else if (this.props.canEdit && args.data.isNonLotBasedVariation && args.data.claimType !== 'Temporary') {
						return (
							<div>
								<DataTableNumericEditor
									type="decimal"
									nullable={true}
									value={args.data.certifiedQty} 
									onChange={(v) => { this.updateCertifiedQty(args.data, v); }}
									style={{ width: 'auto' }}
									underlineStyle={{ borderBottom: '2px solid #5bc0de' }}
								/>
							</div>
						);
					} else {
						return args.formattedValue;
					}
				}
			},
			{
				name: 'certifiedAmount',
				label: 'Amount',
				fullLabel: 'Certified This Period Amount',
				dataType: 'number',
				excludeFromSearch: true,
				formatString: 'currency',
				cellRenderer: (args) => {
					if (args.data.isTextOnlyLine) {
						return '';
					} else if (args.data.isNonLotBasedVariation) {
						return toCurrency(args.data.certifiedQty * args.data.rate);
					} else if (this.props.canEdit && args.data.claimType !== 'Temporary') {
						return (	
							<div>
								{args.data.certifiedAmount !== null && 
									<span
										role="button"
										className="data-indicator" 
										onClick={(e) => this.onMouseOverCertifiedAmount(e, args.data)}
									></span>
								}
								<DataTableNumericEditor
									type="currency"
									nullable={true}
									value={args.data.certifiedAmount || args.data.calcCertifiedAmount} 
									onChange={(v) => { this.updateCertifiedAmount(args.data, v); }}
									underlineStyle={{ borderBottom: '2px solid #5bc0de' }}
								/>
							</div>
						);
					} else if (args.data.certifiedAmount === null) {
						return toCurrency(args.data.calcCertifiedAmount);
					} else {
						return args.formattedValue;
					}
				},
				// value: (args) => args.data.prevClaimAmount + (args.data.rate * args.data.claimQty),
				headerStyle: { backgroundColor: '#c5d9f1', top: '62px' },
				cellStyle: { textAlign: 'right', backgroundColor: '#c5d9f1' },
				headerClassName: 'administrator-header',
				cellClassName: 'administrator-data'
			},
			{
				name: 'qtyCertifiedToDate',
				label: 'Qty',
				fullLabel: 'Certified To Date Qty',
				dataType: 'number',
				excludeFromSearch: true,
				headerStyle: { backgroundColor: '#c5d9f1', top: '62px' },
				cellStyle: { textAlign: 'right', backgroundColor: '#c5d9f1' },
				headerClassName: 'administrator-header',
				cellClassName: 'administrator-data',
				value: (args) => (args.data.isTextOnlyLine) ? '' : round(args.data.prevCertifiedQty + args.data.certifiedQty, 2)
			},
			{
				name: 'amountCertifiedToDate',
				label: 'Amount',
				fullLabel: 'Certified To Date Amount',
				dataType: 'number',
				excludeFromSearch: true,
				headerStyle: { backgroundColor: '#c5d9f1', top: '62px' },
				cellStyle: { textAlign: 'right', backgroundColor: '#c5d9f1' },
				headerClassName: 'administrator-header',
				cellClassName: 'administrator-data',
				formatString: 'currency',
				value: (args) => (args.data.isTextOnlyLine) ? '' : args.data.prevCertifiedAmount + (args.data.certifiedAmount || args.data.calcCertifiedAmount)
			},
			{
				name: 'qtyDifference',
				label: 'Qty',
				fullLabel: 'Difference Qty',
				dataType: 'number',
				excludeFromSearch: true,
				headerStyle: { backgroundColor: '#fff', top: '62px' },
				cellStyle: { textAlign: 'right', backgroundColor: '#fff' },
				headerClassName: 'difference-header',
				cellClassName: 'difference-data',
				value: (args) => (args.data.isTextOnlyLine) ? '' : round(args.data.certifiedQty - args.data.claimQty, 3)
			},
			{
				name: 'amountDifference',
				label: 'Amount',
				fullLabel: 'Difference Amount',
				dataType: 'number',
				excludeFromSearch: true,
				headerStyle: { backgroundColor: '#fff', top: '62px' },
				cellStyle: { textAlign: 'right', backgroundColor: '#fff' },
				headerClassName: 'difference-header',
				cellClassName: 'difference-data',
				formatString: 'currency',
				value: (args) => {
					if (args.data.isTextOnlyLine) {
						return '';
					} else if (args.data.claimType === 'Temporary') {
						return args.data.certifiedAmount - args.data.claimAmount;
					} else if (args.data.isNonLotBasedVariation) {
						return (args.data.certifiedQty * args.data.rate) - (args.data.rate * args.data.claimQty);
					} else {
						return (args.data.certifiedAmount || args.data.calcCertifiedAmount) - (args.data.rate * args.data.claimQty);
					}
				}
			},
			{
				name: 'comments',
				label: 'Comments',
				headerClassName: 'comments-header',
				cellStyle: { backgroundColor: '#fff' },
				headerStyle: { borderTop: 'none', backgroundColor: '#fff', top: '62px' },
				cellClassName: 'comments-data',
				cellRenderer: (args) =>
					args.data.isTextOnlyLine ? '' :
					<DataTableTextEditor
						key={`comments-${args.data.scheduleOfRatesItemId}`}
						value={args.data.comments} 
						multiline={true}
						onChange={(v) => { 
							this.updateComments(args.data, v); 
						}}
						underlineStyle={{ borderBottom: '2px solid #5bc0de' }}
					/>
			}
		];

		const headerRows = [
			{
				className: 'sticky-header',
				cells: [
					{ colspan: 7, rowspan: 2, className: 'schedule-header', value: 'Schedule', style: { backgroundColor: '#d8e4bc' } },
					{ colspan: 2, rowspan: 2, className: 'prev-claim-header', value: 'Previous Claim', style: { backgroundColor: '#d8e4bc' } },   
					{ colspan: 2, className: 'contractor-header', value: this.props.contract.contractor, style: { backgroundColor: '#e6b8b7' } },   
					{ colspan: 4, className: 'administrator-header', value: 'Administrator', style: { backgroundColor: '#c5d9f1' } },
					{ colspan: 2, rowspan: 2, className: 'difference-header', value: 'Difference', style: { backgroundColor: '#fff' } },
					{ colspan: 1, rowspan: 2, className: 'comments-header', style: { borderBottom: 'none', backgroundColor: '#fff' } },
				]
			},
			{
				className: 'sticky-header',
				cells: [
					{ colspan: 2, className: 'contractor-header', value: 'This Period', style: { backgroundColor: '#e6b8b7', top: '31px' } },
					{ colspan: 2, className: 'administrator-header', value: 'Certified This Period', style: { backgroundColor: '#c5d9f1', top: '31px' } },
					{ colspan: 2, className: 'administrator-header', value: 'Certified To Date', style: { backgroundColor: '#c5d9f1', top: '31px' } }
				]
			}
		];

		const footerRows = [
			{
				cells: [
					{ colspan: 18, style: { padding: 0, height: '10px' } }
				]
			},
			{
				className: 'short-row',
				cells: [
					{},
					{ value: 'Totals', style: { fontWeight: 'bold' } },
					{ colspan: 16 }
				]
			}
		];
		forEach(scheduleTotals, (t) => {
			const scheduleColor = find(scheduleColours, (s) => s.scheduleOfRatesId === t.scheduleOfRatesId);
			const background = scheduleColor ? scheduleColor.colour : null;
	
			footerRows.push({
				cells: [
					/* Schedule */
					{},
					{ value: t.name, style: { backgroundColor: background } },
					{},
					{},
					{},
					{},
					{ value: toCurrency(t.amount), style: { textAlign: 'right', backgroundColor: background } },
					/* Prev Certified */
					{},
					{ value: toCurrency(t.prevCertifiedAmount), style: { textAlign: 'right', backgroundColor: background } },
					/* Claim This Period */	
					{},
					{ value: toCurrency(t.claimAmount), style: { textAlign: 'right', backgroundColor: '#e6b8b7' } },
					/* Certiifed This Period */
					{},
					{ value: toCurrency(t.certifiedAmount), style: { textAlign: 'right', backgroundColor: '#c5d9f1' } },
					/* Certified To Date */
					{},
					{ value: toCurrency(t.prevCertifiedAmount + t.certifiedAmount), style: { textAlign: 'right', backgroundColor: '#c5d9f1' } },
					/* Difference Certified Total - Claimed Total */
					{},
					{ value: toCurrency((t.certifiedAmount) - (t.claimAmount)), style: { textAlign: 'right', backgroundColor: '#fff' } },
					/* Comments */
					{}
				]
			});
		});
		footerRows.push({
			cells: [
				/* Schedule */
				{},
				{ value: 'Total', style: { fontWeight: 'bold' } },
				{},
				{},
				{},
				{},
				{ value: toCurrency(total.amount), style: { textAlign: 'right', backgroundColor: '#d8e4bc' } },
				/* Prev Certified */
				{},
				{ value: toCurrency(total.prevCertifiedAmount), style: { textAlign: 'right', backgroundColor: '#d8e4bc' } },
				/* Claim This Period */
				{},
				{ value: toCurrency(total.claimAmount), style: { textAlign: 'right', backgroundColor: '#e6b8b7' } },
				/* Certiifed This Period */
				{},
				{ value: toCurrency(total.certifiedAmount), style: { textAlign: 'right', backgroundColor: '#c5d9f1' } },
				/* Certified To Date */
				{},
				{ value: toCurrency(total.prevCertifiedAmount + total.certifiedAmount), style: { textAlign: 'right', backgroundColor: '#c5d9f1' } },
				/* Difference Certified Total - Claimed Total */
				{},
				{ value: toCurrency((total.certifiedAmount) - (total.claimAmount)), style: { textAlign: 'right', backgroundColor: '#fff' } },
				/* Comments */
				{}
			]
		});

		return (
<PageLayout
	title="Progress Claims"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'Add Temp Claim',
				onClick: () => {
					this.setState({
						...this.state,
						showTempClaimItemDialog: true,
						tempClaimItem: null
					});
				},
				isLoading: this.props.isLoading,
				icon: <AddIcon />,
				primary: false
			},
			{
				label: 'Save',
				onClick: this.onSave,
				isLoading: this.props.isLoading,
				icon: <SaveIcon />,
				primary: true
			}			
		] : []
	}
	content={
    <div>
		<ContentContainer>	
			<PersistedDataTable 
				id="progressClaims"
				name="Progress Claim"
				data={progressClaim.items || []} 
				columns={columns}
				defaultRowsPerPage="All"
				groupBy="scheduleOfRatesId"
				groupByFormatter={
					(args) => {
						const schedule = find(scheduleOfRates, (s) => s.scheduleOfRatesId === args.value);
						return schedule ? schedule.name : '';
					}
				}
				headerRows={headerRows}
				footerRows={footerRows}
				collapseGroupsByDefault={true}
				tableContainerStyles={{
					height: this.state.tableHeight
				}}
			/>

			{ this.props.isLoading &&
				<ProgressIndicator />
			}
		</ContentContainer>

        <Popover
			open={this.state.showCertifiedAmountPopover}
			anchorEl={this.state.certifiedAmountPopoverElement}
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			targetOrigin={{ horizontal: 'left', vertical: 'top' }}
			onClose={() => this.setState({ showCertifiedAmountPopover: false })}
			style={{ overflowY: 'visible', padding: '10px' }}
        >
			<span>Original Certified Amount: {toCurrency(this.state.calcCertifiedAmount)}</span>
        </Popover>
		<TempClaimItemDialog
			show={this.state.showTempClaimItemDialog}
			item={this.state.tempClaimItem}
			onOk={(item) => {
				if (item._clientKey) {
					const existingItem = find(this.state.progressClaim.items, i => i._clientKey === item._clientKey);
					existingItem.scheduleName = item.scheduleName;
					existingItem.itemNo = item.itemNo;
					existingItem.description = item.description;
				} else {
					this.state.progressClaim.items.push({
						...item,
						_clientKey: uniqueId('pci_')
					});
				}

				// Need to call setState to update the item
				this.setState({
					showTempClaimItemDialog: false,
					progressClaim: this.state.progressClaim
				});
			}}
			onCancel={() => {
				this.setState({
					...this.state,
					showTempClaimItemDialog: false
				});
			}}
		/>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
	</div>   
	}
/>
		);
	}
}

ProgressClaims.propTypes = {
	dispatch: PropTypes.func.isRequired,
	contract: PropTypes.object.isRequired,
	progressClaim: PropTypes.object,
	scheduleOfRates: PropTypes.array,
	canEdit: PropTypes.bool.isRequired,
	saveChanges: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

ProgressClaims.defaultProps = {
	progressClaim: null,
	scheduleOfRates: null
}

const mapStateToProps = (state) => ({
	contract: state.context.contract,
	progressClaim: state.progressClaim.progressClaim,
	scheduleOfRates: state.scheduleOfRates.scheduleOfRates,
	canEdit: canEdit(state, 'EditProgressClaims'),
	isLoading: state.progressClaim.isLoading,
	saveResult: state.progressClaim.saveResult
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	clearSaveResult: () => {
		dispatch(clearSaveResult());
	},
	saveChanges: (progressClaim) => {
		dispatch(saveProgressClaim(progressClaim, (data) => {
			if (data.success) {
				dispatch(showSuccessNotification(data.message));
			} else {
				dispatch(showErrorNotification(data.message));
			}
		}));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProgressClaims);
