import React from 'react';
import PropTypes from 'prop-types';
import { toNumber, toCurrency, toPercent } from '../../utils/utils';
import { getElementInTableCellAbove, getElementInTableCellBelow } from '../../utils/dom';

class DataTableNumericEditor extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.input = null;

		this.state = {
			value: props.value,
			displayValue: this.getDisplayValue(props.value),
			size: 10
		};

		this.onChange = this.onChange.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);

		this.setInputRef = element => {
			this.input = element;
		};
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value,
			displayValue: this.getDisplayValue(nextProps.value)
		});
	}

	getDisplayValue(value) {
		if (value === '' && this.props.nullable) {
			return '';
		}

		switch (this.props.type) {
			case 'currency':
				return toCurrency(value);
			case 'percent':
				return toPercent(value);
			default:
				return (value === undefined || value === null) ? '' : value;
		}
	}
	onChange(e, v) {
		if (this.props.editable === false) return;

		this.setState({
			value: v,
			displayValue: v
		});
		if (this.props.onInput) this.props.onInput(v, v);
	}
	onKeyUp(e) {
		switch (e.keyCode) {
			case 40: {
				const belowInput = getElementInTableCellBelow(this.input, 'INPUT');
				if (belowInput) belowInput.focus();
				break;
			}
			case 38: {
				const aboveInput = getElementInTableCellAbove(this.input, 'INPUT');
				if (aboveInput) aboveInput.focus();
				break;
			}
			default:
				if (this.props.expandable) {
					this.setState({
						size: Math.max(10, e.target.value.length)
					});
				}
		}
	}
	onFocus() {
	}

	onBlur(e) {
		const inputValue = e.target.value;
		let value;
		let displayValue;
		if (inputValue === '') {
			value = '';
			displayValue = '';
		} else {
			switch (this.props.type) {
				case 'int':
					value = Math.round(toNumber(inputValue));
					break;
				case 'percent':
					value = toNumber(inputValue) / 100.00;
					break;
				default:
					value = toNumber(inputValue);
					break;
			}
			displayValue = this.getDisplayValue(value);
		}
		if (Number.isNaN(value)) value = '';
		if (value === '') {
			if (this.props.nullable) {
				value = null;
				displayValue = '';
			} else {
				value = 0;
				displayValue = '0';
			}
		}

		this.setState({
			value: value,
			displayValue: displayValue
		});
		if (this.props.onChange) this.props.onChange(value, displayValue);
	}
	
	render() {
		const displayValue = this.state.displayValue === null ? '' : this.state.displayValue;
		const fieldStyles = {
			width: '100%',
		};
		const mergedProps = { ...fieldStyles, ...this.props };
		delete mergedProps.nullable;

		if (!mergedProps.className) {
			mergedProps.className = '';
		}
		mergedProps.className = `data-table-numeric-editor ${mergedProps.className}`; 

		if (mergedProps.hintText && !mergedProps.placeholder) {
			mergedProps.placeholder = mergedProps.hintText;
		}

		let size = this.state.size;
		if (displayValue && this.props.expandable) {
			size = Math.max(10, displayValue.toString().length);
		}

		delete mergedProps.editable;
		delete mergedProps.expandable;
		delete mergedProps.underlineStyle;
		delete mergedProps.fullWidth;
		
		return (
			<input 
				ref={this.setInputRef}
				{...mergedProps}
				size={size}
				onInput={null}
				onChange={this.onChange}
				onKeyUp={this.onKeyUp} 
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				value={displayValue} 
				style={{
					textAlign: 'right'
				}}
				// style={{
				// 	minWidth: displayValue ? `${displayValue.length + 1}ch` : undefined
				// }}
			/>
		);
	}
}

DataTableNumericEditor.propTypes = {
	type: PropTypes.oneOf(['int', 'decimal', 'currency', 'percent']),
	value: PropTypes.any,
	nullable: PropTypes.bool,
	editable: PropTypes.bool,
	onChange: PropTypes.func,
	onInput: PropTypes.func,
	expandable: PropTypes.bool
};

DataTableNumericEditor.defaultProps = {
	type: 'int',
	value: 0,
	nullable: false,
	editable: true,
	onChange: null,
	onInput: null,
	expandable: true
};

export default DataTableNumericEditor;
