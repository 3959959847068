import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import addReportWrapper from './reportWrapper';
import ProgressClaimByLotReportContent from './progressClaimByLotReportContent';

class ProgressClaimByLot extends React.Component {
	render() {
		const report = this.props.report || {};
		
		return (
			<ProgressClaimByLotReportContent report={report} />
		);
	}
}

ProgressClaimByLot.propTypes = {
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addReportWrapper(
	ProgressClaimByLot, {
		reportUrl: '/api/reports/progress-claim-by-lot',
		downloadUrl: '/Reports/ProgressClaimByLot',
		title: 'Progress Claim by Lots',
		className: 'progress-claim-by-lot'
	})
);
