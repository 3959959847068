import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import addReportWrapper from './reportWrapper';
import TableOfDifferencesContent from './tableOfDifferencesContent';

class TableOfDifferences extends React.Component {
	render() {
		const report = this.props.report || {};
		
		return (
			<TableOfDifferencesContent report={report} />
		);
	}
}

TableOfDifferences.propTypes = {
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addReportWrapper(
	TableOfDifferences, {
		reportUrl: '/api/reports/table-of-differences',
		downloadUrl: '/Reports/TableOfDifferences',
		title: 'Schedule of Differences',
		className: 'table-of-differences'
	})
);
