import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LotHandoverContent from './content/lotHandoverContent';
import addHandoverWrapper from './handoverWrapper';

class LotHandover extends React.Component {
	static getTitle(report) {
		return `Lot ${report.lotNo}`;
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<LotHandoverContent report={report} />
		);
	}
}

LotHandover.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	LotHandover, {
		title: 'Lots',
		className: 'lot-handover'
	})
);
