import React, { Component } from 'react';

class CloseContractWizardStartPage extends Component {
	render() {
		return (
<div>
	<p>
		This wizard will step you through the process of closing the Contract.
	</p>
	<p>
		Press Next to continue
	</p>
</div>
		);
	}
}

CloseContractWizardStartPage.propTypes = {
};

export default CloseContractWizardStartPage;
