import find from 'lodash/find';
import map from 'lodash/map';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import cloneDeep from 'lodash/cloneDeep';
import { globals } from '../globals';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	contract: null, 
	contractList: null,
	period: null
};

const CLEAR_CONTRACTS = 'CLEAR_CONTRACTS';
const CLEAR_CONTRACT = 'CLEAR_CONTRACT';
const FETCH_CONTRACT = 'FETCH_CONTRACT';
const DELETE_CONTRACT = 'DELETE_CONTRACT';
const REQUEST_CONTRACTS = 'REQUEST_CONTRACTS';
const RECEIVE_CONTRACTS = 'RECEIVE_CONTRACTS';
const REQUEST_CONTRACT = 'REQUEST_CONTRACT';
const RECEIVE_CONTRACT = 'RECEIVE_CONTRACT';
const CREATE_NEW_CONTRACT = 'CREATE_NEW_CONTRACT';
const SAVE_CONTRACT = 'SAVE_CONTRACT';
const RECEIVE_SAVE_CONTRACT_RESPONSE = 'RECEIVE_SAVE_CONTRACT_RESPONSE';
const RECEIVE_DELETE_CONTRACT_RESPONSE = 'RECEIVE_DELETE_CONTRACT_RESPONSE';
const START_CONTRACT = 'START_CONTRACT';
const RECEIVE_START_CONTRACT_RESPONSE = 'RECEIVE_START_CONTRACT_RESPONSE';
const SHOW_CONTRACT_ERRORS = 'SHOW_CONTRACT_ERRORS';
const CLOSE_CONTRACT = 'CLOSE_CONTRACT';
const SHOW_START_CONTRACT_ERRORS = 'SHOW_START_CONTRACT_ERRORS';

export const clearContracts = () => ({ type: CLEAR_CONTRACTS });
export const clearContract = () => ({ type: CLEAR_CONTRACT });
export const fetchContract = (contractId) => ({ type: FETCH_CONTRACT, contractId });
export const receiveContracts = (data) => ({ type: RECEIVE_CONTRACTS, payload: { data } });
export const receiveContract = (data) => ({ type: RECEIVE_CONTRACT, payload: { data } });
export const createNewContract = () => ({ type: CREATE_NEW_CONTRACT });
export const receiveSaveContractResponse = (data) => ({ type: RECEIVE_SAVE_CONTRACT_RESPONSE, data });
export const receiveDeleteContractResponse = (data) => ({ type: RECEIVE_DELETE_CONTRACT_RESPONSE, data });
export const receiveStartContractResponse = (data) => ({ type: RECEIVE_START_CONTRACT_RESPONSE, data });
export const closeContract = (data) => ({ type: CLOSE_CONTRACT, data });

export const requestContracts = () => (
	createFetchAction({
		objectName: 'Contracts',
		url: '/api/contracts',
		startAction: REQUEST_CONTRACTS,
		success: (data) => 
			receiveContracts(map(data, (c) => ({
				...c,
				dateCommenced: new Date(c.dateCommenced),
				practicalCompletionDate: c.practicalCompletionDate ? new Date(c.practicalCompletionDate) : null,
				liquidatedDamagesAppliesFrom: c.liquidatedDamagesAppliesFrom ? new Date(c.liquidatedDamagesAppliesFrom) : null,
				periods: map(c.periods, (p) => ({
					...p,
					startDate: p.startDate ? new Date(p.startDate) : null,
					endDate: p.endDate ? new Date(p.endDate) : null
				}))
			})))
	})
);

export const requestContract = (contractId) => (
	createFetchAction({
		objectName: 'Contract',
		url: `/api/contracts/${contractId}`,
		startAction: REQUEST_CONTRACT,
		success: (data) => 
			receiveContract({
				...data,
				dateCommenced: new Date(data.dateCommenced),
				practicalCompletionDate: data.practicalCompletionDate ? new Date(data.practicalCompletionDate) : null,
				liquidatedDamagesAppliesFrom: data.liquidatedDamagesAppliesFrom ? new Date(data.liquidatedDamagesAppliesFrom) : null,
				periods: map(data.periods, (p) => ({
					...p,
					startDate: p.startDate ? new Date(p.startDate) : null,
					endDate: p.endDate ? new Date(p.endDate) : null
				}))
			})
	})
);

export const requestContractByNumber = (contractNumber) => (
	createFetchAction({
		objectName: 'Contract',
		url: `/api/contracts/get-by-contract-number/${contractNumber}`,
		startAction: REQUEST_CONTRACT,
		success: (data) => 
			receiveContract({
				...data,
				dateCommenced: new Date(data.dateCommenced),
				practicalCompletionDate: data.practicalCompletionDate ? new Date(data.practicalCompletionDate) : null,
				liquidatedDamagesAppliesFrom: data.liquidatedDamagesAppliesFrom ? new Date(data.liquidatedDamagesAppliesFrom) : null,
				periods: map(data.periods, (p) => ({
					...p,
					startDate: p.startDate ? new Date(p.startDate) : null,
					endDate: p.endDate ? new Date(p.endDate) : null
				}))
			})
	})
);

const validateContract = (contract) => {
	const errors = [];
	if (contract.contractorId <= 0) {
		errors.push({
			fieldName: 'ContractorId',
			valid: false,
			message: 'A valid Contractor is required. You may need to create the Contractor first.'
		});
	}
	return errors;
};

export const saveContract = (contract, onSuccess) => {
	const errors = validateContract(contract);
	if (errors.length > 0) return { type: SHOW_CONTRACT_ERRORS, data: errors };

	return createPostAction({
		url: '/api/contracts',
		data: contract,
		startAction: SAVE_CONTRACT,
		success: (data, dispatch) => {
			dispatch(receiveSaveContractResponse({
				...data,
				object: data.object ? {
					...data.object,
					dateCommenced: new Date(data.object.dateCommenced),
					practicalCompletionDate: data.object.practicalCompletionDate ? new Date(data.object.practicalCompletionDate) : null,
					liquidatedDamagesAppliesFrom: data.object.liquidatedDamagesAppliesFrom ? new Date(data.object.liquidatedDamagesAppliesFrom) : null,
					periods: map(data.object.periods, (p) => ({
						...p,
						startDate: p.startDate ? new Date(p.startDate) : null,
						endDate: p.endDate ? new Date(p.endDate) : null
					}))
				} : data.object
			}));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteContract = (contract, onSuccess) => (
	createPostAction({
		url: `/api/contracts/${contract.contractId}/delete`,
		startAction: DELETE_CONTRACT,
		success: (data, dispatch) => {
			dispatch(receiveDeleteContractResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	})
);

export const startContract = (contract, periodName, onSuccess) => {
	if (contract.scheduleCount === 0) {
		return { type: SHOW_START_CONTRACT_ERRORS, data: 'A contract must have at least one Schedule of Rates.' };
	}

	return createPostAction({
		url: `/api/contracts/${contract.contractId}/start?periodName=${periodName}`,
		startAction: START_CONTRACT,
		success: (data, dispatch) => {
			dispatch(receiveStartContractResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CONTRACTS:
			return {
				...state,
				contractList: null
			};
		case CLEAR_CONTRACT:
			return {
				...state,
				contract: null
			};
		case REQUEST_CONTRACTS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				contractList: []
			};
		case RECEIVE_CONTRACTS:
			return {
				...state,
				isLoading: false,
				contractList: action.payload.data
			};
		case REQUEST_CONTRACT:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				contract: { ...cloneDeep(globals.templates.contract) }
			};
		case RECEIVE_CONTRACT:
			return {
				...state,
				isLoading: false,
				contract: action.payload.data
			};
		case DELETE_CONTRACT:
			return { 
				...state
			};
		case RECEIVE_DELETE_CONTRACT_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message
					}
				};
			}
			return {
				...state,
				isLoading: false,
				contractList: state.contractList.filter(c => c.contractId !== action.data.objectId) 
			};
		case FETCH_CONTRACT:
			return { 
				...state,
				contract: find(state.contractList, (c) => c.contractId === action.contractId)
			};
		case CREATE_NEW_CONTRACT:
			return {
				...state,
				contract: { ...cloneDeep(globals.templates.contract) }
			};	
		case SAVE_CONTRACT:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_CONTRACT_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				contractList: state.contractList ? addOrUpdate(state.contractList, action.data.object, { contractId: action.data.object.contractId }) : null, 
				contract: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case START_CONTRACT:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case SHOW_START_CONTRACT_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: action.data,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_START_CONTRACT_RESPONSE:
			if (action.data.success) {
				const contract = {
					...action.data.object,
					dateCommenced: new Date(action.data.object.dateCommenced),
					periods: map(action.data.object.periods, (p) => ({
						...p,
						startDate: p.startDate ? new Date(p.startDate) : null,
						endDate: p.endDate ? new Date(p.endDate) : null
					}))
				};
				return {
					...state,
					contractList: map(state.contractList, (c) => ({
							...(c.contractId === contract.contractId ? contract : c)
						})
					),
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					},
				};
			} else {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					},
				};
			}
		case SHOW_CONTRACT_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
