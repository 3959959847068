import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

const connectedRouterHOC = (mapStateToProps, mapDispatchToProps) => {
    return Component => {
        return (
            connect(
                mapStateToProps,
                mapDispatchToProps
            )(
                withRouter(Component)
            )
        );
    };
};

export default connectedRouterHOC;
