import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VariationsHandoverContent from '../content/variationsHandoverContent';
import addHandoverWrapper from '../handoverWrapper';

class VariationsHandover extends React.Component {
	static getTitle() {
		return 'Variations';
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<VariationsHandoverContent report={report} />
		);
	}
}

VariationsHandover.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	VariationsHandover, {
		title: 'Variations',
		className: 'variations-handover'
	})
);
