import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../utils/ajax';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import map from 'lodash/map';
import MailIcon from '@material-ui/icons/MailOutline';
import SendIcon from '@material-ui/icons/Send';
import { formatDate } from '../../utils/utils';
import WarningIcon from '@material-ui/icons/Warning';
import { useHistory } from 'react-router-dom';

const CorrespondenceNeedingAction = ({ setLoading, contractId, changeContractContext, setSubHeader }) => {
	const [correspondence, setCorrespondence] = React.useState([]);
	const history = useHistory();

	const onSelectCorrespondence = (correspondence) => {
		if (contractId !== correspondence.contractId) {
			changeContractContext(correspondence.contractId);
		}
		if (correspondence.type === 'IncomingCorrespondence') {
			history.push(`/incoming-correspondence/${correspondence.id}`);
		} else {
			history.push(`/outgoing-correspondence/${correspondence.id}`);
		}
	}

	React.useEffect(() => {
		const loadData = () => {
			setLoading(true);
			setCorrespondence([]);
			setSubHeader("");
			get({
				url: `/api/dashboard/correspondence-needing-action?contractId=${contractId}`,
				onSuccess: data => {
					setSubHeader(data.length === 1 ? '(1 item)' : `(${data.length} items)`);
					setLoading(false);
					setCorrespondence(data);
				},
				onError: () => {
					setLoading(false);
				}
			});
		};

		if (contractId) {
			loadData();
		}
	}, [contractId]);

	if (correspondence.length === 0) return <p style={{ marginLeft: '10px', marginTop: '10px' }}>None</p>;
		
	return (
		<div style={{ overflow: 'auto', height: '100%' }}>
		<List>
			{
				map(correspondence, (c, i) => (
					<ListItem
						key={`cna-${i}`}
						button
						onClick={() => onSelectCorrespondence(c)}
					>
						<ListItemIcon>
							{c.type === 'IncomingCorrespondence' ? <MailIcon /> :  <SendIcon />}
						</ListItemIcon>
						<ListItemText 
							primary={c.rfiNumber}
							secondary={
								<span>
									{c.docTitle}<br />
									{c.type === 'IncomingCorrespondence' && <span>Due {c.actionDueDate ? formatDate(c.actionDueDate, 'dd/MM/yyyy') : null}</span>}
								</span>
							}
						/>
						{c.overdue &&
							<ListItemIcon>
								<WarningIcon color="error" />
							</ListItemIcon>
						}
					</ListItem>
				))
			}
		</List>
		</div>
	);
};

CorrespondenceNeedingAction.propTypes = {
	setLoading: PropTypes.func.isRequired,
	changeContractContext: PropTypes.func.isRequired,
	contractId: PropTypes.number
};

CorrespondenceNeedingAction.defaultProps = {
	contractId: null
};

export default CorrespondenceNeedingAction;
