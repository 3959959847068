import React from 'react';
import PropTypes from 'prop-types';
import { 
	Checkbox as MuiCheckbox, 
	FormControlLabel 
} from '@material-ui/core';

const Checkbox = props => {
	const propsToPassOn = { ...props };
	
	let labelPlacement = 'end';
	if (props.labelPosition === 'left') labelPlacement = 'start';
	delete propsToPassOn.labelPosition;

	if (props.onCheck) propsToPassOn.onChange = props.onCheck;
	delete propsToPassOn.onCheck;

	delete propsToPassOn.label;

	if (propsToPassOn.checked === undefined) propsToPassOn.checked = false;

	return (
		<FormControlLabel
			className="checkbox"
			control={
				<MuiCheckbox {...propsToPassOn} />
			}
			label={props.label}
			labelPlacement={labelPlacement}
		/>
	);
};
	

Checkbox.propTypes = {
	label: PropTypes.string,
	labelPosition: PropTypes.string,
	onCheck: PropTypes.func
};

Checkbox.defaultProps = {
	label: '',
	labelPosition: undefined,
	onCheck: undefined
};

export default Checkbox;
