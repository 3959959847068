import React from 'react';
import PropTypes from 'prop-types';

const FormSectionHeader = ({ text, style }) => 
	<div className="form-section-header" style={style}>{text}</div>
;

FormSectionHeader.propTypes = {
	text: PropTypes.string.isRequired,
	style: PropTypes.object
};

FormSectionHeader.defaultProps = {
	style: undefined
};

export default FormSectionHeader;
