import React from 'react';
import PropTypes from 'prop-types';
import Button  from './button';
import Dialog from './Dialog';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import find from 'lodash/find';
import filter from 'lodash/filter';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from './IconButton';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { produce } from 'immer';

const DataTableColumnSelector = (props) => {
	const [state, setState] = React.useState({
		selectedColumns: props.selectedColumns,
		notSelectedColumns: map(filter(props.columns, c => !c.excludeFromGrid && !some(props.selectedColumns, c2 => c2 === c.name)), c3 => c3.name),
		leftSelection: null,
		rightSelection: null
	});

	React.useEffect(() => {
		setState(produce(draft => {
			draft.selectedColumns = props.selectedColumns;
			draft.notSelectedColumns = map(filter(props.columns, c => !c.excludeFromGrid && !some(props.selectedColumns, c2 => c2 === c.name)), c3 => c3.name);
		}));
	}, [props.columns]);

	const selectLeft = (column) => {
		setState(produce(draft => {
			draft.leftSelection = column.name
		}));
	};
	const selectRight = (column) => {
		setState(produce(draft => {
			draft.rightSelection = column.name
		}));
	};

	const addColumn = () => {
		if (!state.leftSelection) return;
		const column = find(props.columns, c => c.name === state.leftSelection);
		if (!column) return;
		setState(produce(draft => {
			draft.selectedColumns.push(column.name);
			draft.rightSelection = column.name;
			const leftIndex = draft.notSelectedColumns.indexOf(column.name);
			draft.notSelectedColumns.splice(leftIndex, 1);
			if (leftIndex < draft.notSelectedColumns.length) {
				draft.leftSelection = draft.notSelectedColumns[leftIndex];
			} else if (leftIndex > 0) {
				draft.leftSelection = draft.notSelectedColumns[leftIndex - 1];
			} else {
				draft.leftSelection = null;
			}
		}));
	};

	const removeColumn = () => {
		if (!state.rightSelection) return;
		const column = find(props.columns, c => c.name === state.rightSelection);
		setState(produce(draft => {
			draft.notSelectedColumns.push(column.name);
			draft.leftSelection = column.name;
			const rightIndex = draft.selectedColumns.indexOf(column.name);
			draft.selectedColumns.splice(rightIndex, 1);
			if (rightIndex < draft.selectedColumns.length) {
				draft.rightSelection = draft.selectedColumns[rightIndex];
			} else if (rightIndex > 0) {
				draft.rightSelection = draft.selectedColumns[rightIndex - 1];
			} else {
				draft.rightSelection = null;
			}
		}));
	};
	const moveDown = () => {
		setState(produce(draft => {
			const index = draft.selectedColumns.indexOf(state.rightSelection);
			if (index > -1 && index + 1 < draft.selectedColumns.length) {
				const next = draft.selectedColumns[index + 1];
				draft.selectedColumns[index + 1] = draft.selectedColumns[index];
				draft.selectedColumns[index] = next;
			}
		}));
	};
	const moveUp = () => {
		setState(produce(draft => {
			const index = draft.selectedColumns.indexOf(state.rightSelection);
			if (index > 0) {
				const next = draft.selectedColumns[index - 1];
				draft.selectedColumns[index - 1] = draft.selectedColumns[index];
				draft.selectedColumns[index] = next;
			}
		}));
	};

	const onCancel = () => {
		setState(produce(draft => {
			draft.selectedColumns = props.selectedColumns;
			draft.notSelectedColumns = map(filter(props.columns, c => !some(props.selectedColumns, c2 => c2 === c.name)), c3 => c3.name);
			draft.leftSelection = null;
			draft.rightSelection = null;
			props.onCancel();
		}));		
	};
	

	const { columns } = props;
	const selectedColumns = [];
	const notSelectedColumns = [];

	forEach(state.selectedColumns, c => {
		const column = find(columns, c2 => c2.name === c);
		if (column) {
			selectedColumns.push(column);
		}
	});
	forEach(state.notSelectedColumns, c => {
		const column = find(columns, c2 => c2.name === c);
		if (column) {
			notSelectedColumns.push(column);
		}
	});

	return (
		<Dialog
			className='data-table-column-selector'
			title="Select Columns"
			actions={
				<React.Fragment>
					<Button
						label="Update"
						primary={true}
						onClick={() => props.onOk(state.selectedColumns)}
					/>,
					<Button
						label="Cancel"
						primary={false}
						onClick={onCancel}
					/>
				</React.Fragment>
			}
			open={props.show}
			onClose={onCancel}
			scroll='paper'
			fullWidth={true}
			maxWidth='lg'
		>

			<div className='selector'>
				<div className='column-list'>
					<List 
						style={{ height: '400px', minWidth: '400px' }}
						subheader={
							<ListSubheader>Available Columns</ListSubheader>
						}
					>
						{map(notSelectedColumns, (c, i) => 
							<ListItem 
								key={`not-selected-col-${i}`}
								className={c.name === state.leftSelection ? 'selected' : ''}
								onClick={() => selectLeft(c)}
							>
								<ListItemText primary={c.name === 'commands' ? 'Commands' : c.label} />
							</ListItem>
						)}
					</List>
				</div>
				<div className='buttons'>
					<IconButton onClick={ () => { addColumn(); } }>
						<ArrowRight />
					</IconButton>	
					<IconButton onClick={ () => { removeColumn(); } }>
						<ArrowLeft />
					</IconButton>	
				</div>
				<div className='selection-list'>
					<List 
						style={{ height: '400px', minWidth: '400px' }}
						subheader={
							<ListSubheader>Selected Columns</ListSubheader>
						}
					>
						{map(selectedColumns, (c, i) => 
							<ListItem 
								key={`selected-col-${i}`}
								className={c.name === state.rightSelection ? 'selected' : ''}
								onClick={() => selectRight(c)}
							>
								<ListItemText primary={c.name === 'commands' ? 'Commands' : c.label}  />
							</ListItem>
						)}
					</List>
				</div>
				<div className='buttons'>
					<IconButton onClick={ () => { moveUp(); } }>
						<ArrowUp />
					</IconButton>							
					<IconButton onClick={ () => { moveDown(); } }>
						<ArrowDown />
					</IconButton>		
				</div>
			</div>
		</Dialog>
	);
};

DataTableColumnSelector.propTypes = {
	show: PropTypes.bool.isRequired,
	columns: PropTypes.array.isRequired,
	selectedColumns: PropTypes.array,
	onCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired
};

DataTableColumnSelector.defaultProps = {
	selectedColumns: null
};

export default DataTableColumnSelector;
