import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	saveRegister, 
	clearRegister, 
	requestRegister, 
	createNewRegister 
} from '../../reducers/registers';
import RegisterForm from './registerForm';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { globals } from '../../globals';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import uniqueId from 'lodash/uniqueId';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { requestRegisterDefinition } from '../../reducers/registerDefinitions';
import { produce } from 'immer';
import PageLayout from '../pageLayout';
import cloneDeep from 'lodash/cloneDeep';

class RegisterMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			register: props.register,
			newFiles: [],
			hasUnsavedChanges: false
		};

		this.updateField = this.updateField.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestRegisterDefinition(props.match.params.registerDefinitionId, (def) => {
			if (props.match.params.registerId) {
				dispatch(requestRegister(props.match.params.registerId));
			} else {
				dispatch(createNewRegister(def));
			}	
		}));
	}
	static clearData(dispatch) {
		dispatch(clearRegister());
	}
	componentDidMount() {
		RegisterMaintenance.fetchData(this.props.dispatch, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.register !== this.props.register) {
			this.setState(produce(draft => {
				draft.register = nextProps.register;
				for (let i = 0; i < nextProps.registerDefinition.fields.length; i++) {
					const field = nextProps.registerDefinition.fields[i];
					if (field.fieldType === "Tags") {
						let tags = filter(
							nextProps.register.tags, t => t.registerFieldId === field.registerFieldId
						);
						draft.register.values[field.name] = tags;
					}
				}
				draft.hasUnsavedChanges = false;
			}));
		}
		
	}

	setClientKeyOnFields(register) {
		forEach(register.fields, (f) => { f._clientKey = uniqueId('registerField_'); });
	}
	updateField(field, newValue) {
		this.setState(produce(draft => {
			draft.register.values[field] = newValue;
			draft.hasUnsavedChanges = false;
		}));
	}

	downloadFile = (file) => {
		window.open(`/api/registers/${this.state.register.registerId}/download-file/${file.userFileId}?contractId=${this.props.register.contractId}`, '_self');
	}
	deleteFile = (fileIndex) => {
		this.setState(produce(draft => {
			draft.register.files.splice(fileIndex, 1);
		}));
	}
	addFiles = (field, files) => {
		const register = this.state.register;
		// The files arg will be garbage collected if we wait
		// until inside the setState callback so store in local
		// vars here first
		const newFiles = [];
		const newFormFiles = [];

		forEach(files, f => {
			newFiles.push({
				referenceId: `${register.registerId}.${field.registerFieldId}`,
				name: f.name,
				sizeBytes: f.size,
				_isNew: true
			});
			newFormFiles.push({
				file: f
			});
		});

		this.setState(produce(draft => {
			if (!draft.register.files) draft.register.files = [];
			forEach(newFiles, f => draft.register.files.push(f));
			forEach(newFormFiles, f => draft.newFiles.push(f));
			draft.hasUnsavedChanges = true;
		}));
	}

	onSelectCorrespondence = (registerFieldId, selectedCorrespondence) => {
		const register = this.state.register;
		const correspondence = register.correspondence;
		const registerCorrespondence = [];

		forEach(selectedCorrespondence.incomingCorrespondence, (c) => {
			const lc = {
				registerId: register.registerId,
				registerFieldId: registerFieldId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Incoming',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle
			};

			// Keep the current id if it already existed
			const existingLC = find(correspondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Incoming');
			if (existingLC) lc.registerCorrespondenceId = existingLC.registerCorrespondenceId;

			registerCorrespondence.push(lc);
		});
		forEach(selectedCorrespondence.outgoingCorrespondence, (c) => {
			const lc = {
				registerId: register.registerId,
				registerFieldId: registerFieldId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Outgoing',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle
			};

			// Keep the current id if it already existed
			const existingLC = find(correspondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Outgoing');
			if (existingLC) lc.registerCorrespondenceId = existingLC.registerCorrespondenceId;

			registerCorrespondence.push(lc);
		});
		forEach(selectedCorrespondence.principalCorrespondence, (c) => {
			const lc = {
				registerId: register.registerId,
				registerFieldId: registerFieldId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Principal',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle
			};

			// Keep the current id if it already existed
			const existingLC = find(correspondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Principal');
			if (existingLC) lc.registerCorrespondenceId = existingLC.registerCorrespondenceId;

			registerCorrespondence.push(lc);
		});
		forEach(selectedCorrespondence.designCorrespondence, (c) => {
			const lc = {
				registerId: register.registerId,
				registerFieldId: registerFieldId,
				correspondenceId: c.correspondenceId,
				correspondenceType: 'Design',
				correspondenceNumber: c.correspondenceNumber,
				correspondenceTitle: c.correspondenceTitle
			};

			// Keep the current id if it already existed
			const existingLC = find(correspondence, (c2) => c2.correspondenceId === lc.correspondenceId && c2.correspondenceType === 'Design');
			if (existingLC) lc.registerCorrespondenceId = existingLC.registerCorrespondenceId;

			registerCorrespondence.push(lc);
		});

		this.setState(produce(draft => {
			draft.register.correspondence = registerCorrespondence;
			draft.hasUnsavedChanges = true;
		}));
	}
	deleteCorrespondence = (registerFieldId, correspondence) => {
		this.setState(produce(draft => {
			draft.register.correspondence = filter(draft.register.correspondence, (c) => !(c.registerFieldId === registerFieldId && c.correspondenceType === correspondence.correspondenceType && c.correspondenceId === correspondence.correspondenceId));
			draft.hasUnsavedChanges = true;
		}));
	}
	viewCorrespondence =(correspondence) => {
		switch (correspondence.correspondenceType) { 
			case 'Incoming':
				this.props.history.push(`/incoming-correspondence/${correspondence.correspondenceId}`);
				break;
			case 'Outgoing':
				this.props.history.push(`/outgoing-correspondence/${correspondence.correspondenceId}`);
				break;
			case 'Principal':
				this.props.history.push(`/principal-correspondence/${correspondence.correspondenceId}`);
				break;
			case 'Design':
				this.props.history.push(`/design-correspondence/${correspondence.correspondenceId}`);
				break;
			default:
		}
	}

	formatRegisterTagsForSave = () => {
		const register = cloneDeep(this.state.register);
		let registerTags = [];
		for (let i = 0; i < this.props.registerDefinition.fields.length; i++) {
			const field = this.props.registerDefinition.fields[i];
			if (field.fieldType === "Tags") {
				registerTags = registerTags.concat(map(
					register.values[field.name],
					t => ({
						...t,
						registerFieldId: field.registerFieldId
					})
				));

				// the values field is ignored in db, the values are pulled from
				// the RegisterTags table. So just make the values a list of tag
				// names for readability in the db
				register.values[field.name] = map(register.values[field.name], t => t.name);
			}
		}
		register.tags = registerTags;
		return register;
	}
	onSave = () => {
		this.props.saveChanges(this.formatRegisterTagsForSave(this.state.register), this.state.newFiles);
	}
	onSaveAndContinue = () => {
		this.props.saveChangesAndContinue(this.formatRegisterTagsForSave(this.state.register), this.state.newFiles, () => this.setState({ newFiles: [] }));
	}

	render() {
		const registerDefinition =  this.props.registerDefinition || { fields: [] };
		const register =  this.state.register || { values: {} };

		return (
<PageLayout
	title={registerDefinition.name}
	isLoading={this.props.isLoading || this.props.saveResult.progress}
	formActions={{
		isLoading: this.props.isLoading || this.props.saveResult.progress,
		canEdit: this.props.canEdit,
		hasUnsavedChanges: this.state.hasUnsavedChanges,
		onSave: () => this.onSave(),
		onSaveAndContinue: () => this.onSaveAndContinue(),
		onCancel: () => this.props.history.goBack()
	}}
	content={
	<div>
		<RegisterForm 
			registerDefinition={registerDefinition} 
			register={register} 
			isLoading={this.props.isLoading} 
			saveResult={this.props.saveResult}  
			updateField={this.updateField}
			downloadFile={this.downloadFile}
			deleteFile={this.deleteFile}
			addFiles={this.addFiles}
			canEdit={this.props.canEdit}
			onSelectCorrespondence={this.onSelectCorrespondence}
			deleteCorrespondence={this.deleteCorrespondence}
			viewCorrespondence={this.viewCorrespondence}
		/>
		
		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />
	</div>
	}
/>
		);
	}
}

RegisterMaintenance.propTypes = {
	params: PropTypes.shape({
		registerDefinitionId: PropTypes.node,
		registerId: PropTypes.node
	}).isRequired,
	dispatch: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	registerDefinition: PropTypes.object,
	register: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

RegisterMaintenance.defaultProps = {
	registerDefinition: null,
	register: null
};

const mapStateToProps = (state) => ({
	canEdit: canEditAnyPeriod(state, 'EditRegisters'),
	registerDefinition: state.registerDefinitions.registerDefinition,
	register: state.registers.register,
	fields: globals.fields || [],
	isLoading: state.registers.isLoading,
	saveResult: state.registers.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (register, newFiles) => {
		dispatch(saveRegister(register, newFiles,  (data) => {
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (register, newFiles, onSuccess) => {
		dispatch(saveRegister(register, newFiles, (data) => {
			dispatch(showSuccessNotification(data.message));
			onSuccess();
		}));	
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(RegisterMaintenance);
