import React from 'react';
import { 
	Grid
} from '@material-ui/core';
import AutoComplete from '../../widgets/AutoComplete';
import TextField from '../../widgets/TextField';

const ContractorField = props => {
	const contractor = props.value;

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<AutoComplete 
					fullWidth={true}
					required={true}
					getOptionLabel={(o) => o.label}
					loadItems={{
						route: "contractors",
						mapItem: c => ({ label: c.name, value: c.contractorId })
					}}
					defaultItem={{ label: "<Create New>", value: -1 }}
					value={contractor.contractorId}
					label="Contractor"
					onChange={(e, v) => {
						props.onChange({
							...contractor,
							contractorId: v ? v.value : -1
						});
					}}
				/>
			</Grid>

		{contractor.contractorId === -1 &&
			<React.Fragment>
					<Grid item xs={12}>
						<TextField
							value={contractor.name}
							fullWidth={true}
							onChange={(e, v) => props.onChange({
								...contractor,
								name: v
							})}
							label="Name"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={contractor.tradingName}
							fullWidth={true}
							onChange={(e, v) => props.onChange({
								...contractor,
								tradingName: v
							})}
							label="Trading Name"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={contractor.abn}
							fullWidth={true}
							onChange={(e, v) => props.onChange({
								...contractor,
								abn: v
							})}
							label="ABN"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={contractor.address}
							fullWidth={true}
							onChange={(e, v) => props.onChange({
								...contractor,
								address: v
							})}
							label="Address"
						/>
					</Grid>
			</React.Fragment>
		}
				
		</Grid>
	);
};

export default ContractorField;
