import React from 'react';
import PropTypes from 'prop-types';
import NumericField from './numericField';

const ReportNumericField = (props) => {
	let classes = 'report-field';
	if (props.editable !== false) {
		classes += ' editable';
	}

	const style = {
		width: '100px', 
		lineHeight: '14px',
		height: '18px',
		...props.style
	};
	const inputStyle = {
		fontSize: '12px', 
		textAlign: 'right',
		padding: '2px',
		...props.inputStyle
	};

	const mergedProps = { ...props };
	delete mergedProps.editable;
	
	return (
		<NumericField 
			{...mergedProps} 
			type="decimal" 
			className={classes} 
			disableUnderline={true} 
			style={style} 
			inputStyle={inputStyle} 
		/>
	);
};

ReportNumericField.propTypes = {
	editable: PropTypes.bool,
	style: PropTypes.object,
	inputStyle: PropTypes.object
};

ReportNumericField.defaultProps = {
	editable: false,
	style: {},
	inputStyle: {}
};

export default ReportNumericField;
