import React from 'react';
import PropTypes from 'prop-types'; 
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';

const IconMenu = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const showMenu = (e) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const closeMenu = (e) => {
		if (e) e.preventDefault();
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton 
				onClick={showMenu}
				style={props.style}
				size={props.size}
			>
				{props.icon}
			</IconButton>
			<Menu
				open={open}
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
				onClose={closeMenu}
			>
				{props.items(closeMenu)}
			</Menu>
		</React.Fragment>
	);
};
	
IconMenu.propTypes = {
	icon: PropTypes.node.isRequired,
	style: PropTypes.object,
	size: PropTypes.string,
	items: PropTypes.func.isRequired
};

IconMenu.defaultProps = {
	style: {},
	size: undefined
};

export default IconMenu;
