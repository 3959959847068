import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { deleteUser, clearUsers, requestAllUsers } from '../../reducers/users';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ChangePasswordIcon from '@material-ui/icons/VpnKey';
import MessagePanel from '../messagePanel';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import PersistedDataTable from '../widgets/persistedDataTable';
import PageLayout from '../pageLayout';
import ResetPasswordDialog from '../widgets/resetPasswordDialog';

class UserList extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			showDeleteConfirmation: false,
			user: null,
			showStartUserDialog: false,
			showChangePasswordDialog: false
		};

        this.handleEditUser = this.handleEditUser.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestAllUsers());
	}
	static clearData(dispatch) {
		dispatch(clearUsers());
	}

	componentDidMount() {
		UserList.fetchData(this.props.dispatch);
	}

	showChangePasswordDialog = (user) => {
		this.setState({
			showChangePasswordDialog: true,
			user: user
		});
	}
	onChangePasswordOk = () => {
		this.setState({
			showChangePasswordDialog: false
		});
	}
	onChangePasswordCancel = () => {
		this.setState({
			showChangePasswordDialog: false
		});
	}

	handleEditUser = (userId) => {
		this.props.history.push(`/user/${userId}`);
	}

	handleDeleteUser(user) {
		this.props.deleteUser(user);
	}

	newUser = () => {
		this.props.history.push('/user');
	}

	render() {
		const users = this.props.users || [];
		const atMaxUserLimit = this.props.maxUsers > 0 && this.props.users.length > this.props.maxUsers;

		const columns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => (
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.handleEditUser(args.data.userId); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.handleDeleteUser(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
						<IconButton onClick={ () => { this.showChangePasswordDialog(args.data); } } style={{ padding: '2px' }}>
							<ChangePasswordIcon />
						</IconButton>
					</div>
				)
			},
			{ name: 'userName', label: 'User Name' },
			{ name: 'fullName', label: 'Full Name' },
			{ name: 'active', label: 'Active', dataType: 'bool' },
			{ name: 'email', label: 'Email' },
			{ name: 'position', label: 'Position' }
		];
		if (this.props.isGlobalAdmin) {
			columns.splice(3, 0, { name: 'tenantName', label: 'Tenant' });
		}

		return (
<PageLayout
	title="Users"
	isLoading={this.props.isLoading}
	actions={
		[
			{
				label: 'New User',
				onClick: this.newUser,
				tooltip: atMaxUserLimit ? "You have reached the maximum number of users" : undefined,
				disabled: atMaxUserLimit
			}
		]
	}
	content={
	<div>
		<ResetPasswordDialog
			dispatch={this.props.dispatch}
			show={this.state.showChangePasswordDialog}
			user={this.state.user}
			title={this.state.user ? `Change Password for ${this.state.user.userName}` : 'Change Password'}
			message="Enter the new password"
			onOk={this.onChangePasswordOk}
			onCancel={this.onChangePasswordCancel}
		/>

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />

		<ContentContainer>
			<PersistedDataTable 
				id="users"
				data={users || []} 
				columns={columns}
			/>
		</ContentContainer>
	</div>
	}
/>
    );
  }
}

UserList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	users: PropTypes.array,
	deleteUser: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired,
	isGlobalAdmin: PropTypes.bool
};

UserList.defaultProps = {
	users: [],
	isGlobalAdmin: false
};

const mapStateToProps = (state) => ({
	users: state.users.userList,
	isLoading: state.users.isLoading,
	saveResult: state.users.saveResult,
	isGlobalAdmin: state.context.userRole === "Global Administrator",
	maxUsers: state.context.maxUsers
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteUser: (user) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete User ?',
			message: 'Are you sure you wish to delete this user?',
			onOk: () => {
				dispatch(deleteUser(user));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(UserList);
