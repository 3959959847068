import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import PrincipalCorrespondenceGrid from './principalCorrespondenceGrid';
import { 
	savePrincipalCorrespondence, 
	deletePrincipalCorrespondence, 
	requestPrincipalCorrespondences, 
	clearPrincipalCorrespondences 
} from '../../reducers/principalCorrespondence';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { getContractId } from '../../selectors/contract';
import { get } from '../../utils/ajax';

class PrincipalCorrespondence extends Component {
    constructor(props) {
        super(props);

        this.state = {
			correspondence: {},
			tags: []
        };

		this.savePrincipalCorrespondence = this.savePrincipalCorrespondence.bind(this);
		this.onEditCorrespondence = this.onEditCorrespondence.bind(this);
		this.onDeleteCorrespondence = this.onDeleteCorrespondence.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestPrincipalCorrespondences());
	}
	static clearData(dispatch) {
		dispatch(clearPrincipalCorrespondences());
	}
	componentDidMount() {
		PrincipalCorrespondence.fetchData(this.props.dispatch);

		get({
			url:`/api/principal-correspondence/tags?contractId=${this.props.contractId}`,
			onSuccess: data => {
				this.setState(prevState => ({
					...prevState,
					tags: data
				}));
			},
			onError: error => {
			}
		});
	}
	onEditCorrespondence = (correspondence) => {
		this.props.history.push(`/principal-correspondence/${correspondence.principalCorrespondenceId}`);
	}
	onViewCorrespondence = (correspondence) => {
		this.props.history.push(`/principal-correspondence/${correspondence.principalCorrespondenceId}`);
	}
	onDeleteCorrespondence(correspondence) {
		this.props.deletePrincipalCorrespondence(correspondence);
	}

	savePrincipalCorrespondence() {
		this.props.saveChanges(this.state.correspondence);
	}
	
	onCancelPrincipalCorrespondence() {
        this.setState({ open: false });
	}

	registerNewCorrespondence = () => {
		this.props.history.push('/principal-correspondence');
	}

	render() {
		return (
<PageLayout
	title="Principal Correspondence"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'Register Principal Correspondence',
				onClick: this.registerNewCorrespondence
			}
		] : []
	}
	content={
		<ContentContainer>
			{ !this.props.isLoading &&
				<PrincipalCorrespondenceGrid 
					correspondences={this.props.correspondences} 
					onEditCorrespondence={this.onEditCorrespondence} 
					onDeleteCorrespondence={this.onDeleteCorrespondence} 
					onViewCorrespondence={this.onViewCorrespondence} 
					canView={this.props.canView}
					canEdit={this.props.canEdit}
					tags={this.state.tags}
				/>
			}
		</ContentContainer>
	}
/>
		);
	}
}

PrincipalCorrespondence.propTypes = {
	dispatch: PropTypes.func.isRequired,
	contractId: PropTypes.number.isRequired,
	correspondences: PropTypes.array,
	saveChanges: PropTypes.func.isRequired,
	deletePrincipalCorrespondence: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

PrincipalCorrespondence.defaultProps = {
	correspondences: []
};

const mapStateToProps = (state) => ({
	contractId: getContractId(state),
	correspondences: state.principalCorrespondence.principalCorrespondences,
	canView: canView(state, 'ViewPrincipalCorrespondenceItem'),
	canEdit: canEdit(state, 'EditPrincipalCorrespondence'),
	isLoading: state.principalCorrespondence.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	saveChanges: (correspondence) => {
		dispatch(savePrincipalCorrespondence(correspondence));
	},
	deletePrincipalCorrespondence: (correspondence) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this correspondence?',
			onOk: () => {
				dispatch(deletePrincipalCorrespondence(correspondence));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(PrincipalCorrespondence);
