import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import PageLayout from '../pageLayout';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { get } from '../../utils/ajax';
import { canView } from '../../selectors/canView';
import AuditLogGrid from './AuditLogGrid';
import addDays from 'date-fns/addDays';
import startOfToday from 'date-fns/startOfToday';
import endOfToday from 'date-fns/endOfToday';

const AuditLogController = props => {
	const [state, setState] = React.useState({
		auditLog: [],
		isLoading: false
	});
	const [fromDate, setFromDate] = React.useState(addDays(startOfToday(), -14));
	const [fromDateError, setFromDateError] = React.useState(null);
	const [toDate, setToDate] = React.useState(endOfToday());

	const fetchAuditLog = (from, to) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		get({
			url: `/api/audit-log?fromDate=${from.toISOString()}&toDate=${to.toISOString()}`,
			onSuccess: data => setState(prevState => ({ ...prevState, auditLog: data, isLoading: false })),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	};
	
	React.useEffect(() => {
		fetchAuditLog(fromDate, toDate);
	}, [props.contractId]);

	return (
		<PageLayout
			title="Audit Log"
			isLoading={state.isLoading}
			content={
				<AuditLogGrid
					auditLog={state.auditLog}
					canView={props.canView}
					onViewAuditLog={log => {  }}
					fromDate={fromDate}
					setFromDate={(date) => {
						setFromDate(date);
						if (date) {
							setFromDateError(null);
							fetchAuditLog(date, toDate);
						} else {
							setFromDateError('From Date is required');
							setState(prevState => ({ ...prevState, auditLog: [] }))
						}
					}}
					fromDateError={fromDateError}
					toDate={toDate}
					setToDate={(date) => {
						setToDate(date);
						fetchAuditLog(fromDate, date);
					}}
				/>
			}
		/>
    );
};

AuditLogController.propTypes = {
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

AuditLogController.defaultProps = {
};

const mapStateToProps = (state) => ({
	canView: canView(state, 'ViewAuditLog')
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
});

export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(AuditLogController);
