import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete as MuiAutoComplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { 
	// InputLabel, 
	CircularProgress,
	FormControl,
	FormHelperText,
	TextField
} from '@material-ui/core';
// import { errorColour } from '../../variables';
import uniqueId from 'lodash/uniqueId';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
import find from 'lodash/find';
import isString from 'lodash/isString';

const filterOptions = createFilterOptions({
	limit: 20
});

const AutoComplete = props => {
	const [id] = React.useState(props.id || uniqueId('select-'));
	const [options, setOptions] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState("");

	React.useEffect(() => {
		if (props.loadItems && props.loadItems.route) {
			const { route, mapItem, onSuccess } = props.loadItems;

			setLoading(true);
			
			get({
				url: `/api/${route}`,
				ignoreSessionTimeout: true,
				onSuccess: newOptions => {
					let mapped = newOptions;
					if (mapItem) mapped = map(newOptions, isString(mapItem) ? item => ({ label: item[mapItem], value: item[mapItem] }) : mapItem);
					setOptions(props.defaultItem ? [props.defaultItem, ...mapped] : mapped);
					setLoading(false);

					if (onSuccess) onSuccess(mapped);
				},
				onError: error => {
					console.error("Error fetching items:", error);
					setLoading(false);
				}
			});
		} else {
			setOptions(map(props.defaultItem ? [props.defaultItem, ...props.options] : props.options, item => isString(item) ? { label: item, value: item } : item));
			setLoading(false);
		}
	// }, [props.options, props.loadItems ? props.loadItems.route : ""]);
	}, [props.options]);

	React.useEffect(() => {
		const selectedItem = props.value ? find(options, o => o.value === props.value) : null;
		if (selectedItem) {
			setValue(selectedItem);
			setInputValue(selectedItem.label);
		} else {
			setValue(null);
			setInputValue("");
		}

	}, [props.value, options]);

	let error = false;
	let helpText = '';
	if (props.errorText) {
		error = true;
		helpText = props.errorText;
	}

	return (
		<FormControl
			fullWidth={props.fullWidth}
			error={error}
			variant={props.variant}
			style={props.style}
		>
			{/* {props.label && 
				<InputLabel htmlFor={id}>
					{props.label}
					{props.required && <span style={{ color: errorColour }}> *</span>}
				</InputLabel>
			} */}
			<MuiAutoComplete 
				id={id}
				filterOptions={filterOptions}
				options={options}
				getOptionLabel={props.getOptionLabel}
				value={value}
				inputValue={inputValue}
				onChange={(e, v) => {
					props.onChange(e, v);
				}}
				onInputChange={(e, v) => {
					setInputValue(v);
				}}
				renderInput={(params) => 
					<TextField 
						{...params} 
						value={inputValue}
						label={props.label} 
						variant={props.variant}  
						InputProps={{
							...params.InputProps,
							disableUnderline: props.disableUnderline,
							endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
							),
						}}
					/>
				}
			/>
			{helpText && <FormHelperText>{helpText}</FormHelperText>}
		</FormControl>
	);
};
	
AutoComplete.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	fullWidth: PropTypes.bool,
	required: PropTypes.bool,
	errorText: PropTypes.string,
	variant: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	getOptionLabel: PropTypes.func,
	value: PropTypes.any,
	disableUnderline: PropTypes.bool,
	style: PropTypes.object
};

AutoComplete.defaultProps = {
	id: '',
	label: '',
	required: false,
	errorText: undefined,
	variant: 'filled',
	fullWidth: false,
	onChange: () => {},
	options: [],
	getOptionLabel: (o) => o.label,
	value: undefined,
	disableUnderline: false,
	style: {}
};

export default AutoComplete;
