import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../widgets/Dialog';
import Button from '../../widgets/button';
import PersistedDataTable from '../../widgets/persistedDataTable';
import Checkbox from '../../widgets/Checkbox';
import some from 'lodash/some';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useMountEffect } from '../../../utils/utils';
import { get } from '../../../utils/ajax';
import { showErrorNotification } from '../../../reducers/notifications';
import { 
	CircularProgress
} from '@material-ui/core';

const UserAccessRoleDialog = (props) => {
	const [loading, setLoading] = React.useState(false);
	const [roles, setRoles] = React.useState([]);
	const [userRoles, setUserRoles] = React.useState([]);

    useMountEffect(() => {
        setLoading(true);
		get({
			url: `/api/roles`,
			onSuccess: data => {
				setLoading(false);
				setRoles(data);
			},
			onError: error => {
				setLoading(false);
				showErrorNotification(error.message);
			}
		});
    });

	React.useEffect(() => {
		setUserRoles(props.userRoles);
	}, [props.userRoles])

	const isAllSelected = () => {
		for (let i = 0, ii = roles.length; i < ii; i++) {
			const role = roles[i];
			if (!some(userRoles, ur => ur.roleId === role.roleId)) return false;
		}
		return true;
	};
	const toggleAll = () => {
		const nextValue = !isAllSelected();
		if (nextValue) {
			setUserRoles(map(roles, r => ({
				roleId: r.roleId,
				name: r.name
			})));
		} else {
			setUserRoles([]);
		}
	};

	const columns = [
		{
			name: '_isSelected',
			excludeFromFilter: true,
			dataType: 'bool',
			sortable: false,
			headerRenderer: () =>
				<Checkbox
					checked={isAllSelected()}
					onCheck={() => { toggleAll(); } }
				/>,
			value: (args) => args.data._isSelected || false,
			cellRenderer: (args) => {
				const isSelected = some(userRoles, ur => ur.roleId === args.data.roleId);
				return (
					<Checkbox
						checked={isSelected}
						onCheck={(e, v) => { 
							if (v) {
								if (!isSelected) {
									setUserRoles([
										...userRoles,
										{
											roleId: args.data.roleId,
											name: args.data.name
										}
									]);
								}
							} else {
								setUserRoles(filter(userRoles, ur => ur.roleId !== args.data.roleId));
							}
						} }
					/> 
				);
			}
		},
		{ name: 'name', label: 'Name' }
	];

	return (
		<Dialog
			title="Roles"
			actions={
				<React.Fragment>
					<Button
						label="Cancel"
						primary={false}
						onClick={props.onCancel}
					/>
					<Button
						label="Ok"
						primary={true}
						onClick={() => props.onChange(userRoles)}
					/>
				</React.Fragment>
			}
			open={props.show}
			onClose={props.onCancel}
			scroll='paper'
			fullWidth={true}
			maxWidth='lg'
		>
			{loading && <CircularProgress size={24} />}
			{!loading && <PersistedDataTable 
				id="newContractUserAccess"
				data={roles || []} 
				columns={columns}
			/>}
		</Dialog>
	);

};

UserAccessRoleDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	userRoles: PropTypes.array
};

UserAccessRoleDialog.defaultProps = {
	userRoles: []
};

export default UserAccessRoleDialog;
