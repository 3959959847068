import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../utils/ajax';
import EventIcon from '@material-ui/icons/Event';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import map from 'lodash/map';
import { useHistory } from 'react-router-dom';

const UpcomingEvents = ({ setLoading, contractId }) => {
	const [events, setEvents] = React.useState([]);
	const history = useHistory();

	const onSelectEvent = (e) => {
		history.push(`/event/${e.userEventId}`);
	}

	React.useEffect(() => {
		const loadData = () => {
			setLoading(true);
			setEvents([]);
			get({
				url: `/api/dashboard/upcoming-events?contractId=${contractId}`,
				onSuccess: data => {
					setLoading(false);
					setEvents(data);
				},
				onError: () => {
					setLoading(false);
				}
			});
		};

		if (contractId) {
			loadData();
		}
	}, [contractId]);

	if (events.length === 0) return <p style={{ marginLeft: '10px', marginTop: '10px' }}>No Upcoming Events</p>;
		
	return (
		<div style={{ overflow: 'auto', height: '100%' }}>
		<List>
			{
				map(events, (e, i) => (
					<ListItem
						key={`ue-${i}`}
						button
						onClick={() => onSelectEvent(e)}
					>
						<ListItemIcon>
							<EventIcon />
						</ListItemIcon>
						<ListItemText 
							primary={e.name}
							secondary={e.due}
						/>
					</ListItem>
				))
			}
		</List>
		</div>
	);
};

UpcomingEvents.propTypes = {
	setLoading: PropTypes.func.isRequired,
	contractId: PropTypes.number
};

UpcomingEvents.defaultProps = {
	contractId: null
};

export default UpcomingEvents;
