import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import map from 'lodash/map';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { toCurrency } from '../../utils/utils';
import Checkbox from '../widgets/Checkbox';
import Button from '../widgets/button';
import TextField from '../widgets/TextField';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '@material-ui/icons/Lock';
import UnlockedIcon from '@material-ui/icons/LockOpen';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';

class ContractForecastList extends Component {
    constructor(props) {
        super(props);

        this.state = {
			contractForecast: props.contractForecast
        };

		this.saveChanges = this.saveChanges.bind(this);
    }

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			contractForecast: nextProps.contractForecast
		});
	}

	saveChanges() {

	}

	getForecastPeriod = (f, period) => {
		let amount = null;
		let fp;
		let fpIndex;
		
		if (period.forecastPeriodId) {
			fpIndex = findIndex(f.periodForecasts, pf => pf.forecastPeriodId === period.forecastPeriodId);
			if (fpIndex > -1) {
				fp = f.periodForecasts[fpIndex];
				amount = fp.amount;
			}
		} else {
			fpIndex = findIndex(f.periodForecasts, pf => pf.periodId === period.periodId);
			if (fpIndex > -1) {
				fp = f.periodForecasts[fpIndex];
				amount = fp.amount;
			} else {
				// May be in a forecast period that was merged into the actual period
				for (let i = 0, ii = period.forecastPeriods.length; i < ii; i++) {
					fpIndex = findIndex(f.periodForecasts, pf => pf.forecastPeriodId === period.forecastPeriods[i].forecastPeriodId);
					fp = f.periodForecasts[fpIndex];
					if (fpIndex > -1) {
						amount = fp.amount;
						break;
					}
				}
			}
		}

		return {
			forecastPeriod: fp,
			fpIndex: fpIndex,
			amount: amount
		};
	}
	
	getSubForecastPeriod = (f, sf, period) => {
		let amount = null;
		let fp;
		let fpIndex;

		if (period.forecastPeriodId) {
			fpIndex = findIndex(sf.periodForecasts, pf => pf.forecastPeriodId === period.forecastPeriodId);
			if (fpIndex > -1) {
				fp = sf.periodForecasts[fpIndex];
				amount = fp.amount;
			}
		} else {
			fpIndex = findIndex(sf.periodForecasts, pf => pf.forecastPeriodId === period.periodId);
			if (fpIndex > -1) {
				fp = sf.periodForecasts[fpIndex];
				amount = fp.amount;
			}
		}

		return {
			forecastPeriod: fp,
			fpIndex: fpIndex,
			amount: amount
		};
	}

	render() {
		const contractForecast = this.props.contractForecast || {};
		const periods = this.props.periods;

		const activeForecast = find(contractForecast.forecasts, (f) => f.type === 'Calculated' && f.isActive);

		const headerStyles = { backgroundColor: '#eee' };
		const totalStyles = { backgroundColor: '#eee' };
		
		return (
<div className="contract-forecast-list">
	<div style={{ overflowX: 'auto' }}>
		<table 
			className="full-bordered data-table" 
			style={{ 
				width: '100%'
			}}
		>
			<thead>
				<tr>
					<th style={headerStyles}></th>
					<th style={headerStyles}>Chart?</th>
					<th style={headerStyles}>Forecast</th>
					{ 
						map(periods, (p) => {
							let backgroundColour;
							let textColour;
							switch (p.status) {
								case 'Closed':
									backgroundColour = '#f0ad4e';
									textColour = '#fff';
									break;
								case 'Open':
									backgroundColour = '#35aa47';
									textColour = '#fff';
									break;
								case 'Forecast':
									backgroundColour = '#5bc0de';
									textColour = '#fff';
									break;
								default:
							}
							return (
								<th 
									style={{
										...headerStyles,
										backgroundColor: backgroundColour,
										color: textColour
									}}
								>
									{p.name}
								</th>
							);
						})
					}
				</tr>
			</thead>
			<tbody>
				{this.props.isLoading && 
					<tr>
						<td colSpan={periods.length + 3} className="contract-forecast-list__loading">
							<CircularProgress />
						</td>
					</tr>
				}
				{!this.props.isLoading && (!contractForecast.forecasts || contractForecast.forecasts.length === 0) &&
					<tr>
						<td colSpan={periods.length + 3} className="contract-forecast-list__no-forecasts-text">
							<p>
								There are no forecasts.
							</p>
						</td>
					</tr>
				}
				{!this.props.isLoading &&
					map(contractForecast.forecasts, (f, forecastIndex) => {
						const rows = [];

						rows.push(
							<tr className="data-table-row">
								<td
									style={{
										minWidth: f.isLocked ? undefined : '110px'
									}}
								>
									{f.isLocked &&
										<IconButton onClick={ () => { this.props.unlockForecast(forecastIndex); } } style={{ padding: '2px' }}>
											<LockIcon />
										</IconButton>
									}
									{!f.isLocked &&
										<IconButton onClick={ () => { this.props.lockForecast(forecastIndex); } } style={{ padding: '2px' }}>
											<UnlockedIcon />
										</IconButton>
									}
									{!f.isLocked && 
										<IconButton onClick={ () => { this.props.deleteForecast(forecastIndex); } } style={{ padding: '2px' }}>
											<ActionDelete />
										</IconButton>
									}
								</td>
								<td>
									<Checkbox
										checked={f.includeInChart}
										disabled={f.isLocked}
										onCheck={(e, v) => { this.props.setIncludeInChart(forecastIndex, v); } }
									/> 
								</td>
								<td style={{ minWidth: `${Math.max(30, f.name.length)}ch` }}>
									{f.isLocked && f.name}
									{!f.isLocked && 
										<TextField
											value={f.name}
											variant="standard"
											InputProps={{ disableUnderline: true }}
											onChange={(e) => { this.props.updateForecastName(forecastIndex, e.target.value); } }
											fullWidth={true}
										/>
									}
								</td>
								{  
									map(periods, (period) => {
										const isForecastPeriod = period.type === 'Forecast';
										const isContractorForecast = f.type === 'Contractor';
										let isEditable = false;

										const { 
											amount, 
											forecastPeriod: fp, 
											fpIndex 
										} = this.getForecastPeriod(f, period);

										isEditable = !f.isLocked && (isForecastPeriod || isContractorForecast);

										if (isEditable) {
											return (
												<td className="numeric-cell">
													<DataTableNumericEditor
														type="currency"
														value={amount}
														nullable={true} 
														onChange={(v) => { if (v !== amount) this.props.setForecastAmount(forecastIndex, fpIndex, period, v); }}
													/>
												</td>
											);
										} else {
											return <td className="numeric-cell">{fp ? toCurrency(amount) : ''}</td>;
										}
									})
								}
							</tr>
						);

						if (f.type === 'Project') {
							rows.push(
								<tr>
									<td colSpan={periods.length + 3}>
										<table className="full-bordered data-table" style={{ width: '100%' }}>
											<tbody>
											<tr>
												<th colSpan={contractForecast.forecastPeriods.length + 3} style={{ textAlign: 'left' }}>
													{f._isCollapsed &&
														<IconButton 
															onClick={ () => { this.props.expandForecast(forecastIndex); } } 
															style={{ 
																padding: '2px', 
																height: '30px', 
																width: '30px',
																verticalAlign: 'top',
																margin: '5px 0 0 0'
															}}
														>
															<ExpandIcon />
														</IconButton>
													}
													{!f._isCollapsed &&
														<IconButton 
															onClick={ () => { this.props.collapseForecast(forecastIndex); } } 
															style={{ 
																padding: '2px', 
																height: '30px', 
																width: '30px',
																verticalAlign: 'top',
																margin: '5px 0 0 0'
															}}
														>
															<CollapseIcon />
														</IconButton>
													}
													<h3 style={{ display: 'inline-block', margin: '8px 0 0 0' }}>Sub Forecasts</h3>

													{!f.isLocked &&
														<Button
															label="Add Sub Forecast"
															primary={true}
															onClick={() => this.props.addSubForecast(forecastIndex)}
															style={{ verticalAlign: 'top' }}
														/>
													}
												</th>
											</tr>
											{!f._isCollapsed && (!f.subForecasts || f.subForecasts.length === 0) &&
												<tr>
													<td>No sub forecasts exist</td>
												</tr>
											}
											{!f._isCollapsed && f.subForecasts && f.subForecasts.length > 0 &&
											<tr>
												{!f.isLocked &&
													<th style={headerStyles}></th>
												}
												<th style={headerStyles}>Include</th>
												<th style={headerStyles}>Name</th>
												{ 
													map(periods, (p) => {
														let backgroundColour;
														let textColour;
														switch (p.status) {
															case 'Closed':
																backgroundColour = '#f0ad4e';
																textColour = '#fff';
																break;
															case 'Open':
																backgroundColour = '#35aa47';
																textColour = '#fff';
																break;
															case 'Forecast':
																backgroundColour = '#5bc0de';
																textColour = '#fff';
																break;
															default:
														}
														return (
															<th 
																style={{
																	...headerStyles,
																	backgroundColor: backgroundColour,
																	color: textColour
																}}
															>
																{p.name}
															</th>
														);
													})
												}	
											</tr>
											}
											{!f._isCollapsed && f.subForecasts && 
												map(f.subForecasts, (sf, subForecastIndex) =>
													<tr className="data-table-row">
														{!f.isLocked &&
															<td>
																<IconButton onClick={ () => { this.props.deleteSubForecast(forecastIndex, subForecastIndex); } } style={{ padding: '2px' }}>
																	<ActionDelete />
																</IconButton>
															</td>
														}
														<td>
															<Checkbox
																checked={sf.include}
																disabled={f.isLocked}
																onCheck={(e, v) => { this.props.setSubForecastInclude(forecastIndex, subForecastIndex, v); } }
															/> 
														</td>
														<td>
															{f.isLocked && sf.name}
															{!f.isLocked &&
																<TextField
																	value={sf.name}
																	variant="standard"
																	InputProps={{ disableUnderline: true }}
																	onChange={(e) => { this.props.updateSubForecastName(forecastIndex, subForecastIndex, e.target.value); } }
																	fullWidth={true}
																/>
															}
														</td>
														{  
															map(periods, (period) => {
																const isForecastPeriod = period.type === 'Forecast';
																const isContractorForecast = f.type === 'Contractor';
																let isEditable = false;

																const { 
																	amount, 
																	forecastPeriod: fp, 
																	fpIndex 
																} = this.getSubForecastPeriod(f, sf, period);

																isEditable = !f.isLocked && (isForecastPeriod || isContractorForecast);
												
																if (isEditable) {
																	return (
																		<td className="numeric-cell">
																			<DataTableNumericEditor
																				type="currency"
																				value={amount}
																				nullable={true} 
																				onChange={(v) => { if (v !== amount) this.props.setSubForecastAmount(forecastIndex, subForecastIndex, fpIndex, period, v); }}
																			/>
																		</td>
																	);
																} else {
																	return <td className="numeric-cell">{fp ? toCurrency(amount) : ''}</td>;
																}
															})
														}
													</tr>
												)
											}
											{f.subForecasts && f.subForecasts.length > 0 &&
											<tr>
												<td colSpan={3}>
													<span>Forecast Total</span>
												</td>
												{  
													map(periods, (period) => {
														let { 
															amount
														} = this.getForecastPeriod(f, period);
														let hasValue = amount !== undefined;
														amount = amount || 0;

														for (let i = 0, ii = f.subForecasts.length; i < ii; i++) {
															const subForecast = f.subForecasts[i];
															if (subForecast.include) {
																const { 
																	amount: subAmount,
																	forecastPeriod: fp
																} = this.getSubForecastPeriod(f, subForecast, period);
																hasValue = hasValue || !!fp;
																amount += subAmount || 0;
															}
														}
															
														return <td className="numeric-cell">{hasValue ? toCurrency(amount) : ''}</td>;
													})
												}
											</tr>
											}
											</tbody>
										</table>
									</td>
										
								</tr>
							);
						}

						rows.push(<tr style={{ backgroundColor: '#eee' }}><td colSpan={periods.length + 3}></td></tr>);

						return rows;
					})
				}
			</tbody>
			<tfoot>
				{!this.props.isLoading && activeForecast &&
					<tr>
						<td colSpan={3} style={totalStyles}>Contract Forecast</td>
						{ 
							map(periods, (p) => {
								if (p.type === 'Forecast') {
									const amount = 0;
									return <td className="numeric-cell" style={totalStyles}>{toCurrency(amount)}</td>;
								} else {
									return <td className="numeric-cell" style={{ ...totalStyles, color: '#35aa47' }}>{toCurrency(p.certifiedToDate)}</td>;
								}
							})
						}
					</tr>
				}
			</tfoot>
		</table>
	</div>


</div>
		);
	}
}

ContractForecastList.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	periods: PropTypes.array.isRequired,
	setIncludeInChart: PropTypes.func.isRequired,
	contractForecast: PropTypes.object.isRequired,
	// newForecastPeriod: PropTypes.func.isRequired,
	updateForecastName: PropTypes.func.isRequired,
	setForecastAmount: PropTypes.func.isRequired,
	deleteForecast: PropTypes.func.isRequired,

	addSubForecast: PropTypes.func.isRequired,
	deleteSubForecast: PropTypes.func.isRequired,
	updateSubForecastName: PropTypes.func.isRequired,
	setSubForecastAmount: PropTypes.func.isRequired,
	setSubForecastInclude: PropTypes.func.isRequired,
	lockForecast: PropTypes.func.isRequired,
	unlockForecast: PropTypes.func.isRequired,
	expandForecast: PropTypes.func.isRequired,
	collapseForecast: PropTypes.func.isRequired
};

export default injectIntl(ContractForecastList);
