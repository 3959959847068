import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import FormFieldContainer from '../formFieldContainer';
import Checkbox from '../widgets/Checkbox';

class EmployeeForm extends React.Component {
	render() {
		const employee = this.props.employee;

		return (
<div>
	<FormFieldContainer>
		<TextField
			value={employee.name}
			fullWidth={true}
			onChange={(e, v) => { this.props.updateField('name', v); }}
			label="Name"
			errorText={getFieldErrorText(this.props.saveResult, 'Name')}
		/>
	</FormFieldContainer>

	<FormFieldContainer>
		<TextField
			value={employee.email}
			fullWidth={true}
			onChange={(e, v) => { this.props.updateField('email', v); }}
			label="Email"
			errorText={getFieldErrorText(this.props.saveResult, 'Email')}
		/>
	</FormFieldContainer>

	<FormFieldContainer classes={{ s12: true, m6: true, hasToggle: true }}>
		<Checkbox
			label="Is Active"
			labelPosition="right"
			checked={employee.isActive}
			onCheck={(e, v) => { this.props.updateField('isActive', v); }}
		/>
	</FormFieldContainer>
</div>
		);
	}
}

EmployeeForm.propTypes = {
	employee: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired
};

export default EmployeeForm;
