import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import ProgressIndicator from '../widgets/progressIndicator';
import IncomingCorrespondenceGrid from './incomingCorrespondenceGrid';
import { showModal } from '../../reducers/modal';
import { 
	deleteIncomingCorrespondence
} from '../../reducers/incomingCorrespondence';
import ContentContainer from '../contentContainer';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { getContractId } from '../../selectors/contract';
import { getPeriodId } from '../../selectors/period';
import filter from 'lodash/filter';
import history from '../../history';
import axios from 'axios';
import { get } from '../../utils/ajax';
import { useMountEffect } from '../../utils/utils';

const IncomingCorrespondence = props => {
	const [state, setState] = React.useState({
		correspondences: [],
		isLoading: false
	});
	const [tags, setTags] = React.useState([]);

	useMountEffect(() => {
		const contractId = props.contractId;
		const periodId = props.periodId;
		const request = axios.CancelToken.source();

		setState(prevState => ({
			...prevState,
			isLoading: true
		}));

		get({
			url:`/api/incoming-correspondence?contractId=${contractId}&periodId=${periodId}`,
			cancelToken: request.token,
			onSuccess: data => {
				setState(prevState => ({
					...prevState,
					correspondences: data,
					isLoading: false
				}));
			},
			onError: error => {
				if (!axios.isCancel(error)) {
					setState(prevState => ({
						...prevState,
						correspondences: [],
						isLoading: false
					}));
					props.showError(error.message);
				}
			}
		});

		get({
			url:`/api/incoming-correspondence/tags?contractId=${contractId}`,
			cancelToken: request.token,
			onSuccess: data => {
				setTags(data);
			},
			onError: error => {
			}
		});

		return () => {
			request.cancel();
		};
	});

	const onEditCorrespondence = (correspondence) => {
		history.push(`/incoming-correspondence/${correspondence.incomingCorrespondenceId}`);
	};
	const onViewCorrespondence = (correspondence) => {
		history.push(`/incoming-correspondence/${correspondence.incomingCorrespondenceId}`);
	};
	const onDeleteCorrespondence = (correspondence) => {
		props.deleteIncomingCorrespondence(correspondence, (data) => {
			if (data.success) {
				setState(prevState => ({
					...prevState,
					correspondences: filter(state.correspondences, c => c.incomingCorrespondenceId !== data.objectId),
					isLoading: false
				}));
				props.showSuccessNotification(data.message);
			}
		});
	};

	const registerNewCorrespondence = () => {
		props.history.push('/incoming-correspondence');
	};

	return (
<PageLayout
	title="Incoming Correspondence"
	isLoading={state.isLoading}
	actions={
		props.canEdit ? 
		[
			{
				label: 'Register Incoming Correspondence',
				onClick: registerNewCorrespondence
			}
		] : []
	}
	content={
		<ContentContainer>
			{state.isLoading &&
				<ProgressIndicator />
			}
			{!state.isLoading &&
				<IncomingCorrespondenceGrid 
					correspondences={state.correspondences} 
					onEditCorrespondence={onEditCorrespondence} 
					onDeleteCorrespondence={onDeleteCorrespondence} 
					onViewCorrespondence={onViewCorrespondence} 
					canView={props.canView}
					canEdit={props.canEdit}
					tags={tags}
				/>
			}
		</ContentContainer>
	}
/>
	);
};

IncomingCorrespondence.propTypes = {
	// dispatch: PropTypes.func.isRequired,
	contractId: PropTypes.number.isRequired,
	periodId: PropTypes.number.isRequired,
	// correspondences: PropTypes.array,
	deleteIncomingCorrespondence: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canView: PropTypes.bool.isRequired,
	// isLoading: PropTypes.bool.isRequired,
	showError: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

IncomingCorrespondence.defaultProps = {
	// correspondences: []
};

const mapStateToProps = (state) => ({
	contractId: getContractId(state),
	periodId: getPeriodId(state),
	// correspondences: state.incomingCorrespondence.incomingCorrespondences,
	canView: canView(state, 'ViewIncomingCorrespondenceItem'),
	canEdit: canEdit(state, 'EditIncomingCorrespondence'),
	isLoading: state.incomingCorrespondence.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteIncomingCorrespondence: (correspondence, onSuccess) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this correspondence?',
			onOk: () => {
				dispatch(deleteIncomingCorrespondence(correspondence, onSuccess));
			}
		}));
	},
	showError: (error) => {
		dispatch(showErrorNotification(error));
	},
	showSuccessNotification: (error) => {
		dispatch(showSuccessNotification(error));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(IncomingCorrespondence);
