import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import UserForm from './UserForm';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import { emptySaveResult } from '../../variables';
import { get, post } from '../../utils/ajax';
import cloneDeep from 'lodash/cloneDeep';
import { globals } from '../../globals';

const UserMaintenance = (props) => {
	const [state, setState] = React.useState({
		user: null,
		tenants: [],
		roles: [],
		contracts: [],
		isLoading: false,
		saveResult: emptySaveResult,
		hasUnsavedChanges: false,
		showUserRoleDialog: false
	});

	const userId = props.match.params.userId;

	React.useEffect(() => {
		if (userId) {
			setState(prevState => ({ ...prevState, isLoading: true }));
			get({
				url: `/api/users/${userId}`,
				onSuccess: data => {
					setState(prevState => ({ ...prevState, user: data, isLoading: false }));
					loadFields(props.isGlobalAdmin ? data.tenantId : null);
				},
				onError: (error) => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		} else {
			setState(prevState => ({ ...prevState, user: { ...cloneDeep(globals.templates.user) } }));
			loadFields();
		}
		if (props.isGlobalAdmin) {
			get({
				url: '/api/tenants',
				onSuccess: data => setState(prevState => ({ ...prevState, tenants: data, isLoading: false })),
				onError: (error) => {
					setState(prevState => ({ ...prevState, isLoading: false }));
				}
			});
		}
	}, [userId]);

	const loadFields = (tenantId) => {
		setState(prevState => ({ ...prevState, contracts: [], roles: [] }));
		get({
			url: tenantId ? `/api/contracts/get-by-tenant/${tenantId}` : '/api/contracts',
			onSuccess: data => setState(prevState => ({ ...prevState, contracts: data, isLoading: false })),
			onError: (error) => {
				setState(prevState => ({ ...prevState, isLoading: false }));
			}
		});
		get({
			url: tenantId ? `/api/roles/get-by-tenant/${tenantId}` : '/api/roles',
			onSuccess: data => setState(prevState => ({ ...prevState, roles: data, isLoading: false })),
			onError: (error) => {
				setState(prevState => ({ ...prevState, isLoading: false }));
			}
		});
	};

	const onTenantIdChanged = (tenantId) => {
		loadFields(tenantId);
		const user = state.user;
		user.tenantId = tenantId;
        setState(prevState => ({
			...prevState,
			user,
			hasUnsavedChanges: true
        }));
	};

	const updateField = (field, newValue) => {
		const user = state.user;
		user[field] = newValue;
        setState(prevState => ({
			...prevState,
			user,
			hasUnsavedChanges: true
        }));
	};

	const showRolesDialog = (userContractRoles) => {
		setState(
			prevState => ({
				...prevState,
				showUserRoleDialog: true,
				userContractRoles: userContractRoles
			})
		);
	};
	const onCancelUserRoleDialog = () => {
		setState(
			prevState => ({
				...prevState,
				showUserRoleDialog: false
			})
		);
	};
	const onUpdateRoles = (userContractRoles, roles) => {
		const user = state.user;
		let contractRoles = user.contractRoles[userContractRoles.contractId];
		if (!contractRoles) {
			contractRoles = {
				contractId: userContractRoles.contractId,
				contractName: userContractRoles.contractName
			};
		}
		contractRoles.roles = roles;
		user.contractRoles[userContractRoles.contractId] = contractRoles;

		setState(
			prevState => ({
				...prevState,
				user: user,
				showUserRoleDialog: false
			})
		);
	}

	const validateUser = (user) => {
		const errors = [];
		if (!user.userName) {
			errors.push({
				fieldName: 'UserName',
				valid: false,
				message: 'User Name is required'
			});
		}
		if (user._isNew) {
			if (!user.password) {
				errors.push({
					fieldName: 'Password',
					valid: false,
					message: 'Password is required'
				});
			} else if (user.password.length < 8) {
				errors.push({
					fieldName: 'Password',
					valid: false,
					message: 'The password must be at least 8 characters long'
				});
			}
		}
		if (!user.email) {
			errors.push({
				fieldName: 'Email',
				valid: false,
				message: 'Email is required'
			});
		}
		if (!user.userRole) {
			errors.push({
				fieldName: 'UserRole',
				valid: false,
				message: 'Role is required'
			});
		}
		return errors;
	};

	const save = (onSuccess) => {
		const errors = validateUser(state.user);
		if (errors.length > 0) {
			setState(prevState => ({
				...prevState, 
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: errors
				}
			}));
			return;
		}

		setState(prevState => ({ ...prevState, isLoading: true, saveResult: emptySaveResult }));
			
		post({
			url: `/api/users`,
			data: state.user,
			onSuccess: data => {
				setState(prevState => ({ 
					...prevState,
					isLoading: false, 
					user: data.success ? data.object : state.user,
					saveResult: {
						success: data.success,
						message: data.message,
						fields: data.fields
					} 
				}));

				if (data.success) {
					props.showSuccessNotification(data.message);
					if (onSuccess) onSuccess();
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};
	const saveChanges = () => save(() => { props.history.goBack(); });
	const saveChangesAndContinue = () => save();
	const onCancel = () => props.history.goBack();

	const user = state.user || {};

	return (
<div>
	<UserForm 
		isGlobalAdmin={props.isGlobalAdmin}
		user={user} 
		userRole={props.userRole}
		canEdit={props.canEdit}
		tenants={state.tenants}
		contracts={state.contracts}
		roles={state.roles}
		onTenantIdChanged={onTenantIdChanged}
		updateFieldValue={updateField}
		onSave={(u) => saveChanges(u) } 
		onSaveAndContinue={(u) => saveChangesAndContinue(u) } 
		onCancel={onCancel} 
		isLoading={state.isLoading} 
		saveResult={state.saveResult} 
		showRolesDialog={showRolesDialog}
		onCancelUserRoleDialog={onCancelUserRoleDialog}
		onUpdateRoles={onUpdateRoles}
		showUserRoleDialog={state.showUserRoleDialog}
		userContractRoles={state.userContractRoles}
	/>
</div>
	);
}

UserMaintenance.propTypes = {
	userRole: PropTypes.string.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isGlobalAdmin: PropTypes.bool,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

UserMaintenance.defaultProps = {
	isGlobalAdmin: true
};

const mapStateToProps = (state) => ({
	canEdit: canEditAnyPeriod(state, 'EditUsers'),
	userRole: state.context.userRole,
	isGlobalAdmin: state.context.userRole === "Global Administrator"
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(UserMaintenance);
