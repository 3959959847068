
export const primaryColour = "#0E76BC";
export const primaryTextColour = "#fff";
export const secondaryColour = "#8DC63F";
export const secondaryTextColour = "#fff";

export const successColour = '#35aa47';
export const errorColour = '#d9534f';
export const errorColourContrast = '#fff';
export const warningColour = '#f0ad4e';
export const infoColor = '#5bc0de';
export const infoForeColor = '#ffffff';
export const chipColour = '#35aa47';
// export const primaryColour = '#00bcd4';
export const palette = ['#AA4643', '#4572A7', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92'];

export const units = [
	{ key: '', text: '' },
	{ key: 'container', text: 'container' },
	{ key: 'each', text: 'each' },
	{ key: 'kg', text: 'kL' },
	{ key: 'kilolitre', text: 'kilolitre' },
	{ key: 'kL', text: 'kL' },
	{ key: 'litre', text: 'litre' },
	{ key: 'lump sum', text: 'lump sum' },
	{ key: 'm', text: 'm' },
	{ key: 'm2', text: 'm2' },
	{ key: 'm^2', text: 'm^2' },
	{ key: 'm3', text: 'm3' },
	{ key: 'm^3', text: 'm^3' },
	{ key: 'per set', text: 'per set' },
	{ key: 'tonne', text: 'tonne' }
];

export const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};
