import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete as MuiAutoComplete } from '@material-ui/lab';
import { 
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText
} from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import map from 'lodash/map';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';
import filter from 'lodash/filter';
import { post } from '../../utils/ajax';
import Button from './button';
import TextField from './TextField';
import SaveIcon from '@material-ui/icons/Save';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import { emptySaveResult } from '../../variables';
import { getFieldErrorText } from '../../utils/field-validation';
import MessagePanel from '../messagePanel';
import { showSuccessNotification } from '../../reducers/notifications';
import { fetch, addTask } from 'domain-task';

const filterOptions = createFilterOptions({
});

const PersonSelect = (props) => {
	const [loading, setLoading] = React.useState(false);
	const [allPeople, setAllPeople] = React.useState([]);
	const [showAddDialog, setShowAddDialog] = React.useState(false);
	const [addValue, setAddValue] = React.useState({
		personType: 'Contact',
		name: '',
		email: ''
	});
	const [saveResult, setSaveResult] = React.useState(emptySaveResult);
	const contractId = props.contractId;

	React.useEffect(() => {
		setLoading(true);
		const fetchTask = 
			fetch(
				`/api/people?contractId=${contractId}`, 
				{
					credentials: 'same-origin',
					headers: {
						'cache-control': 'no-store',
						'pragma': 'no-cache'
					}
				}
			)
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();
				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				setLoading(false);
				setAllPeople(data);
			})
			.catch((error) => {
				setLoading(false);
				console.log('request failed', error);
			});
	
		addTask(fetchTask);
	}, [contractId]);

	// Create a single field id for the people
	let people = map(allPeople, p => ({ ...p, id: `${p.personType}|${p.personId}` }));
	
	// Sort items
	people = sortBy(people || [], ['personType', 'name'], [props.showContactsFirst ? 'asc' : 'desc', 'asc']);

	const value = `${props.personType}|${props.personId}`;

	const personItems = [];
	let personValue = null;
	for (let i = 0, ii = people.length; i < ii; i++) {
		const p = people[i];
		if (p.isActive) {
			if (p.id === value) personValue = p;
			personItems.push(
				{ value: p.id, label: p.name }
			);
		}
	}

	if (!personValue) {
		const p = find(people, p2 => p2.id === value);
		if (p) {
			personValue = p;
			personItems.push(
				{ value: p.id, label: p.name, disabled: true }
			);
		}
	}

	const [id] = React.useState(props.id || uniqueId('people-select-'));

	let error = false;
	let helpText = '';
	if (props.errorText) {
		error = true;
		helpText = props.errorText;
	}

	const onCloseAddDialog = () => {
		setAddValue({
			personType: 'Contact',
			name: '',
			email: ''
		});
	
		setShowAddDialog(false);
	};
	const onAdd = (e) => {
		e.preventDefault();
		
		const errors = [];
		if (!addValue.name) {
			errors.push({
				fieldName: 'Name',
				valid: false,
				message: 'Name is required'
			});
		}

		if (errors.length > 0) {
			setSaveResult({
				success: false,
				message: 'Please correct the errors',
				fields: errors
			});
			return;
		}

		setLoading(true);
		setSaveResult(emptySaveResult);

		post({
			url: `/api/contacts?contractId=${props.contractId}`,
			data: {
				contactId: 0,
				contractId: props.contractId,
				name: addValue.name,
				email: addValue.email,
				isActive: true
			},
			onSuccess: data => {
				setLoading(false);
				setSaveResult({
					success: data.success,
					message: data.message,
					fields: data.fields
				});

				if (data.success) {
					const newContact = data.object;
					props.showSuccessNotification(`'${newContact.name} was added to the list of contacts`);
					const newPerson = {
						personType: 'Contact',
						personId: newContact.contactId,
						name: newContact.name,
						email: newContact.email,
						isActive: newContact.isActive
					};
					setAllPeople([...allPeople, newPerson]);
					props.onChange({ personType: 'Contact', personId: newContact.contactId });
					setShowAddDialog(false);
				}
			},
			onError: err => {
				setLoading(false);
				setSaveResult({
					success: false,
					message: err.message,
					fields: []
				});
			}
		});

		onCloseAddDialog();
	};

	var activePeople = filter(people, p => p.isActive);

	return (
		<React.Fragment>
		<FormControl
			fullWidth={props.fullWidth}
			error={error}
			variant={props.variant}
			style={props.style}
		>
			<MuiAutoComplete 
				id={id}
				loading={true}
				filterOptions={(options, params) => {
					const filtered = filterOptions(options, params);

					if (params.inputValue !== '') {
						filtered.push({
							inputValue: params.inputValue,
							name: `Add "${params.inputValue}"`,
						});
					}

					return filtered;
				}}
				value={personValue}
				groupBy={(option) => option.personType ? `${option.personType}s` : ''}
				options={activePeople}
				getOptionLabel={(option) => {
					if (typeof option === 'string') {
						return option;
					}
					if (option.inputValue) {
						return option.inputValue;
					}
					return option.name;
				}}
				renderOption={(option) => option.name}
				freeSolo={true}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				onChange={(event, newValue) => {
					if (typeof newValue === 'string') {
						// timeout to avoid instant validation of the dialog's form.
						setTimeout(() => {
							setShowAddDialog(true);
							setAddValue({
								personType: 'Contact',
								name: newValue,
								email: '',
							});
						});
					} else if (newValue && newValue.inputValue) {
						setShowAddDialog(true);
						setAddValue({
								personType: 'Contact',
								name: newValue.inputValue,
							email: '',
						});
					} else if (newValue) {
						props.onChange({ personType: newValue.personType, personId: newValue.personId });
					} else {
						props.onChange(null);
					}
				}}
				renderInput={(params) => 
					<TextField 
						{...params} 
						label={props.label} 
						variant={props.variant}  
						InputProps={{
							...params.InputProps,
							disableUnderline: props.disableUnderline,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				}
			/>
			{helpText && <FormHelperText>{helpText}</FormHelperText>}
		</FormControl>
		<Dialog 
			open={showAddDialog} 
			onClose={onCloseAddDialog} 
			aria-labelledby="form-dialog-title" 
			fullWidth={true}
			maxWidth='md'
		>
			<DialogTitle id="form-dialog-title">Add a new contact</DialogTitle>
			<DialogContent>
				<div className="row clearfix">
				<FormRow>
					<FormFieldContainer>
						<TextField
							autoFocus
							fullWidth={true}
							id="contact-name"
							value={addValue.name}
							onChange={(event) => setAddValue({ ...addValue, name: event.target.value })}
							label="Name"
							errorText={getFieldErrorText(saveResult, 'Name')}
						/>
					</FormFieldContainer>
				</FormRow>

				<FormRow>
					<FormFieldContainer>
						<TextField
							id="contact-email"
							fullWidth={true}
							value={addValue.email}
							onChange={(event) => setAddValue({ ...addValue, email: event.target.value })}
							label="Email"
							errorText={getFieldErrorText(saveResult, 'Name')}
						/>
					</FormFieldContainer>
				</FormRow>
				<MessagePanel 
					isSuccess={saveResult.success} 
					message={saveResult.message} 
				/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button 
					primary={true} 
					icon={<SaveIcon />} 
					loading={loading}
					onClick={onAdd} 
				>
					Add
				</Button>
				<Button onClick={onCloseAddDialog}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
		</React.Fragment>
	);
};

PersonSelect.propTypes = {
	contractId: PropTypes.number.isRequired,
	id: PropTypes.string,
	fullWidth: PropTypes.bool,
	required: PropTypes.bool,
	variant: PropTypes.string,
	style: PropTypes.object,
	options: PropTypes.array,
	disableUnderline: PropTypes.bool,
	personType: PropTypes.string,
	personId: PropTypes.any,
	label: PropTypes.node.isRequired,
	onChange: PropTypes.func,
	errorText: PropTypes.node,
	showSuccessNotification: PropTypes.func.isRequired,
	showContactsFirst: PropTypes.bool
};

PersonSelect.defaultProps = {
	id: '',
	fullWidth: false,
	required: false,
	variant: 'filled',
	onChange: () => {},
	options: [],
	disableUnderline: false,
	personType: undefined,
	personId: undefined,
	errorText: undefined,
	style: {},
	showContactsFirst: true
};

const mapStateToProps = (state) => ({
	contractId: state.context.contract ? state.context.contract.contractId : 0
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PersonSelect);
