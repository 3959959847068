import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { updateContract } from '../../reducers/context';
import { 
	saveContract, 
	createNewContract, 
	requestContractByNumber, 
	clearContract 
} from '../../reducers/contracts';
import { requestContractors } from '../../reducers/contractors';
import { requestEmployees } from '../../reducers/employees';
import ContractForm from './contractForm';
import { showSuccessNotification } from '../../reducers/notifications';
import { canEditAnyPeriod } from '../../selectors/canEdit';

class ContractMaintenance extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleSubmit = this.handleSubmit.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestContractors());
		dispatch(requestEmployees());
		if (props.match.params.contractNumber) {
			dispatch(requestContractByNumber(props.match.params.contractNumber));
		} else {
			dispatch(createNewContract());
		}
	}
	static clearData(dispatch) {
		dispatch(clearContract());
	}
	componentDidMount() {
		ContractMaintenance.fetchData(this.props.dispatch, this.props);
	}

	onCancel = () => {
		this.props.history.goBack();
	}
	handleSubmit(contract) {
		this.props.saveChanges(contract, !this.props.match.params.contractNumber);
	}
	
	render() {
		const contract = this.props.contract || {};
		const contractors = this.props.contractors || [];
		const employees = this.props.employees || [];

		return (
<div>
	<ContractForm 
		contract={contract} 
		contractors={contractors} 
		employees={employees}
		onSave={(c) => this.props.saveChanges(c, !this.props.match.params.contractNumber) } 
		onSaveAndContinue={(c) => this.props.saveChangesAndContinue(c, !this.props.match.params.contractNumber) } 
		onCancel={this.onCancel} 
		isLoading={this.props.isLoading} 
		saveResult={this.props.saveResult} 
		canEdit={this.props.canEdit}
	/>
</div>
		);
	}
}

ContractMaintenance.propTypes = {
	dispatch: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveChanges: PropTypes.func.isRequired,
	saveChangesAndContinue: PropTypes.func.isRequired,
	contract: PropTypes.object,
	contractors: PropTypes.array,
	employees: PropTypes.array,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

ContractMaintenance.defaultProps = {
	contract: null,
	contractors: [],
	employees: []
};

const mapStateToProps = (state) => ({
	canEdit: canEditAnyPeriod(state, 'EditContracts'),
	contract: state.contracts.contract,
	contractors: state.contractors.contractors,
	employees: state.employees.employees,
	isLoading: state.contracts.isLoading,
	saveResult: state.contracts.saveResult
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	dispatch,
	saveChanges: (contract, isNew) => {
		dispatch(saveContract(contract, (data) => {
			dispatch(updateContract(data.object, isNew));
			dispatch(showSuccessNotification(data.message));
			ownProps.history.goBack();
		}));
	},
	saveChangesAndContinue: (contract, isNew) => {
		dispatch(saveContract(contract, (data) => {
			dispatch(updateContract(data.object, isNew));
			dispatch(showSuccessNotification(data.message));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(ContractMaintenance);
