import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import CircularProgress from '@material-ui/core/CircularProgress';
import SeparablePortionsGrid from './separablePortionsGrid';
import { 
	deleteSeparablePortion, 
	requestSeparablePortions, 
	clearSeparablePortions 
} from '../../reducers/separablePortions';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canView } from '../../selectors/canView';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class SeparablePortions extends Component {
    constructor(props) {
        super(props);

        this.state = {
			separablePortions: {}
        };

		this.onEditSeparablePortion = this.onEditSeparablePortion.bind(this);
		this.onViewSeparablePortion = this.onViewSeparablePortion.bind(this);
		this.onDeleteSeparablePortion = this.onDeleteSeparablePortion.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestSeparablePortions());
	}
	static clearData(dispatch) {
		dispatch(clearSeparablePortions());
	}
	componentDidMount() {
		SeparablePortions.fetchData(this.props.dispatch);
	}

	onEditSeparablePortion = (separablePortion) => {
		this.props.history.push(`/separable-portion/${separablePortion.separablePortionId}`);
	}

	onViewSeparablePortion = (separablePortion) => {
		this.props.history.push(`/separable-portion/${separablePortion.separablePortionId}`);
	}
	onDeleteSeparablePortion(separablePortion) {
		this.props.deleteSeparablePortion(separablePortion);
	}

	newSeparablePortion = () => {
		this.props.history.push('/separable-portion');
	}

	render() {
		return (
<PageLayout
	title="Separable Portions"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Separable Portion',
				onClick: this.newSeparablePortion
			}
		] : []
	}
	content={
		<ContentContainer>
			{this.props.isLoading &&
				<CircularProgress />
			}
			<SeparablePortionsGrid 
				separablePortions={this.props.separablePortions} 
				onEditSeparablePortion={this.onEditSeparablePortion} 
				onDeleteSeparablePortion={this.onDeleteSeparablePortion} 
				onViewSeparablePortion={this.onViewSeparablePortion} 
				canView={this.props.canView}
				canEdit={this.props.canEdit}
			/>
		</ContentContainer>
	}
/>
		);
	}
}

SeparablePortions.propTypes = {
	dispatch: PropTypes.func.isRequired,
	separablePortions: PropTypes.array,
	deleteSeparablePortion: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

SeparablePortions.defaultProps = {
	separablePortions: []
};

const mapStateToProps = (state) => ({
	separablePortions: state.separablePortions.separablePortions,
	canView: canView(state, 'ViewSeparablePortion'),
	canEdit: canEdit(state, 'EditSeparablePortions'),
	isLoading: state.separablePortions.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteSeparablePortion: (separablePortion) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Separable Portion?',
			onOk: () => {
				dispatch(deleteSeparablePortion(separablePortion));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(SeparablePortions);
