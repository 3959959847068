import find from 'lodash/find';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { globals } from '../globals';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	role: null, 
	roles: null
};

const CLEAR_ROLES = 'CLEAR_ROLES';
const CLEAR_ROLE = 'CLEAR_ROLE';
const FETCH_ROLE = 'FETCH_ROLE';
const DELETE_ROLE = 'DELETE_ROLE';
const REQUEST_ROLES = 'REQUEST_ROLES';
const RECEIVE_ROLES = 'RECEIVE_ROLES';
const REQUEST_ROLE = 'REQUEST_ROLE';
const RECEIVE_ROLE = 'RECEIVE_ROLE';
const CREATE_NEW_ROLE = 'CREATE_NEW_ROLE';
const SAVE_ROLE = 'SAVE_ROLE';
const RECEIVE_SAVE_ROLE_RESPONSE = 'RECEIVE_SAVE_ROLE_RESPONSE';
const RECEIVE_DELETE_ROLE_RESPONSE = 'RECEIVE_DELETE_ROLE_RESPONSE';

export const clearRoles = () => ({ type: CLEAR_ROLES });
export const clearRole = () => ({ type: CLEAR_ROLE });
export const fetchRole = (roleId) => ({ type: FETCH_ROLE, roleId });
export const receiveRoles = (data) => ({ type: RECEIVE_ROLES, payload: { data } });
export const receiveRole = (data) => ({ type: RECEIVE_ROLE, payload: { data } });
export const createNewRole = () => ({ type: CREATE_NEW_ROLE });
export const receiveSaveRoleResponse = (data) => ({ type: RECEIVE_SAVE_ROLE_RESPONSE, data });
export const receiveDeleteRoleResponse = (data) => ({ type: RECEIVE_DELETE_ROLE_RESPONSE, data });

export const requestRoles = () => (
	createFetchAction({
		objectName: 'Roles',
		url: '/api/roles',
		startAction: REQUEST_ROLES,
		success: (data) => receiveRoles(data)
	})
);

export const requestRole = (roleId) => (
	createFetchAction({
		objectName: 'Role',
		url: `/api/roles/${roleId}`,
		startAction: REQUEST_ROLE,
		success: (data) => receiveRole(data)
	})
);

export const saveRole = (role, onSuccess) => (
	createPostAction({
		url: '/api/roles',
		data: role,
		startAction: SAVE_ROLE,
		success: (data, dispatch) => {
			dispatch(receiveSaveRoleResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	})
);

export const deleteRole = (role) => (
	createPostAction({
		url: `/api/roles/${role.roleId}/delete`,
		startAction: DELETE_ROLE,
		success: (data) => receiveDeleteRoleResponse(data)
	})
);

export default (originalState = initialState, action) => {
	let state = originalState;
	if (!state._hydrated) {
		state = { ...initialState, ...state, _hydrated: true };
	}
	
	switch (action.type) {
		case CLEAR_ROLES:
			return {
				...state,
				roles: null
			};
		case CLEAR_ROLE:
			return {
				...state,
				role: null
			};
		case REQUEST_ROLES:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				roles: []
			};
		case RECEIVE_ROLES:
			return {
				...state,
				isLoading: false,
				roles: action.payload.data
			};
		case REQUEST_ROLE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				role: { ...cloneDeep(globals.templates.role) }
			};
		case RECEIVE_ROLE:
			return {
				...state,
				isLoading: false,
				role: action.payload.data
			};
		case DELETE_ROLE:
			return { 
				...state
			};
		case RECEIVE_DELETE_ROLE_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message
					}, 
				};
			}
			return {
				...state,
				isLoading: false,
				roles: state.roles.filter(c => c.roleId !== action.data.objectId) 
			};
		case FETCH_ROLE:
			return { 
				...state,
				role: find(state.roles, (c) => c.roleId === action.roleId)
			};
		case CREATE_NEW_ROLE:
			return {
				...state,
				role: { ...cloneDeep(globals.templates.role) }
			};	
		case SAVE_ROLE:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_ROLE_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				role: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		default:
			return state;
	}
};
