import React from 'react';
import PropTypes from 'prop-types';
import SelectField from './selectField';
import find from 'lodash/find';
import { useMountEffect } from '../../utils/utils';
import sortBy from 'lodash/sortBy';
import { get } from '../../utils/ajax';

const EmployeeSelect = (props) => {
	const [state, setState] = React.useState({
		employees: [],
		isLoading: false
	});
	
	useMountEffect(() => {
		if (props.employees && props.employees.length > 0) {
			setState(prevState => ({
				...prevState,
				employees: props.employees
			}));			
			return;
		}

		setState(prevState => ({
			...prevState,
			isLoading: true
		}));

		get({
			url:`/api/employees/all`,
			onSuccess: data => {
				setState(prevState => ({
					...prevState,
					employees: data,
					isLoading: false
				}));
			},
			onError: error => {
				setState(prevState => ({
					...prevState,
					employees: [],
					isLoading: false
				}));
				props.showError(error.message);
			}
		});
	});

	const employees = sortBy(state.employees || [], 'name');

	const employeeItems = [];
	let valueInList = false;
	for (let i = 0, ii = employees.length; i < ii; i++) {
		const e = employees[i];
		if (e.isActive) {
			if (e.employeeId === props.value) valueInList = true;
			employeeItems.push(
				{ value: e.employeeId, label: e.name }
			);
		}
	}

	if (!valueInList) {
		const e = find(employees, e2 => e2.employeeId === props.value);
		if (e) {
			employeeItems.push(
				{ value: e.employeeId, label: e.name, disabled: true }
			);
		}
	}


	return (
		<SelectField 
			value={props.value} 
			fullWidth={true}
			label={props.label}
			onChange={props.onChange}
			errorText={props.errorText}
			items={employeeItems}
			helpText={props.helpText}
			style={props.style}
			required={props.required}
		>
		</SelectField>
	);
};

EmployeeSelect.propTypes = {
	employees: PropTypes.array,
	value: PropTypes.any,
	label: PropTypes.node.isRequired,
	onChange: PropTypes.func.isRequired,
	errorText: PropTypes.node,
	helpText: PropTypes.node,
	style: PropTypes.object,
	required: PropTypes.bool

};

EmployeeSelect.defaultProps = {
	employees: [],
	value: null,
	errorText: null,
	helpText: '',
	style: {},
	required: false
};

export default EmployeeSelect;
