import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IncomingCorrespondencesHandoverContent from '../content/incomingCorrespondencesHandoverContent';
import addHandoverWrapper from '../handoverWrapper';

class IncomingCorrespondencesHandover extends React.Component {
	static getTitle() {
		return 'Incoming Correspondence';
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<IncomingCorrespondencesHandoverContent report={report} />
		);
	}
}

IncomingCorrespondencesHandover.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	IncomingCorrespondencesHandover, {
		title: 'Incoming Correspondence',
		className: 'incoming-correspondences-handover'
	})
);
