/* eslint react/no-array-index-key: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import { 
	BarChart, 
	Bar, 
	XAxis, 
	YAxis, 
	Tooltip, 
	Legend,
	ResponsiveContainer,
	Surface
} from 'recharts';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { toCurrency } from '../../utils/utils';

const CertifiedAndClaimByPeriod = ({ certifiedAndClaim }) => {
	const [state, setState] = React.useState({
		disabled: [],
		series: [
			{ label: 'Certified Amount', dataKey: 'certifiedAmount', colour: '#0E76BC' },
			{ label: 'Claim Amount', dataKey: 'claimAmount', colour: '#8DC63F' }
		]
	});

	const handleClick = dataKey => {
		if (includes(state.disabled, dataKey)) {
			setState({
				...state,
				disabled: state.disabled.filter(obj => obj !== dataKey)
			});
		} else {
			setState({ ...state, disabled: state.disabled.concat(dataKey) });
		}
	};

	const renderCustomisedLegend = ({ payload }) =>
		<div className="customized-legend" style={{ textAlign: 'center' }}>
			{payload.map((entry, index) => {
				const { dataKey, color, label } = entry;
				const active = includes(state.disabled, dataKey);
				const style = {
					marginRight: 10,
					color: active ? '#AAA' : '#000'
				};

				return (
					<span
						key={`legend-${index}`}
						role="presentation"
						className="legend-item"
						onClick={() => handleClick(dataKey)}
						style={style}
					>
						<Surface width={14} height={14} style={{ marginRight: '4px' }}>
							<path stroke="none" fill={color} d="M0,0h14v12h-14z"></path>
							{active && (
								<path stroke="none" fill="#fff" d="M0,0h14v12h-14z"></path>
							)}
						</Surface>
						<span>{label}</span>
					</span>
				);
			})}
		</div>
	;

	const certifiedAndClaimClosedPeriods = filter(certifiedAndClaim, p => p.status === 'Closed');

	return (
		<ResponsiveContainer>
		<BarChart data={certifiedAndClaimClosedPeriods}>
			<Tooltip 
				formatter={(v) => toCurrency(v)}
			/>
			{state.series
				.filter(s => !includes(state.disabled, s.dataKey))
				.map(s => (
					<Bar
						key={s.dataKey}
						name={s.label}
						dataKey={s.dataKey}
						fill={s.colour}
					/>
				))
			}
			<XAxis dataKey="name" />
			<YAxis width={120} tickFormatter={(v) => toCurrency(v)} />
			<Legend
				verticalAlign="bottom"
				align="center"
				height={20}
				payload={state.series.map(s => ({
					label: s.label,
					dataKey: s.dataKey,
					color: s.colour
				}))}
				content={renderCustomisedLegend}
			/>
		</BarChart>
		</ResponsiveContainer>
	);
}

CertifiedAndClaimByPeriod.propTypes = {
	setLoading: PropTypes.func.isRequired,
	contractId: PropTypes.number
};

CertifiedAndClaimByPeriod.defaultProps = {
	contractId: null
};

export default CertifiedAndClaimByPeriod;
