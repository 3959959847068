import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	initiateHandover, 
	requestHandover, 
	clearHandover, 
	requestHandoverLogs 
} from '../../reducers/handover';
import HandoverForm from './handoverForm';
import { showSuccessNotification } from '../../reducers/notifications';
import Button from '../widgets/button';
import PlayIcon from '@material-ui/icons/PlayArrow';
import CircularProgress from '@material-ui/core/CircularProgress';
import find from 'lodash/find';
import { runClientSideOnly } from '../../utils/utils';
import PageLayout from '../pageLayout';
import { showModal } from '../../reducers/modal';
import { get } from '../../utils/ajax';
import ContentContainer from '../contentContainer';
import FormSectionHeader from '../widgets/formSectionHeader';
import DisplayField from '../widgets/DisplayField';

class Handover extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			autoRefresh: true,
			estimation: null,
			estimationLoading: false
		};

		this.onInitiate = this.onInitiate.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.setupUpdateLoop = this.setupUpdateLoop.bind(this);
	}
	static fetchData(dispatch, props) {
		dispatch(requestHandover(props.match.params.contractId));
	}
	static clearData(dispatch) {
		dispatch(clearHandover());
	}
	setupUpdateLoop() {
		runClientSideOnly(() => {
			if (this._updateDataFnId) {
				clearInterval(this._updateDataFnId);
			}
			this._updateDataFnId = setInterval(() => {
				const handover = this.props.handover;
				if (handover) {
					this.props.dispatch(requestHandover(handover.contractId, (h) => {
						if (h && (h.status === 'Complete' || h.status === 'Failed')) {
							// Stop updates if handover complete
							if (this._updateDataFnId) {
								clearInterval(this._updateDataFnId);
								this._updateDataFnId = null;
								// Cancelling the last fetch may cancel the only fetch logs so make sure we do it at least once
								this.props.dispatch(requestHandoverLogs(h.handoverId));
							}
						}
					}));
					this.props.dispatch(requestHandoverLogs(handover.handoverId));
				}
			}, 7000);
		});
	}
	componentDidMount() {
		Handover.fetchData(this.props.dispatch, this.props);

		const contractId = this.props.match.params.contractId;
		this.setState(prevState => ({ ...prevState, estimationLoading: true }));
		get({
			url: `/api/handover/${contractId}/estimation`,
			onSuccess: data => this.setState(prevState => ({ ...prevState, estimation: data, estimationLoading: false })),
			onError: (error) => {
				this.setState(prevState => ({ ...prevState, estimationLoading: false }));
			}
		});

		this.setupUpdateLoop();
	}
	componentWillUnmount() {
		if (this._updateDataFnId) {
			clearInterval(this._updateDataFnId);
		}
	}

	onCancel = () => {
		this.props.history.push('/contracts');
	}
	onInitiate() {
		const includeAttachments = (cleanUpFirst) =>{
			this.props.dispatch(showModal('YES_NO', {
				title: 'Include all attachments in Handover',
				message: 'Do you wish to include all attachments in the Handover? Note that this could greatly increase the size of the handover.',
				onYes: () => {
					this.props.initiateHandover(this.props.match.params.contractId, true, cleanUpFirst);
					this.setupUpdateLoop();
				},
				onNo: () => {
					this.props.initiateHandover(this.props.match.params.contractId, false, cleanUpFirst);
					this.setupUpdateLoop();
				}
			}));
		};

		this.props.dispatch(showModal('YES_NO', {
			title: 'Clean up files first?',
			message: 'Do you wish to delete all previous handover files clear space for the new handover?',
			onYes: () => {
				includeAttachments(true);
			},
			onNo: () => {
				includeAttachments(false);
			}
		}));


	}
	
	render() {
		const handover = this.props.handover;
		const contract = find((this.props.contracts || []), (c) => c.contractId === parseInt(this.props.match.params.contractId, 10));

		return (
<PageLayout
	title={
		<span>
			Contract Handover
			{contract && <span> for {contract.contractName}</span>}
		</span>
	}
	content={
		<div>
			<HandoverForm 
				handover={handover} 
				logs={this.props.logs}
				isLogsLoading={this.props.isLogsLoading}
			/>

			<ContentContainer>
				<FormSectionHeader text="Space Required Estimation" />

				<CircularProgress style={{ display: this.state.estimationLoading ? undefined : 'none' }} />

				{this.state.estimation && 
					<div>
						<DisplayField label="Available Space (GB)" value={this.state.estimation.diskSpace ? this.state.estimation.diskSpace.toFixed(2) : ""} />
						<DisplayField label="Estimated Space Required (GB)" value={this.state.estimation.estimatedSpaceRequired ? this.state.estimation.estimatedSpaceRequired.toFixed(2) : ""} />
					</div>
				}
			</ContentContainer>

			<Button label="Initiate Handover" primary={true} icon={<PlayIcon />} onClick={this.onInitiate} />
			<Button label="Cancel" onClick={this.onCancel} style={{ marginLeft: '10px' }} />

			<CircularProgress style={{ display: this.props.isLoading ? undefined : 'none' }} />
		</div>
	}
/>
		);
	}
}

Handover.propTypes = {
	dispatch: PropTypes.func.isRequired,
	initiateHandover: PropTypes.func.isRequired,
	contracts: PropTypes.array.isRequired,
	handover: PropTypes.object,
	logs: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isLogsLoading: PropTypes.bool.isRequired,
	params: PropTypes.object
};

const mapStateToProps = (state) => ({
	contracts: state.context.contracts,
	handover: state.handover.handover,
	logs: state.handover.logs,
	isLoading: state.handover.isLoading,
	isLogsLoading: state.handover.isLogsLoading	
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	initiateHandover: (contractId, includeAttachments, cleanUpFirst) => {
		dispatch(initiateHandover(contractId, includeAttachments, cleanUpFirst, () => {
			dispatch(showSuccessNotification('Handover initiated'));
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(Handover);
