import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import { hideModal } from '../../reducers/modal';
import TextField from '../widgets/TextField';

class PromptModal extends React.Component {
	state = {
		value: ''
	};

	render = () => (
<Dialog
	title={this.props.title}
	actions={
		<React.Fragment>
			<Button
				label='Ok'
				primary={true} 	
				disabled={!this.state.value}
				onClick={() => { this.props.hideModal(); this.props.onOk(this.state.value); }}
			/>,
			<Button
				label='Cancel'
				onClick={() => { this.props.hideModal(); if (this.props.onCancel) this.props.onCancel(); }}
			/>		
		</React.Fragment>	
	}
	open={this.props.show}
	onClose={() => { this.props.hideModal(); if (this.props.onCancel) this.props.onCancel(); }}
>
	<span style={{ marginLeft: '10px' }}>{this.props.message}</span>

	<TextField
		value={this.state.value}
		fullWidth={true}
		onChange={(e, v) => { this.setState({ value: v }); }}
	/>
</Dialog>
	);
}

PromptModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	show: PropTypes.bool
};

PromptModal.defaultProps = {
	onCancel: null,
	show: true
};

export default connect(
	() => ({}),
	(dispatch) => ({
		hideModal: () => {
			dispatch(hideModal());
		}
	})
)(PromptModal);
