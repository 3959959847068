import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import PageLayout from '../pageLayout';
import NewTenant from './NewTenant';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';

const NewTenantController = ({ history, showSuccessNotification, showErrorNotification }) => {
	const [loading, setLoading] = React.useState(false);
	
	return (
		<PageLayout
			className='new-tenant'
			title="New Tenant"
			isLoading={loading}
			content={
				<NewTenant 
					saveResult={{}}
					onComplete={(values, onSuccess, onError) => {
						setLoading(true);
						post({
							url: `/api/tenants/new`,
							data: values,
							onSuccess: data => {
								setLoading(false);
				
								if (data.success) {
									showSuccessNotification(data.message);
									if (onSuccess) onSuccess();
									history.push("/tenants");
								} else {
									showErrorNotification(data.message);
									if (onError) onError();
								}
							},
							onError: error => {
								setLoading(false);
								showErrorNotification(error.message);
								if (onError) onError();
							}
						});
					}}
				/>
			}
		/>
	);
};


NewTenantController.propTypes = {
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(NewTenantController);
