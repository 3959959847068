import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';

class SeperablePortionsHandoverContent extends React.Component {
	render() {
		const data = this.props.report;

		const columns = [
			{ name: 'name', label: 'Name' },
			{ name: 'practicalCompletionDate', label: 'Practical Completion Date', dataType: 'date' },
			{ 
				name: 'status', 
				label: 'Status', 
				cellStyle: (args) => { 
					let statusBackColour = null;
					let statusForeColour = null;
					switch (args.data.status) {
						case 'Closed':
							statusBackColour = '#C6EFCE';
							statusForeColour = '#2C6153';
							break;
						default:
					}
					return { backgroundColor: statusBackColour, color: statusForeColour };
				},
				filterOperators: ['multi-select'],
				filterOptions: ['Open', 'Closed']
			},
			{ 
				name: 'totalValue', 
				label: 'Total Value', 
				dataType: 'number', 
				formatString: 'currency' 
			}
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

SeperablePortionsHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default SeperablePortionsHandoverContent;
