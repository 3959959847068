import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ContentAdd from '@material-ui/icons/Message';
import Dialog from './widgets/Dialog';
import Button from './widgets/button';
import FormContainer from './formContainer';
import FormFieldContainer from './formFieldContainer';
import TextField from './widgets/TextField';
import { showNotification, showSuccessNotification, showErrorNotification } from '../reducers/notifications';
import { fetch, addTask } from 'domain-task';
import LogRocket from 'logrocket';
import { getHelpPage, showHelpPage } from '../reducers/helpPages';
import { makeStyles } from '@material-ui/core/styles';
import { errorColour, successColour, infoColor } from '../variables';
import { shadeColour } from '../utils/utils';
import {ReactComponent as HelpIcon } from '../images/icons/question-mark.svg';
import { post } from '../utils/ajax';

const useStyles = makeStyles({
	speedDialNoHelp: {
		backgroundColor: infoColor,
		'&:hover': {
			backgroundColor: shadeColour(infoColor, -0.2),
		}
	},
	speedDialWithHelp: {
		backgroundColor: successColour,
		'&:hover': {
			backgroundColor: shadeColour(successColour, -0.2),
		}
	},
	supportButton: {
		backgroundColor: errorColour,
		color: '#fff'
	},
	supportButtonTooltip: {
		backgroundColor: errorColour,
		color: '#fff',
		whiteSpace: 'nowrap'
	},
	helpButton: {
		backgroundColor: successColour,
		color: '#fff'
	},
	helpButtonTooltip: {
		backgroundColor: successColour,
		color: '#fff',
		whiteSpace: 'nowrap'
	},
	addHelpButton: {
		backgroundColor: infoColor,
		color: '#fff'
	},
	addHelpButtonTooltip: {
		backgroundColor: infoColor,
		color: '#fff',
		whiteSpace: 'nowrap'
	}
});


const SupportButton = ({
	showSuccessNotification,
	showErrorNotification,
	showNotification,
	helpPages,
	showHelpPage,
	match,
	isGlobalAdmin
}) => {
	const classes = useStyles();
	const [state, setState] = React.useState({
		showSupportDialog: false,
		issueDescription: '',
		speedDialOpen: false,
		showNewHelpPageDialog: false,
		helpPageTargetUrl: '',
		helpPageUrl: '',
		loading: false
	})
	const helpPage = getHelpPage(helpPages);

	const showSupportDialog = () => {
		setState({
			...state,
			message: '',
			showSupportDialog: true
		});
	}

	const handleClose = () => {
		setState({
			...state,
			showSupportDialog: false
		});
	}

	const onSubmit = () => {
		const fetchTask = fetch('/api/support/submit-issue', {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'cache-control': 'no-store',
				'pragma': 'no-cache',
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				issueDescription: state.issueDescription,
				logRocketUrl: LogRocket.recordingURL,
			})
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					setState({
						...state,
						showSupportDialog: false
					});

					showNotification('The issue has been sent to support');
				} else {
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				}
			})
			.catch((error) => {
				console.log('request failed', error);
				showNotification(`Could not send the issue: ${error}`);
			});

		addTask(fetchTask);
	};
	const onAddHelpPage = (name, pageUrl, helpUrl) => {
		setState(prevState => ({ ...prevState, loading: true }));
			
		post({
			url: `/api/help-pages`,
			data: {
				name: name,
				description: '',
				pageUrl: pageUrl,
				helpUrl: helpUrl,
				isActive: true
			},
			onSuccess: data => {
				setState({ isLoading: false });
				if (data.success) {
					showSuccessNotification(data.message);
				} else {
					showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				showErrorNotification(error.message);
			}
		});
	};

	const closeSpeedDial = () => {
		setState(prevState => ({
			...prevState,
			speedDialOpen: false
		}));
	};
	const openSpeedDial = () => {
		setState(prevState => ({
			...prevState,
			speedDialOpen: true
		}));
	};

	const style = {
		right: '10px',
		bottom: '30px',
		position: 'fixed',
		zIndex: '1000'
	};
	const actions = [
		<Button
			key="cancel-button"
			label="Cancel"
			primary={true}
			onClick={handleClose}
		/>,
		<Button
			key="submit-button"
			label="Submit"
			primary={true}
			keyboardFocused={true}
			onClick={() => onSubmit()}
		/>,
	];

	return (
		<div className="support-button">
			<Dialog
				title="Log an Issue or Improvement"
				actions={actions}
				open={state.showSupportDialog}
				onClose={handleClose}
			>
				<FormContainer>
					<p>Enter a brief description of the issue then click submit</p>
					<FormFieldContainer>
						<TextField
							value={state.issueDescription}
							fullWidth={true}
							multiline={true}
							onChange={(e, v) => { setState({ ...state, issueDescription: v }); }}
							label="Description"
						/>
					</FormFieldContainer>
				</FormContainer>
			</Dialog>
			<SpeedDial
				ariaLabel="SpeedDial tooltip example"
				hidden={false}
				icon={<HelpIcon />}
				style={style}
				onClose={closeSpeedDial}
				onOpen={openSpeedDial}
				open={state.speedDialOpen}
				classes={{ fab: helpPage ? classes.speedDialWithHelp : classes.speedDialNoHelp }}
			>
				{helpPage &&
					<SpeedDialAction
						classes={{ fab: classes.helpButton, staticTooltipLabel: classes.helpButtonTooltip }}
						icon={<HelpIcon />}
						tooltipTitle="Show Help"
						tooltipOpen
						onClick={() => showHelpPage(helpPage)}
					/>
				}
				<SpeedDialAction
					classes={{ fab: classes.supportButton, staticTooltipLabel: classes.supportButtonTooltip }}
					icon={<ContentAdd />}
					tooltipTitle="Log Issue"
					tooltipOpen
					onClick={() => showSupportDialog()}
				/>
				{isGlobalAdmin && 
					<SpeedDialAction
						classes={{ fab: classes.addHelpButton, staticTooltipLabel: classes.addHelpButtonTooltip }}
						icon={<ContentAdd />}
						tooltipTitle="Add Help Page"
						tooltipOpen
						onClick={() => {
							let path = match.path;
							path = path.replace('/', '\\/'); // escape slashes
							path = path.replace(/:[^\/]*/g, '[^\\/]{1,}'); // replace :abcId? with anything except /
							path = path + '($|\\?[^\\/]{0,}$)'; // Allow any search string e.g. ?search=abc

							setState(prevState => ({
								...prevState,
								showNewHelpPageDialog: true,
								helpPageTargetUrl: path 
							}));
						}}
					/>
				}
			</SpeedDial>
			<Dialog
				title="Add New Help Page"
				actions={[
					<Button
						key="cancel-button"
						label="Cancel"
						primary={true}
						onClick={() => setState(prevState => ({ ...prevState, showNewHelpPageDialog: false }))}
					/>,
					<Button
						key="submit-button"
						label="Submit"
						primary={true}
						keyboardFocused={true}
						onClick={() => onAddHelpPage(state.name, state.helpPageTargetUrl, state.helpPageUrl)}
					/>
				]}
				open={state.showNewHelpPageDialog}
				onClose={() => setState(prevState => ({ ...prevState, showNewHelpPageDialog: false }))}
			>
				<FormContainer>
					<FormFieldContainer>
						<TextField
							value={state.name}
							fullWidth={true}
							onChange={(e, v) => { setState(prevState => ({ ...prevState, name: v })) }}
							label="Name"
						/>
					</FormFieldContainer>
					<FormFieldContainer>
						<TextField
							value={state.helpPageTargetUrl}
							fullWidth={true}
							onChange={(e, v) => { setState(prevState => ({ ...prevState, helpPageTargetUrl: v })) }}
							label="Page Url"
						/>
					</FormFieldContainer>
					<FormFieldContainer>
						<TextField
							value={state.helpPageUrl}
							fullWidth={true}
							multiline={true}
							onChange={(e, v) => { setState(prevState => ({ ...prevState, helpPageUrl: v })) }}
							label="Help Url"
						/>
					</FormFieldContainer>
				</FormContainer>
			</Dialog>
		</div>
	);
};

SupportButton.propTypes = {
	showNotification: PropTypes.func.isRequired,
	isGlobalAdmin: PropTypes.bool,
	showSuccessNotification: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

SupportButton.defaultProps = {
	isGlobalAdmin: false
};

const mapStateToProps = (state) => ({
	helpPages: state.helpPages.helpPages,
	isGlobalAdmin: state.context.userRole === "Global Administrator"
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => {
		dispatch(showNotification(true, message));
	},
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showHelpPage: (helpPage) => dispatch(showHelpPage(helpPage))
});

export default 
	withRouter(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(SupportButton)
	);