import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import SelectField from '../widgets/selectField';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import DateField from '../widgets/DateField';
import ContentContainer from '../contentContainer';
import SubContentContainer from '../subContentContainer';
import map from 'lodash/map';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import DataTableDatePicker from '../widgets/dataTableDatePicker';
import PersistedDataTable from '../widgets/persistedDataTable';
import Checkbox from '../widgets/Checkbox';
import  { addOrUpdate } from '../../utils/utils';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import some from 'lodash/some';
import Paper from '@material-ui/core/Paper';
import filter from 'lodash/filter';
import CorrespondencePicker from '../widgets/correspondencePicker';
import Button from '../widgets/button';
import FloatingActionButton from '@material-ui/core/Fab';
import IconButton from '../widgets/IconButton';
import ContentAdd from '@material-ui/icons/Add';
import ActionDelete from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmployeeSelect from '../widgets/employeeSelect';
import FileUpload from '@material-ui/icons/Publish';
import FileDownload from '@material-ui/icons/GetApp';

class ExtensionOfTimeForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			extensionOfTime: props.extensionOfTime,
			appliesTo: !props.extensionOfTime || !props.extensionOfTime.separablePortions || props.extensionOfTime.separablePortions.length === 0 ? 'EntireContract' : 'SeparablePortions'
		};

		this.selectSeparablePortion = this.selectSeparablePortion.bind(this);
		this.updateDaysApproved = this.updateDaysApproved.bind(this);
		this.updateRevisedPCDate = this.updateRevisedPCDate.bind(this);
		this.updatePracticalCompletionDate = this.updatePracticalCompletionDate.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			extensionOfTime: nextProps.extensionOfTime,
			appliesTo: !nextProps.extensionOfTime || !nextProps.extensionOfTime.separablePortions || nextProps.extensionOfTime.separablePortions.length === 0 ? 'EntireContract' : 'SeparablePortions'
		});
	}
	selectSeparablePortion(sp, selected) {
		const eot = this.state.extensionOfTime;
		if (sp._isContract) {
			eot.appliesToEntireContract = selected;
		} else if (selected) {
			eot.separablePortions = addOrUpdate(eot.separablePortions, sp, (s) => sp.separablePortionId === s.separablePortionId);
		} else {
			remove(eot.separablePortions, (s) => s.separablePortionId === sp.separablePortionId);
		}
		this.setState({
			extensionOfTime: eot
		});
	}
	updateDaysApproved(sp, daysApproved) {
		const eot = this.state.extensionOfTime;
		if (sp._isContract) {
			eot.daysApproved = daysApproved;

			this.setState({
				extensionOfTime: eot
			});
		} else {
			this.props.updateSeparablePortionField(sp, 'daysApproved', daysApproved);
		}
	}
	updateDaysClaimedByContractor = (sp, daysClaimedByContractor) => {
		const eot = this.state.extensionOfTime;
		if (sp._isContract) {
			eot.daysClaimedByContractor = daysClaimedByContractor;

			this.setState({
				extensionOfTime: eot
			});
		} else {
			this.props.updateSeparablePortionField(sp, 'daysClaimedByContractor', daysClaimedByContractor);
		}
	}
	updatePrevPCDate(sp, prevDate) {
		const eot = this.state.extensionOfTime;
		if (sp._isContract) {
			eot.previousPracticalCompletionDate = prevDate;

			this.setState({
				extensionOfTime: eot
			});
		} else {
			this.props.updateSeparablePortionField(sp, 'previousPracticalCompletionDate', prevDate);
		}
	}
	updateRevisedPCDate(sp, revisedDate) {
		const eot = this.state.extensionOfTime;
		if (sp._isContract) {
			eot.revisedPracticalCompletionDate = revisedDate;

			this.setState({
				extensionOfTime: eot
			});
		} else {
			this.props.updateSeparablePortionField(sp, 'revisedPracticalCompletionDate', revisedDate);
		}
	}
	updatePracticalCompletionDate(sp, pcDate) {
		const eot = this.state.extensionOfTime;
		if (sp._isContract) {
			eot.practicalCompletionDate = pcDate;

			this.setState({
				extensionOfTime: eot
			});
		} else {
			this.props.updateSeparablePortionField(sp, 'practicalCompletionDate', pcDate);
		}
	}

	selectAppliesTo(appliesTo) {
		const eot = this.state.extensionOfTime;
		if (appliesTo === 'SeparablePortions') eot.separablePortions = [];
		this.setState({
			appliesTo: appliesTo,
			extensionOfTime: eot
		});
	}

	isAllSelected = () => {
		const eot = this.state.extensionOfTime;
		const sps = this.props.separablePortions || [];

		for (let i = 0, ii = sps.length; i < ii; i++) {
			const sp = sps[i];
			if (sp._isContract) {
				if (!eot.appliesToEntireContract) return false;
			} else {
				const selected = some(eot.separablePortions, sp2 => sp2.separablePortionId === sp.separablePortionId);
				if (!selected) return false;
			}
		}
		return true;
	}
	toggleAll = () => {
		const nextValue = !this.isAllSelected();

		const eot = this.state.extensionOfTime;
		const sps = this.props.separablePortions || [];
		forEach(sps, (sp) => {
			if (sp._isContract) {
				eot.appliesToEntireContract = nextValue;
			} else if (nextValue) {
				eot.separablePortions = addOrUpdate(eot.separablePortions, sp, (s) => sp.separablePortionId === s.separablePortionId);
			} else {
				remove(eot.separablePortions, (s) => s.separablePortionId === sp.separablePortionId);
			}
		});
		this.setState({
			extensionOfTime: eot
		});
	}

	addLinkedCorrespondence = () => {
		this.setState({ showCorrespondencePicker: true });
	}

	onSelectCorrespondence = (selectedCorrespondence) => {
		this.props.onSelectCorrespondence(selectedCorrespondence);
		this.setState({
			showCorrespondencePicker: false
		});
	}
	onCancelCorrespondencePicker = () => {
		this.setState({ showCorrespondencePicker: false });
	}

	selectFiles = (event) => {
		this.props.addFiles(event.target.files);
	}

	render() {
		const extensionOfTime = this.state.extensionOfTime || {};
		const separablePortions = this.props.separablePortions ? this.props.separablePortions : [];
		const linkedCorrespondence = extensionOfTime.linkedCorrespondence || [];
		forEach(separablePortions, (s) => {
			const eotSP = find(extensionOfTime.separablePortions, (s2) => s2.separablePortionId === s.separablePortionId);
			s._isSelected = !!eotSP;
			if (eotSP) {
				s.daysApproved = eotSP.daysApproved;
				s.daysClaimedByContractor = eotSP.daysClaimedByContractor;
				s.revisedPracticalCompletionDate = eotSP.revisedPracticalCompletionDate;
				s.previousPracticalCompletionDate = eotSP.previousPracticalCompletionDate;
				s.practicalCompletionDate = eotSP.practicalCompletionDate || s.practicalCompletionDate;
			}
		});
		if (separablePortions.length > 0 && separablePortions[0]._isContract === true) separablePortions.splice(0, 1);
		separablePortions.splice(0, 0, {
			_isContract: true,
			_isSelected: extensionOfTime.appliesToEntireContract,
			name: 'Contract',
			daysApproved: extensionOfTime.daysApproved,
			daysClaimedByContractor: extensionOfTime.daysClaimedByContractor,
			revisedPracticalCompletionDate: extensionOfTime.revisedPracticalCompletionDate,
			previousPracticalCompletionDate: extensionOfTime.previousPracticalCompletionDate,
			practicalCompletionDate: null
		});
		const files = extensionOfTime.files;
		const styles = {
			button: {
				marginTop: 12
			},
			fileInput: {
				cursor: 'pointer',
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				width: '100%',
				opacity: 0
			}
		};
		const spColumns = [
			{
				name: '_isSelected',
				excludeFromFilter: true,
				value: (args) => args.data._isSelected || false,
				dataType: 'bool',
				sortable: false,
				headerRenderer: () =>
					<Checkbox
						checked={this.isAllSelected()}
						onCheck={() => { this.toggleAll(); } }
					/>,
				cellRenderer: (args) => 
					<Checkbox
						checked={args.data._isSelected}
						onCheck={(e, v) => { this.selectSeparablePortion(args.data, v); } }
					/> 
			},
			{ name: 'name', label: 'Name' },
			{ 
				name: 'daysApproved', 
				label: 'Days Approved',
				headerStyle: {
					whiteSpace: 'normal'
				},
				cellRenderer: (args) => {
					if (args.data._isSelected) {
						return (
							<div>
								<DataTableNumericEditor
									type="decimal"
									value={args.data.daysApproved} 
									onChange={(v) => { this.updateDaysApproved(args.data, v); }}
									style={{ width: 'auto' }}
								/>
							</div>
						);
					} else {
						return args.data.daysApproved;
					}
				}
			},
			{ 
				name: 'daysClaimedByContractor', 
				label: 'Days Claimed By Contractor',
				headerStyle: {
					whiteSpace: 'normal'
				},
				cellRenderer: (args) => {
					if (args.data._isSelected) {
						return (
							<div>
								<DataTableNumericEditor
									type="decimal"
									value={args.data.daysClaimedByContractor} 
									onChange={(v) => { this.updateDaysClaimedByContractor(args.data, v); }}
									style={{ width: 'auto' }}
								/>
							</div>
						);
					} else {
						return args.data.daysClaimedByContractor;
					}
				}
			},
			{ 
				name: 'previousPracticalCompletionDate', 
				label: 'Previous Date for Practical Completion',
				cellRenderer: (args) => {
					if (args.data._isSelected) {
						return (
							<div>
								<DataTableDatePicker
									formatDate={(d) => d.toLocaleDateString('en-AU') }
									container="inline" 
									value={args.data.previousPracticalCompletionDate} 
									onChange={(d) => { this.updatePrevPCDate(args.data, d); }}
									fullWidth={true}
								/>
							</div>
						);
					} else {
						return args.data.previousPracticalCompletionDate ? args.data.previousPracticalCompletionDate.toLocaleDateString('en-AU') || '' : '';
					}
				}
			},
			{ 
				name: 'revisedPracticalCompletionDate', 
				label: 'Revised Date for Practical Completion',
				cellRenderer: (args) => {
					if (args.data._isSelected) {
						return (
							<div>
								<DataTableDatePicker 
									formatDate={(d) => d.toLocaleDateString('en-AU') }
									container="inline" 
									value={args.data.revisedPracticalCompletionDate} 
									defaultValue={args.data.previousPracticalCompletionDate}
									onChange={(d) => { this.updateRevisedPCDate(args.data, d); }}
									fullWidth={true}
								/>
							</div>
						);
					} else {
						return args.data.revisedPracticalCompletionDate ? args.data.revisedPracticalCompletionDate.toLocaleDateString('en-AU') || '' : '';
					}
				}
			}
		];

		const linkedCorrespondenceColumns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.viewLinkedCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<VisibilityIcon />
						</IconButton>
						{this.props.canEdit && 
							<IconButton onClick={ () => { this.props.deleteLinkedCorrespondence(args.data); } }>
								<ActionDelete />
							</IconButton>
						}
					</div>				
			},
			{ 
				name: 'correspondenceType', 
				label: 'Type'
			},
			{ name: 'correspondenceNumber', label: 'Correspondence Number' },
			{ name: 'correspondenceDate', label: 'Date', dataType: 'date' }
		];

		return (
<div>
	<CorrespondencePicker 
		show={this.state.showCorrespondencePicker} 
		types={['Incoming', 'Outgoing']}
		selectedCorrespondence={{
			incomingCorrespondence: map(filter(extensionOfTime.linkedCorrespondence, (c) => c.correspondenceType === 'Incoming'), (c) => ({ incomingCorrespondenceId: c.correspondenceId })),
			outgoingCorrespondence: map(filter(extensionOfTime.linkedCorrespondence, (c) => c.correspondenceType === 'Outgoing'), (c) => ({ outgoingCorrespondenceId: c.correspondenceId }))
		}}
		onOk={this.onSelectCorrespondence} 
		cancel={this.onCancelCorrespondencePicker} 
	/>

	<ContentContainer>
		<FormFieldContainer>
			<TextField
				value={extensionOfTime.extensionOfTimeNumber}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('extensionOfTimeNumber', v); }}
				label="EOT Number"
				errorText={getFieldErrorText(this.props.saveResult, 'ExtensionOfTimeNumber')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={extensionOfTime.description}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('description', v); }}
				label="EOT Description"
				errorText={getFieldErrorText(this.props.saveResult, 'Description')}
			/>
		</FormFieldContainer>

		<FormRow>
			<FormFieldContainer classes={['s12', 'm6']}>
				<DateField
					value={extensionOfTime.date} 
					lable="Date" 
					onChange={(d) => { this.props.updateField('date', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={['s12', 'm6']}>
				<SelectField 
					value={extensionOfTime.status} 
					fullWidth={true}
					label="EOT Status" 
					onChange={(v) => { this.props.updateField('status', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'Status')}
					items={[
						{ value: 'Open', label: 'Open' },
						{ value: 'Approved', label: 'Approved' },
						{ value: 'Rejected', label: 'Rejected' }
					]}
				>
				</SelectField>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={['s12', 'm6']}>
				<DateField
					value={extensionOfTime.approvedDate} 
					label="Approved Date" 
					onChange={(d) => { this.props.updateField('approvedDate', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={['s12', 'm6']}>
				<EmployeeSelect 
					employees={this.props.employees}
					value={extensionOfTime.approvedBy} 
					label="Approved By" 
					onChange={(v) => { this.props.updateField('approvedBy', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'Approved By')}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer>
				<TextField
					value={extensionOfTime.comments}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('comments', v); }}
					label="Comments"
					errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
				/>
			</FormFieldContainer>
		</FormRow>
	</ContentContainer>

	<Paper style={{ padding: '10px', marginBottom: '10px' }}>
		<PersistedDataTable 
			id="eotSeparablePoritions"
			data={separablePortions || []} 
			columns={spColumns}
		/>
	</Paper>

	<SubContentContainer header="Correspondence" style={{ marginTop: '10px' }}>
		<PersistedDataTable 
			id="linkedCorrespondence"
			data={linkedCorrespondence} 
			columns={linkedCorrespondenceColumns}
		/>

		{this.props.canEdit &&
			<div className="row">
				<FloatingActionButton onClick={this.addLinkedCorrespondence} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
					<ContentAdd />
				</FloatingActionButton>
			</div>
		}
	</SubContentContainer>

	<SubContentContainer 
		header="Files"
		onDragOver={(e) => {
			e.dataTransfer.dropEffect = 'copy';
			e.stopPropagation();
			e.preventDefault();
		}}
		onDrop={(e) => {
			e.stopPropagation();
			e.preventDefault();
			// Allow files to be added by dropping anywhere in container
			this.props.addFiles('ExtensionOfTime', e.dataTransfer.files);
		}}
		style={{ marginTop: '10px' }}	
	>
		<div className="table-container">
			<table className="full-bordered" style={{ width: '100%' }}>
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
					</tr>
				</thead>
				<tbody>
					{
						map(files, (f) =>
							<tr key={f.userFileId}>
								<td style={{ whiteSpace: 'nowrap' }}>
									{!f._isNew && 
										<IconButton onClick={ () => { this.props.downloadFile(f); } } style={{ padding: '2px' }}>
											<FileDownload />
										</IconButton>
									}
									{this.props.canEdit &&
										<IconButton onClick={ () => { this.props.deleteFile(f); } } style={{ padding: '2px' }}>
											<ActionDelete />
										</IconButton>
									}
								</td>	
								<td>{f.name}</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</div>

		{this.props.canEdit &&
			<div className="row">
				<Button
					label="Select a file to upload"
					style={styles.button}
					component="label"
					startIcon={<FileUpload />}
				>
					<input type="file" style={styles.fileInput} multiple="multiple" onChange={this.selectFiles} />
				</Button>
			</div>
		}

	</SubContentContainer>
	
</div>
		);
	}
}

ExtensionOfTimeForm.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	updateField: PropTypes.func.isRequired,
	updateSeparablePortionField: PropTypes.func.isRequired,
	extensionOfTime: PropTypes.object.isRequired,
	employees: PropTypes.array.isRequired,
	separablePortions: PropTypes.array.isRequired,
	saveResult: PropTypes.object.isRequired,
	onSelectCorrespondence: PropTypes.func.isRequired,
	deleteLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondence: PropTypes.func.isRequired
};

export default ExtensionOfTimeForm;
