import React from 'react';
import PropTypes from 'prop-types';
import { getFieldErrorText } from '../../utils/field-validation';
import TextField from '../widgets/TextField';
import DateField from '../widgets/DateField';
import Checkbox from '../widgets/Checkbox';
import SelectField from '../widgets/selectField';
import FormRow from '../formRow';
import FormFieldContainer from '../formFieldContainer';
import map from 'lodash/map';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SubContentContainer from '../subContentContainer';
import FloatingActionButton from '@material-ui/core/Fab';
import ContentAdd from '@material-ui/icons/Add';
import VariationItemDialog from './variationItemDialog';
import ContentContainer from '../contentContainer';
import FormSectionHeader from '../widgets/formSectionHeader';
import PersistedDataTable from '../widgets/persistedDataTable';
import Avatar from '@material-ui/core/Avatar';
import CorrespondencePicker from '../widgets/correspondencePicker';
import filter from 'lodash/filter';
import round from 'lodash/round';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import { units } from '../../variables';
import EmployeeSelect from '../widgets/employeeSelect';
import { toCurrency } from '../../utils/utils';
import NumericField from '../widgets/numericField';

class VariationForm extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			showVariationItemDialog: false,
			showCorrespondencePicker: false,
			scheduleOfRates: null,
			itemErrorText: ''
		};
    
		this.onItemChange = this.onItemChange.bind(this);
		this.addVariationItem = this.addVariationItem.bind(this);
		this.addVariationCorrespondence = this.addVariationCorrespondence.bind(this);
		this.onUpdateItem = this.onUpdateItem.bind(this);
		this.onAddVariationItems = this.onAddVariationItems.bind(this);
		this.onSelectCorrespondence = this.onSelectCorrespondence.bind(this);
		this.onCancelVariationDialog = this.onCancelVariationDialog.bind(this);
		this.onCancelCorrespondencePicker = this.onCancelCorrespondencePicker.bind(this);
		this.selectContractorFiles = this.selectContractorFiles.bind(this);
		this.selectAdministratorFiles = this.selectAdministratorFiles.bind(this);
	}

	selectContractorFiles(event) {
		this.props.addFiles('Variation.Contractor', event.target.files);
	}
	selectAdministratorFiles(event) {
		this.props.addFiles('Variation.Administrator', event.target.files);
	}

	addVariationItem() {
		this.setState({ showVariationItemDialog: true });
	}
	addVariationCorrespondence() {
		this.setState({ showCorrespondencePicker: true });
	}

	onSelectCorrespondence(selectedCorrespondence) {
		this.props.onSelectCorrespondence(selectedCorrespondence);
		this.setState({
			showCorrespondencePicker: false
		});
	}

	onItemChange(scheduleOfRates) {
		this.setState(
			{
				scheduleOfRates: scheduleOfRates,
				itemErrorText: ''
			}
		);
	// 	this.props.updateField('scheduleOfRatesId', scheduleOfRates.scheduleOfRatesId);
	// 	this.props.updateField('scheduleOfRatesItemId', scheduleOfRates.scheduleOfRatesItemId);
	// 	this.props.updateField('itemNo', scheduleOfRates.itemNo);
	// 	this.props.updateField('itemDescription', scheduleOfRates.description);
	// 	this.props.updateField('unit', scheduleOfRates.unit);
	}

	onUpdateItem() {
		this.setState({
			scheduleOfRates: null,
			itemErrorText: ''
		});
	}

	onAddVariationItems(items) {
		for (let i = 0, ii = items.length; i < ii; i++) {
			this.props.updateVariationItem(items[i]);
		}
		this.setState({ 
			showVariationItemDialog: false 
		});
	}

	onCancelVariationDialog() {
		this.setState({ showVariationItemDialog: false });
	}
	onCancelCorrespondencePicker() {
		this.setState({ showCorrespondencePicker: false });
	}

	getScheduleItemByVariationItem(lotItem) {
		for (let i = 0, ii = this.props.scheduleOfRates.length; i < ii; i++) {
			for (let j = 0, jj = this.props.scheduleOfRates[i].items.length; j < jj; j++) {
				const item = this.props.scheduleOfRates[i].items[j];
				if (item.scheduleOfRatesItemId === lotItem.scheduleOfRatesItemId) return item;
			}
		}

		return null;
	}

	render() {
		const variation = this.props.variation || {};
		const items = variation.items || [];
		const correspondence = variation.correspondence || [];
		
		const columns = [
			{ name: 'scheduleName', label: 'Schedule' },
			{ name: 'itemNo', label: 'Item' },
			{ name: 'itemDescription', label: 'Description', headerStyle: { minWidth: '200px' } },
			{ 
				name: 'unit', 
				label: 'Unit',
				filterOperators: ['=', 'Contains', 'multi-select'],
				filterOptions: units 
			},
			{ 
				name: 'qty', 
				label: 'Qty',
				dataType: 'number',
				cellRenderer: (args) => {
					const scheduleItem = this.getScheduleItemByVariationItem(args.data);

					if (variation.isLotBased) {
						return scheduleItem ? round(scheduleItem.remainingQty, 2) : '';
					} else {
						return (
							<div>
								<DataTableNumericEditor
									type="decimal"
									value={args.data.qty} 
									onChange={(v) => { this.props.updateVariationItem({ ...args.data, qty: v }); }}
									style={{ width: 'auto' }}
								/>
								{scheduleItem &&
									<div style={{ whiteSpace: 'nowrap', fontSize: 'small', color: '#bbb' }}> ({round(scheduleItem.remainingQty, 2)} remaining)</div>
								}
							</div>
						);
					}
				}
			},
			{ 
				name: 'rate', 
				label: 'Rate',
				dataType: 'currency',
				cellRenderer: (args) => {
					const scheduleItem = this.getScheduleItemByVariationItem(args.data);

					if (variation.isLotBased) {
						return scheduleItem ? toCurrency(scheduleItem.rate) : '';
					} else {
						return (
							<div>
								<DataTableNumericEditor
									type="currency"
									nullable={true}
									value={args.data.rate} 
									onChange={(v) => { this.props.updateVariationItem({ ...args.data, rate: v }); }}
									style={{ width: 'auto' }}
								/>
							</div>
						);
					}
				}
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '200px' } }
		];

		if (this.props.canEdit) {
			columns.unshift({
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.deleteVariationItem(args.data); } }>
							<ActionDelete />
						</IconButton>
					</div>
			});
		}

		const correspondenceColumns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.viewLinkedCorrespondence(args.data); } } style={{ padding: '2px' }}>
							<VisibilityIcon />
						</IconButton>
						{this.props.canEdit && 
							<IconButton onClick={ () => { this.props.deleteLinkedCorrespondence(args.data); } }>
								<ActionDelete />
							</IconButton>
						}
					</div>				
			},
			{ 
				name: 'correspondenceType', 
				label: 'Type'
			},
			{ name: 'correspondenceNumber', label: 'Correspondence Number' }
		];

		let allowedStatues = [];
		switch (variation.prevStatus) {
			case 'Approved':
				allowedStatues = [
					{ value: 'Approved', label: 'Approved' }
				];
				break;
			default:
				allowedStatues = [
					{ value: 'Open', label: 'Open' },
					{ value: 'Processing', label: 'Processing' },
					{ value: 'Approved', label: 'Approved' },
					{ value: 'Rejected', label: 'Rejected' },
					{ value: 'Withdrawn', label: 'Withdrawn' },
				];
		}

		let isLotBasedEnabled;
		if (variation._isNew) {
			isLotBasedEnabled = true;
		} else {
			switch (variation.prevStatus) {
				case 'Approved':
					isLotBasedEnabled = false;
					break;
				default:
					isLotBasedEnabled = true;
			}
		}

		return (
<div>

	<VariationItemDialog 
		show={this.state.showVariationItemDialog} 
		addVariationItems={this.onAddVariationItems} 
		cancel={this.onCancelVariationDialog} 
		scheduleOfRates={this.props.scheduleOfRates}
		variation={this.props.variation}
	/>

	<CorrespondencePicker 
		show={this.state.showCorrespondencePicker} 
		types={['Incoming', 'Outgoing']}
		selectedCorrespondence={{
			incomingCorrespondence: map(filter(variation.correspondence, (c) => c.correspondenceType === 'Incoming'), (c) => ({ incomingCorrespondenceId: c.correspondenceId })), 
			outgoingCorrespondence: map(filter(variation.correspondence, (c) => c.correspondenceType === 'Outgoing'), (c) => ({ outgoingCorrespondenceId: c.correspondenceId }))
		}}
		onOk={this.onSelectCorrespondence} 
		cancel={this.onCancelCorrespondencePicker} 
	/>

	<ContentContainer>
		<FormFieldContainer classes={{ s12: true, m6: true }}>
			<TextField
				value={variation.variationNo}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('variationNo', v); }}
				label="Variation No."
				errorText={getFieldErrorText(this.props.saveResult, 'VariationNo')}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m6: true }}>
			<TextField
				value={variation.contractorVariationNo}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('contractorVariationNo', v); }}
				label="Contractor Variation No."
				errorText={getFieldErrorText(this.props.saveResult, 'ContractorVariationNo')}
			/>
		</FormFieldContainer>

		<FormFieldContainer>
			<TextField
				value={variation.description}
				fullWidth={true}
				onChange={(e, v) => { this.props.updateField('description', v); }}
				label="Variation Description"
				errorText={getFieldErrorText(this.props.saveResult, 'Description')}
			/>
		</FormFieldContainer>

		<FormSectionHeader text="Status" />
		
		<FormFieldContainer classes={{ s12: true, m6: true }}>
			<SelectField 
				value={variation.status} 
				fullWidth={true}
				label="Status" 
				onChange={(v) => { this.props.updateField('status', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'Status')}
				items={allowedStatues}
			>
			</SelectField>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m6: true }}>
			<SelectField 
				value={variation.category} 
				fullWidth={true}
				label="Category" 
				onChange={(v) => { this.props.updateField('category', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'category')}
				items={[
					{ value: 'Principal', label: 'Principal' },
					{ value: 'Administrator', label: 'Administrator' },
					{ value: 'Design related', label: 'Design related' },
					{ value: 'PUP', label: 'PUP' },
					{ value: 'Site related', label: 'Site related' },
					{ value: 'Other', label: 'Other' },
				]}
			>
			</SelectField>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m6: true, hasToggle: true }}>
			<Checkbox
				label="Is Lot Based"
				labelPosition="right"
				checked={variation.isLotBased}
				onCheck={(e, v) => { this.props.updateField('isLotBased', v); }}
				disabled={!isLotBasedEnabled}
			/>
		</FormFieldContainer>

		<FormSectionHeader text="Details" />

		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<SelectField 
					value={variation.identifiedBy} 
					fullWidth={true}
					label="Identified By" 
					onChange={(v) => { this.props.updateField('identifiedBy', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'IdentifiedBy')}
					items={[
						{ value: 'Contractor', label: 'Contractor' },
						{ value: 'Administrator', label: 'Administrator' },
						{ value: 'Principal', label: 'Principal' }
					]}
				>
				</SelectField>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<TextField
					value={variation.orderedBy}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('orderedBy', v); }}
					label="Ordered By"
					errorText={getFieldErrorText(this.props.saveResult, 'OrderedBy')}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<EmployeeSelect 
					employees={this.props.employees}
					value={variation.to} 
					label="To" 
					onChange={(v) => { this.props.updateField('to', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'To')}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<TextField
					value={variation.from}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('from', v); }}
					label="From"
					errorText={getFieldErrorText(this.props.saveResult, 'From')}
				/>
			</FormFieldContainer>
		</FormRow>
			
		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<DateField
					value={variation.dateReceived} 
					label="Date Received" 
					onChange={(d) => { this.props.updateField('dateReceived', d); } }
					fullWidth={true}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<DateField
					value={variation.orderDate} 
					label="Order Date" 
					onChange={(d) => { this.props.updateField('orderDate', d); }}
					fullWidth={true}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormRow>
			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<NumericField
					type="currency"
					fullWidth={true}
					label="Estimated Value" 
					nullable={true}
					value={variation.estimatedValue} 
					onChange={(v) => { this.props.updateField('estimatedValue', v); }}
					errorText={getFieldErrorText(this.props.saveResult, 'EstimatedValue')}
				/>
			</FormFieldContainer>

			<FormFieldContainer classes={{ s12: true, m6: true }}>
				<TextField
					value={variation.reasonForVariation}
					fullWidth={true}
					onChange={(e, v) => { this.props.updateField('reasonForVariation', v); }}
					label="Reason For Variation"
					errorText={getFieldErrorText(this.props.saveResult, 'ReasonForVariation')}
				/>
			</FormFieldContainer>
		</FormRow>

		<FormFieldContainer>
			<TextField
				value={variation.comments}
				fullWidth={true}
				multiline={true}
				onChange={(e, v) => { this.props.updateField('comments', v); }}
				label="Comments"
				errorText={getFieldErrorText(this.props.saveResult, 'Comments')}
			/>
		</FormFieldContainer>

		<FormSectionHeader text="Notifications" />

		<FormFieldContainer classes={{ hasToggle: true }}>
			<Checkbox
				label="Action Required"
				labelPosition="right"
				checked={variation.actionRequired}
				onCheck={(e, v) => { this.props.updateField('actionRequired', v); }}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m4: true }}>
			<DateField 
				value={variation.actionDueDate} 
				label="Action Due Date" 
				onChange={(d) => { this.props.updateField('actionDueDate', d); }}
				fullWidth={true}
			/>
		</FormFieldContainer>

		<FormFieldContainer classes={{ s12: true, m8: true }}>
			<SelectField 
				value={variation.notificationUser} 
				fullWidth={true}
				label="Notification User" 
				onChange={(v) => { this.props.updateField('notificationUser', v); }}
				errorText={getFieldErrorText(this.props.saveResult, 'NotificationUser')}
				nullable={true}
				items={
						map(this.props.userList, (u) => 
							({ value: u.userId, label: u.userName, leftIcon: <Avatar src={`/images/avatars/${u.avatar}.png`} /> })
						)
					}
			>
			</SelectField>
		</FormFieldContainer>

	</ContentContainer>

	<SubContentContainer header="Items">
		<PersistedDataTable 
			id="variationItems"
			data={items} 
			columns={columns}
		/>

		{this.props.canEdit &&
			<div className="row">
				<FloatingActionButton onClick={this.addVariationItem} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
					<ContentAdd />
				</FloatingActionButton>
			</div>
		}
	</SubContentContainer>

	<SubContentContainer header="Correspondence" style={{ marginTop: '10px' }}>
		<PersistedDataTable 
			id="variationCorrespondence"
			data={correspondence} 
			columns={correspondenceColumns}
		/>

		{this.props.canEdit &&
			<div className="row">
				<FloatingActionButton onClick={this.addVariationCorrespondence} size="small" style={{ float: 'right', marginTop: '20px' }}  className="clearfix">
					<ContentAdd />
				</FloatingActionButton>
			</div>
		}
	</SubContentContainer>
</div>
		);
	}
}


VariationForm.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	scheduleOfRates: PropTypes.array.isRequired,
	variation: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	updateVariationItem: PropTypes.func.isRequired,
	deleteVariationItem: PropTypes.func.isRequired,
	addFiles: PropTypes.func.isRequired,
	onSelectCorrespondence: PropTypes.func.isRequired,
	deleteLinkedCorrespondence: PropTypes.func.isRequired,
	viewLinkedCorrespondence: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	userList: PropTypes.array.isRequired,
	employees: PropTypes.array.isRequired
};

export default VariationForm;
