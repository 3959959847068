import React from 'react';
import DateField from './DateField';

const DataTableDatePicker = (props) => {
	const mergedProps = { ...props };
	if (!mergedProps.className) {
		mergedProps.className = '';
	}
	mergedProps.className = `data-table-date-picker ${mergedProps.className}`; 
	mergedProps.disableUnderline = true;
	mergedProps.style = {
		background: null
	};
	mergedProps.datePickerStyle = {
		marginTop: 0
	};

	return (
		<DateField {...mergedProps} />
	);
};

DataTableDatePicker.propTypes = {
};

export default DataTableDatePicker;
