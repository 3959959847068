import React from 'react';

class DeductionsByLot extends React.Component {

  render() {
    return (
<div>
    <section className="content-header">
        <h1>
            Deductions by Lot - TODO
        </h1>
    </section>

    <section className="content">

    </section>
</div>
    );
  }
}

export default DeductionsByLot;
