import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';
import { 
	formatDate 
} from '../../utils/utils';

class EventsGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.events || this.props.events.length !== nextProps.events.length;
	}

	render() {
		const events = this.props.events || [];

		const columns = [
			{ name: 'name', label: 'Name' },
			{ name: 'description', label: 'Description' },
			{ 
				name: 'eventDate', 
				label: 'Event Date', 
				dataType: 'date', 
				formatter: args => formatDate(args.value, 'dd/MM/yyyy hh:mm a') 
			},
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) =>
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditEvent(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteEvent(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else if (this.props.canView) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) =>
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewEvent(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="events"
				data={events} 
				columns={columns}
			/>
		);
	}
}

EventsGrid.propTypes = {
	events: PropTypes.array,
	canView: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onEditEvent: PropTypes.func.isRequired,
	onViewEvent: PropTypes.func.isRequired,
	onDeleteEvent: PropTypes.func.isRequired
};

EventsGrid.defaultProps = {
	events: []
};

export default EventsGrid;
