import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';

class ReportMemoField extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			value: props.value
		};

		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value
		});
	}
	onChange(e, v) {
		if (this.props.editable === false) return;
		this.setState({
			value: v
		});
	}
	onBlur() {
		if (this.props.editable === false) return;
		if (this.props.onChange) this.props.onChange(this.state.value);
	}
	render() {
		const val = this.state.value || '';

		let classes = 'report-field report-memo-field';
		if (this.props.editable !== false) {
			classes += ' editable';
		}

		const style = {
			...this.props.style
		};
		const inputStyle = {
			fontSize: '12px', 
			verticalAlign: 'top',
			...this.props.textareaStyle
		};

		const mergedProps = { ...this.props };
		delete mergedProps.editable;

		delete mergedProps.textareaStyle;

		mergedProps.InputProps = {
			style: {
				padding: '2px'
			}
		};

		return (
			<TextField 
				{...mergedProps}
				className={classes}
				onChange={this.onChange} 
				onBlur={this.onBlur} 
				underlineShow={false} 
				multiline={true} 
				rows={2} 
				style={style} 
				inputStyle={inputStyle}
				value={val} 
			/>
		);
	}
}

ReportMemoField.propTypes = {
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func,
	editable: PropTypes.bool,
	style: PropTypes.object,
	textareaStyle: PropTypes.object
};

ReportMemoField.defaultProps = {
	onChange: () => {},
	style: {},
	textareaStyle: {},
	editable: false
};

export default ReportMemoField;
