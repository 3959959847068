import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
	Grid,
	CardHeader,
	CardContent,
    Chip
} from '@material-ui/core';
import Card from '../widgets/Card';
import { errorColourContrast, errorColour } from '../../variables';
import round from 'lodash/round';

const TenantCard = React.memo(({tenant, canEdit, classes}) => {
    const currenData = tenant.currentData > 0 ? round(tenant.currentData * 10**-9, 2) : 0;

	return (
		<Grid item xs={12} sm={6} md={6} lg={4}>
            <Link to={`/tenant/${tenant.tenantId}`}>
			<Card className="tenant-card" >
                <CardHeader
                    title={tenant.name}
                    style={{ textAlign: 'center' }}
                >
                </CardHeader>
				<CardContent>
                    <img src={`/Resources/tenant-logo?tenantId=${tenant.tenantId}`} alt="" role="presentation" style={{ width: '100%' }} />
                    {!tenant.isActive &&
                        <div style={{ marginTop: '20px', textAlign: 'center'}}>
                            <Chip
                                size="small"
                                style={{ background: errorColour, color: errorColourContrast, textAlign: 'center' }}
                                label={"Inactive"}
                            />
                        </div>
                    }
                    <table className="full-bordered" style={{ width: '100%', marginTop: '20px' }}>
                        <tbody>
                            <tr>
                                <td>Contracts:</td>
                                <td style={{ minWidth: '30px', textAlign: 'right' }}>{tenant.stats.contractCount} / {tenant.maxContracts > 0 ? tenant.maxContracts : <span>&#8734;</span>}</td>
                            </tr>
                            <tr>
                                <td>Users:</td>
                                <td style={{ minWidth: '30px', textAlign: 'right' }}>{tenant.stats.userCount} / {tenant.maxUsers > 0 ? tenant.maxUsers : <span>&#8734;</span>}</td>
                            </tr>
                            <tr>
                                <td>Data:</td>
                                <td style={{ minWidth: '30px', textAlign: 'right' }}>{`${currenData} GB / ${tenant.maxData > 0 ? tenant.maxData : '∞'} GB`}</td>
                            </tr>
                            <tr>
                                <td>Files:</td>
                                <td style={{ minWidth: '30px', textAlign: 'right' }}>{tenant.stats.fileCount}</td>
                            </tr>
                            <tr>
                                <td>Schedules:</td>
                                <td style={{ minWidth: '30px', textAlign: 'right' }}>{tenant.stats.scheduleOfRatesCount}</td>
                            </tr>
                            <tr>
                                <td>Lots:</td>
                                <td style={{ minWidth: '30px', textAlign: 'right' }}>{tenant.stats.lotCount}</td>
                            </tr>
                            <tr>
                                <td>Correspondence:</td>
                                <td style={{ minWidth: '30px', textAlign: 'right' }}>{tenant.stats.designCorrespondenceCount + tenant.stats.incomingCorrespondenceCount + tenant.stats.outgoingCorrespondenceCount + tenant.stats.principalCorrespondenceCount}</td>
                            </tr>
                        </tbody>
                    </table>
                    {tenant.comments && 
                        <div style={{ marginTop: '20px', color: '#555' }}>
                            {tenant.comments}
                        </div>
                    }
				</CardContent>
			</Card>
            </Link>
		</Grid>
	);
});

TenantCard.propTypes = {
	tenant: PropTypes.object.isRequired,
	canEdit: PropTypes.bool
};

TenantCard.defaultProps = {
	canEdit: false
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantCard);
