import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const HeaderProgressIndicator = () => (
	<CircularProgress size={30} style={{ marginLeft: '1em', marginTop: '7px' }} />
);

HeaderProgressIndicator.propTypes = {
};


export default HeaderProgressIndicator;
