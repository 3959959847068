import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';
import Button from '../widgets/button';
import TextField from '../widgets/TextField';
import { formatDate } from '../../utils/utils';

class ForecastPeriodDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			periodName: formatDate(new Date(), 'MMMM yy')
		};
	}

	render() {
		return (
	<Dialog
		title="New Forecast Period"
		actions={
			<React.Fragment>
				<Button
					label="Add"
					primary={true}
					onClick={() => this.props.onOk(this.state.periodName)}
				/>,
				<Button
					label="Cancel"
					primary={false}
					onClick={this.props.onClose}
				/>
			</React.Fragment>
		}
		open={this.props.show}
		onClose={this.props.onClose}
	>
		<TextField
			value={this.state.periodName}
			onChange={e => this.setState({ periodName: e.target.value })}
			label="Period Name"
			fullWidth={true}
		/>
	</Dialog>
		);
	}
}

ForecastPeriodDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default ForecastPeriodDialog;
