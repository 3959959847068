import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../widgets/dataTable';

class ExtensionsOfTimeHandoverContent extends React.Component {
	render() {
		const data = this.props.report;

		const columns = [
			{ name: 'extensionOfTimeNumber', label: 'EOT Number' },
			{ name: 'date', label: 'Date', dataType: 'date' },
			{ name: 'description', label: 'EOT Description' },
			{ name: 'status', label: 'EOT Status' },
			{ name: 'daysApproved', label: 'Number of Days Approved' },
			{ name: 'approvedByName', label: 'Approved By' },
			{ name: 'previousPracticalCompletionDate', label: 'Previous Date for PC', dataType: 'date' },
			{ name: 'revisedPracticalCompletionDate', label: 'Revised PC Date', dataType: 'date' },
			{ name: 'comments', label: 'Comments', headerStyle: { minWidth: '300px' } }
		];

		return (
		<div>
			<div className="handover-data" style={{ marginTop: '40px' }}>
				<DataTable 
					style={{ width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={data || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

ExtensionsOfTimeHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default ExtensionsOfTimeHandoverContent;
