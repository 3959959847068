import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../reducers/modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '../widgets/button';

const styles = () => ({
});

const YesNoModal = props => {
	const close = () => {
		props.hideModal(); 
		props.onCancel();
	}
	
	const cancel = () => {
		props.hideModal(); 
		if (props.onNo) props.onNo();
	}

	const ok = () => {
		props.hideModal(); 
		props.onYes();
	}

	return (
		<Dialog 
			open 
			onClose={close}
			 aria-labelledby={props.title} 
			 className={`simple-modal ${props.classes.simpleModal}`}
		>
			<DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button 
					autoFocus 
					primary={true} 
					className="yes-button" 
					onClick={ok}
					label="Yes"
				/>
				<Button 
					className="no-button" 
					onClick={cancel} 
					label="No"
				/>
			</DialogActions>
		</Dialog>
	);
};

YesNoModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onYes: PropTypes.func.isRequired,
	onNo: PropTypes.func,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

YesNoModal.defaultProps = {
	onNo: () => null
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(YesNoModal));
