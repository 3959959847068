import React from 'react';

class Adjustments extends React.Component {
  render() {
    return (
<div>
    <section className="content-header">
        <h1>
            Adjustments - TODO
        </h1>
    </section>

    <section className="content">

    </section>
</div>
    );
  }
}

export default Adjustments;
