import React from 'react';
import PropTypes from 'prop-types';
import { 
	Card as MuiCard,
	CardActions, 
	CardHeader as MuiCardHeader, 
	CardContent 
} from '@material-ui/core';
import CardHeader from './CardHeader';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';

const Card = props => {
	const [expanded, setExpanded] = React.useState(true);

	React.useEffect(() => {
		if (props.expanded !== undefined) setExpanded(props.expanded);
	}, []);
	const children = isArray(props.children) ? props.children : [props.children];

	let header;
	let content;
	let actions;
	forEach(children, c => {
		if (c) {
			if (c.type === CardHeader) {
				header = React.cloneElement(c, { 
					expanded: expanded,
					onExpand: () => { setExpanded(true); },
					onCollapse: () => { setExpanded(false); }
				});
			} else if (c.type === MuiCardHeader) {
				header = c;
			} else if (c.type === CardContent) {
				content = c;
			} else if (c.type === CardActions) {
				actions = c;
			}
		}
	});
	
	return (
		<MuiCard {...props}>
			{header}
			{expanded && content}
			{actions}
		</MuiCard>
	);
};

Card.propTypes = {
	children: PropTypes.array.isRequired,
	expanded: PropTypes.bool
};

Card.defaultProps = {
	expanded: undefined
};

export default Card;
