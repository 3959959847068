import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../utils/utils';

const HandoverDateField = ({ label, value }) => (
	<div className="handover-field">
		<span className="handover-field-label">{label}</span>
		<span className="handover-field-value">{value ? formatDate(value, 'dd MMMM yyyy') : null}</span>
	</div>
);

HandoverDateField.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string
};

HandoverDateField.defaultProps = {
	label: '',
	value: ''
};

export default HandoverDateField;
