import React from 'react';

const DisplayField = ({ label, value }) =>
	<div className="display-field">
		{label && <label>{label}:</label>}
		<span>{value}</span>
	</div>;

DisplayField.propsTypes = {
};

DisplayField.defaultProps = {
};

export default DisplayField;
