import React from 'react';
import { Popover as MuiPopover } from '@material-ui/core';

const Popover = props =>
	<MuiPopover {...props} />;
	

Popover.propTypes = {
};

Popover.defaultProps = {
};

export default Popover;
