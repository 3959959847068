import forEach from 'lodash/forEach';
import { get, post } from './ajax';
import { showErrorNotification } from '../reducers/notifications';
import Logger from './logger';
import isArray from 'lodash/isArray';
import { showModal } from '../reducers/modal';
import { addFailedAjaxCall } from '../reducers/system';

let isLoaded = false;
window.addEventListener('load', () => {
	isLoaded = true;
});

const dispatchActions = (dispatch, actions) => {
	if (!actions) return;
	forEach(isArray(actions) ? actions : [actions], action => dispatch(action));
};

export const createFetchAction = (args) => 
	(dispatch, getState) => {
		let url = args.url;

		if (args.passContext) {
			const state = getState();
			const contractId = state.context.contract ? state.context.contract.contractId : 0;
			const periodId = state.context.period ? state.context.period.periodId : null;
			if (url.indexOf('?') >= 0) {
				url = `${args.url}&contractId=${contractId}&periodId=${periodId}`;
			} else {
				url = `${args.url}?contractId=${contractId}&periodId=${periodId}`;
			}
		}
		
		const headers = {};
		if (global._cookies) {
			let cookieString = '';
			forEach(global._cookies, (c) => {
				cookieString += `${c.key}=${c.value};`;
			});
			headers.Cookie = cookieString;
		}

		get({
			url: url,
			headers: headers,
			data: args.data,
			onSuccess: (data) => {
				if (args.success) {
					const result = args.success.call(this, data, dispatch);
					dispatchActions(dispatch, result);
				}
			},
			onError: (error) => {
				Logger.logError(error);
				if (args.onError) {
					const result = args.onError.call(this, error);
					dispatchActions(dispatch, result);
				} else {
					dispatch(showErrorNotification(error.message));
				}
			},
			onSessionTimeout: isLoaded ? () => {
				dispatch(addFailedAjaxCall({
					type: 'get',
					args: args
				}));
				dispatch(showModal("LOGIN", { }));
			} : undefined
		});

		dispatch({ type: args.startAction, payload: null, data: args.startActionData || args.data });
	};

export const createPostAction = (args) => 
	(dispatch, getState) => {
		let url = args.url;

		if (args.passContext) {
			const state = getState();
			const contractId = state.context.contract ? state.context.contract.contractId : 0;
			const periodId = state.context.period ? state.context.period.periodId : null;
			if (url.indexOf('?') >= 0) {
				url = `${args.url}&contractId=${contractId}&periodId=${periodId}`;
			} else {
				url = `${args.url}?contractId=${contractId}&periodId=${periodId}`;
			}
		}

		const headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		};

		post({
			url: url,
			headers: headers,
			data: args.data,
			onSuccess: (data) => {
				if (args.success) {
					const result = args.success.call(this, data, dispatch);
					dispatchActions(dispatch, result);
				}
			},
			onError: (error) => {
				Logger.logError(error);
				if (args.onError) {
					const result = args.onError.call(this, error);
					dispatchActions(dispatch, result);
				} else {
					dispatch(showErrorNotification(error.message));
				}
			},
			onSessionTimeout: isLoaded ? () => {
				dispatch(addFailedAjaxCall({
					type: 'post',
					args: args
				}));
				dispatch(showModal("LOGIN", { }));
			} : undefined
		});

		dispatch({ type: args.startAction || null, payload: null, data: args.startActionData || args.data });
	};


export const createFormPostAction = (args) => 
	(dispatch, getState) => {
		if (args.passContext) {
			const state = getState();
			const contractId = state.context.contract.contractId;
			args.formData.append('ContractId', contractId);
			if (state.context.period) {
				const periodId = state.context.period.periodId;
				args.formData.append('PeriodId', periodId);
			}
		}

		const headers = {};
		if (global._cookies) {
			let cookieString = '';
			forEach(global._cookies, (c) => {
				cookieString += `${c.key}=${c.value};`;
			});
			headers.Cookie = cookieString;
		}
		post({
			url: args.url,
			data: args.formData,
			onProgress: (progress) => {
				if (args.onProgress) {
					const result = args.onProgress.call(this, progress, dispatch);
					dispatchActions(dispatch, result);
				}
			},
			onSuccess: (data) => {
				if (args.success) {
					const result = args.success.call(this, data, dispatch);
					dispatchActions(dispatch, result);
				}
			},
			onError: (error) => {
				Logger.logError(error);
				if (args.onError) {
					const result = args.onError.call(this, error);
					dispatchActions(dispatch, result);
				} else {
					dispatch(showErrorNotification(error.message));
				}
			},
			onSessionTimeout: isLoaded ? () => {
				dispatch(addFailedAjaxCall({
					type: 'formPost',
					args: args
				}));
				dispatch(showModal("LOGIN", { }));
			} : undefined
		});

		dispatch({ type: args.startAction || null, payload: null, data: args.startActionData || args.data });
	};
