import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import PracticalCompletionsGrid from './practicalCompletionGrid';
import { 
	deletePracticalCompletion, 
	requestPracticalCompletions, 
	clearPracticalCompletions 
} from '../../reducers/practicalCompletion';
import ContentContainer from '../contentContainer';
import { showModal } from '../../reducers/modal';
import { canEdit } from '../../selectors/canEdit';
import PageLayout from '../pageLayout';

class PracticalCompletions extends Component {
    constructor(props) {
        super(props);

        this.state = {
			practicalCompletions: {}
        };

		this.onEditPracticalCompletion = this.onEditPracticalCompletion.bind(this);
		this.onViewPracticalCompletion = this.onViewPracticalCompletion.bind(this);
		this.onDeletePracticalCompletion = this.onDeletePracticalCompletion.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestPracticalCompletions());
	}
	static clearData(dispatch) {
		dispatch(clearPracticalCompletions());
	}
	componentDidMount() {
		PracticalCompletions.fetchData(this.props.dispatch);
	}

	onEditPracticalCompletion = (practicalCompletion) => {
		this.props.history.push(`/practical-completion/${practicalCompletion.practicalCompletionId}`);
	}
	onViewPracticalCompletion = (practicalCompletion) => {
		this.props.history.push(`/practical-completion/${practicalCompletion.practicalCompletionId}`);
	}

	onDeletePracticalCompletion(practicalCompletion) {
		this.props.deletePracticalCompletion(practicalCompletion);
	}

	newPracticalCompletion = () => {
		this.props.history.push('/practical-completion');
	}

	render() {
		return (
<PageLayout
	title="Practical Completion"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'New Practical Completion',
				onClick: this.newPracticalCompletion
			}
		] : []
	}
	content={
		<ContentContainer>
			<PracticalCompletionsGrid 
				practicalCompletions={this.props.practicalCompletions} 
				onEditPracticalCompletion={this.onEditPracticalCompletion} 
				onViewPracticalCompletion={this.onViewPracticalCompletion} 
				onDeletePracticalCompletion={this.onDeletePracticalCompletion} 
				canEdit={this.props.canEdit}
			/>
		</ContentContainer>
	}
/>
		);
	}
}

PracticalCompletions.propTypes = {
	dispatch: PropTypes.func.isRequired,
	practicalCompletions: PropTypes.array,
	deletePracticalCompletion: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

PracticalCompletions.defaultProps = {
	practicalCompletions: []
};

const mapStateToProps = (state) => ({
	practicalCompletions: state.practicalCompletion.practicalCompletions,
	canEdit: canEdit(state, 'EditCertificates'),
	isLoading: state.practicalCompletion.isLoading
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deletePracticalCompletion: (practicalCompletion) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this Practical Completion?',
			onOk: () => {
				dispatch(deletePracticalCompletion(practicalCompletion));
			}
		}));
	}
});

export default connectedRouterHOC(
	mapStateToProps,
	mapDispatchToProps
)(PracticalCompletions);
