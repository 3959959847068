import React from 'react';
import PropTypes from 'prop-types';
import PersistedDataTable from '../widgets/persistedDataTable';
import DataTableNumericEditor from '../widgets/dataTableNumericEditor';
import DataTableTextEditor from '../widgets/dataTableTextEditor';
import { 
	successColour, 
	errorColour, 
	warningColour,
	units 
} from '../../variables';
import { 
	Card, 
	CardHeader, 
	CardContent 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ExpandIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
}));

const ForecastScheduleForm = React.memo(({ items, scheduleName, updateForecastItem }) => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const onExpandClick = () => {
		setExpanded(!expanded);
	};

	const isOutOfLimits = (i) => {
		if (i._appliedForecastQty && i.limit) {
			const lowerLimit = (1 - i.limit) * i.qty * i.rate;
			const upperLimit = (1 + i.limit) * i.qty * i.rate;
			const forecastAmount = i._appliedForecastQty * i.rate;
			if (forecastAmount < lowerLimit || forecastAmount > upperLimit) {
				return true;
			}
		}

		return false;
	};

	const columns = [
		{ 
			name: 'itemNo', 
			label: 'Item',
			cellStyle: (args) => args.data.isTextOnlyLine ? { fontStyle: 'italic', backgroundColor: '#FAFAFA' } : {}
		},
		{ 
			name: 'description', 
			label: 'Description', 
			headerStyle: { minWidth: '200px' },
			colspan: (args) => args.data.isTextOnlyLine ? 10 : 1,
			cellStyle: (args) => args.data.isTextOnlyLine ? { fontStyle: 'italic', backgroundColor: '#FAFAFA' } : {} 
		},
		{ 
			name: 'unit', 
			label: 'Unit',
			filterOperators: ['=', 'Contains', 'multi-select'],
			filterOptions: units 
		},
		{ name: 'qty', label: 'Qty', dataType: 'number' },
		{ 
			name: 'rate', 
			label: 'Rate', 
			dataType: 'number', 
			formatString: 'currency' 
		},
		{ 
			name: 'amount', 
			label: 'Amount', 
			excludeFromSearch: true,
			showTotal: true,
			dataType: 'number',
			formatString: 'currency',
			value: (args) => args.data.qty * args.data.rate
		},
		{ 
			name: 'certifiedQtyToDate', 
			label: 'Certified Quantity', 
			showTotal: true,
			dataType: 'number'
		},
		{ 
			name: 'certifiedToDate', 
			label: 'Certified To Date', 
			showTotal: true,
			dataType: 'number',
			formatString: 'currency' 
		},
		{ 
			name: 'certifiedToDate', 
			label: 'Certified To Date', 
			showTotal: true,
			dataType: 'number',
			formatString: 'currency' 
		},
		{ 
			name: 'limit', 
			label: 'Limit Of Accuracy', 
			dataType: 'number', 
			formatString: 'percent' 
		},
		{ 
			name: 'forecastQty', 
			label: 'Forecast Qty',
			cellStyle: args => {
				if (args.data._appliedForecastQty < args.data.certifiedQtyToDate 
					|| (args.data.forecastQty === null && args.data.prevForecastQty === null && args.data.certifiedQtyToDate > args.data.qty)) {
						return {
							backgroundColor: '#FFC7CE',
							color: '#9C0006'
						};
					} else {
						return null;
					}
			},
			cellRenderer: (args) => {
				const value = args.data.forecastQty;
				const hintText = args.data._appliedForecastQty;

				return (
				<div>
					<DataTableNumericEditor
						type='decimal'
						value={value} 
						hintText={hintText}
						style={{ color: args.data._isOutOfLimits ? warningColour : '' }}
						nullable={true}
						onChange={(v) => { 
							args.data._isOutOfLimits = isOutOfLimits(args.data);
							return updateForecastItem({ ...args.data, forecastQty: v }); 
						}}
					/>
				</div>
				);
			}
		},	
		{ 
			name: 'forecastAmount', 
			label: 'Forecast Amount', 
			excludeFromSearch: true,
			showTotal: true,
			dataType: 'number',
			formatString: 'currency',
			value: (args) => args.data._appliedForecastQty * args.data.rate,
			cellStyle: (args) => (
				{ 
					color: args.data.forecastQty ? undefined : 'rgba(0, 0, 0, 0.3)'	
				}
			)
		},
		{ 
			name: 'forecastDifference', 
			label: 'Difference', 
			dataType: 'number',
			formatString: 'currency',
			showTotal: true,
			value: args => args.data._difference === 0 ? '' : args.data._difference,
			cellStyle: (args) => {
				const style = {};
				style.color = args.value < 0 ? errorColour : successColour;
				return style;
			},
			totalStyle: (args) => {
				const style = {};
				style.color = args.value < 0 ? errorColour : successColour;
				return style;
			}
		},
		{ 
			name: 'comments', 
			label: 'Comments', 
			headerStyle: { minWidth: '200px' },
			cellRenderer: (args) => 
				<div>
					<DataTableTextEditor
						key={`comments-${args.data.scheduleOfRatesItemId}`}
						value={args.data.comments} 
						multiline={true}
						onChange={(v) => { updateForecastItem({ ...args.data, comments: v }); }}
					/>
				</div>
		}
	];

	return (
		<Card style={{ marginTop: '20px' }}>
			<CardHeader
				title={scheduleName}
				titleTypographyProps={{
					variant: 'h6'
				}}
				action={
					<IconButton
						className={classnames(classes.expand, {
							[classes.expandOpen]: expanded,
						})}
						onClick={onExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandIcon />
					</IconButton>
				}
			>
			</CardHeader>
			{expanded &&
				<CardContent>
					<PersistedDataTable 
						id="forecastItems"
						data={items} 				
						columns={columns}
						enableSorting={false}
						tableContainerStyles={{
							maxHeight: '600px'
						}}
					/>
				</CardContent>
			}
		</Card>
	);
});

ForecastScheduleForm.propTypes = {
	scheduleName: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	updateForecastItem: PropTypes.func.isRequired
};

export default ForecastScheduleForm;
