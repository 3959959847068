import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="pull-right hidden-xs">
          CAS
        </div>
        <strong>Copyright &copy; 2021 <a href="#">Conplete Array Pty Ltd</a>.</strong> All rights reserved.
      </footer>
    );
  }
}

export default Footer;
