import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import OkIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';

const MessagePanel = ({ isSuccess, message }) => {
	const messagePanelClass = classNames({
		'hidden': !message,
		'success-message': isSuccess,
		'error-message': !isSuccess
	});

	return (
		<Paper 
			className={messagePanelClass} 
			style={{ 
				margin: '10px 0px 10px 0px', 
				padding: '15px', 
				fontSize: '16px', 
				color: (isSuccess) ? '#35aa47' : '#d9534f' 
			}}
		>
			{ isSuccess ? <OkIcon style={{ color: '#35aa47', verticalAlign: 'middle' }} /> : <ErrorIcon style={{ color: '#d9534f', verticalAlign: 'middle' }} /> }
			<span style={{ marginLeft: '10px', verticalAlign: 'middle' }}>{message}</span>
		</Paper>
	);
};

MessagePanel.propTypes = {
	isSuccess: PropTypes.bool,
	message: PropTypes.string
};

MessagePanel.defaultProps = {
	isSuccess: false,
	message: ''
};

export default MessagePanel;
