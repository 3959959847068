import React from 'react';
import MessagePanel from '../messagePanel';
import DesignCorrespondenceHandoverContent from './content/designCorrespondenceHandoverContent';
import DesignCorrespondencesHandoverContent from './content/designCorrespondencesHandoverContent';
import EotHandoverContent from './content/eotHandoverContent';
import EotsHandoverContent from './content/eotsHandoverContent';
import GenericHandoverContent from './content/genericHandoverContent';
import IncomingCorrespondenceHandoverContent from './content/incomingCorrespondenceHandoverContent';
import IncomingCorrespondencesHandoverContent from './content/incomingCorrespondencesHandoverContent';
import LotAdjustmentHandoverContent from './content/lotAdjustmentHandoverContent';
import LotAdjustmentsHandoverContent from './content/lotAdjustmentsHandoverContent';
import LotHandoverContent from './content/lotHandoverContent';
import LotsHandoverContent from './content/lotsHandoverContent';
import NcrHandoverContent from './content/ncrHandoverContent';
import NcrsHandoverContent from './content/ncrsHandoverContent';
import OutgoingCorrespondenceHandoverContent from './content/outgoingCorrespondenceHandoverContent';
import OutgoingCorrespondencesHandoverContent from './content/outgoingCorrespondencesHandoverContent';
import PeriodHandoverContent from './content/PeriodHandoverContent';
import PrincipalCorrespondenceHandoverContent from './content/principalCorrespondenceHandoverContent';
import PrincipalCorrespondencesHandoverContent from './content/principalCorrespondencesHandoverContent';
import ScheduleOfRatesHandoverContent from './content/ScheduleOfRatesHandoverContent';
import VariationHandoverContent from './content/variationHandoverContent';
import VariationsHandoverContent from './content/variationsHandoverContent';
import ProgressClaimReportContent from '../reports/progressClaimReportContent';
import ProgressClaimByLotReportContent from '../reports/progressClaimByLotReportContent';
import ProgressClaimSummaryReportContent from '../reports/progressClaimSummaryReportContent';
import PaymentCertificateContent from '../certificates/PaymentCertificateContent';
import FinalCertificateContent from '../certificates/FinalCertificateContent';
import SeparablePortionHandoverContent from './content/SeparablePortionHandoverContent';
import SeparablePortionsHandoverContent from './content/SeparablePortionsHandoverContent';
import RegisterHandoverContent from './content/RegisterHandoverContent';
import RegistersHandoverContent from './content/RegistersHandoverContent';
import MissingFilesHandoverContent from './content/MissingFilesHandoverContent';
import get from 'lodash/get';

const HandoverRenderer = () => {
	const [state, setState] = React.useState({
		type: null,
		report: null,
		title: '',
		success: false,
		message: ''
	});

	React.useEffect(() => {
		if (state.report) {
			window.status = 'ready_to_print';
		}
	}, [state.report]);

	React.useEffect(() => {
		// Add method to allow handover to be rendered externally
		window.renderHandover = (type, reportJson) => {
			const report = JSON.parse(reportJson);

			setState(prevState => ({
				type: type,
				report: report
			}));
		};
		window.status = 'ready_for_data';

		window.clearHandover = () => {
			window.status = 'ready_for_data';
			setState({
				type: null,
				report: null,
				title: '',
				success: false,
				message: ''
			});
			return true;
		};
	}, []);

	let report = null;
	let title = '';
	let Content = null;
	let className = '';
	let registerDefinition = null;

	switch (state.type) {
		case 'DesignCorrespondence':
			report = get(state.report, "data[0]") || {};
			title = `Design Correspondence ${report.drrNumber}`;
			Content = DesignCorrespondenceHandoverContent;
			break;
		case 'DesignCorrespondences':
			report = get(state.report, "data") || [];
			title = `Design Correspondence`;
			Content = DesignCorrespondencesHandoverContent;
			break;
		case 'ExtensionOfTime':
			report = get(state.report, "data[0]") || {};
			title = `Extension of Time ${report.extensionOfTimeNumber}`;
			Content = EotHandoverContent;
			break;
		case 'ExtensionsOfTime':
			report = get(state.report, "data") || [];
			title = `Extensions of Time`;
			Content = EotsHandoverContent;
			break;
		case 'IncomingCorrespondence':
			report = get(state.report, "data[0]") || {};
			title = `Incoming Correspondence ${report.rfiNumber}`;
			Content = IncomingCorrespondenceHandoverContent;
			break;
		case 'IncomingCorrespondences':
			report = get(state.report, "data") || [];
			title = `Incoming Correspondence`;
			Content = IncomingCorrespondencesHandoverContent;
			break;
		case 'LotAdjustment':
			report = get(state.report, "data[0]") || {};
			title = `Lot Adjustment ${report.lotNo}`;
			Content = LotAdjustmentHandoverContent;
			break;
		case 'LotAdjustments':
			report = get(state.report, "data") || [];
			title = `Lot Adjustments`;
			Content = LotAdjustmentsHandoverContent;
			break;
		case 'Lot':
			report = get(state.report, "data[0]") || {};
			title = `Lot ${report.lotNo}`;
			Content = LotHandoverContent;
			break;
		case 'Lots':
			report = get(state.report, "data") || [];
			title = `Lots`;
			Content = LotsHandoverContent;
			break;
		case 'NonConformanceRegister':
			report = get(state.report, "data[0]") || {};
			title = `NCR ${report.contractorNcrNo}`;
			Content = NcrHandoverContent;
			break;
		case 'NonConformanceRegisters':
			report = get(state.report, "data") || [];
			title = `Non Conformance Register`;
			Content = NcrsHandoverContent;
			break;
		case 'OutgoingCorrespondence':
			report = get(state.report, "data[0]") || {};
			title = `Outgoing Correspondence ${report.rfiNumber}`;
			Content = OutgoingCorrespondenceHandoverContent;
			break;
		case 'OutgoingCorrespondences':
			report = get(state.report, "data") || [];
			title = `Outgoing Correspondence`;
			Content = OutgoingCorrespondencesHandoverContent;
			break;
		case 'PaymentCertificate':
			report = get(state.report, "data[0]") || {};
			title = `Payment Certificate`;
			Content = PaymentCertificateContent;
			break;
		case 'FinalCertificate':
			report = get(state.report, "data[0]") || {};
			title = `Final Certificate`;
			Content = FinalCertificateContent;
			break;
		case 'Period':
			report = get(state.report, "data[0]") || {};
			title = `Period ${report.name}`;
			Content = PeriodHandoverContent;
			break;
		case 'PrincipalCorrespondence':
			report = get(state.report, "data[0]") || {};
			title = `Principal Correspondence ${report.rfiNumber}`;
			Content = PrincipalCorrespondenceHandoverContent;
			break;
		case 'PrincipalCorrespondences':
			report = get(state.report, "data") || [];
			title = `Principal Correspondence`;
			Content = PrincipalCorrespondencesHandoverContent;
			break;
		case 'ProgressClaim':
			report = get(state.report, "data[0]") || {};
			title = 'Progress Claim';
			Content = ProgressClaimReportContent;
			className = 'progress-claim-report'
			break;
		case 'ProgressClaimByLot':
			report = get(state.report, "data[0]") || {};
			title = 'Progress Claim by Lot';
			Content = ProgressClaimByLotReportContent;
			className = 'progress-claim-by-lot';
			break;
		case 'ProgressClaimSummary':
			report = get(state.report, "data[0]") || {};
			title = 'Progress Claim Summary';
			Content = ProgressClaimSummaryReportContent;
			className = 'progress-claim-summary';
			break;
		case 'ScheduleOfRates':
			report = get(state.report, "data[0]") || {};
			title = `Schedule ${report.name}`;
			Content = ScheduleOfRatesHandoverContent;
			break;
		case 'Register':
			report = get(state.report, "data[0]") || {};
			registerDefinition = get(state.report, "registerDefinition");
			title = registerDefinition.name;
			Content = RegisterHandoverContent;
			break;
		case 'Registers':
			report = get(state.report, "data") || [];
			registerDefinition = get(state.report, "registerDefinition");
			title = registerDefinition.name;
			Content = RegistersHandoverContent;
			break;
		case 'SeparablePortion':
			report = get(state.report, "data[0]") || {};
			title = `Separable Portion ${report.name}`;
			Content = SeparablePortionHandoverContent;
			break;
		case 'SeparablePortions':
			report = get(state.report, "data") || [];
			title = `Separable Portions`;
			Content = SeparablePortionsHandoverContent;
			break;
		case 'Variation':
			report = get(state.report, "data[0]") || {};
			title = `Variation ${report.variationNo}`;
			Content = VariationHandoverContent;
			break;
		case 'Variations':
			report = get(state.report, "data") || [];
			title = `Variations`;
			Content = VariationsHandoverContent;
			break;
		case 'MissingFiles':
			report = get(state.report, "data") || [];
			title = `Missing Files`;
			Content = MissingFilesHandoverContent;
			break;
		default:
			report = get(state.report, "data[0]") || {};
			title = state.type;
			Content = GenericHandoverContent;
			break;																																																	
	}
		
	return (
		<div className="handover">
			<section className={`content ${className}`}>
				<div className="report-container">
					<div className='header'>
						<h1>{title}</h1>
					</div>

					<div className="logo">
						<img src="/images/contrack_logo_200x58.jpg" alt="" />
					</div>

					{Content &&
						<Content 
							report={report} 
							registerDefinition={registerDefinition} 
							isLoading={false} 
							success={state.success} 
							message={state.success} 
						/>
					}
				</div>

				<MessagePanel isSuccess={state.success} message={state.message} />
			</section>
		</div>
	);
}

HandoverRenderer.propTypes = {
};

export default HandoverRenderer;
