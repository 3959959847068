import find from 'lodash/find';
import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import cloneDeep from 'lodash/cloneDeep';
import { globals } from '../globals';
import map from 'lodash/map';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	event: null, 
	events: null
};

const CLEAR_EVENTS = 'CLEAR_EVENTS';
const CLEAR_EVENT = 'CLEAR_EVENT';
const FETCH_EVENT = 'FETCH_EVENT';
const DELETE_EVENT = 'DELETE_EVENT';
const REQUEST_EVENTS = 'REQUEST_EVENTS';
const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
const REQUEST_EVENT = 'REQUEST_EVENT';
const RECEIVE_EVENT = 'RECEIVE_EVENT';
const CREATE_NEW_EVENT = 'CREATE_NEW_EVENT';
const SAVE_EVENT = 'SAVE_EVENT';
const RECEIVE_SAVE_EVENT_RESPONSE = 'RECEIVE_SAVE_EVENT_RESPONSE';
const RECEIVE_DELETE_EVENT_RESPONSE = 'RECEIVE_DELETE_EVENT_RESPONSE';
const SHOW_EVENT_ERRORS = 'SHOW_EVENT_ERRORS';

export const clearEvents = () => ({ type: CLEAR_EVENTS });
export const clearEvent = () => ({ type: CLEAR_EVENT });
export const fetchEvent = (eventId) => ({ type: FETCH_EVENT, eventId });
export const receiveEvents = (data) => ({ type: RECEIVE_EVENTS, payload: { data } });
export const receiveEvent = (data) => ({ type: RECEIVE_EVENT, payload: { data } });
export const createNewEvent = (definition) => ({ type: CREATE_NEW_EVENT, definition: definition });
export const receiveSaveEventResponse = (data) => ({ type: RECEIVE_SAVE_EVENT_RESPONSE, data });
export const receiveDeleteEventResponse = (data) => ({ type: RECEIVE_DELETE_EVENT_RESPONSE, data });

export const requestEvents = () => (
	createFetchAction({
		objectName: 'Events',
		passContext: true,
		url: '/api/userEvents',
		startAction: REQUEST_EVENTS,
		success: (data) =>
			receiveEvents(map(data, (e) => ({
				...e,
				eventDate: e.eventDate ? new Date(e.eventDate) : null,
				triggeredDate: e.triggeredDate ? new Date(e.triggeredDate) : null,
				createdDate: e.createdDate ? new Date(e.createdDate) : null
			})))
	})
);

export const requestEvent = (eventId) => (
	createFetchAction({
		objectName: 'Event',
		passContext: true,
		url: `/api/userEvents/${eventId}`,
		startAction: REQUEST_EVENT,
		success: (data) => 
			receiveEvent({
				...data,
				eventDate: data.eventDate ? new Date(data.eventDate) : null,
				triggeredDate: data.triggeredDate ? new Date(data.triggeredDate) : null,
				createdDate: data.createdDate ? new Date(data.createdDate) : null
			})
	})
);

const validateEvent = (event) => {
	const result = {
		success: false,
		message: '',
		fields: []
	};
	if (!event.name) {
		result.fields.push({
			fieldName: 'Name',
			valid: false,
			message: 'Name is required'
		});
	}
	if (!event.eventDate) {
		result.fields.push({
			fieldName: 'EventDate',
			valid: false,
			message: 'Event Date is required'
		});
	}
	if ((event.notification || (event.reminders || []).length > 0) && !event.notificationEmail) {
		result.fields.push({
			fieldName: 'NotificationEmail',
			valid: false,
			message: 'Notification Email is required'
		});
	}

	result.success = !result.message && result.fields.length === 0;
	if (!result.success && !result.message) result.message = 'Please correct the errors';
	return result;
};

export const saveEvent = (event, onSuccess) => {
	const result = validateEvent(event);
	if (!result.success) return { type: SHOW_EVENT_ERRORS, data: result };

	return createPostAction({
		passContext: true,
		url: '/api/userEvents',
		data: event,
		startAction: SAVE_EVENT,
		success: (data, dispatch) => {
			data.object = data.object ? {
				...data.object,
				eventdate: data.object.eventdate ? new Date(data.object.eventdate) : null,
				triggeredDate: data.object.triggeredDate ? new Date(data.object.triggeredDate) : null,
				createdDate: data.object.createdDate ? new Date(data.object.createdDate) : null
			} : data.object;
			dispatch(receiveSaveEventResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteEvent = (event, onSuccess) => (
	createPostAction({
		passContext: true,
		url: `/api/userEvents/${event.userEventId}/delete`,
		startAction: DELETE_EVENT,
		success: (data, dispatch) => {
			dispatch(receiveDeleteEventResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	})
);

const reducer = (originalState = initialState, action) => {
	let state = originalState;
	if (!state._hydrated) {
		state = { ...initialState, ...state, _hydrated: true };
	}

	switch (action.type) {
		case CLEAR_EVENTS:
			return {
				...state,
				events: null
			};
		case CLEAR_EVENT:
			return {
				...state,
				event: null
			};
		case REQUEST_EVENTS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				events: []
			};
		case RECEIVE_EVENTS:
			return {
				...state,
				isLoading: false,
				events: action.payload.data
			};
		case REQUEST_EVENT:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				event: { 
					...cloneDeep(globals.templates.eventTemplate),
					values: {}
				}
			};
		case RECEIVE_EVENT:
			return {
				...state,
				isLoading: false,
				event: action.payload.data
			};
		case DELETE_EVENT:
			return { 
				...state
			};
		case RECEIVE_DELETE_EVENT_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message
					}, 
				};
			}
			return {
				...state,
				isLoading: false,
				events: state.events.filter(c => c.userEventId !== action.data.objectId) 
			};
		case FETCH_EVENT:
			return { 
				...state,
				event: find(state.events, (c) => c.userEventId === action.userEventId)
			};
		case CREATE_NEW_EVENT:
			return {
				...state,
				event: { 
					...cloneDeep(globals.templates.eventTemplate),
					eventDate: new Date()
				}
			};	
		case SAVE_EVENT:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_EVENT_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				event: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_EVENT_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		default:
			return state;
	}
};

export default reducer;