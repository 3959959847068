import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PersistedDataTable from '../widgets/persistedDataTable';
import ViewIcon from '@material-ui/icons/Pageview';

class RegisterDefinitionsGrid extends React.Component {
	shouldComponentUpdate(nextProps) {
		return !this.props.registerDefinitions || this.props.registerDefinitions.length !== nextProps.registerDefinitions.length;
	}

	render() {
		const registerDefinitions = this.props.registerDefinitions || [];

		const columns = [
			{ name: 'name', label: 'Name' },
			{ name: 'description', label: 'Description' },
			{ 
				name: 'fieldCount', 
				label: 'Number of Fields',
				dataType: 'number',
				value: (args) => args.data.fields.length
			},
			{ 
				name: 'recordCount', 
				label: 'Number of Existing Records',
				dataType: 'number'
			}
		];

		if (this.props.canEdit) {
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onEditRegisterDefinition(args.data); } } style={{ padding: '2px' }}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={ () => { this.props.onDeleteRegisterDefinition(args.data); } } style={{ padding: '2px' }}>
							<ActionDelete />
						</IconButton>
					</div>
			});
		} else {	
			columns.splice(0, 0, {
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => 
					<div style={{ whiteSpace: 'nowrap' }}>
						<IconButton onClick={ () => { this.props.onViewRegisterDefinition(args.data); } } style={{ padding: '2px' }}>
							<ViewIcon />
						</IconButton>
					</div>
			});
		}

		return (
			<PersistedDataTable 
				id="registerDefinitions"
				data={registerDefinitions} 
				columns={columns}
			/>
		);
	}
}

RegisterDefinitionsGrid.propTypes = {
	registerDefinitions: PropTypes.array.isRequired,
	onViewRegisterDefinition: PropTypes.func.isRequired,
	onEditRegisterDefinition: PropTypes.func.isRequired,
	onDeleteRegisterDefinition: PropTypes.func.isRequired
};

export default RegisterDefinitionsGrid;
