import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSidePanel, toggleFullScreenSidePanel, hideSidePanels } from '../reducers/layout';
import SelectField from './widgets/selectField';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Divider from './widgets/Divider';
import ChangePasswordDialog from './widgets/changePasswordDialog';
import { post } from '../utils/ajax';
import { bootstrapSuccess } from '../reducers/bootstrap';
import { showSuccessNotification, showErrorNotification } from '../reducers/notifications';
import ToggleIcon from "material-ui-toggle-icon";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contract: 1,
			open: false,
			showChangePasswordDialog: false
		};

		this.logout = this.logout.bind(this);
		this.showChangePasswordDialog = this.showChangePasswordDialog.bind(this);
		this.showMenu = this.showMenu.bind(this);
		this.onCloseMenu = this.onCloseMenu.bind(this);
	}

	logout() {
		post({
			url: "/api/users/logout",
			onSuccess: data => {
				// this.props.showSuccessNotification(data.message);
				this.props.onLogout(data);
			},
			onError: error => {
				this.props.showErrorNotification(error.message);
			}
		});
	}

	showChangePasswordDialog() {
		this.setState({
			open: false,
			showChangePasswordDialog: true
		});
	}


	showMenu(e) {
		e.preventDefault();

		this.setState({
			open: true,
			anchorEl: e.currentTarget
		});
	}

	onCloseMenu() {
		this.setState({
			open: false,
			anchorEl: null
		});
	}

	onChangePasswordOk = () => {
		this.setState({
			showChangePasswordDialog: false
		});
	}
	onChangePasswordCancel = () => {
		this.setState({
			showChangePasswordDialog: false
		});
	}

	render() {
		const contracts = this.props.contracts || [];
		const periods = this.props.periods || [];

		return (
<header className="main-header">
	<Link to='/' className="logo">
		<span className="logo-mini"><img src="/images/contrack_icon_58x58.jpg" alt="" /></span>
	</Link>

	<div className="appbar" role="navigation">
		{/* <a href="#" className="side-panel-toggle" data-toggle="offcanvas" role="button" onClick={ () => { this.props.toggleSidePanel(); } }>
		</a>
		<a href="#" className="side-panel-full-screen-toggle" data-toggle="offcanvas" role="button" onClick={ () => { this.props.toggleFullScreenSidePanel(); } }>
		</a> */}
		<IconButton 
			onClick={() => this.props.sidePanelIsVisible ? this.props.hideSidePanels() : this.props.toggleSidePanel() }
			className="side-panel-toggle"
		>
			<ToggleIcon
				on={this.props.sidePanelIsVisible}
				onIcon={<ChevronLeft fontSize="large" />}
				offIcon={<ChevronRight fontSize="large" />}
			/>
		</IconButton>
		<IconButton 
			onClick={() => this.props.fullScreenSidePanelIsVisible ? this.props.hideSidePanels() : this.props.toggleFullScreenSidePanel() }
			className="side-panel-full-screen-toggle"
		>
			<ToggleIcon
				on={this.props.fullScreenSidePanelIsVisible}
				onIcon={<ChevronLeft fontSize="large" />}
				offIcon={<ChevronRight fontSize="large" />}
			/>
		</IconButton>

		<div className="pull-right logout">
			<IconButton 
				onClick={this.showMenu}
				style={{ padding: '5px', color: '#fff', marginRight: '10px' }}
			>
				<LogoutIcon style={{ height: 24, width: 24 }} />
			</IconButton>
			<Menu
				open={this.state.open}
				anchorEl={this.state.anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
				onClose={this.onCloseMenu}
			>
					{true && <MenuItem component={Link} to="/account" onClick={this.onCloseMenu}>Account</MenuItem>}
					<MenuItem onClick={this.showChangePasswordDialog}>Change Password</MenuItem>
					<Divider />
					<MenuItem onClick={this.logout}>Log Out</MenuItem>
			</Menu>
			<ChangePasswordDialog
				dispatch={this.props.dispatch}
				show={this.state.showChangePasswordDialog}
				title="Change Password"
				message="Enter the new password"
				onOk={this.onChangePasswordOk}
				onCancel={this.onChangePasswordCancel}
			/>
		</div>
		{this.props.contracts && this.props.contracts.length > 0 && 
			<div className="contract-select pull-right input-field">
					<SelectField 
						id="contract-select"
						variant="outlined"
						value={this.props.contract ? this.props.contract.contractId : null} 
						label="Contract"
						onChange={(v) => { this.props.changeContractContext(v); } }
						style={{ marginRight: '20px' }}
						items={
							contracts.map((c) =>
								({ value: c.contractId, label: c.contractName })
							)
						}
					>
					</SelectField>

					<SelectField 
						id="period-select"
						variant="outlined"
						value={this.props.period ? this.props.period.periodId : null} 
						label="Period"
						onChange={(v) => { this.props.changePeriodContext(v); } }
						items={
							periods.map((p) =>
								({ value: p.periodId, label: p.name })
							)
						}
					>
					</SelectField>

				{this.props.periods && this.props.periods.length === 0 &&
					<span className="contract-status">(Preliminary)</span>
				}
			</div>
		}
	</div>
</header>
		);
	}
}

Header.propTypes = {
	dispatch: PropTypes.func.isRequired,
	toggleSidePanel: PropTypes.func.isRequired,
	toggleFullScreenSidePanel: PropTypes.func.isRequired,
	hideSidePanels: PropTypes.func.isRequired,
	contracts: PropTypes.array.isRequired,
	contract: PropTypes.object,
	periods: PropTypes.array.isRequired,
	period: PropTypes.object,
	changeContractContext: PropTypes.func.isRequired,
	changePeriodContext: PropTypes.func.isRequired,
	sidePanelIsVisible: PropTypes.bool.isRequired
};

Header.defaultProps = {
	contract: null,
	period: null
};

const mapStateToProps = (state) => ({
	contracts: filter(state.context.contracts, (c) => includes(['Preliminary', 'In Construction', 'Pending Defects', 'On Maintenance', 'Closed'], c.contractStatus)),
	contract: state.context.contract,
	periods: state.context.contract ? state.context.contract.periods : [],
	period: state.context.period,
	sidePanelIsVisible: !state.layout.collapseSidePanel,
	fullScreenSidePanelIsVisible: state.layout.fullScreenSidePanel,
	isTenanted: state.context.isTenanted
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	hideSidePanels: () => dispatch(hideSidePanels()),
	toggleSidePanel: () => {
		dispatch(toggleSidePanel());
	},
	toggleFullScreenSidePanel: () => {
		dispatch(toggleFullScreenSidePanel());
	},
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	onLogout: (logoutData) => dispatch(bootstrapSuccess(logoutData.bootstrapData))
	// changeContractContext: (contractId) => {
	// 	dispatch(changeContractContext(contractId));
	// },
	// changePeriodContext: (periodId) => {
	// 	dispatch(changePeriodContext(periodId));
	// }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header);
