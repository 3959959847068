import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgressClaimReportContent from '../reports/progressClaimReportContent';
import addHandoverWrapper from './handoverWrapper';

class ProgressClaimReport extends React.Component {
	static getTitle(report) {
		return `Progress Claim`;
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<ProgressClaimReportContent report={report} />
		);
	}
}

ProgressClaimReport.propTypes = {
	context: PropTypes.object.isRequired,
	report: PropTypes.object
};

ProgressClaimReport.defaultProps = {
	report: undefined
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	ProgressClaimReport, {
		title: 'Progress Claim',
		className: 'progress-claim-report'
	})
);
