import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	requestContractForecast, 
	clearContractForecast, 
	saveContractForecast, 
	deleteForecast 
} from '../../reducers/forecasts';
import ContentContainer from '../contentContainer';
import ContractForecastList from './contractForecastList';
import ContractForecastChart from './contractForecastChart';
import map from 'lodash/map';
import { fetch, addTask } from 'domain-task';
import { canEdit } from '../../selectors/canEdit';
import { showSuccessNotification, showErrorNotification } from '../../reducers/notifications';
import { showModal } from '../../reducers/modal';
import { globals } from '../../globals';
import { produce } from 'immer';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import PageLayout from '../pageLayout';
import SaveIcon from '@material-ui/icons/Save';

class ContractForecast extends Component {
    constructor(props) {
        super(props);

        this.state = {
			contractForecast: props.contractForecast
        };

		this.saveChanges = this.saveChanges.bind(this);
		this.newForecast = this.newForecast.bind(this);
		this.updateForecastName = this.updateForecastName.bind(this);
		this.setIncludeInChart = this.setIncludeInChart.bind(this);
		this.setForecastAmount = this.setForecastAmount.bind(this);
		this.deleteForecast = this.deleteForecast.bind(this);
		this.newBaseline = this.newBaseline.bind(this);
    }

	static fetchData(dispatch) {
		dispatch(requestContractForecast());
	}
	static clearData(dispatch) {
		dispatch(clearContractForecast());
	}

	componentDidMount() {
		ContractForecast.fetchData(this.props.dispatch);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			contractForecast: nextProps.contractForecast
		});
	}

	saveChanges() {

	}

	newForecast() {
		const periods = this.getPeriods();
		this.setState(produce(draft => {
			const contractForecast = draft.contractForecast;
			contractForecast.forecasts.push({
				type: 'Project',
				name: 'New Forecast',
				includeInChart: false,
				periodForecasts: map(periods, (p) => ({
					periodId: p.periodId,
					forecastPeriodId: p.forecastPeriodId,
					amount: p.amount
				})),
				subForecasts: []
			});
		}));
	}
	newBaseline() {
		const contractForecast = this.state.contractForecast;

		this.setState({
			isLoading: true
		});
		const fetchTask =  fetch(`/api/forecasts/new-baseline?contractId=${contractForecast.contractId}`, { 
				credentials: 'same-origin',
				headers: {
					'cache-control': 'no-store',
					'pragma': 'no-cache'
				}
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) return response.json();

				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((data) => {
				this.setState(produce(draft => {
					draft.contractForecast.forecasts.push(data);
					draft.isLoading = false;
				}));
			}).catch((error) => {
				console.log('request failed', error);
			});
					
		addTask(fetchTask);
	}
	newForecastPeriod = () => {
		this.props.getForecastPeriodName((v) => {
			const contractForecast = this.state.contractForecast;

			this.setState({
				isLoading: true
			});
			fetch(`/api/forecasts/new-forecast-period?contractId=${contractForecast.contractId}`, { 
					method: 'post', 
					credentials: 'same-origin',
					headers: {
						'cache-control': 'no-store',
						'pragma': 'no-cache',
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						periodName: v
					})
				})
				.then(response => {
					if (response.status >= 200 && response.status < 300) return response.json();
	
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				})
				.then(() => {
					this.setState({
						isLoading: false
					});
				}).catch((error) => {
					this.setState({
						isLoading: false
					});
					this.props.showError(error.message);
				});
		});
	}
	updateForecastName(forecastIndex, name) {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts[forecastIndex].name = name;
		}));
	}
	setIncludeInChart(forecastIndex, includeInChart) {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts[forecastIndex].includeInChart = includeInChart;
		}));
	}
	setForecastAmount(targetForecastIndex, targetPeriodIndex, period, amount) {
		this.setState(produce(draft => {
			const forecast = draft.contractForecast.forecasts[targetForecastIndex];
			if (targetPeriodIndex > -1) {
				forecast.periodForecasts[targetPeriodIndex].amount = amount;
			} else {
				forecast.periodForecasts.push({
					forecastId: forecast.forecastId,
					periodId: period.periodId,
					forecastPeriodId: period.forecastPeriodId,
					amount: amount
				});
			}
		}));
	}
	deleteForecast(forecastIndex) {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts.splice(forecastIndex, 1);
		}));
	}
	lockForecast = (forecastIndex) => {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts[forecastIndex].isLocked = true;
		}));
	}
	unlockForecast = (forecastIndex) => {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts[forecastIndex].isLocked = false;
		}));
	}
	expandForecast = (forecastIndex) => {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts[forecastIndex]._isCollapsed = false;
		}));
	}
	collapseForecast = (forecastIndex) => {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts[forecastIndex]._isCollapsed = true;
		}));
	}
	addSubForecast = (forecastIndex) => {
		const periods = this.getPeriods();
		this.setState(produce(draft => {
			const forecast = draft.contractForecast.forecasts[forecastIndex];
			const subForecast = {
				...globals.templates.subForecast,
				forecastId: forecast.forecastId,
				name: ''
			};
			subForecast.periodForecasts = map(periods, (p) => ({
				periodId: p.periodId,
				forecastPeriodId: p.forecastPeriodId
			}));
			
			forecast.subForecasts.push(subForecast);
		}));
	}
	deleteSubForecast = (forecastIndex, subForecastIndex) => {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts[forecastIndex].subForecasts.splice(subForecastIndex, 1);
		}));
	}
	updateSubForecastName = (forecastIndex, subForecastIndex, name) => {
		this.setState(produce(draft => {
			draft.contractForecast.forecasts[forecastIndex].subForecasts[subForecastIndex].name = name;
		}));
	}
	setSubForecastAmount = (forecastIndex, subForecastIndex, periodIndex, period, amount) => {
		this.setState(produce(draft => {
			const forecast = draft.contractForecast.forecasts[forecastIndex];
			const subForecast = forecast.subForecasts[subForecastIndex];
			if (periodIndex > -1) {
				subForecast.periodForecasts[periodIndex].amount = amount;
			} else {
				subForecast.periodForecasts.push({
					subForecastId: subForecast.subForecastId,
					periodId: period.periodId,
					forecastPeriodId: period.forecastPeriodId,
					amount: amount
				});
			}

			// No longer updating total
			// // Update forecast amount as total of sub forecasts
			// let total = 0;
			// let forecastPeriod;
			// for (let i = 0, ii = forecast.subForecasts.length; i < ii; i++) {
			// 	forecastPeriod = find(forecast.subForecasts[i].periodForecasts, fp => period.periodId ? (fp.periodId === period.periodId || fp.forecastPeriodId === period.forecastPeriodId) : fp.forecastPeriodId === period.forecastPeriodId);
			// 	if (forecastPeriod) total += forecastPeriod.amount || 0;
			// }
			// forecastPeriod = find(forecast.periodForecasts, fp => period.periodId ? (fp.periodId === period.periodId || fp.forecastPeriodId === period.forecastPeriodId) : fp.forecastPeriodId === period.forecastPeriodId);
			// if (forecastPeriod) {
			// 	forecastPeriod.amount = total;
			// } else {
			// 	forecast.periodForecasts.push({
			// 		forecastId: forecast.subForecastId,
			// 		periodId: period.periodId,
			// 		forecastPeriodId: period.forecastPeriodId,
			// 		amount: amount
			// 	});
			// }
		}));
	}
	setSubForecastInclude = (forecastIndex, subForecastIndex, include) => {
		this.setState(produce(draft => {
			const forecast = draft.contractForecast.forecasts[forecastIndex];
			const subForecast = forecast.subForecasts[subForecastIndex];
			subForecast.include = include;
		}));
	}

	saveContractForecast() {

	}

	getPeriods = () => {
		const contractForecast = this.state.contractForecast;
		let periods = map(this.props.periods || [], p => ({ ...p, type: 'Actual', forecastPeriods: [] }));
		const forecastPeriods = contractForecast ? contractForecast.forecastPeriods || [] : [];
		for (let i = 0, ii = forecastPeriods.length; i < ii; i++) {
			const forecastPeriod = forecastPeriods[i];
			if (forecastPeriod.periodId) {
				const period = find(periods, p => p.periodId === forecastPeriod.periodId);
				if (period) {
					period.forecastPeriods.push(forecastPeriod);
				}
			} else {
				periods.push({
					...forecastPeriod, 
					name: forecastPeriod.periodName,
					status: 'Forecast',
					type: 'Forecast',
					forecastPeriods: []
				});
			}
		}
		periods = orderBy(periods, 'startDate');

		return periods;
	}

	render() {
		const contractForecast = this.state.contractForecast;
		const periods = this.getPeriods();

		return (
<PageLayout
	title="Quantity Forecast"
	className="contract-forecast"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'Save',
				onClick: () => this.props.saveContractForecast(this.state.contractForecast),
				primary: true,
				icon: <SaveIcon />,
				isLoading: this.props.isLoading
			},
			{
				label: 'New Project Forecast',
				onClick: this.newForecast
			},
			{
				label: 'New Contractor Cash Flow',
				onClick: this.newBaseline
			}
		] : []
	}
	content={
		<ContentContainer style={{ clear: 'both' }}>
			{/* <ForecastsGrid 
				forecasts={forecasts} 
				canEdit={this.props.canEdit} 
				handleViewForecast={this.handleViewForecast} 
				handleEditForecast={this.handleEditForecast} 
				handleDeleteForecast={this.handleDeleteForecast} 
			/> */}

			<ContractForecastList
				isLoading={this.props.isLoading || this.state.isLoading}
				periods={periods}
				contractForecast={contractForecast}
				canEdit={this.props.canEdit}
				newForecast={this.newForecast}
				updateForecastName={this.updateForecastName}
				setIncludeInChart={this.setIncludeInChart}
				setForecastAmount={this.setForecastAmount}
				deleteForecast={this.deleteForecast}
				newBaseline={this.newBaseline}
				newForecastPeriod={this.newForecastPeriod}
				lockForecast={this.lockForecast}
				unlockForecast={this.unlockForecast}
				expandForecast={this.expandForecast}
				collapseForecast={this.collapseForecast}

				addSubForecast={this.addSubForecast}
				deleteSubForecast={this.deleteSubForecast}
				updateSubForecastName={this.updateSubForecastName}
				setSubForecastAmount={this.setSubForecastAmount}
				setSubForecastInclude={this.setSubForecastInclude}
			/>

			<br />

			{contractForecast && contractForecast.forecasts && contractForecast.forecasts.length > 0 &&
				<ContractForecastChart
					periods={periods}
					contractForecast={contractForecast}
					canEdit={this.props.canEdit}
				/>
			}
		</ContentContainer>
	}
/>
		);
	}
}

ContractForecast.propTypes = {
	dispatch: PropTypes.func.isRequired,
	periods: PropTypes.array.isRequired,
	contractForecast: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveContractForecast: PropTypes.func.isRequired,
	getForecastPeriodName: PropTypes.func.isRequired,
	showError: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	periods: state.context.contract.periods,
	contractForecast: state.forecasts.contractForecast,
	canEdit: canEdit(state, 'EditForecasts'),
	isLoading: state.forecasts.isLoading,
	saveResult: state.forecasts.saveResult
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch,
	saveContractForecast: (contractForecast) => {
		dispatch(saveContractForecast(contractForecast, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));	
	},
	deleteForecast: (forecast) => {
		dispatch(deleteForecast(forecast, (data) => {
			dispatch(showSuccessNotification(data.message));
		}));
	},
	showError: (error) => {
		dispatch(showErrorNotification(error));
	},
	getForecastPeriodName: (onOk) => {
		dispatch(showModal('PROMPT', {
			title: 'New Forecast Period',
			message: 'Enter the name of the new Forecast Period',
			onOk: (v) => {
				onOk(v);
			}
		}));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContractForecast);
