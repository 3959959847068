import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { get } from '../../utils/ajax';
import ReportContainer from '../reportContainer';
import FileDownload from '@material-ui/icons/GetApp';
import MessagePanel from '../messagePanel';
import PageLayout from '../pageLayout';

function addReportWrapper(WrappedComponent, args) {
	const WrappedClass = props => {
		const [state, setState] = React.useState({
			isLoading: true,
			report: {},
			success: false,
			message: ''
		});
		const contract = props.context.contract;
		const period = props.context.period;

		React.useEffect(() => {
			const runReport = (contract, period) => {
				const contractId = contract.contractId;
				const periodId = period.periodId;
	
				axios.all([
					get({
						url: `/api/reports/contract-details?contractId=${contractId}&periodId=${periodId}`,
						onSuccess: (data) => {
							setState(state => ({ ...state, contractDetails: data }));
						},
						onError: (error) => {
							console.log('request failed', error);
						}
					}),
					get({
						url: `${args.reportUrl}?contractId=${contractId}&periodId=${periodId}`,
						onSuccess: (data) => {
							setState(state => ({ ...state, report: data }));
						},
						onError: (error) => {
						}
					}),
				]).then(responseArr => {
					setState(state => ({ ...state, isLoading: false }));
				}).catch((e) => {
					setState(state => ({ ...state, isLoading: false }));
					window.status = 'ready_to_print'; // For wkhtmltopdf to know report is ready
				});
			};

			if (contract) {
				runReport(contract, period);
			}
		}, [contract, period])

		React.useEffect(() => {
			if (!state.isLoading) {
				window.status = 'ready_to_print'; // For wkhtmltopdf to know report is ready
			}
		}, [state.isLoading])

		const downloadReport = () => {
			window.open(args.downloadUrl);
		};
		
		const exportToExcel = () => {
			const contractId = props.context.contract.contractId;
			const periodId = props.context.period.periodId;
			window.open(`${args.excelDownloadUrl}?contractId=${contractId}&periodId=${periodId}`);
		};

		const contractDetails = state.contractDetails;

		const pageActions = [
			{
				label: 'Download',
				icon: <FileDownload />,
				onClick: downloadReport
			}
		];
		if (args.excelDownloadUrl) {
			pageActions.push({
				label: 'Export to Excel',
				icon: <FileDownload />,
				onClick: exportToExcel
			});
		}
			
		return (
<PageLayout
	className={args.className}
	title={args.title}
	isLoading={state.isLoading}
	showHeader={!props.isReportView}
	actions={pageActions}
	content={
	<div>
		<ReportContainer isReportView={props.isReportView} orientation='landscape'>
			<div className='report-header'>
				<div className='header'>
					<h1>{args.title}</h1>
				</div>

				{contractDetails && 
					<div className='contract-details'>
						<div className='contract-details-row'>
							<span>Contract No.</span>
							<span>{contractDetails.contractNumber}</span>
						</div>	
						<div className='contract-details-row'>
							<span>{state.contractName}</span>
						</div>
						<div className='contract-details-row'>
							<span>{contractDetails.periodName}</span>
						</div>
					</div>
				}

				<div className="logo">
					<img src="/images/contrack_logo_200x58.jpg" alt="" />
				</div>
			</div>

			<WrappedComponent 
				report={state.report} 
				isLoading={state.isLoading} 
				success={state.success} 
				message={state.success} 
				{...props} 
			/>
		</ReportContainer>

		<MessagePanel isSuccess={state.success} message={state.message} />

		{/* Add div to indicate rendering has completed for PDF generation */}
		{!state.isLoading &&
			<div id="report-rendering-complete"></div>
		}
	</div>
	}
/>
		);
	};

	WrappedClass.propTypes = {
		context: PropTypes.object.isRequired,
		isReportView: PropTypes.bool
	};

	WrappedClass.defaultProps = {
		isReportView: false
	};

	return WrappedClass;
}

export default addReportWrapper;
