import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverDateField from '../components/handoverDateField';
import HandoverBooleanField from '../components/handoverBooleanField';
import HandoverMemoField from '../components/handoverMemoField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';
import filter from 'lodash/filter';

class OutgoingCorrespondenceHandoverContent extends React.Component {
	render() {
		const correspondence = this.props.report;
		
		const filesColumns = [
			{ name: 'name', label: 'Name' }
		];

		const linkedCorrespondence = filter(correspondence.linkedCorrespondence || [], c => !c.isParent);
		
		const linkedCorrespondenceColumns = [
			{ name: 'correspondenceType', label: 'Type' },
			{ name: 'correspondenceNumber', label: 'Number' },
			{ name: 'correspondenceTitle', label: 'Title' }
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Outgoing Ref Number" value={correspondence.rfiNumber} />
				<HandoverField label="Linked Correspondence" value={correspondence.linkedIncomingCorrespondence} />
				<HandoverDateField label="Dated" value={correspondence.receivedDate} />
				<HandoverField label="Doc Ref No" value={correspondence.docRefNo} />
				<HandoverField label="Document Title" value={correspondence.docTitle} />
				<HandoverField label="To" value={correspondence.toName} />
				<HandoverField label="From" value={correspondence.fromName} />
				<HandoverField label="Delivery Method" value={correspondence.deliveryMethod} />
				<HandoverDateField label="Sent Date" value={correspondence.sentDate} />
				<HandoverBooleanField label="Time Barred" value={correspondence.timeBarred} />
				<HandoverBooleanField label="Action Required" value={correspondence.actionRequired} />
				<HandoverDateField label="Action Due Date" value={correspondence.actionDueDate} />
				<HandoverField label="Status" value={correspondence.status} />
				<HandoverField label="Actioned By" value={correspondence.actionedByName} />
				<HandoverDateField label="Action Date" value={correspondence.actionDate} />
				<HandoverMemoField label="Comments" value={correspondence.comments} />
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Linked Correspondence</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={linkedCorrespondence || []}
					columns={linkedCorrespondenceColumns}
				/>
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Attachments</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={correspondence.files || []}
					columns={filesColumns}
				/>
			</div>
		</div>
    );
  }
}

OutgoingCorrespondenceHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default OutgoingCorrespondenceHandoverContent;
