
const Logger = (() => {
	return {
		logError: (error) => {
			console.log(error);
		}
	};
})();

export default Logger;
