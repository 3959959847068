import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Pageview';
import PersistedDataTable from '../widgets/persistedDataTable';
import { units } from '../../variables';

class ScheduleFormItems extends React.Component {
	shouldComponentUpdate(nextProps) {
		// console.log('changed = ' + (this.props.items !== nextProps.items) + ' length1: ' + this.props.items.length + ' length2: ' + nextProps.items.length);
		return this.props.items !== nextProps.items;
	}

	render() {
		const columns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => {
					if (this.props.canEdit) {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.editScheduleItem(args.data); } } style={{ padding: '2px' }}>
									<EditIcon />
								</IconButton>
								<IconButton onClick={ () => { this.props.deleteScheduleItem(args.data); } } style={{ padding: '2px' }}>
									<ActionDelete />
								</IconButton>
							</div>
						);
					} else {
						return (
							<div style={{ whiteSpace: 'nowrap' }}>
								<IconButton onClick={ () => { this.props.viewScheduleItem(args.data); } } style={{ padding: '2px' }}>
									<ViewIcon />
								</IconButton>
							</div>
						);
					}
				}	
			},
			{
				name: 'itemNo',
				label: 'Item No'
			},
			{
				name: 'description',
				label: 'Description',
				colspan: ({ data }) => {
					if (data.isTextOnlyLine) return 6;
					return 1;
				}
			},
			{
				name: 'unit',
				label: 'Unit',
				filterOperators: ['=', 'Contains', 'multi-select'],
				filterOptions: units
			},
			{
				name: 'qty',
				label: 'Qty',
				dataType: 'number'
			},
			{
				name: 'forecastQty',
				label: 'Forecast Qty',
				dataType: 'number'
			},
			{
				name: 'rate',
				label: 'Rate',
				dataType: 'number',
				cellRenderer: (args) => (args.value).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
			},
			{
				name: 'amount',
				label: 'Amount',
				dataType: 'number',
				value: (args) => args.data.qty * args.data.rate,
				cellRenderer: (args) => (args.data.qty * args.data.rate).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
			}
		];
		
		
		return (
			<div>
				<PersistedDataTable 
					id="scheduleItems" 
					data={this.props.items || []} 
					columns={columns} 
					rowStyle={(data) => {
						if (data.isTextOnlyLine) {
							return {
								fontWeight: 'bold',
								fontStyle: 'italic'
							}
						} else {
							return null;
						}
					}}
				/>
			</div>
		);
	}
}


ScheduleFormItems.propTypes = {
	items: PropTypes.array,
	canEdit: PropTypes.bool.isRequired,
	editScheduleItem: PropTypes.func.isRequired,
	viewScheduleItem: PropTypes.func.isRequired,
	deleteScheduleItem: PropTypes.func.isRequired
};

ScheduleFormItems.defaultProps = {
	items: []
};

export default ScheduleFormItems;
