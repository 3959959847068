import React from 'react';
import PropTypes from 'prop-types';
import HandoverField from '../components/handoverField';
import HandoverMemoField from '../components/handoverMemoField';
import HandoverSectionHeader from '../components/handoverSectionHeader';
import DataTable from '../../widgets/dataTable';

class LotAdjustmentHandoverContent extends React.Component {
	render() {
		const items = this.props.report;

		const columns = [
			{ name: 'itemNo', label: 'Item' },
			{ name: 'itemDescription', label: 'Description' },
			{ name: 'qty', label: 'Qty' },
			{ name: 'comments', label: 'Comments' }
		];

		return (
		<div>
			<div className="handover-data">
				<HandoverField label="Lot No" value={items.lotNo} />
				<HandoverField label="Adjustment Type" value={items.adjustmentType} />
				<HandoverMemoField label="Comments" value={items.comments} />
			</div>

			<div className="handover-items">
				<HandoverSectionHeader>Items</HandoverSectionHeader>

				<DataTable 
					style={{ marginTop: '20px', width: '100%' }}
					hideActions={true}
					hideSearch={true}
					hideFilter={true}
					hidePaging={true}
					defaultRowsPerPage="All"
					data={items.items || []}
					columns={columns}
				/>
			</div>
		</div>
    );
  }
}

LotAdjustmentHandoverContent.propTypes = {
	report: PropTypes.object.isRequired
};

export default LotAdjustmentHandoverContent;
