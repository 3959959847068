import React from 'react';
import PropTypes from 'prop-types';
import { getElementInTableCellAbove, getElementInTableCellBelow } from '../../utils/dom';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import isString from 'lodash/isString';
import { 
	Checkbox,
	ListItemIcon,
	MenuItem,
	Select
} from '@material-ui/core';

class DataTableSelectEditor extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			value: props.value || '',
			size: 10
		};

	}

	onChangeDebounced = debounce((v) => {
		this.props.onChange(v);
	}, 500)

	onChange = (e) => {
		const value = e.target.value;
		this.setState(prevState => ({
			...prevState,
			value: value
		}), () => {
		});
		if (this.props.onChange) this.onChangeDebounced(value);
	}
	onKeyUp = (e) => {
		switch (e.keyCode) {
			case 40: {
				const belowInput = getElementInTableCellBelow(this.input, 'INPUT');
				if (belowInput) belowInput.focus();
				break;
			}
			case 38: {
				const aboveInput = getElementInTableCellAbove(this.input, 'INPUT');
				if (aboveInput) aboveInput.focus();
				break;
			}
			default:
				if (this.props.autoWidth) {
					this.setState(prevState => ({
						...prevState,
						size: Math.max(10, e.target.value.length)
					}));
				}
		}
	}

	render = () => {
		const value = this.state.value;

		return (
			<Select
				className="data-table-select-editor"
				disableUnderline={true}
				fullWidth={true}
				value={value}
				onChange={this.onChange}
				items={this.props.items}
			>
				{map(this.props.items, (item, index) => 
					<MenuItem 
						key={index} 
						value={isString(item) ? item : item.value}
						disabled={item.disabled}
					>
						{item.leftIcon && <ListItemIcon style={{ verticalAlign: 'middle' }}>{item.leftIcon}</ListItemIcon>}
						{this.props.multiple && <Checkbox checked={value.indexOf(item.value) > -1} />}
						{isString(item) ? item : item.label}
					</MenuItem>
				)}
			</Select>
		);
	};
}

DataTableSelectEditor.propTypes = {
	value: PropTypes.string,
	multiline: PropTypes.bool,
	onChange: PropTypes.func,
	autoWidth: PropTypes.bool
};

DataTableSelectEditor.defaultProps = {
	value: undefined,
	multiline: true,
	onChange: true,
	autoWidth: false
};

export default DataTableSelectEditor;
