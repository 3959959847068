import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import AddIcon from '@material-ui/icons/Add';
import SelectField from './selectField';
import NumericField from './numericField';
import DateField from './DateField';
import isObject from 'lodash/isObject';
import AutoComplete from './AutoComplete';
import Button from './button';
import isArray from 'lodash/isArray';

class DataTableFilterEditor extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			filters: props.filters
		};

		this.close = this.close.bind(this);
		this.addFilter = this.addFilter.bind(this);
		this.removeFilter = this.removeFilter.bind(this);
		this.onChangeFilterColumn = this.onChangeFilterColumn.bind(this);
		this.onChangeFilterOperator = this.onChangeFilterOperator.bind(this);
		this.onChangeFilterValue = this.onChangeFilterValue.bind(this);
		this.applyFilter = this.applyFilter.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			filters: nextProps.filters
		});
	}

	getFilterOptionsKey = (value, column) => {
		if (isObject(value)) {
			return value[column.filterOptionsKey || 'key'];
		} else {
			return value;
		}
	}
	getFilterOptionsText = (value, column) => {
		if (isObject(value)) {
			return value[column.filterOptionsText || 'text'];
		} else {
			return value;
		}
	}

	onFilterDropdownClick(e) {
		e.stopPropagation();
	}

	close(e) {
		e.stopPropagation();
		this.props.onCancelFilter();
	}

	getDefaultValue = (column, f) => {
		if (f === 'includes') return null;
		switch (column.dataType) {
			case 'currency':
			case 'percent':
			case 'number':
				return 0;
			case 'bool':
				return true;
			case 'date':
			case 'datetime':
				return null;
			default:
				return '';
		}
	}

	addFilter() {
		this.setState({
			filters: [...this.state.filters, {
				column: this.props.columns[0].name,
				operator: '=',
				value: this.getDefaultValue(this.props.columns[0], '=')
			}]
		});
	}
	removeFilter(f) {
		this.setState({
			filters: filter(this.state.filters, (f2) => f2 !== f)
		});
	}
	onChangeFilterColumn(f, value) {
		const filt = find(this.state.filters, (f2) => f2 === f);
		filt.column = value;
		
		const column = find(this.props.columns, (c) => c.name === value);
		filt.operator = (column.filterOperators || ['='])[0];

		filt.value = this.getDefaultValue(column, f);

		this.setState({
			filters: this.state.filters
		});
	}
	onChangeFilterOperator(f, value) {
		const filt = find(this.state.filters, (f2) => f2 === f);
		filt.operator = value;
		
		this.setState({
			filters: this.state.filters
		});
	}
	onChangeFilterValue(f, value) {
		const filt = find(this.state.filters, (f2) => f2 === f);
				
		if (f.operator === 'multi-select') {
			filt.value = value.value;
			filt.label = value.label;
		} else {
			filt.value = value;
		}

		this.setState({
			filters: this.state.filters
		});
	}
	applyFilter() {
		this.props.onApplyFilter(this.state.filters);
	}

	render() {
		return (
<div role="button" className="filter-dropdown" onClick={(e) => { this.onFilterDropdownClick(e); }}>
	<a role="button" className="filter-dropdown-close" onClick={(e) => { this.close(e); }}><i className='fa fa-remove' /></a>
	<div className="filters-title">Filters</div>
	<table className="filters">
		<tbody>
		{
			map(this.state.filters, (f, fi) => {
				const column = find(this.props.columns, (c) => c.name === f.column);
				let operators;
				if (column.filterOperators) {
					operators = column.filterOperators;
				} else {
					switch (column.dataType) {
						case 'currency':
						case 'percent':
						case 'number':
							operators = ['=', '<', '<=', '>', '>='];
							break;
						case 'bool':
							operators = ['='];
							break;
						case 'date':
						case 'datetime':
							operators = ['=', '<', '<=', '>', '>='];
							break;
						default:
							operators = ['=', 'Contains'];
					}
				}

				let fieldType = 'text';
				let fieldValue = f.value;
				switch (column.dataType) {
					case 'currency':
					case 'percent':
					case 'number':
						fieldType = 'numeric';
						break;
					case 'bool':
						fieldType = 'bool';
						break;
					case 'date':
						fieldType = 'date';
						break;
					case 'datetime':
						fieldType = 'datetime';
						break;
					default:
						fieldType = 'text';
				}
				if (f.operator === 'multi-select') {
					fieldType = 'multi-select';
					if (!isArray(f.value)) fieldValue = [];
				}
				if (f.operator === 'includes') {
					fieldType = 'includes';
				}

				return (
					<tr key={`filter-${fi}`}>
						<td>
							<select value={f.column} onChange={(e) => { this.onChangeFilterColumn(f, e.target.value); }}>
								{
									map(this.props.columns, (c, i) =>
										<option key={`filter-col-${i}`} value={c.name}>{c.fullLabel || c.label || c.name}</option>
									)
								}
							</select>
						</td>
						<td>
							<select value={f.operator} style={{ width: '120px' }} onChange={(e) => { this.onChangeFilterOperator(f, e.target.value); }}>
								{operators.indexOf('=') > -1 && <option value='='>Is Equal To</option>}
								{operators.indexOf('Contains') > -1 && <option value='Contains'>Contains</option>}
								{operators.indexOf('>') > -1 && <option value='>'>&gt;</option>}
								{operators.indexOf('>=') > -1 && <option value='>='>&gt;=</option>}
								{operators.indexOf('<') > -1 && <option value='<'>&lt;</option>}
								{operators.indexOf('<=') > -1 && <option value='<='>&lt;=</option>}
								{operators.indexOf('multi-select') > -1 && <option value='multi-select'>Any Of</option>}
								{operators.indexOf('includes') > -1 && <option value='includes'>Includes</option>}
							</select>
						</td>
						<td>
							{fieldType === 'text' &&
								<input value={fieldValue} onChange={(e) => { this.onChangeFilterValue(f, e.target.value); }} />
							}
							{fieldType === 'numeric' &&
								<NumericField
									type={column.dataType === 'number' ? 'decimal' : column.dataType}
									value={fieldValue}
									onChange={(v) => { f.value = v; this.setState({}); }}
									variant="standard"
									fullWidth={true}
									style={{ 
										width: 'auto', 
										height: '31px', 
										backgroundColor: '#fff', 
										border: '0.5px solid #aaa', 
										padding: 0, 
										borderRadius: '2px' 
									}}
									inputStyle={{ marginTop: 0 }}
									disableUnderline={true}
								/>
							}
							{fieldType === 'bool' &&
								<SelectField 
									variant="standard"
									value={fieldValue} 
									multiple={false}
									onChange={(v) => { f.value = v; this.setState({}); }}
									fullWidth={true}
									style={{ 
										display: 'block', 
										width: '300px', 
										height: '31px', 
										backgroundColor: '#fff', 
										border: '0.5px solid #aaa', 
										padding: 0, 
										borderRadius: '2px' 
									}}
									disableUnderline={true}
									items={[
										{ value: true, label: 'True' },
										{ value: false, label: 'False' }
									]}
								>
								</SelectField>
							}
							{(fieldType === 'date' || fieldType === 'datetime') &&
								<DateField 
									value={fieldValue} 
									onChange={(d) => { f.value = d; this.setState({}); }}
									fullWidth={true}
									style={{ 
										display: 'block', 
										width: '300px', 
										height: '31px', 
										backgroundColor: '#fff', 
										border: '0.5px solid #aaa', 
										padding: '0', 
										borderRadius: '2px'  
									}}
									inputStyle={{
										paddingTop: '5px'
									}}
									datePickerStyle={{
										marginTop: 0
									}}
									clearButtonStyle={{
										padding: '5px'
									}}
									disableUnderline={true}
								/>
							}
							{fieldType === 'multi-select' &&
								(() => {
									const items = map(column.filterOptions, (o) => 
										({ value: this.getFilterOptionsKey(o, column), label: this.getFilterOptionsText(o, column) })
									);

									return (
										<SelectField 
											value={fieldValue} 
											multiple={true}
											onChange={(v) => { 
												const item = find(items, i => i.value === v);
												f.value = v; 
												f.label = item ? item.label : undefined;
												this.setState({}); 
											}}
											variant="standard"
											fullWidth={true}
											style={{ 
												display: 'block', 
												width: '300px', 
												height: '31px', 
												backgroundColor: '#fff', 
												border: '0.5px solid #aaa', 
												padding: 0, 
												borderRadius: '2px' 
											}}
											disableUnderline={true}
											items={items}
										>
										</SelectField>
									);
								})()
							}
							{fieldType === 'includes' &&
								<AutoComplete 
									fullWidth={true}
									variant="standard"
									options={map(column.filterData, d => ({ label: d[column.filterValue], value: d[column.filterKey] }))}
									value={fieldValue ? fieldValue[column.filterKey] : null}
									onChange={(event, v) => {
										f.value = find(column.filterData, d => d[column.filterKey] === v.value);
										this.setState({});
									}}
									disableUnderline={true}
									style={{ 
										display: 'block', 
										width: '300px', 
										height: '31px', 
										backgroundColor: '#fff', 
										border: '0.5px solid #aaa', 
										padding: 0, 
										borderRadius: '2px' 
									}}
								/>
							}
						</td>
						<td>
							<a role="button" className="remove-filter" onClick={() => { this.removeFilter(f); }}><i className='fa fa-remove' /></a>
						</td>						
					</tr>
				);
			})
		}
		</tbody>
	</table>
	<div>
		<Button label="Add" className="add-filter" onClick={() => { this.addFilter(); }} icon={<AddIcon />} />
		<Button label="Apply" className="apply-filter-button" primary={true} onClick={this.applyFilter} />
	</div>
</div>
		);
	}
}

DataTableFilterEditor.propTypes = {
	columns: PropTypes.array.isRequired,
	filters: PropTypes.array.isRequired,
	onCancelFilter: PropTypes.func.isRequired,
	onApplyFilter: PropTypes.func.isRequired
};

export default DataTableFilterEditor;
