import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';

const initialState = {
	isLoading: false,
	saveResult: {
		success: null,
		message: null,
		fields: [] 
	},
	contractor: null, 
	contractors: null
};

const newContractor = {
	'contractorId': 0,
	'name': '',
	'tradingName': '',
	'abn': '',
	'address': ''
};

const CLEAR_CONTRACTORS = 'CLEAR_CONTRACTORS';
const CLEAR_CONTRACTOR = 'CLEAR_CONTRACTOR';
const REQUEST_CONTRACTOR = 'REQUEST_CONTRACTOR';
const RECEIVE_CONTRACTOR = 'RECEIVE_CONTRACTOR';
const REQUEST_CONTRACTORS = 'REQUEST_CONTRACTORS';
const RECEIVE_CONTRACTORS = 'RECEIVE_CONTRACTORS';
const CREATE_NEW_CONTRACTOR = 'CREATE_NEW_CONTRACTOR';
const SAVE_CONTRACTOR = 'SAVE_CONTRACTOR';
const RECEIVE_SAVE_CONTRACTOR_RESPONSE = 'RECEIVE_SAVE_CONTRACTOR_RESPONSE';
const DELETE_CONTRACTOR = 'DELETE_CONTRACTOR';
const RECEIVE_DELETE_CONTRACTOR_RESPONSE = 'RECEIVE_DELETE_CONTRACTOR_RESPONSE';
const SHOW_CONTRACTOR_ERRORS = 'SHOW_CONTRACTOR_ERRORS';
const RECEIVE_ERROR_RESPONSE = 'RECEIVE_ERROR_RESPONSE';

export const clearContractors = () => ({ type: CLEAR_CONTRACTORS });
export const clearContractor = () => ({ type: CLEAR_CONTRACTOR });
export const receiveContractors = (data) => ({ type: RECEIVE_CONTRACTORS, payload: { data } });
export const receiveContractor = (data) => ({ type: RECEIVE_CONTRACTOR, payload: { data } });
export const createNewContractor = () => ({ type: CREATE_NEW_CONTRACTOR });
export const receiveSaveContractorResponse = (data) => ({ type: RECEIVE_SAVE_CONTRACTOR_RESPONSE, data });
export const receiveDeleteContractorResponse = (data) => ({ type: RECEIVE_DELETE_CONTRACTOR_RESPONSE, data });
export const receiveErrorResponse = (error) => ({ type: RECEIVE_ERROR_RESPONSE, error });

export const requestContractors = () => (
	createFetchAction({
		objectName: 'Contractors',
		passContext: true,
		url: '/api/contractors',
		startAction: REQUEST_CONTRACTORS,
		onError: (response) => receiveErrorResponse(response),
		success: (data) => receiveContractors(data)
	})
);

export const requestContractor = (contractorId) => (
	createFetchAction({
		objectName: 'Contractor',
		passContext: true,
		url: `/api/contractors/${contractorId}`,
		startAction: REQUEST_CONTRACTOR,
		onError: (response) => receiveErrorResponse(response),
		success: (data) => receiveContractor(data)
	})
);

export const saveContractor = (contractor, onSuccess) => {
	const errors = [];
	if (!contractor.name) {
		errors.push({
			fieldName: 'Name',
			valid: false,
			message: ' Name is required'
		});
	}
	if (errors.length > 0) return { type: SHOW_CONTRACTOR_ERRORS, data: errors };

	return createPostAction({
		passContext: true,
		url: '/api/contractors',
		data: contractor,
		startAction: SAVE_CONTRACTOR,
		onError: (response) => receiveErrorResponse(response),
		success: (data, dispatch) => {
			dispatch(receiveSaveContractorResponse(data));
			if (data.success && onSuccess) onSuccess.call(this, data);
		}
	});
};

export const deleteContractor = (contractor) => (
	createPostAction({
		passContext: true,
		url: `/api/contractors/${contractor.contractorId}/delete`,
		data: contractor,
		startAction: DELETE_CONTRACTOR,
		success: (data) => receiveDeleteContractorResponse(data)
	})
);

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CONTRACTORS:
			return {
				...state,
				contractors: null
			};
		case CLEAR_CONTRACTOR:
			return {
				...state,
				contractor: null
			};
		case RECEIVE_ERROR_RESPONSE:
			console.log('RECEIVE_ERROR_RESPONSE - contractors');
			return {
				...state,
				isLoading: false,
				result: {
					success: false,
					message: action.error.message,
					errors: [] 
				}
			};
		case REQUEST_CONTRACTORS:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				contractors: []
			};
		case RECEIVE_CONTRACTORS:
			return {
				...state,
				isLoading: false,
				contractors: action.payload.data
			};
		case REQUEST_CONTRACTOR:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				contractor: { ...newContractor }
			};
		case RECEIVE_CONTRACTOR:
			return {
				...state,
				isLoading: false,
				contractor: action.payload.data
			};
		case DELETE_CONTRACTOR:
			return { 
				...state
			};
		case RECEIVE_DELETE_CONTRACTOR_RESPONSE:
			return {
				...state,
				contractors: state.contractors.filter(c => c.contractorId !== action.data.objectId) 
			};
		case CREATE_NEW_CONTRACTOR:
			return {
				...state,
				contractor: { ...newContractor }
			};	
		case SAVE_CONTRACTOR:
			return {
				...state,
				isLoading: true,
				saveResult: {
					success: null,
					message: null,
					fields: [] 
				},
				message: null
			};
		case RECEIVE_SAVE_CONTRACTOR_RESPONSE:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}

			return {
				...state,
				contractors: addOrUpdate(state.contractors, action.data.object, { contractorId: action.data.object.contractorId }), 
				contractor: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
			};
		case SHOW_CONTRACTOR_ERRORS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: 'Please correct the errors',
					fields: action.data
				},
			};
		default:
			return state;
	}
};
