import React from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import { 
	deleteContract, 
	startContract, 
	requestContracts, 
	clearContracts 
} from '../../reducers/contracts';
import IconButton from '../widgets/IconButton';
import ActionDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import WorkIcon from '@material-ui/icons/Work';
import PlayArrow from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import MessagePanel from '../messagePanel';
import ContentContainer from '../contentContainer';
import StartContractDialog from './startContractDialog';
import { showModal } from '../../reducers/modal';
import PersistedDataTable from '../widgets/persistedDataTable';
import { updateContract, removeContractFromContext } from '../../reducers/context';
import PageLayout from '../pageLayout';

class ContractList extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			contract: null,
			showStartContractDialog: false
		};

        this.handleEditContract = this.handleEditContract.bind(this);
        this.handleDeleteContract = this.handleDeleteContract.bind(this);
        this.showStartContractDialog = this.showStartContractDialog.bind(this);
        this.onStartContractDialogClose = this.onStartContractDialogClose.bind(this);
        this.onStartContract = this.onStartContract.bind(this);
        this.onCloseContract = this.onCloseContract.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestContracts());
	}
	static clearData(dispatch) {
		dispatch(clearContracts());
	}

	componentDidMount() {
		ContractList.fetchData(this.props.dispatch);
	}

	handleEditContract(contractNumber) {
		this.props.history.push(`/contract/${contractNumber}`);
	}

	handleDeleteContract(contract) {
		this.props.deleteContract(contract);
	}
	contractHandover = (contract) => {
		this.props.history.push(`/handover/${contract.contractId}`);
	}

	newContract = () => {
		this.props.history.push('/contract/new');
	}

	onStartContractDialogClose() {
        this.setState({ 
			showStartContractDialog: false 
		});
	}

	onStartContract(periodName) {
        this.setState({ 
			showStartContractDialog: false 
		});
		this.props.startContract(this.state.contract, periodName);
	}

	showStartContractDialog(contract) {
        this.setState({ 
			contract: contract,
			showStartContractDialog: true 
		});
	}

	onCloseContract(contract) {
		this.props.history.push(`/close-contract/${contract.contractId}`);
	}

	render() {
		const contracts = this.props.contracts || [];
		const atMaxContractLimit = this.props.maxContracts > 0 && contracts.length > this.props.maxContracts;

		const columns = [
			{
				name: 'commands',
				excludeFromSearch: true,
				excludeFromFilter: true,
				cellRenderer: (args) => (
					<div style={{ whiteSpace: 'nowrap' }}>
						{args.data.contractStatus !== 'Closed' &&
							<React.Fragment>
							<IconButton onClick={ () => { this.handleEditContract(args.data.contractNumber); } } style={{ padding: '2px' }}>
								<EditIcon />
							</IconButton>
							<IconButton onClick={ () => { this.handleDeleteContract(args.data); } } style={{ padding: '2px' }}>
								<ActionDelete />
							</IconButton>
							<IconButton onClick={ () => { this.contractHandover(args.data); } } style={{ padding: '2px' }}>
								<WorkIcon />
							</IconButton>
							</React.Fragment>
						}
						{args.data.contractStatus === 'Preliminary' &&
							<IconButton onClick={ () => { this.showStartContractDialog(args.data); } }>
								<PlayArrow />
							</IconButton>
						}
						{args.data.contractStatus === 'In Construction' &&
							<IconButton onClick={ () => { this.onCloseContract(args.data); } }>
								<StopIcon />
							</IconButton>
						}
					</div>
				)
			},
			{ name: 'contractNumber', label: 'Contract Number' },
			{ name: 'contractName', label: 'Contract Name' },
			{ 
				name: 'contractStatus', 
				label: 'Status',  
				filterOperators: ['multi-select'],
				filterOptions: ['Preliminary', 'In Construction', 'Pending Defects', 'On Maintenance', 'Complete']
			},
			{ name: 'contractor', label: 'Contractor' },
			{ name: 'dateCommenced', label: 'Date Commenced', dataType: 'date' }
		];

		return (
<PageLayout
	title="Contracts"
	isLoading={this.props.isLoading}
	actions={
		[
			{
				label: 'New Contract',
				onClick: this.newContract,
				tooltip: atMaxContractLimit ? "You have reached the maximum number of contracts" : undefined,
				disabled: atMaxContractLimit
			}
		]
	}
	content={
		<div>
		<StartContractDialog show={this.state.showStartContractDialog} onClose={this.onStartContractDialogClose} onStartContract={this.onStartContract} />

		<MessagePanel isSuccess={this.props.saveResult.success} message={this.props.saveResult.message} />

		<ContentContainer>
			<PersistedDataTable 
				id="contracts"
				data={contracts || []} 
				columns={columns}
			/>
		</ContentContainer>
		</div>
	}
/>
    );
  }
}

ContractList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	contracts: PropTypes.array,
	deleteContract: PropTypes.func.isRequired,
	startContract: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	saveResult: PropTypes.object.isRequired
};

ContractList.defaultProps = {
	contracts: []
};

const mapStateToProps = (state) => ({
	contracts: state.contracts.contractList,
	isLoading: state.contracts.isLoading,
	saveResult: state.contracts.saveResult,
	maxContracts: state.context.maxContracts
});
const mapDispatchToProps = (dispatch) => ({
	dispatch,
	deleteContract: (contract) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this contract?',
			onOk: () => {
				dispatch(deleteContract(contract, (data) => {
					if (data.success) {
						dispatch(removeContractFromContext(contract.contractId));
					}
				}));
			}
		}));
	},
	startContract: (contract, periodName) => {
		dispatch(startContract(contract, periodName, (data) => {
			dispatch(updateContract(data.object, true));
		}));
	}
});


export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(ContractList);
