import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectedRouterHOC from '../../utils/connectedRouterHOC';
import Button from '../widgets/button';
import Dialog from '../widgets/Dialog';
import TextField from '../widgets/TextField';
import { 
	importScheduleOfRates, 
	deleteSchedule, 
	deleteScheduleItem, 
	requestScheduleOfRates, 
	clearSchedules,
	reorderSchedules
} from '../../reducers/scheduleOfRates';
import MessagePanel from '../messagePanel';
import map from 'lodash/map';
import { showModal } from '../../reducers/modal';
import { canEditAnyPeriod } from '../../selectors/canEdit';
import forEach from 'lodash/forEach';
import { toCurrency } from '../../utils/utils';
import Paper from '@material-ui/core/Paper';
import filter from 'lodash/filter';
import PageLayout from '../pageLayout';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import round from 'lodash/round';
import SearchField from '../widgets/searchField';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import ScheduleOfRatesCard from './ScheduleOfRatesCard';
import Checkbox from '../widgets/Checkbox';
import { showSuccessNotification } from '../../reducers/notifications';

class ScheduleOfRates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
			importFile: null,
			importScheduleOfRatesName: '',
			importScheduleOfRatesNameErrorText: '',
			search: '',
			hideInactiveSchedules: false
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.selectImportFile = this.selectImportFile.bind(this);
		this.importSchedule = this.importSchedule.bind(this);
    }
	static fetchData(dispatch) {
		dispatch(requestScheduleOfRates());
	}
	static clearData(dispatch) {
		dispatch(clearSchedules());
	}

	componentDidMount() {
		ScheduleOfRates.fetchData(this.props.dispatch);
	}

	downloadImportTemplate() {
		window.open('/api/schedule-of-rates/template', '_self');
	}

    handleOpen() {
        this.setState({ 
			open: true,
			importScheduleOfRatesNameErrorText: '' 
		});
    }

    handleClose() {
        this.setState({ open: false });
    }

	selectImportFile(event) {
		this.setState({ importFile: event.target.files[0] });
	}

	importSchedule() {
		if (!this.state.importScheduleOfRatesName) {
			this.setState({ 
				importScheduleOfRatesNameErrorText: 'You must enter a schedule name' 
			});
		} else {
			this.setState({ 
				open: false 
			});
			this.props.importScheduleOfRates(this.state.importFile, this.state.importScheduleOfRatesName, (data) => {
				this.props.showSuccessNotification(data.message);
			});
		}
	}

	handleDeleteSchedule = (schedule) => {
		this.props.deleteSchedule(schedule);
	}

	handleEditSchedule = (schedule) => {
		this.props.history.push(`/schedule/${schedule.scheduleOfRatesId}`);
	}

	handleDeleteScheduleItem(schedule, item) {
		this.props.deleteScheduleItem(item);
	}

	newSchedule = () => {
		this.props.history.push('/schedule');
	}

	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const sourceSchedule = this.props.scheduleOfRates[result.source.index];
		const destinationSchedule = this.props.scheduleOfRates[result.destination.index];
		this.props.reorderSchedules(sourceSchedule, destinationSchedule);
	}

	render() {
		const scheduleOfRates = map(
			filter(this.props.scheduleOfRates || [], (s) => !this.state.search || includes(toLower(s.name), toLower(this.state.search))),
			s => ({
				...s,
				_deletable: this.props.contract.contractStatus === 'Preliminary' || !s.tendered,
				_editable: true
			})
		);
		let total = 0;
		forEach(scheduleOfRates, (s) => {
			let scheduleTotal = 0;
			forEach(s.items, (i) => {
				scheduleTotal += i.rate * i.qty;
			});

			s._total = round(scheduleTotal, 2);
			total += scheduleTotal;
		});
		total = round(total, 2);

		const actions = [
			<Button
				key="import-cancel"
				label="Cancel"
				primary={true}
				onClick={this.handleClose}
			/>,
			<Button
				key="import-submit"
				label="Submit"
				primary={true}
				onClick={this.importSchedule}
			/>
		];
		const importDialog = (
			<Dialog
				title="Import Schedule of Rates"
				actions={actions}
				open={this.state.open}
				onClose={this.handleClose}
			>
				<form action="/api/schedule-of-rates/import" method="post" encType="multipart/form-data">
					<TextField
						value={this.state.importScheduleOfRatesName}
						onChange={e => this.setState({ importScheduleOfRatesName: e.target.value })}
						label="Schedule of Rates Name"
						fullWidth={true}
						errorText={this.state.importScheduleOfRatesNameErrorText}
					/>

					<input style={{ marginTop: '10px' }} type="file" name="file" onChange={this.selectImportFile} />
					{
						/*
					<input type="submit" />
					*/
					}
				</form>
			</Dialog>
		);

		return (
<PageLayout
	title="Schedule of Rates"
	isLoading={this.props.isLoading}
	actions={
		this.props.canEdit ? 
		[
			{
				label: 'Add Schedule',
				onClick: this.newSchedule
			},
			{
				label: 'Import Schedule of Rates',
				onClick: this.handleOpen
			},
			{
				label: 'Download Import Template',
				onClick: this.downloadImportTemplate
			}
		] : []
	}
	content={
		<div>
		{importDialog}

		<MessagePanel isSuccess={this.props.result.success} message={this.props.result.message} />

		<div className="schedule-filters" style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
			<SearchField 
				style={{ flexGrow: 1 }}
				onSearch={(search) => this.setState({ search: search })}
			/>
			<Checkbox
				style={{ marginLeft: '5px' }}
				label="Show Active Only"
				labelPosition="right"
				checked={this.state.hideInactiveSchedules}
				onCheck={(e, v) => { this.setState({ hideInactiveSchedules: v }); }}
			/>
		</div>

		<DragDropContext onDragEnd={this.onDragEnd}>
			<Droppable droppableId="droppable">
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
				>
					{
						map(scheduleOfRates, (s, index) =>
							this.state.hideInactiveSchedules && !s.active ? undefined :
							<Draggable key={s.scheduleOfRatesId} draggableId={`${s.scheduleOfRatesId}`} index={index}>
							{(dragProvided) => (
								<div
									ref={dragProvided.innerRef}
									{...dragProvided.draggableProps}
								>
									<ScheduleOfRatesCard
										schedule={s} 
										canEdit={this.props.canEdit}
										dragHandleProps={{ ...dragProvided.dragHandleProps }}
										onEditSchedule={this.handleEditSchedule}
										onDeleteSchedule={this.handleDeleteSchedule}
									/>
								</div>
							)}
							</Draggable>
						)
					}
					{provided.placeholder}
				</div>
				)}
			</Droppable>
		</DragDropContext>

		{!this.props.isLoading &&
		<Paper style={{ 
				float: 'right', 
				padding: '20px', 
				margin: '20px 0', 
				fontSize: '16px', 
				fontWeight: 'bold' 
			}}
		>
			<div>
				<span>Contract Total: </span>
				<span>{toCurrency(total)}</span>
			</div>
		</Paper>
		}
		</div>
	}
/>
		);
	}
}

ScheduleOfRates.propTypes = {
	contract: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	scheduleOfRates: PropTypes.array,
	isLoading: PropTypes.bool.isRequired,
	importScheduleOfRates: PropTypes.func.isRequired,
	result: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	deleteSchedule: PropTypes.func.isRequired,
	deleteScheduleItem: PropTypes.func.isRequired,
	reorderSchedules: PropTypes.func.isRequired
};

ScheduleOfRates.defaultProps = {
	scheduleOfRates: []
};

const mapStateToProps = (state) => ({
	contract: state.context.contract,
	scheduleOfRates: state.scheduleOfRates.scheduleOfRates,
	canEdit: canEditAnyPeriod(state, 'EditSchedules'),
	isLoading: state.scheduleOfRates.isLoading,
	result: state.scheduleOfRates.result
});
const mapDispatchToProps = (dispatch) => ({
	dispatch,
	importScheduleOfRates: (importFile, scheduleOfRatesName, onSuccess) => {
		dispatch(importScheduleOfRates(importFile, scheduleOfRatesName, onSuccess));
	},
	showSuccessNotification: (message) => {
		dispatch(showSuccessNotification(message));
	},
	deleteSchedule: (schedule) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this schedule?',
			onOk: () => {
				dispatch(deleteSchedule(schedule));
			}
		}));
	},
	deleteScheduleItem: (item) => {
		dispatch(showModal('CONFIRM_DELETE', {
			title: 'Delete?',
			message: 'Are you sure you wish to delete this schedule item?',
			onOk: () => {
				dispatch(deleteScheduleItem(item));
			}
		}));
	},
	reorderSchedules: (sourceSchedule, destinationSchedule) => dispatch(reorderSchedules(sourceSchedule, destinationSchedule))
});

export default connectedRouterHOC(
	mapStateToProps, 
	mapDispatchToProps
)(ScheduleOfRates);
