import React from 'react';
import PropTypes from 'prop-types';

const FormContainer = ({ children, onSubmit, preventSubmit, style }) =>
	<div className="row">
		<form 
			className="col s12" 
			style={{ padding: 0, ...style }} 
			onSubmit={(e) => {
				if (onSubmit) onSubmit.call(this, e);
				if (preventSubmit !== false) {
					e.preventDefault();
				}
			}}
		>
			{children}
		</form>
	</div>;

FormContainer.propTypes = {
	children: PropTypes.any.isRequired,
	onSubmit: PropTypes.func,
	preventSubmit: PropTypes.bool,
	style: PropTypes.object
};

FormContainer.defaultProps = {
	onSubmit: null,
	preventSubmit: false,
	style: undefined
};


export default FormContainer;
