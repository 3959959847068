import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrincipalCorrespondenceHandoverContent from '../content/principalCorrespondenceHandoverContent';
import addHandoverWrapper from '../handoverWrapper';

class PrincipalCorrespondenceHandover extends React.Component {
	static getTitle(report) {
		return `Principal Correspondence ${report.rfiNumber}`;
	}

	render() {
		const report = this.props.report.data[0];
		
		return (
			<PrincipalCorrespondenceHandoverContent report={report} />
		);
	}
}

PrincipalCorrespondenceHandover.propTypes = {
	context: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	context: state.context
});

export default connect(
	mapStateToProps
)(addHandoverWrapper(
	PrincipalCorrespondenceHandover, {
		title: 'Principal Correspondence',
		className: 'principal-correspondence-handover'
	})
);
